import { Box, CircularProgress } from "@mui/material";


const Loadder = () => {
  return <Box position={'fixed'} zIndex={1000} display="grid"
    top={0} left={0} right={0} bottom={0}
  >
    <CircularProgress sx={{ placeSelf: 'center' }} />
  </Box>
}


export default Loadder;