import { Box, Button, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import SlideUp from "../../components/animate/SlideUp";
import CircleShadow from "../CircleShadow";
import Wave from "../Wave";

const Locations = ({ children, sx, ...props }) => {
  return (
    <Stack sx={{ ...sx, position: 'relative' }} {...props}>
      <SlideUp hover={false}>
        <Typography
          color='primary.contrastText'
          sx={{
            textAlign: 'center',
            fontWeight: 600,
            fontSize: 'clamp(1.2rem, 3vw, 2.5rem)',
            pb: 14.5
          }}
        >
          We Have Crew Of Designers, Developers And Consultants
        </Typography>
      </SlideUp>
      <Box
        justifyContent='center'
        display={'grid'}
        gap={10}
        gridTemplateColumns={'repeat(auto-fit, 64px)'}
        px={{ xs: 2, md: 5 }}>
        {
          window.dashboard?.technologys?.map(({ title, icon }) => <SlideUp key={title} hover={false}><Box
            component='img'
            loading='lazy'
            key={title}
            sx={{ placeSelf: 'center' }}
            {...{
              alt: title,
              key: title,
              src: icon,
              width: 64,
              height: 64,
            }}
          />
          </SlideUp>)
        }
      </Box>
      <CircleShadow sx={{
        height: { xs: 288, md: 350 }, mt: 20, backgroundColor: '#0D1328',
        boxShadow: 'none', borderRadius: 0,
      }}>
        <Wave />
        <Box display='grid' width='100%' height='100%' sx={{ position: 'absolute', top: 0, left: 0, }}>
          <Stack sx={{ placeSelf: 'center' }}>
            <SlideUp component={Typography}
              color='primary.contrastText'
              sx={{
                fontWeight: 600,
                textAlign: 'center',
                fontSize: 'clamp(1.2rem, 3vw, 2.5rem)',
              }}
            >
              Have any projects on mind?
            </SlideUp>
            <SlideUp display='grid' mt={2} variants={{ hover: { scale: 1.1 } }}>
              <Button variant="outlined" sx={{
                placeSelf: 'center', px: 4, py: 2, boxShadow: 'none', borderRadius: 0,
              }}
                LinkComponent={Link}
                to='/contact-us'>
                Lets Talk
              </Button>
            </SlideUp>
          </Stack>
        </Box>
      </CircleShadow>
    </Stack>
  )
}
export default Locations