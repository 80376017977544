import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

function createGradient(color1, color2) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

// SETUP COLORS
export const GREY = {
  0: '#000000',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
  A100: alpha('#919EAB', 0.08),
  A200: alpha('#919EAB', 0.12),
  A300: alpha('#919EAB', 0.16),
  A400: alpha('#919EAB', 0.24),
  A500: alpha('#919EAB', 0.32),
  A600: alpha('#919EAB', 0.48),
  A700: alpha('#919EAB', 0.56),
  A800: alpha('#919EAB', 0.8)
};

const PRIMARY = {
  main: '#1B8AF6'
};
const SECONDARY = {
  main: '#3366FF',
};
const INFO = {
  lighter: '#D0F2FF',
  light: '#74CAFF',
  main: '#1890FF',
  dark: '#0C53B7',
  darker: '#04297A',
  contrastText: '#fff'
};
const SUCCESS = {
  lighter: '#E9FCD4',
  light: '#AAF27F',
  main: '#54D62C',
  dark: '#229A16',
  darker: '#08660D',
  contrastText: '#fff'
};
const WARNING = {
  lighter: '#FFF7CD',
  light: '#FFE16A',
  main: '#FFC107',
  dark: '#B78103',
  darker: '#7A4F01',
  contrastText: '#fff'
};
const ERROR = {
  lighter: '#FFE7D9',
  light: '#FFA48D',
  main: '#FF4842',
  dark: '#B72136',
  darker: '#7A0C2E',
  contrastText: '#fff'
};

const GRADIENTS = {
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main)
};

const palette = {
  common: { black: '#000', white: '#fff' },
  primary: { ...PRIMARY },
  secondary: { ...SECONDARY },
  info: { ...INFO },
  success: { ...SUCCESS },
  warning: { ...WARNING },
  error: { ...ERROR },
  grey: GREY,
  gradients: GRADIENTS,
  light: {
    type: 'light',
    mode: 'light',
    divider: GREY[500],
    text: { primary: GREY[800], secondary: GREY[600], disabled: GREY[500] },
    background: { paper: '#0E1630', default: '#0E1630', neutral: GREY[200] },
    action: {
      active: GREY[600],
      hover: GREY['A100'],
      selected: GREY['A300'],
      disabled: GREY['A800'],
      disabledBackground: GREY['A400'],
      focus: GREY['A400'],
      hoverOpacity: 0.08,
      disabledOpacity: 0.48,
      activatedOpacity: 0.12,
    }
  },
  dark: {
    type: 'dark',
    mode: 'dark',
    divider: GREY[700],
    text: { primary: GREY[100], secondary: GREY[200], disabled: GREY[500] },
    background: { paper: '#0E1630', default: '#0E1630', neutral: GREY[500] }
  }
};

export default palette;
export { PRIMARY, SECONDARY }