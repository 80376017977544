import { Box, Stack, Typography } from "@mui/material"
import { motion } from "framer-motion"
import SlideUp from "../../components/animate/SlideUp"
import HeadingTitles from "./HeadingTitles"
const About = ({ children, ...props }) => {
  return <Stack {...props} pt={5}>
    <HeadingTitles title='About us' subtitle='We are more than just an app company' />
    <Stack direction='row' spacing={2.1875} mt={{ xs: 3, md: 8 }} justifyContent={'flex-start'}>
      <Box flexGrow={1} >
        <img loading='lazy' src='https://ik.imagekit.io/grknoi8mz/images/about/about_1.png' alt='about' style={{ width: '100%' }} />
      </Box>
      <Stack flexGrow={1} spacing={2.1875} justifyContent={'space-between'}>
        <Box flexGrow={1}>
          <img loading='lazy' src='https://ik.imagekit.io/grknoi8mz/images/about/about_2.png' alt='about' style={{ height: '100%' }} />
        </Box>
        <Box flexGrow={1} justifyContent='flex-end'>
          <img loading='lazy' src='https://ik.imagekit.io/grknoi8mz/images/about/about_3.png' alt='about' style={{ height: '100%' }} />
        </Box>
      </Stack>
    </Stack>

    <Box
      component={motion.div}
      initial="initial"
      animate="enter"
      exit="exit"
      variants={{ exit: { transition: { staggerChildren: 0.1 } } }}
      display={'grid'} gridTemplateColumns={{ xs: '1fr', md: '1fr 1fr 1fr' }}
      gap={2}
      pt={5}  >
      {
        window.dashboard.aboutus.map((service, index) => (<AboutItem {...{ ...service, index }} key={`work-${index}`} />))
      }
    </Box>
  </Stack>
}
export default About

const AboutItem = ({ index, title, description }) => {
  return (
    <Stack direction={{ xs: 'column', md: 'row' }} spacing={3} {...index > 0 && { pl: 2 }}>
      <SlideUp>
        <Stack pr={2}>
          <Typography
            color='primary.contrastText'
            sx={{
              flexGrow: 1,

              fontWeight: 600,
              mt: 2,
              fontSize: '1.375rem',
              textAlign: { xs: 'center', md: 'left' },
            }}
          >
            {title}
          </Typography>
          <Typography
            sx={{
              flexGrow: 1,
              color: '#808DAD',
              mt: 1,
              fontFamily: 'Inter',
              fontWeight: 400,
              fontSize: '1rem',
              textAlign: { xs: 'center', md: 'left' },
              justifyContent: 'center',
            }}
          >
            {description}
          </Typography>
        </Stack>
      </SlideUp>
      {index < 2 && <Box sx={{ backgroundColor: 'primary.main', width: { xs: '100%', md: 16 }, height: { xs: 4, md: '100%' } }}>
      </Box>}
    </Stack>
  )
}