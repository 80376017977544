
import { Box } from '@mui/material'
import CircleLoading from '../components/animate/CircleLoading'
import { Circle } from './DashboardLayout'
const CircleShadow = ({ children, ...props }) => {
  return <Box {...props} position={'relative'}>
    <Circle
      {...{
        name: 'left',
        color: '#7CF381',
        background: '#7CF381',
        opacity: .8,
      }}
      sx={{
        top: { xs: -80, md: -200 },
        left: { xs: -80, md: -200 },
        height: { xs: 160, md: 400 },
        width: { xs: 160, md: 400 },
        filter: { xs: 'blur(100px)', md: 'blur(248px)' },
        position: 'absolute',
        borderRadius: 0,
        // transform: 'scale(2.4)',
        zIndex: -1,
      }}
    />
    <Circle
      {...{
        name: 'right',
        color: '#676DFF',
        background: '#676DFF',
        position: 'absolute',
        opacity: 1,
      }}
      sx={{
        bottom: { xs: -80, md: -450 / 2 },
        right: { xs: -80, md: -450/2 },
        height: { xs: 160, md: 450 },
        width: { xs: 160, md: 450 },
        filter: { xs: 'blur(80px)', md: 'blur(248px)' },
        position: 'absolute',
        borderRadius: 0,
        zIndex: -1
      }}
    />
    {children}
  </Box>
}
export default CircleShadow