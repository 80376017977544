import { Box, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import SlideUp from "../../components/animate/SlideUp";

const HeadingTitles = ({ title, subtitle, all }) => {
  return (
    <SlideUp hover={false}>
      <Stack >
        <Stack direction={'row'} alignItems={'center'} spacing={5}>
          <Box sx={{ backgroundColor: 'primary.main', width: '73px', height: '6px' }}>
          </Box>
          <Typography
            color='primary.contrastText'
            sx={{
              fontWeight: 600,
              fontSize: '2.5rem',
              lineHeight: '1.1',
            }}>
            {title}
          </Typography>
        </Stack>
        <Stack direction={'row'} alignItems={'center'} spacing={5} mt={3}>
          <Typography
            color='primary'
            sx={{
              flexGrow: 1,
              fontSize: '1.125rem',
              textTransform: 'uppercase'
            }}
          >
            {subtitle}
          </Typography>
          {all && <Typography
            component={Link}
            to={all}
            color='primary'
            sx={{
              fontSize: '1.125rem',
              textTransform: 'uppercase'
            }}>
            View All
          </Typography>}
        </Stack>
      </Stack>
    </SlideUp>
  )
}
export default HeadingTitles;