import { styled } from "@mui/system"
import shadowImage from '../images/cbimage.png';
const G = styled('g')(({ transform }) => ({
  cursor: 'pointer',
  pointerEvents: 'all',
  transition: 'all 0.2s ease-in-out',
  '&:hover': {
    path: {
      transform,
    }
  }
}))

const Map = ({ onClick = e => { },...props }) => {
  return (
    <svg version="1.1"
      width='100%'
      viewBox="0 100 750 270"
      {...props}
      >
      <defs>
        <filter id="dropshadow" height="130%">
          <feGaussianBlur in="SourceAlpha" stdDeviation="3" />
          <feOffset dx="2" dy="2" result="offsetblur" />
          <feMerge>
            <feMergeNode />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
      <g>
        <g>
          {
            paths.map((d, i) => <path fill='#7E8F9A' d={d} key={`path-${i}`} />)
          }
        </g>
        <G transform='scale(1.2) translate3d(-85px, -34px, 0)' onClick={e => {
          onClick(0)
        }}>
          <SvgImage xlinkHref={shadowImage} transform="matrix(1 0 0 1 473 163)" />
          <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="498.4267" y1="185.0392" x2="519.0425" y2="185.0392" gradientTransform="matrix(1 -6.900000e-03 6.900000e-03 1 -1.071 2.3168)">
            <stop offset="0" stopColor='#FFC853' />
            <stop offset="0.4076" stopColor='#FFBB54' />
            <stop offset="0.9945" stopColor='#FFA157' />
          </linearGradient>
          <path
            filter="url(#dropshadow)"
            fill='url(#SVGID_3_)' d="M508.83,167.11c-5.69,0.04-10.28,4.69-10.24,10.38c0.04,5.69,10.47,23.08,10.47,23.08s10.19-17.53,10.15-23.23C519.16,171.66,514.52,167.08,508.83,167.11z M508.93,182.04c-2.73,0.02-4.95-2.18-4.97-4.91c-0.02-2.73,2.18-4.96,4.91-4.97c2.73-0.02,4.95,2.18,4.97,4.91C513.85,179.8,511.66,182.02,508.93,182.04z" />
          <path fill='url(#id-0)' d="M504.09,191.66c2.52,4.85,4.97,8.92,4.97,8.92s7.47-12.86,9.59-20.12C516.3,186.48,509.04,191.74,504.09,191.66z" />
        </G>
        <G transform='scale(1.2) translate3d(-115px, -57px, 0)' onClick={e => {
          onClick(1)
        }}>
          <SvgImage xlinkHref={shadowImage} transform="matrix(1 0 0 1 649 299)" />
          <linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="673.5715" y1="321.7388" x2="694.1874" y2="321.7388" gradientTransform="matrix(1 -6.900000e-03 6.900000e-03 1 -1.071 2.3168)">
            <stop offset="0" stopColor='#8CC6F0' />
            <stop offset="1" stopColor='#8C91F0' />
          </linearGradient>
          <path
            filter="url(#dropshadow)" fill='url(#SVGID_5_)'
            d="M684.91,302.61c-5.69,0.04-10.28,4.69-10.24,10.38c0.04,5.69,10.47,23.08,10.47,23.08s10.19-17.53,10.15-23.23C695.25,307.15,690.61,302.57,684.91,302.61z M685.02,317.53c-2.73,0.02-4.95-2.18-4.97-4.91c-0.02-2.73,2.18-4.96,4.91-4.97c2.73-0.02,4.95,2.18,4.97,4.91C689.94,315.29,687.74,317.52,685.02,317.53z" />
          <path fill='url(#id-1)' d="M680.18,327.15c2.52,4.85,4.97,8.92,4.97,8.92s7.47-12.86,9.59-20.12C692.39,321.97,685.13,327.23,680.18,327.15z" />
        </G>
        <G transform='scale(1.2) translate3d(-32px, -36px, 0)' onClick={e => {
          onClick(2)
        }}>
          <SvgImage xlinkHref={shadowImage} transform="matrix(1 0 0 1 156.3124 177.8976)" />
          <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="182.1211" y1="197.7166" x2="202.737" y2="197.7166" gradientTransform="matrix(1 -6.900000e-03 6.900000e-03 1 -1.071 2.3168)">
            <stop offset="0" stopColor='#FF636E' />
            <stop offset="1" stopColor='#FF406F' />
          </linearGradient>
          <path
            filter="url(#dropshadow)" fill='url(#SVGID_1_)'
            d="M192.61,181.97c-5.69,0.04-10.28,4.69-10.24,10.38c0.04,5.69,10.47,23.08,10.47,23.08s10.19-17.53,10.15-23.23C202.95,186.52,198.3,181.94,192.61,181.97z M192.71,196.9c-2.73,0.02-4.95-2.18-4.97-4.91c-0.02-2.73,2.18-4.96,4.91-4.97c2.73-0.02,4.95,2.18,4.97,4.91C197.63,194.66,195.44,196.88,192.71,196.9z" />
          <path fill='url(#id-2)' d="M187.87,206.52c2.52,4.85,4.97,8.92,4.97,8.92s7.47-12.86,9.59-20.12C200.09,201.34,192.82,206.6,187.87,206.52z" />
        </G>
        {
          gradient.map((path, i) => <linearGradient id={`id-${i}`} key={`g-${i}`} {...path}
            gradientUnits="userSpaceOnUse" gradientTransform="matrix(1 -6.900000e-03 6.900000e-03 1 -1.071 2.3168)">
            <stop offset="0.1518" stopColor='#FFF' />
            <stop offset="0.8912" stopColor='#FFF' stopOpacity={0} />
          </linearGradient>)
        }
      </g>
    </svg>
  )
}
const SvgImage = styled('image')({
  width: 105, height: 95,
  overflow: 'hidden'
})
export default Map;

const gradient = [
  { x1: "504.9583", y1: "199.5909", x2: "516.076", y2: "180.3345" },
  { x1: "680.1031", y1: "336.2904", x2: "691.2208", y2: "317.034" },
  { x1: "188.6527", y1: "212.2683", x2: "199.7704", y2: "193.0119" },
]

const paths = [
  "M200.09,362.39c-0.02,0.63,0.72,1.09,1.65,1.03c0.92-0.06,1.68-0.63,1.7-1.26c0.01-0.63-0.72-1.1-1.64-1.04C200.87,361.19,200.1,361.76,200.09,362.39z",
  "M194.76,362.77c-0.02,0.64,0.72,1.09,1.64,1.03c0.92-0.07,1.68-0.63,1.7-1.26c0.02-0.63-0.71-1.1-1.64-1.03C195.55,361.56,194.78,362.13,194.76,362.77z",
  "M221.45,357.4c-0.02,0.64,0.72,1.11,1.64,1.06c0.92-0.06,1.68-0.62,1.69-1.26c0.01-0.64-0.72-1.12-1.64-1.07C222.23,356.19,221.47,356.76,221.45,357.4z",
  "M194.9,359.14c-0.03,0.65,0.7,1.11,1.62,1.05c0.92-0.06,1.69-0.64,1.71-1.28c0.03-0.65-0.69-1.12-1.61-1.06C195.7,357.91,194.93,358.49,194.9,359.14z",
  "M189.59,359.52c-0.03,0.65,0.69,1.12,1.61,1.05c0.92-0.07,1.69-0.64,1.72-1.29c0.03-0.65-0.69-1.12-1.61-1.06C190.4,358.29,189.62,358.87,189.59,359.52z",
  "M200.38,355.09c-0.04,0.66,0.68,1.14,1.6,1.07c0.92-0.06,1.69-0.64,1.72-1.3c0.03-0.66-0.68-1.14-1.6-1.08C201.19,353.85,200.42,354.43,200.38,355.09z",
  "M195.09,355.45c-0.04,0.66,0.68,1.14,1.6,1.07c0.92-0.06,1.69-0.64,1.73-1.3c0.04-0.66-0.67-1.14-1.59-1.08C195.91,354.21,195.13,354.8,195.09,355.45z",
  "M189.8,355.83c-0.04,0.66,0.67,1.14,1.59,1.07c0.92-0.07,1.69-0.65,1.73-1.31c0.04-0.66-0.67-1.14-1.58-1.08C190.62,354.58,189.84,355.17,189.8,355.83z",
  "M248.2,348.56c-0.02,0.66,0.71,1.16,1.63,1.12c0.92-0.04,1.68-0.62,1.7-1.28c0.02-0.66-0.71-1.17-1.63-1.12C248.99,347.32,248.23,347.9,248.2,348.56z",
  "M242.9,348.83c-0.02,0.66,0.71,1.16,1.62,1.11c0.92-0.05,1.68-0.62,1.7-1.28c0.02-0.66-0.7-1.17-1.62-1.12C243.69,347.59,242.93,348.17,242.9,348.83z",
  "M237.61,349.11c-0.03,0.66,0.7,1.16,1.62,1.11c0.92-0.05,1.68-0.62,1.71-1.29c0.02-0.66-0.7-1.17-1.62-1.12C238.4,347.86,237.63,348.44,237.61,349.11z",
  "M205.88,350.99c-0.04,0.67,0.67,1.16,1.59,1.1c0.92-0.06,1.69-0.64,1.73-1.31c0.04-0.67-0.67-1.16-1.58-1.1C206.7,349.73,205.93,350.32,205.88,350.99z",
  "M200.61,351.34c-0.05,0.67,0.66,1.16,1.58,1.1c0.92-0.06,1.69-0.65,1.73-1.32c0.04-0.67-0.66-1.16-1.57-1.1C201.43,350.08,200.65,350.67,200.61,351.34z",
  "M195.34,351.7c-0.05,0.67,0.66,1.16,1.57,1.09c0.92-0.06,1.69-0.65,1.74-1.32c0.05-0.67-0.65-1.16-1.57-1.1C196.17,350.44,195.38,351.03,195.34,351.7z",
  "M190.07,352.07c-0.05,0.67,0.65,1.16,1.56,1.09c0.92-0.07,1.69-0.66,1.74-1.33c0.05-0.67-0.65-1.16-1.56-1.1C190.9,350.81,190.12,351.4,190.07,352.07z",
  "M243.06,345.05c-0.03,0.68,0.69,1.18,1.61,1.13c0.92-0.05,1.68-0.63,1.71-1.3c0.03-0.67-0.69-1.19-1.6-1.14C243.86,343.78,243.09,344.37,243.06,345.05z",
  "M237.78,345.32c-0.03,0.68,0.68,1.18,1.6,1.13c0.92-0.05,1.68-0.63,1.72-1.31c0.03-0.67-0.68-1.19-1.6-1.14C238.58,344.06,237.81,344.65,237.78,345.32z",
  "M232.49,345.61c-0.04,0.68,0.68,1.18,1.6,1.13c0.92-0.05,1.69-0.63,1.72-1.31c0.03-0.68-0.68-1.19-1.59-1.14C233.3,344.34,232.53,344.93,232.49,345.61z",
  "M206.15,347.19c-0.05,0.68,0.65,1.18,1.56,1.12c0.91-0.06,1.69-0.65,1.74-1.33c0.05-0.68-0.65-1.18-1.56-1.12C206.98,345.91,206.2,346.51,206.15,347.19z",
  "M200.89,347.53c-0.05,0.68,0.64,1.18,1.56,1.12c0.91-0.06,1.69-0.66,1.75-1.33c0.05-0.68-0.64-1.18-1.55-1.12C201.73,346.25,200.95,346.86,200.89,347.53z",
  "M195.64,347.89c-0.06,0.68,0.64,1.18,1.55,1.11c0.91-0.06,1.69-0.66,1.75-1.34c0.06-0.68-0.63-1.18-1.54-1.12C196.48,346.61,195.7,347.21,195.64,347.89z",
  "M190.39,348.26c-0.06,0.68,0.63,1.18,1.54,1.11c0.91-0.06,1.7-0.66,1.76-1.34c0.06-0.68-0.63-1.18-1.54-1.12C191.24,346.97,190.45,347.58,190.39,348.26z",
  "M206.46,343.33c-0.06,0.69,0.63,1.2,1.54,1.14c0.91-0.06,1.69-0.66,1.75-1.35c0.06-0.69-0.63-1.2-1.54-1.14C207.31,342.03,206.52,342.64,206.46,343.33z",
  "M201.23,343.67c-0.06,0.69,0.62,1.2,1.53,1.14c0.91-0.06,1.69-0.66,1.76-1.35c0.06-0.69-0.62-1.2-1.53-1.14C202.08,342.37,201.29,342.98,201.23,343.67z",
  "M195.99,344.02c-0.07,0.69,0.62,1.19,1.52,1.13c0.91-0.06,1.7-0.67,1.76-1.36c0.07-0.69-0.61-1.2-1.52-1.14C196.85,342.72,196.06,343.33,195.99,344.02z",
  "M190.77,344.39c-0.07,0.69,0.61,1.19,1.52,1.13c0.91-0.06,1.7-0.67,1.77-1.36c0.07-0.69-0.61-1.2-1.51-1.14C191.63,343.08,190.84,343.7,190.77,344.39z",
  "M680.92,350.63c0.27,0.74,1.19,1.41,2.04,1.51c0.85,0.1,1.32-0.42,1.04-1.16c-0.28-0.74-1.2-1.41-2.05-1.51C681.11,349.37,680.65,349.9,680.92,350.63z",
  "M676,350.09c0.27,0.73,1.17,1.4,2.03,1.5c0.86,0.09,1.33-0.42,1.06-1.16c-0.27-0.74-1.18-1.41-2.03-1.5C676.21,348.83,675.73,349.35,676,350.09z",
  "M671.07,349.55c0.26,0.73,1.16,1.4,2.02,1.49c0.86,0.09,1.34-0.42,1.08-1.16c-0.26-0.73-1.17-1.41-2.02-1.5C671.29,348.3,670.81,348.82,671.07,349.55z",
  "M666.12,349.02c0.25,0.73,1.14,1.4,2.01,1.49c0.86,0.09,1.36-0.42,1.1-1.16c-0.25-0.73-1.15-1.4-2.01-1.49C666.36,347.77,665.87,348.29,666.12,349.02z",
  "M661.16,348.5c0.24,0.73,1.13,1.39,1.99,1.48c0.86,0.09,1.37-0.43,1.12-1.16c-0.25-0.73-1.14-1.4-2-1.49C661.42,347.25,660.92,347.77,661.16,348.5z",
  "M238.24,337.57c-0.05,0.7,0.65,1.22,1.56,1.17c0.91-0.05,1.69-0.65,1.73-1.34c0.05-0.7-0.65-1.23-1.56-1.18C239.07,336.27,238.29,336.87,238.24,337.57z",
  "M212.05,339.07c-0.07,0.7,0.62,1.21,1.53,1.16c0.91-0.06,1.69-0.66,1.76-1.36c0.06-0.7-0.62-1.22-1.52-1.17C212.91,337.76,212.12,338.38,212.05,339.07z",
  "M206.83,339.4c-0.07,0.7,0.61,1.21,1.52,1.16c0.91-0.06,1.69-0.67,1.76-1.37c0.07-0.7-0.61-1.22-1.51-1.16C207.69,338.09,206.9,338.71,206.83,339.4z",
  "M201.61,339.75c-0.07,0.7,0.6,1.21,1.51,1.15c0.91-0.06,1.69-0.67,1.77-1.37c0.07-0.7-0.6-1.22-1.51-1.16C202.48,338.43,201.69,339.05,201.61,339.75z",
  "M196.4,340.1c-0.08,0.7,0.6,1.21,1.5,1.15c0.91-0.06,1.7-0.67,1.77-1.37c0.07-0.7-0.59-1.22-1.5-1.16C197.28,338.78,196.48,339.4,196.4,340.1z",
  "M191.2,340.46c-0.08,0.7,0.59,1.21,1.49,1.15c0.9-0.06,1.7-0.68,1.78-1.38c0.08-0.7-0.59-1.22-1.49-1.16C192.08,339.14,191.28,339.76,191.2,340.46z",
  "M684.2,347c0.29,0.74,1.21,1.42,2.05,1.52c0.84,0.1,1.29-0.43,0.99-1.17c-0.3-0.74-1.22-1.43-2.06-1.52C684.35,345.72,683.91,346.25,684.2,347z",
  "M679.34,346.45c0.28,0.74,1.2,1.42,2.04,1.51c0.85,0.09,1.3-0.43,1.01-1.17c-0.29-0.74-1.21-1.42-2.05-1.52C679.51,345.18,679.06,345.71,679.34,346.45z",
  "M674.47,345.92c0.27,0.74,1.18,1.41,2.03,1.51c0.85,0.09,1.31-0.43,1.03-1.17c-0.28-0.74-1.19-1.42-2.03-1.51C674.66,344.65,674.2,345.18,674.47,345.92z",
  "M669.59,345.39c0.27,0.74,1.17,1.41,2.02,1.5c0.85,0.09,1.32-0.43,1.05-1.17c-0.27-0.74-1.18-1.42-2.02-1.51C669.79,344.13,669.32,344.65,669.59,345.39z",
  "M664.69,344.87c0.26,0.74,1.15,1.41,2.01,1.5c0.85,0.09,1.33-0.43,1.07-1.17c-0.26-0.74-1.17-1.41-2.01-1.5C664.91,343.61,664.43,344.13,664.69,344.87z",
  "M659.78,344.36c0.25,0.74,1.14,1.4,2,1.49c0.85,0.09,1.34-0.43,1.09-1.17c-0.26-0.74-1.15-1.41-2-1.5C660.02,343.09,659.53,343.62,659.78,344.36z",
  "M654.86,343.85c0.24,0.73,1.13,1.4,1.99,1.49c0.86,0.09,1.36-0.43,1.11-1.17c-0.25-0.73-1.14-1.4-1.99-1.49C655.11,342.59,654.61,343.12,654.86,343.85z",
  "M217.65,334.78c-0.07,0.71,0.61,1.23,1.51,1.18c0.91-0.05,1.69-0.67,1.76-1.37c0.07-0.71-0.61-1.24-1.51-1.19C218.52,333.46,217.73,334.08,217.65,334.78z",
  "M212.45,335.1c-0.07,0.71,0.6,1.23,1.51,1.18c0.9-0.06,1.69-0.67,1.76-1.38c0.07-0.71-0.6-1.24-1.5-1.18C213.32,333.77,212.52,334.39,212.45,335.1z",
  "M207.25,335.43c-0.08,0.71,0.59,1.23,1.5,1.17c0.9-0.06,1.69-0.67,1.77-1.38c0.08-0.71-0.59-1.24-1.49-1.18C208.12,334.1,207.32,334.72,207.25,335.43z",
  "M202.05,335.77c-0.08,0.71,0.59,1.23,1.49,1.17c0.9-0.06,1.7-0.68,1.77-1.39c0.08-0.71-0.58-1.24-1.48-1.18C202.93,334.44,202.13,335.06,202.05,335.77z",
  "M196.86,336.12c-0.09,0.71,0.58,1.23,1.48,1.17c0.9-0.06,1.7-0.68,1.78-1.39c0.08-0.71-0.57-1.24-1.47-1.18C197.75,334.78,196.95,335.41,196.86,336.12z",
  "M191.68,336.47c-0.09,0.71,0.57,1.23,1.47,1.17c0.9-0.06,1.7-0.68,1.79-1.39c0.09-0.71-0.57-1.24-1.46-1.17C192.58,335.14,191.77,335.77,191.68,336.47z",
  "M687.31,343.32c0.31,0.75,1.23,1.43,2.06,1.53c0.83,0.1,1.25-0.44,0.94-1.19c-0.32-0.75-1.24-1.44-2.07-1.53C687.42,342.03,687,342.57,687.31,343.32z",
  "M682.52,342.78c0.3,0.75,1.22,1.43,2.05,1.52c0.83,0.09,1.26-0.44,0.96-1.19c-0.31-0.75-1.23-1.44-2.06-1.53C682.65,341.49,682.22,342.03,682.52,342.78z",
  "M677.72,342.24c0.29,0.75,1.2,1.43,2.04,1.52c0.84,0.09,1.28-0.44,0.98-1.19c-0.3-0.75-1.21-1.43-2.05-1.52C677.86,340.96,677.42,341.5,677.72,342.24z",
  "M672.9,341.72c0.28,0.75,1.19,1.42,2.03,1.51c0.84,0.09,1.29-0.44,1-1.18c-0.29-0.75-1.2-1.43-2.03-1.52C673.06,340.44,672.61,340.97,672.9,341.72z",
  "M668.06,341.2c0.28,0.74,1.18,1.42,2.02,1.51c0.84,0.09,1.3-0.44,1.02-1.18c-0.28-0.75-1.19-1.42-2.02-1.51C668.24,339.92,667.78,340.45,668.06,341.2z",
  "M663.21,340.69c0.27,0.74,1.16,1.42,2.01,1.5c0.84,0.09,1.31-0.44,1.04-1.18c-0.27-0.74-1.18-1.42-2.01-1.51C663.4,339.41,662.94,339.94,663.21,340.69z",
  "M658.34,340.18c0.26,0.74,1.15,1.41,2,1.5c0.85,0.09,1.32-0.44,1.06-1.18c-0.26-0.74-1.16-1.42-2-1.5C658.56,338.91,658.09,339.44,658.34,340.18z",
  "M653.47,339.68c0.25,0.74,1.14,1.41,1.99,1.5c0.85,0.09,1.33-0.44,1.08-1.18c-0.26-0.74-1.15-1.41-1.99-1.5C653.69,338.41,653.21,338.94,653.47,339.68z",
  "M613.94,335.98c0.19,0.73,1.05,1.38,1.92,1.46c0.87,0.08,1.41-0.45,1.21-1.19c-0.2-0.73-1.06-1.39-1.92-1.46C614.29,334.71,613.75,335.24,613.94,335.98z",
  "M223.27,330.45c-0.07,0.72,0.6,1.25,1.5,1.2c0.9-0.05,1.69-0.67,1.76-1.39c0.07-0.72-0.6-1.26-1.5-1.21C224.13,329.11,223.34,329.74,223.27,330.45z",
  "M218.08,330.76c-0.08,0.72,0.59,1.25,1.49,1.2c0.9-0.05,1.69-0.67,1.77-1.39c0.08-0.72-0.59-1.26-1.49-1.2C218.95,329.42,218.15,330.04,218.08,330.76z",
  "M212.89,331.08c-0.08,0.72,0.58,1.25,1.48,1.19c0.9-0.06,1.69-0.68,1.77-1.39c0.08-0.72-0.58-1.26-1.48-1.2C213.77,329.73,212.97,330.36,212.89,331.08z",
  "M207.71,331.4c-0.09,0.72,0.57,1.25,1.47,1.19c0.9-0.06,1.69-0.68,1.78-1.4c0.08-0.72-0.57-1.25-1.47-1.2C208.6,330.06,207.8,330.69,207.71,331.4z",
  "M202.54,331.74c-0.09,0.72,0.57,1.25,1.46,1.19c0.9-0.06,1.7-0.69,1.78-1.4c0.09-0.72-0.56-1.25-1.46-1.2C203.43,330.39,202.63,331.02,202.54,331.74z",
  "M197.38,332.09c-0.1,0.72,0.56,1.25,1.45,1.19c0.9-0.06,1.7-0.69,1.79-1.41c0.09-0.72-0.56-1.25-1.45-1.19C198.28,330.73,197.47,331.37,197.38,332.09z",
  "M192.22,332.44c-0.1,0.72,0.55,1.25,1.44,1.18c0.9-0.06,1.7-0.69,1.79-1.41c0.1-0.72-0.55-1.25-1.44-1.19C193.12,331.09,192.32,331.72,192.22,332.44z",
  "M685.53,339.06c0.32,0.76,1.24,1.44,2.06,1.54c0.82,0.09,1.23-0.44,0.9-1.2c-0.32-0.76-1.25-1.45-2.07-1.54C685.62,337.76,685.22,338.3,685.53,339.06z",
  "M680.8,338.53c0.31,0.75,1.23,1.44,2.05,1.53c0.83,0.09,1.24-0.44,0.93-1.2c-0.32-0.76-1.24-1.44-2.06-1.53C680.9,337.23,680.49,337.77,680.8,338.53z",
  "M676.04,338c0.3,0.75,1.21,1.44,2.04,1.53c0.83,0.09,1.25-0.44,0.95-1.2c-0.31-0.75-1.22-1.44-2.05-1.53C676.16,336.71,675.74,337.25,676.04,338z",
  "M671.27,337.48c0.29,0.75,1.2,1.43,2.03,1.52c0.83,0.09,1.26-0.44,0.97-1.2c-0.3-0.75-1.21-1.44-2.04-1.53C671.41,336.19,670.98,336.73,671.27,337.48z",
  "M666.48,336.97c0.28,0.75,1.19,1.43,2.02,1.52c0.83,0.09,1.28-0.45,0.99-1.2c-0.29-0.75-1.2-1.43-2.03-1.52C666.64,335.68,666.2,336.22,666.48,336.97z",
  "M661.68,336.47c0.27,0.75,1.17,1.43,2.01,1.51c0.84,0.09,1.29-0.45,1.01-1.2c-0.28-0.75-1.18-1.43-2.02-1.52C661.85,335.18,661.4,335.72,661.68,336.47z",
  "M656.86,335.97c0.27,0.75,1.16,1.42,2,1.51c0.84,0.09,1.3-0.45,1.03-1.2c-0.27-0.75-1.17-1.43-2.01-1.51C657.05,334.68,656.59,335.22,656.86,335.97z",
  "M652.02,335.48c0.26,0.75,1.15,1.42,1.99,1.5c0.84,0.09,1.31-0.45,1.05-1.2c-0.27-0.75-1.16-1.42-2-1.51C652.23,334.19,651.76,334.73,652.02,335.48z",
  "M647.17,334.99c0.25,0.75,1.14,1.42,1.98,1.5c0.84,0.08,1.32-0.45,1.06-1.2c-0.26-0.75-1.15-1.42-1.99-1.5C647.39,333.71,646.92,334.25,647.17,334.99z",
  "M642.31,334.52c0.25,0.74,1.13,1.41,1.97,1.5c0.85,0.08,1.33-0.45,1.08-1.2c-0.25-0.75-1.14-1.42-1.98-1.5C642.54,333.23,642.06,333.77,642.31,334.52z",
  "M632.53,333.59c0.23,0.74,1.1,1.41,1.96,1.49c0.85,0.08,1.35-0.46,1.12-1.2c-0.24-0.74-1.12-1.41-1.96-1.49C632.8,332.31,632.3,332.85,632.53,333.59z",
  "M627.63,333.13c0.22,0.74,1.09,1.4,1.95,1.48c0.85,0.08,1.36-0.46,1.14-1.2c-0.23-0.74-1.1-1.41-1.95-1.49C627.91,331.85,627.4,332.39,627.63,333.13z",
  "M622.7,332.69c0.22,0.74,1.08,1.4,1.94,1.48c0.86,0.08,1.37-0.46,1.15-1.2c-0.22-0.74-1.09-1.41-1.94-1.48C623,331.41,622.49,331.95,622.7,332.69z",
  "M617.77,332.25c0.21,0.74,1.07,1.4,1.93,1.48c0.86,0.08,1.38-0.46,1.17-1.2c-0.21-0.74-1.08-1.4-1.94-1.48C618.08,330.97,617.56,331.51,617.77,332.25z",
  "M612.82,331.82c0.2,0.74,1.06,1.4,1.92,1.47c0.86,0.08,1.39-0.46,1.18-1.2c-0.21-0.74-1.07-1.4-1.93-1.48C613.15,330.54,612.62,331.08,612.82,331.82z",
  "M228.89,326.08c-0.08,0.72,0.59,1.27,1.49,1.22c0.9-0.05,1.69-0.67,1.77-1.4c0.08-0.72-0.59-1.27-1.49-1.22C229.76,324.73,228.97,325.36,228.89,326.08z",
  "M223.71,326.38c-0.08,0.72,0.58,1.27,1.48,1.22c0.9-0.05,1.69-0.68,1.77-1.4c0.08-0.72-0.58-1.27-1.48-1.22C224.59,325.02,223.79,325.65,223.71,326.38z",
  "M218.54,326.69c-0.09,0.73,0.57,1.27,1.47,1.21c0.9-0.05,1.69-0.68,1.78-1.41c0.08-0.73-0.57-1.27-1.47-1.22C219.43,325.33,218.63,325.96,218.54,326.69z",
  "M213.38,327c-0.09,0.73,0.56,1.27,1.46,1.21c0.9-0.06,1.69-0.68,1.78-1.41c0.09-0.72-0.56-1.27-1.46-1.22C214.27,325.64,213.47,326.28,213.38,327z",
  "M208.22,327.33c-0.1,0.73,0.56,1.27,1.45,1.21c0.9-0.06,1.69-0.69,1.79-1.41c0.09-0.73-0.55-1.27-1.45-1.21C209.12,325.96,208.32,326.6,208.22,327.33z",
  "M203.08,327.66c-0.1,0.73,0.55,1.26,1.44,1.21c0.89-0.06,1.7-0.69,1.79-1.42c0.1-0.73-0.55-1.27-1.44-1.21C203.98,326.3,203.18,326.93,203.08,327.66z",
  "M197.94,328c-0.1,0.73,0.54,1.26,1.43,1.2c0.89-0.06,1.7-0.69,1.8-1.42c0.1-0.73-0.54-1.27-1.43-1.21C198.85,326.64,198.04,327.28,197.94,328z",
  "M683.71,334.77c0.33,0.76,1.25,1.45,2.06,1.54c0.81,0.09,1.21-0.45,0.87-1.21c-0.33-0.76-1.26-1.45-2.06-1.55C683.78,333.47,683.39,334.01,683.71,334.77z",
  "M679.02,334.25c0.32,0.76,1.23,1.45,2.05,1.54c0.82,0.09,1.22-0.45,0.9-1.21c-0.32-0.76-1.24-1.45-2.05-1.54C679.11,332.95,678.71,333.49,679.02,334.25z",
  "M674.32,333.73c0.31,0.76,1.22,1.44,2.04,1.53c0.82,0.09,1.23-0.45,0.92-1.21c-0.31-0.76-1.23-1.45-2.05-1.54C674.42,332.43,674.01,332.97,674.32,333.73z",
  "M669.59,333.22c0.3,0.76,1.21,1.44,2.03,1.53c0.82,0.09,1.24-0.45,0.94-1.21c-0.31-0.76-1.22-1.44-2.04-1.53C669.71,331.92,669.29,332.46,669.59,333.22z",
  "M664.85,332.72c0.29,0.76,1.2,1.44,2.02,1.53c0.83,0.09,1.25-0.45,0.96-1.21c-0.3-0.76-1.21-1.44-2.03-1.53C664.98,331.42,664.56,331.96,664.85,332.72z",
  "M660.09,332.22c0.28,0.75,1.19,1.43,2.01,1.52c0.83,0.09,1.27-0.45,0.98-1.21c-0.29-0.76-1.2-1.44-2.02-1.52C660.24,330.92,659.81,331.46,660.09,332.22z",
  "M655.32,331.73c0.28,0.75,1.17,1.43,2,1.52c0.83,0.09,1.28-0.45,1-1.21c-0.28-0.75-1.18-1.44-2.01-1.52C655.48,330.43,655.04,330.97,655.32,331.73z",
  "M650.53,331.24c0.27,0.75,1.16,1.43,2,1.51c0.83,0.08,1.29-0.46,1.02-1.21c-0.28-0.75-1.17-1.43-2-1.52C650.71,329.94,650.26,330.49,650.53,331.24z",
  "M645.72,330.76c0.26,0.75,1.15,1.43,1.99,1.51c0.84,0.08,1.3-0.46,1.03-1.21c-0.27-0.75-1.16-1.43-1.99-1.51C645.92,329.47,645.46,330.01,645.72,330.76z",
  "M640.89,330.29c0.25,0.75,1.14,1.42,1.98,1.51c0.84,0.08,1.31-0.46,1.05-1.21c-0.26-0.75-1.15-1.43-1.98-1.51C641.11,329,640.64,329.54,640.89,330.29z",
  "M636.06,329.83c0.25,0.75,1.13,1.42,1.97,1.5c0.84,0.08,1.32-0.46,1.07-1.21c-0.25-0.75-1.14-1.43-1.97-1.51C636.29,328.53,635.81,329.08,636.06,329.83z",
  "M631.2,329.37c0.24,0.75,1.12,1.42,1.96,1.5c0.84,0.08,1.33-0.46,1.09-1.21c-0.24-0.75-1.13-1.42-1.97-1.5C631.45,328.08,630.96,328.62,631.2,329.37z",
  "M626.33,328.92c0.23,0.75,1.11,1.42,1.95,1.49c0.85,0.08,1.34-0.46,1.11-1.21c-0.24-0.75-1.12-1.42-1.96-1.5C626.59,327.63,626.1,328.17,626.33,328.92z",
  "M621.45,328.48c0.23,0.75,1.1,1.41,1.95,1.49c0.85,0.08,1.35-0.47,1.12-1.21c-0.23-0.75-1.11-1.42-1.95-1.49C621.73,327.19,621.23,327.73,621.45,328.48z",
  "M616.55,328.05c0.22,0.75,1.09,1.41,1.94,1.49c0.85,0.08,1.36-0.47,1.14-1.21c-0.22-0.75-1.09-1.42-1.94-1.49C616.84,326.75,616.33,327.3,616.55,328.05z",
  "M611.64,327.62c0.21,0.75,1.08,1.41,1.93,1.48c0.85,0.07,1.37-0.47,1.16-1.22c-0.22-0.75-1.08-1.41-1.93-1.49C611.94,326.33,611.43,326.87,611.64,327.62z",
  "M401.74,317.78c0.03,0.73,0.79,1.33,1.7,1.34c0.91,0.01,1.62-0.58,1.59-1.31c-0.03-0.73-0.79-1.34-1.7-1.34C402.43,316.46,401.72,317.05,401.74,317.78z",
  "M234.51,321.68c-0.08,0.73,0.58,1.29,1.48,1.24c0.9-0.05,1.69-0.68,1.77-1.41c0.08-0.73-0.58-1.29-1.47-1.24C235.39,320.31,234.6,320.94,234.51,321.68z",
  "M229.35,321.96c-0.09,0.73,0.57,1.28,1.47,1.23c0.9-0.05,1.69-0.68,1.77-1.41c0.08-0.73-0.57-1.29-1.47-1.24C230.24,320.59,229.44,321.23,229.35,321.96z",
  "M224.2,322.26c-0.09,0.73,0.56,1.28,1.46,1.23c0.9-0.05,1.69-0.68,1.78-1.42c0.09-0.73-0.56-1.29-1.46-1.24C225.09,320.89,224.29,321.52,224.2,322.26z",
  "M219.05,322.56c-0.09,0.73,0.55,1.28,1.45,1.23c0.89-0.05,1.69-0.69,1.78-1.42c0.09-0.73-0.55-1.29-1.45-1.23C219.95,321.19,219.15,321.83,219.05,322.56z",
  "M213.92,322.88c-0.1,0.73,0.55,1.28,1.44,1.23c0.89-0.05,1.69-0.69,1.79-1.42c0.1-0.73-0.55-1.29-1.44-1.23C214.82,321.5,214.01,322.14,213.92,322.88z",
  "M208.79,323.2c-0.1,0.73,0.54,1.28,1.43,1.22c0.89-0.06,1.69-0.69,1.79-1.43c0.1-0.73-0.54-1.29-1.42-1.23C209.69,321.82,208.89,322.47,208.79,323.2z",
  "M203.66,323.53c-0.11,0.73,0.53,1.28,1.42,1.22c0.89-0.06,1.69-0.7,1.8-1.43c0.1-0.73-0.53-1.28-1.41-1.23C204.58,322.16,203.77,322.8,203.66,323.53z",
  "M198.55,323.88c-0.11,0.73,0.52,1.28,1.41,1.22c0.89-0.06,1.7-0.7,1.8-1.43c0.11-0.73-0.52-1.28-1.4-1.22C199.47,322.49,198.66,323.14,198.55,323.88z",
  "M681.85,330.46c0.33,0.76,1.25,1.46,2.06,1.55c0.81,0.09,1.18-0.45,0.84-1.22c-0.34-0.77-1.26-1.46-2.06-1.55C681.89,329.15,681.51,329.7,681.85,330.46z",
  "M677.21,329.94c0.32,0.76,1.24,1.45,2.05,1.54c0.81,0.09,1.2-0.46,0.87-1.22c-0.33-0.76-1.25-1.46-2.05-1.55C677.27,328.63,676.88,329.18,677.21,329.94z",
  "M672.55,329.43c0.32,0.76,1.23,1.45,2.04,1.54c0.81,0.09,1.21-0.46,0.89-1.22c-0.32-0.76-1.24-1.46-2.05-1.54C672.62,328.12,672.23,328.67,672.55,329.43z",
  "M667.87,328.93c0.31,0.76,1.22,1.45,2.03,1.54c0.82,0.09,1.22-0.46,0.91-1.22c-0.31-0.76-1.23-1.45-2.04-1.54C667.96,327.62,667.56,328.17,667.87,328.93z",
  "M663.17,328.43c0.3,0.76,1.21,1.45,2.02,1.53c0.82,0.09,1.23-0.46,0.93-1.22c-0.31-0.76-1.22-1.45-2.03-1.54C663.28,327.12,662.87,327.67,663.17,328.43z",
  "M658.46,327.94c0.29,0.76,1.19,1.44,2.02,1.53c0.82,0.09,1.25-0.46,0.95-1.22c-0.3-0.76-1.2-1.45-2.02-1.53C658.59,326.63,658.16,327.18,658.46,327.94z",
  "M653.73,327.45c0.29,0.76,1.18,1.44,2.01,1.53c0.82,0.08,1.26-0.46,0.97-1.22c-0.29-0.76-1.19-1.44-2.01-1.53C653.87,326.14,653.44,326.69,653.73,327.45z",
  "M648.98,326.97c0.28,0.76,1.17,1.44,2,1.52c0.83,0.08,1.27-0.46,0.99-1.22c-0.28-0.76-1.18-1.44-2-1.53C649.14,325.66,648.7,326.21,648.98,326.97z",
  "M644.21,326.5c0.27,0.76,1.16,1.44,1.99,1.52c0.83,0.08,1.28-0.46,1-1.22c-0.28-0.76-1.17-1.44-1.99-1.52C644.39,325.19,643.94,325.74,644.21,326.5z",
  "M639.43,326.03c0.26,0.76,1.15,1.43,1.98,1.51c0.83,0.08,1.29-0.47,1.02-1.22c-0.27-0.76-1.16-1.44-1.99-1.52C639.62,324.73,639.17,325.28,639.43,326.03z",
  "M634.63,325.57c0.26,0.76,1.14,1.43,1.97,1.51c0.84,0.08,1.3-0.47,1.04-1.22c-0.26-0.76-1.15-1.44-1.98-1.51C634.84,324.27,634.38,324.82,634.63,325.57z",
  "M629.82,325.12c0.25,0.76,1.13,1.43,1.97,1.51c0.84,0.08,1.31-0.47,1.06-1.22c-0.25-0.76-1.14-1.43-1.97-1.51C630.04,323.82,629.57,324.37,629.82,325.12z",
  "M624.99,324.68c0.24,0.75,1.12,1.43,1.96,1.5c0.84,0.08,1.32-0.47,1.08-1.22c-0.25-0.75-1.13-1.43-1.96-1.51C625.23,323.37,624.75,323.92,624.99,324.68z",
  "M620.15,324.24c0.24,0.75,1.11,1.42,1.95,1.5c0.84,0.08,1.33-0.47,1.09-1.23c-0.24-0.75-1.12-1.43-1.95-1.5C620.4,322.93,619.91,323.48,620.15,324.24z",
  "M615.29,323.81c0.23,0.75,1.1,1.42,1.94,1.5c0.85,0.07,1.34-0.47,1.11-1.23c-0.23-0.75-1.11-1.43-1.95-1.5C615.55,322.5,615.06,323.06,615.29,323.81z",
  "M610.41,323.39c0.22,0.75,1.09,1.42,1.94,1.49c0.85,0.07,1.35-0.48,1.13-1.23c-0.23-0.75-1.1-1.42-1.94-1.5C610.69,322.08,610.19,322.63,610.41,323.39z",
  "M605.52,322.97c0.22,0.75,1.08,1.42,1.93,1.49c0.85,0.07,1.36-0.48,1.14-1.23c-0.22-0.75-1.09-1.42-1.93-1.49C605.82,321.67,605.31,322.22,605.52,322.97z",
  "M417.23,313.78c0.04,0.74,0.81,1.35,1.71,1.36c0.91,0.01,1.61-0.58,1.56-1.32c-0.04-0.74-0.81-1.36-1.71-1.37C417.89,312.45,417.19,313.04,417.23,313.78z",
  "M412.02,313.72c0.04,0.74,0.8,1.35,1.71,1.36c0.91,0.01,1.61-0.58,1.57-1.32c-0.04-0.74-0.8-1.35-1.71-1.37C412.68,312.38,411.98,312.97,412.02,313.72z",
  "M406.8,313.66c0.03,0.74,0.79,1.35,1.7,1.36c0.91,0.01,1.61-0.58,1.58-1.32c-0.04-0.74-0.8-1.35-1.7-1.36C407.47,312.32,406.77,312.92,406.8,313.66z",
  "M401.58,313.61c0.03,0.74,0.79,1.35,1.7,1.35c0.91,0.01,1.62-0.58,1.58-1.33c-0.03-0.74-0.79-1.35-1.7-1.36C402.26,312.27,401.55,312.87,401.58,313.61z",
  "M396.36,313.58c0.03,0.74,0.78,1.34,1.69,1.35c0.91,0.01,1.62-0.59,1.59-1.33c-0.03-0.74-0.79-1.35-1.69-1.36C397.04,312.24,396.33,312.84,396.36,313.58z",
  "M391.13,313.55c0.02,0.74,0.78,1.34,1.68,1.35c0.91,0,1.62-0.59,1.6-1.33c-0.02-0.74-0.78-1.35-1.68-1.35C391.82,312.21,391.11,312.81,391.13,313.55z",
  "M240.14,317.23c-0.08,0.74,0.57,1.3,1.47,1.25c0.89-0.05,1.68-0.68,1.77-1.42c0.08-0.74-0.57-1.31-1.46-1.26C241.02,315.85,240.22,316.49,240.14,317.23z",
  "M235,317.51c-0.09,0.74,0.57,1.3,1.46,1.25c0.89-0.05,1.69-0.69,1.77-1.43c0.09-0.74-0.56-1.31-1.45-1.26C235.88,316.13,235.09,316.77,235,317.51z",
  "M229.86,317.79c-0.09,0.74,0.56,1.3,1.45,1.25c0.89-0.05,1.69-0.69,1.78-1.43c0.09-0.74-0.56-1.3-1.44-1.25C230.75,316.41,229.95,317.05,229.86,317.79z",
  "M224.73,318.09c-0.1,0.74,0.55,1.3,1.44,1.25c0.89-0.05,1.69-0.69,1.78-1.43c0.09-0.74-0.55-1.3-1.43-1.25C225.63,316.7,224.83,317.35,224.73,318.09z",
  "M219.61,318.39c-0.1,0.74,0.54,1.3,1.43,1.24c0.89-0.05,1.69-0.69,1.79-1.44c0.1-0.74-0.54-1.3-1.42-1.25C220.51,317.01,219.71,317.65,219.61,318.39z",
  "M214.5,318.71c-0.11,0.74,0.53,1.3,1.42,1.24c0.89-0.06,1.69-0.7,1.79-1.44c0.1-0.74-0.53-1.3-1.41-1.25C215.41,317.32,214.6,317.97,214.5,318.71z",
  "M209.39,319.03c-0.11,0.74,0.52,1.29,1.41,1.24c0.89-0.06,1.69-0.7,1.8-1.44c0.11-0.74-0.52-1.3-1.4-1.24C210.31,317.64,209.5,318.29,209.39,319.03z",
  "M204.29,319.36c-0.12,0.74,0.51,1.29,1.39,1.24c0.89-0.06,1.69-0.7,1.81-1.45c0.11-0.74-0.51-1.3-1.39-1.24C205.22,317.97,204.41,318.62,204.29,319.36z",
  "M199.21,319.7c-0.12,0.74,0.5,1.29,1.38,1.23c0.88-0.06,1.69-0.71,1.81-1.45c0.12-0.74-0.5-1.3-1.38-1.24C200.14,318.31,199.33,318.96,199.21,319.7z",
  "M194.13,320.05c-0.13,0.74,0.49,1.29,1.37,1.23c0.88-0.06,1.69-0.71,1.82-1.45c0.12-0.74-0.49-1.3-1.37-1.24C195.07,318.66,194.25,319.31,194.13,320.05z",
  "M679.94,326.13c0.34,0.77,1.26,1.46,2.06,1.55c0.8,0.09,1.16-0.46,0.82-1.23c-0.35-0.77-1.27-1.47-2.06-1.56C679.96,324.8,679.6,325.36,679.94,326.13z",
  "M670.73,325.11c0.32,0.77,1.24,1.46,2.04,1.55c0.8,0.09,1.19-0.46,0.86-1.23c-0.33-0.77-1.25-1.46-2.04-1.55C670.79,323.79,670.41,324.34,670.73,325.11z",
  "M666.1,324.61c0.32,0.77,1.23,1.46,2.03,1.54c0.81,0.09,1.2-0.46,0.88-1.23c-0.32-0.77-1.24-1.46-2.04-1.55C666.17,323.29,665.78,323.84,666.1,324.61z",
  "M661.44,324.11c0.31,0.77,1.22,1.45,2.03,1.54c0.81,0.09,1.21-0.46,0.9-1.23c-0.31-0.77-1.22-1.46-2.03-1.54C661.54,322.8,661.14,323.35,661.44,324.11z",
  "M656.77,323.63c0.3,0.77,1.2,1.45,2.02,1.54c0.81,0.08,1.23-0.47,0.92-1.23c-0.31-0.77-1.21-1.46-2.02-1.54C656.88,322.31,656.47,322.86,656.77,323.63z",
  "M652.09,323.15c0.29,0.76,1.19,1.45,2.01,1.53c0.82,0.08,1.24-0.47,0.94-1.23c-0.3-0.76-1.2-1.45-2.01-1.54C652.21,321.83,651.79,322.38,652.09,323.15z",
  "M647.38,322.67c0.29,0.76,1.18,1.45,2,1.53c0.82,0.08,1.25-0.47,0.96-1.23c-0.29-0.76-1.19-1.45-2-1.53C647.52,321.35,647.1,321.91,647.38,322.67z",
  "M642.66,322.2c0.28,0.76,1.17,1.45,1.99,1.53c0.82,0.08,1.26-0.47,0.98-1.23c-0.28-0.76-1.18-1.45-2-1.53C642.81,320.88,642.38,321.44,642.66,322.2z",
  "M637.92,321.74c0.27,0.76,1.16,1.44,1.99,1.52c0.82,0.08,1.27-0.47,0.99-1.23c-0.28-0.76-1.17-1.45-1.99-1.53C638.09,320.42,637.64,320.98,637.92,321.74z",
  "M633.16,321.28c0.27,0.76,1.15,1.44,1.98,1.52c0.83,0.08,1.28-0.47,1.01-1.23c-0.27-0.76-1.16-1.44-1.98-1.52C633.34,319.97,632.89,320.52,633.16,321.28z",
  "M628.38,320.84c0.26,0.76,1.14,1.44,1.97,1.52c0.83,0.08,1.29-0.47,1.03-1.24c-0.26-0.76-1.15-1.44-1.97-1.52C628.59,319.52,628.13,320.08,628.38,320.84z",
  "M623.59,320.4c0.25,0.76,1.13,1.44,1.96,1.51c0.83,0.08,1.3-0.48,1.05-1.24c-0.26-0.76-1.14-1.44-1.97-1.52C623.81,319.08,623.34,319.64,623.59,320.4z",
  "M618.79,319.96c0.24,0.76,1.12,1.43,1.96,1.51c0.84,0.08,1.31-0.48,1.07-1.24c-0.25-0.76-1.13-1.44-1.96-1.51C619.02,318.64,618.54,319.2,618.79,319.96z",
  "M613.97,319.53c0.24,0.76,1.11,1.43,1.95,1.51c0.84,0.07,1.33-0.48,1.08-1.24c-0.24-0.76-1.12-1.44-1.95-1.51C614.21,318.22,613.73,318.78,613.97,319.53z",
  "M609.13,319.11c0.23,0.76,1.1,1.43,1.94,1.5c0.84,0.07,1.34-0.48,1.1-1.24c-0.24-0.76-1.11-1.43-1.94-1.51C609.39,317.8,608.9,318.36,609.13,319.11z",
  "M604.28,318.7c0.22,0.76,1.09,1.43,1.93,1.5c0.84,0.07,1.35-0.48,1.12-1.24c-0.23-0.76-1.1-1.43-1.94-1.5C604.55,317.39,604.05,317.95,604.28,318.7z",
  "M599.41,318.3c0.22,0.76,1.08,1.43,1.93,1.5c0.85,0.07,1.35-0.49,1.13-1.24c-0.22-0.76-1.09-1.43-1.93-1.5C599.7,316.98,599.19,317.54,599.41,318.3z",
  "M416.99,309.56c0.04,0.75,0.81,1.37,1.71,1.38c0.9,0.01,1.6-0.58,1.55-1.33c-0.05-0.75-0.81-1.37-1.71-1.38C417.64,308.21,416.95,308.81,416.99,309.56z",
  "M411.8,309.5c0.04,0.75,0.8,1.36,1.7,1.37c0.9,0.01,1.6-0.58,1.56-1.33c-0.04-0.75-0.81-1.37-1.71-1.38C412.46,308.15,411.76,308.75,411.8,309.5z",
  "M406.6,309.44c0.04,0.75,0.8,1.36,1.7,1.37c0.9,0.01,1.6-0.59,1.57-1.34c-0.04-0.75-0.8-1.37-1.7-1.38C407.27,308.09,406.57,308.69,406.6,309.44z",
  "M401.4,309.4c0.03,0.75,0.79,1.36,1.69,1.37c0.9,0.01,1.61-0.59,1.57-1.34c-0.03-0.75-0.79-1.37-1.69-1.37C402.08,308.04,401.37,308.65,401.4,309.4z",
  "M396.2,309.36c0.03,0.75,0.78,1.36,1.69,1.36c0.9,0.01,1.61-0.59,1.58-1.34c-0.03-0.75-0.79-1.36-1.69-1.37C396.88,308.01,396.17,308.61,396.2,309.36z",
  "M240.64,313.02c-0.09,0.75,0.56,1.32,1.45,1.27c0.89-0.05,1.68-0.69,1.77-1.44c0.09-0.75-0.56-1.32-1.45-1.27C241.52,311.63,240.73,312.27,240.64,313.02z",
  "M235.52,313.3c-0.1,0.75,0.55,1.31,1.44,1.27c0.89-0.05,1.68-0.69,1.78-1.44c0.09-0.75-0.55-1.32-1.43-1.27C236.41,311.9,235.61,312.55,235.52,313.3z",
  "M230.41,313.58c-0.1,0.75,0.54,1.31,1.43,1.26c0.89-0.05,1.68-0.69,1.78-1.44c0.1-0.75-0.54-1.32-1.42-1.27C231.31,312.18,230.51,312.83,230.41,313.58z",
  "M225.3,313.88c-0.11,0.75,0.53,1.31,1.42,1.26c0.89-0.05,1.69-0.7,1.79-1.45c0.1-0.75-0.53-1.32-1.41-1.27C226.21,312.48,225.41,313.13,225.3,313.88z",
  "M220.21,314.18c-0.11,0.75,0.52,1.31,1.41,1.26c0.88-0.05,1.69-0.7,1.79-1.45c0.11-0.75-0.52-1.32-1.4-1.26C221.12,312.78,220.32,313.43,220.21,314.18z",
  "M215.12,314.49c-0.11,0.75,0.51,1.31,1.39,1.26c0.88-0.05,1.69-0.7,1.8-1.45c0.11-0.75-0.51-1.32-1.39-1.26C216.04,313.09,215.23,313.74,215.12,314.49z",
  "M210.04,314.81c-0.12,0.75,0.5,1.31,1.38,1.25c0.88-0.06,1.69-0.71,1.81-1.46c0.12-0.75-0.5-1.31-1.38-1.26C210.97,313.41,210.16,314.07,210.04,314.81z",
  "M204.97,315.15c-0.12,0.75,0.49,1.31,1.37,1.25c0.88-0.06,1.69-0.71,1.81-1.46c0.12-0.75-0.49-1.31-1.37-1.25C205.91,313.74,205.1,314.4,204.97,315.15z",
  "M199.91,315.49c-0.13,0.75,0.48,1.31,1.36,1.25c0.88-0.06,1.69-0.71,1.82-1.46c0.13-0.75-0.48-1.31-1.36-1.25C200.86,314.08,200.04,314.74,199.91,315.49z",
  "M194.86,315.83c-0.13,0.75,0.47,1.31,1.35,1.24c0.88-0.06,1.69-0.72,1.82-1.46c0.13-0.75-0.47-1.31-1.34-1.25C195.82,314.43,195,315.09,194.86,315.83z",
  "M668.87,320.76c0.33,0.77,1.25,1.47,2.04,1.55c0.8,0.09,1.17-0.47,0.83-1.24c-0.34-0.77-1.25-1.47-2.04-1.56C668.91,319.43,668.54,319.99,668.87,320.76z",
  "M664.28,320.26c0.32,0.77,1.24,1.46,2.03,1.55c0.8,0.09,1.18-0.47,0.85-1.24c-0.33-0.77-1.24-1.47-2.04-1.55C664.33,318.93,663.96,319.49,664.28,320.26z",
  "M659.67,319.77c0.32,0.77,1.22,1.46,2.03,1.55c0.8,0.08,1.19-0.47,0.87-1.24c-0.32-0.77-1.23-1.47-2.03-1.55C659.74,318.45,659.36,319,659.67,319.77z",
  "M655.05,319.29c0.31,0.77,1.21,1.46,2.02,1.54c0.81,0.08,1.2-0.47,0.89-1.24c-0.32-0.77-1.22-1.46-2.02-1.55C655.13,317.96,654.74,318.52,655.05,319.29z",
  "M650.4,318.81c0.3,0.77,1.2,1.46,2.01,1.54c0.81,0.08,1.22-0.47,0.91-1.24c-0.31-0.77-1.21-1.46-2.01-1.54C650.5,317.48,650.1,318.04,650.4,318.81z",
  "M645.74,318.34c0.3,0.77,1.19,1.46,2,1.54c0.81,0.08,1.23-0.47,0.93-1.24c-0.3-0.77-1.2-1.46-2.01-1.54C645.85,317.01,645.44,317.57,645.74,318.34z",
  "M641.05,317.88c0.29,0.77,1.18,1.45,2,1.53c0.82,0.08,1.24-0.48,0.95-1.24c-0.29-0.77-1.19-1.46-2-1.54C641.19,316.55,640.77,317.11,641.05,317.88z",
  "M636.35,317.42c0.28,0.77,1.17,1.45,1.99,1.53c0.82,0.08,1.25-0.48,0.97-1.24c-0.29-0.77-1.18-1.46-1.99-1.53C636.5,316.09,636.07,316.65,636.35,317.42z",
  "M631.63,316.97c0.27,0.77,1.16,1.45,1.98,1.53c0.82,0.08,1.26-0.48,0.98-1.25c-0.28-0.77-1.17-1.45-1.98-1.53C631.8,315.64,631.36,316.2,631.63,316.97z",
  "M626.9,316.52c0.27,0.77,1.15,1.45,1.97,1.53c0.82,0.08,1.27-0.48,1-1.25c-0.27-0.77-1.16-1.45-1.98-1.53C627.08,315.19,626.63,315.75,626.9,316.52z",
  "M622.15,316.08c0.26,0.77,1.14,1.45,1.97,1.52c0.83,0.08,1.28-0.48,1.02-1.25c-0.26-0.77-1.15-1.45-1.97-1.53C622.34,314.76,621.89,315.32,622.15,316.08z",
  "M617.38,315.65c0.25,0.77,1.13,1.44,1.96,1.52c0.83,0.07,1.3-0.48,1.04-1.25c-0.26-0.77-1.14-1.45-1.96-1.52C617.59,314.32,617.13,314.89,617.38,315.65z",
  "M612.59,315.23c0.25,0.76,1.12,1.44,1.95,1.52c0.83,0.07,1.31-0.49,1.05-1.25c-0.25-0.76-1.13-1.45-1.96-1.52C612.82,313.9,612.35,314.46,612.59,315.23z",
  "M607.79,314.81c0.24,0.76,1.11,1.44,1.95,1.51c0.84,0.07,1.32-0.49,1.07-1.25c-0.24-0.76-1.12-1.45-1.95-1.52C608.03,313.48,607.55,314.05,607.79,314.81z",
  "M602.98,314.4c0.23,0.76,1.1,1.44,1.94,1.51c0.84,0.07,1.33-0.49,1.09-1.25c-0.24-0.76-1.11-1.44-1.94-1.51C603.23,313.07,602.74,313.64,602.98,314.4z",
  "M568.85,311.75c0.19,0.76,1.04,1.43,1.89,1.49c0.86,0.06,1.39-0.5,1.2-1.27c-0.2-0.76-1.04-1.43-1.9-1.49C569.2,310.42,568.66,310.99,568.85,311.75z",
  "M421.91,305.38c0.05,0.76,0.82,1.38,1.71,1.4c0.9,0.01,1.58-0.58,1.53-1.34c-0.05-0.76-0.82-1.39-1.72-1.4C422.54,304.02,421.86,304.62,421.91,305.38z",
  "M416.74,305.3c0.05,0.76,0.81,1.38,1.71,1.39c0.9,0.01,1.59-0.59,1.54-1.35c-0.05-0.76-0.81-1.38-1.71-1.4C417.38,303.94,416.69,304.55,416.74,305.3z",
  "M411.57,305.24c0.04,0.76,0.8,1.38,1.7,1.39c0.9,0.01,1.59-0.59,1.55-1.35c-0.05-0.76-0.81-1.38-1.7-1.39C412.22,303.87,411.53,304.48,411.57,305.24z",
  "M406.39,305.18c0.04,0.76,0.8,1.38,1.7,1.39c0.9,0.01,1.6-0.59,1.55-1.35c-0.04-0.76-0.8-1.38-1.7-1.39C407.05,303.82,406.36,304.43,406.39,305.18z",
  "M401.22,305.14c0.03,0.76,0.79,1.37,1.69,1.38c0.9,0.01,1.6-0.6,1.56-1.36c-0.04-0.76-0.79-1.38-1.69-1.39C401.88,303.77,401.18,304.38,401.22,305.14z",
  "M396.04,305.1c0.03,0.76,0.78,1.37,1.68,1.38c0.9,0.01,1.6-0.6,1.57-1.36c-0.03-0.76-0.79-1.38-1.68-1.38C396.71,303.73,396.01,304.35,396.04,305.1z",
  "M390.86,305.08c0.02,0.76,0.77,1.37,1.68,1.38c0.9,0,1.61-0.6,1.58-1.36c-0.03-0.76-0.78-1.38-1.68-1.38C391.54,303.71,390.83,304.32,390.86,305.08z",
  "M385.68,305.07c0.02,0.76,0.77,1.37,1.67,1.37c0.9,0,1.61-0.61,1.59-1.36c-0.02-0.76-0.77-1.38-1.67-1.38C386.36,303.69,385.65,304.31,385.68,305.07z",
  "M246.27,308.5c-0.09,0.76,0.55,1.33,1.44,1.29c0.89-0.04,1.68-0.69,1.77-1.45c0.09-0.76-0.55-1.34-1.44-1.29C247.16,307.1,246.36,307.75,246.27,308.5z",
  "M241.17,308.77c-0.1,0.76,0.54,1.33,1.43,1.28c0.89-0.05,1.68-0.69,1.77-1.45c0.09-0.76-0.54-1.33-1.43-1.29C242.07,307.36,241.27,308.01,241.17,308.77z",
  "M236.08,309.04c-0.1,0.76,0.53,1.33,1.42,1.28c0.89-0.05,1.68-0.7,1.78-1.45c0.1-0.76-0.53-1.33-1.41-1.28C236.98,307.63,236.18,308.29,236.08,309.04z",
  "M230.99,309.33c-0.11,0.76,0.52,1.33,1.41,1.28c0.88-0.05,1.68-0.7,1.79-1.46c0.1-0.76-0.52-1.33-1.4-1.28C231.9,307.92,231.1,308.57,230.99,309.33z",
  "M225.92,309.62c-0.11,0.76,0.51,1.33,1.4,1.27c0.88-0.05,1.68-0.7,1.79-1.46c0.11-0.76-0.51-1.33-1.39-1.28C226.83,308.21,226.03,308.87,225.92,309.62z",
  "M220.85,309.93c-0.12,0.76,0.5,1.32,1.38,1.27c0.88-0.05,1.68-0.71,1.8-1.46c0.11-0.76-0.5-1.33-1.38-1.28C221.77,308.51,220.96,309.17,220.85,309.93z",
  "M215.79,310.24c-0.12,0.76,0.49,1.32,1.37,1.27c0.88-0.05,1.69-0.71,1.8-1.47c0.12-0.76-0.49-1.33-1.37-1.27C216.72,308.82,215.91,309.48,215.79,310.24z",
  "M210.74,310.56c-0.13,0.76,0.48,1.32,1.36,1.27c0.88-0.06,1.69-0.71,1.81-1.47c0.12-0.76-0.48-1.33-1.36-1.27C211.68,309.14,210.86,309.8,210.74,310.56z",
  "M205.7,310.89c-0.13,0.76,0.47,1.32,1.35,1.26c0.88-0.06,1.69-0.72,1.82-1.47c0.13-0.76-0.47-1.33-1.35-1.27C206.64,309.47,205.83,310.13,205.7,310.89z",
  "M200.66,311.23c-0.14,0.76,0.46,1.32,1.34,1.26c0.87-0.06,1.69-0.72,1.82-1.47c0.13-0.76-0.46-1.32-1.33-1.27C201.62,309.81,200.8,310.47,200.66,311.23z",
  "M195.64,311.58c-0.14,0.76,0.45,1.32,1.32,1.26c0.87-0.06,1.69-0.72,1.83-1.48c0.14-0.76-0.45-1.32-1.32-1.26C196.61,310.16,195.78,310.82,195.64,311.58z",
  "M671.49,316.88c0.35,0.78,1.26,1.47,2.05,1.56c0.78,0.09,1.14-0.47,0.78-1.25c-0.35-0.78-1.27-1.48-2.05-1.57C671.49,315.54,671.14,316.1,671.49,316.88z",
  "M666.96,316.38c0.34,0.78,1.25,1.47,2.04,1.56c0.79,0.09,1.15-0.47,0.8-1.25c-0.35-0.78-1.26-1.48-2.04-1.56C666.98,315.05,666.62,315.61,666.96,316.38z",
  "M662.42,315.89c0.33,0.78,1.24,1.47,2.03,1.56c0.79,0.09,1.16-0.47,0.82-1.25c-0.34-0.78-1.25-1.47-2.04-1.56C662.45,314.56,662.09,315.12,662.42,315.89z",
  "M657.85,315.41c0.33,0.77,1.23,1.47,2.03,1.55c0.8,0.09,1.17-0.47,0.84-1.25c-0.33-0.78-1.24-1.47-2.03-1.56C657.9,314.07,657.53,314.63,657.85,315.41z",
  "M653.27,314.93c0.32,0.77,1.22,1.47,2.02,1.55c0.8,0.08,1.18-0.48,0.86-1.25c-0.32-0.77-1.23-1.47-2.02-1.55C653.34,313.59,652.95,314.15,653.27,314.93z",
  "M648.67,314.45c0.31,0.77,1.21,1.47,2.01,1.55c0.8,0.08,1.2-0.48,0.88-1.25c-0.32-0.77-1.22-1.47-2.02-1.55C648.75,313.12,648.35,313.68,648.67,314.45z",
  "M644.04,313.98c0.3,0.77,1.2,1.46,2.01,1.55c0.81,0.08,1.21-0.48,0.9-1.25c-0.31-0.77-1.21-1.47-2.01-1.55C644.14,312.65,643.74,313.21,644.04,313.98z",
  "M639.4,313.52c0.3,0.77,1.19,1.46,2,1.54c0.81,0.08,1.22-0.48,0.92-1.25c-0.3-0.77-1.2-1.47-2-1.55C639.51,312.19,639.1,312.75,639.4,313.52z",
  "M634.74,313.07c0.29,0.77,1.18,1.46,1.99,1.54c0.81,0.08,1.23-0.48,0.94-1.25c-0.29-0.77-1.19-1.46-1.99-1.54C634.87,311.73,634.45,312.29,634.74,313.07z",
  "M630.06,312.62c0.28,0.77,1.17,1.46,1.98,1.54c0.81,0.08,1.24-0.48,0.96-1.26c-0.29-0.77-1.18-1.46-1.99-1.54C630.21,311.28,629.78,311.84,630.06,312.62z",
  "M625.37,312.17c0.28,0.77,1.16,1.46,1.98,1.53c0.82,0.08,1.25-0.48,0.97-1.26c-0.28-0.77-1.17-1.46-1.98-1.54C625.52,310.84,625.09,311.4,625.37,312.17z",
  "M620.65,311.74c0.27,0.77,1.15,1.45,1.97,1.53c0.82,0.08,1.27-0.49,0.99-1.26c-0.27-0.77-1.16-1.46-1.97-1.53C620.83,310.4,620.38,310.97,620.65,311.74z",
  "M615.92,311.31c0.26,0.77,1.14,1.45,1.96,1.53c0.82,0.07,1.28-0.49,1.01-1.26c-0.27-0.77-1.15-1.46-1.97-1.53C616.11,309.97,615.66,310.54,615.92,311.31z",
  "M611.17,310.89c0.26,0.77,1.13,1.45,1.96,1.53c0.83,0.07,1.29-0.49,1.03-1.26c-0.26-0.77-1.14-1.46-1.96-1.53C611.38,309.55,610.92,310.12,611.17,310.89z",
  "M606.41,310.47c0.25,0.77,1.12,1.45,1.95,1.52c0.83,0.07,1.3-0.49,1.04-1.26c-0.25-0.77-1.13-1.45-1.95-1.53C606.63,309.14,606.16,309.7,606.41,310.47z",
  "M567.75,307.43c0.2,0.77,1.05,1.44,1.9,1.5c0.85,0.06,1.37-0.51,1.17-1.28c-0.2-0.77-1.06-1.44-1.9-1.5C568.07,306.09,567.55,306.66,567.75,307.43z",
  "M452.38,301.73c0.08,0.77,0.86,1.4,1.75,1.43c0.89,0.02,1.54-0.57,1.46-1.34c-0.08-0.76-0.87-1.41-1.75-1.43C452.95,300.37,452.3,300.97,452.38,301.73z",
  "M421.61,301.08c0.05,0.76,0.82,1.39,1.71,1.41c0.89,0.01,1.57-0.59,1.52-1.36c-0.06-0.76-0.82-1.4-1.71-1.41C422.24,299.7,421.56,300.31,421.61,301.08z",
  "M416.47,301c0.05,0.76,0.81,1.39,1.71,1.4c0.89,0.01,1.58-0.59,1.53-1.36c-0.05-0.76-0.82-1.4-1.71-1.41C417.1,299.62,416.42,300.24,416.47,301z",
  "M411.33,300.94c0.04,0.76,0.8,1.39,1.7,1.4c0.89,0.01,1.58-0.6,1.53-1.36c-0.05-0.77-0.81-1.4-1.7-1.41C411.97,299.56,411.28,300.17,411.33,300.94z",
  "M406.18,300.88c0.04,0.76,0.8,1.39,1.69,1.4c0.89,0.01,1.59-0.6,1.54-1.37c-0.04-0.76-0.8-1.39-1.69-1.4C406.83,299.5,406.14,300.12,406.18,300.88z",
  "M401.02,300.84c0.04,0.76,0.79,1.39,1.68,1.4c0.9,0.01,1.59-0.6,1.55-1.37c-0.04-0.76-0.79-1.39-1.69-1.4C401.68,299.46,400.99,300.07,401.02,300.84z",
  "M395.87,300.8c0.03,0.76,0.78,1.39,1.68,1.39c0.9,0.01,1.59-0.61,1.56-1.37c-0.03-0.76-0.79-1.39-1.68-1.4C396.54,299.42,395.84,300.04,395.87,300.8z",
  "M390.71,300.78c0.03,0.76,0.77,1.38,1.67,1.39c0.9,0,1.6-0.61,1.57-1.37c-0.03-0.76-0.78-1.39-1.67-1.39C391.39,299.4,390.69,300.01,390.71,300.78z",
  "M385.55,300.76c0.02,0.76,0.77,1.38,1.66,1.39c0.9,0,1.6-0.61,1.58-1.38c-0.03-0.76-0.77-1.39-1.66-1.39C386.24,299.38,385.53,300,385.55,300.76z",
  "M251.9,303.95c-0.09,0.76,0.55,1.34,1.43,1.3c0.88-0.04,1.67-0.69,1.76-1.46c0.09-0.76-0.55-1.35-1.43-1.31C252.78,302.53,251.99,303.19,251.9,303.95z",
  "M246.81,304.21c-0.1,0.76,0.54,1.34,1.42,1.3c0.88-0.04,1.68-0.7,1.77-1.46c0.1-0.76-0.54-1.35-1.42-1.3C247.71,302.79,246.91,303.45,246.81,304.21z",
  "M241.74,304.47c-0.1,0.76,0.53,1.34,1.41,1.3c0.88-0.05,1.68-0.7,1.78-1.46c0.1-0.76-0.53-1.35-1.41-1.3C242.64,303.05,241.85,303.71,241.74,304.47z",
  "M236.68,304.75c-0.11,0.76,0.52,1.34,1.4,1.29c0.88-0.05,1.68-0.7,1.78-1.47c0.11-0.76-0.52-1.34-1.39-1.3C237.59,303.33,236.79,303.99,236.68,304.75z",
  "M231.62,305.03c-0.11,0.76,0.51,1.34,1.39,1.29c0.88-0.05,1.68-0.71,1.79-1.47c0.11-0.76-0.51-1.34-1.38-1.29C232.54,303.61,231.73,304.27,231.62,305.03z",
  "M226.57,305.33c-0.12,0.76,0.5,1.34,1.37,1.29c0.88-0.05,1.68-0.71,1.8-1.47c0.12-0.76-0.5-1.34-1.37-1.29C227.49,303.9,226.69,304.56,226.57,305.33z",
  "M221.53,305.63c-0.12,0.76,0.49,1.34,1.36,1.28c0.88-0.05,1.68-0.71,1.8-1.47c0.12-0.76-0.49-1.34-1.36-1.29C222.46,304.2,221.65,304.87,221.53,305.63z",
  "M216.5,305.94c-0.13,0.76,0.48,1.34,1.35,1.28c0.87-0.05,1.68-0.71,1.81-1.48c0.13-0.76-0.48-1.34-1.35-1.29C217.44,304.51,216.63,305.18,216.5,305.94z",
  "M211.47,306.26c-0.13,0.76,0.47,1.33,1.34,1.28c0.87-0.06,1.68-0.72,1.82-1.48c0.13-0.76-0.47-1.34-1.33-1.28C212.42,304.83,211.61,305.5,211.47,306.26z",
  "M206.46,306.59c-0.14,0.76,0.45,1.33,1.33,1.28c0.87-0.06,1.69-0.72,1.82-1.48c0.14-0.76-0.46-1.34-1.32-1.28C207.42,305.16,206.6,305.83,206.46,306.59z",
  "M201.46,306.93c-0.15,0.76,0.44,1.33,1.31,1.27c0.87-0.06,1.69-0.72,1.83-1.49c0.14-0.76-0.44-1.34-1.31-1.28C202.43,305.5,201.6,306.17,201.46,306.93z",
  "M196.47,307.28c-0.15,0.76,0.43,1.33,1.3,1.27c0.87-0.06,1.69-0.73,1.83-1.49c0.15-0.76-0.43-1.33-1.3-1.27C197.44,305.85,196.62,306.52,196.47,307.28z",
  "M660.52,311.5c0.34,0.78,1.25,1.48,2.03,1.56c0.78,0.08,1.14-0.48,0.8-1.26c-0.35-0.78-1.26-1.48-2.04-1.57C660.53,310.15,660.18,310.72,660.52,311.5z",
  "M655.99,311.01c0.33,0.78,1.24,1.48,2.03,1.56c0.79,0.08,1.15-0.48,0.82-1.26c-0.34-0.78-1.25-1.48-2.03-1.56C656.02,309.67,655.66,310.24,655.99,311.01z",
  "M651.45,310.54c0.33,0.78,1.23,1.47,2.02,1.56c0.79,0.08,1.17-0.48,0.84-1.26c-0.33-0.78-1.24-1.48-2.02-1.56C651.5,309.19,651.12,309.76,651.45,310.54z",
  "M646.89,310.07c0.32,0.78,1.22,1.47,2.01,1.56c0.79,0.08,1.18-0.48,0.85-1.26c-0.32-0.78-1.23-1.48-2.02-1.56C646.95,308.72,646.57,309.29,646.89,310.07z",
  "M642.3,309.6c0.31,0.78,1.21,1.47,2.01,1.55c0.8,0.08,1.19-0.48,0.87-1.26c-0.32-0.78-1.22-1.48-2.01-1.56C642.38,308.26,641.99,308.82,642.3,309.6z",
  "M637.7,309.14c0.3,0.78,1.2,1.47,2,1.55c0.8,0.08,1.2-0.48,0.89-1.26c-0.31-0.78-1.21-1.47-2-1.55C637.79,307.8,637.4,308.36,637.7,309.14z",
  "M633.08,308.69c0.3,0.78,1.19,1.47,1.99,1.55c0.81,0.08,1.21-0.49,0.91-1.26c-0.3-0.78-1.2-1.47-2-1.55C633.19,307.34,632.78,307.91,633.08,308.69z",
  "M628.44,308.24c0.29,0.78,1.18,1.47,1.99,1.54c0.81,0.08,1.22-0.49,0.93-1.26c-0.3-0.78-1.19-1.47-1.99-1.55C628.56,306.9,628.15,307.46,628.44,308.24z",
  "M623.78,307.8c0.28,0.78,1.17,1.47,1.98,1.54c0.81,0.08,1.24-0.49,0.95-1.27c-0.29-0.78-1.18-1.47-1.98-1.55C623.92,306.45,623.5,307.02,623.78,307.8z",
  "M619.11,307.37c0.28,0.78,1.16,1.46,1.97,1.54c0.81,0.08,1.25-0.49,0.96-1.27c-0.28-0.78-1.17-1.47-1.98-1.54C619.26,306.02,618.83,306.59,619.11,307.37z",
  "M614.42,306.94c0.27,0.78,1.15,1.46,1.97,1.54c0.82,0.07,1.26-0.49,0.98-1.27c-0.27-0.78-1.16-1.47-1.97-1.54C614.58,305.59,614.15,306.16,614.42,306.94z",
  "M571.44,303.42c0.21,0.77,1.07,1.45,1.91,1.51c0.84,0.06,1.35-0.51,1.13-1.29c-0.22-0.77-1.08-1.45-1.91-1.52C571.73,302.07,571.23,302.65,571.44,303.42z",
  "M566.6,303.07c0.21,0.77,1.06,1.45,1.9,1.51c0.84,0.06,1.36-0.52,1.15-1.29c-0.21-0.77-1.07-1.45-1.91-1.51C566.9,301.72,566.39,302.3,566.6,303.07z",
  "M561.73,302.73c0.2,0.77,1.05,1.45,1.9,1.51c0.85,0.06,1.37-0.52,1.16-1.29c-0.21-0.77-1.06-1.45-1.9-1.51C562.05,301.37,561.53,301.95,561.73,302.73z",
  "M456.99,297.53c0.09,0.77,0.88,1.42,1.76,1.44c0.88,0.03,1.52-0.58,1.43-1.35c-0.09-0.77-0.88-1.42-1.76-1.45C457.54,296.16,456.91,296.76,456.99,297.53z",
  "M451.91,297.39c0.09,0.77,0.87,1.41,1.75,1.44c0.88,0.02,1.53-0.58,1.44-1.35c-0.09-0.77-0.87-1.42-1.75-1.44C452.47,296.01,451.83,296.62,451.91,297.39z",
  "M426.42,296.82c0.06,0.77,0.83,1.41,1.72,1.42c0.89,0.02,1.56-0.59,1.49-1.37c-0.06-0.77-0.83-1.41-1.72-1.43C427.03,295.43,426.36,296.05,426.42,296.82z",
  "M421.31,296.73c0.06,0.77,0.82,1.41,1.71,1.42c0.89,0.01,1.56-0.6,1.5-1.37c-0.06-0.77-0.83-1.41-1.71-1.42C421.92,295.35,421.25,295.96,421.31,296.73z",
  "M416.19,296.66c0.05,0.77,0.81,1.4,1.7,1.42c0.89,0.01,1.57-0.6,1.51-1.37c-0.05-0.77-0.82-1.41-1.7-1.42C416.81,295.27,416.14,295.89,416.19,296.66z",
  "M411.07,296.59c0.05,0.77,0.8,1.4,1.69,1.41c0.89,0.01,1.57-0.6,1.52-1.37c-0.05-0.77-0.81-1.41-1.7-1.42C411.7,295.2,411.02,295.82,411.07,296.59z",
  "M405.95,296.54c0.04,0.77,0.8,1.4,1.69,1.41c0.89,0.01,1.58-0.61,1.53-1.38c-0.04-0.77-0.8-1.41-1.69-1.42C406.59,295.15,405.9,295.77,405.95,296.54z",
  "M400.82,296.5c0.04,0.77,0.79,1.4,1.68,1.41c0.89,0.01,1.58-0.61,1.54-1.38c-0.04-0.77-0.79-1.4-1.68-1.41C401.47,295.1,400.78,295.72,400.82,296.5z",
  "M395.69,296.46c0.03,0.77,0.78,1.4,1.67,1.4c0.89,0.01,1.59-0.61,1.55-1.38c-0.04-0.77-0.78-1.4-1.67-1.41C396.35,295.07,395.66,295.69,395.69,296.46z",
  "M390.56,296.44c0.03,0.77,0.77,1.4,1.66,1.4c0.89,0,1.59-0.62,1.56-1.39c-0.03-0.77-0.78-1.4-1.66-1.41C391.23,295.04,390.53,295.67,390.56,296.44z",
  "M385.43,296.42c0.02,0.77,0.76,1.4,1.66,1.4c0.89,0,1.59-0.62,1.57-1.39c-0.03-0.77-0.77-1.4-1.66-1.4C386.1,295.03,385.4,295.65,385.43,296.42z",
  "M262.57,299.14c-0.09,0.77,0.55,1.36,1.43,1.32c0.88-0.04,1.67-0.69,1.75-1.46c0.09-0.77-0.55-1.36-1.43-1.32C263.45,297.71,262.66,298.37,262.57,299.14z",
  "M257.51,299.37c-0.1,0.77,0.54,1.36,1.42,1.32c0.88-0.04,1.67-0.7,1.76-1.47c0.09-0.77-0.54-1.36-1.42-1.32C258.39,297.94,257.6,298.6,257.51,299.37z",
  "M252.45,299.62c-0.1,0.77,0.53,1.36,1.41,1.31c0.88-0.04,1.67-0.7,1.77-1.47c0.1-0.77-0.53-1.36-1.41-1.32C253.34,298.19,252.55,298.85,252.45,299.62z",
  "M247.39,299.88c-0.11,0.77,0.52,1.35,1.4,1.31c0.88-0.04,1.67-0.7,1.77-1.47c0.1-0.77-0.52-1.36-1.4-1.31C248.29,298.45,247.5,299.11,247.39,299.88z",
  "M242.35,300.14c-0.11,0.77,0.51,1.35,1.39,1.31c0.88-0.05,1.67-0.71,1.78-1.47c0.11-0.77-0.51-1.36-1.39-1.31C243.26,298.71,242.46,299.37,242.35,300.14z",
  "M237.31,300.42c-0.12,0.77,0.5,1.35,1.38,1.3c0.88-0.05,1.67-0.71,1.79-1.48c0.11-0.77-0.5-1.36-1.37-1.31C238.23,298.98,237.43,299.65,237.31,300.42z",
  "M232.28,300.7c-0.12,0.77,0.49,1.35,1.37,1.3c0.87-0.05,1.68-0.71,1.79-1.48c0.12-0.77-0.49-1.36-1.36-1.31C233.21,299.27,232.4,299.93,232.28,300.7z",
  "M227.26,301c-0.13,0.77,0.48,1.35,1.35,1.3c0.87-0.05,1.68-0.71,1.8-1.48c0.12-0.77-0.48-1.35-1.35-1.3C228.19,299.56,227.39,300.23,227.26,301z",
  "M222.25,301.3c-0.13,0.77,0.47,1.35,1.34,1.3c0.87-0.05,1.68-0.72,1.81-1.49c0.13-0.77-0.47-1.35-1.34-1.3C223.19,299.86,222.38,300.53,222.25,301.3z",
  "M217.25,301.61c-0.14,0.77,0.46,1.35,1.33,1.29c0.87-0.05,1.68-0.72,1.81-1.49c0.13-0.77-0.46-1.35-1.33-1.3C218.2,300.17,217.38,300.84,217.25,301.61z",
  "M212.25,301.93c-0.14,0.77,0.45,1.35,1.32,1.29c0.87-0.06,1.68-0.72,1.82-1.49c0.14-0.77-0.45-1.35-1.31-1.29C213.21,300.49,212.4,301.16,212.25,301.93z",
  "M207.27,302.26c-0.15,0.77,0.44,1.34,1.3,1.29c0.87-0.06,1.68-0.73,1.83-1.49c0.14-0.77-0.44-1.35-1.3-1.29C208.24,300.82,207.42,301.49,207.27,302.26z",
  "M202.3,302.6c-0.15,0.77,0.43,1.34,1.29,1.28c0.86-0.06,1.68-0.73,1.83-1.5c0.15-0.77-0.43-1.35-1.29-1.29C203.27,301.16,202.45,301.83,202.3,302.6z",
  "M192.39,303.3c-0.16,0.77,0.4,1.34,1.26,1.28c0.86-0.06,1.69-0.73,1.84-1.5c0.16-0.77-0.4-1.34-1.26-1.28C193.38,301.86,192.55,302.53,192.39,303.3z",
  "M658.57,307.08c0.35,0.78,1.26,1.49,2.04,1.57c0.78,0.08,1.12-0.48,0.77-1.26c-0.35-0.78-1.27-1.49-2.04-1.57C658.57,305.73,658.22,306.3,658.57,307.08z",
  "M654.09,306.6c0.34,0.78,1.25,1.48,2.03,1.57c0.78,0.08,1.13-0.48,0.79-1.27c-0.35-0.78-1.25-1.49-2.03-1.57C654.1,305.25,653.75,305.82,654.09,306.6z",
  "M645.06,305.66c0.33,0.78,1.23,1.48,2.02,1.56c0.79,0.08,1.16-0.49,0.83-1.27c-0.33-0.78-1.23-1.48-2.02-1.56C645.11,304.3,644.74,304.87,645.06,305.66z",
  "M640.52,305.19c0.32,0.78,1.22,1.48,2.01,1.56c0.79,0.08,1.17-0.49,0.85-1.27c-0.32-0.78-1.23-1.48-2.01-1.56C640.58,303.84,640.2,304.41,640.52,305.19z",
  "M635.96,304.73c0.31,0.78,1.21,1.48,2,1.56c0.8,0.08,1.18-0.49,0.87-1.27c-0.32-0.78-1.22-1.48-2-1.56C636.03,303.38,635.65,303.95,635.96,304.73z",
  "M631.38,304.28c0.3,0.78,1.2,1.48,2,1.55c0.8,0.08,1.19-0.49,0.88-1.27c-0.31-0.78-1.21-1.48-2-1.56C631.46,302.93,631.07,303.5,631.38,304.28z",
  "M626.78,303.84c0.3,0.78,1.19,1.48,1.99,1.55c0.8,0.08,1.21-0.49,0.9-1.27s-1.2-1.48-1.99-1.56C626.88,302.48,626.48,303.06,626.78,303.84z",
  "M622.16,303.4c0.29,0.78,1.18,1.47,1.98,1.55c0.8,0.08,1.22-0.49,0.92-1.27c-0.3-0.78-1.19-1.48-1.99-1.55C622.27,302.05,621.86,302.62,622.16,303.4z",
  "M603.5,301.71c0.26,0.78,1.14,1.47,1.96,1.54c0.82,0.07,1.26-0.5,0.99-1.28c-0.27-0.78-1.15-1.47-1.96-1.54C603.68,300.35,603.23,300.93,603.5,301.71z",
  "M589.33,300.52c0.25,0.78,1.11,1.46,1.94,1.53c0.83,0.07,1.29-0.51,1.04-1.29c-0.25-0.78-1.12-1.47-1.94-1.54C589.55,299.16,589.08,299.74,589.33,300.52z",
  "M584.57,300.14c0.24,0.78,1.1,1.46,1.93,1.53c0.83,0.07,1.3-0.51,1.06-1.29c-0.24-0.78-1.11-1.47-1.93-1.53C584.8,298.78,584.33,299.36,584.57,300.14z",
  "M579.8,299.76c0.23,0.78,1.09,1.46,1.93,1.53c0.83,0.07,1.31-0.51,1.07-1.29c-0.24-0.78-1.1-1.47-1.93-1.53C580.05,298.4,579.57,298.98,579.8,299.76z",
  "M575.01,299.39c0.23,0.78,1.09,1.46,1.92,1.52c0.83,0.06,1.32-0.51,1.09-1.29c-0.23-0.78-1.09-1.46-1.92-1.53C575.27,298.03,574.79,298.62,575.01,299.39z",
  "M570.21,299.03c0.22,0.78,1.08,1.46,1.91,1.52c0.84,0.06,1.33-0.52,1.1-1.3c-0.23-0.78-1.08-1.46-1.92-1.52C570.48,297.67,569.99,298.26,570.21,299.03z",
  "M456.48,293.15c0.09,0.78,0.88,1.43,1.76,1.45c0.88,0.03,1.51-0.58,1.41-1.36c-0.1-0.78-0.89-1.43-1.76-1.46C457.01,291.77,456.38,292.38,456.48,293.15z",
  "M451.42,293.01c0.09,0.78,0.87,1.43,1.75,1.45c0.88,0.02,1.52-0.58,1.42-1.36c-0.09-0.78-0.88-1.43-1.75-1.46C451.97,291.62,451.33,292.23,451.42,293.01z",
  "M431.15,292.53c0.07,0.78,0.84,1.42,1.72,1.44c0.88,0.02,1.54-0.6,1.47-1.37c-0.07-0.78-0.84-1.42-1.72-1.44C431.74,291.14,431.08,291.76,431.15,292.53z",
  "M426.07,292.44c0.06,0.78,0.83,1.42,1.71,1.43c0.88,0.02,1.55-0.6,1.48-1.38c-0.07-0.78-0.84-1.42-1.71-1.44C426.67,291.04,426,291.66,426.07,292.44z",
  "M420.98,292.35c0.06,0.78,0.82,1.42,1.71,1.43c0.88,0.01,1.55-0.6,1.49-1.38c-0.06-0.78-0.83-1.42-1.71-1.44C421.59,290.96,420.92,291.58,420.98,292.35z",
  "M415.89,292.28c0.05,0.78,0.81,1.42,1.7,1.43c0.88,0.01,1.56-0.61,1.5-1.38c-0.06-0.78-0.82-1.42-1.7-1.43C416.51,290.88,415.84,291.5,415.89,292.28z",
  "M410.8,292.22c0.05,0.78,0.8,1.41,1.69,1.43c0.89,0.01,1.56-0.61,1.51-1.39c-0.05-0.78-0.81-1.42-1.69-1.43C411.43,290.82,410.75,291.44,410.8,292.22z",
  "M405.7,292.16c0.04,0.78,0.8,1.41,1.68,1.42c0.89,0.01,1.57-0.61,1.52-1.39c-0.05-0.78-0.8-1.42-1.68-1.43C406.34,290.76,405.66,291.39,405.7,292.16z",
  "M400.61,292.12c0.04,0.78,0.79,1.41,1.67,1.42c0.89,0.01,1.57-0.61,1.53-1.39c-0.04-0.78-0.79-1.42-1.67-1.42C401.25,290.71,400.57,291.34,400.61,292.12z",
  "M395.5,292.08c0.03,0.78,0.78,1.41,1.67,1.42c0.89,0.01,1.58-0.62,1.54-1.4c-0.04-0.78-0.78-1.41-1.67-1.42C396.16,290.68,395.47,291.31,395.5,292.08z",
  "M390.4,292.06c0.03,0.78,0.77,1.41,1.66,1.41c0.89,0,1.58-0.62,1.55-1.4c-0.03-0.78-0.77-1.41-1.66-1.42C391.06,290.65,390.37,291.28,390.4,292.06z",
  "M385.29,292.05c0.02,0.78,0.76,1.41,1.65,1.41c0.89,0,1.58-0.62,1.56-1.4c-0.03-0.78-0.77-1.41-1.65-1.41C385.97,290.64,385.27,291.27,385.29,292.05z",
  "M380.19,292.04c0.02,0.78,0.75,1.41,1.64,1.41c0.89,0,1.59-0.63,1.57-1.4c-0.02-0.78-0.76-1.41-1.64-1.41C380.87,290.64,380.17,291.27,380.19,292.04z",
  "M263.1,294.77c-0.1,0.78,0.54,1.37,1.42,1.33c0.88-0.04,1.66-0.7,1.75-1.47c0.09-0.78-0.54-1.38-1.41-1.33C263.98,293.33,263.2,293.99,263.1,294.77z",
  "M258.06,295.01c-0.1,0.78,0.53,1.37,1.4,1.33c0.88-0.04,1.66-0.7,1.76-1.48c0.1-0.78-0.53-1.37-1.4-1.33C258.95,293.57,258.16,294.23,258.06,295.01z",
  "M253.03,295.25c-0.11,0.78,0.52,1.37,1.39,1.32c0.87-0.04,1.66-0.7,1.77-1.48c0.1-0.78-0.52-1.37-1.39-1.33C253.93,293.81,253.13,294.48,253.03,295.25z",
  "M248,295.51c-0.11,0.78,0.51,1.37,1.38,1.32c0.87-0.05,1.67-0.71,1.77-1.48c0.11-0.78-0.51-1.37-1.38-1.33C248.91,294.07,248.12,294.73,248,295.51z",
  "M242.99,295.78c-0.12,0.78,0.5,1.36,1.37,1.32c0.87-0.05,1.67-0.71,1.78-1.49c0.11-0.78-0.5-1.37-1.37-1.32C243.9,294.33,243.1,295,242.99,295.78z",
  "M237.98,296.05c-0.12,0.78,0.49,1.36,1.36,1.32c0.87-0.05,1.67-0.71,1.79-1.49c0.12-0.78-0.49-1.37-1.35-1.32C238.9,294.6,238.1,295.28,237.98,296.05z",
  "M232.98,296.34c-0.13,0.78,0.48,1.36,1.34,1.31c0.87-0.05,1.67-0.72,1.8-1.49c0.12-0.78-0.48-1.37-1.34-1.32C233.91,294.89,233.11,295.56,232.98,296.34z",
  "M227.99,296.63c-0.13,0.78,0.46,1.36,1.33,1.31c0.87-0.05,1.67-0.72,1.8-1.49c0.13-0.78-0.47-1.37-1.33-1.31C228.93,295.18,228.12,295.85,227.99,296.63z",
  "M223.01,296.93c-0.14,0.78,0.45,1.36,1.32,1.31c0.87-0.05,1.67-0.72,1.81-1.5c0.13-0.78-0.45-1.36-1.32-1.31C223.96,295.48,223.15,296.16,223.01,296.93z",
  "M218.04,297.24c-0.14,0.78,0.44,1.36,1.31,1.3c0.86-0.05,1.68-0.72,1.82-1.5c0.14-0.78-0.44-1.36-1.3-1.31C218.99,295.79,218.18,296.47,218.04,297.24z",
  "M213.07,297.57c-0.15,0.78,0.43,1.36,1.29,1.3c0.86-0.06,1.68-0.73,1.82-1.5c0.14-0.77-0.43-1.36-1.29-1.3C214.04,296.11,213.22,296.79,213.07,297.57z",
  "M208.12,297.9c-0.15,0.77,0.42,1.35,1.28,1.3c0.86-0.06,1.68-0.73,1.83-1.51c0.15-0.78-0.42-1.36-1.28-1.3C209.1,296.44,208.28,297.12,208.12,297.9z",
  "M203.18,298.23c-0.16,0.78,0.41,1.35,1.27,1.29c0.86-0.06,1.68-0.73,1.84-1.51c0.16-0.78-0.41-1.36-1.26-1.3C204.16,296.78,203.34,297.46,203.18,298.23z",
  "M198.25,298.58c-0.17,0.77,0.4,1.35,1.25,1.29c0.86-0.06,1.68-0.74,1.84-1.51c0.16-0.77-0.4-1.36-1.25-1.3C199.24,297.13,198.42,297.81,198.25,298.58z",
  "M193.33,298.94c-0.17,0.77,0.38,1.35,1.24,1.29c0.85-0.06,1.68-0.74,1.85-1.51c0.17-0.78-0.39-1.36-1.24-1.29C194.33,297.48,193.5,298.16,193.33,298.94z",
  "M188.42,299.3c-0.18,0.78,0.37,1.35,1.23,1.29c0.85-0.06,1.68-0.74,1.86-1.52c0.17-0.78-0.37-1.35-1.22-1.29C189.43,297.85,188.6,298.53,188.42,299.3z",
  "M652.14,302.17c0.35,0.79,1.25,1.49,2.03,1.57c0.77,0.08,1.12-0.49,0.76-1.27c-0.35-0.79-1.26-1.49-2.03-1.58C652.13,300.81,651.79,301.38,652.14,302.17z",
  "M643.2,301.22c0.33,0.79,1.24,1.49,2.02,1.57c0.78,0.08,1.14-0.49,0.8-1.28c-0.34-0.79-1.24-1.49-2.02-1.57C643.22,299.86,642.86,300.44,643.2,301.22z",
  "M638.69,300.76c0.33,0.79,1.23,1.49,2.01,1.57c0.78,0.08,1.15-0.49,0.82-1.28c-0.33-0.79-1.23-1.49-2.01-1.57C638.73,299.4,638.37,299.98,638.69,300.76z",
  "M634.17,300.3c0.32,0.79,1.22,1.48,2,1.56c0.79,0.08,1.16-0.49,0.84-1.28c-0.32-0.79-1.22-1.49-2.01-1.57C634.22,298.94,633.85,299.52,634.17,300.3z",
  "M629.63,299.85c0.31,0.79,1.21,1.48,2,1.56c0.79,0.08,1.18-0.49,0.86-1.28c-0.32-0.79-1.21-1.49-2-1.56C629.7,298.49,629.31,299.07,629.63,299.85z",
  "M601.98,297.29c0.27,0.78,1.15,1.48,1.96,1.55c0.81,0.07,1.24-0.51,0.96-1.29c-0.28-0.78-1.16-1.48-1.96-1.55C602.13,295.92,601.7,296.5,601.98,297.29z",
  "M583.19,295.72c0.25,0.78,1.11,1.47,1.93,1.54c0.82,0.07,1.28-0.51,1.03-1.3c-0.25-0.78-1.12-1.48-1.94-1.54C583.4,294.35,582.94,294.93,583.19,295.72z",
  "M578.46,295.34c0.24,0.78,1.1,1.47,1.93,1.54c0.82,0.06,1.29-0.52,1.05-1.3c-0.25-0.78-1.11-1.47-1.93-1.54C578.68,293.97,578.21,294.56,578.46,295.34z",
  "M573.7,294.98c0.24,0.78,1.1,1.47,1.92,1.53c0.83,0.06,1.3-0.52,1.06-1.3c-0.24-0.78-1.1-1.47-1.92-1.54C573.94,293.6,573.47,294.19,573.7,294.98z",
  "M568.94,294.62c0.23,0.78,1.09,1.47,1.92,1.53c0.83,0.06,1.31-0.52,1.08-1.31c-0.23-0.78-1.09-1.47-1.92-1.53C569.19,293.25,568.71,293.83,568.94,294.62z",
  "M564.16,294.27c0.22,0.78,1.08,1.47,1.91,1.53c0.83,0.06,1.32-0.52,1.1-1.31c-0.23-0.78-1.08-1.47-1.91-1.53C564.42,292.89,563.93,293.48,564.16,294.27z",
  "M554.55,293.59c0.21,0.78,1.06,1.46,1.9,1.52c0.84,0.06,1.34-0.53,1.13-1.31c-0.22-0.78-1.07-1.47-1.9-1.53C554.84,292.21,554.34,292.8,554.55,293.59z",
  "M455.93,288.74c0.1,0.78,0.88,1.44,1.76,1.46c0.87,0.03,1.5-0.59,1.4-1.37c-0.1-0.78-0.89-1.44-1.76-1.47C456.46,287.34,455.83,287.96,455.93,288.74z",
  "M450.91,288.6c0.09,0.78,0.87,1.44,1.75,1.46c0.87,0.02,1.5-0.59,1.41-1.37c-0.1-0.78-0.88-1.44-1.75-1.47C451.44,287.2,450.82,287.81,450.91,288.6z",
  "M430.75,288.12c0.07,0.78,0.84,1.43,1.72,1.45c0.88,0.02,1.53-0.6,1.45-1.38c-0.08-0.78-0.85-1.44-1.72-1.45C431.33,286.71,430.68,287.34,430.75,288.12z",
  "M425.7,288.03c0.07,0.78,0.83,1.43,1.71,1.45c0.88,0.02,1.53-0.6,1.46-1.39c-0.07-0.78-0.84-1.43-1.71-1.45C426.29,286.62,425.64,287.24,425.7,288.03z",
  "M420.64,287.94c0.06,0.78,0.82,1.43,1.7,1.44c0.88,0.01,1.54-0.61,1.48-1.39c-0.06-0.78-0.83-1.43-1.7-1.45C421.24,286.53,420.58,287.16,420.64,287.94z",
  "M415.58,287.87c0.06,0.78,0.81,1.43,1.69,1.44c0.88,0.01,1.55-0.61,1.49-1.39c-0.06-0.78-0.82-1.43-1.69-1.44C416.19,286.46,415.53,287.09,415.58,287.87z",
  "M410.52,287.8c0.05,0.78,0.8,1.43,1.68,1.44c0.88,0.01,1.55-0.61,1.5-1.4c-0.05-0.78-0.81-1.43-1.69-1.44C411.14,286.39,410.47,287.02,410.52,287.8z",
  "M405.45,287.75c0.05,0.78,0.8,1.42,1.68,1.43c0.88,0.01,1.56-0.62,1.51-1.4c-0.05-0.78-0.8-1.43-1.68-1.44C406.08,286.34,405.41,286.97,405.45,287.75z",
  "M400.38,287.71c0.04,0.78,0.79,1.42,1.67,1.43c0.88,0.01,1.56-0.62,1.52-1.4c-0.04-0.78-0.79-1.43-1.67-1.43C401.02,286.29,400.34,286.92,400.38,287.71z",
  "M395.31,287.67c0.03,0.78,0.78,1.42,1.66,1.43c0.88,0.01,1.57-0.62,1.53-1.41c-0.04-0.78-0.78-1.42-1.66-1.43C395.96,286.26,395.27,286.89,395.31,287.67z",
  "M390.23,287.65c0.03,0.78,0.77,1.42,1.65,1.42c0.88,0,1.57-0.63,1.54-1.41c-0.03-0.78-0.77-1.42-1.65-1.43C390.89,286.23,390.2,286.87,390.23,287.65z",
  "M385.16,287.64c0.02,0.78,0.76,1.42,1.64,1.42c0.88,0,1.58-0.63,1.55-1.41c-0.03-0.78-0.76-1.42-1.64-1.42C385.82,286.22,385.13,286.85,385.16,287.64z",
  "M380.08,287.63c0.02,0.78,0.75,1.42,1.63,1.42c0.88,0,1.58-0.63,1.56-1.42c-0.02-0.78-0.75-1.42-1.63-1.42C380.76,286.21,380.06,286.85,380.08,287.63z",
  "M263.65,290.37c-0.1,0.78,0.53,1.38,1.4,1.34c0.87-0.04,1.66-0.7,1.75-1.49c0.1-0.78-0.53-1.39-1.39-1.34C264.54,288.92,263.75,289.58,263.65,290.37z",
  "M258.64,290.6c-0.11,0.78,0.51,1.38,1.39,1.34c0.87-0.04,1.66-0.71,1.76-1.49c0.1-0.78-0.52-1.38-1.38-1.34C259.54,289.15,258.75,289.82,258.64,290.6z",
  "M253.64,290.85c-0.11,0.78,0.5,1.38,1.37,1.33c0.87-0.04,1.66-0.71,1.77-1.49c0.11-0.78-0.51-1.38-1.37-1.34C254.55,289.4,253.75,290.07,253.64,290.85z",
  "M248.65,291.11c-0.12,0.78,0.49,1.38,1.36,1.33c0.87-0.05,1.66-0.71,1.78-1.49c0.11-0.78-0.5-1.38-1.36-1.34C249.56,289.66,248.77,290.33,248.65,291.11z",
  "M243.66,291.37c-0.12,0.78,0.48,1.38,1.35,1.33c0.87-0.05,1.66-0.72,1.78-1.5c0.12-0.78-0.48-1.38-1.35-1.33C244.58,289.92,243.78,290.59,243.66,291.37z",
  "M238.68,291.65c-0.13,0.78,0.47,1.37,1.34,1.33c0.87-0.05,1.67-0.72,1.79-1.5c0.12-0.78-0.47-1.38-1.33-1.33C239.62,290.19,238.81,290.87,238.68,291.65z",
  "M233.71,291.94c-0.13,0.78,0.46,1.37,1.32,1.32c0.86-0.05,1.67-0.72,1.8-1.5c0.13-0.78-0.46-1.38-1.32-1.33C234.65,290.48,233.85,291.15,233.71,291.94z",
  "M228.75,292.23c-0.14,0.78,0.45,1.37,1.31,1.32c0.86-0.05,1.67-0.72,1.8-1.51c0.14-0.78-0.45-1.37-1.31-1.32C229.7,290.77,228.89,291.45,228.75,292.23z",
  "M223.8,292.53c-0.14,0.78,0.44,1.37,1.3,1.32c0.86-0.05,1.67-0.73,1.81-1.51c0.14-0.78-0.44-1.37-1.29-1.32C224.76,291.07,223.95,291.75,223.8,292.53z",
  "M218.86,292.84c-0.15,0.78,0.43,1.37,1.28,1.31c0.86-0.05,1.67-0.73,1.82-1.51c0.15-0.78-0.43-1.37-1.28-1.32C219.83,291.38,219.01,292.06,218.86,292.84z",
  "M213.93,293.17c-0.16,0.78,0.41,1.37,1.27,1.31c0.86-0.06,1.67-0.73,1.82-1.51c0.15-0.78-0.42-1.37-1.27-1.31C214.91,291.71,214.09,292.39,213.93,293.17z",
  "M209.01,293.5c-0.16,0.78,0.4,1.36,1.26,1.31c0.86-0.06,1.67-0.74,1.83-1.52c0.16-0.78-0.4-1.37-1.25-1.31C210,292.03,209.17,292.72,209.01,293.5z",
  "M204.1,293.84c-0.17,0.78,0.39,1.36,1.24,1.3c0.85-0.06,1.68-0.74,1.84-1.52c0.16-0.78-0.39-1.37-1.24-1.31C205.1,292.37,204.27,293.06,204.1,293.84z",
  "M199.2,294.18c-0.17,0.78,0.38,1.36,1.23,1.3c0.85-0.06,1.68-0.74,1.85-1.52c0.17-0.78-0.38-1.37-1.23-1.31C200.21,292.72,199.38,293.4,199.2,294.18z",
  "M194.32,294.54c-0.18,0.78,0.37,1.36,1.22,1.3c0.85-0.06,1.68-0.74,1.85-1.52c0.17-0.78-0.37-1.37-1.21-1.3C195.33,293.07,194.5,293.76,194.32,294.54z",
  "M189.44,294.9c-0.18,0.78,0.35,1.36,1.2,1.3c0.85-0.06,1.68-0.75,1.86-1.53c0.18-0.78-0.36-1.36-1.2-1.3C190.46,293.44,189.63,294.12,189.44,294.9z",
  "M184.58,295.28c-0.19,0.78,0.34,1.36,1.19,1.29c0.85-0.06,1.68-0.75,1.87-1.53c0.19-0.78-0.34-1.36-1.18-1.3C185.61,293.81,184.77,294.5,184.58,295.28z",
  "M680.5,301.16c0.4,0.79,1.33,1.5,2.07,1.59c0.74,0.09,1.01-0.48,0.6-1.27c-0.41-0.79-1.34-1.51-2.07-1.59C680.37,299.8,680.1,300.37,680.5,301.16z",
  "M667.63,299.65c0.38,0.79,1.3,1.5,2.05,1.59c0.75,0.09,1.05-0.48,0.66-1.27c-0.39-0.79-1.31-1.5-2.05-1.59C667.54,298.29,667.25,298.86,667.63,299.65z",
  "M654.56,298.19c0.36,0.79,1.27,1.5,2.03,1.58c0.76,0.08,1.09-0.49,0.72-1.28c-0.37-0.79-1.28-1.5-2.04-1.58C654.52,296.82,654.2,297.4,654.56,298.19z",
  "M650.16,297.71c0.36,0.79,1.26,1.5,2.03,1.58c0.77,0.08,1.1-0.49,0.74-1.28c-0.36-0.79-1.27-1.5-2.03-1.58C650.13,296.35,649.8,296.92,650.16,297.71z",
  "M600.41,292.84c0.28,0.79,1.16,1.48,1.96,1.56c0.8,0.07,1.23-0.51,0.94-1.3c-0.29-0.79-1.17-1.49-1.97-1.56C600.54,291.47,600.13,292.05,600.41,292.84z",
  "M562.87,289.82c0.23,0.79,1.09,1.48,1.91,1.54c0.83,0.06,1.31-0.53,1.07-1.32c-0.24-0.79-1.09-1.48-1.91-1.54C563.12,288.44,562.64,289.03,562.87,289.82z",
  "M42.94,237.66c-0.52,0.8-0.46,1.36,0.14,1.24c0.6-0.11,1.51-0.85,2.02-1.65c0.52-0.8,0.45-1.36-0.14-1.24C44.36,236.13,43.46,236.87,42.94,237.66z",
  "M39.51,238.31c-0.52,0.8-0.47,1.35,0.13,1.24c0.59-0.11,1.5-0.85,2.02-1.65c0.52-0.8,0.46-1.35-0.13-1.24C40.94,236.77,40.03,237.51,39.51,238.31z",
  "M36.11,238.96c-0.53,0.8-0.48,1.35,0.11,1.24c0.59-0.11,1.5-0.85,2.02-1.65c0.52-0.8,0.47-1.35-0.12-1.24C37.54,237.42,36.64,238.16,36.11,238.96z",
  "M56.24,231.22c-0.51,0.8-0.43,1.36,0.17,1.25c0.61-0.11,1.51-0.85,2.02-1.65c0.51-0.8,0.43-1.36-0.18-1.25C57.66,229.68,56.75,230.42,56.24,231.22z",
  "M52.76,231.86c-0.51,0.8-0.44,1.36,0.16,1.25c0.6-0.11,1.51-0.85,2.02-1.65c0.51-0.8,0.44-1.36-0.16-1.25C54.18,230.32,53.28,231.06,52.76,231.86z",
  "M49.31,232.5c-0.52,0.8-0.45,1.36,0.15,1.25c0.6-0.11,1.5-0.85,2.02-1.65c0.52-0.8,0.45-1.36-0.15-1.25C50.73,230.96,49.83,231.7,49.31,232.5z",
  "M45.88,233.15c-0.52,0.8-0.46,1.36,0.13,1.24c0.6-0.11,1.5-0.85,2.02-1.65c0.52-0.8,0.46-1.36-0.14-1.24C47.3,231.61,46.4,232.35,45.88,233.15z",
  "M59.13,226.69c-0.51,0.8-0.44,1.36,0.17,1.25c0.6-0.11,1.51-0.85,2.02-1.65c0.51-0.8,0.43-1.36-0.17-1.25C60.55,225.15,59.64,225.89,59.13,226.69z",
  "M55.67,227.33c-0.52,0.8-0.45,1.36,0.15,1.25c0.6-0.11,1.5-0.85,2.02-1.65c0.51-0.8,0.44-1.36-0.15-1.25C57.09,225.8,56.19,226.53,55.67,227.33z",
  "M52.24,227.98c-0.52,0.8-0.46,1.36,0.14,1.24c0.6-0.11,1.5-0.85,2.02-1.65c0.52-0.8,0.45-1.36-0.14-1.24C53.66,226.44,52.75,227.18,52.24,227.98z",
  "M48.82,228.63c-0.52,0.8-0.46,1.35,0.13,1.24c0.59-0.11,1.49-0.85,2.01-1.65c0.52-0.8,0.46-1.36-0.13-1.24C50.24,227.1,49.34,227.84,48.82,228.63z",
  "M45.43,229.29c-0.52,0.8-0.47,1.35,0.12,1.24c0.59-0.11,1.49-0.85,2.01-1.65c0.52-0.8,0.47-1.35-0.12-1.24C46.85,227.75,45.95,228.49,45.43,229.29z",
  "M38.72,230.61c-0.53,0.8-0.49,1.35,0.09,1.23c0.58-0.11,1.48-0.85,2.01-1.65c0.53-0.8,0.49-1.35-0.09-1.23C40.14,229.07,39.25,229.81,38.72,230.61z",
  "M48.39,224.78c-0.52,0.8-0.48,1.35,0.11,1.24c0.59-0.11,1.49-0.85,2.01-1.65c0.52-0.8,0.47-1.35-0.11-1.24C49.82,223.24,48.92,223.98,48.39,224.78z",
  "M455.36,284.3c0.1,0.79,0.89,1.45,1.75,1.47c0.87,0.03,1.48-0.59,1.38-1.38c-0.11-0.79-0.89-1.45-1.76-1.48C455.88,282.89,455.26,283.51,455.36,284.3z",
  "M450.37,284.15c0.1,0.79,0.88,1.45,1.75,1.47c0.87,0.02,1.49-0.59,1.39-1.38c-0.1-0.79-0.88-1.45-1.75-1.47C450.9,282.74,450.28,283.36,450.37,284.15z",
  "M440.37,283.9c0.09,0.79,0.86,1.44,1.73,1.46c0.87,0.02,1.5-0.6,1.41-1.39c-0.09-0.79-0.87-1.45-1.73-1.47C440.91,282.48,440.28,283.11,440.37,283.9z",
  "M435.36,283.78c0.08,0.79,0.85,1.44,1.72,1.46c0.87,0.02,1.51-0.6,1.43-1.39c-0.08-0.79-0.86-1.45-1.72-1.47C435.91,282.37,435.28,282.99,435.36,283.78z",
  "M430.34,283.68c0.07,0.79,0.84,1.44,1.71,1.46c0.87,0.02,1.52-0.6,1.44-1.39c-0.08-0.79-0.85-1.44-1.71-1.46C430.91,282.26,430.26,282.89,430.34,283.68z",
  "M425.32,283.58c0.07,0.79,0.83,1.44,1.7,1.46c0.87,0.02,1.52-0.61,1.45-1.4c-0.07-0.79-0.84-1.44-1.71-1.46C425.9,282.17,425.25,282.79,425.32,283.58z",
  "M420.29,283.5c0.06,0.79,0.82,1.44,1.7,1.45c0.87,0.01,1.53-0.61,1.46-1.4c-0.07-0.79-0.83-1.44-1.7-1.46C420.88,282.08,420.23,282.71,420.29,283.5z",
  "M415.26,283.42c0.06,0.79,0.81,1.44,1.69,1.45c0.87,0.01,1.53-0.61,1.47-1.4c-0.06-0.79-0.82-1.44-1.69-1.45C415.86,282,415.2,282.64,415.26,283.42z",
  "M410.23,283.36c0.05,0.79,0.8,1.43,1.68,1.45c0.88,0.01,1.54-0.62,1.48-1.41c-0.06-0.79-0.81-1.44-1.68-1.45C410.84,281.94,410.18,282.57,410.23,283.36z",
  "M405.19,283.31c0.05,0.79,0.79,1.43,1.67,1.44c0.88,0.01,1.55-0.62,1.49-1.41c-0.05-0.79-0.8-1.44-1.67-1.45C405.81,281.88,405.14,282.52,405.19,283.31z",
  "M400.15,283.26c0.04,0.79,0.78,1.43,1.66,1.44c0.88,0.01,1.55-0.62,1.5-1.41c-0.05-0.79-0.79-1.44-1.66-1.44C400.78,281.84,400.11,282.47,400.15,283.26z",
  "M395.11,283.23c0.04,0.79,0.78,1.43,1.65,1.44c0.88,0.01,1.56-0.63,1.52-1.42c-0.04-0.79-0.78-1.43-1.65-1.44C395.75,281.81,395.07,282.44,395.11,283.23z",
  "M390.06,283.21c0.03,0.79,0.77,1.43,1.64,1.43c0.88,0,1.56-0.63,1.53-1.42c-0.04-0.79-0.77-1.43-1.64-1.44C390.72,281.78,390.03,282.42,390.06,283.21z",
  "M385.02,283.19c0.02,0.79,0.76,1.43,1.63,1.43c0.88,0,1.57-0.63,1.54-1.42c-0.03-0.79-0.76-1.43-1.63-1.43C385.68,281.77,384.99,282.41,385.02,283.19z",
  "M379.97,283.19c0.02,0.79,0.75,1.43,1.62,1.43c0.88,0,1.57-0.64,1.55-1.43c-0.02-0.79-0.75-1.43-1.62-1.43C380.64,281.76,379.95,282.4,379.97,283.19z",
  "M264.24,285.93c-0.11,0.79,0.51,1.39,1.38,1.35c0.87-0.04,1.65-0.71,1.75-1.5c0.1-0.79-0.51-1.39-1.38-1.35C265.13,284.47,264.34,285.15,264.24,285.93z",
  "M259.26,286.17c-0.11,0.79,0.5,1.39,1.37,1.35c0.87-0.04,1.65-0.71,1.76-1.5c0.11-0.79-0.5-1.39-1.36-1.35C260.16,284.71,259.37,285.38,259.26,286.17z",
  "M254.29,286.42c-0.12,0.79,0.49,1.39,1.35,1.34c0.86-0.04,1.65-0.71,1.77-1.5c0.11-0.79-0.49-1.39-1.35-1.35C255.2,284.96,254.4,285.63,254.29,286.42z",
  "M249.32,286.68c-0.12,0.79,0.48,1.39,1.34,1.34c0.86-0.04,1.66-0.72,1.78-1.5c0.12-0.79-0.48-1.39-1.34-1.34C250.24,285.21,249.45,285.89,249.32,286.68z",
  "M244.37,286.94c-0.13,0.79,0.47,1.38,1.33,1.34c0.86-0.05,1.66-0.72,1.78-1.51c0.12-0.79-0.47-1.39-1.33-1.34C245.3,285.48,244.5,286.16,244.37,286.94z",
  "M239.42,287.22c-0.13,0.79,0.46,1.38,1.32,1.33c0.86-0.05,1.66-0.72,1.79-1.51c0.13-0.79-0.46-1.39-1.31-1.34C240.36,285.75,239.56,286.43,239.42,287.22z",
  "M234.48,287.5c-0.14,0.79,0.44,1.38,1.3,1.33c0.86-0.05,1.66-0.73,1.8-1.51c0.14-0.79-0.45-1.39-1.3-1.34C235.43,286.04,234.62,286.72,234.48,287.5z",
  "M229.56,287.8c-0.15,0.79,0.43,1.38,1.29,1.33c0.86-0.05,1.66-0.73,1.8-1.51c0.14-0.79-0.43-1.38-1.29-1.33C230.51,286.33,229.7,287.01,229.56,287.8z",
  "M224.64,288.1c-0.15,0.79,0.42,1.38,1.28,1.33c0.86-0.05,1.67-0.73,1.81-1.52c0.15-0.79-0.42-1.38-1.27-1.33C225.6,286.63,224.79,287.32,224.64,288.1z",
  "M219.73,288.42c-0.16,0.79,0.41,1.38,1.26,1.32c0.85-0.05,1.67-0.73,1.82-1.52c0.15-0.79-0.41-1.38-1.26-1.33C220.7,286.95,219.89,287.63,219.73,288.42z",
  "M214.83,288.74c-0.16,0.79,0.4,1.38,1.25,1.32c0.85-0.06,1.67-0.74,1.83-1.52c0.16-0.79-0.4-1.38-1.25-1.32C215.81,287.27,214.99,287.95,214.83,288.74z",
  "M209.94,289.07c-0.17,0.79,0.39,1.37,1.24,1.32c0.85-0.06,1.67-0.74,1.83-1.53c0.16-0.79-0.39-1.38-1.23-1.32C210.93,287.6,210.11,288.28,209.94,289.07z",
  "M205.07,289.41c-0.17,0.79,0.37,1.37,1.22,1.31c0.85-0.06,1.67-0.74,1.84-1.53c0.17-0.79-0.37-1.38-1.22-1.32C206.07,287.93,205.24,288.62,205.07,289.41z",
  "M200.2,289.76c-0.18,0.79,0.36,1.37,1.21,1.31c0.85-0.06,1.67-0.74,1.85-1.53c0.18-0.79-0.36-1.38-1.2-1.31C201.21,288.28,200.38,288.97,200.2,289.76z",
  "M195.35,290.11c-0.19,0.79,0.35,1.37,1.19,1.31c0.84-0.06,1.67-0.75,1.85-1.53c0.18-0.79-0.35-1.37-1.19-1.31C196.36,288.64,195.53,289.33,195.35,290.11z",
  "M190.5,290.48c-0.19,0.79,0.34,1.37,1.18,1.31c0.84-0.06,1.67-0.75,1.86-1.54c0.19-0.79-0.34-1.37-1.18-1.31C191.53,289,190.7,289.69,190.5,290.48z",
  "M185.68,290.85c-0.2,0.79,0.32,1.37,1.16,1.3c0.84-0.06,1.68-0.75,1.87-1.54c0.19-0.79-0.33-1.37-1.16-1.31C186.71,289.37,185.87,290.07,185.68,290.85z",
  "M180.86,291.23c-0.2,0.79,0.31,1.37,1.15,1.3c0.84-0.07,1.68-0.76,1.88-1.54c0.2-0.79-0.31-1.37-1.15-1.3C181.9,289.75,181.06,290.45,180.86,291.23z",
  "M669.72,295.67c0.4,0.79,1.32,1.51,2.06,1.59c0.74,0.09,1.02-0.48,0.62-1.28c-0.4-0.79-1.32-1.51-2.06-1.6C669.6,294.31,669.33,294.88,669.72,295.67z",
  "M661.15,294.68c0.38,0.79,1.3,1.5,2.05,1.59c0.75,0.09,1.04-0.49,0.66-1.28c-0.39-0.79-1.3-1.51-2.05-1.59C661.06,293.31,660.77,293.89,661.15,294.68z",
  "M656.83,294.2c0.37,0.79,1.29,1.5,2.04,1.59c0.75,0.08,1.05-0.49,0.68-1.28c-0.38-0.79-1.3-1.51-2.04-1.59C656.76,292.83,656.46,293.4,656.83,294.2z",
  "M652.49,293.71c0.37,0.79,1.28,1.5,2.03,1.59c0.76,0.08,1.07-0.49,0.69-1.28c-0.37-0.79-1.28-1.51-2.04-1.59C652.44,292.34,652.13,292.92,652.49,293.71z",
  "M648.13,293.23c0.36,0.79,1.27,1.5,2.03,1.58c0.76,0.08,1.08-0.49,0.71-1.29c-0.37-0.79-1.27-1.5-2.03-1.59C648.09,291.86,647.77,292.44,648.13,293.23z",
  "M643.75,292.76c0.35,0.79,1.26,1.5,2.02,1.58c0.76,0.08,1.09-0.49,0.73-1.29c-0.36-0.79-1.27-1.5-2.03-1.59C643.72,291.39,643.39,291.97,643.75,292.76z",
  "M639.34,292.3c0.35,0.79,1.25,1.5,2.02,1.58c0.77,0.08,1.11-0.5,0.75-1.29c-0.35-0.79-1.26-1.5-2.02-1.58C639.33,290.92,638.99,291.5,639.34,292.3z",
  "M607.94,289.2c0.3,0.79,1.19,1.49,1.98,1.57c0.79,0.07,1.18-0.51,0.88-1.3c-0.31-0.79-1.19-1.5-1.98-1.57C608.03,287.82,607.64,288.4,607.94,289.2z",
  "M584.95,287.18c0.27,0.79,1.14,1.49,1.95,1.56c0.81,0.07,1.24-0.52,0.97-1.31c-0.27-0.79-1.15-1.49-1.95-1.56C585.11,285.8,584.68,286.39,584.95,287.18z",
  "M580.3,286.8c0.26,0.79,1.13,1.49,1.94,1.55c0.81,0.07,1.25-0.52,0.98-1.31c-0.27-0.79-1.14-1.49-1.94-1.56C580.48,285.42,580.04,286.01,580.3,286.8z",
  "M459.73,279.98c0.11,0.79,0.9,1.46,1.76,1.49c0.86,0.03,1.47-0.59,1.35-1.38c-0.12-0.79-0.91-1.46-1.76-1.49C460.22,278.56,459.62,279.18,459.73,279.98z",
  "M434.89,279.31c0.08,0.79,0.85,1.45,1.72,1.47c0.87,0.02,1.5-0.61,1.41-1.4c-0.09-0.79-0.86-1.46-1.72-1.47C435.44,277.89,434.81,278.51,434.89,279.31z",
  "M429.91,279.2c0.08,0.79,0.84,1.45,1.71,1.47c0.87,0.02,1.5-0.61,1.42-1.4c-0.08-0.79-0.85-1.45-1.71-1.47C430.47,277.78,429.83,278.41,429.91,279.2z",
  "M424.92,279.11c0.07,0.79,0.83,1.45,1.7,1.46c0.87,0.02,1.51-0.61,1.43-1.41c-0.08-0.79-0.84-1.45-1.7-1.47C425.49,277.68,424.85,278.32,424.92,279.11z",
  "M419.93,279.03c0.07,0.79,0.82,1.45,1.69,1.46c0.87,0.01,1.52-0.62,1.45-1.41c-0.07-0.79-0.83-1.45-1.69-1.46C420.51,277.6,419.86,278.23,419.93,279.03z",
  "M414.93,278.95c0.06,0.79,0.81,1.45,1.68,1.46c0.87,0.01,1.52-0.62,1.46-1.41c-0.06-0.79-0.82-1.45-1.68-1.46C415.52,277.52,414.87,278.16,414.93,278.95z",
  "M409.93,278.89c0.05,0.79,0.8,1.44,1.67,1.45c0.87,0.01,1.53-0.62,1.47-1.42c-0.06-0.79-0.81-1.45-1.67-1.46C410.53,277.46,409.87,278.09,409.93,278.89z",
  "M404.92,278.83c0.05,0.79,0.79,1.44,1.66,1.45c0.87,0.01,1.53-0.62,1.48-1.42c-0.05-0.79-0.8-1.45-1.66-1.46C405.53,277.4,404.87,278.04,404.92,278.83z",
  "M399.91,278.79c0.04,0.79,0.78,1.44,1.65,1.45c0.87,0.01,1.54-0.63,1.49-1.42c-0.05-0.79-0.79-1.44-1.65-1.45C400.54,277.36,399.87,278,399.91,278.79z",
  "M394.9,278.76c0.04,0.79,0.77,1.44,1.64,1.44c0.87,0.01,1.54-0.63,1.5-1.43c-0.04-0.79-0.78-1.44-1.65-1.45C395.54,277.32,394.86,277.96,394.9,278.76z",
  "M389.89,278.74c0.03,0.79,0.76,1.44,1.64,1.44c0.87,0,1.55-0.63,1.51-1.43c-0.04-0.79-0.77-1.44-1.64-1.45C390.53,277.3,389.85,277.94,389.89,278.74z",
  "M384.87,278.72c0.03,0.79,0.75,1.44,1.63,1.44c0.87,0,1.56-0.64,1.53-1.43c-0.03-0.79-0.76-1.44-1.63-1.44C385.53,277.29,384.84,277.93,384.87,278.72z",
  "M269.8,281.24c-0.1,0.79,0.51,1.4,1.37,1.36c0.86-0.04,1.64-0.71,1.74-1.5c0.1-0.79-0.51-1.4-1.37-1.37C270.68,279.77,269.9,280.45,269.8,281.24z",
  "M264.85,281.47c-0.11,0.79,0.5,1.4,1.36,1.36c0.86-0.04,1.64-0.71,1.75-1.5c0.11-0.79-0.5-1.4-1.36-1.36C265.74,280,264.96,280.68,264.85,281.47z",
  "M259.9,281.71c-0.12,0.79,0.49,1.4,1.35,1.36c0.86-0.04,1.65-0.72,1.76-1.51c0.11-0.79-0.49-1.4-1.35-1.36C260.8,280.24,260.01,280.92,259.9,281.71z",
  "M254.96,281.96c-0.12,0.79,0.48,1.4,1.33,1.35c0.86-0.04,1.65-0.72,1.77-1.51c0.12-0.79-0.48-1.4-1.33-1.36C255.87,280.49,255.08,281.17,254.96,281.96z",
  "M250.03,282.22c-0.13,0.79,0.46,1.39,1.32,1.35c0.86-0.05,1.65-0.72,1.77-1.51c0.12-0.79-0.47-1.4-1.32-1.35C250.95,280.74,250.16,281.42,250.03,282.22z",
  "M245.11,282.48c-0.13,0.79,0.45,1.39,1.31,1.35c0.86-0.05,1.65-0.72,1.78-1.52c0.13-0.79-0.46-1.4-1.31-1.35C246.04,281.01,245.24,281.69,245.11,282.48z",
  "M240.19,282.76c-0.14,0.79,0.44,1.39,1.3,1.34c0.85-0.05,1.66-0.73,1.79-1.52c0.14-0.79-0.44-1.4-1.29-1.35C241.13,281.29,240.33,281.97,240.19,282.76z",
  "M235.29,283.05c-0.15,0.79,0.43,1.39,1.28,1.34c0.85-0.05,1.66-0.73,1.8-1.52c0.14-0.79-0.43-1.39-1.28-1.34C236.24,281.57,235.43,282.25,235.29,283.05z",
  "M230.39,283.34c-0.15,0.79,0.42,1.39,1.27,1.34c0.85-0.05,1.66-0.73,1.81-1.52c0.15-0.79-0.42-1.39-1.27-1.34C231.35,281.86,230.54,282.55,230.39,283.34z",
  "M225.51,283.64c-0.16,0.79,0.41,1.39,1.25,1.33c0.85-0.05,1.66-0.74,1.81-1.53c0.15-0.79-0.41-1.39-1.25-1.34C226.48,282.17,225.66,282.85,225.51,283.64z",
  "M220.63,283.96c-0.16,0.79,0.39,1.39,1.24,1.33c0.85-0.05,1.66-0.74,1.82-1.53c0.16-0.79-0.4-1.39-1.24-1.33C221.61,282.48,220.79,283.17,220.63,283.96z",
  "M215.76,284.28c-0.17,0.79,0.38,1.38,1.23,1.33c0.85-0.06,1.66-0.74,1.83-1.53c0.16-0.79-0.38-1.39-1.22-1.33C216.75,282.8,215.93,283.49,215.76,284.28z",
  "M210.91,284.61c-0.17,0.79,0.37,1.38,1.21,1.33c0.84-0.06,1.66-0.74,1.84-1.53c0.17-0.79-0.37-1.39-1.21-1.33C211.91,283.13,211.08,283.82,210.91,284.61z",
  "M206.07,284.95c-0.18,0.79,0.36,1.38,1.2,1.32c0.84-0.06,1.67-0.75,1.84-1.54c0.18-0.79-0.36-1.39-1.2-1.33C207.07,283.47,206.25,284.16,206.07,284.95z",
  "M201.23,285.3c-0.19,0.79,0.34,1.38,1.18,1.32c0.84-0.06,1.67-0.75,1.85-1.54c0.18-0.79-0.35-1.38-1.18-1.32C202.25,283.82,201.42,284.51,201.23,285.3z",
  "M196.41,285.66c-0.19,0.79,0.33,1.38,1.17,1.32c0.84-0.06,1.67-0.75,1.86-1.54c0.19-0.79-0.33-1.38-1.17-1.32C197.44,284.17,196.61,284.87,196.41,285.66z",
  "M191.61,286.02c-0.2,0.79,0.32,1.38,1.15,1.31c0.84-0.06,1.67-0.75,1.86-1.54c0.19-0.79-0.32-1.38-1.15-1.32C192.64,284.54,191.81,285.23,191.61,286.02z",
  "M186.81,286.4c-0.21,0.79,0.31,1.38,1.14,1.31c0.83-0.07,1.67-0.76,1.87-1.55c0.2-0.79-0.31-1.38-1.14-1.31C187.86,284.91,187.02,285.61,186.81,286.4z",
  "M182.03,286.78c-0.21,0.79,0.29,1.38,1.12,1.31c0.83-0.07,1.67-0.76,1.88-1.55c0.21-0.79-0.3-1.38-1.12-1.31C183.08,285.29,182.24,285.99,182.03,286.78z",
  "M667.47,291.18c0.4,0.8,1.32,1.51,2.05,1.6c0.73,0.09,1-0.49,0.59-1.28c-0.41-0.8-1.33-1.51-2.06-1.6C667.33,289.81,667.07,290.38,667.47,291.18z",
  "M654.7,289.7c0.38,0.8,1.29,1.51,2.04,1.59c0.75,0.08,1.04-0.49,0.65-1.29c-0.39-0.8-1.3-1.51-2.04-1.6C654.61,288.33,654.31,288.91,654.7,289.7z",
  "M650.39,289.22c0.38,0.8,1.29,1.51,2.03,1.59c0.75,0.08,1.05-0.49,0.67-1.29c-0.38-0.8-1.29-1.51-2.04-1.59C650.32,287.85,650.02,288.42,650.39,289.22z",
  "M646.07,288.74c0.37,0.8,1.28,1.51,2.03,1.59c0.75,0.08,1.06-0.49,0.69-1.29c-0.37-0.8-1.28-1.51-2.03-1.59C646.01,287.37,645.7,287.95,646.07,288.74z",
  "M641.72,288.27c0.36,0.8,1.27,1.51,2.02,1.59c0.76,0.08,1.08-0.5,0.71-1.29c-0.36-0.8-1.27-1.51-2.03-1.59C641.68,286.89,641.36,287.47,641.72,288.27z",
  "M637.35,287.8c0.35,0.8,1.26,1.51,2.02,1.59c0.76,0.08,1.09-0.5,0.73-1.29c-0.36-0.8-1.26-1.51-2.02-1.59C637.32,286.43,637,287.01,637.35,287.8z",
  "M588,283.08c0.28,0.8,1.16,1.5,1.95,1.56c0.8,0.07,1.21-0.52,0.92-1.32c-0.29-0.8-1.16-1.5-1.96-1.57C588.13,281.69,587.72,282.28,588,283.08z",
  "M583.41,282.69c0.28,0.8,1.15,1.5,1.95,1.56c0.8,0.07,1.22-0.52,0.94-1.32c-0.28-0.8-1.16-1.5-1.95-1.57C583.55,281.3,583.13,281.89,583.41,282.69z",
  "M574.16,281.93c0.26,0.8,1.13,1.49,1.93,1.56c0.81,0.06,1.24-0.53,0.97-1.32c-0.27-0.8-1.14-1.5-1.94-1.56C574.33,280.54,573.9,281.14,574.16,281.93z",
  "M564.86,281.21c0.25,0.8,1.11,1.49,1.92,1.55c0.81,0.06,1.26-0.53,1.01-1.33c-0.26-0.8-1.12-1.49-1.92-1.56C565.06,279.81,564.61,280.41,564.86,281.21z",
  "M439.36,274.92c0.09,0.8,0.86,1.46,1.72,1.48c0.86,0.02,1.48-0.61,1.38-1.4c-0.1-0.8-0.87-1.46-1.72-1.49C439.89,273.49,439.27,274.13,439.36,274.92z",
  "M434.42,274.81c0.09,0.8,0.85,1.46,1.71,1.48c0.86,0.02,1.49-0.61,1.4-1.41c-0.09-0.8-0.86-1.46-1.71-1.48C434.95,273.38,434.33,274.01,434.42,274.81z",
  "M429.46,274.71c0.08,0.8,0.84,1.46,1.7,1.48c0.86,0.02,1.49-0.61,1.41-1.41c-0.08-0.8-0.85-1.46-1.7-1.48C430.01,273.27,429.39,273.91,429.46,274.71z",
  "M424.51,274.61c0.07,0.8,0.83,1.46,1.69,1.47c0.86,0.02,1.5-0.62,1.42-1.41c-0.08-0.8-0.84-1.46-1.69-1.48C425.07,273.18,424.43,273.81,424.51,274.61z",
  "M419.55,274.53c0.07,0.8,0.82,1.46,1.68,1.47c0.86,0.01,1.5-0.62,1.43-1.42c-0.07-0.8-0.83-1.46-1.69-1.47C420.12,273.09,419.48,273.73,419.55,274.53z",
  "M414.58,274.45c0.06,0.8,0.81,1.45,1.68,1.47c0.86,0.01,1.51-0.62,1.44-1.42c-0.07-0.8-0.82-1.46-1.68-1.47C415.17,273.02,414.52,273.66,414.58,274.45z",
  "M409.61,274.39c0.06,0.8,0.8,1.45,1.67,1.46c0.86,0.01,1.52-0.63,1.46-1.42c-0.06-0.8-0.81-1.46-1.67-1.47C410.21,272.95,409.56,273.59,409.61,274.39z",
  "M404.64,274.34c0.05,0.8,0.79,1.45,1.66,1.46c0.86,0.01,1.52-0.63,1.47-1.43c-0.05-0.8-0.8-1.45-1.66-1.46C405.25,272.9,404.59,273.54,404.64,274.34z",
  "M399.66,274.29c0.04,0.8,0.78,1.45,1.65,1.46c0.86,0.01,1.53-0.63,1.48-1.43c-0.05-0.8-0.79-1.45-1.65-1.46C400.28,272.85,399.62,273.49,399.66,274.29z",
  "M394.69,274.26c0.04,0.8,0.77,1.45,1.64,1.45c0.87,0.01,1.53-0.64,1.49-1.43c-0.04-0.8-0.78-1.45-1.64-1.46C395.31,272.82,394.65,273.46,394.69,274.26z",
  "M389.7,274.24c0.03,0.8,0.76,1.45,1.63,1.45c0.87,0,1.54-0.64,1.5-1.44c-0.04-0.8-0.77-1.45-1.63-1.45C390.34,272.79,389.67,273.44,389.7,274.24z",
  "M384.72,274.22c0.03,0.8,0.75,1.44,1.62,1.45c0.87,0,1.54-0.64,1.51-1.44c-0.03-0.8-0.76-1.45-1.62-1.45C385.37,272.78,384.69,273.43,384.72,274.22z",
  "M275.33,276.53c-0.1,0.8,0.51,1.41,1.37,1.37c0.86-0.04,1.63-0.71,1.73-1.51c0.1-0.8-0.51-1.41-1.37-1.38C276.21,275.06,275.43,275.73,275.33,276.53z",
  "M270.4,276.75c-0.11,0.8,0.5,1.41,1.35,1.37c0.86-0.04,1.63-0.71,1.74-1.51c0.1-0.8-0.5-1.41-1.35-1.37C271.29,275.28,270.51,275.96,270.4,276.75z",
  "M265.48,276.98c-0.11,0.8,0.49,1.41,1.34,1.37c0.86-0.04,1.64-0.72,1.75-1.51c0.11-0.8-0.49-1.41-1.34-1.37C266.38,275.51,265.6,276.19,265.48,276.98z",
  "M260.57,277.22c-0.12,0.8,0.47,1.41,1.33,1.36c0.85-0.04,1.64-0.72,1.76-1.52c0.12-0.8-0.48-1.41-1.33-1.37C261.48,275.74,260.69,276.42,260.57,277.22z",
  "M255.66,277.47c-0.13,0.8,0.46,1.4,1.32,1.36c0.85-0.04,1.64-0.72,1.77-1.52c0.12-0.8-0.47-1.41-1.31-1.36C256.58,275.99,255.79,276.67,255.66,277.47z",
  "M250.76,277.73c-0.13,0.8,0.45,1.4,1.3,1.36c0.85-0.05,1.64-0.73,1.77-1.52c0.13-0.8-0.45-1.41-1.3-1.36C251.69,276.25,250.9,276.93,250.76,277.73z",
  "M245.88,278c-0.14,0.8,0.44,1.4,1.29,1.35c0.85-0.05,1.65-0.73,1.78-1.52c0.13-0.8-0.44-1.41-1.29-1.36C246.81,276.52,246.01,277.2,245.88,278z",
  "M240.99,278.27c-0.14,0.8,0.43,1.4,1.27,1.35c0.85-0.05,1.65-0.73,1.79-1.53c0.14-0.8-0.43-1.4-1.27-1.35C241.94,276.79,241.14,277.48,240.99,278.27z",
  "M236.12,278.56c-0.15,0.8,0.41,1.4,1.26,1.35c0.85-0.05,1.65-0.73,1.8-1.53c0.15-0.8-0.42-1.4-1.26-1.35C237.08,277.08,236.27,277.76,236.12,278.56z",
  "M231.26,278.86c-0.16,0.8,0.4,1.4,1.25,1.35c0.85-0.05,1.65-0.74,1.81-1.53c0.15-0.8-0.4-1.4-1.24-1.35C232.23,277.37,231.42,278.06,231.26,278.86z",
  "M226.41,279.16c-0.16,0.8,0.39,1.4,1.23,1.34c0.84-0.05,1.65-0.74,1.81-1.53c0.16-0.8-0.39-1.4-1.23-1.35C227.39,277.68,226.57,278.37,226.41,279.16z",
  "M221.57,279.47c-0.17,0.8,0.38,1.39,1.22,1.34c0.84-0.06,1.66-0.74,1.82-1.54c0.16-0.79-0.38-1.4-1.22-1.34C222.55,277.99,221.74,278.68,221.57,279.47z",
  "M216.74,279.8c-0.18,0.8,0.36,1.39,1.2,1.34c0.84-0.06,1.66-0.75,1.83-1.54c0.17-0.79-0.37-1.4-1.2-1.34C217.73,278.31,216.91,279,216.74,279.8z",
  "M211.91,280.13c-0.18,0.79,0.35,1.39,1.19,1.33c0.84-0.06,1.66-0.75,1.84-1.54c0.18-0.8-0.35-1.39-1.19-1.34C212.92,278.64,212.1,279.33,211.91,280.13z",
  "M207.11,280.47c-0.19,0.8,0.34,1.39,1.18,1.33c0.84-0.06,1.66-0.75,1.84-1.55c0.18-0.79-0.34-1.39-1.17-1.33C208.12,278.98,207.29,279.67,207.11,280.47z",
  "M202.31,280.82c-0.19,0.8,0.33,1.39,1.16,1.33c0.83-0.06,1.66-0.75,1.85-1.55c0.19-0.79-0.33-1.39-1.16-1.33C203.33,279.33,202.5,280.02,202.31,280.82z",
  "M197.52,281.18c-0.2,0.79,0.31,1.39,1.15,1.32c0.83-0.06,1.66-0.76,1.86-1.55c0.2-0.79-0.32-1.39-1.14-1.33C198.55,279.69,197.72,280.38,197.52,281.18z",
  "M192.75,281.54c-0.21,0.79,0.3,1.39,1.13,1.32c0.83-0.06,1.66-0.76,1.87-1.55c0.2-0.79-0.3-1.39-1.13-1.32C193.79,280.05,192.95,280.75,192.75,281.54z",
  "M187.99,281.92c-0.21,0.79,0.29,1.38,1.12,1.32c0.83-0.07,1.67-0.76,1.87-1.55c0.21-0.79-0.29-1.39-1.11-1.32C189.04,280.43,188.2,281.12,187.99,281.92z",
  "M183.24,282.3c-0.22,0.79,0.28,1.38,1.1,1.32c0.83-0.07,1.67-0.76,1.88-1.56c0.21-0.79-0.28-1.39-1.1-1.32C184.3,280.81,183.46,281.51,183.24,282.3z",
  "M665.18,286.67c0.41,0.8,1.33,1.51,2.05,1.6c0.73,0.09,0.98-0.49,0.57-1.29c-0.41-0.8-1.33-1.52-2.05-1.6C665.03,285.3,664.77,285.87,665.18,286.67z",
  "M639.66,283.76c0.37,0.8,1.27,1.51,2.02,1.59c0.75,0.08,1.06-0.5,0.69-1.3c-0.37-0.8-1.28-1.51-2.02-1.6C639.6,282.38,639.29,282.96,639.66,283.76z",
  "M635.33,283.3c0.36,0.8,1.26,1.51,2.02,1.59c0.75,0.08,1.07-0.5,0.7-1.3c-0.37-0.8-1.27-1.51-2.02-1.59C635.28,281.91,634.97,282.5,635.33,283.3z",
  "M622.21,281.93c0.34,0.8,1.24,1.51,2,1.59c0.76,0.08,1.11-0.51,0.76-1.3c-0.35-0.8-1.24-1.51-2-1.59C622.21,280.54,621.87,281.13,622.21,281.93z",
  "M617.8,281.49c0.34,0.8,1.23,1.51,2,1.58c0.77,0.08,1.12-0.51,0.78-1.31c-0.34-0.8-1.24-1.51-2-1.59C617.81,280.1,617.47,280.69,617.8,281.49z",
  "M604.45,280.19c0.32,0.8,1.2,1.51,1.98,1.58c0.78,0.07,1.15-0.51,0.83-1.31c-0.32-0.8-1.21-1.51-1.98-1.58S604.13,279.39,604.45,280.19z",
  "M595.45,279.37c0.3,0.8,1.18,1.5,1.97,1.58c0.78,0.07,1.17-0.52,0.87-1.32c-0.31-0.8-1.19-1.51-1.97-1.58C595.53,277.97,595.15,278.57,595.45,279.37z",
  "M433.92,270.29c0.09,0.8,0.85,1.47,1.71,1.49c0.85,0.02,1.47-0.61,1.38-1.42c-0.09-0.8-0.86-1.47-1.71-1.49C434.45,268.85,433.83,269.48,433.92,270.29z",
  "M429,270.18c0.08,0.8,0.84,1.46,1.7,1.48c0.86,0.02,1.48-0.62,1.39-1.42c-0.09-0.8-0.85-1.47-1.7-1.49C429.55,268.74,428.92,269.38,429,270.18z",
  "M424.08,270.09c0.08,0.8,0.83,1.46,1.69,1.48c0.86,0.02,1.49-0.62,1.4-1.42c-0.08-0.8-0.84-1.47-1.69-1.48C424.63,268.65,424.01,269.29,424.08,270.09z",
  "M419.16,270c0.07,0.8,0.82,1.46,1.68,1.48c0.86,0.01,1.49-0.62,1.42-1.42c-0.07-0.8-0.83-1.46-1.68-1.48C419.72,268.56,419.09,269.2,419.16,270z",
  "M414.22,269.93c0.06,0.8,0.81,1.46,1.67,1.47c0.86,0.01,1.5-0.63,1.43-1.43c-0.07-0.8-0.82-1.46-1.67-1.48C414.8,268.49,414.16,269.13,414.22,269.93z",
  "M409.29,269.87c0.06,0.8,0.8,1.46,1.66,1.47c0.86,0.01,1.5-0.63,1.44-1.43c-0.06-0.8-0.81-1.46-1.66-1.47C409.88,268.42,409.23,269.06,409.29,269.87z",
  "M404.35,269.81c0.05,0.8,0.79,1.46,1.65,1.47c0.86,0.01,1.51-0.63,1.45-1.43c-0.06-0.8-0.79-1.46-1.65-1.47C404.95,268.37,404.3,269.01,404.35,269.81z",
  "M399.41,269.77c0.05,0.8,0.78,1.46,1.64,1.46c0.86,0.01,1.52-0.64,1.47-1.44c-0.05-0.8-0.78-1.46-1.64-1.47C400.02,268.32,399.36,268.97,399.41,269.77z",
  "M394.46,269.74c0.04,0.8,0.77,1.45,1.63,1.46c0.86,0,1.52-0.64,1.48-1.44c-0.04-0.8-0.77-1.46-1.63-1.46C395.09,268.29,394.43,268.93,394.46,269.74z",
  "M389.52,269.71c0.03,0.8,0.76,1.45,1.62,1.46c0.86,0,1.53-0.64,1.49-1.44c-0.04-0.8-0.76-1.46-1.62-1.46C390.15,268.26,389.48,268.91,389.52,269.71z",
  "M384.57,269.7c0.03,0.8,0.75,1.45,1.61,1.45c0.86,0,1.53-0.64,1.5-1.45c-0.03-0.8-0.75-1.46-1.61-1.46C385.21,268.25,384.54,268.9,384.57,269.7z",
  "M271.03,272.24c-0.11,0.8,0.49,1.42,1.34,1.38c0.85-0.04,1.63-0.72,1.74-1.52c0.11-0.8-0.49-1.42-1.33-1.38C271.92,270.76,271.14,271.44,271.03,272.24z",
  "M266.14,272.47c-0.12,0.8,0.47,1.41,1.32,1.37c0.85-0.04,1.63-0.72,1.75-1.52c0.12-0.8-0.48-1.42-1.32-1.38C267.04,270.99,266.26,271.67,266.14,272.47z",
  "M261.26,272.71c-0.13,0.8,0.46,1.41,1.31,1.37c0.85-0.04,1.63-0.72,1.75-1.52c0.12-0.8-0.46-1.42-1.31-1.37C262.18,271.22,261.39,271.91,261.26,272.71z",
  "M256.39,272.96c-0.13,0.8,0.45,1.41,1.3,1.37c0.85-0.04,1.64-0.73,1.76-1.53c0.13-0.8-0.45-1.41-1.29-1.37C257.31,271.47,256.52,272.16,256.39,272.96z",
  "M251.53,273.22c-0.14,0.8,0.44,1.41,1.28,1.36c0.85-0.05,1.64-0.73,1.77-1.53c0.13-0.8-0.44-1.41-1.28-1.37C252.46,271.73,251.67,272.42,251.53,273.22z",
  "M246.67,273.49c-0.14,0.8,0.42,1.41,1.27,1.36c0.84-0.05,1.64-0.73,1.78-1.53c0.14-0.8-0.43-1.41-1.27-1.36C247.62,272,246.82,272.69,246.67,273.49z",
  "M241.83,273.76c-0.15,0.8,0.41,1.41,1.25,1.36c0.84-0.05,1.64-0.73,1.79-1.53c0.15-0.8-0.41-1.41-1.25-1.36C242.78,272.28,241.98,272.96,241.83,273.76z",
  "M236.99,274.05c-0.16,0.8,0.4,1.41,1.24,1.36c0.84-0.05,1.64-0.74,1.8-1.54c0.15-0.8-0.4-1.41-1.24-1.36C237.95,272.56,237.15,273.25,236.99,274.05z",
  "M232.16,274.35c-0.16,0.8,0.39,1.4,1.23,1.35c0.84-0.05,1.65-0.74,1.8-1.54c0.16-0.8-0.39-1.41-1.22-1.36C233.14,272.86,232.33,273.55,232.16,274.35z",
  "M227.35,274.65c-0.17,0.8,0.37,1.4,1.21,1.35c0.84-0.05,1.65-0.74,1.81-1.54c0.16-0.8-0.38-1.41-1.21-1.35C228.33,273.16,227.51,273.85,227.35,274.65z",
  "M222.54,274.97c-0.18,0.8,0.36,1.4,1.2,1.35c0.84-0.05,1.65-0.75,1.82-1.54c0.17-0.8-0.36-1.41-1.2-1.35C223.53,273.48,222.71,274.17,222.54,274.97z",
  "M217.74,275.29c-0.18,0.8,0.35,1.4,1.18,1.34c0.83-0.06,1.65-0.75,1.83-1.55c0.18-0.8-0.35-1.4-1.18-1.35C218.74,273.8,217.92,274.49,217.74,275.29z",
  "M212.95,275.62c-0.19,0.8,0.34,1.4,1.17,1.34c0.83-0.06,1.65-0.75,1.84-1.55c0.18-0.8-0.34-1.4-1.17-1.34C213.97,274.13,213.14,274.83,212.95,275.62z",
  "M208.18,275.97c-0.19,0.8,0.32,1.4,1.15,1.34c0.83-0.06,1.65-0.75,1.84-1.55c0.19-0.8-0.33-1.4-1.15-1.34C209.2,274.47,208.37,275.17,208.18,275.97z",
  "M203.42,276.32c-0.2,0.8,0.31,1.39,1.14,1.33c0.83-0.06,1.66-0.76,1.85-1.55c0.2-0.8-0.31-1.4-1.14-1.34C204.45,274.82,203.62,275.52,203.42,276.32z",
  "M198.67,276.67c-0.21,0.8,0.3,1.39,1.12,1.33c0.83-0.06,1.66-0.76,1.86-1.56c0.2-0.8-0.3-1.4-1.12-1.33C199.71,275.18,198.87,275.88,198.67,276.67z",
  "M193.93,277.04c-0.21,0.8,0.28,1.39,1.11,1.33c0.82-0.06,1.66-0.76,1.87-1.56c0.21-0.8-0.29-1.4-1.11-1.33C194.98,275.54,194.14,276.24,193.93,277.04z",
  "M189.2,277.42c-0.22,0.8,0.27,1.39,1.09,1.33c0.82-0.07,1.66-0.76,1.87-1.56c0.21-0.8-0.27-1.39-1.09-1.33C190.26,275.92,189.42,276.62,189.2,277.42z",
  "M184.49,277.8c-0.22,0.8,0.26,1.39,1.08,1.32c0.82-0.07,1.66-0.77,1.88-1.56c0.22-0.8-0.26-1.39-1.08-1.33C185.56,276.3,184.71,277,184.49,277.8z",
  "M179.79,278.19c-0.23,0.8,0.25,1.39,1.06,1.32c0.82-0.07,1.66-0.77,1.89-1.57c0.23-0.8-0.25-1.39-1.06-1.32C180.87,276.69,180.02,277.39,179.79,278.19z",
  "M624.62,277.85c0.35,0.8,1.25,1.51,2.01,1.59c0.76,0.08,1.08-0.51,0.72-1.31c-0.36-0.8-1.26-1.52-2.01-1.6C624.59,276.47,624.27,277.05,624.62,277.85z",
  "M607.08,276.09c0.33,0.8,1.22,1.51,1.99,1.59c0.77,0.07,1.12-0.51,0.79-1.32c-0.33-0.8-1.22-1.51-1.99-1.59C607.1,274.7,606.75,275.29,607.08,276.09z",
  "M602.64,275.67c0.32,0.8,1.21,1.51,1.98,1.58c0.77,0.07,1.13-0.52,0.81-1.32c-0.33-0.8-1.22-1.51-1.98-1.59C602.68,274.27,602.32,274.86,602.64,275.67z",
  "M598.19,275.25c0.32,0.8,1.2,1.51,1.97,1.58c0.77,0.07,1.15-0.52,0.82-1.32c-0.32-0.8-1.2-1.51-1.98-1.59C598.24,273.85,597.88,274.45,598.19,275.25z",
  "M593.72,274.84c0.31,0.8,1.19,1.51,1.97,1.58c0.78,0.07,1.16-0.52,0.84-1.32c-0.31-0.8-1.2-1.51-1.97-1.58C593.79,273.44,593.41,274.03,593.72,274.84z",
  "M589.23,274.43c0.3,0.8,1.18,1.51,1.96,1.58c0.78,0.07,1.17-0.52,0.86-1.33c-0.31-0.8-1.19-1.51-1.96-1.58C589.31,273.03,588.93,273.63,589.23,274.43z",
  "M584.73,274.03c0.3,0.8,1.17,1.51,1.96,1.58c0.78,0.07,1.18-0.53,0.88-1.33c-0.3-0.8-1.18-1.51-1.96-1.58C584.82,272.64,584.43,273.23,584.73,274.03z",
  "M580.2,273.64c0.29,0.8,1.16,1.51,1.95,1.57c0.79,0.07,1.19-0.53,0.89-1.33c-0.29-0.8-1.17-1.51-1.95-1.58C580.31,272.24,579.91,272.84,580.2,273.64z",
  "M575.66,273.26c0.28,0.8,1.15,1.51,1.94,1.57c0.79,0.07,1.2-0.53,0.91-1.33c-0.29-0.8-1.16-1.51-1.95-1.58C575.78,271.86,575.38,272.46,575.66,273.26z",
  "M571.1,272.89c0.28,0.8,1.14,1.51,1.94,1.57c0.79,0.06,1.21-0.53,0.93-1.34c-0.28-0.8-1.15-1.51-1.94-1.57C571.24,271.48,570.83,272.08,571.1,272.89z",
  "M561.94,272.16c0.26,0.8,1.12,1.5,1.92,1.57c0.8,0.06,1.23-0.54,0.96-1.34c-0.27-0.8-1.13-1.51-1.93-1.57C562.11,270.75,561.68,271.36,561.94,272.16z",
  "M433.41,265.74c0.09,0.81,0.85,1.47,1.7,1.49c0.85,0.02,1.46-0.62,1.36-1.42c-0.1-0.81-0.86-1.48-1.7-1.5C433.93,264.3,433.32,264.94,433.41,265.74z",
  "M428.53,265.64c0.08,0.8,0.84,1.47,1.69,1.49c0.85,0.02,1.47-0.62,1.38-1.42c-0.09-0.81-0.85-1.47-1.69-1.49C429.06,264.19,428.45,264.83,428.53,265.64z",
  "M423.64,265.54c0.08,0.81,0.83,1.47,1.68,1.49c0.85,0.02,1.47-0.62,1.39-1.43c-0.08-0.81-0.84-1.47-1.68-1.49C424.19,264.1,423.56,264.74,423.64,265.54z",
  "M418.75,265.46c0.07,0.81,0.82,1.47,1.67,1.48c0.85,0.01,1.48-0.63,1.4-1.43c-0.08-0.81-0.83-1.47-1.67-1.49C419.31,264.01,418.68,264.65,418.75,265.46z",
  "M413.86,265.39c0.07,0.81,0.81,1.47,1.66,1.48c0.85,0.01,1.49-0.63,1.41-1.43c-0.07-0.81-0.82-1.47-1.66-1.48C414.42,263.94,413.79,264.58,413.86,265.39z",
  "M408.96,265.32c0.06,0.81,0.8,1.47,1.65,1.48c0.85,0.01,1.49-0.63,1.43-1.44c-0.06-0.81-0.8-1.47-1.65-1.48C409.54,263.87,408.9,264.52,408.96,265.32z",
  "M404.05,265.27c0.05,0.81,0.79,1.46,1.64,1.47c0.85,0.01,1.5-0.64,1.44-1.44c-0.06-0.81-0.79-1.47-1.64-1.48C404.64,263.81,404,264.46,404.05,265.27z",
  "M399.15,265.23c0.05,0.81,0.78,1.46,1.63,1.47c0.85,0.01,1.5-0.64,1.45-1.44c-0.05-0.81-0.78-1.47-1.63-1.47C399.75,263.77,399.1,264.42,399.15,265.23z",
  "M394.24,265.19c0.04,0.81,0.77,1.46,1.62,1.47c0.85,0.01,1.51-0.64,1.46-1.45c-0.05-0.81-0.77-1.46-1.62-1.47C394.85,263.74,394.2,264.39,394.24,265.19z",
  "M389.32,265.17c0.03,0.81,0.75,1.46,1.61,1.46c0.85,0,1.52-0.65,1.48-1.45c-0.04-0.81-0.76-1.46-1.61-1.47C389.95,263.71,389.29,264.36,389.32,265.17z",
  "M384.41,265.16c0.03,0.81,0.74,1.46,1.6,1.46c0.85,0,1.52-0.65,1.49-1.45c-0.03-0.81-0.75-1.46-1.6-1.46C385.05,263.7,384.38,264.35,384.41,265.16z",
  "M379.49,265.15c0.02,0.81,0.73,1.46,1.59,1.46c0.85,0,1.53-0.65,1.5-1.46c-0.03-0.81-0.74-1.46-1.59-1.46C380.14,263.69,379.47,264.35,379.49,265.15z",
  "M271.68,267.7c-0.12,0.8,0.47,1.42,1.32,1.38c0.84-0.04,1.62-0.72,1.73-1.52c0.11-0.8-0.48-1.43-1.32-1.39C272.57,266.21,271.8,266.9,271.68,267.7z",
  "M266.83,267.93c-0.12,0.8,0.46,1.42,1.3,1.38c0.84-0.04,1.62-0.72,1.74-1.53c0.12-0.8-0.46-1.42-1.3-1.38C267.73,266.44,266.95,267.13,266.83,267.93z",
  "M261.98,268.17c-0.13,0.8,0.45,1.42,1.29,1.38c0.84-0.04,1.63-0.73,1.75-1.53c0.13-0.8-0.45-1.42-1.29-1.38C262.9,266.68,262.11,267.37,261.98,268.17z",
  "M257.15,268.42c-0.14,0.8,0.43,1.42,1.28,1.37c0.84-0.04,1.63-0.73,1.76-1.53c0.13-0.8-0.44-1.42-1.27-1.38C258.07,266.93,257.28,267.62,257.15,268.42z",
  "M252.32,268.68c-0.14,0.8,0.42,1.42,1.26,1.37c0.84-0.05,1.63-0.73,1.77-1.54c0.14-0.8-0.43-1.42-1.26-1.37C253.26,267.19,252.46,267.88,252.32,268.68z",
  "M247.5,268.95c-0.15,0.8,0.41,1.42,1.25,1.37c0.84-0.05,1.63-0.74,1.78-1.54c0.14-0.8-0.41-1.42-1.25-1.37C248.45,267.46,247.65,268.15,247.5,268.95z",
  "M242.69,269.23c-0.16,0.8,0.4,1.41,1.23,1.36c0.84-0.05,1.64-0.74,1.79-1.54c0.15-0.8-0.4-1.42-1.23-1.37C243.65,267.74,242.85,268.43,242.69,269.23z",
  "M237.89,269.52c-0.16,0.8,0.38,1.41,1.22,1.36c0.84-0.05,1.64-0.74,1.79-1.54c0.16-0.8-0.39-1.42-1.22-1.36C238.85,268.02,238.05,268.72,237.89,269.52z",
  "M233.1,269.82c-0.17,0.8,0.37,1.41,1.21,1.36c0.83-0.05,1.64-0.74,1.8-1.55c0.16-0.8-0.37-1.41-1.2-1.36C234.07,268.32,233.26,269.02,233.1,269.82z",
  "M228.31,270.12c-0.17,0.8,0.36,1.41,1.19,1.36c0.83-0.05,1.64-0.75,1.81-1.55c0.17-0.8-0.36-1.41-1.19-1.36C229.3,268.63,228.49,269.32,228.31,270.12z",
  "M223.54,270.44c-0.18,0.8,0.35,1.41,1.18,1.35c0.83-0.05,1.64-0.75,1.82-1.55c0.18-0.8-0.35-1.41-1.17-1.36C224.54,268.94,223.72,269.64,223.54,270.44z",
  "M218.78,270.76c-0.19,0.8,0.33,1.41,1.16,1.35c0.83-0.06,1.65-0.75,1.83-1.55c0.18-0.8-0.34-1.41-1.16-1.35C219.79,269.26,218.97,269.96,218.78,270.76z",
  "M214.03,271.1c-0.19,0.8,0.32,1.4,1.15,1.35c0.83-0.06,1.65-0.75,1.84-1.56c0.19-0.8-0.32-1.41-1.14-1.35C215.05,269.6,214.22,270.3,214.03,271.1z",
  "M209.29,271.44c-0.2,0.8,0.31,1.4,1.13,1.34c0.82-0.06,1.65-0.76,1.84-1.56c0.2-0.8-0.31-1.41-1.13-1.35C210.32,269.94,209.49,270.64,209.29,271.44z",
  "M204.56,271.79c-0.21,0.8,0.29,1.4,1.12,1.34c0.82-0.06,1.65-0.76,1.85-1.56c0.2-0.8-0.3-1.4-1.11-1.34C205.6,270.29,204.77,270.99,204.56,271.79z",
  "M199.85,272.15c-0.21,0.8,0.28,1.4,1.1,1.34c0.82-0.06,1.65-0.76,1.86-1.56c0.21-0.8-0.28-1.4-1.1-1.34C200.89,270.65,200.06,271.35,199.85,272.15z",
  "M195.14,272.52c-0.22,0.8,0.27,1.4,1.09,1.33c0.82-0.06,1.65-0.76,1.87-1.57c0.21-0.8-0.27-1.4-1.08-1.34C196.2,271.01,195.36,271.72,195.14,272.52z",
  "M190.45,272.89c-0.23,0.8,0.25,1.4,1.07,1.33c0.82-0.07,1.65-0.77,1.87-1.57c0.22-0.8-0.26-1.4-1.07-1.33C191.52,271.39,190.68,272.09,190.45,272.89z",
  "M185.78,273.28c-0.23,0.8,0.24,1.4,1.05,1.33c0.81-0.07,1.65-0.77,1.88-1.57c0.23-0.8-0.24-1.4-1.05-1.33C186.85,271.77,186.01,272.48,185.78,273.28z",
  "M609.58,271.98c0.34,0.8,1.23,1.52,1.99,1.59c0.76,0.08,1.09-0.51,0.75-1.32c-0.35-0.8-1.24-1.52-1.99-1.6C609.57,270.58,609.24,271.18,609.58,271.98z",
  "M596.39,270.7c0.32,0.81,1.2,1.52,1.97,1.59c0.77,0.07,1.13-0.52,0.8-1.33c-0.33-0.8-1.21-1.52-1.98-1.59C596.42,269.3,596.07,269.9,596.39,270.7z",
  "M591.95,270.29c0.32,0.81,1.2,1.51,1.97,1.59c0.77,0.07,1.14-0.52,0.82-1.33c-0.32-0.81-1.2-1.52-1.97-1.59C592,268.89,591.64,269.49,591.95,270.29z",
  "M587.5,269.89c0.31,0.81,1.19,1.51,1.96,1.58c0.78,0.07,1.15-0.52,0.84-1.33c-0.31-0.81-1.19-1.52-1.96-1.59C587.56,268.48,587.19,269.08,587.5,269.89z",
  "M583.03,269.49c0.3,0.81,1.18,1.51,1.96,1.58c0.78,0.07,1.16-0.53,0.85-1.33c-0.31-0.81-1.18-1.52-1.96-1.58C583.11,268.08,582.73,268.68,583.03,269.49z",
  "M578.54,269.1c0.3,0.81,1.17,1.51,1.95,1.58c0.78,0.07,1.17-0.53,0.87-1.34c-0.3-0.81-1.18-1.51-1.95-1.58C578.64,267.69,578.25,268.29,578.54,269.1z",
  "M569.52,268.34c0.28,0.81,1.15,1.51,1.94,1.58c0.79,0.06,1.19-0.53,0.9-1.34c-0.29-0.81-1.16-1.51-1.94-1.58C569.64,266.93,569.24,267.53,569.52,268.34z",
  "M564.98,267.97c0.28,0.81,1.14,1.51,1.93,1.57c0.79,0.06,1.2-0.54,0.92-1.34c-0.28-0.81-1.15-1.51-1.93-1.58C565.12,266.56,564.71,267.16,564.98,267.97z",
  "M560.43,267.61c0.27,0.81,1.13,1.51,1.92,1.57c0.79,0.06,1.21-0.54,0.94-1.35c-0.27-0.81-1.14-1.51-1.92-1.57C560.58,266.2,560.16,266.8,560.43,267.61z",
  "M432.89,261.18c0.09,0.81,0.85,1.48,1.69,1.5c0.84,0.02,1.44-0.62,1.35-1.43c-0.1-0.81-0.86-1.48-1.69-1.5C433.4,259.73,432.79,260.37,432.89,261.18z",
  "M428.04,261.07c0.09,0.81,0.84,1.48,1.68,1.5c0.84,0.02,1.45-0.62,1.36-1.43c-0.09-0.81-0.85-1.48-1.68-1.5C428.56,259.62,427.95,260.27,428.04,261.07z",
  "M423.19,260.98c0.08,0.81,0.83,1.48,1.67,1.49c0.84,0.02,1.46-0.62,1.37-1.43c-0.09-0.81-0.84-1.48-1.67-1.49C423.72,259.53,423.11,260.17,423.19,260.98z",
  "M418.34,260.9c0.07,0.81,0.82,1.47,1.66,1.49c0.84,0.01,1.47-0.63,1.39-1.44c-0.08-0.81-0.82-1.48-1.66-1.49C418.88,259.44,418.26,260.09,418.34,260.9z",
  "M413.48,260.82c0.07,0.81,0.81,1.47,1.65,1.49c0.85,0.01,1.47-0.63,1.4-1.44c-0.07-0.81-0.81-1.48-1.65-1.49C414.04,259.37,413.41,260.01,413.48,260.82z",
  "M408.61,260.76c0.06,0.81,0.8,1.47,1.64,1.48c0.85,0.01,1.48-0.63,1.41-1.44c-0.07-0.81-0.8-1.47-1.64-1.49C409.18,259.3,408.55,259.95,408.61,260.76z",
  "M403.75,260.7c0.05,0.81,0.78,1.47,1.63,1.48c0.85,0.01,1.48-0.64,1.43-1.45c-0.06-0.81-0.79-1.47-1.63-1.48C404.33,259.25,403.69,259.9,403.75,260.7z",
  "M398.88,260.66c0.05,0.81,0.77,1.47,1.62,1.48c0.85,0.01,1.49-0.64,1.44-1.45c-0.05-0.81-0.78-1.47-1.62-1.48C399.47,259.2,398.83,259.85,398.88,260.66z",
  "M394,260.63c0.04,0.81,0.76,1.47,1.61,1.47c0.85,0.01,1.5-0.64,1.45-1.45c-0.05-0.81-0.77-1.47-1.61-1.48C394.61,259.17,393.96,259.82,394,260.63z",
  "M389.13,260.61c0.03,0.81,0.75,1.47,1.6,1.47c0.85,0,1.5-0.65,1.46-1.46c-0.04-0.81-0.76-1.47-1.6-1.47C389.75,259.14,389.09,259.8,389.13,260.61z",
  "M384.25,260.59c0.03,0.81,0.74,1.46,1.59,1.47c0.85,0,1.51-0.65,1.48-1.46c-0.03-0.81-0.74-1.47-1.59-1.47C384.88,259.13,384.22,259.78,384.25,260.59z",
  "M379.37,260.59c0.02,0.81,0.73,1.46,1.58,1.46c0.85,0,1.51-0.65,1.49-1.46c-0.03-0.81-0.73-1.47-1.58-1.47C380.01,259.13,379.35,259.78,379.37,260.59z",
  "M374.49,260.6c0.01,0.81,0.72,1.46,1.56,1.46c0.85,0,1.52-0.66,1.5-1.47c-0.02-0.81-0.72-1.46-1.56-1.46C375.15,259.13,374.47,259.79,374.49,260.6z",
  "M253.14,264.13c-0.15,0.81,0.41,1.42,1.24,1.38c0.83-0.05,1.62-0.74,1.77-1.54c0.14-0.81-0.41-1.43-1.24-1.38C254.08,262.63,253.29,263.33,253.14,264.13z",
  "M248.36,264.4c-0.15,0.81,0.4,1.42,1.23,1.37c0.83-0.05,1.63-0.74,1.78-1.54c0.15-0.81-0.4-1.42-1.23-1.38C249.31,262.9,248.51,263.6,248.36,264.4z",
  "M243.58,264.68c-0.16,0.81,0.38,1.42,1.21,1.37c0.83-0.05,1.63-0.74,1.78-1.55c0.16-0.81-0.39-1.42-1.21-1.37C244.54,263.18,243.74,263.88,243.58,264.68z",
  "M238.81,264.97c-0.17,0.81,0.37,1.42,1.2,1.37c0.83-0.05,1.63-0.74,1.79-1.55c0.16-0.81-0.37-1.42-1.2-1.37C239.79,263.47,238.98,264.17,238.81,264.97z",
  "M234.06,265.27c-0.17,0.81,0.36,1.42,1.18,1.36c0.83-0.05,1.63-0.75,1.8-1.55c0.17-0.81-0.36-1.42-1.18-1.37C235.04,263.77,234.23,264.46,234.06,265.27z",
  "M229.31,265.58c-0.18,0.81,0.34,1.41,1.17,1.36c0.82-0.05,1.64-0.75,1.81-1.56c0.17-0.81-0.35-1.42-1.17-1.36C230.3,264.07,229.49,264.77,229.31,265.58z",
  "M224.58,265.89c-0.19,0.81,0.33,1.41,1.15,1.36c0.82-0.06,1.64-0.75,1.82-1.56c0.18-0.81-0.33-1.42-1.15-1.36C225.58,264.39,224.76,265.09,224.58,265.89z",
  "M219.85,266.22c-0.19,0.81,0.32,1.41,1.14,1.35c0.82-0.06,1.64-0.75,1.83-1.56c0.19-0.81-0.32-1.41-1.14-1.36C220.86,264.71,220.04,265.41,219.85,266.22z",
  "M215.14,266.55c-0.2,0.81,0.31,1.41,1.12,1.35c0.82-0.06,1.64-0.76,1.83-1.56c0.19-0.81-0.31-1.41-1.12-1.35C216.16,265.05,215.34,265.75,215.14,266.55z",
  "M210.43,266.9c-0.21,0.81,0.29,1.41,1.11,1.35c0.82-0.06,1.64-0.76,1.84-1.57c0.2-0.81-0.29-1.41-1.11-1.35C211.47,265.39,210.64,266.09,210.43,266.9z",
  "M205.74,267.25c-0.21,0.81,0.28,1.41,1.09,1.35c0.82-0.06,1.64-0.76,1.85-1.57c0.21-0.81-0.28-1.41-1.09-1.35C206.78,265.74,205.95,266.44,205.74,267.25z",
  "M201.06,267.61c-0.22,0.81,0.27,1.41,1.08,1.34c0.81-0.06,1.65-0.76,1.86-1.57c0.21-0.81-0.27-1.41-1.08-1.35C202.12,266.1,201.28,266.8,201.06,267.61z",
  "M196.4,267.98c-0.22,0.81,0.25,1.4,1.06,1.34c0.81-0.06,1.65-0.77,1.87-1.57c0.22-0.81-0.25-1.41-1.06-1.34C197.46,266.47,196.62,267.17,196.4,267.98z",
  "M191.74,268.35c-0.23,0.8,0.24,1.4,1.05,1.34c0.81-0.07,1.65-0.77,1.87-1.57c0.23-0.8-0.24-1.41-1.05-1.34C192.81,266.84,191.97,267.55,191.74,268.35z",
  "M187.1,268.74c-0.24,0.8,0.22,1.4,1.03,1.33c0.81-0.07,1.65-0.77,1.88-1.58c0.23-0.8-0.23-1.4-1.03-1.34C188.18,267.23,187.34,267.94,187.1,268.74z",
  "M594.55,266.14c0.33,0.81,1.21,1.52,1.97,1.59c0.76,0.07,1.11-0.52,0.78-1.33c-0.33-0.81-1.22-1.52-1.98-1.59C594.57,264.74,594.22,265.34,594.55,266.14z",
  "M590.15,265.73c0.32,0.81,1.2,1.52,1.97,1.59c0.77,0.07,1.12-0.52,0.8-1.33c-0.33-0.81-1.21-1.52-1.97-1.59C590.18,264.33,589.83,264.92,590.15,265.73z",
  "M585.73,265.32c0.31,0.81,1.19,1.52,1.96,1.59c0.77,0.07,1.13-0.53,0.81-1.33c-0.32-0.81-1.2-1.52-1.96-1.59C585.78,263.92,585.42,264.52,585.73,265.32z",
  "M581.3,264.93c0.31,0.81,1.18,1.52,1.96,1.59c0.77,0.07,1.14-0.53,0.83-1.34c-0.31-0.81-1.19-1.52-1.96-1.59C581.36,263.52,580.99,264.12,581.3,264.93z",
  "M576.85,264.53c0.3,0.81,1.17,1.52,1.95,1.58c0.77,0.07,1.16-0.53,0.85-1.34c-0.31-0.81-1.18-1.52-1.95-1.59C576.93,263.12,576.55,263.73,576.85,264.53z",
  "M563.4,263.4c0.28,0.81,1.15,1.51,1.93,1.58c0.78,0.06,1.19-0.54,0.9-1.35c-0.29-0.81-1.15-1.52-1.93-1.58C563.52,261.99,563.12,262.59,563.4,263.4z",
  "M558.88,263.04c0.28,0.81,1.14,1.51,1.92,1.58c0.79,0.06,1.2-0.54,0.92-1.35c-0.28-0.81-1.14-1.52-1.92-1.58C559.02,261.63,558.61,262.23,558.88,263.04z",
  "M554.35,262.69c0.27,0.81,1.13,1.51,1.92,1.57c0.79,0.06,1.21-0.54,0.93-1.35c-0.27-0.81-1.13-1.52-1.92-1.58C554.5,261.27,554.08,261.88,554.35,262.69z",
  "M437.15,256.71c0.1,0.81,0.86,1.49,1.7,1.51c0.83,0.02,1.42-0.62,1.32-1.43c-0.11-0.81-0.87-1.49-1.7-1.51C437.64,255.26,437.05,255.9,437.15,256.71z",
  "M432.35,256.6c0.1,0.81,0.85,1.48,1.69,1.5c0.84,0.02,1.43-0.62,1.33-1.43c-0.1-0.81-0.86-1.49-1.69-1.51C432.85,255.15,432.25,255.79,432.35,256.6z",
  "M427.54,256.5c0.09,0.81,0.84,1.48,1.68,1.5c0.84,0.02,1.44-0.62,1.34-1.44c-0.09-0.81-0.85-1.48-1.68-1.5C428.05,255.04,427.45,255.68,427.54,256.5z",
  "M422.73,256.4c0.08,0.81,0.83,1.48,1.67,1.5c0.84,0.02,1.45-0.63,1.36-1.44c-0.09-0.81-0.83-1.48-1.67-1.5C423.25,254.94,422.64,255.59,422.73,256.4z",
  "M417.91,256.32c0.08,0.81,0.82,1.48,1.65,1.49c0.84,0.01,1.45-0.63,1.37-1.44c-0.08-0.81-0.82-1.48-1.66-1.5C418.45,254.86,417.83,255.5,417.91,256.32z",
  "M413.09,256.24c0.07,0.81,0.81,1.48,1.64,1.49c0.84,0.01,1.46-0.63,1.38-1.45c-0.07-0.81-0.81-1.48-1.64-1.49C413.64,254.78,413.02,255.43,413.09,256.24z",
  "M408.26,256.18c0.06,0.81,0.79,1.48,1.63,1.49c0.84,0.01,1.47-0.64,1.4-1.45c-0.07-0.81-0.8-1.48-1.63-1.49C408.82,254.72,408.2,255.37,408.26,256.18z",
  "M403.43,256.12c0.06,0.81,0.78,1.48,1.62,1.48c0.84,0.01,1.47-0.64,1.41-1.45c-0.06-0.81-0.79-1.48-1.62-1.49C404.01,254.66,403.38,255.31,403.43,256.12z",
  "M398.6,256.08c0.05,0.81,0.77,1.47,1.61,1.48c0.84,0.01,1.48-0.64,1.42-1.45c-0.05-0.81-0.78-1.48-1.61-1.48C399.19,254.62,398.55,255.27,398.6,256.08z",
  "M393.76,256.05c0.04,0.81,0.76,1.47,1.6,1.48c0.84,0.01,1.49-0.65,1.44-1.46c-0.05-0.81-0.76-1.47-1.6-1.48C394.37,254.58,393.72,255.24,393.76,256.05z",
  "M388.93,256.03c0.04,0.81,0.75,1.47,1.59,1.47s1.49-0.65,1.45-1.46c-0.04-0.81-0.75-1.47-1.59-1.48C389.54,254.56,388.89,255.22,388.93,256.03z",
  "M384.09,256.01c0.03,0.81,0.74,1.47,1.58,1.47c0.84,0,1.5-0.65,1.46-1.46c-0.03-0.81-0.74-1.47-1.58-1.47C384.71,254.55,384.06,255.2,384.09,256.01z",
  "M379.24,256.01c0.02,0.81,0.72,1.47,1.56,1.47c0.84,0,1.5-0.66,1.48-1.47c-0.03-0.81-0.73-1.47-1.57-1.47C379.88,254.54,379.22,255.2,379.24,256.01z",
  "M374.4,256.02c0.02,0.81,0.71,1.47,1.55,1.46c0.84,0,1.51-0.66,1.49-1.47c-0.02-0.81-0.72-1.47-1.55-1.47C375.05,254.55,374.39,255.21,374.4,256.02z",
  "M369.56,256.04c0.01,0.81,0.7,1.46,1.54,1.46c0.84,0,1.51-0.66,1.5-1.47c-0.01-0.81-0.7-1.47-1.54-1.46C370.22,254.57,369.55,255.23,369.56,256.04z",
  "M249.24,259.84c-0.16,0.81,0.38,1.43,1.21,1.38c0.83-0.05,1.62-0.74,1.77-1.55c0.15-0.81-0.39-1.43-1.21-1.38C250.19,258.33,249.39,259.03,249.24,259.84z",
  "M244.5,260.12c-0.16,0.81,0.37,1.42,1.19,1.38c0.82-0.05,1.62-0.74,1.78-1.55c0.16-0.81-0.37-1.43-1.19-1.38C245.46,258.61,244.66,259.31,244.5,260.12z",
  "M239.77,260.41c-0.17,0.81,0.36,1.42,1.18,1.37c0.82-0.05,1.62-0.75,1.79-1.56c0.17-0.81-0.36-1.43-1.18-1.37C240.74,258.9,239.94,259.6,239.77,260.41z",
  "M235.05,260.7c-0.18,0.81,0.34,1.42,1.16,1.37c0.82-0.05,1.63-0.75,1.8-1.56c0.17-0.81-0.35-1.42-1.16-1.37C236.04,259.2,235.23,259.9,235.05,260.7z",
  "M230.34,261.01c-0.18,0.81,0.33,1.42,1.15,1.37c0.82-0.05,1.63-0.75,1.81-1.56c0.18-0.81-0.33-1.42-1.15-1.37C231.34,259.5,230.53,260.2,230.34,261.01z",
  "M225.64,261.33c-0.19,0.81,0.32,1.42,1.13,1.36c0.82-0.06,1.63-0.75,1.82-1.56c0.19-0.81-0.32-1.42-1.13-1.37C226.65,259.82,225.83,260.52,225.64,261.33z",
  "M220.95,261.66c-0.2,0.81,0.3,1.42,1.12,1.36c0.82-0.06,1.63-0.76,1.83-1.57c0.19-0.81-0.31-1.42-1.12-1.36C221.97,260.15,221.15,260.85,220.95,261.66z",
  "M216.28,261.99c-0.2,0.81,0.29,1.41,1.1,1.36c0.81-0.06,1.63-0.76,1.83-1.57c0.2-0.81-0.29-1.42-1.1-1.36C217.3,260.48,216.48,261.18,216.28,261.99z",
  "M211.61,262.34c-0.21,0.81,0.28,1.41,1.09,1.35c0.81-0.06,1.64-0.76,1.84-1.57c0.21-0.81-0.28-1.42-1.09-1.36C212.65,260.82,211.82,261.53,211.61,262.34z",
  "M206.96,262.69c-0.22,0.81,0.26,1.41,1.07,1.35c0.81-0.06,1.64-0.76,1.85-1.57c0.21-0.81-0.27-1.41-1.07-1.35C208,261.17,207.17,261.88,206.96,262.69z",
  "M202.31,263.05c-0.22,0.81,0.25,1.41,1.06,1.35c0.81-0.06,1.64-0.77,1.86-1.58c0.22-0.81-0.25-1.41-1.05-1.35C203.37,261.53,202.54,262.24,202.31,263.05z",
  "M197.68,263.42c-0.23,0.81,0.24,1.41,1.04,1.34c0.8-0.06,1.64-0.77,1.87-1.58c0.23-0.81-0.24-1.41-1.04-1.35C198.75,261.9,197.91,262.61,197.68,263.42z",
  "M193.07,263.8c-0.24,0.81,0.22,1.41,1.02,1.34c0.8-0.07,1.64-0.77,1.87-1.58c0.23-0.81-0.22-1.41-1.02-1.34C194.14,262.28,193.3,262.99,193.07,263.8z",
  "M605.65,262.86c0.35,0.81,1.24,1.53,1.99,1.6c0.75,0.08,1.06-0.52,0.7-1.33c-0.36-0.81-1.25-1.53-1.99-1.6C605.61,261.45,605.3,262.05,605.65,262.86z",
  "M592.68,261.57c0.33,0.81,1.22,1.52,1.97,1.6c0.76,0.07,1.1-0.52,0.76-1.33c-0.34-0.81-1.22-1.53-1.97-1.6C592.68,260.17,592.34,260.76,592.68,261.57z",
  "M588.31,261.16c0.33,0.81,1.21,1.52,1.97,1.59c0.76,0.07,1.11-0.53,0.77-1.34c-0.33-0.81-1.21-1.53-1.97-1.6C588.33,259.75,587.99,260.35,588.31,261.16z",
  "M561.78,258.82c0.29,0.81,1.15,1.52,1.93,1.58c0.78,0.06,1.17-0.54,0.88-1.35c-0.29-0.81-1.16-1.52-1.93-1.58C561.89,257.41,561.5,258.01,561.78,258.82z",
  "M557.31,258.46c0.28,0.81,1.14,1.52,1.92,1.58c0.78,0.06,1.18-0.54,0.89-1.35c-0.29-0.81-1.15-1.52-1.92-1.58C557.42,257.04,557.02,257.65,557.31,258.46z",
  "M441.32,252.25c0.11,0.81,0.87,1.49,1.7,1.51c0.83,0.02,1.4-0.62,1.28-1.43c-0.12-0.81-0.88-1.49-1.7-1.52C441.78,250.79,441.2,251.43,441.32,252.25z",
  "M436.56,252.12c0.11,0.81,0.86,1.49,1.69,1.51c0.83,0.02,1.41-0.62,1.3-1.43c-0.11-0.81-0.87-1.49-1.69-1.51C437.03,250.66,436.45,251.31,436.56,252.12z",
  "M431.79,252.01c0.1,0.81,0.85,1.49,1.68,1.51c0.83,0.02,1.42-0.62,1.31-1.44c-0.1-0.81-0.86-1.49-1.68-1.51C432.28,250.55,431.7,251.19,431.79,252.01z",
  "M427.03,251.9c0.09,0.81,0.84,1.49,1.67,1.5c0.83,0.02,1.42-0.63,1.33-1.44c-0.1-0.81-0.84-1.49-1.67-1.51C427.53,250.44,426.93,251.09,427.03,251.9z",
  "M422.25,251.81c0.08,0.81,0.83,1.48,1.66,1.5c0.83,0.02,1.43-0.63,1.34-1.44c-0.09-0.81-0.83-1.49-1.66-1.5C422.77,250.35,422.17,250.99,422.25,251.81z",
  "M417.47,251.72c0.08,0.81,0.81,1.48,1.65,1.5c0.83,0.01,1.44-0.63,1.36-1.45c-0.08-0.81-0.82-1.49-1.65-1.5C418,250.26,417.39,250.91,417.47,251.72z",
  "M412.69,251.65c0.07,0.81,0.8,1.48,1.64,1.49c0.83,0.01,1.45-0.64,1.37-1.45c-0.08-0.81-0.81-1.48-1.64-1.5C413.23,250.18,412.62,250.83,412.69,251.65z",
  "M407.9,251.58c0.06,0.81,0.79,1.48,1.62,1.49c0.83,0.01,1.45-0.64,1.38-1.45c-0.07-0.81-0.8-1.48-1.62-1.49C408.46,250.12,407.84,250.77,407.9,251.58z",
  "M403.11,251.53c0.06,0.81,0.78,1.48,1.61,1.49c0.83,0.01,1.46-0.64,1.4-1.46c-0.06-0.81-0.78-1.48-1.61-1.49C403.68,250.06,403.05,250.72,403.11,251.53z",
  "M398.32,251.49c0.05,0.81,0.77,1.48,1.6,1.49c0.83,0.01,1.47-0.65,1.41-1.46c-0.06-0.81-0.77-1.48-1.6-1.49C398.9,250.02,398.27,250.67,398.32,251.49z",
  "M393.52,251.45c0.04,0.81,0.75,1.48,1.59,1.48c0.83,0.01,1.47-0.65,1.42-1.46c-0.05-0.81-0.76-1.48-1.59-1.48C394.11,249.98,393.48,250.64,393.52,251.45z",
  "M388.72,251.43c0.04,0.81,0.74,1.48,1.58,1.48c0.83,0,1.48-0.65,1.44-1.47c-0.04-0.81-0.75-1.48-1.58-1.48C389.33,249.96,388.68,250.62,388.72,251.43z",
  "M383.92,251.42c0.03,0.81,0.73,1.47,1.57,1.48c0.84,0,1.48-0.66,1.45-1.47c-0.03-0.81-0.74-1.48-1.57-1.48C384.54,249.95,383.89,250.61,383.92,251.42z",
  "M379.12,251.42c0.02,0.81,0.72,1.47,1.55,1.47c0.84,0,1.49-0.66,1.46-1.47c-0.03-0.81-0.72-1.47-1.55-1.47C379.75,249.94,379.09,250.6,379.12,251.42z",
  "M374.31,251.43c0.02,0.81,0.71,1.47,1.54,1.47c0.84,0,1.5-0.66,1.48-1.48c-0.02-0.81-0.71-1.47-1.54-1.47C374.96,249.95,374.3,250.61,374.31,251.43z",
  "M240.75,255.83c-0.18,0.81,0.34,1.43,1.16,1.38c0.82-0.05,1.62-0.75,1.79-1.56c0.17-0.81-0.35-1.43-1.16-1.38C241.73,254.31,240.93,255.01,240.75,255.83z",
  "M236.07,256.13c-0.18,0.81,0.33,1.43,1.14,1.37c0.81-0.05,1.62-0.75,1.8-1.56c0.18-0.81-0.33-1.43-1.14-1.38C237.06,254.61,236.25,255.31,236.07,256.13z",
  "M231.4,256.43c-0.19,0.81,0.32,1.42,1.13,1.37c0.81-0.05,1.62-0.75,1.8-1.57c0.18-0.81-0.32-1.43-1.13-1.37C232.4,254.92,231.59,255.62,231.4,256.43z",
  "M226.74,256.75c-0.2,0.81,0.3,1.42,1.11,1.37c0.81-0.06,1.62-0.76,1.81-1.57c0.19-0.81-0.31-1.42-1.11-1.37C227.75,255.24,226.93,255.94,226.74,256.75z",
  "M222.09,257.08c-0.2,0.81,0.29,1.42,1.1,1.36c0.81-0.06,1.62-0.76,1.82-1.57c0.2-0.81-0.29-1.42-1.1-1.37C223.11,255.56,222.29,256.27,222.09,257.08z",
  "M217.45,257.42c-0.21,0.81,0.28,1.42,1.08,1.36c0.81-0.06,1.63-0.76,1.83-1.57c0.21-0.81-0.28-1.42-1.08-1.36C218.48,255.9,217.66,256.6,217.45,257.42z",
  "M212.82,257.76c-0.22,0.81,0.26,1.42,1.07,1.36c0.8-0.06,1.63-0.76,1.84-1.58c0.21-0.81-0.26-1.42-1.06-1.36C213.86,256.24,213.03,256.95,212.82,257.76z",
  "M208.2,258.11c-0.22,0.81,0.25,1.42,1.05,1.35c0.8-0.06,1.63-0.77,1.85-1.58c0.22-0.81-0.25-1.42-1.05-1.36C209.25,256.6,208.42,257.3,208.2,258.11z",
  "M203.59,258.48c-0.23,0.81,0.23,1.41,1.03,1.35c0.8-0.06,1.63-0.77,1.86-1.58c0.23-0.81-0.24-1.42-1.03-1.35C204.66,256.96,203.82,257.67,203.59,258.48z",
  "M199,258.85c-0.24,0.81,0.22,1.41,1.02,1.35c0.8-0.06,1.63-0.77,1.86-1.58c0.23-0.81-0.22-1.42-1.02-1.35C200.07,257.33,199.24,258.04,199,258.85z",
  "M194.42,259.23c-0.24,0.81,0.21,1.41,1,1.35c0.8-0.07,1.63-0.78,1.87-1.58c0.24-0.81-0.21-1.41-1-1.35C195.5,257.71,194.66,258.42,194.42,259.23z",
  "M189.85,259.61c-0.25,0.81,0.19,1.41,0.99,1.34c0.79-0.07,1.64-0.78,1.88-1.59c0.24-0.81-0.2-1.41-0.98-1.34C190.95,258.09,190.1,258.8,189.85,259.61z",
  "M180.76,260.41c-0.26,0.81,0.17,1.41,0.95,1.34c0.79-0.07,1.64-0.78,1.9-1.59c0.26-0.81-0.17-1.41-0.95-1.34C181.87,258.89,181.02,259.6,180.76,260.41z",
  "M661.27,264.99c0.44,0.81,1.36,1.53,2.05,1.62c0.69,0.09,0.88-0.49,0.43-1.3c-0.45-0.81-1.37-1.53-2.05-1.62C661.02,263.61,660.82,264.19,661.27,264.99z",
  "M657.29,264.48c0.44,0.81,1.35,1.53,2.05,1.62c0.69,0.09,0.89-0.49,0.45-1.3c-0.44-0.81-1.36-1.53-2.05-1.62C657.06,263.09,656.86,263.68,657.29,264.48z",
  "M653.3,263.97c0.43,0.81,1.35,1.53,2.04,1.62c0.7,0.09,0.91-0.49,0.47-1.3c-0.44-0.81-1.35-1.53-2.04-1.62C653.08,262.58,652.87,263.17,653.3,263.97z",
  "M628.85,261.03c0.4,0.81,1.3,1.53,2.02,1.61c0.72,0.08,0.98-0.51,0.58-1.31c-0.4-0.81-1.3-1.53-2.02-1.61C628.71,259.63,628.45,260.22,628.85,261.03z",
  "M603.64,258.28c0.36,0.81,1.25,1.53,1.99,1.61c0.74,0.08,1.05-0.52,0.68-1.33c-0.36-0.81-1.25-1.53-1.99-1.61C603.58,256.87,603.28,257.47,603.64,258.28z",
  "M599.37,257.84c0.35,0.81,1.24,1.53,1.98,1.6c0.74,0.07,1.06-0.52,0.7-1.33c-0.36-0.81-1.25-1.53-1.98-1.61C599.33,256.44,599.01,257.03,599.37,257.84z",
  "M590.77,256.99c0.34,0.81,1.22,1.53,1.97,1.6c0.75,0.07,1.08-0.52,0.74-1.34c-0.34-0.81-1.23-1.53-1.97-1.6C590.76,255.58,590.43,256.18,590.77,256.99z",
  "M560.14,254.24c0.29,0.81,1.16,1.52,1.93,1.59c0.77,0.06,1.15-0.54,0.86-1.35c-0.3-0.81-1.16-1.52-1.93-1.59C560.22,252.82,559.84,253.42,560.14,254.24z",
  "M555.69,253.87c0.29,0.81,1.15,1.52,1.92,1.58c0.77,0.06,1.16-0.54,0.87-1.36c-0.29-0.81-1.15-1.52-1.92-1.59C555.8,252.45,555.41,253.06,555.69,253.87z",
  "M551.24,253.52c0.28,0.81,1.14,1.52,1.91,1.58c0.78,0.06,1.17-0.55,0.89-1.36c-0.29-0.81-1.14-1.52-1.91-1.58C551.35,252.09,550.96,252.7,551.24,253.52z",
  "M450.09,247.92c0.13,0.81,0.9,1.5,1.71,1.52c0.82,0.03,1.37-0.61,1.24-1.43c-0.13-0.81-0.9-1.5-1.72-1.53C450.51,246.47,449.96,247.11,450.09,247.92z",
  "M445.38,247.78c0.12,0.81,0.88,1.5,1.7,1.52c0.82,0.02,1.38-0.61,1.25-1.43c-0.13-0.81-0.89-1.5-1.7-1.52C445.82,246.32,445.26,246.96,445.38,247.78z",
  "M440.67,247.64c0.11,0.81,0.87,1.49,1.69,1.52c0.82,0.02,1.39-0.62,1.27-1.43c-0.12-0.82-0.88-1.5-1.69-1.52C441.12,246.18,440.56,246.83,440.67,247.64z",
  "M435.95,247.52c0.11,0.82,0.86,1.49,1.68,1.51c0.82,0.02,1.4-0.62,1.28-1.44c-0.11-0.82-0.87-1.49-1.68-1.52C436.42,246.06,435.85,246.7,435.95,247.52z",
  "M431.23,247.4c0.1,0.82,0.85,1.49,1.67,1.51c0.82,0.02,1.4-0.62,1.3-1.44c-0.11-0.82-0.85-1.49-1.67-1.51C431.71,245.94,431.13,246.59,431.23,247.4z",
  "M426.5,247.3c0.09,0.82,0.84,1.49,1.66,1.51c0.82,0.02,1.41-0.63,1.31-1.44c-0.1-0.82-0.84-1.49-1.66-1.51C426.99,245.83,426.4,246.48,426.5,247.3z",
  "M421.76,247.2c0.09,0.82,0.82,1.49,1.65,1.5c0.82,0.02,1.42-0.63,1.33-1.45c-0.09-0.82-0.83-1.49-1.65-1.51C422.27,245.74,421.68,246.39,421.76,247.2z",
  "M417.02,247.12c0.08,0.82,0.81,1.49,1.64,1.5c0.82,0.01,1.42-0.63,1.34-1.45c-0.08-0.82-0.82-1.49-1.64-1.5C417.55,245.65,416.95,246.3,417.02,247.12z",
  "M412.28,247.04c0.07,0.82,0.8,1.49,1.63,1.5c0.83,0.01,1.43-0.64,1.35-1.45c-0.08-0.82-0.81-1.49-1.63-1.5C412.81,245.58,412.21,246.23,412.28,247.04z",
  "M407.53,246.98c0.07,0.82,0.79,1.48,1.61,1.5c0.83,0.01,1.44-0.64,1.37-1.46c-0.07-0.82-0.79-1.49-1.61-1.5C408.08,245.51,407.47,246.16,407.53,246.98z",
  "M402.78,246.93c0.06,0.82,0.78,1.48,1.6,1.49c0.83,0.01,1.45-0.64,1.38-1.46c-0.06-0.82-0.78-1.49-1.6-1.49C403.34,245.46,402.72,246.11,402.78,246.93z",
  "M398.03,246.88c0.05,0.82,0.76,1.48,1.59,1.49c0.83,0.01,1.45-0.65,1.4-1.46c-0.06-0.82-0.77-1.48-1.59-1.49C398.6,245.41,397.98,246.07,398.03,246.88z",
  "M393.27,246.85c0.04,0.82,0.75,1.48,1.58,1.49c0.83,0.01,1.46-0.65,1.41-1.47c-0.05-0.82-0.76-1.48-1.58-1.49C393.86,245.38,393.23,246.03,393.27,246.85z",
  "M388.51,246.83c0.04,0.82,0.74,1.48,1.57,1.48c0.83,0,1.47-0.65,1.42-1.47c-0.04-0.82-0.74-1.48-1.57-1.48C389.11,245.35,388.47,246.01,388.51,246.83z",
  "M383.75,246.81c0.03,0.82,0.73,1.48,1.55,1.48c0.83,0,1.47-0.66,1.44-1.47c-0.04-0.82-0.73-1.48-1.55-1.48C384.36,245.34,383.72,246,383.75,246.81z",
  "M378.99,246.81c0.02,0.82,0.71,1.48,1.54,1.48c0.83,0,1.48-0.66,1.45-1.48c-0.03-0.82-0.72-1.48-1.54-1.48C379.61,245.34,378.96,246,378.99,246.81z",
  "M374.22,246.82c0.02,0.82,0.7,1.47,1.53,1.47c0.83,0,1.48-0.66,1.46-1.48c-0.02-0.82-0.71-1.48-1.53-1.47C374.86,245.34,374.21,246.01,374.22,246.82z",
  "M369.46,246.84c0.01,0.82,0.69,1.47,1.52,1.47c0.83,0,1.49-0.67,1.48-1.48c-0.01-0.82-0.69-1.47-1.52-1.47C370.11,245.36,369.45,246.03,369.46,246.84z",
  "M237.12,251.53c-0.19,0.81,0.32,1.43,1.12,1.38c0.81-0.05,1.61-0.75,1.79-1.57c0.18-0.81-0.32-1.43-1.12-1.38C238.11,250.02,237.31,250.72,237.12,251.53z",
  "M232.48,251.84c-0.19,0.81,0.3,1.43,1.11,1.37c0.81-0.05,1.61-0.76,1.8-1.57c0.19-0.81-0.31-1.43-1.11-1.38C233.49,250.33,232.68,251.03,232.48,251.84z",
  "M227.86,252.16c-0.2,0.81,0.29,1.43,1.09,1.37c0.8-0.06,1.61-0.76,1.81-1.57c0.2-0.81-0.29-1.43-1.09-1.37C228.87,250.64,228.06,251.35,227.86,252.16z",
  "M223.25,252.49c-0.21,0.81,0.27,1.42,1.08,1.37c0.8-0.06,1.62-0.76,1.82-1.57c0.2-0.81-0.28-1.43-1.07-1.37C224.27,250.97,223.46,251.68,223.25,252.49z",
  "M218.65,252.83c-0.22,0.81,0.26,1.42,1.06,1.36c0.8-0.06,1.62-0.76,1.83-1.58c0.21-0.81-0.26-1.42-1.06-1.37C219.68,251.31,218.86,252.02,218.65,252.83z",
  "M214.05,253.17c-0.22,0.81,0.25,1.42,1.04,1.36c0.8-0.06,1.62-0.77,1.84-1.58c0.22-0.81-0.25-1.42-1.04-1.36C215.1,251.65,214.28,252.36,214.05,253.17z",
  "M209.48,253.53c-0.23,0.81,0.23,1.42,1.03,1.36c0.8-0.06,1.62-0.77,1.85-1.58c0.22-0.81-0.24-1.42-1.03-1.36C210.53,252.01,209.7,252.72,209.48,253.53z",
  "M204.91,253.89c-0.24,0.81,0.22,1.42,1.01,1.35c0.79-0.06,1.62-0.77,1.85-1.58c0.23-0.81-0.22-1.42-1.01-1.36C205.97,252.37,205.14,253.08,204.91,253.89z",
  "M200.35,254.26c-0.24,0.81,0.2,1.42,1,1.35c0.79-0.07,1.63-0.77,1.86-1.59c0.24-0.81-0.21-1.42-1-1.35C201.43,252.74,200.59,253.45,200.35,254.26z",
  "M195.81,254.64c-0.25,0.81,0.19,1.42,0.98,1.35c0.79-0.07,1.63-0.78,1.87-1.59c0.24-0.81-0.19-1.42-0.98-1.35C196.9,253.12,196.06,253.83,195.81,254.64z",
  "M182.26,255.83c-0.27,0.81,0.15,1.41,0.93,1.34c0.78-0.07,1.63-0.78,1.89-1.6c0.26-0.81-0.15-1.41-0.93-1.34C183.38,254.31,182.53,255.02,182.26,255.83z",
  "M173.3,256.67c-0.28,0.81,0.12,1.41,0.9,1.33c0.78-0.07,1.63-0.79,1.91-1.6c0.28-0.81-0.12-1.41-0.9-1.34C174.44,255.14,173.58,255.86,173.3,256.67z",
  "M666.56,261.48c0.46,0.81,1.38,1.53,2.05,1.62c0.67,0.09,0.84-0.49,0.38-1.29c-0.46-0.81-1.38-1.53-2.05-1.63C666.27,260.09,666.1,260.67,666.56,261.48z",
  "M662.66,260.95c0.45,0.81,1.37,1.53,2.05,1.62c0.68,0.09,0.86-0.49,0.4-1.3c-0.46-0.81-1.37-1.53-2.05-1.63C662.39,259.57,662.21,260.15,662.66,260.95z",
  "M658.75,260.43c0.45,0.81,1.36,1.53,2.05,1.62c0.68,0.09,0.87-0.49,0.42-1.3c-0.45-0.81-1.37-1.53-2.05-1.62C658.48,259.04,658.3,259.63,658.75,260.43z",
  "M654.81,259.92c0.44,0.81,1.36,1.53,2.04,1.62c0.69,0.09,0.88-0.49,0.43-1.3c-0.45-0.81-1.36-1.53-2.04-1.62C654.56,258.53,654.36,259.11,654.81,259.92z",
  "M650.84,259.41c0.44,0.81,1.35,1.53,2.04,1.62c0.69,0.09,0.89-0.49,0.45-1.3c-0.44-0.81-1.35-1.53-2.04-1.62C650.61,258.02,650.41,258.6,650.84,259.41z",
  "M646.86,258.9c0.43,0.81,1.34,1.53,2.04,1.62c0.69,0.09,0.9-0.5,0.47-1.3c-0.44-0.81-1.35-1.53-2.04-1.62C646.63,257.51,646.42,258.09,646.86,258.9z",
  "M638.82,257.91c0.42,0.81,1.33,1.53,2.03,1.62c0.7,0.09,0.93-0.5,0.51-1.31c-0.42-0.81-1.33-1.54-2.03-1.62C638.62,256.51,638.4,257.1,638.82,257.91z",
  "M618.34,255.51c0.39,0.81,1.29,1.53,2.01,1.61c0.72,0.08,0.99-0.51,0.59-1.32c-0.39-0.81-1.29-1.53-2.01-1.62C618.21,254.1,617.95,254.7,618.34,255.51z",
  "M614.18,255.04c0.38,0.81,1.28,1.53,2,1.61c0.72,0.08,1-0.51,0.61-1.32c-0.39-0.81-1.29-1.53-2-1.61C614.07,253.64,613.8,254.23,614.18,255.04z",
  "M610,254.59c0.38,0.81,1.27,1.53,2,1.61c0.73,0.08,1.01-0.51,0.63-1.33c-0.38-0.81-1.28-1.53-2-1.61C609.91,253.18,609.63,253.78,610,254.59z",
  "M605.81,254.14c0.37,0.81,1.26,1.53,1.99,1.61c0.73,0.08,1.02-0.52,0.65-1.33c-0.37-0.81-1.27-1.53-1.99-1.61C605.73,252.73,605.44,253.33,605.81,254.14z",
  "M601.59,253.69c0.36,0.81,1.25,1.53,1.99,1.61c0.73,0.08,1.03-0.52,0.66-1.33c-0.37-0.81-1.26-1.53-1.99-1.61C601.52,252.29,601.23,252.88,601.59,253.69z",
  "M558.46,249.64c0.3,0.81,1.16,1.53,1.92,1.59c0.76,0.06,1.14-0.54,0.83-1.36c-0.3-0.81-1.17-1.53-1.93-1.59C558.53,248.22,558.16,248.82,558.46,249.64z",
  "M554.05,249.27c0.29,0.81,1.15,1.52,1.92,1.59c0.77,0.06,1.15-0.55,0.85-1.36c-0.3-0.81-1.16-1.53-1.92-1.59C554.14,247.85,553.76,248.46,554.05,249.27z",
  "M449.35,243.31c0.13,0.82,0.9,1.5,1.71,1.53c0.81,0.03,1.36-0.61,1.22-1.43c-0.14-0.82-0.9-1.5-1.71-1.53C449.76,241.85,449.22,242.49,449.35,243.31z",
  "M444.68,243.16c0.12,0.82,0.88,1.5,1.7,1.52c0.81,0.02,1.37-0.62,1.24-1.43c-0.13-0.82-0.89-1.5-1.7-1.52C445.11,241.71,444.56,242.35,444.68,243.16z",
  "M440.01,243.03c0.12,0.82,0.87,1.5,1.68,1.52c0.81,0.02,1.37-0.62,1.25-1.44c-0.12-0.82-0.88-1.5-1.69-1.52C440.45,241.57,439.89,242.21,440.01,243.03z",
  "M435.33,242.9c0.11,0.82,0.86,1.5,1.67,1.52c0.81,0.02,1.38-0.62,1.27-1.44c-0.12-0.82-0.87-1.5-1.67-1.52C435.79,241.44,435.22,242.09,435.33,242.9z",
  "M430.65,242.79c0.1,0.82,0.85,1.49,1.66,1.51c0.82,0.02,1.39-0.63,1.28-1.44c-0.11-0.82-0.85-1.5-1.66-1.52C431.12,241.32,430.55,241.97,430.65,242.79z",
  "M425.96,242.68c0.1,0.82,0.83,1.49,1.65,1.51c0.82,0.02,1.4-0.63,1.29-1.45c-0.1-0.82-0.84-1.49-1.65-1.51C426.44,241.22,425.86,241.87,425.96,242.68z",
  "M421.27,242.59c0.09,0.82,0.82,1.49,1.64,1.51c0.82,0.02,1.4-0.63,1.31-1.45c-0.09-0.82-0.83-1.49-1.64-1.51C421.76,241.12,421.18,241.77,421.27,242.59z",
  "M416.57,242.5c0.08,0.82,0.81,1.49,1.63,1.5c0.82,0.01,1.41-0.64,1.32-1.45c-0.09-0.82-0.82-1.49-1.63-1.51C417.08,241.04,416.49,241.69,416.57,242.5z",
  "M411.86,242.43c0.07,0.82,0.8,1.49,1.62,1.5c0.82,0.01,1.42-0.64,1.34-1.46c-0.08-0.82-0.8-1.49-1.62-1.5C412.39,240.96,411.79,241.61,411.86,242.43z",
  "M407.16,242.37c0.07,0.82,0.78,1.49,1.6,1.5c0.82,0.01,1.42-0.64,1.35-1.46c-0.07-0.82-0.79-1.49-1.6-1.5C407.7,240.89,407.09,241.55,407.16,242.37z",
  "M402.45,242.31c0.06,0.82,0.77,1.49,1.59,1.49c0.82,0.01,1.43-0.65,1.37-1.46c-0.07-0.82-0.78-1.49-1.59-1.5C403,240.84,402.39,241.49,402.45,242.31z",
  "M397.73,242.27c0.05,0.82,0.76,1.48,1.58,1.49c0.82,0.01,1.44-0.65,1.38-1.47c-0.06-0.82-0.77-1.49-1.58-1.49C398.3,240.79,397.68,241.45,397.73,242.27z",
  "M393.02,242.24c0.04,0.82,0.75,1.48,1.57,1.49c0.82,0.01,1.45-0.65,1.4-1.47c-0.05-0.82-0.75-1.48-1.57-1.49C393.6,240.76,392.97,241.42,393.02,242.24z",
  "M388.3,242.21c0.04,0.82,0.73,1.48,1.55,1.48c0.82,0,1.45-0.65,1.41-1.47c-0.04-0.82-0.74-1.48-1.56-1.49C388.89,240.74,388.26,241.4,388.3,242.21z",
  "M383.58,242.2c0.03,0.82,0.72,1.48,1.54,1.48c0.82,0,1.46-0.66,1.42-1.47c-0.04-0.82-0.73-1.48-1.54-1.48C384.18,240.72,383.55,241.38,383.58,242.2z",
  "M378.85,242.2c0.02,0.82,0.71,1.48,1.53,1.48c0.82,0,1.46-0.66,1.44-1.48c-0.03-0.82-0.71-1.48-1.53-1.48C379.47,240.72,378.83,241.38,378.85,242.2z",
  "M374.13,242.21c0.02,0.82,0.69,1.48,1.52,1.47c0.82,0,1.47-0.66,1.45-1.48c-0.02-0.82-0.7-1.48-1.52-1.48C374.76,240.73,374.11,241.39,374.13,242.21z",
  "M369.41,242.23c0.01,0.82,0.68,1.48,1.5,1.47c0.82,0,1.48-0.67,1.46-1.48c-0.01-0.82-0.69-1.48-1.5-1.47C370.05,240.74,369.4,241.41,369.41,242.23z",
  "M364.68,242.26c0,0.82,0.67,1.47,1.49,1.47c0.82-0.01,1.48-0.67,1.48-1.49c-0.01-0.82-0.67-1.48-1.49-1.47C365.34,240.77,364.68,241.44,364.68,242.26z",
  "M350.5,242.41c-0.02,0.82,0.63,1.47,1.45,1.46c0.82-0.01,1.5-0.68,1.52-1.5c0.02-0.82-0.63-1.47-1.45-1.46C351.2,240.92,350.52,241.59,350.5,242.41z",
  "M345.78,242.48c-0.03,0.82,0.62,1.47,1.44,1.45c0.82-0.01,1.51-0.68,1.53-1.5c0.02-0.82-0.62-1.47-1.44-1.46C346.49,240.99,345.81,241.66,345.78,242.48z",
  "M336.33,242.65c-0.04,0.82,0.59,1.46,1.41,1.45c0.82-0.02,1.52-0.69,1.55-1.51c0.04-0.82-0.59-1.47-1.41-1.45C337.07,241.16,336.38,241.84,336.33,242.65z",
  "M224.44,247.89c-0.21,0.81,0.26,1.43,1.06,1.37c0.8-0.06,1.61-0.76,1.82-1.58c0.21-0.81-0.26-1.43-1.05-1.37C225.46,246.37,224.65,247.08,224.44,247.89z",
  "M219.87,248.23c-0.22,0.81,0.25,1.43,1.04,1.37c0.79-0.06,1.61-0.77,1.83-1.58c0.21-0.81-0.25-1.43-1.04-1.37C220.91,246.71,220.09,247.41,219.87,248.23z",
  "M215.32,248.58c-0.23,0.81,0.23,1.42,1.02,1.36c0.79-0.06,1.61-0.77,1.83-1.58c0.22-0.81-0.24-1.43-1.02-1.37C216.37,247.05,215.55,247.76,215.32,248.58z",
  "M210.78,248.93c-0.23,0.81,0.22,1.42,1.01,1.36c0.79-0.06,1.61-0.77,1.84-1.58c0.23-0.81-0.22-1.42-1.01-1.36C211.84,247.41,211.01,248.12,210.78,248.93z",
  "M206.25,249.3c-0.24,0.81,0.2,1.42,0.99,1.36c0.79-0.06,1.62-0.77,1.85-1.59c0.24-0.81-0.21-1.42-0.99-1.36C207.32,247.77,206.49,248.48,206.25,249.3z",
  "M201.73,249.67c-0.25,0.81,0.19,1.42,0.98,1.35c0.79-0.06,1.62-0.78,1.86-1.59c0.24-0.81-0.19-1.42-0.97-1.36C202.81,248.14,201.98,248.86,201.73,249.67z",
  "M197.23,250.05c-0.25,0.81,0.18,1.42,0.96,1.35c0.78-0.07,1.62-0.78,1.87-1.59c0.25-0.81-0.18-1.42-0.96-1.35C198.32,248.52,197.48,249.24,197.23,250.05z",
  "M174.91,252.08c-0.29,0.81,0.11,1.41,0.88,1.34c0.77-0.07,1.62-0.79,1.91-1.6c0.28-0.81-0.11-1.41-0.88-1.34C176.05,250.55,175.19,251.27,174.91,252.08z",
  "M660.09,256.39c0.46,0.81,1.37,1.54,2.05,1.63c0.67,0.09,0.84-0.49,0.38-1.3c-0.46-0.81-1.38-1.54-2.05-1.63C659.8,255,659.63,255.58,660.09,256.39z",
  "M656.2,255.87c0.45,0.81,1.37,1.54,2.04,1.63c0.68,0.09,0.86-0.49,0.4-1.3c-0.46-0.81-1.37-1.54-2.04-1.63C655.93,254.48,655.75,255.06,656.2,255.87z",
  "M648.36,254.84c0.44,0.81,1.35,1.54,2.04,1.62c0.68,0.09,0.88-0.49,0.43-1.3c-0.45-0.81-1.36-1.54-2.04-1.63C648.11,253.45,647.92,254.03,648.36,254.84z",
  "M612,250.46c0.39,0.81,1.28,1.54,2,1.62c0.72,0.08,0.98-0.51,0.59-1.32c-0.39-0.81-1.29-1.54-2-1.62C611.88,249.05,611.61,249.64,612,250.46z",
  "M607.86,250c0.38,0.81,1.27,1.53,2,1.61c0.72,0.08,0.99-0.51,0.61-1.33c-0.39-0.81-1.28-1.54-2-1.61C607.75,248.59,607.48,249.19,607.86,250z",
  "M599.52,249.1c0.37,0.81,1.26,1.53,1.99,1.61c0.73,0.08,1.02-0.52,0.64-1.33c-0.37-0.81-1.26-1.53-1.99-1.61C599.44,247.69,599.15,248.29,599.52,249.1z",
  "M595.32,248.66c0.36,0.81,1.25,1.53,1.98,1.61c0.73,0.08,1.03-0.52,0.66-1.33c-0.37-0.81-1.25-1.53-1.98-1.61C595.25,247.25,594.96,247.85,595.32,248.66z",
  "M569.76,246.18c0.32,0.81,1.19,1.53,1.94,1.6c0.75,0.07,1.09-0.54,0.76-1.35c-0.33-0.81-1.2-1.53-1.94-1.6C569.77,244.76,569.43,245.36,569.76,246.18z",
  "M565.44,245.79c0.32,0.81,1.18,1.53,1.94,1.6c0.75,0.07,1.1-0.54,0.78-1.35c-0.32-0.81-1.19-1.53-1.94-1.6C565.47,244.37,565.12,244.97,565.44,245.79z",
  "M561.1,245.41c0.31,0.81,1.17,1.53,1.93,1.59c0.75,0.07,1.11-0.54,0.8-1.36c-0.32-0.81-1.18-1.53-1.93-1.6C561.15,243.98,560.79,244.59,561.1,245.41z",
  "M552.38,244.67c0.3,0.82,1.15,1.53,1.91,1.59c0.76,0.06,1.13-0.55,0.83-1.36c-0.3-0.81-1.16-1.53-1.92-1.59C552.46,243.24,552.09,243.85,552.38,244.67z",
  "M516.96,242.04c0.24,0.82,1.07,1.52,1.85,1.57c0.78,0.05,1.21-0.57,0.96-1.38c-0.25-0.82-1.08-1.52-1.85-1.57C517.14,240.61,516.71,241.23,516.96,242.04z",
  "M448.59,238.69c0.13,0.82,0.89,1.5,1.7,1.53c0.8,0.03,1.34-0.61,1.2-1.43c-0.14-0.82-0.9-1.5-1.7-1.53C449,237.23,448.46,237.87,448.59,238.69z",
  "M443.97,238.54c0.13,0.82,0.88,1.5,1.69,1.52c0.81,0.02,1.35-0.62,1.22-1.43c-0.13-0.82-0.89-1.5-1.69-1.53C444.39,237.08,443.84,237.73,443.97,238.54z",
  "M439.34,238.41c0.12,0.82,0.87,1.5,1.68,1.52c0.81,0.02,1.36-0.62,1.23-1.44c-0.13-0.82-0.88-1.5-1.68-1.52C439.77,236.95,439.22,237.59,439.34,238.41z",
  "M434.7,238.28c0.11,0.82,0.86,1.5,1.66,1.52c0.81,0.02,1.37-0.62,1.25-1.44c-0.12-0.82-0.86-1.5-1.67-1.52C435.15,236.82,434.59,237.47,434.7,238.28z",
  "M430.06,238.17c0.11,0.82,0.85,1.5,1.65,1.52c0.81,0.02,1.37-0.63,1.26-1.44c-0.11-0.82-0.85-1.5-1.65-1.52C430.52,236.7,429.95,237.35,430.06,238.17z",
  "M425.41,238.06c0.1,0.82,0.83,1.49,1.64,1.51c0.81,0.02,1.38-0.63,1.28-1.45c-0.1-0.82-0.84-1.5-1.64-1.51C425.89,236.6,425.31,237.25,425.41,238.06z",
  "M420.76,237.97c0.09,0.82,0.82,1.49,1.63,1.51c0.81,0.02,1.39-0.63,1.29-1.45c-0.1-0.82-0.83-1.49-1.63-1.51C421.25,236.5,420.67,237.15,420.76,237.97z",
  "M416.1,237.88c0.08,0.82,0.81,1.49,1.62,1.51c0.81,0.01,1.4-0.64,1.31-1.45c-0.09-0.82-0.81-1.49-1.62-1.51C416.6,236.41,416.02,237.07,416.1,237.88z",
  "M411.44,237.81c0.08,0.82,0.79,1.49,1.61,1.5c0.81,0.01,1.4-0.64,1.32-1.46c-0.08-0.82-0.8-1.49-1.61-1.5C411.96,236.34,411.36,236.99,411.44,237.81z",
  "M406.77,237.74c0.07,0.82,0.78,1.49,1.59,1.5c0.81,0.01,1.41-0.64,1.34-1.46c-0.07-0.82-0.79-1.49-1.59-1.5C407.3,236.27,406.71,236.93,406.77,237.74z",
  "M402.11,237.69c0.06,0.82,0.77,1.49,1.58,1.5c0.81,0.01,1.42-0.65,1.35-1.46c-0.07-0.82-0.77-1.49-1.58-1.5C402.65,236.22,402.04,236.87,402.11,237.69z",
  "M397.43,237.65c0.05,0.82,0.76,1.49,1.57,1.49c0.81,0.01,1.42-0.65,1.37-1.47c-0.06-0.82-0.76-1.49-1.57-1.49C397.99,236.17,397.38,236.83,397.43,237.65z",
  "M392.76,237.61c0.05,0.82,0.74,1.48,1.56,1.49c0.81,0.01,1.43-0.65,1.38-1.47c-0.05-0.82-0.75-1.49-1.56-1.49C393.33,236.14,392.71,236.8,392.76,237.61z",
  "M388.08,237.59c0.04,0.82,0.73,1.48,1.54,1.49c0.81,0,1.44-0.66,1.39-1.47c-0.04-0.82-0.73-1.48-1.54-1.49C388.67,236.11,388.04,236.77,388.08,237.59z",
  "M383.4,237.58c0.03,0.82,0.72,1.48,1.53,1.48c0.81,0,1.44-0.66,1.41-1.48c-0.04-0.82-0.72-1.48-1.53-1.48C384,236.1,383.37,236.76,383.4,237.58z",
  "M378.72,237.58c0.02,0.82,0.7,1.48,1.52,1.48c0.82,0,1.45-0.66,1.42-1.48c-0.03-0.82-0.71-1.48-1.52-1.48C379.33,236.1,378.7,236.76,378.72,237.58z",
  "M374.04,237.59c0.02,0.82,0.69,1.48,1.5,1.48c0.81,0,1.46-0.67,1.44-1.48c-0.02-0.82-0.69-1.48-1.5-1.48C374.67,236.1,374.02,236.77,374.04,237.59z",
  "M369.36,237.61c0.01,0.82,0.68,1.48,1.49,1.47c0.82,0,1.46-0.67,1.45-1.49c-0.01-0.82-0.68-1.48-1.49-1.47C370,236.12,369.35,236.79,369.36,237.61z",
  "M364.67,237.64c0,0.82,0.66,1.48,1.48,1.47c0.82-0.01,1.47-0.67,1.46-1.49c-0.01-0.82-0.67-1.48-1.48-1.47C365.33,236.15,364.67,236.82,364.67,237.64z",
  "M359.99,237.68c-0.01,0.82,0.65,1.47,1.46,1.47c0.82-0.01,1.48-0.68,1.48-1.49c0-0.82-0.65-1.48-1.46-1.47C360.66,236.19,359.99,236.86,359.99,237.68z",
  "M355.3,237.73c-0.01,0.82,0.64,1.47,1.45,1.46c0.82-0.01,1.48-0.68,1.49-1.5c0.01-0.82-0.64-1.47-1.45-1.46C355.98,236.24,355.32,236.91,355.3,237.73z",
  "M350.62,237.79c-0.02,0.82,0.62,1.47,1.44,1.46c0.82-0.01,1.49-0.68,1.5-1.5c0.02-0.82-0.63-1.47-1.44-1.46C351.31,236.3,350.64,236.97,350.62,237.79z",
  "M345.94,237.86c-0.03,0.82,0.61,1.47,1.42,1.46c0.81-0.01,1.49-0.69,1.52-1.5c0.02-0.82-0.61-1.47-1.42-1.46C346.64,236.37,345.96,237.04,345.94,237.86z",
  "M341.25,237.94c-0.04,0.82,0.6,1.47,1.41,1.45c0.82-0.01,1.5-0.69,1.53-1.51c0.03-0.82-0.6-1.47-1.41-1.45C341.98,236.45,341.29,237.13,341.25,237.94z",
  "M336.57,238.04c-0.04,0.82,0.58,1.47,1.4,1.45c0.81-0.02,1.51-0.69,1.54-1.51c0.04-0.82-0.59-1.47-1.4-1.45C337.31,236.54,336.62,237.22,336.57,238.04z",
  "M331.9,238.14c-0.05,0.82,0.57,1.46,1.38,1.45c0.81-0.02,1.51-0.69,1.56-1.51c0.05-0.82-0.57-1.47-1.38-1.45C332.64,236.64,331.95,237.32,331.9,238.14z",
  "M327.22,238.25c-0.06,0.82,0.55,1.46,1.37,1.44c0.81-0.02,1.52-0.7,1.57-1.52c0.05-0.82-0.56-1.46-1.37-1.44C327.98,236.75,327.28,237.43,327.22,238.25z",
  "M180.94,247.06c-0.29,0.81,0.11,1.41,0.87,1.34c0.77-0.07,1.62-0.79,1.9-1.6c0.28-0.81-0.11-1.42-0.87-1.34C182.08,245.53,181.23,246.25,180.94,247.06z",
  "M176.54,247.48c-0.29,0.81,0.09,1.41,0.86,1.34c0.77-0.07,1.62-0.79,1.91-1.61c0.29-0.81-0.09-1.41-0.85-1.34C177.69,245.95,176.84,246.67,176.54,247.48z",
  "M172.16,247.92c-0.3,0.81,0.08,1.41,0.84,1.34c0.76-0.08,1.62-0.79,1.91-1.61c0.29-0.81-0.08-1.41-0.84-1.34C173.31,246.38,172.46,247.1,172.16,247.92z",
  "M167.79,248.35c-0.31,0.81,0.06,1.41,0.82,1.33c0.76-0.08,1.62-0.8,1.92-1.61c0.3-0.81-0.07-1.41-0.82-1.33C168.95,246.82,168.09,247.54,167.79,248.35z",
  "M163.43,248.8c-0.31,0.81,0.05,1.41,0.81,1.33c0.76-0.08,1.62-0.8,1.93-1.61c0.31-0.81-0.05-1.41-0.8-1.33C164.61,247.27,163.75,247.99,163.43,248.8z",
  "M159.09,249.25c-0.32,0.81,0.03,1.41,0.79,1.33c0.75-0.08,1.62-0.8,1.93-1.61c0.31-0.81-0.04-1.41-0.79-1.33C160.28,247.72,159.41,248.44,159.09,249.25z",
  "M657.49,251.82c0.46,0.81,1.37,1.54,2.04,1.63c0.67,0.09,0.83-0.49,0.37-1.3c-0.46-0.81-1.38-1.54-2.04-1.63C657.19,250.43,657.03,251.01,657.49,251.82z",
  "M653.64,251.3c0.46,0.81,1.37,1.54,2.04,1.63c0.67,0.09,0.84-0.49,0.38-1.3c-0.46-0.81-1.37-1.54-2.04-1.63C653.35,249.91,653.18,250.49,653.64,251.3z",
  "M649.76,250.78c0.45,0.81,1.36,1.54,2.04,1.63c0.68,0.09,0.85-0.49,0.4-1.3c-0.45-0.81-1.37-1.54-2.04-1.63C649.49,249.39,649.31,249.97,649.76,250.78z",
  "M645.86,250.26c0.44,0.81,1.35,1.54,2.03,1.63c0.68,0.09,0.87-0.49,0.42-1.3c-0.45-0.81-1.36-1.54-2.03-1.63C645.6,248.87,645.41,249.45,645.86,250.26z",
  "M613.88,246.33c0.4,0.81,1.29,1.54,2,1.62c0.71,0.08,0.96-0.51,0.55-1.32c-0.4-0.81-1.3-1.54-2-1.62C613.73,244.92,613.49,245.52,613.88,246.33z",
  "M605.69,245.4c0.39,0.81,1.28,1.54,1.99,1.62c0.72,0.08,0.98-0.51,0.59-1.33c-0.39-0.81-1.28-1.54-1.99-1.62C605.56,244,605.3,244.59,605.69,245.4z",
  "M597.42,244.5c0.37,0.81,1.26,1.54,1.98,1.61c0.72,0.08,1-0.52,0.62-1.33c-0.38-0.81-1.27-1.54-1.98-1.61C597.32,243.09,597.04,243.69,597.42,244.5z",
  "M593.25,244.06c0.37,0.81,1.25,1.53,1.98,1.61c0.72,0.08,1.01-0.52,0.64-1.34c-0.37-0.81-1.26-1.54-1.98-1.61C593.17,242.65,592.88,243.25,593.25,244.06z",
  "M567.91,241.57c0.33,0.82,1.2,1.53,1.94,1.6c0.74,0.07,1.08-0.54,0.74-1.35c-0.33-0.82-1.2-1.53-1.94-1.6C567.91,240.15,567.58,240.75,567.91,241.57z",
  "M563.63,241.18c0.32,0.82,1.19,1.53,1.93,1.6c0.75,0.07,1.09-0.54,0.76-1.35c-0.33-0.82-1.19-1.53-1.93-1.6C563.64,239.76,563.3,240.36,563.63,241.18z",
  "M559.33,240.8c0.32,0.82,1.18,1.53,1.93,1.6c0.75,0.07,1.1-0.54,0.78-1.36c-0.32-0.82-1.18-1.53-1.93-1.6C559.36,239.37,559.01,239.98,559.33,240.8z",
  "M550.69,240.06c0.3,0.82,1.16,1.53,1.91,1.59c0.75,0.06,1.12-0.55,0.81-1.36c-0.31-0.82-1.16-1.53-1.91-1.59C550.75,238.63,550.39,239.24,550.69,240.06z",
  "M511.13,237.14c0.24,0.82,1.06,1.52,1.83,1.57c0.77,0.05,1.2-0.57,0.96-1.39c-0.25-0.82-1.07-1.52-1.84-1.57C511.31,235.7,510.89,236.32,511.13,237.14z",
  "M506.67,236.86c0.23,0.82,1.05,1.52,1.83,1.57c0.78,0.05,1.21-0.57,0.98-1.39c-0.24-0.82-1.06-1.52-1.83-1.57C506.87,235.41,506.43,236.04,506.67,236.86z",
  "M456.97,234.39c0.15,0.82,0.92,1.5,1.71,1.54c0.79,0.03,1.31-0.61,1.15-1.43c-0.16-0.82-0.93-1.51-1.71-1.54C457.33,232.93,456.82,233.57,456.97,234.39z",
  "M452.4,234.22c0.14,0.82,0.91,1.5,1.7,1.53c0.8,0.03,1.32-0.61,1.17-1.43c-0.15-0.82-0.91-1.5-1.7-1.53C452.78,232.77,452.26,233.41,452.4,234.22z",
  "M447.82,234.07c0.14,0.82,0.89,1.5,1.69,1.53c0.8,0.03,1.33-0.61,1.18-1.43c-0.14-0.82-0.9-1.5-1.69-1.53C448.22,232.61,447.69,233.25,447.82,234.07z",
  "M443.24,233.92c0.13,0.82,0.88,1.5,1.68,1.53c0.8,0.03,1.34-0.62,1.2-1.44c-0.14-0.82-0.89-1.5-1.68-1.53C443.65,232.46,443.11,233.1,443.24,233.92z",
  "M438.65,233.79c0.12,0.82,0.87,1.5,1.67,1.52c0.8,0.02,1.34-0.62,1.22-1.44c-0.13-0.82-0.87-1.5-1.67-1.52C439.07,232.32,438.53,232.97,438.65,233.79z",
  "M434.06,233.66c0.11,0.82,0.86,1.5,1.66,1.52c0.8,0.02,1.35-0.62,1.23-1.44c-0.12-0.82-0.86-1.5-1.66-1.52C434.49,232.2,433.94,232.84,434.06,233.66z",
  "M429.46,233.54c0.11,0.82,0.84,1.5,1.64,1.52c0.8,0.02,1.36-0.63,1.25-1.45c-0.11-0.82-0.85-1.5-1.64-1.52C429.91,232.08,429.35,232.73,429.46,233.54z",
  "M424.85,233.44c0.1,0.82,0.83,1.49,1.63,1.51c0.8,0.02,1.37-0.63,1.26-1.45c-0.1-0.82-0.84-1.5-1.63-1.51C425.32,231.97,424.75,232.62,424.85,233.44z",
  "M420.24,233.34c0.09,0.82,0.82,1.49,1.62,1.51c0.8,0.02,1.37-0.63,1.28-1.45c-0.1-0.82-0.82-1.49-1.62-1.51C420.72,231.87,420.15,232.53,420.24,233.34z",
  "M415.63,233.26c0.08,0.82,0.8,1.49,1.61,1.51c0.8,0.01,1.38-0.64,1.29-1.45c-0.09-0.82-0.81-1.49-1.61-1.51C416.12,231.79,415.54,232.44,415.63,233.26z",
  "M411.01,233.18c0.08,0.82,0.79,1.49,1.59,1.5c0.8,0.01,1.39-0.64,1.31-1.46c-0.08-0.82-0.8-1.49-1.6-1.5C411.52,231.71,410.93,232.37,411.01,233.18z",
  "M406.38,233.12c0.07,0.82,0.78,1.49,1.58,1.5c0.8,0.01,1.4-0.64,1.32-1.46c-0.07-0.82-0.78-1.49-1.58-1.5C406.91,231.65,406.32,232.3,406.38,233.12z",
  "M401.76,233.07c0.06,0.82,0.76,1.49,1.57,1.5c0.8,0.01,1.4-0.65,1.34-1.46c-0.07-0.82-0.77-1.49-1.57-1.5C402.29,231.59,401.7,232.25,401.76,233.07z",
  "M397.13,233.02c0.05,0.82,0.75,1.49,1.56,1.49c0.81,0.01,1.41-0.65,1.35-1.47c-0.06-0.82-0.76-1.49-1.56-1.5C397.68,231.55,397.07,232.21,397.13,233.02z",
  "M392.5,232.99c0.05,0.82,0.74,1.49,1.54,1.49c0.81,0.01,1.42-0.65,1.37-1.47c-0.05-0.82-0.74-1.49-1.54-1.49C393.06,231.51,392.45,232.17,392.5,232.99z",
  "M387.86,232.97c0.04,0.82,0.72,1.48,1.53,1.49s1.43-0.66,1.38-1.47c-0.04-0.82-0.73-1.48-1.53-1.49C388.44,231.49,387.82,232.15,387.86,232.97z",
  "M383.22,232.96c0.03,0.82,0.71,1.48,1.52,1.48c0.81,0,1.43-0.66,1.39-1.48c-0.04-0.82-0.72-1.48-1.52-1.49C383.82,231.48,383.19,232.14,383.22,232.96z",
  "M378.59,232.95c0.02,0.82,0.7,1.48,1.5,1.48c0.81,0,1.44-0.66,1.41-1.48c-0.03-0.82-0.7-1.48-1.5-1.48C379.19,231.47,378.56,232.14,378.59,232.95z",
  "M373.95,232.96c0.02,0.82,0.68,1.48,1.49,1.48c0.81,0,1.44-0.67,1.42-1.48c-0.02-0.82-0.69-1.48-1.49-1.48C374.57,231.48,373.93,232.15,373.95,232.96z",
  "M369.31,232.98c0.01,0.82,0.67,1.48,1.48,1.47c0.81,0,1.45-0.67,1.44-1.49c-0.01-0.82-0.68-1.48-1.48-1.48C369.94,231.5,369.3,232.17,369.31,232.98z",
  "M364.66,233.01c0,0.82,0.66,1.48,1.46,1.47c0.81-0.01,1.46-0.67,1.45-1.49c-0.01-0.82-0.66-1.48-1.46-1.47C365.31,231.53,364.66,232.19,364.66,233.01z",
  "M360.02,233.05c-0.01,0.82,0.64,1.47,1.45,1.47c0.81-0.01,1.46-0.68,1.46-1.49c0-0.82-0.65-1.48-1.45-1.47C360.69,231.57,360.03,232.23,360.02,233.05z",
  "M355.38,233.1c-0.01,0.82,0.63,1.47,1.44,1.46c0.81-0.01,1.47-0.68,1.48-1.5c0.01-0.82-0.63-1.47-1.44-1.47C356.06,231.61,355.4,232.29,355.38,233.1z",
  "M350.74,233.17c-0.02,0.82,0.62,1.47,1.42,1.46c0.81-0.01,1.48-0.68,1.49-1.5c0.02-0.82-0.62-1.47-1.42-1.46C351.43,231.67,350.76,232.35,350.74,233.17z",
  "M346.1,233.24c-0.03,0.82,0.6,1.47,1.41,1.46c0.81-0.01,1.48-0.69,1.51-1.5c0.02-0.82-0.61-1.47-1.41-1.46C346.8,231.75,346.13,232.42,346.1,233.24z",
  "M341.46,233.32c-0.04,0.82,0.59,1.47,1.39,1.45c0.81-0.01,1.49-0.69,1.52-1.51c0.03-0.82-0.59-1.47-1.39-1.46C342.18,231.83,341.5,232.5,341.46,233.32z",
  "M336.82,233.41c-0.04,0.82,0.57,1.47,1.38,1.45c0.81-0.02,1.49-0.69,1.53-1.51c0.04-0.82-0.58-1.47-1.38-1.45C337.55,231.92,336.87,232.59,336.82,233.41z",
  "M332.19,233.52c-0.05,0.82,0.56,1.47,1.37,1.45c0.81-0.02,1.5-0.7,1.55-1.51c0.05-0.82-0.56-1.47-1.37-1.45C332.93,232.02,332.24,232.7,332.19,233.52z",
  "M327.55,233.63c-0.06,0.82,0.55,1.46,1.35,1.44c0.81-0.02,1.5-0.7,1.56-1.52c0.05-0.82-0.55-1.47-1.35-1.45C328.31,232.13,327.61,232.81,327.55,233.63z",
  "M322.92,233.75c-0.07,0.82,0.53,1.46,1.34,1.44c0.81-0.02,1.51-0.7,1.57-1.52c0.06-0.82-0.54-1.46-1.34-1.44C323.69,232.25,322.99,232.93,322.92,233.75z",
  "M318.29,233.89c-0.07,0.82,0.52,1.46,1.32,1.44c0.81-0.02,1.51-0.7,1.58-1.52c0.07-0.82-0.52-1.46-1.32-1.44C319.08,232.39,318.37,233.07,318.29,233.89z",
  "M295.21,234.71c-0.11,0.82,0.45,1.45,1.25,1.42c0.8-0.03,1.54-0.72,1.65-1.54c0.11-0.82-0.45-1.45-1.25-1.42C296.06,233.2,295.32,233.89,295.21,234.71z",
  "M276.83,235.55c-0.14,0.82,0.39,1.45,1.19,1.41c0.8-0.04,1.56-0.73,1.69-1.55c0.14-0.82-0.39-1.45-1.19-1.41C277.73,234.04,276.97,234.74,276.83,235.55z",
  "M186.95,242.04c-0.28,0.81,0.1,1.42,0.87,1.35c0.76-0.07,1.61-0.79,1.89-1.6c0.28-0.81-0.11-1.42-0.87-1.35C188.08,240.51,187.23,241.23,186.95,242.04z",
  "M178.21,242.88c-0.3,0.81,0.08,1.41,0.83,1.34c0.76-0.07,1.61-0.79,1.9-1.61c0.29-0.81-0.08-1.42-0.83-1.34C179.36,241.35,178.51,242.07,178.21,242.88z",
  "M173.86,243.32c-0.3,0.81,0.06,1.41,0.82,1.34c0.76-0.07,1.61-0.8,1.91-1.61c0.3-0.81-0.07-1.41-0.82-1.34C175.02,241.78,174.17,242.5,173.86,243.32z",
  "M169.53,243.76c-0.31,0.81,0.05,1.41,0.8,1.33c0.75-0.08,1.61-0.8,1.92-1.61c0.31-0.81-0.05-1.41-0.8-1.34C170.7,242.22,169.84,242.94,169.53,243.76z",
  "M165.21,244.2c-0.32,0.81,0.03,1.41,0.79,1.33c0.75-0.08,1.61-0.8,1.92-1.61c0.31-0.81-0.04-1.41-0.78-1.33C166.39,242.67,165.53,243.39,165.21,244.2z",
  "M160.91,244.66c-0.32,0.81,0.02,1.41,0.77,1.33c0.75-0.08,1.61-0.8,1.93-1.62c0.32-0.81-0.02-1.41-0.77-1.33C162.1,243.12,161.23,243.85,160.91,244.66z",
  "M156.62,245.12c-0.33,0.81,0.01,1.41,0.75,1.33c0.75-0.08,1.61-0.8,1.94-1.62c0.33-0.81-0.01-1.41-0.75-1.33C157.82,243.58,156.95,244.31,156.62,245.12z",
  "M152.35,245.59c-0.34,0.81-0.01,1.41,0.73,1.32c0.74-0.08,1.61-0.81,1.95-1.62c0.33-0.81,0-1.41-0.73-1.32C153.56,244.05,152.69,244.78,152.35,245.59z",
  "M148.1,246.07c-0.34,0.81-0.02,1.4,0.72,1.32c0.74-0.08,1.61-0.81,1.95-1.62c0.34-0.81,0.02-1.41-0.72-1.32C149.32,244.53,148.44,245.26,148.1,246.07z",
  "M143.86,246.55c-0.35,0.81-0.04,1.4,0.7,1.32c0.74-0.08,1.61-0.81,1.96-1.62c0.35-0.81,0.03-1.4-0.7-1.32C145.09,245.01,144.21,245.74,143.86,246.55z",
  "M139.64,247.04c-0.36,0.81-0.05,1.4,0.68,1.32c0.73-0.09,1.61-0.81,1.96-1.62c0.35-0.81,0.05-1.4-0.68-1.32C140.88,245.5,140,246.23,139.64,247.04z",
  "M677.33,250.5c0.49,0.81,1.41,1.54,2.05,1.63c0.64,0.1,0.75-0.48,0.25-1.28c-0.5-0.8-1.41-1.54-2.05-1.63C676.96,249.12,676.84,249.69,677.33,250.5z",
  "M669.94,249.4c0.48,0.81,1.4,1.54,2.05,1.63c0.65,0.09,0.77-0.48,0.29-1.29c-0.49-0.81-1.4-1.54-2.05-1.63C669.59,248.02,669.46,248.6,669.94,249.4z",
  "M615.65,242.21c0.41,0.81,1.31,1.54,2.01,1.62c0.7,0.08,0.93-0.51,0.52-1.32c-0.41-0.81-1.31-1.54-2.01-1.62C615.48,240.8,615.24,241.4,615.65,242.21z",
  "M611.62,241.73c0.4,0.81,1.3,1.54,2,1.62c0.7,0.08,0.94-0.51,0.54-1.32c-0.41-0.81-1.3-1.54-2-1.62C611.45,240.33,611.21,240.92,611.62,241.73z",
  "M607.56,241.27c0.4,0.81,1.29,1.54,2,1.62c0.71,0.08,0.95-0.51,0.55-1.33c-0.4-0.81-1.3-1.54-2-1.62C607.41,239.86,607.17,240.45,607.56,241.27z",
  "M591.16,239.46c0.37,0.81,1.26,1.54,1.97,1.61c0.72,0.08,1-0.52,0.62-1.34c-0.38-0.81-1.26-1.54-1.98-1.61C591.07,238.05,590.79,238.65,591.16,239.46z",
  "M566.03,236.96c0.33,0.82,1.2,1.53,1.94,1.6c0.74,0.07,1.06-0.54,0.72-1.35c-0.34-0.82-1.21-1.53-1.94-1.6C566.02,235.54,565.7,236.14,566.03,236.96z",
  "M561.79,236.57c0.33,0.82,1.19,1.53,1.93,1.6c0.74,0.07,1.07-0.54,0.74-1.35c-0.33-0.82-1.2-1.53-1.93-1.6C561.79,235.14,561.46,235.75,561.79,236.57z",
  "M557.53,236.18c0.32,0.82,1.18,1.53,1.92,1.6c0.74,0.07,1.08-0.54,0.76-1.36c-0.33-0.82-1.19-1.53-1.92-1.6C557.55,234.76,557.21,235.37,557.53,236.18z",
  "M553.26,235.81c0.31,0.82,1.17,1.53,1.91,1.59c0.74,0.06,1.09-0.54,0.77-1.36c-0.32-0.82-1.18-1.53-1.92-1.6C553.29,234.38,552.94,234.99,553.26,235.81z",
  "M548.97,235.44c0.31,0.82,1.16,1.53,1.91,1.59c0.75,0.06,1.1-0.55,0.79-1.36c-0.31-0.82-1.17-1.53-1.91-1.59C549.01,234.01,548.66,234.63,548.97,235.44z",
  "M509.76,232.52c0.24,0.82,1.06,1.52,1.83,1.57c0.77,0.05,1.19-0.57,0.94-1.39c-0.25-0.82-1.07-1.52-1.83-1.57C509.93,231.08,509.51,231.7,509.76,232.52z",
  "M505.34,232.24c0.24,0.82,1.05,1.52,1.82,1.57c0.77,0.05,1.2-0.57,0.96-1.39c-0.24-0.82-1.06-1.52-1.82-1.57C505.53,230.79,505.1,231.42,505.34,232.24z",
  "M500.91,231.96c0.23,0.82,1.04,1.52,1.81,1.56c0.77,0.05,1.21-0.58,0.97-1.39c-0.23-0.82-1.05-1.52-1.81-1.57C501.12,230.52,500.68,231.15,500.91,231.96z",
  "M442.5,229.3c0.13,0.82,0.88,1.5,1.67,1.53c0.79,0.03,1.32-0.62,1.18-1.44c-0.14-0.82-0.89-1.5-1.67-1.53C442.9,227.84,442.37,228.48,442.5,229.3z",
  "M437.95,229.16c0.12,0.82,0.87,1.5,1.66,1.52c0.79,0.02,1.33-0.62,1.2-1.44c-0.13-0.82-0.87-1.5-1.66-1.52C438.37,227.7,437.83,228.34,437.95,229.16z",
  "M433.4,229.03c0.12,0.82,0.85,1.5,1.65,1.52c0.79,0.02,1.34-0.62,1.21-1.44c-0.12-0.82-0.86-1.5-1.65-1.52C433.83,227.57,433.29,228.22,433.4,229.03z",
  "M428.85,228.92c0.11,0.82,0.84,1.5,1.63,1.52c0.79,0.02,1.34-0.63,1.23-1.45c-0.11-0.82-0.85-1.5-1.63-1.52C429.29,227.45,428.74,228.1,428.85,228.92z",
  "M424.28,228.81c0.1,0.82,0.83,1.5,1.62,1.51c0.79,0.02,1.35-0.63,1.25-1.45c-0.11-0.82-0.83-1.5-1.62-1.51C424.74,227.35,424.18,228,424.28,228.81z",
  "M419.72,228.72c0.09,0.82,0.81,1.49,1.61,1.51c0.79,0.02,1.36-0.63,1.26-1.45c-0.1-0.82-0.82-1.5-1.61-1.51C420.19,227.25,419.62,227.9,419.72,228.72z",
  "M415.14,228.63c0.09,0.82,0.8,1.49,1.6,1.51c0.8,0.01,1.37-0.64,1.28-1.45c-0.09-0.82-0.81-1.49-1.6-1.51C415.63,227.16,415.06,227.82,415.14,228.63z",
  "M410.57,228.56c0.08,0.82,0.79,1.49,1.58,1.5c0.8,0.01,1.38-0.64,1.29-1.46c-0.08-0.82-0.79-1.49-1.58-1.5C411.07,227.09,410.49,227.74,410.57,228.56z",
  "M405.99,228.49c0.07,0.82,0.77,1.49,1.57,1.5c0.8,0.01,1.38-0.64,1.31-1.46c-0.08-0.82-0.78-1.49-1.57-1.5C406.5,227.02,405.92,227.68,405.99,228.49z",
  "M401.4,228.44c0.06,0.82,0.76,1.49,1.56,1.5c0.8,0.01,1.39-0.65,1.32-1.46c-0.07-0.82-0.77-1.49-1.56-1.5C401.93,226.97,401.34,227.62,401.4,228.44z",
  "M396.82,228.4c0.05,0.82,0.75,1.49,1.54,1.49c0.8,0.01,1.4-0.65,1.34-1.47c-0.06-0.82-0.75-1.49-1.54-1.49C397.36,226.92,396.76,227.58,396.82,228.4z",
  "M392.23,228.36c0.05,0.82,0.73,1.49,1.53,1.49c0.8,0.01,1.4-0.65,1.35-1.47c-0.05-0.82-0.74-1.49-1.53-1.49C392.79,226.89,392.18,227.55,392.23,228.36z",
  "M387.64,228.34c0.04,0.82,0.72,1.48,1.52,1.49c0.8,0,1.41-0.66,1.37-1.47c-0.05-0.82-0.73-1.48-1.52-1.49C388.21,226.86,387.6,227.52,387.64,228.34z",
  "M383.04,228.33c0.03,0.82,0.71,1.48,1.51,1.48c0.8,0,1.42-0.66,1.38-1.48c-0.04-0.82-0.71-1.48-1.5-1.48C383.63,226.85,383.01,227.51,383.04,228.33z",
  "M378.45,228.33c0.02,0.82,0.69,1.48,1.49,1.48c0.8,0,1.42-0.66,1.39-1.48c-0.03-0.82-0.7-1.48-1.49-1.48C379.05,226.85,378.43,227.51,378.45,228.33z",
  "M373.85,228.34c0.02,0.82,0.68,1.48,1.48,1.48c0.8,0,1.43-0.67,1.41-1.48c-0.02-0.82-0.68-1.48-1.48-1.48C374.47,226.85,373.84,227.52,373.85,228.34z",
  "M369.26,228.36c0.01,0.82,0.66,1.48,1.46,1.47c0.8,0,1.44-0.67,1.42-1.49c-0.01-0.82-0.67-1.48-1.46-1.48C369.89,226.87,369.25,227.54,369.26,228.36z",
  "M364.66,228.39c0,0.82,0.65,1.48,1.45,1.47c0.8-0.01,1.44-0.67,1.44-1.49c-0.01-0.82-0.66-1.48-1.45-1.47C365.3,226.9,364.66,227.57,364.66,228.39z",
  "M360.06,228.43c-0.01,0.82,0.64,1.47,1.44,1.47c0.8-0.01,1.45-0.68,1.45-1.49c0-0.82-0.64-1.48-1.44-1.47C360.72,226.94,360.07,227.61,360.06,228.43z",
  "M355.46,228.48c-0.02,0.82,0.62,1.47,1.42,1.46c0.8-0.01,1.46-0.68,1.47-1.5c0.01-0.82-0.63-1.47-1.42-1.47C356.13,226.99,355.48,227.66,355.46,228.48z",
  "M350.86,228.54c-0.02,0.82,0.61,1.47,1.41,1.46c0.8-0.01,1.46-0.68,1.48-1.5c0.02-0.82-0.61-1.47-1.41-1.46C351.55,227.05,350.89,227.72,350.86,228.54z",
  "M346.27,228.61c-0.03,0.82,0.59,1.47,1.39,1.46c0.8-0.01,1.47-0.69,1.49-1.5c0.03-0.82-0.6-1.47-1.39-1.46C346.97,227.12,346.3,227.79,346.27,228.61z",
  "M341.67,228.7c-0.04,0.82,0.58,1.47,1.38,1.45c0.8-0.01,1.47-0.69,1.51-1.51c0.03-0.82-0.59-1.47-1.38-1.46C342.38,227.2,341.71,227.88,341.67,228.7z",
  "M337.08,228.79c-0.05,0.82,0.56,1.47,1.36,1.45c0.8-0.02,1.48-0.69,1.52-1.51c0.04-0.82-0.57-1.47-1.36-1.45C337.8,227.29,337.12,227.97,337.08,228.79z",
  "M332.48,228.89c-0.05,0.82,0.55,1.47,1.35,1.45c0.8-0.02,1.49-0.7,1.53-1.51c0.05-0.82-0.56-1.47-1.35-1.45C333.23,227.39,332.54,228.07,332.48,228.89z",
  "M327.89,229c-0.06,0.82,0.54,1.46,1.34,1.44c0.8-0.02,1.49-0.7,1.55-1.52c0.06-0.82-0.54-1.47-1.34-1.45C328.65,227.51,327.95,228.19,327.89,229z",
  "M323.31,229.13c-0.07,0.82,0.52,1.46,1.32,1.44c0.8-0.02,1.5-0.7,1.56-1.52c0.06-0.82-0.53-1.46-1.32-1.44C324.07,227.63,323.37,228.31,323.31,229.13z",
  "M318.72,229.26c-0.08,0.82,0.51,1.46,1.31,1.44c0.8-0.02,1.5-0.71,1.57-1.52c0.07-0.82-0.51-1.46-1.31-1.44C319.5,227.76,318.8,228.45,318.72,229.26z",
  "M286.73,230.49c-0.13,0.82,0.41,1.45,1.2,1.41c0.79-0.04,1.54-0.73,1.66-1.55c0.12-0.82-0.41-1.45-1.2-1.42C287.61,228.98,286.86,229.67,286.73,230.49z",
  "M282.18,230.71c-0.14,0.82,0.39,1.45,1.18,1.41c0.79-0.04,1.54-0.73,1.67-1.55c0.13-0.82-0.4-1.45-1.18-1.41C283.07,229.2,282.32,229.89,282.18,230.71z",
  "M214.85,235.11c-0.25,0.82,0.18,1.43,0.95,1.36c0.77-0.06,1.59-0.77,1.83-1.59c0.24-0.82-0.18-1.43-0.94-1.37C215.92,233.58,215.1,234.29,214.85,235.11z",
  "M206.04,235.85c-0.26,0.82,0.15,1.42,0.91,1.36c0.77-0.07,1.59-0.78,1.85-1.6c0.26-0.82-0.15-1.42-0.91-1.36C207.13,234.32,206.3,235.03,206.04,235.85z",
  "M201.65,236.23c-0.27,0.82,0.13,1.42,0.9,1.36c0.76-0.07,1.59-0.78,1.86-1.6c0.26-0.82-0.14-1.42-0.9-1.36C202.76,234.7,201.92,235.42,201.65,236.23z",
  "M197.28,236.62c-0.28,0.82,0.12,1.42,0.88,1.35c0.76-0.07,1.6-0.78,1.87-1.6c0.27-0.82-0.12-1.42-0.88-1.35C198.39,235.09,197.55,235.81,197.28,236.62z",
  "M188.56,237.43c-0.29,0.82,0.09,1.42,0.85,1.35c0.76-0.07,1.6-0.79,1.88-1.6c0.28-0.82-0.09-1.42-0.85-1.35C189.7,235.9,188.85,236.62,188.56,237.43z",
  "M175.59,238.71c-0.31,0.81,0.05,1.41,0.8,1.34c0.75-0.08,1.6-0.8,1.91-1.61c0.3-0.81-0.05-1.41-0.8-1.34C176.76,237.17,175.9,237.9,175.59,238.71z",
  "M171.3,239.15c-0.32,0.81,0.03,1.41,0.78,1.34c0.75-0.08,1.6-0.8,1.91-1.61c0.31-0.81-0.04-1.41-0.78-1.34C172.47,237.62,171.62,238.34,171.3,239.15z",
  "M154.27,240.99c-0.34,0.81-0.02,1.41,0.71,1.32c0.74-0.08,1.61-0.81,1.94-1.62c0.34-0.81,0.02-1.41-0.71-1.33C155.48,239.45,154.61,240.18,154.27,240.99z",
  "M150.05,241.47c-0.35,0.81-0.04,1.4,0.7,1.32c0.73-0.08,1.61-0.81,1.95-1.62c0.34-0.81,0.03-1.41-0.7-1.32C151.27,239.93,150.4,240.66,150.05,241.47z",
  "M145.85,241.96c-0.35,0.81-0.05,1.4,0.68,1.32c0.73-0.08,1.61-0.81,1.96-1.62c0.35-0.81,0.05-1.4-0.68-1.32C147.08,240.42,146.2,241.14,145.85,241.96z",
  "M141.66,242.45c-0.36,0.81-0.06,1.4,0.66,1.32c0.73-0.09,1.61-0.81,1.96-1.63c0.36-0.81,0.06-1.4-0.66-1.32C142.9,240.91,142.03,241.64,141.66,242.45z",
  "M685.36,247.63c0.51,0.8,1.42,1.53,2.04,1.63c0.62,0.1,0.7-0.47,0.19-1.28c-0.51-0.8-1.43-1.53-2.04-1.63C684.94,246.25,684.85,246.82,685.36,247.63z",
  "M681.78,247.06c0.5,0.8,1.42,1.54,2.04,1.63c0.62,0.1,0.72-0.47,0.21-1.28c-0.51-0.8-1.42-1.54-2.04-1.63C681.37,245.69,681.27,246.26,681.78,247.06z",
  "M678.17,246.5c0.5,0.8,1.42,1.54,2.04,1.63c0.63,0.1,0.73-0.48,0.22-1.28c-0.5-0.8-1.42-1.54-2.04-1.63C677.77,245.12,677.67,245.7,678.17,246.5z",
  "M674.53,245.95c0.5,0.81,1.41,1.54,2.04,1.63c0.63,0.1,0.74-0.48,0.24-1.28c-0.5-0.81-1.41-1.54-2.04-1.63C674.15,244.57,674.04,245.14,674.53,245.95z",
  "M670.88,245.39c0.49,0.81,1.41,1.54,2.04,1.63c0.64,0.1,0.75-0.48,0.26-1.29c-0.49-0.81-1.41-1.54-2.04-1.63C670.5,244.01,670.39,244.59,670.88,245.39z",
  "M667.2,244.84c0.49,0.81,1.4,1.54,2.04,1.63c0.64,0.1,0.76-0.48,0.27-1.29c-0.49-0.81-1.4-1.54-2.04-1.63C666.83,243.46,666.71,244.04,667.2,244.84z",
  "M663.49,244.3c0.48,0.81,1.4,1.54,2.04,1.63c0.65,0.09,0.77-0.48,0.29-1.29c-0.49-0.81-1.4-1.54-2.04-1.63C663.14,242.91,663.01,243.49,663.49,244.3z",
  "M648.44,242.15c0.46,0.81,1.37,1.54,2.03,1.63c0.66,0.09,0.82-0.49,0.35-1.3c-0.47-0.81-1.38-1.54-2.03-1.63C648.14,240.76,647.98,241.34,648.44,242.15z",
  "M644.63,241.63c0.46,0.81,1.37,1.54,2.03,1.63c0.66,0.09,0.83-0.49,0.37-1.3c-0.46-0.81-1.37-1.54-2.03-1.63C644.33,240.23,644.17,240.82,644.63,241.63z",
  "M636.92,240.59c0.45,0.81,1.35,1.54,2.03,1.63c0.67,0.09,0.85-0.5,0.4-1.31c-0.45-0.81-1.36-1.54-2.03-1.63C636.66,239.19,636.48,239.78,636.92,240.59z",
  "M633.04,240.08c0.44,0.81,1.35,1.54,2.02,1.63c0.68,0.09,0.86-0.5,0.42-1.31c-0.45-0.81-1.35-1.54-2.02-1.63C632.79,238.68,632.6,239.27,633.04,240.08z",
  "M613.33,237.61c0.41,0.81,1.31,1.54,2,1.62c0.69,0.08,0.92-0.51,0.5-1.32c-0.42-0.81-1.31-1.54-2-1.62C613.14,236.21,612.92,236.8,613.33,237.61z",
  "M609.33,237.14c0.41,0.81,1.3,1.54,2,1.62c0.7,0.08,0.93-0.51,0.52-1.32c-0.41-0.81-1.31-1.54-2-1.62C609.15,235.73,608.92,236.32,609.33,237.14z",
  "M597.21,235.75c0.39,0.81,1.28,1.54,1.98,1.62c0.71,0.08,0.96-0.52,0.57-1.33c-0.39-0.81-1.28-1.54-1.98-1.62C597.08,234.34,596.82,234.93,597.21,235.75z",
  "M589.04,234.86c0.38,0.81,1.26,1.54,1.97,1.61c0.71,0.08,0.98-0.52,0.6-1.34c-0.38-0.82-1.26-1.54-1.97-1.61C588.94,233.44,588.67,234.04,589.04,234.86z",
  "M559.93,231.95c0.33,0.82,1.19,1.53,1.93,1.6c0.73,0.07,1.05-0.54,0.72-1.35c-0.34-0.82-1.2-1.53-1.93-1.6C559.92,230.53,559.6,231.14,559.93,231.95z",
  "M555.71,231.57c0.32,0.82,1.18,1.53,1.92,1.6c0.74,0.07,1.07-0.54,0.74-1.36c-0.33-0.82-1.19-1.53-1.92-1.6C555.71,230.15,555.38,230.75,555.71,231.57z",
  "M551.47,231.19c0.32,0.82,1.17,1.53,1.91,1.6c0.74,0.06,1.08-0.54,0.75-1.36c-0.32-0.82-1.18-1.53-1.91-1.6C551.49,229.77,551.15,230.38,551.47,231.19z",
  "M547.22,230.83c0.31,0.82,1.16,1.53,1.9,1.59c0.74,0.06,1.08-0.55,0.77-1.36c-0.31-0.82-1.17-1.53-1.9-1.59C547.25,229.4,546.91,230.01,547.22,230.83z",
  "M542.95,230.47c0.3,0.82,1.15,1.53,1.89,1.59c0.74,0.06,1.09-0.55,0.79-1.37c-0.31-0.82-1.16-1.53-1.9-1.59C543,229.04,542.65,229.65,542.95,230.47z",
  "M538.68,230.12c0.3,0.82,1.14,1.53,1.89,1.59c0.75,0.06,1.11-0.55,0.8-1.37c-0.3-0.82-1.15-1.53-1.89-1.59C538.74,228.69,538.38,229.3,538.68,230.12z",
  "M508.37,227.9c0.25,0.82,1.06,1.52,1.82,1.57c0.76,0.05,1.17-0.57,0.92-1.39c-0.25-0.82-1.07-1.52-1.82-1.57C508.54,226.46,508.12,227.08,508.37,227.9z",
  "M503.99,227.61c0.24,0.82,1.05,1.52,1.81,1.57c0.76,0.05,1.18-0.57,0.94-1.39c-0.25-0.82-1.06-1.52-1.81-1.57C504.17,226.17,503.75,226.8,503.99,227.61z",
  "M455.22,225.14c0.16,0.82,0.92,1.5,1.7,1.54c0.78,0.03,1.28-0.61,1.12-1.43c-0.16-0.82-0.92-1.51-1.7-1.54C455.56,223.69,455.06,224.32,455.22,225.14z",
  "M450.73,224.98c0.15,0.82,0.9,1.5,1.68,1.53c0.78,0.03,1.29-0.61,1.13-1.43c-0.15-0.82-0.91-1.5-1.68-1.53C451.09,223.52,450.58,224.16,450.73,224.98z",
  "M446.24,224.82c0.14,0.82,0.89,1.5,1.67,1.53c0.78,0.03,1.3-0.61,1.15-1.43c-0.15-0.82-0.9-1.5-1.67-1.53C446.62,223.36,446.1,224,446.24,224.82z",
  "M441.75,224.67c0.13,0.82,0.88,1.5,1.66,1.53c0.78,0.02,1.3-0.62,1.17-1.43c-0.14-0.82-0.88-1.5-1.66-1.53C442.14,223.21,441.61,223.85,441.75,224.67z",
  "M437.24,224.54c0.13,0.82,0.86,1.5,1.65,1.52c0.78,0.02,1.31-0.62,1.18-1.44c-0.13-0.82-0.87-1.5-1.65-1.52C437.65,223.07,437.12,223.72,437.24,224.54z",
  "M432.74,224.41c0.12,0.82,0.85,1.5,1.64,1.52c0.79,0.02,1.32-0.62,1.2-1.44c-0.12-0.82-0.86-1.5-1.64-1.52C433.15,222.95,432.62,223.59,432.74,224.41z",
  "M428.22,224.29c0.11,0.82,0.84,1.5,1.62,1.52c0.79,0.02,1.33-0.63,1.21-1.44c-0.12-0.82-0.84-1.5-1.62-1.52C428.66,222.83,428.11,223.48,428.22,224.29z",
  "M423.7,224.19c0.1,0.82,0.82,1.49,1.61,1.51c0.79,0.02,1.34-0.63,1.23-1.45c-0.11-0.82-0.83-1.5-1.61-1.51C424.15,222.72,423.6,223.37,423.7,224.19z",
  "M419.18,224.09c0.1,0.82,0.81,1.49,1.6,1.51c0.79,0.02,1.34-0.63,1.24-1.45c-0.1-0.82-0.82-1.49-1.6-1.51C419.64,222.63,419.09,223.28,419.18,224.09z",
  "M414.65,224.01c0.09,0.82,0.8,1.49,1.58,1.51c0.79,0.01,1.35-0.64,1.26-1.45c-0.09-0.82-0.8-1.49-1.59-1.51C415.13,222.54,414.57,223.19,414.65,224.01z",
  "M410.12,223.93c0.08,0.82,0.78,1.49,1.57,1.5c0.79,0.01,1.36-0.64,1.27-1.46s-0.79-1.49-1.57-1.5C410.61,222.46,410.04,223.12,410.12,223.93z",
  "M405.59,223.87c0.07,0.82,0.77,1.49,1.56,1.5c0.79,0.01,1.37-0.64,1.29-1.46c-0.08-0.82-0.78-1.49-1.56-1.5C406.09,222.4,405.51,223.05,405.59,223.87z",
  "M401.05,223.82c0.06,0.82,0.76,1.49,1.55,1.5c0.79,0.01,1.37-0.65,1.31-1.46c-0.07-0.82-0.76-1.49-1.55-1.5C401.57,222.34,400.98,223,401.05,223.82z",
  "M396.5,223.77c0.06,0.82,0.74,1.49,1.53,1.49c0.79,0.01,1.38-0.65,1.32-1.47c-0.06-0.82-0.75-1.49-1.53-1.49C397.04,222.3,396.45,222.96,396.5,223.77z",
  "M391.96,223.74c0.05,0.82,0.73,1.49,1.52,1.49c0.79,0.01,1.39-0.65,1.34-1.47c-0.05-0.82-0.73-1.49-1.52-1.49C392.51,222.26,391.91,222.92,391.96,223.74z",
  "M387.41,223.72c0.04,0.82,0.71,1.48,1.51,1.49c0.79,0,1.4-0.66,1.35-1.47c-0.05-0.82-0.72-1.48-1.51-1.49C387.98,222.24,387.37,222.9,387.41,223.72z",
  "M382.86,223.71c0.03,0.82,0.7,1.48,1.49,1.48c0.79,0,1.4-0.66,1.37-1.48c-0.04-0.82-0.71-1.48-1.49-1.48C383.44,222.23,382.83,222.89,382.86,223.71z",
  "M378.31,223.7c0.02,0.82,0.69,1.48,1.48,1.48c0.79,0,1.41-0.66,1.38-1.48c-0.03-0.82-0.69-1.48-1.48-1.48C378.91,222.22,378.29,222.89,378.31,223.7z",
  "M373.76,223.71c0.02,0.82,0.67,1.48,1.46,1.48c0.79,0,1.42-0.67,1.4-1.48c-0.02-0.82-0.68-1.48-1.46-1.48C374.37,222.23,373.74,222.9,373.76,223.71z",
  "M369.21,223.73c0.01,0.82,0.66,1.48,1.45,1.47c0.79,0,1.42-0.67,1.41-1.49c-0.01-0.82-0.66-1.48-1.45-1.47C369.83,222.25,369.2,222.92,369.21,223.73z",
  "M364.65,223.76c0,0.82,0.64,1.48,1.44,1.47c0.79-0.01,1.43-0.67,1.42-1.49c-0.01-0.82-0.65-1.48-1.44-1.47C365.29,222.28,364.65,222.95,364.65,223.76z",
  "M360.1,223.8c-0.01,0.82,0.63,1.47,1.42,1.47c0.79-0.01,1.44-0.68,1.44-1.49c0-0.82-0.63-1.47-1.42-1.47C360.75,222.32,360.11,222.99,360.1,223.8z",
  "M355.55,223.86c-0.02,0.82,0.61,1.47,1.41,1.46c0.79-0.01,1.44-0.68,1.45-1.5c0.01-0.82-0.62-1.47-1.41-1.46C356.21,222.37,355.56,223.04,355.55,223.86z",
  "M350.99,223.92c-0.02,0.82,0.6,1.47,1.39,1.46c0.79-0.01,1.45-0.68,1.47-1.5c0.02-0.82-0.61-1.47-1.39-1.46C351.67,222.43,351.02,223.1,350.99,223.92z",
  "M346.44,223.99c-0.03,0.82,0.59,1.47,1.38,1.46c0.79-0.01,1.46-0.69,1.48-1.5c0.03-0.82-0.59-1.47-1.38-1.46C347.13,222.5,346.47,223.17,346.44,223.99z",
  "M341.89,224.07c-0.04,0.82,0.57,1.47,1.36,1.45c0.79-0.01,1.46-0.69,1.49-1.51c0.03-0.82-0.58-1.47-1.36-1.45C342.6,222.58,341.93,223.25,341.89,224.07z",
  "M337.34,224.16c-0.05,0.82,0.56,1.47,1.35,1.45c0.79-0.02,1.47-0.69,1.51-1.51c0.04-0.82-0.56-1.47-1.35-1.45C338.06,222.67,337.38,223.35,337.34,224.16z",
  "M332.79,224.27c-0.06,0.82,0.54,1.47,1.33,1.45c0.79-0.02,1.47-0.7,1.52-1.51c0.05-0.82-0.55-1.47-1.33-1.45C333.53,222.77,332.84,223.45,332.79,224.27z",
  "M328.24,224.38c-0.06,0.82,0.53,1.46,1.32,1.44c0.79-0.02,1.48-0.7,1.54-1.52c0.06-0.82-0.53-1.46-1.32-1.44C328.99,222.88,328.3,223.56,328.24,224.38z",
  "M323.7,224.51c-0.07,0.82,0.51,1.46,1.3,1.44c0.79-0.02,1.48-0.7,1.55-1.52c0.06-0.82-0.52-1.46-1.3-1.44C324.46,223.01,323.77,223.69,323.7,224.51z",
  "M319.16,224.64c-0.08,0.82,0.5,1.46,1.29,1.44c0.79-0.02,1.49-0.71,1.56-1.52c0.07-0.82-0.5-1.46-1.29-1.44C319.94,223.14,319.24,223.82,319.16,224.64z",
  "M225.04,229.78c-0.24,0.82,0.19,1.43,0.96,1.37c0.77-0.06,1.57-0.77,1.81-1.59c0.23-0.82-0.2-1.43-0.96-1.37C226.09,228.26,225.28,228.97,225.04,229.78z",
  "M220.65,230.13c-0.24,0.82,0.18,1.43,0.94,1.37c0.76-0.06,1.58-0.77,1.82-1.59c0.24-0.82-0.18-1.43-0.94-1.37C221.7,228.6,220.89,229.32,220.65,230.13z",
  "M207.53,231.23c-0.27,0.82,0.13,1.42,0.89,1.36c0.76-0.07,1.58-0.78,1.84-1.6c0.26-0.82-0.14-1.42-0.89-1.36C208.62,229.7,207.8,230.42,207.53,231.23z",
  "M203.18,231.62c-0.27,0.82,0.12,1.42,0.88,1.36c0.76-0.07,1.59-0.78,1.85-1.6c0.27-0.82-0.12-1.42-0.88-1.36C204.29,230.09,203.46,230.8,203.18,231.62z",
  "M194.52,232.42c-0.29,0.82,0.09,1.42,0.84,1.35c0.75-0.07,1.59-0.79,1.87-1.6c0.28-0.82-0.1-1.42-0.84-1.35C195.65,230.88,194.81,231.6,194.52,232.42z",
  "M185.91,233.24c-0.3,0.82,0.06,1.42,0.81,1.34c0.75-0.07,1.59-0.79,1.89-1.61c0.3-0.82-0.07-1.42-0.81-1.34C187.06,231.71,186.21,232.43,185.91,233.24z",
  "M168.85,235c-0.33,0.81,0.01,1.41,0.74,1.33c0.74-0.08,1.6-0.8,1.92-1.62c0.32-0.81-0.01-1.41-0.74-1.33C170.04,233.46,169.18,234.18,168.85,235z",
  "M156.21,236.39c-0.35,0.81-0.04,1.41,0.69,1.32c0.73-0.08,1.6-0.81,1.94-1.62c0.34-0.81,0.03-1.41-0.69-1.33C157.43,234.85,156.56,235.58,156.21,236.39z",
  "M152.03,236.87c-0.35,0.81-0.05,1.41,0.68,1.32c0.73-0.08,1.6-0.81,1.95-1.62c0.35-0.81,0.05-1.41-0.68-1.32C153.26,235.33,152.38,236.06,152.03,236.87z",
  "M147.87,237.36c-0.36,0.81-0.06,1.4,0.66,1.32c0.72-0.08,1.6-0.81,1.95-1.63c0.35-0.81,0.06-1.4-0.66-1.32C149.1,235.82,148.23,236.55,147.87,237.36z",
  "M143.72,237.85c-0.37,0.81-0.08,1.4,0.64,1.32c0.72-0.09,1.6-0.81,1.96-1.63c0.36-0.81,0.07-1.4-0.64-1.32C144.96,236.31,144.08,237.04,143.72,237.85z",
  "M131.37,239.38c-0.38,0.81-0.12,1.4,0.6,1.31c0.71-0.09,1.6-0.82,1.98-1.63c0.38-0.81,0.11-1.4-0.59-1.31C132.64,237.83,131.75,238.56,131.37,239.38z",
  "M678.92,242.52c0.51,0.8,1.42,1.54,2.04,1.63c0.62,0.1,0.71-0.47,0.2-1.28c-0.51-0.8-1.42-1.54-2.04-1.63C678.5,241.14,678.41,241.71,678.92,242.52z",
  "M675.33,241.95c0.5,0.81,1.42,1.54,2.04,1.63c0.62,0.1,0.72-0.48,0.21-1.28c-0.51-0.8-1.42-1.54-2.04-1.63C674.92,240.57,674.83,241.15,675.33,241.95z",
  "M671.72,241.39c0.5,0.81,1.41,1.54,2.04,1.63c0.63,0.1,0.73-0.48,0.23-1.28c-0.5-0.8-1.41-1.54-2.04-1.63C671.33,240.01,671.22,240.59,671.72,241.39z",
  "M668.09,240.83c0.49,0.81,1.41,1.54,2.04,1.63c0.63,0.1,0.74-0.48,0.24-1.28c-0.5-0.81-1.41-1.54-2.04-1.63C667.71,239.45,667.6,240.03,668.09,240.83z",
  "M664.43,240.28c0.49,0.81,1.4,1.54,2.04,1.63c0.64,0.1,0.75-0.48,0.26-1.29c-0.49-0.81-1.41-1.54-2.04-1.63C664.06,238.9,663.95,239.47,664.43,240.28z",
  "M660.76,239.73c0.48,0.81,1.4,1.54,2.04,1.63c0.64,0.1,0.76-0.48,0.28-1.29c-0.49-0.81-1.4-1.54-2.04-1.63C660.39,238.35,660.27,238.92,660.76,239.73z",
  "M657.06,239.19c0.48,0.81,1.39,1.54,2.04,1.63c0.64,0.09,0.77-0.48,0.29-1.29c-0.48-0.81-1.4-1.54-2.04-1.63C656.71,237.8,656.57,238.38,657.06,239.19z",
  "M653.33,238.64c0.48,0.81,1.39,1.54,2.03,1.63c0.65,0.09,0.79-0.49,0.31-1.29c-0.48-0.81-1.39-1.54-2.03-1.63C652.99,237.26,652.86,237.84,653.33,238.64z",
  "M649.59,238.11c0.47,0.81,1.38,1.54,2.03,1.63c0.65,0.09,0.8-0.49,0.32-1.3c-0.47-0.81-1.38-1.54-2.03-1.63C649.26,236.72,649.12,237.3,649.59,238.11z",
  "M634.39,236.01c0.45,0.81,1.36,1.54,2.02,1.63c0.67,0.09,0.84-0.49,0.39-1.31c-0.45-0.81-1.36-1.54-2.02-1.63C634.11,234.61,633.94,235.2,634.39,236.01z",
  "M607.01,232.54c0.41,0.81,1.3,1.54,1.99,1.62c0.69,0.08,0.92-0.51,0.5-1.32c-0.42-0.81-1.31-1.54-2-1.62C606.83,231.14,606.6,231.73,607.01,232.54z",
  "M570.51,228.54c0.36,0.82,1.22,1.53,1.94,1.61c0.72,0.07,1.01-0.53,0.65-1.35c-0.36-0.81-1.23-1.53-1.94-1.61C570.44,227.13,570.15,227.73,570.51,228.54z",
  "M566.37,228.14c0.35,0.81,1.21,1.53,1.94,1.6c0.72,0.07,1.02-0.53,0.67-1.35c-0.35-0.81-1.22-1.53-1.94-1.6C566.32,226.72,566.02,227.32,566.37,228.14z",
  "M558.04,227.34c0.33,0.82,1.2,1.53,1.92,1.6c0.73,0.07,1.04-0.54,0.7-1.35c-0.34-0.82-1.2-1.53-1.92-1.6C558.02,225.92,557.71,226.53,558.04,227.34z",
  "M553.86,226.96c0.33,0.82,1.18,1.53,1.91,1.6c0.73,0.07,1.05-0.54,0.72-1.36c-0.33-0.82-1.19-1.53-1.91-1.6C553.85,225.54,553.53,226.14,553.86,226.96z",
  "M549.66,226.58c0.32,0.82,1.17,1.53,1.91,1.59c0.73,0.06,1.06-0.54,0.73-1.36c-0.33-0.82-1.18-1.53-1.91-1.6C549.67,225.16,549.34,225.77,549.66,226.58z",
  "M545.45,226.21c0.31,0.82,1.16,1.53,1.9,1.59c0.73,0.06,1.07-0.55,0.75-1.36c-0.32-0.82-1.17-1.53-1.9-1.59C545.47,224.79,545.14,225.4,545.45,226.21z",
  "M541.22,225.85c0.31,0.82,1.15,1.53,1.89,1.59c0.74,0.06,1.08-0.55,0.77-1.36c-0.31-0.82-1.16-1.53-1.89-1.59C541.26,224.43,540.92,225.04,541.22,225.85z",
  "M536.99,225.5c0.3,0.82,1.14,1.53,1.88,1.59c0.74,0.06,1.09-0.55,0.78-1.37c-0.31-0.82-1.15-1.53-1.88-1.59C537.04,224.07,536.69,224.69,536.99,225.5z",
  "M515.6,223.87c0.27,0.82,1.09,1.52,1.84,1.57c0.75,0.05,1.14-0.57,0.87-1.38c-0.27-0.82-1.09-1.52-1.84-1.58C515.72,222.43,515.34,223.05,515.6,223.87z",
  "M511.29,223.57c0.26,0.82,1.08,1.52,1.83,1.57c0.75,0.05,1.15-0.57,0.88-1.38c-0.26-0.82-1.08-1.52-1.83-1.57C511.42,222.13,511.03,222.75,511.29,223.57z",
  "M506.96,223.28c0.25,0.82,1.06,1.52,1.82,1.57c0.75,0.05,1.16-0.57,0.9-1.39c-0.26-0.82-1.07-1.52-1.82-1.57C507.12,221.84,506.71,222.46,506.96,223.28z",
  "M502.63,223c0.24,0.82,1.05,1.52,1.81,1.57c0.76,0.05,1.17-0.57,0.92-1.39c-0.25-0.82-1.06-1.52-1.81-1.57C502.8,221.56,502.39,222.18,502.63,223z",
  "M498.28,222.72c0.24,0.82,1.04,1.52,1.8,1.56c0.76,0.05,1.18-0.58,0.93-1.39c-0.24-0.82-1.05-1.52-1.8-1.56C498.46,221.28,498.05,221.91,498.28,222.72z",
  "M463.18,220.88c0.17,0.82,0.94,1.51,1.71,1.54c0.77,0.03,1.25-0.6,1.07-1.42c-0.18-0.82-0.95-1.51-1.71-1.54C463.48,219.43,463,220.07,463.18,220.88z",
  "M458.75,220.7c0.17,0.82,0.93,1.5,1.7,1.54c0.77,0.03,1.26-0.6,1.08-1.42c-0.17-0.82-0.93-1.51-1.7-1.54C459.07,219.24,458.59,219.88,458.75,220.7z",
  "M454.32,220.52c0.16,0.82,0.91,1.5,1.69,1.53c0.77,0.03,1.26-0.61,1.1-1.42c-0.17-0.82-0.92-1.5-1.69-1.53C454.65,219.07,454.16,219.7,454.32,220.52z",
  "M449.88,220.35c0.15,0.82,0.9,1.5,1.67,1.53c0.77,0.03,1.27-0.61,1.12-1.43c-0.16-0.82-0.91-1.5-1.67-1.53C450.23,218.9,449.73,219.54,449.88,220.35z",
  "M445.44,220.2c0.14,0.82,0.89,1.5,1.66,1.53c0.77,0.03,1.28-0.61,1.13-1.43c-0.15-0.82-0.89-1.5-1.66-1.53C445.8,218.74,445.29,219.38,445.44,220.2z",
  "M440.98,220.05c0.14,0.82,0.87,1.5,1.65,1.52c0.78,0.03,1.29-0.62,1.15-1.43c-0.14-0.82-0.88-1.5-1.65-1.52C441.36,218.59,440.85,219.24,440.98,220.05z",
  "M436.52,219.92c0.13,0.82,0.86,1.5,1.64,1.52c0.78,0.02,1.3-0.62,1.16-1.44c-0.13-0.82-0.87-1.5-1.64-1.52C436.92,218.46,436.4,219.1,436.52,219.92z",
  "M432.06,219.79c0.12,0.82,0.85,1.5,1.62,1.52c0.78,0.02,1.31-0.62,1.18-1.44c-0.13-0.82-0.85-1.5-1.62-1.52C432.47,218.33,431.94,218.97,432.06,219.79z",
  "M427.59,219.67c0.11,0.82,0.83,1.5,1.61,1.51c0.78,0.02,1.31-0.63,1.2-1.44c-0.12-0.82-0.84-1.5-1.61-1.52C428.01,218.21,427.48,218.86,427.59,219.67z",
  "M423.12,219.57c0.1,0.82,0.82,1.49,1.6,1.51c0.78,0.02,1.32-0.63,1.21-1.45c-0.11-0.82-0.83-1.49-1.6-1.51C423.56,218.1,423.01,218.75,423.12,219.57z",
  "M418.64,219.47c0.1,0.82,0.81,1.49,1.59,1.51c0.78,0.02,1.33-0.63,1.23-1.45c-0.1-0.82-0.81-1.49-1.59-1.51C419.09,218.01,418.54,218.66,418.64,219.47z",
  "M414.15,219.39c0.09,0.82,0.79,1.49,1.57,1.5c0.78,0.01,1.34-0.64,1.24-1.45c-0.09-0.82-0.8-1.49-1.57-1.51C414.62,217.92,414.07,218.57,414.15,219.39z",
  "M409.67,219.31c0.08,0.82,0.78,1.49,1.56,1.5c0.78,0.01,1.35-0.64,1.26-1.46c-0.09-0.82-0.78-1.49-1.56-1.5C410.15,217.84,409.59,218.5,409.67,219.31z",
  "M405.18,219.25c0.07,0.82,0.76,1.49,1.55,1.5c0.78,0.01,1.35-0.64,1.27-1.46c-0.08-0.82-0.77-1.49-1.55-1.5C405.67,217.78,405.1,218.43,405.18,219.25z",
  "M400.68,219.2c0.06,0.82,0.75,1.49,1.53,1.5c0.78,0.01,1.36-0.65,1.29-1.46c-0.07-0.82-0.76-1.49-1.53-1.5C401.2,217.72,400.62,218.38,400.68,219.2z",
  "M396.19,219.15c0.06,0.82,0.74,1.48,1.52,1.49c0.78,0.01,1.37-0.65,1.31-1.47c-0.06-0.82-0.74-1.49-1.52-1.49C396.71,217.68,396.13,218.34,396.19,219.15z",
  "M391.69,219.12c0.05,0.82,0.72,1.48,1.51,1.49c0.78,0.01,1.38-0.65,1.32-1.47c-0.05-0.82-0.73-1.48-1.51-1.49C392.23,217.64,391.64,218.3,391.69,219.12z",
  "M387.18,219.1c0.04,0.82,0.71,1.48,1.49,1.49c0.78,0,1.38-0.66,1.34-1.47c-0.05-0.82-0.71-1.48-1.49-1.49C387.74,217.62,387.14,218.28,387.18,219.1z",
  "M382.68,219.09c0.03,0.82,0.69,1.48,1.48,1.48c0.78,0,1.39-0.66,1.35-1.48c-0.04-0.82-0.7-1.48-1.48-1.48C383.25,217.61,382.65,218.27,382.68,219.09z",
  "M378.17,219.08c0.02,0.82,0.68,1.48,1.46,1.48c0.78,0,1.4-0.66,1.37-1.48c-0.03-0.82-0.69-1.48-1.46-1.48C378.76,217.6,378.15,218.27,378.17,219.08z",
  "M373.67,219.09c0.02,0.82,0.67,1.48,1.45,1.48c0.78,0,1.4-0.66,1.38-1.48c-0.02-0.82-0.67-1.48-1.45-1.48C374.27,217.61,373.65,218.28,373.67,219.09z",
  "M369.16,219.11c0.01,0.82,0.65,1.48,1.44,1.47c0.79,0,1.41-0.67,1.4-1.49c-0.01-0.82-0.66-1.48-1.44-1.47C369.78,217.63,369.15,218.3,369.16,219.11z",
  "M364.65,219.14c0,0.82,0.64,1.47,1.42,1.47c0.78-0.01,1.42-0.67,1.41-1.49c-0.01-0.82-0.64-1.47-1.42-1.47C365.28,217.66,364.65,218.33,364.65,219.14z",
  "M360.14,219.18c-0.01,0.82,0.62,1.47,1.41,1.47c0.78-0.01,1.42-0.68,1.42-1.49c0-0.82-0.63-1.47-1.41-1.47C360.79,217.7,360.15,218.37,360.14,219.18z",
  "M355.63,219.23c-0.02,0.82,0.61,1.47,1.39,1.46c0.78-0.01,1.43-0.68,1.44-1.5s-0.61-1.47-1.39-1.46C356.29,217.75,355.65,218.42,355.63,219.23z",
  "M351.12,219.3c-0.02,0.82,0.59,1.47,1.38,1.46c0.79-0.01,1.44-0.68,1.45-1.5c0.02-0.82-0.6-1.47-1.38-1.46C351.8,217.81,351.15,218.48,351.12,219.3z",
  "M346.62,219.37c-0.03,0.82,0.58,1.47,1.36,1.46c0.78-0.01,1.44-0.69,1.47-1.5c0.03-0.82-0.58-1.47-1.36-1.46C347.31,217.88,346.65,218.55,346.62,219.37z",
  "M342.11,219.45c-0.04,0.82,0.56,1.47,1.35,1.45c0.78-0.01,1.45-0.69,1.48-1.51c0.03-0.82-0.57-1.47-1.35-1.45C342.81,217.96,342.15,218.63,342.11,219.45z",
  "M337.6,219.54c-0.05,0.82,0.55,1.47,1.33,1.45c0.78-0.02,1.45-0.69,1.5-1.51c0.04-0.82-0.55-1.47-1.33-1.45C338.32,218.05,337.65,218.73,337.6,219.54z",
  "M333.1,219.65c-0.06,0.82,0.53,1.46,1.32,1.45c0.78-0.02,1.46-0.7,1.51-1.51c0.05-0.82-0.54-1.46-1.32-1.45C333.83,218.15,333.16,218.83,333.1,219.65z",
  "M328.6,219.76c-0.06,0.82,0.52,1.46,1.3,1.44c0.78-0.02,1.47-0.7,1.52-1.51c0.06-0.82-0.52-1.46-1.3-1.44C329.34,218.27,328.66,218.94,328.6,219.76z",
  "M324.1,219.89c-0.07,0.82,0.51,1.46,1.29,1.44c0.78-0.02,1.47-0.7,1.54-1.52c0.07-0.82-0.51-1.46-1.29-1.44C324.86,218.39,324.17,219.07,324.1,219.89z",
  "M319.6,220.02c-0.08,0.82,0.49,1.46,1.27,1.44c0.78-0.02,1.48-0.7,1.55-1.52c0.07-0.82-0.5-1.46-1.27-1.44C320.38,218.52,319.68,219.2,319.6,220.02z",
  "M274.86,221.93c-0.16,0.82,0.34,1.44,1.12,1.4c0.77-0.04,1.53-0.74,1.68-1.55c0.15-0.82-0.35-1.45-1.12-1.4C275.77,220.42,275.02,221.11,274.86,221.93z",
  "M270.41,222.18c-0.17,0.82,0.33,1.44,1.1,1.4c0.77-0.04,1.53-0.74,1.69-1.56c0.16-0.82-0.33-1.44-1.1-1.4C271.34,220.66,270.58,221.36,270.41,222.18z",
  "M196.15,227.81c-0.29,0.82,0.08,1.42,0.82,1.35c0.75-0.07,1.58-0.79,1.87-1.6c0.29-0.81-0.08-1.42-0.82-1.35C197.28,226.27,196.44,226.99,196.15,227.81z",
  "M191.88,228.22c-0.3,0.81,0.06,1.42,0.81,1.35c0.74-0.07,1.58-0.79,1.87-1.61c0.29-0.82-0.07-1.42-0.81-1.35C193.02,226.68,192.18,227.4,191.88,228.22z",
  "M187.62,228.64c-0.3,0.82,0.05,1.42,0.79,1.34c0.74-0.07,1.58-0.79,1.88-1.61c0.3-0.81-0.05-1.42-0.79-1.34C188.77,227.1,187.92,227.82,187.62,228.64z",
  "M158.18,231.79c-0.35,0.81-0.05,1.41,0.68,1.32c0.72-0.08,1.59-0.81,1.94-1.62c0.35-0.81,0.04-1.41-0.67-1.32C159.4,230.25,158.54,230.98,158.18,231.79z",
  "M154.04,232.27c-0.36,0.81-0.06,1.41,0.66,1.32c0.72-0.08,1.59-0.81,1.94-1.62c0.35-0.81,0.06-1.41-0.66-1.32C155.27,230.73,154.4,231.46,154.04,232.27z",
  "M149.91,232.76c-0.36,0.81-0.08,1.4,0.64,1.32c0.72-0.09,1.59-0.81,1.95-1.63c0.36-0.81,0.07-1.4-0.64-1.32C151.15,231.22,150.27,231.95,149.91,232.76z",
  "M145.79,233.26c-0.37,0.81-0.09,1.4,0.63,1.32c0.72-0.09,1.59-0.81,1.96-1.63c0.37-0.81,0.09-1.4-0.62-1.32C147.04,231.71,146.16,232.44,145.79,233.26z",
  "M141.7,233.76c-0.38,0.81-0.1,1.4,0.61,1.31c0.71-0.09,1.59-0.82,1.96-1.63c0.37-0.81,0.1-1.4-0.61-1.31C142.95,232.22,142.07,232.95,141.7,233.76z",
  "M137.61,234.27c-0.38,0.81-0.12,1.4,0.59,1.31c0.71-0.09,1.59-0.82,1.97-1.63c0.38-0.81,0.11-1.4-0.59-1.31C138.88,232.72,138,233.46,137.61,234.27z",
  "M676.04,237.97c0.51,0.8,1.42,1.54,2.04,1.64c0.62,0.1,0.7-0.47,0.19-1.28c-0.51-0.8-1.42-1.54-2.04-1.64C675.62,236.59,675.53,237.16,676.04,237.97z",
  "M668.9,236.84c0.5,0.81,1.41,1.54,2.04,1.64c0.62,0.1,0.72-0.48,0.22-1.28c-0.5-0.81-1.41-1.54-2.04-1.64C668.5,235.46,668.4,236.03,668.9,236.84z",
  "M665.29,236.28c0.5,0.81,1.41,1.54,2.04,1.64c0.63,0.1,0.73-0.48,0.23-1.28c-0.5-0.81-1.41-1.54-2.03-1.64C664.9,234.89,664.79,235.47,665.29,236.28z",
  "M661.66,235.72c0.49,0.81,1.4,1.54,2.04,1.64c0.63,0.1,0.74-0.48,0.25-1.29c-0.49-0.81-1.4-1.54-2.03-1.64C661.28,234.34,661.17,234.91,661.66,235.72z",
  "M658.01,235.17c0.49,0.81,1.4,1.54,2.03,1.63c0.64,0.1,0.75-0.48,0.26-1.29c-0.49-0.81-1.4-1.54-2.03-1.64C657.64,233.78,657.52,234.36,658.01,235.17z",
  "M654.33,234.62c0.48,0.81,1.39,1.54,2.03,1.63c0.64,0.09,0.76-0.48,0.28-1.29c-0.49-0.81-1.4-1.54-2.03-1.63C653.98,233.23,653.85,233.81,654.33,234.62z",
  "M650.64,234.07c0.48,0.81,1.39,1.54,2.03,1.63c0.64,0.09,0.77-0.48,0.29-1.29c-0.48-0.81-1.39-1.54-2.03-1.63C650.29,232.69,650.16,233.26,650.64,234.07z",
  "M646.92,233.53c0.47,0.81,1.38,1.54,2.03,1.63c0.65,0.09,0.79-0.49,0.31-1.29c-0.48-0.81-1.39-1.54-2.03-1.63C646.58,232.14,646.44,232.72,646.92,233.53z",
  "M643.18,233c0.47,0.81,1.38,1.54,2.03,1.63c0.65,0.09,0.8-0.49,0.32-1.3c-0.47-0.81-1.38-1.54-2.03-1.63C642.86,231.61,642.71,232.19,643.18,233z",
  "M639.42,232.47c0.46,0.81,1.37,1.54,2.02,1.63c0.65,0.09,0.81-0.49,0.34-1.3c-0.47-0.81-1.37-1.54-2.02-1.63C639.11,231.08,638.95,231.66,639.42,232.47z",
  "M635.64,231.94c0.46,0.81,1.36,1.54,2.02,1.63c0.66,0.09,0.82-0.49,0.36-1.3c-0.46-0.81-1.37-1.54-2.02-1.63C635.34,230.55,635.18,231.13,635.64,231.94z",
  "M628.01,230.91c0.45,0.81,1.35,1.54,2.02,1.63c0.67,0.09,0.84-0.5,0.39-1.31c-0.45-0.81-1.36-1.54-2.02-1.63C627.74,229.51,627.57,230.1,628.01,230.91z",
  "M596.76,227.01c0.4,0.81,1.29,1.54,1.98,1.62c0.69,0.08,0.92-0.51,0.52-1.33c-0.41-0.81-1.29-1.54-1.98-1.62C596.58,225.6,596.35,226.19,596.76,227.01z",
  "M592.77,226.55c0.4,0.81,1.28,1.54,1.98,1.62c0.69,0.08,0.93-0.52,0.53-1.33c-0.4-0.81-1.29-1.54-1.98-1.62C592.61,225.14,592.37,225.73,592.77,226.55z",
  "M588.76,226.09c0.39,0.81,1.27,1.54,1.97,1.61c0.7,0.08,0.94-0.52,0.55-1.33c-0.39-0.81-1.28-1.54-1.97-1.62C588.62,224.68,588.37,225.28,588.76,226.09z",
  "M584.74,225.65c0.38,0.81,1.26,1.54,1.96,1.61c0.7,0.08,0.95-0.52,0.56-1.33c-0.39-0.81-1.27-1.54-1.96-1.61C584.61,224.24,584.35,224.83,584.74,225.65z",
  "M572.57,224.35c0.36,0.81,1.24,1.53,1.94,1.61c0.71,0.07,0.98-0.53,0.61-1.34c-0.37-0.81-1.24-1.53-1.95-1.61C572.48,222.94,572.21,223.54,572.57,224.35z",
  "M568.49,223.94c0.36,0.81,1.23,1.53,1.94,1.6c0.71,0.07,0.99-0.53,0.63-1.34c-0.36-0.81-1.23-1.53-1.94-1.61C568.41,222.52,568.13,223.12,568.49,223.94z",
  "M564.39,223.53c0.35,0.81,1.22,1.53,1.93,1.6c0.71,0.07,1-0.53,0.65-1.35c-0.36-0.81-1.22-1.53-1.93-1.6C564.32,222.11,564.03,222.71,564.39,223.53z",
  "M560.27,223.13c0.35,0.81,1.21,1.53,1.92,1.6c0.72,0.07,1.01-0.54,0.66-1.35c-0.35-0.81-1.21-1.53-1.92-1.6C560.22,221.71,559.92,222.31,560.27,223.13z",
  "M556.14,222.73c0.34,0.82,1.2,1.53,1.92,1.6c0.72,0.07,1.03-0.54,0.68-1.35c-0.34-0.81-1.2-1.53-1.92-1.6C556.1,221.31,555.8,221.92,556.14,222.73z",
  "M551.99,222.35c0.33,0.81,1.19,1.53,1.91,1.6c0.72,0.07,1.03-0.54,0.7-1.35c-0.34-0.81-1.19-1.53-1.91-1.6C551.97,220.93,551.66,221.53,551.99,222.35z",
  "M547.83,221.97c0.32,0.82,1.18,1.53,1.9,1.59c0.72,0.07,1.04-0.54,0.71-1.36c-0.33-0.81-1.18-1.53-1.9-1.59C547.83,220.55,547.51,221.16,547.83,221.97z",
  "M543.66,221.6c0.32,0.82,1.17,1.53,1.89,1.59c0.73,0.06,1.05-0.55,0.73-1.36c-0.32-0.82-1.17-1.53-1.89-1.59C543.67,220.18,543.34,220.79,543.66,221.6z",
  "M539.47,221.24c0.31,0.82,1.15,1.53,1.88,1.59c0.73,0.06,1.06-0.55,0.75-1.36c-0.32-0.82-1.16-1.53-1.88-1.59C539.5,219.82,539.16,220.43,539.47,221.24z",
  "M535.27,220.89c0.3,0.82,1.14,1.53,1.87,1.59c0.73,0.06,1.07-0.55,0.76-1.37c-0.31-0.82-1.15-1.53-1.88-1.59C535.31,219.46,534.97,220.07,535.27,220.89z",
  "M531.06,220.55c0.3,0.82,1.13,1.52,1.87,1.58c0.73,0.06,1.08-0.55,0.78-1.37c-0.3-0.82-1.14-1.52-1.87-1.58C531.11,219.12,530.76,219.73,531.06,220.55z",
  "M526.84,220.21c0.29,0.82,1.12,1.52,1.86,1.58c0.74,0.06,1.09-0.56,0.8-1.37c-0.29-0.82-1.13-1.52-1.86-1.58C526.9,218.78,526.55,219.39,526.84,220.21z",
  "M518.35,219.56c0.28,0.82,1.1,1.52,1.84,1.58c0.74,0.05,1.11-0.56,0.83-1.38c-0.28-0.82-1.1-1.52-1.84-1.58C518.45,218.13,518.07,218.75,518.35,219.56z",
  "M514.09,219.26c0.27,0.82,1.09,1.52,1.83,1.57c0.74,0.05,1.12-0.56,0.85-1.38c-0.27-0.82-1.09-1.52-1.83-1.57C514.2,217.82,513.82,218.44,514.09,219.26z",
  "M509.82,218.96c0.26,0.82,1.08,1.52,1.82,1.57c0.74,0.05,1.13-0.57,0.87-1.38c-0.27-0.82-1.08-1.52-1.82-1.57C509.94,217.52,509.56,218.14,509.82,218.96z",
  "M505.54,218.66c0.25,0.82,1.06,1.52,1.81,1.57c0.75,0.05,1.14-0.57,0.88-1.39c-0.26-0.82-1.07-1.52-1.81-1.57C505.68,217.23,505.28,217.85,505.54,218.66z",
  "M501.24,218.38c0.25,0.82,1.05,1.52,1.8,1.56c0.75,0.05,1.15-0.57,0.9-1.39c-0.25-0.82-1.06-1.52-1.8-1.56C501.4,216.94,501,217.57,501.24,218.38z",
  "M496.94,218.11c0.24,0.82,1.04,1.52,1.79,1.56c0.75,0.05,1.16-0.58,0.92-1.39c-0.24-0.82-1.05-1.51-1.79-1.56C497.11,216.67,496.7,217.29,496.94,218.11z",
  "M492.63,217.85c0.23,0.82,1.03,1.51,1.78,1.56c0.75,0.05,1.17-0.58,0.93-1.39c-0.24-0.82-1.03-1.51-1.78-1.56C492.81,216.41,492.4,217.03,492.63,217.85z",
  "M466.56,216.47c0.18,0.82,0.95,1.51,1.71,1.54c0.76,0.04,1.22-0.6,1.03-1.41c-0.19-0.82-0.96-1.51-1.71-1.54C466.83,215.02,466.37,215.65,466.56,216.47z",
  "M462.18,216.27c0.18,0.82,0.94,1.5,1.7,1.54c0.76,0.03,1.23-0.6,1.05-1.42c-0.18-0.82-0.94-1.5-1.7-1.54C462.47,214.82,462.01,215.45,462.18,216.27z",
  "M457.8,216.08c0.17,0.82,0.92,1.5,1.69,1.53c0.76,0.03,1.24-0.6,1.07-1.42c-0.18-0.82-0.93-1.5-1.69-1.53C458.11,214.63,457.63,215.27,457.8,216.08z",
  "M453.41,215.91c0.16,0.82,0.91,1.5,1.68,1.53c0.76,0.03,1.25-0.61,1.08-1.42c-0.17-0.82-0.92-1.5-1.68-1.53C453.74,214.45,453.25,215.09,453.41,215.91z",
  "M449.02,215.74c0.15,0.82,0.9,1.5,1.66,1.53c0.77,0.03,1.26-0.61,1.1-1.43c-0.16-0.82-0.9-1.5-1.66-1.53C449.36,214.29,448.86,214.92,449.02,215.74z",
  "M444.62,215.58c0.15,0.82,0.88,1.5,1.65,1.53c0.77,0.03,1.27-0.61,1.11-1.43c-0.15-0.82-0.89-1.5-1.65-1.53C444.97,214.13,444.47,214.77,444.62,215.58z",
  "M440.21,215.44c0.14,0.82,0.87,1.5,1.64,1.52c0.77,0.02,1.27-0.62,1.13-1.43c-0.14-0.82-0.88-1.5-1.64-1.52C440.58,213.98,440.07,214.62,440.21,215.44z",
  "M435.79,215.3c0.13,0.82,0.86,1.5,1.63,1.52c0.77,0.02,1.28-0.62,1.15-1.43c-0.14-0.82-0.86-1.5-1.63-1.52C436.18,213.84,435.66,214.49,435.79,215.3z",
  "M431.38,215.18c0.12,0.82,0.84,1.49,1.61,1.52c0.77,0.02,1.29-0.62,1.16-1.44c-0.13-0.82-0.85-1.49-1.61-1.52C431.77,213.72,431.25,214.36,431.38,215.18z",
  "M426.95,215.06c0.11,0.82,0.83,1.49,1.6,1.51c0.77,0.02,1.3-0.63,1.18-1.44c-0.12-0.82-0.84-1.49-1.6-1.51C427.37,213.6,426.84,214.24,426.95,215.06z",
  "M422.52,214.95c0.1,0.82,0.82,1.49,1.59,1.51c0.77,0.02,1.31-0.63,1.19-1.44c-0.11-0.82-0.82-1.49-1.59-1.51C422.95,213.49,422.42,214.14,422.52,214.95z",
  "M418.09,214.86c0.1,0.82,0.8,1.49,1.57,1.51c0.77,0.02,1.31-0.63,1.21-1.45c-0.1-0.82-0.81-1.49-1.57-1.51C418.53,213.4,417.99,214.04,418.09,214.86z",
  "M413.65,214.77c0.09,0.82,0.79,1.49,1.56,1.5c0.77,0.01,1.32-0.63,1.23-1.45c-0.1-0.82-0.79-1.49-1.56-1.5C414.11,213.31,413.56,213.96,413.65,214.77z",
  "M409.21,214.7c0.08,0.82,0.77,1.49,1.55,1.5c0.77,0.01,1.33-0.64,1.24-1.45c-0.09-0.82-0.78-1.49-1.55-1.5C409.68,213.23,409.13,213.88,409.21,214.7z",
  "M404.76,214.64c0.07,0.82,0.76,1.49,1.53,1.5c0.77,0.01,1.34-0.64,1.26-1.46c-0.08-0.82-0.77-1.49-1.53-1.5C405.25,213.17,404.69,213.82,404.76,214.64z",
  "M400.31,214.58c0.07,0.82,0.75,1.48,1.52,1.49c0.77,0.01,1.35-0.64,1.27-1.46c-0.07-0.82-0.75-1.48-1.52-1.49C400.82,213.11,400.25,213.77,400.31,214.58z",
  "M395.86,214.54c0.06,0.82,0.73,1.48,1.51,1.49c0.78,0.01,1.35-0.65,1.29-1.46c-0.06-0.82-0.74-1.48-1.51-1.49C396.38,213.07,395.81,213.72,395.86,214.54z",
  "M391.41,214.51c0.05,0.82,0.72,1.48,1.49,1.49c0.78,0.01,1.36-0.65,1.31-1.47c-0.05-0.82-0.72-1.48-1.49-1.49C391.94,213.03,391.36,213.69,391.41,214.51z",
  "M386.95,214.48c0.04,0.82,0.7,1.48,1.48,1.48c0.78,0,1.37-0.65,1.32-1.47c-0.05-0.82-0.71-1.48-1.48-1.48C387.5,213.01,386.91,213.67,386.95,214.48z",
  "M382.49,214.47c0.03,0.82,0.69,1.48,1.46,1.48c0.78,0,1.37-0.66,1.34-1.47c-0.04-0.82-0.69-1.48-1.46-1.48C383.06,213,382.46,213.66,382.49,214.47z",
  "M378.03,214.47c0.02,0.82,0.67,1.48,1.45,1.48c0.78,0,1.38-0.66,1.35-1.48c-0.03-0.82-0.68-1.48-1.45-1.48C378.62,212.99,378.01,213.65,378.03,214.47z",
  "M373.57,214.48c0.02,0.82,0.66,1.48,1.44,1.47c0.78,0,1.39-0.66,1.37-1.48c-0.02-0.82-0.66-1.47-1.44-1.47C374.17,213,373.56,213.66,373.57,214.48z",
  "M369.11,214.5c0.01,0.82,0.64,1.47,1.42,1.47c0.78,0,1.4-0.67,1.38-1.48c-0.01-0.82-0.65-1.47-1.42-1.47C369.72,213.02,369.1,213.68,369.11,214.5z",
  "M364.65,214.53c0,0.82,0.63,1.47,1.41,1.47c0.78-0.01,1.4-0.67,1.4-1.49c-0.01-0.82-0.64-1.47-1.41-1.47C365.27,213.05,364.65,213.71,364.65,214.53z",
  "M360.18,214.57c-0.01,0.82,0.62,1.47,1.39,1.46c0.78-0.01,1.41-0.67,1.41-1.49c0-0.82-0.62-1.47-1.39-1.46C360.83,213.09,360.19,213.75,360.18,214.57z",
  "M355.72,214.62c-0.02,0.82,0.6,1.47,1.38,1.46c0.78-0.01,1.42-0.68,1.43-1.49c0.01-0.82-0.61-1.47-1.38-1.46C356.38,213.14,355.74,213.8,355.72,214.62z",
  "M351.26,214.68c-0.02,0.82,0.59,1.47,1.36,1.46c0.78-0.01,1.42-0.68,1.44-1.5c0.02-0.82-0.59-1.47-1.36-1.46C351.93,213.2,351.28,213.87,351.26,214.68z",
  "M346.8,214.75c-0.03,0.82,0.57,1.47,1.35,1.45c0.78-0.01,1.43-0.68,1.45-1.5c0.03-0.82-0.58-1.47-1.35-1.45C347.48,213.27,346.83,213.94,346.8,214.75z",
  "M342.34,214.84c-0.04,0.82,0.56,1.46,1.33,1.45c0.78-0.01,1.43-0.69,1.47-1.5c0.03-0.82-0.56-1.46-1.33-1.45C343.03,213.35,342.38,214.02,342.34,214.84z",
  "M337.88,214.93c-0.05,0.82,0.54,1.46,1.32,1.45c0.78-0.02,1.44-0.69,1.48-1.51c0.04-0.82-0.55-1.46-1.32-1.45C338.59,213.44,337.92,214.11,337.88,214.93z",
  "M333.42,215.03c-0.06,0.82,0.53,1.46,1.3,1.44c0.78-0.02,1.45-0.69,1.5-1.51c0.05-0.82-0.53-1.46-1.3-1.44C334.15,213.54,333.47,214.22,333.42,215.03z",
  "M328.96,215.15c-0.07,0.82,0.51,1.46,1.29,1.44c0.78-0.02,1.45-0.7,1.51-1.51c0.06-0.82-0.52-1.46-1.29-1.44C329.7,213.65,329.03,214.33,328.96,215.15z",
  "M324.51,215.27c-0.07,0.82,0.5,1.46,1.27,1.44c0.78-0.02,1.46-0.7,1.53-1.52c0.07-0.82-0.5-1.46-1.27-1.44C325.26,213.78,324.58,214.46,324.51,215.27z",
  "M320.06,215.41c-0.08,0.82,0.48,1.46,1.26,1.43c0.77-0.02,1.46-0.7,1.54-1.52c0.08-0.82-0.49-1.46-1.26-1.43C320.83,213.91,320.14,214.59,320.06,215.41z",
  "M236.41,219.88c-0.23,0.82,0.2,1.43,0.95,1.37c0.76-0.06,1.55-0.76,1.77-1.58c0.22-0.82-0.2-1.43-0.95-1.37C237.43,218.36,236.64,219.07,236.41,219.88z",
  "M232.08,220.22c-0.24,0.82,0.18,1.43,0.94,1.37c0.75-0.06,1.55-0.77,1.78-1.58c0.23-0.82-0.19-1.43-0.93-1.37C233.12,218.69,232.32,219.4,232.08,220.22z",
  "M227.76,220.56c-0.24,0.82,0.17,1.43,0.92,1.37c0.75-0.06,1.55-0.77,1.79-1.58c0.24-0.82-0.17-1.43-0.92-1.37C228.81,219.03,228,219.74,227.76,220.56z",
  "M223.45,220.91c-0.25,0.81,0.15,1.43,0.9,1.36c0.75-0.06,1.56-0.77,1.8-1.59c0.25-0.82-0.16-1.43-0.9-1.37C224.51,219.38,223.7,220.09,223.45,220.91z",
  "M197.8,223.2c-0.3,0.81,0.07,1.42,0.8,1.35c0.74-0.07,1.57-0.79,1.86-1.6c0.29-0.81-0.07-1.42-0.8-1.35C198.93,221.67,198.1,222.38,197.8,223.2z",
  "M193.57,223.61c-0.3,0.81,0.05,1.42,0.79,1.34c0.74-0.07,1.57-0.79,1.87-1.6c0.3-0.81-0.06-1.42-0.79-1.35C194.71,222.08,193.87,222.8,193.57,223.61z",
  "M189.35,224.03c-0.31,0.81,0.04,1.42,0.77,1.34c0.73-0.07,1.58-0.79,1.88-1.61c0.3-0.81-0.04-1.42-0.77-1.34C190.5,222.5,189.66,223.22,189.35,224.03z",
  "M160.18,227.19c-0.36,0.81-0.06,1.41,0.66,1.32c0.72-0.08,1.58-0.81,1.93-1.62c0.35-0.81,0.06-1.41-0.66-1.32C161.4,225.65,160.53,226.38,160.18,227.19z",
  "M156.07,227.68c-0.36,0.81-0.07,1.4,0.64,1.32c0.71-0.08,1.58-0.81,1.94-1.62c0.36-0.81,0.07-1.4-0.64-1.32C157.3,226.13,156.43,226.86,156.07,227.68z",
  "M151.97,228.16c-0.37,0.81-0.09,1.4,0.62,1.32c0.71-0.08,1.58-0.81,1.95-1.63c0.36-0.81,0.08-1.4-0.62-1.32C153.21,226.62,152.34,227.35,151.97,228.16z",
  "M147.9,228.66c-0.37,0.81-0.1,1.4,0.61,1.32c0.71-0.09,1.58-0.82,1.95-1.63c0.37-0.81,0.1-1.4-0.61-1.32C149.14,227.12,148.27,227.85,147.9,228.66z",
  "M143.83,229.16c-0.38,0.81-0.12,1.4,0.59,1.31c0.71-0.09,1.58-0.82,1.96-1.63c0.38-0.81,0.11-1.4-0.59-1.31C145.09,227.62,144.21,228.35,143.83,229.16z",
  "M139.79,229.67c-0.39,0.81-0.13,1.4,0.57,1.31c0.7-0.09,1.58-0.82,1.96-1.63c0.38-0.81,0.13-1.4-0.57-1.31C141.05,228.13,140.17,228.86,139.79,229.67z",
  "M135.76,230.19c-0.39,0.81-0.14,1.4,0.56,1.31c0.7-0.09,1.58-0.82,1.97-1.63c0.39-0.81,0.14-1.4-0.56-1.31C137.03,228.65,136.15,229.38,135.76,230.19z",
  "M127.75,231.25c-0.4,0.81-0.17,1.39,0.53,1.3c0.69-0.09,1.58-0.82,1.98-1.64c0.4-0.81,0.16-1.4-0.53-1.3C129.04,229.7,128.15,230.43,127.75,231.25z",
  "M666.06,232.28c0.5,0.81,1.41,1.54,2.03,1.64c0.62,0.1,0.71-0.47,0.21-1.28c-0.51-0.8-1.41-1.54-2.03-1.64C665.65,230.9,665.56,231.48,666.06,232.28z",
  "M662.48,231.72c0.5,0.81,1.41,1.54,2.03,1.64c0.62,0.1,0.72-0.48,0.22-1.28c-0.5-0.81-1.41-1.54-2.03-1.64C662.08,230.34,661.98,230.91,662.48,231.72z",
  "M658.87,231.16c0.49,0.81,1.4,1.54,2.03,1.64c0.63,0.1,0.73-0.48,0.24-1.28c-0.5-0.81-1.41-1.54-2.03-1.64C658.49,229.78,658.38,230.35,658.87,231.16z",
  "M655.24,230.6c0.49,0.81,1.4,1.54,2.03,1.64c0.63,0.1,0.74-0.48,0.25-1.29c-0.49-0.81-1.4-1.54-2.03-1.64C654.87,229.22,654.76,229.79,655.24,230.6z",
  "M651.6,230.05c0.49,0.81,1.39,1.54,2.03,1.64c0.63,0.1,0.75-0.48,0.27-1.29c-0.49-0.81-1.4-1.54-2.03-1.64C651.23,228.67,651.11,229.24,651.6,230.05z",
  "M647.92,229.5c0.48,0.81,1.39,1.54,2.03,1.63c0.64,0.09,0.76-0.48,0.28-1.29c-0.48-0.81-1.39-1.54-2.03-1.63C647.57,228.12,647.44,228.7,647.92,229.5z",
  "M644.23,228.96c0.48,0.81,1.38,1.54,2.03,1.63c0.64,0.09,0.78-0.49,0.3-1.29c-0.48-0.81-1.39-1.54-2.02-1.63C643.89,227.57,643.76,228.15,644.23,228.96z",
  "M640.52,228.42c0.47,0.81,1.38,1.54,2.02,1.63c0.65,0.09,0.79-0.49,0.31-1.3c-0.47-0.81-1.38-1.54-2.02-1.63C640.19,227.03,640.05,227.62,640.52,228.42z",
  "M636.79,227.89c0.47,0.81,1.37,1.54,2.02,1.63c0.65,0.09,0.8-0.49,0.33-1.3c-0.47-0.81-1.37-1.54-2.02-1.63C636.47,226.5,636.32,227.08,636.79,227.89z",
  "M633.04,227.36c0.46,0.81,1.36,1.54,2.02,1.63c0.65,0.09,0.81-0.49,0.34-1.3c-0.47-0.81-1.37-1.54-2.02-1.63C632.73,225.97,632.57,226.55,633.04,227.36z",
  "M629.26,226.84c0.46,0.81,1.36,1.54,2.02,1.63c0.66,0.09,0.82-0.49,0.36-1.3c-0.46-0.81-1.36-1.54-2.01-1.63C628.97,225.45,628.81,226.03,629.26,226.84z",
  "M621.66,225.82c0.45,0.81,1.35,1.54,2.01,1.63c0.66,0.09,0.84-0.5,0.39-1.31c-0.45-0.81-1.35-1.54-2.01-1.63C621.39,224.42,621.21,225.01,621.66,225.82z",
  "M582.55,221.05c0.39,0.81,1.26,1.53,1.96,1.61c0.69,0.08,0.94-0.52,0.55-1.33c-0.39-0.81-1.27-1.53-1.96-1.61C582.41,219.64,582.17,220.24,582.55,221.05z",
  "M578.55,220.61c0.38,0.81,1.26,1.53,1.95,1.61c0.7,0.08,0.95-0.52,0.56-1.33c-0.39-0.81-1.26-1.53-1.95-1.61C578.42,219.2,578.17,219.8,578.55,220.61z",
  "M574.53,220.18c0.38,0.81,1.25,1.53,1.95,1.61c0.7,0.07,0.96-0.52,0.58-1.34c-0.38-0.81-1.25-1.53-1.95-1.61C574.42,218.77,574.16,219.36,574.53,220.18z",
  "M570.5,219.75c0.37,0.81,1.24,1.53,1.94,1.61c0.7,0.07,0.97-0.53,0.6-1.34c-0.37-0.81-1.24-1.53-1.94-1.61C570.4,218.34,570.13,218.94,570.5,219.75z",
  "M566.45,219.34c0.36,0.81,1.23,1.53,1.93,1.6c0.7,0.07,0.98-0.53,0.61-1.34c-0.37-0.81-1.23-1.53-1.93-1.6C566.36,217.92,566.09,218.52,566.45,219.34z",
  "M562.38,218.93c0.36,0.81,1.22,1.53,1.93,1.6c0.71,0.07,0.99-0.53,0.63-1.35c-0.36-0.81-1.22-1.53-1.93-1.6C562.31,217.51,562.03,218.11,562.38,218.93z",
  "M558.3,218.52c0.35,0.81,1.21,1.53,1.92,1.6c0.71,0.07,1-0.53,0.65-1.35c-0.35-0.81-1.21-1.53-1.92-1.6C558.24,217.11,557.96,217.71,558.3,218.52z",
  "M554.21,218.13c0.34,0.81,1.2,1.53,1.91,1.6c0.71,0.07,1.01-0.54,0.66-1.35c-0.35-0.81-1.2-1.53-1.91-1.6C554.17,216.71,553.87,217.32,554.21,218.13z",
  "M550.1,217.74c0.33,0.81,1.19,1.53,1.9,1.59c0.72,0.07,1.02-0.54,0.68-1.35c-0.34-0.81-1.19-1.53-1.9-1.59C550.07,216.33,549.77,216.93,550.1,217.74z",
  "M545.98,217.37c0.33,0.81,1.18,1.53,1.89,1.59c0.72,0.07,1.03-0.54,0.7-1.36c-0.33-0.81-1.18-1.53-1.89-1.59C545.97,215.95,545.65,216.55,545.98,217.37z",
  "M541.85,217c0.32,0.81,1.17,1.53,1.89,1.59c0.72,0.06,1.04-0.54,0.71-1.36c-0.33-0.81-1.17-1.53-1.89-1.59C541.85,215.58,541.53,216.18,541.85,217z",
  "M537.7,216.64c0.32,0.81,1.15,1.52,1.88,1.59c0.72,0.06,1.05-0.55,0.73-1.36c-0.32-0.81-1.16-1.52-1.88-1.59C537.71,215.21,537.39,215.82,537.7,216.64z",
  "M533.54,216.28c0.31,0.81,1.14,1.52,1.87,1.58c0.72,0.06,1.06-0.55,0.75-1.36c-0.31-0.81-1.15-1.52-1.87-1.58C533.57,214.86,533.23,215.47,533.54,216.28z",
  "M529.37,215.94c0.3,0.81,1.13,1.52,1.86,1.58c0.73,0.06,1.07-0.55,0.76-1.37c-0.31-0.81-1.14-1.52-1.86-1.58C529.41,214.51,529.07,215.12,529.37,215.94z",
  "M525.19,215.6c0.29,0.81,1.12,1.52,1.85,1.58c0.73,0.06,1.08-0.55,0.78-1.37c-0.3-0.81-1.13-1.52-1.85-1.58C525.24,214.18,524.89,214.79,525.19,215.6z",
  "M520.99,215.28c0.29,0.81,1.11,1.52,1.84,1.58c0.73,0.06,1.09-0.56,0.8-1.37c-0.29-0.81-1.12-1.52-1.84-1.58C521.06,213.85,520.7,214.46,520.99,215.28z",
  "M516.78,214.96c0.28,0.81,1.1,1.52,1.83,1.57c0.73,0.06,1.1-0.56,0.81-1.37c-0.28-0.81-1.1-1.52-1.83-1.57C516.87,213.53,516.5,214.14,516.78,214.96z",
  "M512.56,214.65c0.27,0.81,1.09,1.52,1.82,1.57c0.73,0.05,1.11-0.56,0.83-1.38c-0.28-0.81-1.09-1.52-1.82-1.57C512.66,213.22,512.29,213.83,512.56,214.65z",
  "M508.33,214.35c0.26,0.81,1.07,1.52,1.81,1.57c0.74,0.05,1.12-0.57,0.85-1.38c-0.27-0.81-1.08-1.52-1.81-1.57C508.45,212.92,508.07,213.53,508.33,214.35z",
  "M504.09,214.06c0.26,0.81,1.06,1.52,1.8,1.57c0.74,0.05,1.12-0.57,0.86-1.38c-0.26-0.81-1.07-1.52-1.8-1.57C504.22,212.62,503.84,213.24,504.09,214.06z",
  "M499.84,213.78c0.25,0.81,1.05,1.51,1.79,1.56c0.74,0.05,1.14-0.57,0.88-1.39c-0.25-0.81-1.06-1.51-1.79-1.56C499.99,212.34,499.59,212.96,499.84,213.78z",
  "M495.58,213.5c0.24,0.81,1.04,1.51,1.78,1.56c0.74,0.05,1.14-0.58,0.9-1.39c-0.25-0.81-1.04-1.51-1.78-1.56C495.74,212.07,495.34,212.69,495.58,213.5z",
  "M491.31,213.24c0.23,0.81,1.03,1.51,1.77,1.56c0.74,0.05,1.15-0.58,0.91-1.39c-0.24-0.81-1.03-1.51-1.77-1.56C491.49,211.8,491.08,212.42,491.31,213.24z",
  "M465.5,211.86c0.19,0.81,0.95,1.5,1.7,1.54c0.75,0.04,1.21-0.6,1.01-1.41c-0.19-0.81-0.96-1.5-1.7-1.54C465.77,210.41,465.32,211.04,465.5,211.86z",
  "M461.17,211.66c0.18,0.81,0.94,1.5,1.69,1.54c0.75,0.03,1.22-0.6,1.03-1.41c-0.18-0.81-0.94-1.5-1.69-1.53C461.46,210.22,460.99,210.85,461.17,211.66z",
  "M456.84,211.48c0.17,0.81,0.92,1.5,1.68,1.53c0.76,0.03,1.22-0.6,1.05-1.42c-0.18-0.81-0.93-1.5-1.68-1.53C457.13,210.03,456.67,210.66,456.84,211.48z",
  "M452.49,211.3c0.16,0.81,0.91,1.5,1.67,1.53c0.76,0.03,1.23-0.6,1.06-1.42c-0.17-0.81-0.92-1.5-1.67-1.53C452.81,209.85,452.33,210.49,452.49,211.3z",
  "M448.14,211.13c0.16,0.81,0.9,1.5,1.65,1.53c0.76,0.03,1.24-0.61,1.08-1.42c-0.16-0.81-0.9-1.5-1.65-1.53C448.47,209.68,447.99,210.32,448.14,211.13z",
  "M443.79,210.98c0.15,0.81,0.88,1.5,1.64,1.52c0.76,0.03,1.25-0.61,1.1-1.43c-0.15-0.81-0.89-1.5-1.64-1.52C444.13,209.52,443.64,210.16,443.79,210.98z",
  "M439.42,210.83c0.14,0.81,0.87,1.49,1.63,1.52c0.76,0.03,1.26-0.61,1.11-1.43c-0.15-0.81-0.87-1.49-1.63-1.52C439.78,209.38,439.28,210.02,439.42,210.83z",
  "M435.06,210.69c0.13,0.81,0.85,1.49,1.61,1.52c0.76,0.02,1.27-0.62,1.13-1.43c-0.14-0.81-0.86-1.49-1.62-1.52C435.43,209.24,434.92,209.88,435.06,210.69z",
  "M430.68,210.57c0.12,0.81,0.84,1.49,1.6,1.51c0.76,0.02,1.27-0.62,1.15-1.43c-0.13-0.81-0.85-1.49-1.6-1.51C431.07,209.11,430.56,209.76,430.68,210.57z",
  "M426.3,210.45c0.11,0.81,0.83,1.49,1.59,1.51c0.76,0.02,1.28-0.62,1.16-1.44c-0.12-0.81-0.83-1.49-1.59-1.51C426.71,209,426.19,209.64,426.3,210.45z",
  "M421.92,210.35c0.11,0.81,0.81,1.49,1.58,1.51c0.76,0.02,1.29-0.63,1.18-1.44c-0.11-0.81-0.82-1.49-1.58-1.51C422.34,208.89,421.81,209.53,421.92,210.35z",
  "M417.53,210.25c0.1,0.81,0.8,1.49,1.56,1.5c0.76,0.02,1.3-0.63,1.19-1.44c-0.1-0.81-0.8-1.49-1.56-1.5C417.97,208.79,417.43,209.44,417.53,210.25z",
  "M413.14,210.17c0.09,0.81,0.78,1.49,1.55,1.5c0.76,0.01,1.31-0.63,1.21-1.45c-0.1-0.81-0.79-1.49-1.55-1.5C413.59,208.71,413.05,209.35,413.14,210.17z",
  "M408.74,210.09c0.08,0.81,0.77,1.48,1.53,1.5c0.77,0.01,1.31-0.64,1.23-1.45c-0.09-0.81-0.78-1.48-1.54-1.5C409.21,208.63,408.66,209.28,408.74,210.09z",
  "M404.34,210.03c0.07,0.81,0.75,1.48,1.52,1.49c0.77,0.01,1.32-0.64,1.24-1.45c-0.08-0.81-0.76-1.48-1.52-1.49C404.83,208.56,404.27,209.22,404.34,210.03z",
  "M399.94,209.98c0.07,0.81,0.74,1.48,1.51,1.49c0.77,0.01,1.33-0.64,1.26-1.46c-0.07-0.81-0.75-1.48-1.51-1.49C400.44,208.51,399.88,209.16,399.94,209.98z",
  "M395.54,209.93c0.06,0.81,0.73,1.48,1.49,1.49c0.77,0.01,1.34-0.65,1.27-1.46c-0.06-0.81-0.73-1.48-1.49-1.49C396.05,208.47,395.48,209.12,395.54,209.93z",
  "M391.13,209.9c0.05,0.81,0.71,1.48,1.48,1.48c0.77,0.01,1.35-0.65,1.29-1.46c-0.06-0.81-0.72-1.48-1.48-1.48C391.66,208.43,391.08,209.09,391.13,209.9z",
  "M386.72,209.88c0.04,0.81,0.7,1.48,1.46,1.48c0.77,0,1.35-0.65,1.31-1.47c-0.05-0.81-0.7-1.48-1.46-1.48C387.26,208.41,386.68,209.06,386.72,209.88z",
  "M382.31,209.87c0.03,0.81,0.68,1.48,1.45,1.48c0.77,0,1.36-0.66,1.32-1.47c-0.04-0.81-0.69-1.47-1.45-1.48C382.87,208.39,382.27,209.05,382.31,209.87z",
  "M377.89,209.86c0.02,0.81,0.67,1.47,1.44,1.47c0.77,0,1.37-0.66,1.34-1.47c-0.03-0.81-0.67-1.47-1.44-1.47C378.47,208.39,377.87,209.05,377.89,209.86z",
  "M373.48,209.87c0.02,0.81,0.65,1.47,1.42,1.47c0.77,0,1.37-0.66,1.35-1.48c-0.02-0.81-0.66-1.47-1.42-1.47C374.07,208.4,373.46,209.06,373.48,209.87z",
  "M369.06,209.89c0.01,0.81,0.64,1.47,1.41,1.47c0.77,0,1.38-0.67,1.37-1.48c-0.01-0.81-0.64-1.47-1.41-1.47C369.67,208.42,369.06,209.08,369.06,209.89z",
  "M364.65,209.92c0,0.81,0.62,1.47,1.39,1.46c0.77-0.01,1.39-0.67,1.38-1.48c-0.01-0.81-0.63-1.47-1.39-1.46C365.27,208.45,364.65,209.11,364.65,209.92z",
  "M360.23,209.96c-0.01,0.81,0.61,1.47,1.38,1.46c0.77-0.01,1.39-0.67,1.4-1.49c0-0.81-0.61-1.47-1.38-1.46C360.87,208.49,360.24,209.15,360.23,209.96z",
  "M355.81,210.02c-0.02,0.81,0.59,1.47,1.36,1.46c0.77-0.01,1.4-0.68,1.41-1.49c0.01-0.81-0.6-1.47-1.36-1.46C356.46,208.53,355.83,209.2,355.81,210.02z",
  "M351.4,210.08c-0.03,0.81,0.58,1.46,1.35,1.45c0.77-0.01,1.41-0.68,1.43-1.49c0.02-0.81-0.58-1.46-1.35-1.45C352.06,208.6,351.42,209.26,351.4,210.08z",
  "M346.98,210.15c-0.03,0.81,0.56,1.46,1.33,1.45c0.77-0.01,1.41-0.68,1.44-1.5c0.03-0.81-0.57-1.46-1.33-1.45C347.66,208.67,347.01,209.34,346.98,210.15z",
  "M342.57,210.23c-0.04,0.81,0.55,1.46,1.32,1.45c0.77-0.01,1.42-0.69,1.46-1.5c0.04-0.81-0.55-1.46-1.32-1.45C343.26,208.75,342.61,209.42,342.57,210.23z",
  "M338.15,210.33c-0.05,0.81,0.53,1.46,1.3,1.44c0.77-0.02,1.43-0.69,1.47-1.5c0.04-0.81-0.54-1.46-1.3-1.44C338.86,208.84,338.2,209.51,338.15,210.33z",
  "M333.74,210.43c-0.06,0.81,0.52,1.46,1.29,1.44c0.77-0.02,1.43-0.69,1.49-1.51c0.05-0.81-0.52-1.46-1.29-1.44C334.46,208.94,333.8,209.62,333.74,210.43z",
  "M329.33,210.54c-0.07,0.81,0.5,1.46,1.27,1.44c0.77-0.02,1.44-0.7,1.5-1.51c0.06-0.81-0.51-1.46-1.27-1.44C330.07,209.05,329.4,209.73,329.33,210.54z",
  "M324.92,210.67c-0.07,0.81,0.49,1.46,1.25,1.43c0.77-0.02,1.45-0.7,1.51-1.51c0.07-0.81-0.49-1.46-1.25-1.43C325.68,209.18,325,209.85,324.92,210.67z",
  "M320.52,210.8c-0.08,0.81,0.47,1.45,1.24,1.43c0.77-0.02,1.45-0.7,1.53-1.52c0.08-0.81-0.48-1.45-1.24-1.43C321.28,209.31,320.6,209.99,320.52,210.8z",
  "M294.15,211.83c-0.13,0.81,0.38,1.45,1.14,1.41c0.76-0.03,1.48-0.72,1.61-1.54c0.13-0.81-0.39-1.45-1.14-1.41C295.01,210.32,294.29,211.01,294.15,211.83z",
  "M289.78,212.03c-0.14,0.81,0.37,1.44,1.13,1.41c0.76-0.04,1.49-0.73,1.62-1.54c0.13-0.81-0.37-1.44-1.13-1.41C290.64,210.53,289.92,211.22,289.78,212.03z",
  "M281.03,212.48c-0.16,0.81,0.34,1.44,1.1,1.4c0.76-0.04,1.5-0.73,1.65-1.55c0.15-0.81-0.34-1.44-1.1-1.4C281.93,210.97,281.19,211.66,281.03,212.48z",
  "M276.67,212.71c-0.16,0.81,0.32,1.44,1.08,1.4c0.76-0.04,1.5-0.73,1.66-1.55c0.16-0.81-0.33-1.44-1.08-1.4C277.58,211.2,276.83,211.9,276.67,212.71z",
  "M242.01,214.96c-0.23,0.81,0.2,1.43,0.95,1.37c0.75-0.05,1.53-0.76,1.75-1.57c0.22-0.81-0.2-1.43-0.95-1.37C243.02,213.44,242.24,214.14,242.01,214.96z",
  "M237.71,215.28c-0.23,0.81,0.19,1.43,0.93,1.37c0.75-0.06,1.54-0.76,1.77-1.58c0.23-0.81-0.19-1.43-0.93-1.37C238.74,213.76,237.95,214.46,237.71,215.28z",
  "M233.43,215.61c-0.24,0.81,0.17,1.43,0.92,1.37c0.75-0.06,1.54-0.76,1.78-1.58c0.23-0.81-0.17-1.43-0.92-1.37C234.46,214.09,233.67,214.8,233.43,215.61z",
  "M229.15,215.95c-0.25,0.81,0.16,1.43,0.9,1.37c0.74-0.06,1.54-0.77,1.79-1.58c0.24-0.81-0.16-1.43-0.9-1.37C230.2,214.43,229.39,215.14,229.15,215.95z",
  "M195.28,219.01c-0.31,0.81,0.04,1.42,0.77,1.34c0.73-0.07,1.56-0.79,1.86-1.6c0.3-0.81-0.04-1.41-0.77-1.34C196.43,217.48,195.59,218.19,195.28,219.01z",
  "M191.1,219.43c-0.31,0.81,0.02,1.41,0.75,1.34c0.73-0.07,1.57-0.79,1.87-1.61c0.31-0.81-0.03-1.41-0.75-1.34C192.25,217.9,191.42,218.62,191.1,219.43z",
  "M170.39,221.65c-0.35,0.81-0.05,1.41,0.67,1.33c0.72-0.08,1.57-0.8,1.91-1.62c0.34-0.81,0.04-1.41-0.67-1.33C171.59,220.11,170.73,220.84,170.39,221.65z",
  "M166.28,222.12c-0.35,0.81-0.06,1.41,0.65,1.32c0.71-0.08,1.57-0.81,1.92-1.62c0.35-0.81,0.06-1.41-0.65-1.32C167.5,220.58,166.64,221.31,166.28,222.12z",
  "M162.2,222.6c-0.36,0.81-0.07,1.4,0.64,1.32c0.71-0.08,1.57-0.81,1.93-1.62c0.35-0.81,0.07-1.4-0.64-1.32C163.42,221.06,162.55,221.78,162.2,222.6z",
  "M158.12,223.08c-0.37,0.81-0.09,1.4,0.62,1.32c0.71-0.08,1.57-0.81,1.94-1.62c0.36-0.81,0.08-1.4-0.62-1.32C159.35,221.54,158.49,222.27,158.12,223.08z",
  "M154.06,223.57c-0.37,0.81-0.1,1.4,0.61,1.32c0.71-0.09,1.58-0.81,1.94-1.63c0.37-0.81,0.1-1.4-0.6-1.32C155.3,222.03,154.43,222.76,154.06,223.57z",
  "M150.02,224.07c-0.38,0.81-0.11,1.4,0.59,1.31c0.7-0.09,1.58-0.82,1.95-1.63c0.37-0.81,0.11-1.4-0.59-1.31C151.27,222.53,150.4,223.26,150.02,224.07z",
  "M145.99,224.57c-0.38,0.81-0.13,1.4,0.57,1.31c0.7-0.09,1.58-0.82,1.96-1.63c0.38-0.81,0.12-1.4-0.57-1.31C147.25,223.03,146.38,223.76,145.99,224.57z",
  "M141.98,225.08c-0.39,0.81-0.14,1.4,0.56,1.31c0.7-0.09,1.58-0.82,1.96-1.63c0.39-0.81,0.14-1.4-0.56-1.31C143.25,223.54,142.37,224.27,141.98,225.08z",
  "M137.98,225.6c-0.4,0.81-0.15,1.4,0.54,1.31c0.69-0.09,1.58-0.82,1.97-1.63c0.39-0.81,0.15-1.4-0.54-1.31C139.26,224.06,138.38,224.79,137.98,225.6z",
  "M134,226.13c-0.4,0.81-0.17,1.39,0.52,1.3c0.69-0.09,1.58-0.82,1.97-1.63c0.4-0.81,0.16-1.4-0.52-1.3C135.29,224.58,134.41,225.32,134,226.13z",
  "M130.04,226.66c-0.41,0.81-0.18,1.39,0.51,1.3c0.69-0.09,1.57-0.82,1.98-1.64c0.4-0.81,0.18-1.39-0.51-1.3C131.34,225.12,130.45,225.85,130.04,226.66z",
  "M666.76,228.3c0.51,0.8,1.42,1.54,2.03,1.64c0.61,0.1,0.69-0.47,0.18-1.28c-0.51-0.8-1.42-1.54-2.03-1.64C666.33,226.92,666.25,227.5,666.76,228.3z",
  "M663.22,227.73c0.5,0.81,1.41,1.54,2.03,1.64c0.62,0.1,0.7-0.47,0.2-1.28c-0.51-0.8-1.41-1.54-2.03-1.64C662.8,226.35,662.71,226.92,663.22,227.73z",
  "M659.66,227.16c0.5,0.81,1.41,1.54,2.03,1.64c0.62,0.1,0.71-0.48,0.21-1.28c-0.5-0.81-1.41-1.54-2.03-1.64C659.25,225.78,659.16,226.36,659.66,227.16z",
  "M656.07,226.6c0.5,0.81,1.4,1.54,2.03,1.64c0.62,0.1,0.72-0.48,0.23-1.28c-0.5-0.81-1.41-1.54-2.03-1.64C655.68,225.22,655.58,225.79,656.07,226.6z",
  "M652.47,226.04c0.49,0.81,1.4,1.54,2.03,1.64c0.63,0.1,0.73-0.48,0.24-1.29c-0.49-0.81-1.4-1.54-2.03-1.64C652.09,224.66,651.98,225.23,652.47,226.04z",
  "M637.85,223.85c0.47,0.81,1.38,1.54,2.02,1.63c0.64,0.09,0.77-0.49,0.3-1.29c-0.48-0.81-1.38-1.54-2.02-1.63C637.51,222.46,637.38,223.04,637.85,223.85z",
  "M634.14,223.32c0.47,0.81,1.37,1.54,2.02,1.63c0.65,0.09,0.78-0.49,0.31-1.3c-0.47-0.81-1.38-1.54-2.02-1.63C633.81,221.93,633.68,222.51,634.14,223.32z",
  "M630.42,222.79c0.46,0.81,1.37,1.54,2.01,1.63c0.65,0.09,0.79-0.49,0.33-1.3c-0.47-0.81-1.37-1.54-2.01-1.63C630.1,221.4,629.96,221.98,630.42,222.79z",
  "M626.68,222.26c0.46,0.81,1.36,1.54,2.01,1.63c0.65,0.09,0.81-0.49,0.34-1.3c-0.46-0.81-1.36-1.54-2.01-1.63C626.37,220.87,626.22,221.45,626.68,222.26z",
  "M622.91,221.75c0.45,0.81,1.35,1.54,2.01,1.63c0.65,0.09,0.81-0.49,0.36-1.3c-0.46-0.81-1.36-1.54-2.01-1.63C622.62,220.35,622.46,220.94,622.91,221.75z",
  "M619.13,221.23c0.45,0.81,1.35,1.54,2,1.63c0.66,0.09,0.83-0.5,0.37-1.31c-0.45-0.81-1.35-1.54-2-1.63C618.85,219.84,618.68,220.42,619.13,221.23z",
  "M580.35,216.46c0.39,0.81,1.27,1.53,1.95,1.61c0.69,0.08,0.93-0.52,0.53-1.33c-0.4-0.81-1.27-1.53-1.95-1.61C580.2,215.05,579.96,215.64,580.35,216.46z",
  "M576.38,216.02c0.38,0.81,1.26,1.53,1.95,1.61c0.69,0.08,0.94-0.52,0.55-1.33c-0.39-0.81-1.26-1.53-1.95-1.61C576.24,214.61,576,215.2,576.38,216.02z",
  "M572.4,215.58c0.38,0.81,1.25,1.53,1.94,1.61c0.69,0.07,0.95-0.52,0.56-1.34c-0.38-0.81-1.25-1.53-1.94-1.61C572.27,214.17,572.02,214.77,572.4,215.58z",
  "M568.4,215.16c0.37,0.81,1.24,1.53,1.93,1.6c0.7,0.07,0.95-0.53,0.58-1.34c-0.38-0.81-1.24-1.53-1.93-1.6C568.29,213.75,568.03,214.35,568.4,215.16z",
  "M564.39,214.74c0.37,0.81,1.23,1.53,1.93,1.6c0.7,0.07,0.97-0.53,0.6-1.34c-0.37-0.81-1.23-1.53-1.93-1.6C564.29,213.33,564.02,213.93,564.39,214.74z",
  "M560.36,214.33c0.36,0.81,1.22,1.53,1.92,1.6c0.7,0.07,0.97-0.53,0.61-1.34c-0.36-0.81-1.22-1.53-1.92-1.6C560.28,212.92,560,213.52,560.36,214.33z",
  "M556.32,213.93c0.35,0.81,1.21,1.53,1.91,1.6c0.7,0.07,0.99-0.53,0.63-1.35c-0.36-0.81-1.21-1.53-1.91-1.6C556.25,212.51,555.97,213.12,556.32,213.93z",
  "M552.27,213.53c0.35,0.81,1.2,1.53,1.9,1.59c0.71,0.07,1-0.54,0.64-1.35c-0.35-0.81-1.2-1.53-1.9-1.59C552.21,212.12,551.92,212.72,552.27,213.53z",
  "M548.2,213.15c0.34,0.81,1.19,1.52,1.9,1.59c0.71,0.07,1-0.54,0.66-1.35c-0.34-0.81-1.19-1.53-1.9-1.59C548.15,211.73,547.86,212.34,548.2,213.15z",
  "M544.11,212.77c0.33,0.81,1.18,1.52,1.89,1.59c0.71,0.07,1.01-0.54,0.68-1.35c-0.34-0.81-1.18-1.52-1.89-1.59C544.09,211.35,543.78,211.96,544.11,212.77z",
  "M540.02,212.4c0.32,0.81,1.17,1.52,1.88,1.59c0.71,0.06,1.02-0.54,0.69-1.36c-0.33-0.81-1.17-1.52-1.88-1.59C540.01,210.98,539.69,211.59,540.02,212.4z",
  "M535.91,212.04c0.32,0.81,1.15,1.52,1.87,1.58c0.72,0.06,1.03-0.55,0.71-1.36c-0.32-0.81-1.16-1.52-1.87-1.58C535.91,210.62,535.59,211.23,535.91,212.04z",
  "M531.79,211.69c0.31,0.81,1.14,1.52,1.86,1.58c0.72,0.06,1.04-0.55,0.73-1.36c-0.32-0.81-1.15-1.52-1.86-1.58C531.81,210.26,531.48,210.87,531.79,211.69z",
  "M527.66,211.34c0.3,0.81,1.13,1.52,1.85,1.58c0.72,0.06,1.05-0.55,0.74-1.36c-0.31-0.81-1.14-1.52-1.85-1.58C527.69,209.92,527.36,210.53,527.66,211.34z",
  "M523.52,211c0.3,0.81,1.12,1.52,1.84,1.58c0.72,0.06,1.06-0.55,0.76-1.37c-0.3-0.81-1.13-1.52-1.84-1.58C523.56,209.58,523.22,210.19,523.52,211z",
  "M519.36,210.68c0.29,0.81,1.11,1.52,1.83,1.57c0.72,0.06,1.07-0.56,0.78-1.37c-0.29-0.81-1.12-1.52-1.83-1.57C519.42,209.25,519.07,209.87,519.36,210.68z",
  "M515.2,210.36c0.28,0.81,1.1,1.52,1.82,1.57c0.73,0.05,1.08-0.56,0.79-1.37c-0.29-0.81-1.1-1.52-1.82-1.57C515.27,208.93,514.91,209.55,515.2,210.36z",
  "M511.02,210.05c0.27,0.81,1.09,1.51,1.81,1.57c0.73,0.05,1.09-0.56,0.81-1.37c-0.28-0.81-1.09-1.51-1.81-1.57C511.11,208.62,510.74,209.24,511.02,210.05z",
  "M506.83,209.75c0.27,0.81,1.07,1.51,1.8,1.56c0.73,0.05,1.1-0.56,0.83-1.38c-0.27-0.81-1.08-1.51-1.8-1.56C506.94,208.32,506.56,208.94,506.83,209.75z",
  "M502.63,209.46c0.26,0.81,1.06,1.51,1.79,1.56c0.73,0.05,1.11-0.57,0.84-1.38c-0.27-0.81-1.07-1.51-1.79-1.56C502.75,208.03,502.37,208.65,502.63,209.46z",
  "M498.43,209.18c0.25,0.81,1.05,1.51,1.78,1.56c0.73,0.05,1.12-0.57,0.86-1.38c-0.26-0.81-1.06-1.51-1.78-1.56C498.56,207.75,498.17,208.36,498.43,209.18z",
  "M494.21,208.9c0.24,0.81,1.04,1.51,1.77,1.56c0.73,0.05,1.13-0.57,0.88-1.39c-0.25-0.81-1.04-1.51-1.77-1.56C494.36,207.47,493.96,208.09,494.21,208.9z",
  "M489.98,208.64c0.24,0.81,1.02,1.51,1.76,1.55c0.74,0.05,1.14-0.58,0.9-1.39c-0.24-0.81-1.03-1.51-1.76-1.55C490.14,207.21,489.74,207.83,489.98,208.64z",
  "M485.74,208.39c0.23,0.81,1.01,1.51,1.75,1.55c0.74,0.04,1.15-0.58,0.91-1.39c-0.23-0.81-1.02-1.51-1.75-1.55C485.92,206.95,485.52,207.58,485.74,208.39z",
  "M481.5,208.14c0.22,0.81,1,1.5,1.74,1.55c0.74,0.04,1.16-0.58,0.93-1.39c-0.23-0.81-1.01-1.5-1.74-1.55C481.69,206.71,481.28,207.33,481.5,208.14z",
  "M460.15,207.07c0.18,0.81,0.93,1.5,1.68,1.53c0.75,0.03,1.2-0.6,1.01-1.41c-0.19-0.81-0.94-1.5-1.68-1.53C460.42,205.62,459.97,206.25,460.15,207.07z",
  "M455.86,206.88c0.17,0.81,0.92,1.5,1.67,1.53c0.75,0.03,1.21-0.6,1.03-1.41c-0.18-0.81-0.93-1.5-1.67-1.53C456.15,205.44,455.69,206.07,455.86,206.88z",
  "M451.56,206.7c0.16,0.81,0.91,1.49,1.65,1.52c0.75,0.03,1.22-0.6,1.05-1.42c-0.17-0.81-0.91-1.49-1.65-1.52C451.86,205.26,451.4,205.89,451.56,206.7z",
  "M447.26,206.54c0.16,0.81,0.89,1.49,1.64,1.52c0.75,0.03,1.23-0.61,1.06-1.42c-0.16-0.81-0.9-1.49-1.64-1.52C447.58,205.09,447.1,205.73,447.26,206.54z",
  "M442.95,206.38c0.15,0.81,0.88,1.49,1.63,1.52c0.75,0.03,1.23-0.61,1.08-1.42c-0.15-0.81-0.88-1.49-1.63-1.52C443.28,204.93,442.8,205.57,442.95,206.38z",
  "M438.63,206.24c0.14,0.81,0.86,1.49,1.62,1.52c0.75,0.02,1.24-0.61,1.1-1.42c-0.15-0.81-0.87-1.49-1.62-1.51C438.98,204.79,438.49,205.42,438.63,206.24z",
  "M434.31,206.1c0.13,0.81,0.85,1.49,1.6,1.51c0.75,0.02,1.25-0.62,1.11-1.43c-0.14-0.81-0.86-1.49-1.6-1.51C434.67,204.65,434.17,205.29,434.31,206.1z",
  "M429.98,205.97c0.12,0.81,0.84,1.49,1.59,1.51c0.75,0.02,1.26-0.62,1.13-1.43c-0.13-0.81-0.84-1.49-1.59-1.51C430.36,204.52,429.85,205.16,429.98,205.97z",
  "M425.65,205.86c0.12,0.81,0.82,1.49,1.58,1.51c0.75,0.02,1.27-0.62,1.15-1.43c-0.12-0.81-0.83-1.49-1.58-1.51C426.04,204.4,425.53,205.05,425.65,205.86z",
  "M421.31,205.75c0.11,0.81,0.81,1.48,1.56,1.5c0.76,0.02,1.28-0.63,1.16-1.44c-0.11-0.81-0.81-1.48-1.56-1.5C421.72,204.3,421.2,204.94,421.31,205.75z",
  "M416.97,205.66c0.1,0.81,0.79,1.48,1.55,1.5c0.76,0.02,1.28-0.63,1.18-1.44c-0.11-0.81-0.8-1.48-1.55-1.5C417.4,204.2,416.87,204.85,416.97,205.66z",
  "M412.62,205.57c0.09,0.81,0.78,1.48,1.54,1.5c0.76,0.01,1.29-0.63,1.19-1.44c-0.1-0.81-0.79-1.48-1.54-1.5C413.07,204.12,412.53,204.76,412.62,205.57z",
  "M408.27,205.5c0.08,0.81,0.76,1.48,1.52,1.49c0.76,0.01,1.3-0.64,1.21-1.45c-0.09-0.81-0.77-1.48-1.52-1.49C408.73,204.04,408.19,204.69,408.27,205.5z",
  "M403.92,205.44c0.07,0.81,0.75,1.48,1.51,1.49c0.76,0.01,1.31-0.64,1.23-1.45c-0.08-0.81-0.76-1.48-1.51-1.49C404.4,203.97,403.85,204.62,403.92,205.44z",
  "M399.57,205.38c0.07,0.81,0.73,1.48,1.49,1.49c0.76,0.01,1.31-0.64,1.24-1.45c-0.07-0.81-0.74-1.48-1.49-1.49C400.06,203.92,399.5,204.57,399.57,205.38z",
  "M395.21,205.34c0.06,0.81,0.72,1.48,1.48,1.48c0.76,0.01,1.32-0.65,1.26-1.46c-0.06-0.81-0.73-1.48-1.48-1.48C395.71,203.88,395.15,204.53,395.21,205.34z",
  "M390.85,205.31c0.05,0.81,0.71,1.47,1.47,1.48c0.76,0.01,1.33-0.65,1.27-1.46c-0.06-0.81-0.71-1.47-1.47-1.48C391.37,203.84,390.8,204.49,390.85,205.31z",
  "M386.48,205.28c0.04,0.81,0.69,1.47,1.45,1.48c0.76,0,1.34-0.65,1.29-1.46c-0.05-0.81-0.7-1.47-1.45-1.48C387.02,203.82,386.44,204.47,386.48,205.28z",
  "M382.12,205.27c0.03,0.81,0.68,1.47,1.44,1.47c0.76,0,1.34-0.65,1.31-1.47c-0.04-0.81-0.68-1.47-1.44-1.47C382.67,203.8,382.09,204.46,382.12,205.27z",
  "M377.75,205.27c0.02,0.81,0.66,1.47,1.42,1.47c0.76,0,1.35-0.66,1.32-1.47c-0.03-0.81-0.67-1.47-1.42-1.47C378.32,203.8,377.73,204.46,377.75,205.27z",
  "M373.39,205.28c0.02,0.81,0.65,1.47,1.41,1.47c0.76,0,1.36-0.66,1.34-1.47c-0.02-0.81-0.65-1.47-1.41-1.47C373.97,203.81,373.37,204.47,373.39,205.28z",
  "M369.02,205.3c0.01,0.81,0.63,1.47,1.39,1.46c0.76,0,1.37-0.66,1.35-1.48c-0.01-0.81-0.64-1.47-1.39-1.46C369.61,203.83,369.01,204.49,369.02,205.3z",
  "M364.65,205.33c0,0.81,0.62,1.47,1.38,1.46c0.76-0.01,1.37-0.67,1.37-1.48c-0.01-0.81-0.62-1.46-1.38-1.46C365.26,203.86,364.65,204.52,364.65,205.33z",
  "M360.28,205.37c-0.01,0.81,0.6,1.46,1.36,1.46c0.76-0.01,1.38-0.67,1.38-1.48c0-0.81-0.61-1.46-1.36-1.46C360.91,203.9,360.29,204.56,360.28,205.37z",
  "M355.91,205.42c-0.02,0.81,0.59,1.46,1.35,1.45c0.76-0.01,1.39-0.67,1.4-1.49c0.01-0.81-0.59-1.46-1.35-1.45C356.55,203.95,355.93,204.61,355.91,205.42z",
  "M351.54,205.48c-0.03,0.81,0.57,1.46,1.33,1.45c0.76-0.01,1.39-0.68,1.41-1.49c0.02-0.81-0.58-1.46-1.33-1.45C352.2,204.01,351.56,204.67,351.54,205.48z",
  "M347.17,205.56c-0.03,0.81,0.56,1.46,1.32,1.45c0.76-0.01,1.4-0.68,1.43-1.49c0.03-0.81-0.56-1.46-1.32-1.45C347.84,204.08,347.2,204.74,347.17,205.56z",
  "M342.8,205.64c-0.04,0.81,0.54,1.46,1.3,1.44c0.76-0.01,1.41-0.68,1.44-1.5c0.04-0.81-0.55-1.46-1.3-1.44C343.49,204.16,342.84,204.83,342.8,205.64z",
  "M338.44,205.73c-0.05,0.81,0.52,1.46,1.28,1.44c0.76-0.02,1.41-0.69,1.46-1.5c0.04-0.81-0.53-1.46-1.28-1.44C339.14,204.25,338.49,204.92,338.44,205.73z",
  "M334.07,205.83c-0.06,0.81,0.51,1.45,1.27,1.44c0.76-0.02,1.42-0.69,1.47-1.5c0.05-0.81-0.51-1.45-1.27-1.44C334.79,204.35,334.13,205.02,334.07,205.83z",
  "M329.71,205.95c-0.07,0.81,0.49,1.45,1.25,1.43c0.76-0.02,1.43-0.69,1.49-1.51c0.06-0.81-0.5-1.45-1.25-1.43C330.44,204.46,329.77,205.14,329.71,205.95z",
  "M325.35,206.07c-0.08,0.81,0.48,1.45,1.24,1.43c0.76-0.02,1.43-0.7,1.5-1.51c0.07-0.81-0.48-1.45-1.24-1.43C326.09,204.59,325.42,205.26,325.35,206.07z",
  "M243.29,210.36c-0.23,0.81,0.19,1.43,0.93,1.37c0.74-0.05,1.52-0.76,1.75-1.57c0.22-0.81-0.19-1.43-0.93-1.37C244.3,208.84,243.52,209.55,243.29,210.36z",
  "M239.04,210.68c-0.24,0.81,0.17,1.43,0.91,1.37c0.74-0.06,1.53-0.76,1.76-1.57c0.23-0.81-0.18-1.43-0.91-1.37C240.06,209.17,239.27,209.87,239.04,210.68z",
  "M230.55,211.36c-0.25,0.81,0.14,1.42,0.88,1.36c0.74-0.06,1.53-0.77,1.78-1.58c0.25-0.81-0.15-1.42-0.88-1.36C231.6,209.84,230.81,210.54,230.55,211.36z",
  "M192.88,214.83c-0.32,0.81,0.01,1.41,0.73,1.34c0.72-0.07,1.56-0.79,1.87-1.6c0.31-0.81-0.02-1.41-0.73-1.34C194.03,213.3,193.19,214.02,192.88,214.83z",
  "M188.75,215.26c-0.32,0.81,0,1.41,0.72,1.34c0.72-0.07,1.56-0.79,1.88-1.61c0.32-0.81,0-1.41-0.72-1.34C189.91,213.73,189.07,214.45,188.75,215.26z",
  "M184.63,215.7c-0.33,0.81-0.02,1.41,0.7,1.33c0.72-0.08,1.56-0.8,1.88-1.61c0.32-0.81,0.01-1.41-0.7-1.33C185.8,214.17,184.96,214.89,184.63,215.7z",
  "M180.52,216.14c-0.34,0.81-0.03,1.41,0.68,1.33c0.71-0.08,1.56-0.8,1.89-1.61c0.33-0.81,0.03-1.41-0.68-1.33C181.71,214.61,180.86,215.33,180.52,216.14z",
  "M176.43,216.6c-0.34,0.81-0.04,1.41,0.67,1.33c0.71-0.08,1.56-0.8,1.9-1.61c0.34-0.81,0.04-1.41-0.67-1.33C177.63,215.06,176.77,215.79,176.43,216.6z",
  "M172.35,217.06c-0.35,0.81-0.06,1.4,0.65,1.32c0.71-0.08,1.56-0.8,1.91-1.62c0.34-0.81,0.05-1.4-0.65-1.32C173.56,215.52,172.7,216.25,172.35,217.06z",
  "M168.29,217.53c-0.36,0.81-0.07,1.4,0.64,1.32c0.71-0.08,1.57-0.81,1.92-1.62c0.35-0.81,0.07-1.4-0.64-1.32C169.5,215.99,168.64,216.72,168.29,217.53z",
  "M164.23,218c-0.36,0.81-0.09,1.4,0.62,1.32c0.7-0.08,1.57-0.81,1.92-1.62c0.36-0.81,0.08-1.4-0.62-1.32C165.46,216.47,164.6,217.19,164.23,218z",
  "M160.2,218.49c-0.37,0.81-0.1,1.4,0.6,1.32c0.7-0.08,1.57-0.81,1.93-1.62c0.36-0.81,0.09-1.4-0.6-1.32C161.43,216.95,160.57,217.68,160.2,218.49z",
  "M156.17,218.98c-0.38,0.81-0.11,1.4,0.59,1.31c0.7-0.09,1.57-0.81,1.94-1.62c0.37-0.81,0.11-1.4-0.59-1.31C157.42,217.44,156.55,218.17,156.17,218.98z",
  "M152.16,219.48c-0.38,0.81-0.13,1.4,0.57,1.31c0.7-0.09,1.57-0.81,1.94-1.63c0.38-0.81,0.12-1.4-0.57-1.31C153.42,217.94,152.55,218.67,152.16,219.48z",
  "M148.17,219.98c-0.39,0.81-0.14,1.4,0.56,1.31c0.69-0.09,1.57-0.82,1.95-1.63s0.13-1.4-0.55-1.31C149.43,218.44,148.56,219.17,148.17,219.98z",
  "M144.19,220.5c-0.39,0.81-0.15,1.4,0.54,1.31c0.69-0.09,1.57-0.82,1.96-1.63c0.39-0.81,0.15-1.4-0.54-1.31C145.46,218.96,144.59,219.69,144.19,220.5z",
  "M140.23,221.02c-0.4,0.81-0.17,1.39,0.52,1.3c0.69-0.09,1.57-0.82,1.96-1.63c0.4-0.81,0.16-1.39-0.52-1.3C141.51,219.47,140.63,220.21,140.23,221.02z",
  "M136.29,221.54c-0.41,0.81-0.18,1.39,0.51,1.3c0.69-0.09,1.57-0.82,1.97-1.63c0.4-0.81,0.17-1.39-0.51-1.3C137.57,220,136.69,220.73,136.29,221.54z",
  "M132.36,222.08c-0.41,0.81-0.19,1.39,0.49,1.3c0.68-0.09,1.57-0.82,1.97-1.64c0.41-0.81,0.19-1.39-0.49-1.3C133.65,220.53,132.77,221.27,132.36,222.08z",
  "M128.44,222.61c-0.42,0.81-0.2,1.39,0.48,1.3c0.68-0.09,1.57-0.83,1.98-1.64c0.41-0.81,0.2-1.39-0.48-1.3C129.75,221.07,128.86,221.8,128.44,222.61z",
  "M124.55,223.16c-0.42,0.81-0.22,1.39,0.46,1.29c0.68-0.09,1.57-0.83,1.98-1.64c0.42-0.81,0.21-1.39-0.46-1.3C125.86,221.62,124.97,222.35,124.55,223.16z",
  "M120.67,223.71c-0.43,0.81-0.23,1.39,0.45,1.29c0.67-0.1,1.56-0.83,1.99-1.64c0.42-0.81,0.22-1.39-0.45-1.29C121.99,222.17,121.1,222.9,120.67,223.71z",
  "M656.82,222.61c0.5,0.81,1.41,1.54,2.02,1.64c0.62,0.1,0.71-0.47,0.2-1.28c-0.5-0.81-1.41-1.54-2.02-1.64C656.41,221.23,656.32,221.8,656.82,222.61z",
  "M653.26,222.04c0.5,0.81,1.4,1.54,2.02,1.64c0.62,0.1,0.72-0.48,0.21-1.28c-0.5-0.81-1.41-1.54-2.02-1.64C652.86,220.66,652.77,221.24,653.26,222.04z",
  "M635.16,219.28c0.48,0.81,1.38,1.54,2.02,1.63c0.64,0.09,0.76-0.49,0.29-1.29c-0.48-0.81-1.38-1.54-2.01-1.63C634.82,217.9,634.69,218.47,635.16,219.28z",
  "M631.49,218.75c0.47,0.81,1.37,1.54,2.01,1.63c0.64,0.09,0.77-0.49,0.3-1.29c-0.47-0.81-1.38-1.54-2.01-1.63C631.15,217.36,631.01,217.94,631.49,218.75z",
  "M627.79,218.22c0.47,0.81,1.37,1.54,2.01,1.63c0.64,0.09,0.78-0.49,0.31-1.3c-0.47-0.81-1.37-1.54-2.01-1.63C627.46,216.83,627.32,217.41,627.79,218.22z",
  "M624.07,217.69c0.46,0.81,1.36,1.54,2.01,1.63c0.65,0.09,0.79-0.49,0.33-1.3c-0.47-0.81-1.36-1.54-2.01-1.63C623.76,216.3,623.61,216.88,624.07,217.69z",
  "M620.34,217.17c0.46,0.81,1.35,1.54,2,1.63c0.65,0.09,0.8-0.49,0.34-1.3c-0.46-0.81-1.36-1.54-2-1.63C620.04,215.78,619.88,216.36,620.34,217.17z",
  "M616.59,216.66c0.45,0.81,1.35,1.54,2,1.63c0.65,0.09,0.81-0.5,0.36-1.3c-0.45-0.81-1.35-1.54-2-1.63C616.29,215.26,616.13,215.85,616.59,216.66z",
  "M597.56,214.18c0.42,0.81,1.31,1.53,1.98,1.62c0.67,0.08,0.86-0.51,0.44-1.32c-0.43-0.81-1.31-1.53-1.98-1.62C597.33,212.78,597.14,213.37,597.56,214.18z",
  "M578.13,211.87c0.39,0.81,1.27,1.53,1.95,1.61c0.68,0.08,0.91-0.52,0.51-1.33c-0.4-0.81-1.27-1.53-1.95-1.61C577.97,210.46,577.74,211.06,578.13,211.87z",
  "M574.2,211.43c0.39,0.81,1.26,1.53,1.94,1.61c0.69,0.08,0.92-0.52,0.53-1.33c-0.39-0.81-1.26-1.53-1.94-1.61C574.05,210.02,573.81,210.62,574.2,211.43z",
  "M570.25,211c0.38,0.81,1.25,1.53,1.93,1.6c0.69,0.07,0.93-0.52,0.55-1.33c-0.39-0.81-1.25-1.53-1.94-1.6C570.11,209.59,569.87,210.18,570.25,211z",
  "M566.29,210.57c0.37,0.81,1.24,1.53,1.93,1.6c0.69,0.07,0.94-0.52,0.56-1.33c-0.38-0.81-1.24-1.53-1.93-1.6C566.16,209.16,565.91,209.76,566.29,210.57z",
  "M562.31,210.15c0.37,0.81,1.23,1.53,1.92,1.6c0.69,0.07,0.95-0.53,0.58-1.34c-0.37-0.81-1.23-1.53-1.92-1.6C562.2,208.74,561.94,209.34,562.31,210.15z",
  "M558.32,209.74c0.36,0.81,1.22,1.53,1.91,1.6c0.69,0.07,0.96-0.53,0.59-1.34c-0.37-0.81-1.22-1.53-1.91-1.6C558.23,208.33,557.96,208.93,558.32,209.74z",
  "M554.32,209.34c0.36,0.81,1.21,1.52,1.91,1.59c0.7,0.07,0.97-0.53,0.61-1.34c-0.36-0.81-1.21-1.52-1.91-1.59C554.24,207.93,553.96,208.53,554.32,209.34z",
  "M550.3,208.95c0.35,0.81,1.2,1.52,1.9,1.59c0.7,0.07,0.98-0.53,0.63-1.34c-0.35-0.81-1.2-1.52-1.9-1.59C550.24,207.53,549.95,208.13,550.3,208.95z",
  "M546.27,208.56c0.34,0.81,1.19,1.52,1.89,1.59c0.7,0.07,0.99-0.54,0.64-1.35c-0.35-0.81-1.19-1.52-1.89-1.59C546.22,207.15,545.93,207.75,546.27,208.56z",
  "M542.23,208.18c0.33,0.81,1.18,1.52,1.88,1.59c0.7,0.07,1-0.54,0.66-1.35c-0.34-0.81-1.18-1.52-1.88-1.59C542.19,206.77,541.89,207.37,542.23,208.18z",
  "M538.17,207.81c0.33,0.81,1.17,1.52,1.87,1.58c0.71,0.06,1.01-0.54,0.68-1.35c-0.33-0.81-1.17-1.52-1.87-1.58C538.15,206.39,537.85,207,538.17,207.81z",
  "M534.11,207.45c0.32,0.81,1.15,1.52,1.86,1.58c0.71,0.06,1.02-0.54,0.69-1.36c-0.33-0.81-1.16-1.52-1.86-1.58C534.1,206.03,533.79,206.64,534.11,207.45z",
  "M530.03,207.1c0.31,0.81,1.14,1.52,1.85,1.58c0.71,0.06,1.03-0.55,0.71-1.36c-0.32-0.81-1.15-1.52-1.85-1.58C530.03,205.68,529.71,206.29,530.03,207.1z",
  "M525.94,206.75c0.31,0.81,1.13,1.52,1.84,1.58c0.71,0.06,1.04-0.55,0.73-1.36c-0.31-0.81-1.14-1.52-1.85-1.58C525.95,205.33,525.63,205.94,525.94,206.75z",
  "M521.83,206.42c0.3,0.81,1.12,1.51,1.83,1.57c0.71,0.06,1.05-0.55,0.74-1.36c-0.3-0.81-1.13-1.51-1.84-1.57C521.87,205,521.53,205.61,521.83,206.42z",
  "M517.72,206.09c0.29,0.81,1.11,1.51,1.83,1.57c0.72,0.06,1.06-0.55,0.76-1.37c-0.3-0.81-1.11-1.51-1.83-1.57C517.77,204.67,517.43,205.28,517.72,206.09z",
  "M513.59,205.77c0.28,0.81,1.1,1.51,1.82,1.57c0.72,0.05,1.07-0.56,0.78-1.37c-0.29-0.81-1.1-1.51-1.82-1.57C513.66,204.35,513.31,204.96,513.59,205.77z",
  "M509.46,205.46c0.28,0.81,1.09,1.51,1.81,1.56c0.72,0.05,1.08-0.56,0.79-1.37c-0.28-0.81-1.09-1.51-1.81-1.56C509.54,204.04,509.18,204.65,509.46,205.46z",
  "M505.31,205.16c0.27,0.81,1.07,1.51,1.79,1.56c0.72,0.05,1.08-0.56,0.81-1.37c-0.27-0.81-1.08-1.51-1.8-1.56C505.41,203.74,505.05,204.35,505.31,205.16z",
  "M501.16,204.87c0.26,0.81,1.06,1.51,1.78,1.56c0.72,0.05,1.09-0.57,0.83-1.38c-0.27-0.81-1.07-1.51-1.78-1.56C501.27,203.45,500.9,204.06,501.16,204.87z",
  "M496.99,204.59c0.25,0.81,1.05,1.51,1.77,1.56c0.73,0.05,1.1-0.57,0.84-1.38c-0.26-0.81-1.05-1.51-1.77-1.55C497.12,203.16,496.74,203.78,496.99,204.59z",
  "M492.82,204.32c0.25,0.81,1.04,1.51,1.76,1.55c0.73,0.05,1.11-0.57,0.86-1.38c-0.25-0.81-1.04-1.5-1.76-1.55C492.96,202.89,492.57,203.51,492.82,204.32z",
  "M488.64,204.06c0.24,0.81,1.02,1.5,1.75,1.55c0.73,0.05,1.12-0.57,0.88-1.38c-0.24-0.81-1.03-1.5-1.75-1.55C488.79,202.63,488.4,203.25,488.64,204.06z",
  "M484.44,203.8c0.23,0.81,1.01,1.5,1.74,1.55c0.73,0.04,1.13-0.58,0.89-1.39c-0.24-0.81-1.02-1.5-1.74-1.55C484.61,202.37,484.21,202.99,484.44,203.8z",
  "M480.24,203.56c0.22,0.81,1,1.5,1.73,1.54c0.73,0.04,1.14-0.58,0.91-1.39c-0.23-0.81-1-1.5-1.73-1.54C480.43,202.13,480.02,202.75,480.24,203.56z",
  "M476.03,203.32c0.22,0.81,0.98,1.5,1.72,1.54c0.73,0.04,1.15-0.58,0.93-1.39c-0.22-0.81-0.99-1.5-1.72-1.54C476.23,201.89,475.82,202.51,476.03,203.32z",
  "M471.82,203.1c0.21,0.81,0.97,1.5,1.7,1.54c0.74,0.04,1.16-0.59,0.94-1.4c-0.21-0.81-0.98-1.5-1.71-1.54C472.03,201.66,471.61,202.29,471.82,203.1z",
  "M467.59,202.88c0.2,0.81,0.96,1.5,1.69,1.53c0.74,0.04,1.17-0.59,0.96-1.4c-0.21-0.81-0.96-1.5-1.69-1.53C467.82,201.45,467.39,202.07,467.59,202.88z",
  "M463.36,202.68c0.19,0.81,0.94,1.5,1.68,1.53c0.74,0.04,1.18-0.59,0.98-1.4c-0.2-0.81-0.95-1.49-1.68-1.53C463.61,201.24,463.17,201.87,463.36,202.68z",
  "M459.12,202.48c0.18,0.81,0.93,1.49,1.67,1.53c0.74,0.03,1.18-0.6,1-1.41c-0.19-0.81-0.94-1.49-1.67-1.53C459.38,201.04,458.94,201.67,459.12,202.48z",
  "M446.36,201.95c0.16,0.81,0.89,1.49,1.63,1.52c0.74,0.03,1.21-0.6,1.05-1.41c-0.16-0.81-0.9-1.49-1.63-1.52C446.67,200.51,446.2,201.15,446.36,201.95z",
  "M442.1,201.8c0.15,0.81,0.87,1.49,1.62,1.51c0.74,0.03,1.22-0.61,1.06-1.42c-0.16-0.81-0.88-1.49-1.62-1.51C442.42,200.36,441.95,200.99,442.1,201.8z",
  "M437.83,201.65c0.14,0.81,0.86,1.49,1.6,1.51c0.74,0.03,1.23-0.61,1.08-1.42c-0.15-0.81-0.87-1.49-1.6-1.51C438.17,200.21,437.68,200.84,437.83,201.65z",
  "M433.55,201.52c0.13,0.81,0.85,1.48,1.59,1.51c0.74,0.02,1.24-0.61,1.1-1.42c-0.14-0.81-0.85-1.48-1.59-1.51C433.91,200.07,433.42,200.71,433.55,201.52z",
  "M429.27,201.39c0.13,0.81,0.83,1.48,1.58,1.5c0.75,0.02,1.24-0.62,1.11-1.43c-0.13-0.81-0.84-1.48-1.58-1.5C429.64,199.94,429.14,200.58,429.27,201.39z",
  "M424.98,201.28c0.12,0.81,0.82,1.48,1.56,1.5c0.75,0.02,1.25-0.62,1.13-1.43c-0.12-0.81-0.82-1.48-1.56-1.5C425.37,199.83,424.87,200.47,424.98,201.28z",
  "M420.69,201.17c0.11,0.81,0.8,1.48,1.55,1.5c0.75,0.02,1.26-0.62,1.14-1.43c-0.11-0.81-0.81-1.48-1.55-1.5C421.1,199.72,420.58,200.36,420.69,201.17z",
  "M416.4,201.08c0.1,0.81,0.79,1.48,1.54,1.49c0.75,0.02,1.27-0.63,1.16-1.44c-0.11-0.81-0.79-1.48-1.54-1.49C416.82,199.63,416.3,200.27,416.4,201.08z",
  "M412.1,200.99c0.09,0.81,0.77,1.48,1.52,1.49c0.75,0.01,1.28-0.63,1.18-1.44c-0.1-0.81-0.78-1.48-1.52-1.49C412.54,199.54,412.01,200.18,412.1,200.99z",
  "M407.8,200.92c0.08,0.81,0.76,1.47,1.51,1.49c0.75,0.01,1.28-0.63,1.19-1.44c-0.09-0.81-0.77-1.47-1.51-1.49C408.25,199.46,407.72,200.11,407.8,200.92z",
  "M403.5,200.85c0.08,0.81,0.74,1.47,1.49,1.48c0.75,0.01,1.29-0.64,1.21-1.45c-0.08-0.81-0.75-1.47-1.49-1.48C403.96,199.4,403.42,200.05,403.5,200.85z",
  "M399.19,200.8c0.07,0.81,0.73,1.47,1.48,1.48c0.75,0.01,1.3-0.64,1.23-1.45c-0.07-0.81-0.74-1.47-1.48-1.48C399.67,199.34,399.12,199.99,399.19,200.8z",
  "M394.88,200.76c0.06,0.81,0.71,1.47,1.47,1.48c0.75,0.01,1.31-0.64,1.24-1.45c-0.06-0.81-0.72-1.47-1.47-1.48C395.37,199.3,394.82,199.95,394.88,200.76z",
  "M390.56,200.73c0.05,0.81,0.7,1.47,1.45,1.47c0.75,0,1.31-0.65,1.26-1.46c-0.06-0.81-0.71-1.47-1.45-1.47C391.08,199.27,390.51,199.92,390.56,200.73z",
  "M386.25,200.7c0.04,0.81,0.68,1.47,1.44,1.47c0.75,0,1.32-0.65,1.27-1.46c-0.05-0.81-0.69-1.47-1.44-1.47C386.78,199.24,386.21,199.9,386.25,200.7z",
  "M381.93,200.69c0.03,0.81,0.67,1.47,1.42,1.47c0.75,0,1.33-0.65,1.29-1.46c-0.04-0.81-0.68-1.47-1.42-1.47C382.48,199.23,381.9,199.88,381.93,200.69z",
  "M377.61,200.69c0.02,0.81,0.65,1.46,1.41,1.46c0.75,0,1.34-0.66,1.31-1.46c-0.03-0.81-0.66-1.46-1.41-1.46C378.17,199.23,377.59,199.88,377.61,200.69z",
  "M373.29,200.7c0.02,0.81,0.64,1.46,1.39,1.46c0.75,0,1.34-0.66,1.32-1.47c-0.02-0.81-0.65-1.46-1.39-1.46C373.87,199.23,373.28,199.89,373.29,200.7z",
  "M368.97,200.72c0.01,0.81,0.62,1.46,1.38,1.46c0.75,0,1.35-0.66,1.34-1.47c-0.01-0.81-0.63-1.46-1.38-1.46C369.56,199.25,368.96,199.91,368.97,200.72z",
  "M364.65,200.75c0,0.81,0.61,1.46,1.36,1.45c0.75-0.01,1.36-0.67,1.35-1.47c-0.01-0.81-0.61-1.46-1.36-1.45C365.26,199.28,364.65,199.94,364.65,200.75z",
  "M360.33,200.79c-0.01,0.81,0.59,1.46,1.35,1.45c0.75-0.01,1.37-0.67,1.37-1.48c0-0.81-0.6-1.46-1.35-1.45C360.95,199.32,360.34,199.98,360.33,200.79z",
  "M356,200.84c-0.02,0.81,0.58,1.46,1.33,1.45c0.75-0.01,1.37-0.67,1.38-1.48c0.01-0.81-0.58-1.46-1.33-1.45C356.64,199.37,356.02,200.03,356,200.84z",
  "M351.68,200.9c-0.03,0.81,0.56,1.46,1.32,1.44c0.75-0.01,1.38-0.68,1.4-1.48c0.02-0.81-0.57-1.46-1.32-1.44C352.34,199.43,351.71,200.09,351.68,200.9z",
  "M347.36,200.98c-0.03,0.81,0.55,1.45,1.3,1.44c0.75-0.01,1.39-0.68,1.41-1.49c0.03-0.81-0.55-1.45-1.3-1.44C348.03,199.5,347.4,200.17,347.36,200.98z",
  "M343.04,201.06c-0.04,0.81,0.53,1.45,1.28,1.44c0.75-0.01,1.39-0.68,1.43-1.49c0.04-0.81-0.54-1.45-1.28-1.44C343.72,199.58,343.08,200.25,343.04,201.06z",
  "M338.72,201.15c-0.05,0.81,0.52,1.45,1.27,1.44c0.75-0.02,1.4-0.69,1.44-1.49c0.05-0.81-0.52-1.45-1.27-1.43C339.42,199.67,338.77,200.34,338.72,201.15z",
  "M334.4,201.25c-0.06,0.81,0.5,1.45,1.25,1.43c0.75-0.02,1.4-0.69,1.46-1.5c0.05-0.81-0.51-1.45-1.25-1.43C335.12,199.77,334.46,200.44,334.4,201.25z",
  "M330.09,201.37c-0.07,0.81,0.49,1.45,1.24,1.43c0.75-0.02,1.41-0.69,1.47-1.5c0.06-0.81-0.49-1.45-1.24-1.43C330.82,199.89,330.16,200.56,330.09,201.37z",
  "M198.77,209.82c-0.31,0.81,0.02,1.41,0.73,1.34c0.72-0.07,1.54-0.79,1.85-1.6c0.31-0.81-0.02-1.41-0.73-1.34C199.91,208.3,199.09,209.01,198.77,209.82z",
  "M194.67,210.25c-0.32,0.81,0,1.41,0.71,1.34c0.71-0.07,1.55-0.79,1.86-1.6c0.31-0.81-0.01-1.41-0.71-1.33C195.82,208.72,194.99,209.43,194.67,210.25z",
  "M190.58,210.67c-0.33,0.81-0.01,1.41,0.7,1.33c0.71-0.07,1.55-0.79,1.87-1.6c0.32-0.81,0.01-1.41-0.7-1.33C191.74,209.15,190.9,209.86,190.58,210.67z",
  "M186.5,211.11c-0.33,0.81-0.03,1.41,0.68,1.33c0.71-0.08,1.55-0.79,1.88-1.61c0.33-0.81,0.02-1.41-0.68-1.33C187.67,209.58,186.83,210.3,186.5,211.11z",
  "M182.43,211.56c-0.34,0.81-0.04,1.4,0.67,1.33c0.71-0.08,1.55-0.8,1.89-1.61c0.33-0.81,0.04-1.4-0.67-1.33C183.62,210.03,182.77,210.75,182.43,211.56z",
  "M178.38,212.01c-0.35,0.81-0.06,1.4,0.65,1.32c0.71-0.08,1.55-0.8,1.9-1.61c0.34-0.81,0.05-1.4-0.65-1.32C179.57,210.48,178.72,211.2,178.38,212.01z",
  "M174.34,212.47c-0.35,0.81-0.07,1.4,0.63,1.32c0.7-0.08,1.55-0.8,1.9-1.61c0.35-0.81,0.06-1.4-0.63-1.32C175.54,210.94,174.69,211.66,174.34,212.47z",
  "M170.31,212.94c-0.36,0.81-0.08,1.4,0.62,1.32c0.7-0.08,1.56-0.8,1.91-1.62c0.35-0.81,0.08-1.4-0.62-1.32C171.52,211.41,170.67,212.13,170.31,212.94z",
  "M166.29,213.42c-0.37,0.81-0.1,1.4,0.6,1.32c0.7-0.08,1.56-0.81,1.92-1.62c0.36-0.81,0.09-1.4-0.6-1.32C167.52,211.88,166.66,212.61,166.29,213.42z",
  "M162.29,213.9c-0.37,0.81-0.11,1.4,0.59,1.31c0.7-0.08,1.56-0.81,1.93-1.62c0.37-0.81,0.11-1.4-0.59-1.31C163.53,212.37,162.66,213.09,162.29,213.9z",
  "M158.3,214.4c-0.38,0.81-0.12,1.4,0.57,1.31c0.69-0.08,1.56-0.81,1.93-1.62c0.37-0.81,0.12-1.4-0.57-1.31C159.55,212.86,158.68,213.59,158.3,214.4z",
  "M154.33,214.9c-0.38,0.81-0.14,1.4,0.55,1.31c0.69-0.09,1.56-0.81,1.94-1.62c0.38-0.81,0.13-1.4-0.55-1.31C155.58,213.36,154.71,214.08,154.33,214.9z",
  "M150.37,215.4c-0.39,0.81-0.15,1.4,0.54,1.31c0.69-0.09,1.56-0.82,1.95-1.63c0.39-0.81,0.15-1.4-0.54-1.31C151.63,213.86,150.76,214.59,150.37,215.4z",
  "M146.43,215.91c-0.4,0.81-0.16,1.39,0.52,1.3c0.69-0.09,1.56-0.82,1.95-1.63c0.39-0.81,0.16-1.39-0.52-1.3C147.7,214.38,146.82,215.1,146.43,215.91z",
  "M142.5,216.44c-0.4,0.81-0.18,1.39,0.51,1.3c0.68-0.09,1.56-0.82,1.96-1.63c0.4-0.81,0.17-1.39-0.51-1.3C143.78,214.9,142.9,215.63,142.5,216.44z",
  "M138.59,216.96c-0.41,0.81-0.19,1.39,0.49,1.3c0.68-0.09,1.56-0.82,1.96-1.63c0.4-0.81,0.19-1.39-0.49-1.3C139.87,215.42,139,216.15,138.59,216.96z",
  "M134.69,217.5c-0.41,0.81-0.2,1.39,0.48,1.3c0.68-0.09,1.56-0.82,1.97-1.63c0.41-0.81,0.2-1.39-0.48-1.3C135.99,215.96,135.1,216.69,134.69,217.5z",
  "M130.81,218.04c-0.42,0.81-0.21,1.39,0.46,1.3c0.67-0.09,1.56-0.83,1.97-1.64c0.42-0.81,0.21-1.39-0.46-1.3C132.11,216.5,131.23,217.23,130.81,218.04z",
  "M126.94,218.58c-0.43,0.81-0.23,1.39,0.45,1.29c0.67-0.09,1.56-0.83,1.98-1.64c0.42-0.81,0.22-1.39-0.45-1.29C128.26,217.04,127.37,217.77,126.94,218.58z",
  "M123.1,219.14c-0.43,0.81-0.24,1.39,0.43,1.29c0.67-0.1,1.56-0.83,1.98-1.64c0.43-0.81,0.23-1.39-0.43-1.29C124.42,217.59,123.53,218.33,123.1,219.14z",
  "M632.47,214.72c0.48,0.81,1.38,1.54,2.01,1.63c0.63,0.09,0.75-0.48,0.27-1.29c-0.48-0.81-1.38-1.54-2.01-1.63C632.11,213.33,631.99,213.91,632.47,214.72z",
  "M628.81,214.18c0.47,0.81,1.37,1.54,2.01,1.63c0.64,0.09,0.76-0.49,0.29-1.29c-0.48-0.81-1.38-1.54-2.01-1.63C628.47,212.79,628.34,213.37,628.81,214.18z",
  "M625.14,213.65c0.47,0.81,1.37,1.54,2.01,1.63c0.64,0.09,0.77-0.49,0.3-1.29c-0.47-0.81-1.37-1.54-2.01-1.63C624.81,212.26,624.67,212.84,625.14,213.65z",
  "M621.45,213.12c0.46,0.81,1.36,1.54,2,1.63c0.64,0.09,0.78-0.49,0.32-1.3c-0.47-0.81-1.36-1.54-2-1.63C621.13,211.73,620.99,212.31,621.45,213.12z",
  "M617.75,212.6c0.46,0.81,1.36,1.54,2,1.63c0.65,0.09,0.79-0.49,0.33-1.3c-0.46-0.81-1.36-1.54-2-1.63C617.44,211.21,617.29,211.79,617.75,212.6z",
  "M614.03,212.08c0.45,0.81,1.35,1.54,2,1.62c0.65,0.09,0.8-0.49,0.35-1.3c-0.46-0.81-1.35-1.54-1.99-1.62C613.73,210.69,613.57,211.28,614.03,212.08z",
  "M610.29,211.58c0.45,0.81,1.34,1.53,1.99,1.62c0.65,0.09,0.81-0.5,0.36-1.3c-0.45-0.81-1.34-1.53-1.99-1.62C610,210.18,609.84,210.77,610.29,211.58z",
  "M606.53,211.07c0.44,0.81,1.33,1.53,1.99,1.62c0.65,0.09,0.82-0.5,0.37-1.31c-0.45-0.81-1.34-1.53-1.99-1.62C606.25,209.68,606.08,210.27,606.53,211.07z",
  "M602.75,210.58c0.44,0.81,1.33,1.53,1.98,1.62c0.66,0.09,0.83-0.5,0.39-1.31c-0.44-0.81-1.33-1.53-1.98-1.62C602.49,209.18,602.32,209.77,602.75,210.58z",
  "M598.96,210.09c0.43,0.81,1.32,1.53,1.98,1.62c0.66,0.08,0.84-0.5,0.41-1.31c-0.44-0.81-1.32-1.53-1.98-1.62C598.71,208.69,598.53,209.28,598.96,210.09z",
  "M595.16,209.6c0.43,0.81,1.31,1.53,1.97,1.62c0.66,0.08,0.85-0.5,0.42-1.31c-0.43-0.81-1.31-1.53-1.97-1.62C594.92,208.21,594.73,208.8,595.16,209.6z",
  "M575.89,207.29c0.4,0.81,1.27,1.53,1.94,1.61c0.68,0.08,0.9-0.52,0.5-1.32c-0.4-0.81-1.27-1.53-1.94-1.6C575.72,205.89,575.5,206.48,575.89,207.29z",
  "M572,206.85c0.39,0.81,1.26,1.53,1.94,1.6c0.68,0.08,0.91-0.52,0.51-1.33c-0.39-0.81-1.26-1.53-1.94-1.6C571.84,205.45,571.61,206.04,572,206.85z",
  "M568.08,206.42c0.38,0.81,1.25,1.53,1.93,1.6c0.68,0.07,0.92-0.52,0.53-1.33c-0.39-0.81-1.25-1.53-1.93-1.6C567.94,205.01,567.7,205.61,568.08,206.42z",
  "M564.16,205.99c0.38,0.81,1.24,1.52,1.92,1.6c0.68,0.07,0.93-0.52,0.55-1.33c-0.38-0.81-1.24-1.52-1.92-1.6C564.03,204.59,563.78,205.18,564.16,205.99z",
  "M560.22,205.57c0.37,0.81,1.23,1.52,1.91,1.6c0.69,0.07,0.94-0.52,0.56-1.33c-0.38-0.81-1.23-1.52-1.91-1.6C560.1,204.17,559.85,204.77,560.22,205.57z",
  "M556.27,205.16c0.36,0.81,1.22,1.52,1.91,1.59c0.69,0.07,0.95-0.53,0.58-1.34c-0.37-0.81-1.22-1.52-1.91-1.59C556.16,203.76,555.9,204.35,556.27,205.16z",
  "M552.3,204.76c0.36,0.81,1.21,1.52,1.9,1.59c0.69,0.07,0.96-0.53,0.59-1.34c-0.36-0.81-1.21-1.52-1.9-1.59C552.21,203.35,551.94,203.95,552.3,204.76z",
  "M548.32,204.37c0.35,0.81,1.2,1.52,1.89,1.59c0.69,0.07,0.97-0.53,0.61-1.34c-0.36-0.81-1.2-1.52-1.89-1.59C548.24,202.96,547.97,203.56,548.32,204.37z",
  "M544.33,203.98c0.34,0.81,1.19,1.52,1.88,1.59c0.7,0.07,0.98-0.54,0.63-1.34c-0.35-0.81-1.19-1.52-1.88-1.58C544.27,202.57,543.99,203.17,544.33,203.98z",
  "M540.33,203.6c0.34,0.81,1.18,1.52,1.87,1.58c0.7,0.07,0.99-0.54,0.64-1.35c-0.34-0.81-1.18-1.52-1.87-1.58C540.28,202.19,539.99,202.79,540.33,203.6z",
  "M536.31,203.23c0.33,0.81,1.16,1.52,1.86,1.58c0.7,0.06,1-0.54,0.66-1.35c-0.34-0.81-1.17-1.52-1.86-1.58C536.28,201.82,535.98,202.43,536.31,203.23z",
  "M532.28,202.87c0.32,0.81,1.15,1.51,1.86,1.58c0.7,0.06,1-0.54,0.68-1.35c-0.33-0.81-1.16-1.51-1.86-1.58C532.26,201.46,531.96,202.06,532.28,202.87z",
  "M528.24,202.52c0.32,0.81,1.14,1.51,1.85,1.57c0.7,0.06,1.01-0.54,0.69-1.35c-0.32-0.81-1.15-1.51-1.85-1.57C528.24,201.11,527.93,201.71,528.24,202.52z",
  "M524.19,202.18c0.31,0.81,1.13,1.51,1.84,1.57c0.71,0.06,1.02-0.55,0.71-1.36c-0.31-0.81-1.14-1.51-1.84-1.57C524.2,200.76,523.89,201.37,524.19,202.18z",
  "M520.13,201.84c0.3,0.81,1.12,1.51,1.83,1.57c0.71,0.06,1.03-0.55,0.73-1.36c-0.31-0.81-1.13-1.51-1.83-1.57C520.16,200.42,519.83,201.03,520.13,201.84z",
  "M516.06,201.51c0.29,0.81,1.11,1.51,1.82,1.57c0.71,0.06,1.04-0.55,0.74-1.36c-0.3-0.81-1.11-1.51-1.82-1.56C516.1,200.1,515.77,200.71,516.06,201.51z",
  "M511.98,201.2c0.29,0.81,1.1,1.51,1.81,1.56c0.71,0.06,1.05-0.56,0.76-1.36c-0.29-0.81-1.1-1.51-1.81-1.56C512.03,199.78,511.69,200.39,511.98,201.2z",
  "M507.89,200.89c0.28,0.81,1.08,1.51,1.8,1.56c0.71,0.05,1.06-0.56,0.78-1.37c-0.29-0.81-1.09-1.51-1.8-1.56C507.95,199.47,507.61,200.08,507.89,200.89z",
  "M503.78,200.59c0.27,0.81,1.07,1.51,1.79,1.56c0.71,0.05,1.07-0.56,0.79-1.37c-0.28-0.81-1.08-1.5-1.79-1.56C503.87,199.17,503.51,199.78,503.78,200.59z",
  "M499.67,200.3c0.26,0.81,1.06,1.5,1.77,1.55c0.72,0.05,1.08-0.56,0.81-1.37c-0.27-0.81-1.06-1.5-1.78-1.55C499.77,198.88,499.41,199.49,499.67,200.3z",
  "M495.55,200.02c0.26,0.81,1.05,1.5,1.76,1.55c0.72,0.05,1.09-0.57,0.83-1.37c-0.26-0.81-1.05-1.5-1.76-1.55C495.66,198.59,495.29,199.21,495.55,200.02z",
  "M491.42,199.75c0.25,0.81,1.03,1.5,1.75,1.55c0.72,0.05,1.1-0.57,0.84-1.38c-0.25-0.81-1.04-1.5-1.75-1.55C491.55,198.32,491.17,198.94,491.42,199.75z",
  "M487.28,199.48c0.24,0.81,1.02,1.5,1.74,1.54c0.72,0.05,1.11-0.57,0.86-1.38c-0.25-0.81-1.03-1.5-1.74-1.54C487.42,198.06,487.04,198.68,487.28,199.48z",
  "M483.13,199.23c0.23,0.81,1.01,1.5,1.73,1.54c0.72,0.04,1.12-0.58,0.88-1.38c-0.24-0.81-1.01-1.5-1.73-1.54C483.29,197.8,482.9,198.42,483.13,199.23z",
  "M478.97,198.99c0.22,0.81,0.99,1.5,1.72,1.54c0.72,0.04,1.12-0.58,0.89-1.39c-0.23-0.81-1-1.5-1.72-1.54C479.15,197.56,478.75,198.18,478.97,198.99z",
  "M474.81,198.75c0.22,0.81,0.98,1.49,1.71,1.53c0.73,0.04,1.13-0.58,0.91-1.39c-0.22-0.81-0.99-1.49-1.71-1.53C475,197.32,474.59,197.95,474.81,198.75z",
  "M470.64,198.53c0.21,0.81,0.97,1.49,1.69,1.53c0.73,0.04,1.14-0.58,0.93-1.39c-0.22-0.81-0.97-1.49-1.69-1.53C470.84,197.1,470.43,197.72,470.64,198.53z",
  "M466.46,198.31c0.2,0.81,0.95,1.49,1.68,1.53c0.73,0.04,1.15-0.59,0.94-1.39c-0.21-0.81-0.96-1.49-1.68-1.53C466.68,196.88,466.26,197.51,466.46,198.31z",
  "M462.27,198.11c0.19,0.81,0.94,1.49,1.67,1.53c0.73,0.03,1.16-0.59,0.96-1.4c-0.2-0.81-0.95-1.49-1.67-1.52C462.51,196.68,462.08,197.3,462.27,198.11z",
  "M458.08,197.91c0.18,0.81,0.93,1.49,1.66,1.52c0.73,0.03,1.17-0.59,0.98-1.4c-0.19-0.81-0.93-1.49-1.66-1.52C458.33,196.48,457.89,197.11,458.08,197.91z",
  "M453.88,197.73c0.18,0.81,0.91,1.49,1.64,1.52c0.73,0.03,1.18-0.6,0.99-1.4c-0.18-0.81-0.92-1.49-1.64-1.52C454.15,196.29,453.7,196.92,453.88,197.73z",
  "M449.67,197.55c0.17,0.81,0.9,1.48,1.63,1.52c0.73,0.03,1.19-0.6,1.01-1.41c-0.17-0.81-0.91-1.48-1.63-1.51C449.96,196.12,449.5,196.75,449.67,197.55z",
  "M441.24,197.23c0.15,0.81,0.87,1.48,1.61,1.51c0.74,0.03,1.2-0.61,1.04-1.41c-0.16-0.81-0.88-1.48-1.61-1.51C441.56,195.79,441.09,196.43,441.24,197.23z",
  "M437.02,197.09c0.14,0.81,0.86,1.48,1.59,1.51c0.74,0.02,1.21-0.61,1.06-1.42c-0.15-0.81-0.86-1.48-1.59-1.5C437.35,195.65,436.87,196.28,437.02,197.09z",
  "M432.79,196.95c0.14,0.81,0.84,1.48,1.58,1.5c0.74,0.02,1.22-0.61,1.08-1.42c-0.14-0.81-0.85-1.48-1.58-1.5C433.14,195.51,432.65,196.14,432.79,196.95z",
  "M428.55,196.83c0.13,0.81,0.83,1.48,1.56,1.5c0.74,0.02,1.23-0.61,1.09-1.42c-0.13-0.81-0.83-1.48-1.56-1.5C428.92,195.38,428.43,196.02,428.55,196.83z",
  "M424.32,196.71c0.12,0.81,0.81,1.48,1.55,1.5c0.74,0.02,1.24-0.62,1.11-1.42c-0.12-0.81-0.82-1.48-1.55-1.49C424.7,195.27,424.2,195.91,424.32,196.71z",
  "M420.07,196.61c0.11,0.81,0.8,1.47,1.54,1.49c0.74,0.02,1.24-0.62,1.13-1.43c-0.12-0.81-0.8-1.47-1.54-1.49C420.47,195.16,419.96,195.8,420.07,196.61z",
  "M415.83,196.51c0.1,0.81,0.78,1.47,1.52,1.49c0.74,0.02,1.25-0.63,1.14-1.43c-0.11-0.81-0.79-1.47-1.52-1.49C416.24,195.07,415.73,195.71,415.83,196.51z",
  "M411.58,196.43c0.09,0.81,0.77,1.47,1.51,1.49c0.74,0.01,1.26-0.63,1.16-1.43c-0.1-0.81-0.78-1.47-1.51-1.48C412,194.98,411.48,195.62,411.58,196.43z",
  "M407.32,196.35c0.08,0.81,0.75,1.47,1.49,1.48c0.74,0.01,1.27-0.63,1.18-1.44c-0.09-0.81-0.76-1.47-1.49-1.48C407.76,194.91,407.24,195.55,407.32,196.35z",
  "M403.06,196.29c0.08,0.81,0.74,1.47,1.48,1.48c0.74,0.01,1.28-0.63,1.19-1.44c-0.08-0.81-0.75-1.47-1.48-1.48C403.52,194.84,402.99,195.48,403.06,196.29z",
  "M398.8,196.24c0.07,0.81,0.72,1.47,1.47,1.48c0.74,0.01,1.28-0.64,1.21-1.44c-0.07-0.81-0.73-1.47-1.47-1.47C399.28,194.79,398.74,195.43,398.8,196.24z",
  "M394.54,196.19c0.06,0.81,0.71,1.46,1.45,1.47c0.74,0.01,1.29-0.64,1.23-1.45c-0.06-0.81-0.72-1.46-1.45-1.47C395.03,194.74,394.48,195.39,394.54,196.19z",
  "M390.28,196.16c0.05,0.81,0.69,1.46,1.44,1.47c0.74,0.01,1.3-0.64,1.24-1.45c-0.06-0.81-0.7-1.46-1.44-1.47C390.78,194.71,390.23,195.36,390.28,196.16z",
  "M386.01,196.14c0.04,0.81,0.68,1.46,1.42,1.47c0.74,0,1.31-0.65,1.26-1.45c-0.05-0.81-0.68-1.46-1.42-1.46C386.53,194.68,385.97,195.33,386.01,196.14z",
  "M381.74,196.13c0.03,0.81,0.66,1.46,1.41,1.46c0.74,0,1.31-0.65,1.27-1.46c-0.04-0.81-0.67-1.46-1.41-1.46C382.28,194.67,381.71,195.32,381.74,196.13z",
  "M377.47,196.13c0.02,0.81,0.65,1.46,1.39,1.46c0.74,0,1.32-0.65,1.29-1.46c-0.03-0.81-0.65-1.46-1.39-1.46C378.02,194.67,377.45,195.32,377.47,196.13z",
  "M373.2,196.14c0.02,0.81,0.63,1.46,1.38,1.46c0.74,0,1.33-0.66,1.31-1.46c-0.02-0.81-0.64-1.46-1.38-1.45C373.77,194.68,373.18,195.33,373.2,196.14z",
  "M368.93,196.16c0.01,0.81,0.62,1.46,1.36,1.45c0.74,0,1.34-0.66,1.32-1.47c-0.01-0.81-0.62-1.46-1.36-1.45C369.51,194.7,368.92,195.35,368.93,196.16z",
  "M364.65,196.19c0,0.81,0.6,1.45,1.35,1.45c0.74-0.01,1.34-0.66,1.34-1.47c-0.01-0.81-0.61-1.45-1.35-1.45C365.25,194.72,364.65,195.38,364.65,196.19z",
  "M360.38,196.23c-0.01,0.81,0.59,1.45,1.33,1.45c0.74-0.01,1.35-0.67,1.35-1.47c0-0.81-0.59-1.45-1.33-1.44C360.99,194.76,360.39,195.42,360.38,196.23z",
  "M356.1,196.28c-0.02,0.81,0.57,1.45,1.32,1.44c0.74-0.01,1.36-0.67,1.37-1.48c0.01-0.81-0.58-1.45-1.31-1.44C356.74,194.81,356.12,195.47,356.1,196.28z",
  "M351.83,196.34c-0.03,0.81,0.56,1.45,1.3,1.44c0.74-0.01,1.36-0.67,1.38-1.48c0.02-0.81-0.56-1.45-1.3-1.44C352.48,194.87,351.86,195.53,351.83,196.34z",
  "M347.56,196.41c-0.03,0.81,0.54,1.45,1.28,1.44c0.74-0.01,1.37-0.68,1.4-1.48c0.03-0.81-0.55-1.45-1.28-1.44C348.22,194.94,347.59,195.61,347.56,196.41z",
  "M343.28,196.49c-0.04,0.81,0.52,1.45,1.27,1.43c0.74-0.01,1.38-0.68,1.42-1.49c0.04-0.81-0.53-1.45-1.27-1.43C343.96,195.02,343.33,195.69,343.28,196.49z",
  "M339.01,196.59c-0.05,0.81,0.51,1.45,1.25,1.43c0.74-0.02,1.38-0.68,1.43-1.49c0.05-0.81-0.51-1.45-1.25-1.43C339.71,195.11,339.07,195.78,339.01,196.59z",
  "M334.74,196.69c-0.06,0.81,0.49,1.44,1.24,1.43c0.74-0.02,1.39-0.69,1.45-1.49c0.05-0.81-0.5-1.44-1.24-1.43C335.45,195.22,334.8,195.88,334.74,196.69z",
  "M330.47,196.8c-0.07,0.81,0.48,1.44,1.22,1.42c0.74-0.02,1.4-0.69,1.46-1.5c0.06-0.81-0.48-1.44-1.22-1.42C331.2,195.33,330.54,196,330.47,196.8z",
  "M204.62,204.84c-0.31,0.81,0.02,1.41,0.73,1.34c0.71-0.07,1.53-0.78,1.83-1.59c0.3-0.81-0.02-1.41-0.73-1.34C205.75,203.31,204.93,204.03,204.62,204.84z",
  "M200.54,205.25c-0.32,0.81,0,1.41,0.71,1.33c0.71-0.07,1.53-0.79,1.84-1.59c0.31-0.81-0.01-1.41-0.71-1.33C201.69,203.73,200.86,204.44,200.54,205.25z",
  "M196.48,205.67c-0.32,0.81-0.01,1.4,0.7,1.33c0.71-0.07,1.54-0.79,1.85-1.6c0.32-0.81,0.01-1.4-0.7-1.33C197.63,204.15,196.8,204.86,196.48,205.67z",
  "M192.43,206.1c-0.33,0.81-0.02,1.4,0.68,1.33c0.71-0.07,1.54-0.79,1.86-1.6c0.32-0.81,0.02-1.4-0.68-1.33C193.59,204.57,192.76,205.29,192.43,206.1z",
  "M188.39,206.54c-0.34,0.81-0.04,1.4,0.66,1.33c0.7-0.08,1.54-0.79,1.87-1.6c0.33-0.81,0.03-1.4-0.66-1.33C189.56,205.01,188.72,205.73,188.39,206.54z",
  "M184.36,206.98c-0.34,0.81-0.05,1.4,0.65,1.32c0.7-0.08,1.54-0.8,1.88-1.6c0.34-0.81,0.05-1.4-0.65-1.32C185.54,205.45,184.7,206.17,184.36,206.98z",
  "M180.34,207.43c-0.35,0.81-0.07,1.4,0.63,1.32c0.7-0.08,1.54-0.8,1.89-1.61c0.34-0.81,0.06-1.4-0.63-1.32C181.54,205.91,180.69,206.63,180.34,207.43z",
  "M176.34,207.9c-0.36,0.81-0.08,1.4,0.62,1.32c0.7-0.08,1.55-0.8,1.9-1.61c0.35-0.81,0.08-1.4-0.62-1.32C177.54,206.37,176.69,207.09,176.34,207.9z",
  "M172.35,208.37c-0.36,0.81-0.09,1.4,0.6,1.32c0.69-0.08,1.55-0.8,1.9-1.61c0.36-0.81,0.09-1.4-0.6-1.32C173.56,206.84,172.71,207.56,172.35,208.37z",
  "M168.37,208.84c-0.37,0.81-0.11,1.4,0.58,1.31c0.69-0.08,1.55-0.81,1.91-1.61c0.36-0.81,0.1-1.4-0.58-1.31C169.59,207.31,168.74,208.03,168.37,208.84z",
  "M164.4,209.33c-0.38,0.81-0.12,1.4,0.57,1.31c0.69-0.08,1.55-0.81,1.92-1.62c0.37-0.81,0.12-1.4-0.57-1.31C165.64,207.79,164.78,208.52,164.4,209.33z",
  "M160.45,209.82c-0.38,0.81-0.13,1.4,0.55,1.31c0.69-0.09,1.55-0.81,1.93-1.62c0.38-0.81,0.13-1.39-0.55-1.31C161.7,208.29,160.83,209.01,160.45,209.82z",
  "M156.51,210.32c-0.39,0.81-0.15,1.39,0.54,1.31c0.68-0.09,1.55-0.81,1.93-1.62c0.38-0.81,0.14-1.39-0.54-1.31C157.77,208.78,156.9,209.51,156.51,210.32z",
  "M152.59,210.83c-0.39,0.81-0.16,1.39,0.52,1.3c0.68-0.09,1.55-0.82,1.94-1.62c0.39-0.81,0.16-1.39-0.52-1.3C153.85,209.29,152.98,210.02,152.59,210.83z",
  "M148.68,211.34c-0.4,0.81-0.17,1.39,0.51,1.3c0.68-0.09,1.55-0.82,1.95-1.63c0.4-0.81,0.17-1.39-0.51-1.3C149.95,209.8,149.08,210.53,148.68,211.34z",
  "M144.78,211.86c-0.41,0.81-0.19,1.39,0.49,1.3c0.68-0.09,1.55-0.82,1.95-1.63c0.4-0.81,0.18-1.39-0.49-1.3C146.07,210.32,145.19,211.05,144.78,211.86z",
  "M140.9,212.39c-0.41,0.81-0.2,1.39,0.48,1.3c0.67-0.09,1.55-0.82,1.96-1.63c0.41-0.81,0.19-1.39-0.48-1.3C142.19,210.85,141.32,211.58,140.9,212.39z",
  "M137.04,212.92c-0.42,0.81-0.21,1.39,0.46,1.3c0.67-0.09,1.55-0.82,1.96-1.63c0.41-0.81,0.21-1.39-0.46-1.29C138.34,211.39,137.46,212.12,137.04,212.92z",
  "M133.19,213.47c-0.42,0.81-0.22,1.39,0.45,1.29c0.67-0.09,1.55-0.82,1.97-1.63c0.42-0.81,0.22-1.39-0.45-1.29C134.5,211.93,133.61,212.66,133.19,213.47z",
  "M129.36,214.01c-0.43,0.81-0.24,1.39,0.43,1.29c0.67-0.1,1.55-0.83,1.98-1.64c0.42-0.81,0.23-1.39-0.43-1.29C130.67,212.47,129.79,213.2,129.36,214.01z",
  "M125.54,214.57c-0.43,0.81-0.25,1.38,0.42,1.29c0.66-0.1,1.55-0.83,1.98-1.64c0.43-0.81,0.24-1.39-0.42-1.29C126.86,213.03,125.98,213.76,125.54,214.57z",
  "M121.74,215.13c-0.44,0.81-0.26,1.38,0.4,1.29c0.66-0.1,1.55-0.83,1.98-1.64c0.44-0.81,0.26-1.38-0.4-1.29C123.07,213.59,122.18,214.32,121.74,215.13z",
  "M117.96,215.69c-0.44,0.81-0.27,1.38,0.39,1.28c0.66-0.1,1.55-0.83,1.99-1.64c0.44-0.81,0.27-1.38-0.39-1.28C119.29,214.15,118.4,214.89,117.96,215.69z",
  "M633.36,210.7c0.48,0.81,1.38,1.54,2.01,1.63c0.62,0.1,0.74-0.48,0.25-1.29c-0.49-0.81-1.39-1.54-2.01-1.63C632.99,209.32,632.88,209.9,633.36,210.7z",
  "M629.76,210.16c0.48,0.81,1.38,1.54,2.01,1.63c0.63,0.09,0.75-0.48,0.26-1.29c-0.48-0.81-1.38-1.54-2.01-1.63C629.39,208.78,629.27,209.35,629.76,210.16z",
  "M626.13,209.62c0.48,0.81,1.37,1.54,2,1.63c0.63,0.09,0.75-0.49,0.28-1.29c-0.48-0.81-1.38-1.54-2-1.63C625.78,208.24,625.65,208.81,626.13,209.62z",
  "M622.49,209.09c0.47,0.81,1.37,1.54,2,1.63c0.63,0.09,0.76-0.49,0.29-1.29c-0.47-0.81-1.37-1.53-2-1.63C622.15,207.7,622.01,208.28,622.49,209.09z",
  "M615.15,208.04c0.46,0.81,1.35,1.53,1.99,1.62c0.64,0.09,0.78-0.49,0.32-1.3c-0.46-0.81-1.36-1.53-1.99-1.62C614.83,206.65,614.69,207.23,615.15,208.04z",
  "M607.74,207.01c0.45,0.81,1.34,1.53,1.99,1.62c0.65,0.09,0.8-0.49,0.35-1.3c-0.45-0.81-1.34-1.53-1.99-1.62C607.45,205.62,607.29,206.2,607.74,207.01z",
  "M604.01,206.51c0.45,0.81,1.33,1.53,1.98,1.62c0.65,0.09,0.81-0.5,0.36-1.3c-0.45-0.81-1.34-1.53-1.98-1.62C603.73,205.12,603.57,205.7,604.01,206.51z",
  "M596.51,205.52c0.43,0.81,1.32,1.53,1.97,1.61c0.65,0.08,0.83-0.5,0.39-1.31c-0.44-0.81-1.32-1.53-1.97-1.61C596.25,204.13,596.08,204.71,596.51,205.52z",
  "M588.95,204.56c0.42,0.81,1.3,1.53,1.96,1.61c0.66,0.08,0.85-0.5,0.42-1.31c-0.43-0.81-1.31-1.53-1.96-1.61C588.71,203.17,588.53,203.75,588.95,204.56z",
  "M585.14,204.09c0.42,0.81,1.29,1.53,1.96,1.61c0.66,0.08,0.86-0.51,0.44-1.31c-0.42-0.81-1.3-1.53-1.96-1.61C584.92,202.7,584.73,203.28,585.14,204.09z",
  "M577.49,203.17c0.41,0.81,1.28,1.52,1.94,1.6c0.67,0.08,0.88-0.51,0.47-1.32c-0.41-0.81-1.28-1.52-1.94-1.6C577.29,201.78,577.08,202.37,577.49,203.17z",
  "M573.64,202.72c0.4,0.81,1.27,1.52,1.94,1.6c0.67,0.08,0.89-0.51,0.48-1.32c-0.4-0.81-1.27-1.52-1.94-1.6C573.46,201.33,573.24,201.92,573.64,202.72z",
  "M569.78,202.28c0.39,0.81,1.26,1.52,1.93,1.6c0.67,0.08,0.89-0.52,0.5-1.32c-0.4-0.81-1.26-1.52-1.93-1.6C569.61,200.88,569.39,201.48,569.78,202.28z",
  "M565.9,201.85c0.39,0.81,1.25,1.52,1.92,1.6c0.67,0.08,0.91-0.52,0.51-1.33c-0.39-0.81-1.25-1.52-1.92-1.6C565.75,200.45,565.52,201.04,565.9,201.85z",
  "M562.01,201.42c0.38,0.81,1.24,1.52,1.91,1.59c0.68,0.07,0.91-0.52,0.53-1.33c-0.38-0.81-1.24-1.52-1.91-1.59C561.87,200.02,561.63,200.62,562.01,201.42z",
  "M558.11,201.01c0.37,0.81,1.23,1.52,1.91,1.59c0.68,0.07,0.92-0.52,0.55-1.33c-0.38-0.81-1.23-1.52-1.91-1.59C557.98,199.61,557.74,200.2,558.11,201.01z",
  "M554.2,200.6c0.37,0.81,1.22,1.52,1.9,1.59c0.68,0.07,0.93-0.53,0.56-1.33c-0.37-0.81-1.22-1.52-1.9-1.59C554.08,199.19,553.83,199.79,554.2,200.6z",
  "M550.27,200.2c0.36,0.81,1.21,1.52,1.89,1.59c0.68,0.07,0.94-0.53,0.58-1.33c-0.37-0.81-1.21-1.52-1.89-1.59C550.17,198.79,549.91,199.39,550.27,200.2z",
  "M546.33,199.8c0.35,0.81,1.2,1.52,1.88,1.58c0.69,0.07,0.95-0.53,0.59-1.34c-0.36-0.81-1.2-1.51-1.88-1.58C546.24,198.4,545.98,199,546.33,199.8z",
  "M542.38,199.42c0.35,0.81,1.19,1.51,1.87,1.58c0.69,0.07,0.96-0.53,0.61-1.34c-0.35-0.81-1.19-1.51-1.87-1.58C542.3,198.01,542.03,198.61,542.38,199.42z",
  "M538.41,199.04c0.34,0.81,1.17,1.51,1.87,1.58c0.69,0.07,0.97-0.54,0.63-1.34c-0.34-0.81-1.18-1.51-1.87-1.58C538.35,197.63,538.07,198.23,538.41,199.04z",
  "M534.44,198.67c0.33,0.81,1.16,1.51,1.86,1.58c0.69,0.06,0.98-0.54,0.64-1.34c-0.34-0.81-1.17-1.51-1.86-1.57C534.39,197.26,534.1,197.86,534.44,198.67z",
  "M530.45,198.31c0.33,0.81,1.15,1.51,1.85,1.57c0.69,0.06,0.99-0.54,0.66-1.35c-0.33-0.81-1.16-1.51-1.85-1.57C530.42,196.9,530.12,197.5,530.45,198.31z",
  "M526.45,197.96c0.32,0.81,1.14,1.51,1.84,1.57c0.7,0.06,1-0.54,0.68-1.35c-0.32-0.81-1.15-1.51-1.84-1.57C526.43,196.55,526.13,197.15,526.45,197.96z",
  "M522.44,197.61c0.31,0.81,1.13,1.51,1.83,1.57c0.7,0.06,1.01-0.55,0.69-1.35c-0.32-0.81-1.13-1.51-1.83-1.57C522.44,196.2,522.13,196.81,522.44,197.61z",
  "M518.42,197.28c0.3,0.81,1.12,1.51,1.82,1.56c0.7,0.06,1.02-0.55,0.71-1.35c-0.31-0.81-1.12-1.5-1.82-1.56C518.43,195.87,518.12,196.47,518.42,197.28z",
  "M514.39,196.95c0.3,0.8,1.11,1.5,1.81,1.56c0.7,0.06,1.03-0.55,0.72-1.36c-0.3-0.81-1.11-1.5-1.81-1.56C514.42,195.54,514.09,196.15,514.39,196.95z",
  "M510.35,196.64c0.29,0.81,1.09,1.5,1.8,1.56c0.7,0.05,1.04-0.55,0.74-1.36c-0.29-0.81-1.1-1.5-1.8-1.56C510.39,195.22,510.06,195.83,510.35,196.64z",
  "M506.3,196.33c0.28,0.8,1.08,1.5,1.79,1.55c0.71,0.05,1.05-0.56,0.76-1.36c-0.29-0.81-1.09-1.5-1.79-1.55C506.36,194.92,506.02,195.52,506.3,196.33z",
  "M502.24,196.03c0.27,0.8,1.07,1.5,1.78,1.55c0.71,0.05,1.05-0.56,0.78-1.36c-0.28-0.8-1.08-1.5-1.78-1.55C502.31,194.62,501.97,195.23,502.24,196.03z",
  "M498.17,195.74c0.27,0.8,1.06,1.5,1.76,1.55c0.71,0.05,1.06-0.56,0.79-1.37c-0.27-0.8-1.06-1.5-1.77-1.55C498.26,194.32,497.9,194.94,498.17,195.74z",
  "M494.09,195.46c0.26,0.8,1.04,1.5,1.75,1.54c0.71,0.05,1.07-0.56,0.81-1.37c-0.26-0.8-1.05-1.5-1.75-1.54C494.2,194.04,493.83,194.66,494.09,195.46z",
  "M490,195.19c0.25,0.8,1.03,1.49,1.74,1.54c0.71,0.05,1.08-0.57,0.83-1.37c-0.26-0.8-1.04-1.49-1.74-1.54C490.12,193.77,489.75,194.38,490,195.19z",
  "M485.91,194.93c0.24,0.8,1.02,1.49,1.73,1.54c0.71,0.05,1.09-0.57,0.84-1.38c-0.25-0.8-1.02-1.49-1.73-1.54C486.04,193.51,485.67,194.12,485.91,194.93z",
  "M481.81,194.67c0.23,0.8,1,1.49,1.72,1.54c0.72,0.04,1.1-0.57,0.86-1.38c-0.24-0.8-1.01-1.49-1.72-1.53C481.96,193.25,481.57,193.87,481.81,194.67z",
  "M477.69,194.43c0.23,0.8,0.99,1.49,1.71,1.53c0.72,0.04,1.11-0.58,0.88-1.38c-0.23-0.8-1-1.49-1.71-1.53C477.86,193.01,477.47,193.63,477.69,194.43z",
  "M473.58,194.2c0.22,0.8,0.98,1.49,1.7,1.53c0.72,0.04,1.12-0.58,0.89-1.38c-0.22-0.8-0.98-1.49-1.7-1.53C473.76,192.78,473.36,193.39,473.58,194.2z",
  "M469.45,193.97c0.21,0.8,0.96,1.49,1.68,1.53c0.72,0.04,1.13-0.58,0.91-1.39c-0.22-0.8-0.97-1.49-1.68-1.52C469.65,192.55,469.24,193.17,469.45,193.97z",
  "M465.31,193.76c0.2,0.8,0.95,1.49,1.67,1.52c0.72,0.04,1.14-0.59,0.93-1.39c-0.21-0.8-0.96-1.48-1.67-1.52C465.53,192.34,465.11,192.96,465.31,193.76z",
  "M461.17,193.56c0.19,0.8,0.94,1.48,1.66,1.52c0.72,0.04,1.14-0.59,0.94-1.39c-0.2-0.8-0.94-1.48-1.66-1.52C461.4,192.13,460.98,192.75,461.17,193.56z",
  "M457.03,193.36c0.19,0.8,0.92,1.48,1.65,1.52c0.72,0.03,1.15-0.59,0.96-1.39c-0.19-0.8-0.93-1.48-1.65-1.51C457.27,191.94,456.84,192.56,457.03,193.36z",
  "M452.87,193.18c0.18,0.8,0.91,1.48,1.63,1.51c0.72,0.03,1.16-0.59,0.98-1.4c-0.18-0.8-0.91-1.48-1.63-1.51C453.13,191.75,452.7,192.37,452.87,193.18z",
  "M448.71,193c0.17,0.8,0.89,1.48,1.62,1.51c0.72,0.03,1.17-0.6,0.99-1.4c-0.18-0.8-0.9-1.48-1.62-1.51C448.99,191.57,448.54,192.2,448.71,193z",
  "M444.55,192.84c0.16,0.8,0.88,1.48,1.61,1.51c0.73,0.03,1.18-0.6,1.01-1.4c-0.17-0.8-0.89-1.48-1.61-1.5C444.84,191.41,444.39,192.03,444.55,192.84z",
  "M440.38,192.68c0.15,0.8,0.87,1.48,1.59,1.5c0.73,0.03,1.19-0.6,1.03-1.41c-0.16-0.8-0.87-1.47-1.59-1.5C440.68,191.25,440.22,191.88,440.38,192.68z",
  "M436.2,192.54c0.14,0.8,0.85,1.47,1.58,1.5c0.73,0.02,1.2-0.61,1.04-1.41c-0.15-0.8-0.86-1.47-1.58-1.5C436.52,191.1,436.06,191.74,436.2,192.54z",
  "M432.02,192.4c0.14,0.8,0.84,1.47,1.57,1.5c0.73,0.02,1.2-0.61,1.06-1.41c-0.14-0.8-0.84-1.47-1.57-1.49C432.36,190.97,431.88,191.6,432.02,192.4z",
  "M427.83,192.28c0.13,0.8,0.82,1.47,1.55,1.49c0.73,0.02,1.21-0.61,1.08-1.42c-0.13-0.8-0.83-1.47-1.55-1.49C428.19,190.84,427.71,191.48,427.83,192.28z",
  "M423.64,192.16c0.12,0.8,0.81,1.47,1.54,1.49c0.73,0.02,1.22-0.62,1.09-1.42c-0.13-0.8-0.81-1.47-1.54-1.49C424.01,190.73,423.52,191.36,423.64,192.16z",
  "M419.45,192.06c0.11,0.8,0.79,1.47,1.52,1.49c0.73,0.02,1.23-0.62,1.11-1.42c-0.12-0.8-0.8-1.47-1.52-1.48C419.83,190.62,419.34,191.26,419.45,192.06z",
  "M415.25,191.97c0.1,0.8,0.78,1.47,1.51,1.48c0.73,0.02,1.24-0.62,1.13-1.42c-0.11-0.8-0.78-1.47-1.51-1.48C415.65,190.53,415.15,191.16,415.25,191.97z",
  "M411.05,191.88c0.09,0.8,0.76,1.46,1.5,1.48c0.73,0.01,1.24-0.63,1.14-1.43c-0.1-0.8-0.77-1.46-1.5-1.48C411.46,190.44,410.95,191.08,411.05,191.88z",
  "M402.63,191.75c0.08,0.8,0.73,1.46,1.47,1.47c0.73,0.01,1.26-0.63,1.18-1.43c-0.08-0.8-0.74-1.46-1.47-1.47C403.08,190.3,402.55,190.94,402.63,191.75z",
  "M398.42,191.69c0.07,0.8,0.72,1.46,1.45,1.47c0.73,0.01,1.27-0.63,1.19-1.44c-0.07-0.8-0.72-1.46-1.45-1.47C398.89,190.25,398.35,190.89,398.42,191.69z",
  "M394.2,191.65c0.06,0.8,0.7,1.46,1.44,1.47c0.73,0.01,1.28-0.64,1.21-1.44c-0.07-0.8-0.71-1.46-1.44-1.46C394.69,190.2,394.15,190.85,394.2,191.65z",
  "M389.99,191.62c0.05,0.8,0.69,1.46,1.42,1.46c0.73,0.01,1.28-0.64,1.23-1.44c-0.06-0.8-0.69-1.46-1.42-1.46C390.49,190.17,389.94,190.82,389.99,191.62z",
  "M377.33,191.58c0.02,0.8,0.64,1.45,1.38,1.45c0.74,0,1.31-0.65,1.28-1.45c-0.03-0.8-0.65-1.45-1.38-1.45C377.87,190.13,377.3,190.78,377.33,191.58z",
  "M373.1,191.59c0.02,0.8,0.63,1.45,1.36,1.45c0.74,0,1.31-0.65,1.29-1.46c-0.02-0.8-0.63-1.45-1.36-1.45C373.67,190.14,373.09,190.79,373.1,191.59z",
  "M368.88,191.61c0.01,0.8,0.61,1.45,1.35,1.45c0.74,0,1.32-0.66,1.31-1.46c-0.01-0.8-0.62-1.45-1.35-1.44C369.46,190.16,368.87,190.81,368.88,191.61z",
  "M364.66,191.64c0,0.8,0.59,1.45,1.33,1.44c0.74-0.01,1.33-0.66,1.32-1.46c-0.01-0.8-0.6-1.45-1.33-1.44C365.25,190.19,364.66,190.84,364.66,191.64z",
  "M360.43,191.68c-0.01,0.8,0.58,1.45,1.31,1.44c0.74-0.01,1.34-0.66,1.34-1.47c0-0.8-0.59-1.45-1.31-1.44C361.04,190.23,360.44,190.88,360.43,191.68z",
  "M356.21,191.73c-0.02,0.8,0.56,1.45,1.3,1.44c0.74-0.01,1.34-0.67,1.35-1.47c0.01-0.8-0.57-1.44-1.3-1.44C356.83,190.27,356.22,190.93,356.21,191.73z",
  "M351.98,191.79c-0.03,0.8,0.55,1.44,1.28,1.43c0.74-0.01,1.35-0.67,1.37-1.47c0.02-0.8-0.55-1.44-1.28-1.43C352.62,190.33,352.01,190.99,351.98,191.79z",
  "M347.76,191.87c-0.04,0.8,0.53,1.44,1.27,1.43c0.74-0.01,1.36-0.67,1.39-1.48c0.03-0.8-0.54-1.44-1.27-1.43C348.41,190.4,347.79,191.06,347.76,191.87z",
  "M343.53,191.95c-0.04,0.8,0.52,1.44,1.25,1.43c0.74-0.01,1.36-0.68,1.4-1.48c0.04-0.8-0.52-1.44-1.25-1.43C344.2,190.48,343.58,191.15,343.53,191.95z",
  "M339.31,192.04c-0.05,0.8,0.5,1.44,1.24,1.42c0.74-0.02,1.37-0.68,1.42-1.48c0.05-0.8-0.51-1.44-1.24-1.42C340,190.58,339.36,191.24,339.31,192.04z",
  "M335.09,192.14c-0.06,0.8,0.48,1.44,1.22,1.42c0.74-0.02,1.38-0.68,1.43-1.49c0.06-0.8-0.49-1.44-1.22-1.42C335.79,190.68,335.15,191.34,335.09,192.14z",
  "M206.36,200.27c-0.31,0.81,0.01,1.4,0.71,1.33s1.52-0.78,1.83-1.59c0.31-0.81-0.01-1.4-0.71-1.33C207.49,198.76,206.68,199.47,206.36,200.27z",
  "M202.33,200.68c-0.32,0.81-0.01,1.4,0.7,1.33c0.7-0.07,1.52-0.78,1.84-1.59c0.31-0.81,0-1.4-0.7-1.33C203.47,199.17,202.65,199.88,202.33,200.68z",
  "M198.31,201.1c-0.33,0.81-0.02,1.4,0.68,1.33c0.7-0.07,1.53-0.79,1.85-1.59c0.32-0.81,0.02-1.4-0.68-1.33C199.46,199.58,198.63,200.3,198.31,201.1z",
  "M194.29,201.53c-0.33,0.81-0.03,1.4,0.66,1.33c0.7-0.07,1.53-0.79,1.86-1.6c0.33-0.81,0.03-1.4-0.66-1.32C195.46,200.01,194.63,200.73,194.29,201.53z",
  "M190.29,201.97c-0.34,0.81-0.05,1.4,0.65,1.32c0.7-0.08,1.53-0.79,1.86-1.6c0.33-0.81,0.04-1.4-0.65-1.32C191.47,200.45,190.63,201.16,190.29,201.97z",
  "M186.3,202.41c-0.35,0.81-0.06,1.4,0.63,1.32c0.69-0.08,1.53-0.79,1.87-1.6c0.34-0.81,0.06-1.4-0.63-1.32C187.49,200.89,186.65,201.61,186.3,202.41z",
  "M182.32,202.87c-0.35,0.81-0.08,1.4,0.62,1.32c0.69-0.08,1.53-0.8,1.88-1.6c0.35-0.81,0.07-1.4-0.62-1.32C183.52,201.34,182.68,202.06,182.32,202.87z",
  "M178.36,203.33c-0.36,0.81-0.09,1.4,0.6,1.32c0.69-0.08,1.54-0.8,1.89-1.61c0.35-0.81,0.09-1.4-0.6-1.31C179.56,201.8,178.72,202.52,178.36,203.33z",
  "M174.4,203.8c-0.37,0.81-0.1,1.4,0.58,1.31c0.69-0.08,1.54-0.8,1.9-1.61c0.36-0.81,0.1-1.39-0.58-1.31C175.62,202.27,174.77,202.99,174.4,203.8z",
  "M170.46,204.28c-0.37,0.81-0.12,1.39,0.57,1.31c0.69-0.08,1.54-0.8,1.91-1.61c0.37-0.81,0.11-1.39-0.57-1.31C171.69,202.75,170.83,203.47,170.46,204.28z",
  "M166.53,204.76c-0.38,0.81-0.13,1.39,0.55,1.31c0.68-0.08,1.54-0.81,1.91-1.61c0.37-0.81,0.13-1.39-0.55-1.31C167.77,203.23,166.91,203.95,166.53,204.76z",
  "M162.62,205.25c-0.38,0.81-0.14,1.39,0.54,1.31c0.68-0.09,1.54-0.81,1.92-1.62c0.38-0.81,0.14-1.39-0.54-1.31C163.86,203.72,163,204.45,162.62,205.25z",
  "M158.71,205.75c-0.39,0.81-0.16,1.39,0.52,1.3c0.68-0.09,1.54-0.81,1.93-1.62c0.39-0.81,0.15-1.39-0.52-1.3C159.97,204.22,159.1,204.95,158.71,205.75z",
  "M154.82,206.26c-0.4,0.81-0.17,1.39,0.51,1.3c0.68-0.09,1.54-0.81,1.94-1.62c0.39-0.81,0.17-1.39-0.51-1.3C156.09,204.73,155.22,205.45,154.82,206.26z",
  "M150.95,206.77c-0.4,0.81-0.18,1.39,0.49,1.3c0.67-0.09,1.54-0.82,1.94-1.62c0.4-0.81,0.18-1.39-0.49-1.3C152.22,205.24,151.35,205.97,150.95,206.77z",
  "M147.09,207.3c-0.41,0.81-0.2,1.39,0.48,1.3c0.67-0.09,1.54-0.82,1.95-1.63c0.4-0.81,0.19-1.39-0.48-1.3C148.37,205.76,147.49,206.49,147.09,207.3z",
  "M143.24,207.82c-0.41,0.81-0.21,1.39,0.46,1.29c0.67-0.09,1.54-0.82,1.95-1.63c0.41-0.81,0.2-1.39-0.46-1.29C144.53,206.29,143.65,207.02,143.24,207.82z",
  "M139.41,208.36c-0.42,0.81-0.22,1.39,0.45,1.29c0.67-0.09,1.54-0.82,1.96-1.63c0.42-0.81,0.22-1.39-0.45-1.29C140.71,206.82,139.83,207.55,139.41,208.36z",
  "M135.59,208.9c-0.43,0.81-0.23,1.38,0.43,1.29c0.66-0.09,1.54-0.82,1.97-1.63c0.42-0.81,0.23-1.38-0.43-1.29C136.9,207.36,136.02,208.1,135.59,208.9z",
  "M131.79,209.45c-0.43,0.81-0.25,1.38,0.42,1.29c0.66-0.1,1.54-0.83,1.97-1.63c0.43-0.81,0.24-1.38-0.42-1.29C133.1,207.91,132.22,208.64,131.79,209.45z",
  "M128,210c-0.44,0.81-0.26,1.38,0.4,1.29c0.66-0.1,1.54-0.83,1.98-1.63c0.43-0.81,0.25-1.38-0.4-1.29C129.32,208.47,128.44,209.2,128,210z",
  "M124.23,210.57c-0.44,0.81-0.27,1.38,0.39,1.28c0.66-0.1,1.54-0.83,1.98-1.64c0.44-0.81,0.27-1.38-0.39-1.28C125.56,209.03,124.67,209.76,124.23,210.57z",
  "M120.48,211.13c-0.45,0.81-0.28,1.38,0.37,1.28c0.65-0.1,1.54-0.83,1.98-1.64c0.44-0.81,0.28-1.38-0.37-1.28C121.81,209.59,120.92,210.33,120.48,211.13z",
  "M641.26,207.82c0.5,0.8,1.4,1.53,2.01,1.63c0.61,0.1,0.7-0.47,0.2-1.28s-1.4-1.53-2.01-1.63C640.85,206.45,640.76,207.02,641.26,207.82z",
  "M608.87,202.96c0.46,0.81,1.35,1.53,1.99,1.62c0.64,0.09,0.78-0.49,0.32-1.3c-0.46-0.81-1.35-1.53-1.98-1.62C608.55,201.58,608.41,202.16,608.87,202.96z",
  "M582.78,199.53c0.42,0.8,1.29,1.52,1.95,1.6c0.66,0.08,0.85-0.51,0.42-1.31c-0.42-0.8-1.3-1.52-1.95-1.6C582.55,198.14,582.36,198.73,582.78,199.53z",
  "M571.38,198.17c0.4,0.8,1.27,1.52,1.93,1.6c0.66,0.08,0.87-0.51,0.47-1.32c-0.41-0.8-1.27-1.52-1.93-1.6C571.18,196.78,570.97,197.36,571.38,198.17z",
  "M567.55,197.73c0.4,0.8,1.26,1.52,1.92,1.59c0.67,0.08,0.88-0.51,0.48-1.32c-0.4-0.8-1.26-1.52-1.92-1.59C567.37,196.33,567.15,196.93,567.55,197.73z",
  "M563.71,197.3c0.39,0.8,1.25,1.52,1.91,1.59c0.67,0.07,0.89-0.52,0.5-1.32c-0.39-0.8-1.25-1.52-1.92-1.59C563.54,195.9,563.32,196.49,563.71,197.3z",
  "M559.86,196.87c0.38,0.8,1.24,1.52,1.91,1.59c0.67,0.07,0.9-0.52,0.51-1.32c-0.39-0.8-1.24-1.51-1.91-1.59C559.7,195.47,559.47,196.07,559.86,196.87z",
  "M555.99,196.45c0.38,0.8,1.23,1.51,1.9,1.59c0.67,0.07,0.91-0.52,0.53-1.33c-0.38-0.8-1.23-1.51-1.9-1.59C555.85,195.06,555.62,195.65,555.99,196.45z",
  "M552.11,196.05c0.37,0.8,1.22,1.51,1.89,1.58c0.68,0.07,0.92-0.52,0.55-1.33c-0.37-0.8-1.22-1.51-1.89-1.58C551.99,194.65,551.74,195.24,552.11,196.05z",
  "M548.22,195.64c0.36,0.8,1.21,1.51,1.88,1.58c0.68,0.07,0.93-0.53,0.56-1.33c-0.37-0.8-1.21-1.51-1.88-1.58C548.11,194.25,547.86,194.84,548.22,195.64z",
  "M544.32,195.25c0.36,0.8,1.19,1.51,1.87,1.58c0.68,0.07,0.94-0.53,0.58-1.33c-0.36-0.8-1.2-1.51-1.87-1.58C544.22,193.85,543.97,194.45,544.32,195.25z",
  "M540.41,194.87c0.35,0.8,1.18,1.51,1.87,1.58c0.68,0.07,0.95-0.53,0.59-1.33c-0.35-0.8-1.19-1.51-1.87-1.57C540.32,193.47,540.06,194.06,540.41,194.87z",
  "M536.48,194.49c0.34,0.8,1.17,1.51,1.86,1.57c0.68,0.07,0.96-0.53,0.61-1.34c-0.35-0.8-1.18-1.51-1.86-1.57C536.41,193.09,536.14,193.69,536.48,194.49z",
  "M532.55,194.12c0.34,0.8,1.16,1.51,1.85,1.57c0.69,0.06,0.97-0.54,0.63-1.34c-0.34-0.8-1.17-1.51-1.85-1.57C532.49,192.72,532.21,193.32,532.55,194.12z",
  "M528.6,193.76c0.33,0.8,1.15,1.5,1.84,1.57c0.69,0.06,0.97-0.54,0.64-1.34c-0.33-0.8-1.16-1.5-1.84-1.57C528.56,192.36,528.27,192.96,528.6,193.76z",
  "M524.64,193.41c0.32,0.8,1.14,1.5,1.83,1.56c0.69,0.06,0.98-0.54,0.66-1.34c-0.33-0.8-1.14-1.5-1.83-1.56C524.62,192.01,524.32,192.61,524.64,193.41z",
  "M520.67,193.07c0.31,0.8,1.13,1.5,1.82,1.56c0.69,0.06,0.99-0.54,0.68-1.35c-0.32-0.8-1.13-1.5-1.82-1.56C520.66,191.66,520.36,192.27,520.67,193.07z",
  "M516.69,192.73c0.31,0.8,1.12,1.5,1.81,1.56c0.69,0.06,1-0.55,0.69-1.35c-0.31-0.8-1.12-1.5-1.81-1.56C516.7,191.33,516.39,191.93,516.69,192.73z",
  "M512.71,192.41c0.3,0.8,1.1,1.5,1.8,1.55c0.7,0.06,1.01-0.55,0.71-1.35c-0.3-0.8-1.11-1.5-1.8-1.55C512.72,191,512.41,191.61,512.71,192.41z",
  "M508.71,192.09c0.29,0.8,1.09,1.5,1.79,1.55c0.7,0.05,1.02-0.55,0.72-1.35c-0.3-0.8-1.1-1.5-1.79-1.55C508.74,190.69,508.42,191.29,508.71,192.09z",
  "M504.7,191.79c0.28,0.8,1.08,1.5,1.78,1.55c0.7,0.05,1.03-0.55,0.74-1.36c-0.29-0.8-1.08-1.49-1.78-1.55C504.75,190.38,504.42,190.99,504.7,191.79z",
  "M500.68,191.49c0.28,0.8,1.07,1.49,1.77,1.54c0.7,0.05,1.04-0.56,0.76-1.36c-0.28-0.8-1.07-1.49-1.77-1.54C500.75,190.08,500.41,190.69,500.68,191.49z",
  "M496.66,191.2c0.27,0.8,1.05,1.49,1.75,1.54c0.7,0.05,1.05-0.56,0.77-1.36c-0.27-0.8-1.06-1.49-1.76-1.54C496.74,189.79,496.39,190.4,496.66,191.2z",
  "M492.62,190.92c0.26,0.8,1.04,1.49,1.74,1.54c0.7,0.05,1.06-0.56,0.79-1.36c-0.27-0.8-1.05-1.49-1.74-1.54C492.72,189.51,492.36,190.12,492.62,190.92z",
  "M488.58,190.65c0.25,0.8,1.03,1.49,1.73,1.54c0.7,0.05,1.07-0.57,0.81-1.37c-0.26-0.8-1.03-1.49-1.73-1.53C488.69,189.24,488.33,189.85,488.58,190.65z",
  "M484.53,190.39c0.24,0.8,1.01,1.49,1.72,1.53c0.71,0.04,1.08-0.57,0.83-1.37c-0.25-0.8-1.02-1.49-1.72-1.53C484.65,188.98,484.29,189.59,484.53,190.39z",
  "M480.47,190.14c0.24,0.8,1,1.49,1.71,1.53c0.71,0.04,1.08-0.57,0.84-1.37c-0.24-0.8-1.01-1.48-1.71-1.53C480.61,188.72,480.24,189.34,480.47,190.14z",
  "M476.41,189.9c0.23,0.8,0.99,1.48,1.7,1.53c0.71,0.04,1.09-0.57,0.86-1.37c-0.23-0.8-0.99-1.48-1.7-1.52C476.56,188.48,476.18,189.1,476.41,189.9z",
  "M472.33,189.66c0.22,0.8,0.97,1.48,1.68,1.52c0.71,0.04,1.1-0.58,0.88-1.38c-0.23-0.8-0.98-1.48-1.68-1.52C472.5,188.25,472.11,188.86,472.33,189.66z",
  "M468.25,189.44c0.21,0.8,0.96,1.48,1.67,1.52c0.71,0.04,1.11-0.58,0.89-1.38c-0.22-0.8-0.97-1.48-1.67-1.52C468.44,188.02,468.04,188.64,468.25,189.44z",
  "M464.16,189.23c0.2,0.8,0.95,1.48,1.66,1.52c0.71,0.04,1.12-0.58,0.91-1.38c-0.21-0.8-0.95-1.48-1.66-1.51C464.37,187.81,463.96,188.43,464.16,189.23z",
  "M460.07,189.02c0.2,0.8,0.93,1.48,1.65,1.51c0.71,0.03,1.13-0.59,0.93-1.39c-0.2-0.8-0.94-1.48-1.65-1.51C460.29,187.6,459.87,188.22,460.07,189.02z",
  "M455.97,188.83c0.19,0.8,0.92,1.48,1.63,1.51c0.71,0.03,1.14-0.59,0.94-1.39c-0.19-0.8-0.93-1.47-1.63-1.51C456.2,187.41,455.78,188.03,455.97,188.83z",
  "M451.86,188.65c0.18,0.8,0.9,1.47,1.62,1.51c0.72,0.03,1.15-0.59,0.96-1.39c-0.19-0.8-0.91-1.47-1.62-1.5C452.11,187.23,451.68,187.85,451.86,188.65z",
  "M447.75,188.47c0.17,0.8,0.89,1.47,1.61,1.5c0.72,0.03,1.15-0.59,0.98-1.39c-0.18-0.8-0.9-1.47-1.61-1.5C448.01,187.05,447.58,187.67,447.75,188.47z",
  "M443.63,188.31c0.16,0.8,0.88,1.47,1.59,1.5c0.72,0.03,1.16-0.6,0.99-1.4c-0.17-0.8-0.88-1.47-1.59-1.5C443.91,186.89,443.47,187.51,443.63,188.31z",
  "M439.51,188.15c0.15,0.8,0.86,1.47,1.58,1.5c0.72,0.03,1.17-0.6,1.01-1.4c-0.16-0.8-0.87-1.47-1.58-1.49C439.8,186.73,439.35,187.36,439.51,188.15z",
  "M435.38,188.01c0.15,0.8,0.85,1.47,1.57,1.49c0.72,0.02,1.18-0.6,1.03-1.4c-0.15-0.8-0.85-1.47-1.57-1.49C435.69,186.58,435.23,187.21,435.38,188.01z",
  "M431.24,187.88c0.14,0.8,0.83,1.47,1.55,1.49c0.72,0.02,1.19-0.61,1.04-1.41c-0.14-0.8-0.84-1.46-1.55-1.49C431.57,186.45,431.11,187.08,431.24,187.88z",
  "M427.11,187.75c0.13,0.8,0.82,1.46,1.54,1.49c0.72,0.02,1.2-0.61,1.06-1.41c-0.13-0.8-0.82-1.46-1.54-1.48C427.45,186.32,426.98,186.95,427.11,187.75z",
  "M422.96,187.64c0.12,0.8,0.8,1.46,1.52,1.48c0.72,0.02,1.2-0.61,1.08-1.41c-0.13-0.8-0.81-1.46-1.52-1.48C423.33,186.21,422.84,186.84,422.96,187.64z",
  "M418.82,187.53c0.11,0.8,0.79,1.46,1.51,1.48c0.72,0.02,1.21-0.62,1.09-1.41c-0.12-0.8-0.79-1.46-1.51-1.48C419.19,186.1,418.7,186.74,418.82,187.53z",
  "M368.84,187.09c0.01,0.8,0.6,1.44,1.33,1.44c0.73,0,1.31-0.65,1.29-1.45c-0.01-0.8-0.61-1.44-1.33-1.44C369.41,185.64,368.83,186.29,368.84,187.09z",
  "M364.66,187.12c0,0.8,0.59,1.44,1.31,1.44c0.73-0.01,1.31-0.66,1.31-1.46c-0.01-0.8-0.59-1.44-1.31-1.43C365.25,185.67,364.66,186.32,364.66,187.12z",
  "M360.48,187.16c-0.01,0.8,0.57,1.44,1.3,1.43c0.73-0.01,1.32-0.66,1.32-1.46c0-0.8-0.58-1.44-1.3-1.43C361.09,185.71,360.49,186.36,360.48,187.16z",
  "M356.31,187.21c-0.02,0.8,0.56,1.44,1.28,1.43c0.73-0.01,1.33-0.66,1.34-1.46c0.01-0.8-0.56-1.44-1.28-1.43C356.93,185.76,356.33,186.41,356.31,187.21z",
  "M352.13,187.27c-0.03,0.8,0.54,1.44,1.27,1.43c0.73-0.01,1.33-0.67,1.36-1.47c0.02-0.8-0.55-1.44-1.27-1.42C352.77,185.82,352.16,186.47,352.13,187.27z",
  "M347.96,187.34c-0.04,0.8,0.52,1.44,1.25,1.42c0.73-0.01,1.34-0.67,1.37-1.47c0.03-0.8-0.53-1.43-1.25-1.42C348.61,185.89,347.99,186.54,347.96,187.34z",
  "M343.78,187.42c-0.04,0.8,0.51,1.43,1.24,1.42c0.73-0.01,1.35-0.67,1.39-1.47c0.04-0.8-0.51-1.43-1.24-1.42C344.45,185.97,343.83,186.63,343.78,187.42z",
  "M339.61,187.52c-0.05,0.8,0.49,1.43,1.22,1.42c0.73-0.02,1.36-0.68,1.4-1.48c0.05-0.8-0.5-1.43-1.22-1.42C340.29,186.06,339.66,186.72,339.61,187.52z",
  "M208.13,195.72c-0.31,0.8,0,1.4,0.69,1.33c0.7-0.07,1.51-0.78,1.82-1.58c0.31-0.8,0-1.4-0.69-1.33C209.25,194.21,208.44,194.92,208.13,195.72z",
  "M204.13,196.13c-0.32,0.8-0.02,1.4,0.68,1.33c0.69-0.07,1.51-0.78,1.83-1.58c0.32-0.8,0.01-1.4-0.68-1.32C205.27,194.62,204.45,195.33,204.13,196.13z",
  "M200.15,196.55c-0.33,0.8-0.03,1.4,0.66,1.32c0.69-0.07,1.52-0.78,1.84-1.59c0.32-0.8,0.03-1.4-0.66-1.32C201.3,195.04,200.48,195.75,200.15,196.55z",
  "M196.18,196.98c-0.33,0.8-0.05,1.4,0.65,1.32c0.69-0.07,1.52-0.79,1.85-1.59c0.33-0.8,0.04-1.39-0.65-1.32C197.34,195.47,196.51,196.18,196.18,196.98z",
  "M192.21,197.42c-0.34,0.8-0.06,1.39,0.63,1.32c0.69-0.08,1.52-0.79,1.86-1.59c0.34-0.8,0.05-1.39-0.63-1.32C193.39,195.9,192.56,196.61,192.21,197.42z",
  "M188.26,197.86c-0.35,0.8-0.07,1.39,0.61,1.32c0.69-0.08,1.52-0.79,1.87-1.6c0.34-0.8,0.07-1.39-0.61-1.32C189.45,196.34,188.61,197.06,188.26,197.86z",
  "M184.32,198.32c-0.35,0.8-0.09,1.39,0.6,1.31c0.69-0.08,1.52-0.79,1.87-1.6c0.35-0.8,0.08-1.39-0.6-1.31C185.52,196.8,184.68,197.51,184.32,198.32z",
  "M180.39,198.78c-0.36,0.8-0.1,1.39,0.58,1.31c0.68-0.08,1.53-0.8,1.88-1.6c0.36-0.8,0.1-1.39-0.58-1.31C181.6,197.25,180.75,197.97,180.39,198.78z",
  "M176.47,199.24c-0.37,0.8-0.11,1.39,0.57,1.31c0.68-0.08,1.53-0.8,1.89-1.6c0.36-0.8,0.11-1.39-0.57-1.31C177.69,197.72,176.84,198.44,176.47,199.24z",
  "M172.57,199.72c-0.37,0.8-0.13,1.39,0.55,1.31c0.68-0.08,1.53-0.8,1.9-1.61c0.37-0.8,0.12-1.39-0.55-1.31C173.79,198.2,172.94,198.92,172.57,199.72z",
  "M168.68,200.21c-0.38,0.8-0.14,1.39,0.54,1.3c0.68-0.08,1.53-0.8,1.91-1.61c0.38-0.8,0.14-1.39-0.54-1.3C169.91,198.68,169.06,199.4,168.68,200.21z",
  "M164.8,200.7c-0.39,0.8-0.15,1.39,0.52,1.3c0.68-0.09,1.53-0.81,1.92-1.61c0.38-0.8,0.15-1.39-0.52-1.3C166.04,199.17,165.18,199.89,164.8,200.7z",
  "M160.93,201.2c-0.39,0.8-0.17,1.39,0.51,1.3c0.67-0.09,1.53-0.81,1.92-1.61c0.39-0.8,0.16-1.39-0.51-1.3C162.18,199.67,161.32,200.39,160.93,201.2z",
  "M157.07,201.7c-0.4,0.8-0.18,1.39,0.49,1.3c0.67-0.09,1.53-0.81,1.93-1.62c0.39-0.8,0.18-1.39-0.49-1.3C158.34,200.18,157.47,200.9,157.07,201.7z",
  "M153.23,202.22c-0.41,0.8-0.19,1.39,0.48,1.3c0.67-0.09,1.53-0.81,1.94-1.62c0.4-0.8,0.19-1.38-0.48-1.3C154.5,200.69,153.64,201.41,153.23,202.22z",
  "M149.4,202.74c-0.41,0.8-0.21,1.38,0.46,1.29c0.67-0.09,1.54-0.82,1.94-1.62c0.41-0.8,0.2-1.38-0.46-1.29C150.68,201.21,149.81,201.93,149.4,202.74z",
  "M145.59,203.27c-0.42,0.8-0.22,1.38,0.45,1.29c0.66-0.09,1.54-0.82,1.95-1.62c0.41-0.8,0.21-1.38-0.45-1.29C146.88,201.74,146.01,202.46,145.59,203.27z",
  "M141.79,203.8c-0.42,0.8-0.23,1.38,0.43,1.29c0.66-0.09,1.54-0.82,1.95-1.63c0.42-0.8,0.23-1.38-0.43-1.29C143.09,202.27,142.21,203,141.79,203.8z",
  "M138,204.35c-0.43,0.8-0.24,1.38,0.42,1.29c0.66-0.09,1.54-0.82,1.96-1.63c0.42-0.81,0.24-1.38-0.42-1.29C139.31,202.81,138.43,203.54,138,204.35z",
  "M134.23,204.89c-0.43,0.8-0.25,1.38,0.4,1.28c0.66-0.1,1.54-0.83,1.97-1.63c0.43-0.81,0.25-1.38-0.4-1.28C135.55,203.36,134.67,204.09,134.23,204.89z",
  "M130.48,205.45c-0.44,0.81-0.27,1.38,0.39,1.28c0.65-0.1,1.54-0.83,1.97-1.63c0.44-0.8,0.26-1.38-0.39-1.28C131.8,203.91,130.92,204.65,130.48,205.45z",
  "M126.74,206.01c-0.44,0.81-0.28,1.38,0.37,1.28c0.65-0.1,1.53-0.83,1.98-1.63c0.44-0.8,0.27-1.38-0.37-1.28C128.06,204.48,127.18,205.21,126.74,206.01z",
  "M62.32,217.06c-0.52,0.8-0.46,1.36,0.14,1.24c0.59-0.11,1.49-0.85,2.01-1.65c0.52-0.8,0.45-1.36-0.14-1.24C63.73,215.53,62.83,216.27,62.32,217.06z",
  "M620.73,200.52c0.48,0.8,1.37,1.53,1.99,1.62c0.62,0.09,0.74-0.48,0.25-1.28c-0.48-0.8-1.38-1.53-1.99-1.62C620.36,199.14,620.25,199.72,620.73,200.52z",
  "M609.91,198.94c0.46,0.8,1.35,1.53,1.98,1.62c0.63,0.09,0.76-0.49,0.29-1.29c-0.47-0.8-1.36-1.53-1.98-1.62C609.58,197.55,609.45,198.13,609.91,198.94z",
  "M584.14,195.46c0.43,0.8,1.3,1.52,1.95,1.6c0.65,0.08,0.82-0.5,0.39-1.3c-0.43-0.8-1.3-1.52-1.95-1.6C583.88,194.07,583.71,194.66,584.14,195.46z",
  "M580.4,194.99c0.42,0.8,1.29,1.52,1.94,1.6c0.65,0.08,0.83-0.5,0.41-1.31c-0.43-0.8-1.29-1.52-1.94-1.6C580.16,193.6,579.97,194.19,580.4,194.99z",
  "M576.64,194.53c0.42,0.8,1.28,1.52,1.94,1.6c0.65,0.08,0.84-0.51,0.42-1.31c-0.42-0.8-1.29-1.52-1.94-1.6C576.42,193.14,576.23,193.73,576.64,194.53z",
  "M572.88,194.07c0.41,0.8,1.27,1.52,1.93,1.6c0.66,0.08,0.85-0.51,0.44-1.31c-0.42-0.8-1.28-1.52-1.93-1.59C572.67,192.69,572.47,193.27,572.88,194.07z",
  "M569.1,193.63c0.4,0.8,1.26,1.51,1.92,1.59c0.66,0.08,0.86-0.51,0.45-1.31c-0.41-0.8-1.27-1.51-1.92-1.59C568.9,192.24,568.7,192.83,569.1,193.63z",
  "M565.31,193.19c0.4,0.8,1.25,1.51,1.92,1.59c0.66,0.08,0.87-0.51,0.47-1.31c-0.4-0.8-1.26-1.51-1.91-1.59C565.12,191.8,564.91,192.39,565.31,193.19z",
  "M561.5,192.76c0.39,0.8,1.25,1.51,1.91,1.59c0.66,0.07,0.88-0.51,0.48-1.32c-0.4-0.8-1.25-1.51-1.91-1.59C561.33,191.37,561.11,191.96,561.5,192.76z",
  "M557.69,192.33c0.38,0.8,1.24,1.51,1.9,1.58c0.66,0.07,0.89-0.52,0.5-1.32c-0.39-0.8-1.24-1.51-1.9-1.58C557.53,190.94,557.3,191.53,557.69,192.33z",
  "M553.86,191.92c0.38,0.8,1.22,1.51,1.89,1.58c0.67,0.07,0.9-0.52,0.51-1.32c-0.38-0.8-1.23-1.51-1.89-1.58C553.71,190.53,553.48,191.12,553.86,191.92z",
  "M550.02,191.51c0.37,0.8,1.21,1.51,1.88,1.58c0.67,0.07,0.91-0.52,0.53-1.32c-0.38-0.8-1.22-1.51-1.88-1.58C549.88,190.12,549.65,190.71,550.02,191.51z",
  "M546.17,191.11c0.36,0.8,1.2,1.51,1.88,1.58c0.67,0.07,0.91-0.52,0.55-1.33c-0.37-0.8-1.21-1.51-1.87-1.57C546.05,189.72,545.8,190.31,546.17,191.11z",
  "M542.3,190.72c0.36,0.8,1.19,1.51,1.87,1.57c0.67,0.07,0.92-0.53,0.56-1.33c-0.36-0.8-1.2-1.5-1.87-1.57C542.2,189.32,541.94,189.92,542.3,190.72z",
  "M538.43,190.33c0.35,0.8,1.18,1.5,1.86,1.57c0.68,0.07,0.93-0.53,0.58-1.33c-0.36-0.8-1.19-1.5-1.86-1.57C538.34,188.94,538.08,189.53,538.43,190.33z",
  "M534.54,189.96c0.34,0.8,1.17,1.5,1.85,1.57c0.68,0.07,0.94-0.53,0.59-1.33c-0.35-0.8-1.18-1.5-1.85-1.57C534.46,188.56,534.2,189.16,534.54,189.96z",
  "M530.65,189.59c0.34,0.8,1.16,1.5,1.84,1.56c0.68,0.06,0.95-0.53,0.61-1.33c-0.34-0.8-1.17-1.5-1.84-1.56C530.58,188.19,530.31,188.79,530.65,189.59z",
  "M526.74,189.23c0.33,0.8,1.15,1.5,1.83,1.56c0.68,0.06,0.96-0.54,0.63-1.34c-0.33-0.8-1.15-1.5-1.83-1.56C526.69,187.84,526.41,188.43,526.74,189.23z",
  "M522.82,188.88c0.32,0.8,1.14,1.5,1.82,1.56c0.68,0.06,0.97-0.54,0.64-1.34c-0.33-0.8-1.14-1.5-1.82-1.56C522.79,187.48,522.5,188.08,522.82,188.88z",
  "M518.89,188.54c0.31,0.8,1.12,1.5,1.81,1.55c0.68,0.06,0.98-0.54,0.66-1.34c-0.32-0.8-1.13-1.49-1.81-1.55C518.87,187.14,518.58,187.74,518.89,188.54z",
  "M514.96,188.21c0.31,0.8,1.11,1.49,1.8,1.55c0.69,0.06,0.99-0.54,0.68-1.34c-0.31-0.8-1.12-1.49-1.8-1.55C514.95,186.81,514.65,187.41,514.96,188.21z",
  "M511.01,187.89c0.3,0.8,1.1,1.49,1.79,1.55c0.69,0.06,1-0.55,0.69-1.35c-0.31-0.8-1.11-1.49-1.79-1.55C511.02,186.48,510.71,187.09,511.01,187.89z",
  "M507.06,187.57c0.29,0.8,1.09,1.49,1.78,1.54c0.69,0.05,1.01-0.55,0.71-1.35c-0.3-0.8-1.09-1.49-1.78-1.54C507.08,186.17,506.76,186.77,507.06,187.57z",
  "M503.09,187.26c0.29,0.8,1.08,1.49,1.77,1.54c0.69,0.05,1.01-0.55,0.72-1.35c-0.29-0.8-1.08-1.49-1.77-1.54C503.13,185.86,502.81,186.47,503.09,187.26z",
  "M499.12,186.97c0.28,0.8,1.06,1.49,1.76,1.54c0.69,0.05,1.02-0.55,0.74-1.35c-0.28-0.8-1.07-1.49-1.76-1.54C499.17,185.57,498.84,186.17,499.12,186.97z",
  "M495.14,186.68c0.27,0.8,1.05,1.49,1.74,1.53c0.69,0.05,1.03-0.56,0.76-1.36c-0.28-0.8-1.06-1.48-1.74-1.53C495.21,185.28,494.87,185.88,495.14,186.68z",
  "M491.15,186.4c0.26,0.8,1.04,1.48,1.73,1.53c0.7,0.05,1.04-0.56,0.77-1.36c-0.27-0.8-1.04-1.48-1.73-1.53C491.23,185,490.88,185.6,491.15,186.4z",
  "M487.15,186.13c0.25,0.8,1.02,1.48,1.72,1.53c0.7,0.05,1.05-0.56,0.79-1.36c-0.26-0.8-1.03-1.48-1.72-1.53C487.25,184.73,486.89,185.34,487.15,186.13z",
  "M483.14,185.87c0.25,0.8,1.01,1.48,1.71,1.52c0.7,0.04,1.06-0.57,0.81-1.36c-0.25-0.8-1.02-1.48-1.71-1.52C483.26,184.47,482.9,185.08,483.14,185.87z",
  "M479.13,185.62c0.24,0.8,1,1.48,1.7,1.52c0.7,0.04,1.07-0.57,0.82-1.37c-0.24-0.8-1-1.48-1.7-1.52C479.26,184.22,478.89,184.83,479.13,185.62z",
  "M475.11,185.38c0.23,0.8,0.98,1.48,1.68,1.52c0.7,0.04,1.08-0.57,0.84-1.37c-0.24-0.8-0.99-1.48-1.68-1.52C475.26,183.97,474.88,184.59,475.11,185.38z",
  "M471.08,185.15c0.22,0.8,0.97,1.47,1.67,1.51c0.7,0.04,1.09-0.57,0.86-1.37c-0.23-0.8-0.98-1.47-1.67-1.51C471.24,183.74,470.86,184.35,471.08,185.15z",
  "M467.04,184.93c0.21,0.8,0.96,1.47,1.66,1.51c0.7,0.04,1.09-0.58,0.88-1.37c-0.22-0.8-0.96-1.47-1.66-1.51C467.22,183.52,466.83,184.13,467.04,184.93z",
  "M463,184.72c0.2,0.8,0.94,1.47,1.65,1.51c0.7,0.04,1.1-0.58,0.89-1.38c-0.21-0.8-0.95-1.47-1.65-1.51C463.2,183.31,462.8,183.92,463,184.72z",
  "M458.96,184.51c0.2,0.8,0.93,1.47,1.63,1.5c0.71,0.03,1.11-0.58,0.91-1.38c-0.2-0.8-0.93-1.47-1.63-1.5C459.17,183.1,458.76,183.72,458.96,184.51z",
  "M454.9,184.32c0.19,0.8,0.91,1.47,1.62,1.5c0.71,0.03,1.12-0.59,0.93-1.38c-0.19-0.8-0.92-1.47-1.62-1.5C455.13,182.91,454.71,183.53,454.9,184.32z",
  "M450.84,184.14c0.18,0.8,0.9,1.47,1.61,1.5c0.71,0.03,1.13-0.59,0.94-1.38c-0.19-0.8-0.91-1.46-1.61-1.5C451.08,182.73,450.66,183.34,450.84,184.14z",
  "M446.78,183.97c0.17,0.79,0.89,1.46,1.59,1.49c0.71,0.03,1.14-0.59,0.96-1.39c-0.18-0.8-0.89-1.46-1.59-1.49C447.03,182.55,446.6,183.17,446.78,183.97z",
  "M442.71,183.8c0.16,0.79,0.87,1.46,1.58,1.49c0.71,0.03,1.15-0.59,0.98-1.39c-0.17-0.79-0.88-1.46-1.58-1.49C442.98,182.39,442.54,183.01,442.71,183.8z",
  "M438.63,183.65c0.15,0.79,0.86,1.46,1.57,1.49c0.71,0.03,1.16-0.6,0.99-1.39c-0.16-0.8-0.86-1.46-1.57-1.49C438.92,182.23,438.47,182.85,438.63,183.65z",
  "M434.55,183.51c0.15,0.79,0.84,1.46,1.55,1.48c0.71,0.02,1.16-0.6,1.01-1.4c-0.15-0.79-0.85-1.46-1.55-1.48C434.85,182.09,434.4,182.71,434.55,183.51z",
  "M430.46,183.37c0.14,0.79,0.83,1.46,1.54,1.48c0.71,0.02,1.17-0.6,1.03-1.4c-0.14-0.79-0.83-1.46-1.54-1.48C430.79,181.95,430.33,182.58,430.46,183.37z",
  "M426.37,183.25c0.13,0.79,0.81,1.46,1.52,1.48c0.71,0.02,1.18-0.61,1.04-1.4c-0.14-0.79-0.82-1.45-1.52-1.48C426.71,181.83,426.24,182.45,426.37,183.25z",
  "M422.28,183.14c0.12,0.79,0.8,1.45,1.51,1.47c0.71,0.02,1.19-0.61,1.06-1.4c-0.13-0.79-0.8-1.45-1.51-1.47C422.63,181.71,422.16,182.34,422.28,183.14z",
  "M418.18,183.03c0.11,0.79,0.78,1.45,1.5,1.47c0.71,0.02,1.2-0.61,1.08-1.41c-0.12-0.79-0.79-1.45-1.5-1.47C418.55,181.61,418.07,182.24,418.18,183.03z",
  "M414.08,182.94c0.1,0.79,0.77,1.45,1.48,1.47c0.71,0.02,1.2-0.62,1.1-1.41c-0.11-0.79-0.77-1.45-1.48-1.47C414.47,181.52,413.98,182.15,414.08,182.94z",
  "M405.87,182.78c0.09,0.79,0.74,1.45,1.45,1.46c0.72,0.01,1.22-0.62,1.13-1.42c-0.09-0.79-0.74-1.45-1.45-1.46C406.29,181.36,405.78,181.99,405.87,182.78z",
  "M397.64,182.67c0.07,0.79,0.71,1.45,1.42,1.45c0.72,0.01,1.24-0.63,1.16-1.42c-0.07-0.79-0.71-1.44-1.42-1.45C398.09,181.24,397.57,181.88,397.64,182.67z",
  "M368.79,182.59c0.01,0.79,0.6,1.43,1.31,1.43c0.72,0,1.29-0.65,1.28-1.44c-0.01-0.79-0.6-1.43-1.31-1.43C369.36,181.15,368.78,181.8,368.79,182.59z",
  "M364.67,182.62c0,0.79,0.58,1.43,1.3,1.43c0.72-0.01,1.3-0.65,1.29-1.45c0-0.79-0.59-1.43-1.3-1.43C365.25,181.18,364.67,181.83,364.67,182.62z",
  "M360.54,182.66c-0.01,0.79,0.56,1.43,1.28,1.42c0.72-0.01,1.3-0.66,1.31-1.45c0-0.79-0.57-1.43-1.28-1.42C361.14,181.22,360.55,181.87,360.54,182.66z",
  "M356.41,182.71c-0.02,0.79,0.55,1.43,1.27,1.42c0.72-0.01,1.31-0.66,1.32-1.45c0.01-0.79-0.55-1.43-1.27-1.42C357.03,181.27,356.43,181.92,356.41,182.71z",
  "M352.29,182.77c-0.03,0.79,0.53,1.43,1.25,1.42c0.72-0.01,1.32-0.66,1.34-1.46c0.02-0.79-0.54-1.43-1.25-1.42C352.92,181.33,352.31,181.98,352.29,182.77z",
  "M225.8,189.64c-0.29,0.8,0.05,1.4,0.74,1.33c0.7-0.06,1.49-0.76,1.77-1.56c0.28-0.8-0.05-1.39-0.74-1.33C226.88,188.14,226.09,188.84,225.8,189.64z",
  "M221.81,190.01c-0.29,0.8,0.03,1.39,0.73,1.33c0.69-0.07,1.49-0.77,1.78-1.57c0.29-0.8-0.04-1.39-0.73-1.33C222.9,188.51,222.11,189.21,221.81,190.01z",
  "M217.83,190.4c-0.3,0.8,0.02,1.39,0.71,1.33c0.69-0.07,1.49-0.77,1.79-1.57c0.3-0.8-0.02-1.39-0.71-1.33C218.94,188.9,218.13,189.6,217.83,190.4z",
  "M213.86,190.79c-0.31,0.8,0,1.39,0.69,1.32c0.69-0.07,1.5-0.77,1.8-1.57c0.3-0.8-0.01-1.39-0.69-1.32C214.98,189.29,214.17,189.99,213.86,190.79z",
  "M209.9,191.19c-0.32,0.8-0.01,1.39,0.68,1.32c0.69-0.07,1.5-0.78,1.81-1.58c0.31-0.8,0.01-1.39-0.68-1.32C211.03,189.69,210.22,190.39,209.9,191.19z",
  "M205.95,191.6c-0.32,0.8-0.03,1.39,0.66,1.32c0.69-0.07,1.5-0.78,1.82-1.58c0.32-0.8,0.02-1.39-0.66-1.32C207.09,190.09,206.27,190.8,205.95,191.6z",
  "M202,192.02c-0.33,0.8-0.04,1.39,0.65,1.32c0.69-0.07,1.51-0.78,1.83-1.58c0.32-0.8,0.04-1.39-0.65-1.32C203.15,190.51,202.33,191.22,202,192.02z",
  "M198.07,192.45c-0.34,0.8-0.05,1.39,0.63,1.32c0.68-0.07,1.51-0.78,1.84-1.58c0.33-0.8,0.05-1.39-0.63-1.31C199.23,190.94,198.41,191.65,198.07,192.45z",
  "M194.15,192.88c-0.34,0.8-0.07,1.39,0.61,1.31c0.68-0.08,1.51-0.79,1.85-1.59c0.34-0.8,0.06-1.39-0.61-1.31C195.32,191.37,194.49,192.08,194.15,192.88z",
  "M190.23,193.33c-0.35,0.8-0.08,1.39,0.6,1.31c0.68-0.08,1.51-0.79,1.86-1.59c0.34-0.8,0.08-1.39-0.6-1.31C191.42,191.81,190.59,192.52,190.23,193.33z",
  "M186.33,193.78c-0.36,0.8-0.1,1.39,0.58,1.31c0.68-0.08,1.51-0.79,1.87-1.59c0.35-0.8,0.09-1.39-0.58-1.31C187.53,192.26,186.69,192.98,186.33,193.78z",
  "M182.44,194.24c-0.36,0.8-0.11,1.39,0.57,1.31c0.68-0.08,1.52-0.8,1.88-1.6c0.36-0.8,0.11-1.39-0.57-1.31C183.64,192.72,182.81,193.44,182.44,194.24z",
  "M178.56,194.7c-0.37,0.8-0.12,1.39,0.55,1.3c0.67-0.08,1.52-0.8,1.88-1.6c0.37-0.8,0.12-1.39-0.55-1.3C179.78,193.19,178.93,193.9,178.56,194.7z",
  "M174.69,195.18c-0.38,0.8-0.14,1.39,0.54,1.3c0.67-0.08,1.52-0.8,1.89-1.6c0.37-0.8,0.13-1.38-0.54-1.3C175.92,193.66,175.07,194.38,174.69,195.18z",
  "M170.83,195.66c-0.38,0.8-0.15,1.38,0.52,1.3c0.67-0.08,1.52-0.8,1.9-1.6c0.38-0.8,0.15-1.38-0.52-1.3C172.07,194.14,171.22,194.86,170.83,195.66z",
  "M166.99,196.16c-0.39,0.8-0.16,1.38,0.51,1.3c0.67-0.09,1.52-0.81,1.91-1.61c0.39-0.8,0.16-1.38-0.51-1.3C168.23,194.63,167.38,195.35,166.99,196.16z",
  "M163.16,196.65c-0.4,0.8-0.18,1.38,0.49,1.3c0.67-0.09,1.52-0.81,1.92-1.61c0.39-0.8,0.17-1.38-0.49-1.3C164.41,195.13,163.55,195.85,163.16,196.65z",
  "M159.34,197.16c-0.4,0.8-0.19,1.38,0.48,1.29c0.66-0.09,1.53-0.81,1.92-1.61c0.4-0.8,0.18-1.38-0.48-1.29C160.6,195.64,159.74,196.36,159.34,197.16z",
  "M155.53,197.67c-0.41,0.8-0.2,1.38,0.46,1.29c0.66-0.09,1.53-0.81,1.93-1.62c0.4-0.8,0.2-1.38-0.46-1.29C156.8,196.15,155.94,196.87,155.53,197.67z",
  "M151.73,198.2c-0.41,0.8-0.21,1.38,0.45,1.29c0.66-0.09,1.53-0.82,1.94-1.62c0.41-0.8,0.21-1.38-0.45-1.29C153.01,196.67,152.15,197.39,151.73,198.2z",
  "M147.95,198.72c-0.42,0.8-0.23,1.38,0.43,1.29c0.66-0.09,1.53-0.82,1.94-1.62c0.42-0.8,0.22-1.38-0.43-1.29C149.24,197.2,148.37,197.92,147.95,198.72z",
  "M144.18,199.26c-0.43,0.8-0.24,1.38,0.42,1.29c0.66-0.09,1.53-0.82,1.95-1.62c0.42-0.8,0.23-1.38-0.42-1.28C145.48,197.73,144.61,198.46,144.18,199.26z",
  "M140.43,199.8c-0.43,0.8-0.25,1.38,0.4,1.28c0.65-0.09,1.53-0.82,1.95-1.62c0.43-0.8,0.25-1.38-0.4-1.28C141.74,198.27,140.86,199,140.43,199.8z",
  "M136.69,200.35c-0.44,0.8-0.26,1.38,0.39,1.28c0.65-0.1,1.53-0.82,1.96-1.63c0.43-0.8,0.26-1.38-0.39-1.28C138,198.82,137.13,199.55,136.69,200.35z",
  "M132.96,200.9c-0.44,0.8-0.27,1.38,0.37,1.28c0.65-0.1,1.53-0.83,1.96-1.63c0.44-0.8,0.27-1.38-0.37-1.28C134.28,199.37,133.41,200.1,132.96,200.9z",
  "M129.25,201.47c-0.45,0.8-0.29,1.38,0.36,1.28c0.65-0.1,1.53-0.83,1.97-1.63c0.44-0.8,0.28-1.37-0.36-1.28C130.58,199.93,129.7,200.66,129.25,201.47z",
  "M125.56,202.04c-0.45,0.8-0.3,1.37,0.35,1.28c0.64-0.1,1.53-0.83,1.97-1.63c0.45-0.8,0.29-1.37-0.35-1.27C126.89,200.5,126.01,201.23,125.56,202.04z",
  "M121.87,202.61c-0.46,0.8-0.31,1.37,0.33,1.27c0.64-0.1,1.53-0.83,1.98-1.64c0.45-0.8,0.3-1.37-0.33-1.27C123.22,201.08,122.33,201.81,121.87,202.61z",
  "M65.24,212.55c-0.52,0.8-0.46,1.35,0.13,1.24c0.59-0.11,1.49-0.85,2-1.65c0.52-0.8,0.46-1.36-0.13-1.24C66.66,211.01,65.76,211.75,65.24,212.55z",
  "M625.1,197.07c0.49,0.8,1.38,1.53,1.99,1.62c0.61,0.1,0.71-0.48,0.22-1.28c-0.49-0.8-1.38-1.53-1.99-1.62C624.71,195.7,624.61,196.27,625.1,197.07z",
  "M621.57,196.53c0.48,0.8,1.38,1.53,1.99,1.62c0.62,0.09,0.72-0.48,0.23-1.28c-0.49-0.8-1.38-1.53-1.99-1.62C621.19,195.15,621.08,195.73,621.57,196.53z",
  "M607.28,194.4c0.47,0.8,1.35,1.52,1.98,1.61c0.63,0.09,0.75-0.49,0.28-1.29c-0.47-0.8-1.36-1.52-1.98-1.61C606.94,193.02,606.81,193.6,607.28,194.4z",
  "M592.75,192.38c0.45,0.8,1.32,1.52,1.96,1.61c0.64,0.09,0.79-0.49,0.34-1.29c-0.45-0.8-1.33-1.52-1.96-1.6C592.46,191,592.31,191.58,592.75,192.38z",
  "M581.71,190.93c0.43,0.8,1.3,1.52,1.94,1.6c0.64,0.08,0.81-0.5,0.38-1.3c-0.43-0.8-1.3-1.51-1.94-1.6C581.45,189.55,581.28,190.13,581.71,190.93z",
  "M578.01,190.46c0.42,0.8,1.29,1.51,1.94,1.6c0.65,0.08,0.82-0.5,0.39-1.3c-0.43-0.8-1.29-1.51-1.93-1.59C577.76,189.08,577.58,189.66,578.01,190.46z",
  "M574.29,190c0.42,0.8,1.28,1.51,1.93,1.59c0.65,0.08,0.83-0.5,0.41-1.3c-0.42-0.8-1.28-1.51-1.93-1.59C574.05,188.62,573.87,189.2,574.29,190z",
  "M570.56,189.55c0.41,0.8,1.27,1.51,1.92,1.59c0.65,0.08,0.84-0.51,0.42-1.31c-0.42-0.8-1.28-1.51-1.92-1.59C570.33,188.17,570.14,188.75,570.56,189.55z",
  "M566.81,189.1c0.41,0.8,1.26,1.51,1.91,1.59c0.65,0.08,0.85-0.51,0.44-1.31c-0.41-0.8-1.27-1.51-1.91-1.59C566.6,187.72,566.41,188.3,566.81,189.1z",
  "M563.06,188.66c0.4,0.8,1.25,1.51,1.91,1.58c0.65,0.08,0.86-0.51,0.45-1.31c-0.4-0.8-1.26-1.51-1.91-1.58C562.86,187.28,562.66,187.87,563.06,188.66z",
  "M559.29,188.23c0.39,0.8,1.24,1.51,1.9,1.58c0.66,0.07,0.87-0.51,0.47-1.31c-0.4-0.8-1.25-1.51-1.9-1.58C559.1,186.85,558.89,187.44,559.29,188.23z",
  "M555.51,187.81c0.39,0.8,1.23,1.5,1.89,1.58c0.66,0.07,0.88-0.51,0.48-1.31c-0.39-0.8-1.24-1.5-1.89-1.58C555.34,186.43,555.12,187.01,555.51,187.81z",
  "M551.72,187.4c0.38,0.8,1.22,1.5,1.88,1.58c0.66,0.07,0.88-0.52,0.5-1.32c-0.39-0.8-1.23-1.5-1.88-1.57C551.56,186.01,551.34,186.6,551.72,187.4z",
  "M547.91,186.99c0.37,0.8,1.21,1.5,1.87,1.57c0.66,0.07,0.89-0.52,0.52-1.32c-0.38-0.8-1.22-1.5-1.87-1.57C547.77,185.6,547.54,186.19,547.91,186.99z",
  "M544.1,186.59c0.37,0.8,1.2,1.5,1.87,1.57c0.66,0.07,0.9-0.52,0.53-1.32c-0.37-0.8-1.21-1.5-1.87-1.57C543.97,185.2,543.73,185.79,544.1,186.59z",
  "M540.27,186.2c0.36,0.8,1.19,1.5,1.86,1.57c0.67,0.07,0.91-0.52,0.55-1.32c-0.36-0.8-1.2-1.5-1.86-1.56C540.16,184.81,539.91,185.4,540.27,186.2z",
  "M536.44,185.82c0.35,0.8,1.18,1.5,1.85,1.56c0.67,0.07,0.92-0.53,0.56-1.32c-0.36-0.8-1.19-1.5-1.85-1.56C536.34,184.43,536.09,185.02,536.44,185.82z",
  "M532.59,185.44c0.35,0.8,1.17,1.5,1.84,1.56c0.67,0.06,0.93-0.53,0.58-1.33c-0.35-0.8-1.17-1.49-1.84-1.56C532.5,184.06,532.25,184.65,532.59,185.44z",
  "M528.74,185.08c0.34,0.8,1.16,1.49,1.83,1.56c0.67,0.06,0.94-0.53,0.59-1.33c-0.34-0.8-1.16-1.49-1.83-1.56C528.66,183.69,528.4,184.28,528.74,185.08z",
  "M524.87,184.72c0.33,0.8,1.15,1.49,1.82,1.55c0.67,0.06,0.95-0.53,0.61-1.33c-0.34-0.8-1.15-1.49-1.82-1.55C524.81,183.33,524.54,183.93,524.87,184.72z",
  "M520.99,184.37c0.32,0.8,1.13,1.49,1.81,1.55c0.68,0.06,0.96-0.54,0.63-1.33c-0.33-0.8-1.14-1.49-1.81-1.55C520.95,182.98,520.67,183.58,520.99,184.37z",
  "M517.11,184.03c0.32,0.8,1.12,1.49,1.8,1.55c0.68,0.06,0.96-0.54,0.64-1.33c-0.32-0.8-1.13-1.49-1.8-1.55C517.08,182.64,516.79,183.24,517.11,184.03z",
  "M513.21,183.7c0.31,0.79,1.11,1.49,1.79,1.54c0.68,0.06,0.97-0.54,0.66-1.34c-0.31-0.8-1.12-1.49-1.79-1.54C513.2,182.31,512.9,182.91,513.21,183.7z",
  "M509.31,183.38c0.3,0.79,1.1,1.48,1.78,1.54c0.68,0.06,0.98-0.54,0.68-1.34c-0.31-0.79-1.1-1.48-1.78-1.54C509.31,181.99,509.01,182.59,509.31,183.38z",
  "M505.4,183.07c0.29,0.79,1.09,1.48,1.77,1.54c0.68,0.05,0.99-0.55,0.69-1.34c-0.3-0.8-1.09-1.48-1.77-1.54C505.41,181.67,505.1,182.27,505.4,183.07z",
  "M501.47,182.76c0.29,0.79,1.07,1.48,1.76,1.53c0.68,0.05,1-0.55,0.71-1.34c-0.29-0.79-1.08-1.48-1.76-1.53C501.51,181.37,501.19,181.97,501.47,182.76z",
  "M497.54,182.47c0.28,0.79,1.06,1.48,1.74,1.53c0.68,0.05,1.01-0.55,0.72-1.35c-0.28-0.79-1.07-1.48-1.74-1.53C497.59,181.07,497.27,181.68,497.54,182.47z",
  "M493.61,182.18c0.27,0.79,1.05,1.48,1.73,1.53c0.69,0.05,1.02-0.55,0.74-1.35c-0.28-0.79-1.05-1.48-1.73-1.53C493.67,180.79,493.34,181.39,493.61,182.18z",
  "M489.66,181.91c0.26,0.79,1.03,1.48,1.72,1.52c0.69,0.05,1.03-0.56,0.76-1.35c-0.27-0.79-1.04-1.47-1.72-1.52C489.74,180.51,489.4,181.11,489.66,181.91z",
  "M485.71,181.64c0.25,0.79,1.02,1.47,1.71,1.52c0.69,0.05,1.04-0.56,0.77-1.35c-0.26-0.79-1.03-1.47-1.71-1.52C485.8,180.24,485.45,180.85,485.71,181.64z",
  "M481.75,181.38c0.25,0.79,1.01,1.47,1.7,1.52c0.69,0.04,1.04-0.56,0.79-1.36c-0.25-0.79-1.01-1.47-1.7-1.52C481.85,179.98,481.5,180.59,481.75,181.38z",
  "M477.78,181.13c0.24,0.79,0.99,1.47,1.68,1.51c0.69,0.04,1.05-0.57,0.81-1.36c-0.25-0.79-1-1.47-1.69-1.51C477.9,179.73,477.54,180.34,477.78,181.13z",
  "M473.8,180.89c0.23,0.79,0.98,1.47,1.67,1.51c0.69,0.04,1.06-0.57,0.82-1.36c-0.24-0.79-0.99-1.47-1.67-1.51C473.94,179.49,473.57,180.1,473.8,180.89z",
  "M469.82,180.66c0.22,0.79,0.97,1.47,1.66,1.51c0.69,0.04,1.07-0.57,0.84-1.36c-0.23-0.79-0.97-1.47-1.66-1.5C469.97,179.26,469.6,179.87,469.82,180.66z",
  "M465.83,180.44c0.21,0.79,0.95,1.47,1.65,1.5c0.7,0.04,1.08-0.57,0.86-1.37c-0.22-0.79-0.96-1.46-1.65-1.5C466,179.04,465.62,179.65,465.83,180.44z",
  "M461.84,180.23c0.21,0.79,0.94,1.46,1.63,1.5c0.7,0.04,1.09-0.58,0.88-1.37c-0.21-0.79-0.94-1.46-1.63-1.5C462.02,178.83,461.63,179.44,461.84,180.23z",
  "M457.84,180.03c0.2,0.79,0.92,1.46,1.62,1.5c0.7,0.03,1.1-0.58,0.89-1.37c-0.2-0.79-0.93-1.46-1.62-1.49C458.04,178.63,457.64,179.24,457.84,180.03z",
  "M453.83,179.84c0.19,0.79,0.91,1.46,1.61,1.49c0.7,0.03,1.11-0.58,0.91-1.37c-0.2-0.79-0.92-1.46-1.61-1.49C454.05,178.43,453.64,179.05,453.83,179.84z",
  "M449.82,179.66c0.18,0.79,0.9,1.46,1.59,1.49c0.7,0.03,1.11-0.59,0.93-1.38c-0.19-0.79-0.9-1.46-1.59-1.49C450.05,178.25,449.64,178.86,449.82,179.66z",
  "M445.8,179.48c0.17,0.79,0.88,1.46,1.58,1.49c0.7,0.03,1.12-0.59,0.94-1.38c-0.18-0.79-0.89-1.45-1.58-1.48C446.05,178.08,445.63,178.69,445.8,179.48z",
  "M441.78,179.32c0.16,0.79,0.87,1.45,1.57,1.48c0.7,0.03,1.13-0.59,0.96-1.38c-0.17-0.79-0.87-1.45-1.57-1.48C442.04,177.91,441.61,178.53,441.78,179.32z",
  "M437.75,179.17c0.16,0.79,0.85,1.45,1.55,1.48c0.7,0.03,1.14-0.59,0.98-1.38c-0.16-0.79-0.86-1.45-1.55-1.48C438.03,177.76,437.59,178.38,437.75,179.17z",
  "M433.72,179.03c0.15,0.79,0.84,1.45,1.54,1.48c0.7,0.02,1.15-0.6,0.99-1.39c-0.15-0.79-0.84-1.45-1.54-1.47C434.01,177.62,433.57,178.24,433.72,179.03z",
  "M429.68,178.89c0.14,0.79,0.82,1.45,1.52,1.47c0.7,0.02,1.16-0.6,1.01-1.39c-0.14-0.79-0.83-1.45-1.52-1.47C429.99,177.48,429.54,178.1,429.68,178.89z",
  "M425.64,178.77c0.13,0.79,0.81,1.45,1.51,1.47c0.7,0.02,1.16-0.6,1.03-1.39c-0.14-0.79-0.81-1.45-1.51-1.47C425.97,177.36,425.51,177.98,425.64,178.77z",
  "M421.59,178.66c0.12,0.79,0.79,1.45,1.5,1.47c0.7,0.02,1.17-0.61,1.04-1.4c-0.13-0.79-0.8-1.44-1.5-1.46C421.94,177.25,421.47,177.87,421.59,178.66z",
  "M417.54,178.56c0.11,0.79,0.78,1.44,1.48,1.46c0.71,0.02,1.18-0.61,1.06-1.4c-0.12-0.79-0.78-1.44-1.48-1.46C417.91,177.14,417.43,177.77,417.54,178.56z",
  "M413.49,178.46c0.1,0.79,0.76,1.44,1.47,1.46c0.71,0.02,1.19-0.61,1.08-1.4c-0.11-0.79-0.77-1.44-1.47-1.46C413.87,177.05,413.39,177.68,413.49,178.46z",
  "M409.43,178.38c0.1,0.79,0.74,1.44,1.45,1.45c0.71,0.01,1.2-0.62,1.1-1.4c-0.1-0.79-0.75-1.44-1.45-1.45C409.83,176.97,409.34,177.59,409.43,178.38z",
  "M405.38,178.31c0.09,0.79,0.73,1.44,1.44,1.45c0.71,0.01,1.21-0.62,1.11-1.41c-0.09-0.79-0.74-1.44-1.44-1.45C405.79,176.89,405.29,177.52,405.38,178.31z",
  "M393.18,178.15c0.06,0.79,0.68,1.43,1.39,1.44c0.71,0.01,1.23-0.63,1.16-1.42c-0.07-0.79-0.69-1.43-1.39-1.44C393.64,176.73,393.12,177.37,393.18,178.15z",
  "M389.12,178.12c0.05,0.79,0.67,1.43,1.38,1.44c0.71,0,1.24-0.63,1.18-1.42c-0.06-0.79-0.67-1.43-1.38-1.44C389.59,176.7,389.06,177.33,389.12,178.12z",
  "M372.82,178.1c0.02,0.79,0.6,1.43,1.31,1.43c0.71,0,1.27-0.64,1.24-1.43c-0.02-0.79-0.61-1.43-1.31-1.42C373.37,176.67,372.81,177.31,372.82,178.1z",
  "M348.37,178.37c-0.04,0.79,0.51,1.42,1.22,1.41c0.71-0.01,1.31-0.66,1.34-1.45c0.03-0.79-0.51-1.42-1.22-1.4C349,176.93,348.4,177.58,348.37,178.37z",
  "M344.29,178.45c-0.05,0.79,0.49,1.42,1.2,1.4c0.71-0.01,1.32-0.67,1.36-1.46c0.04-0.79-0.5-1.42-1.2-1.4C344.95,177.01,344.34,177.66,344.29,178.45z",
  "M340.22,178.54c-0.06,0.79,0.48,1.42,1.19,1.4c0.71-0.02,1.32-0.67,1.37-1.46c0.05-0.79-0.48-1.41-1.19-1.4C340.89,177.1,340.27,177.75,340.22,178.54z",
  "M336.14,178.64c-0.06,0.79,0.46,1.41,1.17,1.4c0.71-0.02,1.33-0.67,1.39-1.46c0.06-0.79-0.47-1.41-1.17-1.4C336.83,177.2,336.21,177.85,336.14,178.64z",
  "M219.54,185.89c-0.3,0.8,0.01,1.39,0.69,1.32c0.69-0.07,1.48-0.77,1.78-1.56c0.3-0.8-0.01-1.39-0.69-1.32C220.64,184.39,219.84,185.09,219.54,185.89z",
  "M215.61,186.28c-0.31,0.8-0.01,1.39,0.68,1.32c0.68-0.07,1.49-0.77,1.79-1.57c0.3-0.8,0-1.39-0.68-1.32C216.72,184.78,215.92,185.48,215.61,186.28z",
  "M211.69,186.68c-0.32,0.8-0.02,1.39,0.66,1.32c0.68-0.07,1.49-0.77,1.8-1.57c0.31-0.8,0.02-1.38-0.66-1.32C212.81,185.18,212,185.88,211.69,186.68z",
  "M207.77,187.09c-0.32,0.8-0.04,1.39,0.65,1.31c0.68-0.07,1.49-0.78,1.81-1.57c0.32-0.8,0.03-1.38-0.65-1.31C208.91,185.59,208.1,186.29,207.77,187.09z",
  "M203.87,187.5c-0.33,0.8-0.05,1.38,0.63,1.31c0.68-0.07,1.49-0.78,1.82-1.58c0.33-0.8,0.04-1.38-0.63-1.31C205.02,186,204.2,186.71,203.87,187.5z",
  "M199.98,187.93c-0.34,0.8-0.06,1.38,0.61,1.31c0.68-0.07,1.5-0.78,1.83-1.58c0.33-0.8,0.06-1.38-0.61-1.31C201.14,186.43,200.32,187.13,199.98,187.93z",
  "M196.09,188.36c-0.35,0.8-0.08,1.38,0.6,1.31c0.68-0.08,1.5-0.78,1.84-1.58c0.34-0.8,0.07-1.38-0.6-1.31C197.26,186.86,196.44,187.57,196.09,188.36z",
  "M192.22,188.81c-0.35,0.8-0.09,1.38,0.58,1.31c0.67-0.08,1.5-0.79,1.85-1.58c0.35-0.8,0.09-1.38-0.58-1.3C193.4,187.3,192.57,188.01,192.22,188.81z",
  "M188.36,189.26c-0.36,0.8-0.11,1.38,0.57,1.3c0.67-0.08,1.5-0.79,1.86-1.59c0.35-0.8,0.1-1.38-0.57-1.3C189.55,187.75,188.72,188.46,188.36,189.26z",
  "M184.5,189.71c-0.37,0.8-0.12,1.38,0.55,1.3c0.67-0.08,1.51-0.79,1.87-1.59c0.36-0.8,0.11-1.38-0.55-1.3C185.7,188.2,184.87,188.92,184.5,189.71z",
  "M180.66,190.18c-0.37,0.8-0.13,1.38,0.54,1.3c0.67-0.08,1.51-0.79,1.88-1.59c0.37-0.8,0.13-1.38-0.54-1.3C181.87,188.67,181.03,189.38,180.66,190.18z",
  "M176.83,190.66c-0.38,0.8-0.15,1.38,0.52,1.3c0.67-0.08,1.51-0.8,1.88-1.6c0.37-0.8,0.14-1.38-0.52-1.3C178.05,189.14,177.21,189.86,176.83,190.66z",
  "M173.01,191.14c-0.39,0.8-0.16,1.38,0.51,1.3c0.66-0.08,1.51-0.8,1.89-1.6c0.38-0.8,0.15-1.38-0.51-1.29C174.24,189.62,173.39,190.34,173.01,191.14z",
  "M169.2,191.63c-0.39,0.8-0.17,1.38,0.49,1.29c0.66-0.09,1.51-0.8,1.9-1.6c0.39-0.8,0.17-1.38-0.49-1.29C170.44,190.11,169.59,190.83,169.2,191.63z",
  "M165.4,192.13c-0.4,0.8-0.18,1.38,0.48,1.29c0.66-0.09,1.51-0.81,1.91-1.6c0.39-0.8,0.18-1.38-0.48-1.29C166.65,190.61,165.8,191.33,165.4,192.13z",
  "M161.61,192.63c-0.4,0.8-0.2,1.38,0.46,1.29c0.66-0.09,1.52-0.81,1.92-1.61c0.4-0.8,0.19-1.38-0.46-1.29C162.87,191.11,162.02,191.83,161.61,192.63z",
  "M157.84,193.14c-0.41,0.8-0.21,1.38,0.45,1.29c0.66-0.09,1.52-0.81,1.92-1.61c0.41-0.8,0.21-1.38-0.45-1.29C159.11,191.62,158.25,192.34,157.84,193.14z",
  "M154.08,193.66c-0.42,0.8-0.22,1.38,0.43,1.29c0.65-0.09,1.52-0.81,1.93-1.61c0.41-0.8,0.22-1.38-0.43-1.28C155.36,192.14,154.49,192.86,154.08,193.66z",
  "M150.33,194.19c-0.42,0.8-0.23,1.38,0.42,1.28c0.65-0.09,1.52-0.81,1.94-1.62c0.42-0.8,0.23-1.37-0.42-1.28C151.62,192.67,150.75,193.39,150.33,194.19z",
  "M146.59,194.73c-0.43,0.8-0.25,1.37,0.4,1.28c0.65-0.09,1.52-0.82,1.94-1.62c0.42-0.8,0.24-1.37-0.4-1.28C147.89,193.2,147.02,193.93,146.59,194.73z",
  "M142.87,195.27c-0.43,0.8-0.26,1.37,0.39,1.28c0.65-0.09,1.52-0.82,1.95-1.62c0.43-0.8,0.25-1.37-0.39-1.28C144.17,193.74,143.3,194.47,142.87,195.27z",
  "M139.16,195.82c-0.44,0.8-0.27,1.37,0.37,1.28c0.64-0.1,1.52-0.82,1.95-1.62c0.43-0.8,0.27-1.37-0.37-1.28C140.47,194.29,139.6,195.01,139.16,195.82z",
  "M135.46,196.37c-0.44,0.8-0.28,1.37,0.36,1.28c0.64-0.1,1.52-0.82,1.96-1.63c0.44-0.8,0.28-1.37-0.36-1.28C136.78,194.84,135.91,195.57,135.46,196.37z",
  "M131.78,196.93c-0.45,0.8-0.29,1.37,0.35,1.27c0.64-0.1,1.52-0.83,1.96-1.63c0.44-0.8,0.29-1.37-0.35-1.27C133.11,195.4,132.23,196.13,131.78,196.93z",
  "M128.11,197.5c-0.45,0.8-0.3,1.37,0.33,1.27c0.64-0.1,1.52-0.83,1.97-1.63c0.45-0.8,0.3-1.37-0.33-1.27C129.45,195.97,128.57,196.7,128.11,197.5z",
  "M124.46,198.08c-0.46,0.8-0.32,1.37,0.32,1.27c0.64-0.1,1.52-0.83,1.97-1.63c0.45-0.8,0.31-1.37-0.32-1.27C125.8,196.54,124.92,197.27,124.46,198.08z",
  "M74.97,206.72c-0.51,0.8-0.45,1.36,0.15,1.24c0.59-0.11,1.49-0.85,2-1.65c0.51-0.8,0.44-1.36-0.15-1.24C76.37,205.18,75.48,205.92,74.97,206.72z",
  "M68.17,208.03c-0.52,0.8-0.46,1.35,0.13,1.24c0.59-0.11,1.48-0.85,2-1.65c0.52-0.8,0.46-1.35-0.13-1.24C69.58,206.49,68.69,207.23,68.17,208.03z",
  "M58.12,210.03c-0.53,0.8-0.48,1.35,0.09,1.23c0.58-0.12,1.47-0.86,2-1.65c0.52-0.8,0.48-1.35-0.1-1.23C59.54,208.49,58.64,209.23,58.12,210.03z",
  "M629.31,193.66c0.5,0.8,1.39,1.53,1.99,1.63c0.6,0.1,0.68-0.47,0.18-1.27c-0.5-0.8-1.39-1.53-1.99-1.62C628.89,192.29,628.81,192.86,629.31,193.66z",
  "M593.85,188.35c0.45,0.8,1.33,1.52,1.96,1.6c0.63,0.09,0.77-0.49,0.31-1.29c-0.46-0.8-1.33-1.52-1.96-1.6C593.54,186.98,593.4,187.55,593.85,188.35z",
  "M590.23,187.86c0.45,0.8,1.32,1.51,1.95,1.6c0.63,0.09,0.78-0.49,0.32-1.29c-0.45-0.8-1.32-1.51-1.95-1.6C589.92,186.48,589.78,187.06,590.23,187.86z",
  "M586.59,187.37c0.44,0.8,1.31,1.51,1.95,1.6c0.63,0.08,0.78-0.49,0.34-1.29c-0.45-0.8-1.32-1.51-1.95-1.6C586.3,185.99,586.15,186.57,586.59,187.37z",
  "M582.94,186.89c0.44,0.8,1.3,1.51,1.94,1.6c0.64,0.08,0.79-0.5,0.35-1.29c-0.44-0.8-1.31-1.51-1.94-1.59C582.66,185.51,582.51,186.09,582.94,186.89z",
  "M579.28,186.42c0.43,0.8,1.3,1.51,1.93,1.59c0.64,0.08,0.8-0.5,0.37-1.3c-0.43-0.8-1.3-1.51-1.93-1.59C579.02,185.04,578.85,185.62,579.28,186.42z",
  "M575.61,185.95c0.42,0.8,1.29,1.51,1.93,1.59c0.64,0.08,0.81-0.5,0.38-1.3c-0.43-0.8-1.29-1.51-1.93-1.59C575.35,184.57,575.18,185.15,575.61,185.95z",
  "M571.92,185.49c0.42,0.8,1.28,1.51,1.92,1.59c0.64,0.08,0.82-0.5,0.4-1.3c-0.42-0.8-1.28-1.51-1.92-1.59C571.68,184.11,571.5,184.69,571.92,185.49z",
  "M568.22,185.04c0.41,0.8,1.27,1.51,1.91,1.58c0.64,0.08,0.83-0.5,0.41-1.3c-0.42-0.8-1.27-1.5-1.91-1.58C567.99,183.66,567.81,184.24,568.22,185.04z",
  "M564.51,184.59c0.41,0.8,1.26,1.5,1.91,1.58c0.65,0.08,0.84-0.51,0.42-1.3c-0.41-0.8-1.26-1.5-1.91-1.58C564.3,183.22,564.11,183.8,564.51,184.59z",
  "M560.79,184.16c0.4,0.8,1.25,1.5,1.9,1.58c0.65,0.08,0.85-0.51,0.44-1.3c-0.4-0.8-1.26-1.5-1.9-1.58C560.59,182.78,560.39,183.36,560.79,184.16z",
  "M557.06,183.73c0.39,0.8,1.24,1.5,1.89,1.57c0.65,0.07,0.85-0.51,0.45-1.31c-0.4-0.8-1.25-1.5-1.89-1.57C556.87,182.35,556.67,182.93,557.06,183.73z",
  "M553.32,183.31c0.39,0.79,1.23,1.5,1.88,1.57c0.65,0.07,0.86-0.51,0.47-1.31c-0.39-0.8-1.24-1.5-1.88-1.57C553.14,181.93,552.93,182.51,553.32,183.31z",
  "M549.56,182.9c0.38,0.79,1.22,1.5,1.87,1.57c0.65,0.07,0.87-0.52,0.49-1.31c-0.39-0.79-1.22-1.5-1.87-1.57C549.4,181.52,549.18,182.1,549.56,182.9z",
  "M545.8,182.49c0.37,0.79,1.21,1.5,1.87,1.57c0.66,0.07,0.88-0.52,0.5-1.31c-0.38-0.79-1.22-1.49-1.87-1.56C545.65,181.11,545.42,181.7,545.8,182.49z",
  "M542.02,182.09c0.37,0.79,1.2,1.49,1.86,1.56c0.66,0.07,0.89-0.52,0.52-1.31c-0.37-0.79-1.2-1.49-1.86-1.56C541.89,180.71,541.65,181.3,542.02,182.09z",
  "M538.23,181.71c0.36,0.79,1.19,1.49,1.85,1.56c0.66,0.07,0.9-0.52,0.53-1.32c-0.37-0.79-1.19-1.49-1.85-1.56C538.11,180.32,537.87,180.91,538.23,181.71z",
  "M534.44,181.33c0.35,0.79,1.18,1.49,1.84,1.56c0.66,0.07,0.91-0.52,0.55-1.32c-0.36-0.79-1.18-1.49-1.84-1.55C534.33,179.94,534.08,180.53,534.44,181.33z",
  "M530.63,180.95c0.35,0.79,1.17,1.49,1.83,1.55c0.66,0.06,0.91-0.53,0.56-1.32c-0.35-0.79-1.17-1.49-1.83-1.55C530.54,179.57,530.29,180.16,530.63,180.95z",
  "M526.82,180.59c0.34,0.79,1.15,1.49,1.82,1.55c0.66,0.06,0.92-0.53,0.58-1.32c-0.34-0.79-1.16-1.48-1.82-1.55C526.74,179.21,526.48,179.8,526.82,180.59z",
  "M522.99,180.23c0.33,0.79,1.14,1.48,1.81,1.55c0.67,0.06,0.93-0.53,0.59-1.32c-0.34-0.79-1.15-1.48-1.81-1.54C522.92,178.85,522.66,179.44,522.99,180.23z",
  "M519.15,179.89c0.33,0.79,1.13,1.48,1.8,1.54c0.67,0.06,0.94-0.53,0.61-1.33c-0.33-0.79-1.14-1.48-1.8-1.54C519.1,178.5,518.83,179.1,519.15,179.89z",
  "M515.31,179.55c0.32,0.79,1.12,1.48,1.79,1.54c0.67,0.06,0.95-0.54,0.63-1.33c-0.32-0.79-1.12-1.48-1.79-1.54C515.27,178.17,514.99,178.76,515.31,179.55z",
  "M511.46,179.22c0.31,0.79,1.11,1.48,1.78,1.54c0.67,0.06,0.96-0.54,0.64-1.33c-0.32-0.79-1.11-1.48-1.78-1.53C511.44,177.84,511.15,178.43,511.46,179.22z",
  "M507.6,178.9c0.3,0.79,1.09,1.48,1.77,1.53c0.67,0.06,0.97-0.54,0.66-1.33c-0.31-0.79-1.1-1.48-1.77-1.53C507.59,177.52,507.29,178.11,507.6,178.9z",
  "M503.73,178.59c0.3,0.79,1.08,1.47,1.76,1.53c0.67,0.05,0.98-0.54,0.68-1.33c-0.3-0.79-1.09-1.47-1.76-1.53C503.73,177.2,503.43,177.8,503.73,178.59z",
  "M499.85,178.29c0.29,0.79,1.07,1.47,1.74,1.53c0.68,0.05,0.99-0.55,0.69-1.34c-0.29-0.79-1.07-1.47-1.74-1.52C499.87,176.9,499.56,177.5,499.85,178.29z",
  "M495.96,177.99c0.28,0.79,1.06,1.47,1.73,1.52c0.68,0.05,0.99-0.55,0.71-1.34c-0.29-0.79-1.06-1.47-1.73-1.52C496,176.61,495.68,177.21,495.96,177.99z",
  "M492.07,177.71c0.27,0.79,1.04,1.47,1.72,1.52c0.68,0.05,1-0.55,0.73-1.34c-0.28-0.79-1.05-1.47-1.72-1.52C492.12,176.32,491.8,176.92,492.07,177.71z",
  "M488.17,177.44c0.26,0.79,1.03,1.47,1.71,1.51c0.68,0.05,1.01-0.55,0.74-1.34c-0.27-0.79-1.04-1.47-1.71-1.51C488.24,176.05,487.9,176.65,488.17,177.44z",
  "M484.26,177.17c0.26,0.79,1.02,1.47,1.7,1.51c0.68,0.05,1.02-0.56,0.76-1.35c-0.26-0.79-1.02-1.46-1.7-1.51C484.34,175.78,484,176.38,484.26,177.17z",
  "M480.34,176.91c0.25,0.79,1,1.46,1.69,1.51c0.68,0.04,1.03-0.56,0.78-1.35c-0.25-0.79-1.01-1.46-1.68-1.51C480.44,175.52,480.09,176.12,480.34,176.91z",
  "M476.42,176.67c0.24,0.79,0.99,1.46,1.67,1.5c0.68,0.04,1.04-0.56,0.79-1.35c-0.25-0.79-1-1.46-1.67-1.5C476.54,175.27,476.18,175.88,476.42,176.67z",
  "M472.49,176.43c0.23,0.79,0.98,1.46,1.66,1.5c0.68,0.04,1.05-0.57,0.81-1.35c-0.24-0.79-0.98-1.46-1.66-1.5C472.62,175.04,472.26,175.64,472.49,176.43z",
  "M468.56,176.2c0.22,0.79,0.96,1.46,1.65,1.5c0.69,0.04,1.06-0.57,0.83-1.35c-0.23-0.79-0.97-1.46-1.65-1.5C468.7,174.81,468.33,175.41,468.56,176.2z",
  "M464.61,175.98c0.22,0.79,0.95,1.46,1.63,1.49c0.69,0.04,1.06-0.57,0.84-1.36c-0.22-0.79-0.95-1.45-1.63-1.49C464.78,174.59,464.4,175.19,464.61,175.98z",
  "M460.67,175.77c0.21,0.79,0.93,1.45,1.62,1.49c0.69,0.04,1.07-0.57,0.86-1.36c-0.21-0.79-0.94-1.45-1.62-1.49C460.84,174.38,460.46,174.98,460.67,175.77z",
  "M456.71,175.57c0.2,0.79,0.92,1.45,1.61,1.49c0.69,0.03,1.08-0.58,0.88-1.36c-0.21-0.79-0.92-1.45-1.61-1.48C456.9,174.18,456.51,174.78,456.71,175.57z",
  "M452.75,175.38c0.19,0.79,0.9,1.45,1.59,1.48c0.69,0.03,1.09-0.58,0.89-1.36c-0.2-0.79-0.91-1.45-1.59-1.48C452.96,173.99,452.56,174.59,452.75,175.38z",
  "M448.79,175.2c0.18,0.79,0.89,1.45,1.58,1.48c0.69,0.03,1.1-0.58,0.91-1.37c-0.19-0.79-0.9-1.45-1.58-1.48C449.01,173.8,448.61,174.41,448.79,175.2z",
  "M444.82,175.03c0.17,0.79,0.87,1.45,1.57,1.48c0.69,0.03,1.11-0.58,0.93-1.37c-0.18-0.79-0.88-1.44-1.57-1.47C445.06,173.63,444.64,174.24,444.82,175.03z",
  "M440.84,174.87c0.17,0.78,0.86,1.45,1.55,1.47c0.69,0.03,1.12-0.59,0.94-1.37c-0.17-0.79-0.87-1.44-1.55-1.47C441.1,173.47,440.68,174.08,440.84,174.87z",
  "M436.86,174.72c0.16,0.78,0.85,1.44,1.54,1.47c0.69,0.03,1.12-0.59,0.96-1.38c-0.16-0.78-0.85-1.44-1.54-1.47C437.14,173.32,436.71,173.93,436.86,174.72z",
  "M432.88,174.58c0.15,0.78,0.83,1.44,1.53,1.47c0.69,0.02,1.13-0.59,0.98-1.38c-0.15-0.78-0.84-1.44-1.52-1.46C433.17,173.18,432.73,173.79,432.88,174.58z",
  "M428.89,174.44c0.14,0.78,0.82,1.44,1.51,1.46c0.69,0.02,1.14-0.6,0.99-1.38c-0.15-0.78-0.82-1.44-1.51-1.46C429.2,173.04,428.75,173.66,428.89,174.44z",
  "M424.9,174.32c0.13,0.78,0.8,1.44,1.5,1.46c0.7,0.02,1.15-0.6,1.01-1.38c-0.14-0.78-0.81-1.44-1.5-1.46C425.22,172.92,424.77,173.54,424.9,174.32z",
  "M420.9,174.21c0.12,0.78,0.78,1.44,1.48,1.46c0.7,0.02,1.16-0.6,1.03-1.39c-0.13-0.78-0.79-1.43-1.48-1.45C421.24,172.81,420.78,173.43,420.9,174.21z",
  "M416.9,174.11c0.11,0.78,0.77,1.44,1.47,1.45c0.7,0.02,1.16-0.61,1.05-1.39c-0.12-0.78-0.78-1.43-1.47-1.45C417.26,172.7,416.79,173.33,416.9,174.11z",
  "M412.9,174.02c0.1,0.78,0.75,1.43,1.45,1.45c0.7,0.02,1.17-0.61,1.06-1.39c-0.11-0.78-0.76-1.43-1.45-1.45C413.27,172.61,412.79,173.23,412.9,174.02z",
  "M408.89,173.94c0.1,0.78,0.74,1.43,1.44,1.45c0.7,0.01,1.18-0.61,1.08-1.4c-0.1-0.78-0.75-1.43-1.44-1.44C409.28,172.53,408.8,173.15,408.89,173.94z",
  "M404.88,173.86c0.09,0.78,0.72,1.43,1.42,1.44c0.7,0.01,1.19-0.61,1.1-1.4c-0.09-0.78-0.73-1.43-1.42-1.44C405.29,172.46,404.8,173.08,404.88,173.86z",
  "M400.87,173.8c0.08,0.78,0.71,1.43,1.41,1.44c0.7,0.01,1.2-0.62,1.11-1.4c-0.08-0.78-0.71-1.43-1.41-1.44C401.29,172.39,400.79,173.02,400.87,173.8z",
  "M396.86,173.75c0.07,0.78,0.69,1.43,1.39,1.44c0.7,0.01,1.21-0.62,1.13-1.4c-0.08-0.78-0.7-1.42-1.39-1.43C397.29,172.34,396.79,172.97,396.86,173.75z",
  "M392.84,173.71c0.06,0.78,0.68,1.43,1.38,1.43c0.7,0.01,1.21-0.62,1.15-1.41c-0.07-0.78-0.68-1.42-1.38-1.43C393.29,172.3,392.78,172.93,392.84,173.71z",
  "M388.82,173.68c0.05,0.78,0.66,1.42,1.36,1.43c0.7,0.01,1.22-0.63,1.16-1.41c-0.06-0.78-0.67-1.42-1.36-1.43C389.29,172.27,388.77,172.9,388.82,173.68z",
  "M380.78,173.65c0.03,0.78,0.63,1.42,1.33,1.42c0.7,0,1.24-0.63,1.2-1.42c-0.04-0.78-0.64-1.42-1.33-1.42C381.28,172.23,380.75,172.86,380.78,173.65z",
  "M376.76,173.65c0.03,0.78,0.61,1.42,1.31,1.42c0.7,0,1.24-0.64,1.21-1.42c-0.03-0.78-0.62-1.42-1.31-1.42C377.27,172.23,376.73,172.86,376.76,173.65z",
  "M352.6,173.85c-0.03,0.78,0.52,1.41,1.22,1.4c0.7-0.01,1.29-0.66,1.31-1.44c0.02-0.78-0.52-1.41-1.22-1.4C353.22,172.43,352.63,173.07,352.6,173.85z",
  "M348.58,173.93c-0.04,0.78,0.5,1.41,1.2,1.4c0.7-0.01,1.3-0.66,1.33-1.44c0.03-0.78-0.51-1.41-1.2-1.39C349.21,172.5,348.61,173.14,348.58,173.93z",
  "M344.55,174.01c-0.05,0.78,0.48,1.41,1.19,1.39c0.7-0.01,1.3-0.66,1.34-1.45c0.04-0.78-0.49-1.41-1.19-1.39C345.2,172.58,344.6,173.22,344.55,174.01z",
  "M340.52,174.1c-0.06,0.78,0.47,1.41,1.17,1.39c0.7-0.02,1.31-0.67,1.36-1.45c0.05-0.78-0.47-1.4-1.17-1.39C341.19,172.67,340.58,173.31,340.52,174.1z",
  "M336.5,174.2c-0.06,0.78,0.45,1.41,1.15,1.39c0.7-0.02,1.32-0.67,1.37-1.45c0.06-0.78-0.46-1.4-1.15-1.39C337.18,172.76,336.56,173.41,336.5,174.2z",
  "M240.81,179.59c-0.27,0.79,0.07,1.38,0.76,1.33c0.68-0.06,1.45-0.75,1.71-1.54c0.26-0.79-0.08-1.38-0.76-1.32C241.84,178.11,241.07,178.8,240.81,179.59z",
  "M236.88,179.93c-0.27,0.79,0.06,1.38,0.74,1.32c0.68-0.06,1.45-0.75,1.72-1.54c0.27-0.79-0.06-1.38-0.74-1.32C237.93,178.45,237.15,179.14,236.88,179.93z",
  "M232.96,180.29c-0.28,0.79,0.04,1.38,0.73,1.32c0.68-0.06,1.46-0.75,1.74-1.54c0.28-0.79-0.05-1.38-0.73-1.32C234.02,178.81,233.24,179.5,232.96,180.29z",
  "M229.05,180.65c-0.29,0.79,0.03,1.38,0.71,1.32c0.68-0.06,1.46-0.76,1.75-1.55c0.28-0.79-0.03-1.38-0.71-1.32C230.12,179.17,229.34,179.86,229.05,180.65z",
  "M225.15,181.02c-0.3,0.79,0.01,1.38,0.69,1.32c0.68-0.06,1.47-0.76,1.76-1.55c0.29-0.79-0.02-1.38-0.69-1.31C226.23,179.53,225.44,180.23,225.15,181.02z",
  "M221.25,181.4c-0.3,0.79,0,1.38,0.68,1.31c0.68-0.07,1.47-0.76,1.77-1.55c0.3-0.79,0-1.38-0.68-1.31C222.35,179.91,221.56,180.61,221.25,181.4z",
  "M217.36,181.79c-0.31,0.79-0.02,1.38,0.66,1.31c0.68-0.07,1.47-0.77,1.78-1.56c0.31-0.79,0.01-1.38-0.66-1.31C218.47,180.3,217.68,181,217.36,181.79z",
  "M213.48,182.19c-0.32,0.79-0.03,1.38,0.65,1.31c0.68-0.07,1.48-0.77,1.79-1.56c0.31-0.79,0.03-1.38-0.64-1.31C214.6,180.7,213.8,181.39,213.48,182.19z",
  "M209.61,182.59c-0.33,0.79-0.04,1.38,0.63,1.31c0.67-0.07,1.48-0.77,1.8-1.56c0.32-0.79,0.04-1.38-0.63-1.31C210.75,181.1,209.94,181.8,209.61,182.59z",
  "M205.75,183.01c-0.33,0.79-0.06,1.38,0.61,1.31c0.67-0.07,1.48-0.77,1.81-1.57c0.33-0.79,0.05-1.38-0.61-1.3C206.89,181.51,206.08,182.22,205.75,183.01z",
  "M201.9,183.43c-0.34,0.79-0.07,1.38,0.6,1.3c0.67-0.07,1.49-0.78,1.82-1.57c0.34-0.79,0.07-1.38-0.6-1.3C203.05,181.94,202.24,182.64,201.9,183.43z",
  "M198.05,183.86c-0.35,0.79-0.09,1.38,0.58,1.3c0.67-0.08,1.49-0.78,1.83-1.57c0.34-0.79,0.08-1.38-0.58-1.3C199.22,182.37,198.4,183.07,198.05,183.86z",
  "M194.22,184.3c-0.35,0.79-0.1,1.38,0.57,1.3c0.67-0.08,1.49-0.78,1.84-1.58c0.35-0.79,0.1-1.38-0.57-1.3C195.39,182.8,194.57,183.51,194.22,184.3z",
  "M190.39,184.75c-0.36,0.79-0.11,1.38,0.55,1.3c0.67-0.08,1.49-0.79,1.85-1.58c0.36-0.79,0.11-1.37-0.55-1.3C191.58,183.25,190.75,183.96,190.39,184.75z",
  "M186.57,185.21c-0.37,0.8-0.13,1.38,0.54,1.3c0.66-0.08,1.5-0.79,1.86-1.58c0.36-0.8,0.12-1.37-0.54-1.29C187.77,183.71,186.94,184.41,186.57,185.21z",
  "M182.77,185.67c-0.37,0.8-0.14,1.38,0.52,1.29c0.66-0.08,1.5-0.79,1.87-1.59c0.37-0.8,0.14-1.37-0.52-1.29C183.98,184.17,183.14,184.88,182.77,185.67z",
  "M178.97,186.15c-0.38,0.8-0.15,1.37,0.51,1.29c0.66-0.08,1.5-0.8,1.88-1.59c0.38-0.8,0.15-1.37-0.51-1.29C180.19,184.64,179.35,185.35,178.97,186.15z",
  "M175.19,186.63c-0.39,0.8-0.17,1.37,0.49,1.29c0.66-0.08,1.5-0.8,1.88-1.59c0.38-0.8,0.16-1.37-0.49-1.29C176.42,185.12,175.58,185.83,175.19,186.63z",
  "M171.41,187.12c-0.39,0.8-0.18,1.37,0.48,1.29c0.66-0.08,1.5-0.8,1.89-1.6c0.39-0.8,0.18-1.37-0.48-1.29S171.81,186.32,171.41,187.12z",
  "M167.65,187.61c-0.4,0.8-0.19,1.37,0.46,1.29c0.65-0.09,1.51-0.8,1.9-1.6c0.4-0.8,0.19-1.37-0.46-1.29C168.9,186.1,168.05,186.82,167.65,187.61z",
  "M163.9,188.12c-0.41,0.8-0.21,1.37,0.45,1.28c0.65-0.09,1.51-0.81,1.91-1.6c0.4-0.8,0.2-1.37-0.45-1.28C165.16,186.6,164.31,187.32,163.9,188.12z",
  "M160.16,188.63c-0.41,0.8-0.22,1.37,0.43,1.28c0.65-0.09,1.51-0.81,1.92-1.6c0.41-0.8,0.21-1.37-0.43-1.28C161.43,187.11,160.57,187.83,160.16,188.63z",
  "M156.43,189.15c-0.42,0.8-0.23,1.37,0.42,1.28c0.65-0.09,1.51-0.81,1.92-1.61c0.41-0.8,0.23-1.37-0.42-1.28C157.71,187.63,156.85,188.35,156.43,189.15z",
  "M152.71,189.67c-0.42,0.8-0.24,1.37,0.4,1.28c0.65-0.09,1.51-0.81,1.93-1.61c0.42-0.8,0.24-1.37-0.4-1.28C154,188.16,153.14,188.88,152.71,189.67z",
  "M149.01,190.21c-0.43,0.8-0.25,1.37,0.39,1.28c0.64-0.09,1.51-0.82,1.94-1.61c0.42-0.8,0.25-1.37-0.39-1.28C150.31,188.69,149.44,189.41,149.01,190.21z",
  "M145.32,190.75c-0.43,0.8-0.27,1.37,0.38,1.28c0.64-0.09,1.51-0.82,1.94-1.62c0.43-0.8,0.26-1.37-0.38-1.27C146.62,189.23,145.75,189.95,145.32,190.75z",
  "M141.64,191.29c-0.44,0.8-0.28,1.37,0.36,1.27c0.64-0.1,1.51-0.82,1.95-1.62c0.44-0.8,0.27-1.37-0.36-1.27C142.95,189.77,142.08,190.5,141.64,191.29z",
  "M137.97,191.85c-0.45,0.8-0.29,1.37,0.35,1.27c0.64-0.1,1.51-0.82,1.95-1.62c0.44-0.8,0.28-1.37-0.35-1.27C139.29,190.33,138.42,191.05,137.97,191.85z",
  "M134.32,192.41c-0.45,0.8-0.3,1.37,0.33,1.27c0.64-0.1,1.51-0.82,1.96-1.62c0.45-0.8,0.3-1.37-0.33-1.27C135.65,190.88,134.77,191.61,134.32,192.41z",
  "M130.68,192.98c-0.46,0.8-0.31,1.37,0.32,1.27c0.63-0.1,1.51-0.83,1.96-1.63c0.45-0.8,0.31-1.37-0.32-1.27C132.01,191.45,131.14,192.18,130.68,192.98z",
  "M127.05,193.55c-0.46,0.8-0.32,1.37,0.31,1.27c0.63-0.1,1.51-0.83,1.97-1.63c0.46-0.8,0.32-1.37-0.31-1.27C128.4,192.02,127.51,192.75,127.05,193.55z",
  "M123.44,194.13c-0.46,0.8-0.33,1.37,0.29,1.26c0.63-0.1,1.51-0.83,1.97-1.63c0.46-0.8,0.33-1.37-0.3-1.26C124.79,192.6,123.91,193.33,123.44,194.13z",
  "M119.85,194.72c-0.47,0.8-0.34,1.37,0.28,1.26c0.63-0.1,1.51-0.83,1.98-1.63c0.47-0.8,0.34-1.36-0.28-1.26C121.2,193.19,120.31,193.92,119.85,194.72z",
  "M116.26,195.31c-0.47,0.8-0.35,1.36,0.27,1.26c0.62-0.1,1.51-0.83,1.98-1.63c0.47-0.8,0.35-1.36-0.27-1.26C117.62,193.78,116.74,194.51,116.26,195.31z",
  "M77.87,202.2c-0.51,0.8-0.45,1.35,0.14,1.24c0.59-0.11,1.49-0.85,2-1.65c0.51-0.8,0.45-1.35-0.14-1.24C79.28,200.66,78.38,201.4,77.87,202.2z",
  "M74.48,202.86c-0.52,0.8-0.46,1.35,0.13,1.24c0.59-0.11,1.48-0.85,2-1.65c0.51-0.8,0.46-1.35-0.13-1.24C75.89,201.32,74.99,202.06,74.48,202.86z",
  "M71.1,203.52c-0.52,0.8-0.47,1.35,0.12,1.24c0.59-0.11,1.48-0.85,2-1.65c0.52-0.8,0.46-1.35-0.12-1.24C72.51,201.98,71.62,202.72,71.1,203.52z",
  "M67.75,204.18c-0.52,0.8-0.47,1.35,0.11,1.24c0.58-0.12,1.48-0.86,2-1.65c0.52-0.8,0.47-1.35-0.11-1.24C69.16,202.65,68.27,203.39,67.75,204.18z",
  "M64.41,204.85c-0.52,0.8-0.48,1.35,0.1,1.23c0.58-0.12,1.47-0.86,1.99-1.65c0.52-0.8,0.48-1.35-0.1-1.23C65.82,203.31,64.93,204.05,64.41,204.85z",
  "M61.09,205.53c-0.53,0.8-0.49,1.35,0.09,1.23c0.58-0.12,1.47-0.86,1.99-1.65c0.52-0.8,0.48-1.35-0.09-1.23C62.51,203.99,61.62,204.73,61.09,205.53z",
  "M57.79,206.2c-0.53,0.8-0.49,1.35,0.08,1.23c0.57-0.12,1.46-0.86,1.99-1.66c0.53-0.8,0.49-1.35-0.08-1.23C59.21,204.66,58.32,205.41,57.79,206.2z",
  "M636.77,190.85c0.51,0.8,1.4,1.53,1.99,1.63c0.59,0.1,0.65-0.47,0.14-1.26c-0.51-0.8-1.41-1.53-1.99-1.63C636.33,189.48,636.26,190.05,636.77,190.85z",
  "M605.53,185.89c0.47,0.8,1.35,1.52,1.97,1.61c0.61,0.09,0.73-0.48,0.25-1.28c-0.48-0.8-1.36-1.52-1.97-1.61C605.17,184.52,605.05,185.09,605.53,185.89z",
  "M601.99,185.37c0.47,0.8,1.35,1.52,1.97,1.61c0.62,0.09,0.73-0.48,0.26-1.28c-0.47-0.8-1.35-1.51-1.96-1.6C601.64,184,601.52,184.57,601.99,185.37z",
  "M598.43,184.85c0.46,0.8,1.34,1.51,1.96,1.6c0.62,0.09,0.74-0.49,0.27-1.28c-0.47-0.8-1.34-1.51-1.96-1.6C598.09,183.49,597.97,184.06,598.43,184.85z",
  "M594.86,184.35c0.46,0.8,1.33,1.51,1.96,1.6c0.62,0.09,0.75-0.49,0.29-1.28c-0.46-0.8-1.34-1.51-1.95-1.6C594.54,182.98,594.41,183.55,594.86,184.35z",
  "M591.29,183.85c0.45,0.8,1.33,1.51,1.95,1.6c0.62,0.09,0.76-0.49,0.3-1.28c-0.46-0.8-1.33-1.51-1.95-1.6C590.97,182.48,590.83,183.05,591.29,183.85z",
  "M587.69,183.35c0.45,0.8,1.32,1.51,1.94,1.59c0.62,0.09,0.77-0.49,0.31-1.29c-0.45-0.8-1.32-1.51-1.94-1.59C587.39,181.99,587.24,182.56,587.69,183.35z",
  "M584.09,182.87c0.44,0.79,1.31,1.51,1.94,1.59c0.63,0.08,0.77-0.49,0.33-1.29c-0.45-0.79-1.31-1.51-1.94-1.59C583.79,181.5,583.64,182.07,584.09,182.87z",
  "M580.47,182.39c0.44,0.79,1.3,1.51,1.93,1.59c0.63,0.08,0.78-0.49,0.34-1.29c-0.44-0.79-1.31-1.51-1.93-1.59C580.19,181.02,580.03,181.6,580.47,182.39z",
  "M576.84,181.92c0.43,0.79,1.29,1.5,1.93,1.59c0.63,0.08,0.79-0.5,0.35-1.29c-0.44-0.79-1.3-1.5-1.93-1.58C576.57,180.55,576.41,181.12,576.84,181.92z",
  "M573.2,181.45c0.43,0.79,1.29,1.5,1.92,1.58c0.63,0.08,0.8-0.5,0.37-1.29c-0.43-0.79-1.29-1.5-1.92-1.58C572.94,180.08,572.78,180.66,573.2,181.45z",
  "M569.55,181c0.42,0.79,1.28,1.5,1.91,1.58c0.64,0.08,0.81-0.5,0.38-1.29c-0.42-0.79-1.28-1.5-1.91-1.58C569.3,179.63,569.13,180.2,569.55,181z",
  "M565.88,180.55c0.41,0.79,1.27,1.5,1.91,1.58c0.64,0.08,0.82-0.5,0.4-1.3c-0.42-0.79-1.27-1.5-1.9-1.58C565.65,179.18,565.47,179.76,565.88,180.55z",
  "M562.21,180.11c0.41,0.79,1.26,1.5,1.9,1.57c0.64,0.08,0.82-0.5,0.41-1.3c-0.41-0.79-1.26-1.5-1.9-1.57C561.99,178.73,561.8,179.31,562.21,180.11z",
  "M558.52,179.67c0.4,0.79,1.25,1.49,1.89,1.57c0.64,0.08,0.83-0.51,0.43-1.3c-0.41-0.79-1.25-1.49-1.89-1.57C558.31,178.3,558.12,178.88,558.52,179.67z",
  "M554.83,179.25c0.39,0.79,1.24,1.49,1.88,1.57c0.64,0.07,0.84-0.51,0.44-1.3c-0.4-0.79-1.24-1.49-1.88-1.57C554.63,177.87,554.43,178.45,554.83,179.25z",
  "M551.12,178.83c0.39,0.79,1.23,1.49,1.87,1.56c0.65,0.07,0.85-0.51,0.46-1.3c-0.39-0.79-1.23-1.49-1.87-1.56C550.94,177.45,550.73,178.04,551.12,178.83z",
  "M547.4,178.42c0.38,0.79,1.22,1.49,1.86,1.56c0.65,0.07,0.86-0.51,0.47-1.3c-0.39-0.79-1.22-1.49-1.86-1.56C547.23,177.04,547.02,177.63,547.4,178.42z",
  "M543.67,178.01c0.38,0.79,1.21,1.49,1.86,1.56c0.65,0.07,0.87-0.51,0.49-1.31c-0.38-0.79-1.21-1.49-1.86-1.56C543.52,176.64,543.3,177.22,543.67,178.01z",
  "M539.94,177.62c0.37,0.79,1.2,1.49,1.85,1.55c0.65,0.07,0.88-0.52,0.5-1.31c-0.37-0.79-1.2-1.48-1.85-1.55C539.79,176.25,539.57,176.83,539.94,177.62z",
  "M536.19,177.23c0.36,0.79,1.18,1.48,1.84,1.55c0.65,0.07,0.88-0.52,0.52-1.31c-0.37-0.79-1.19-1.48-1.84-1.55C536.06,175.86,535.83,176.44,536.19,177.23z",
  "M532.43,176.85c0.35,0.79,1.17,1.48,1.83,1.55c0.65,0.07,0.89-0.52,0.53-1.31c-0.36-0.79-1.18-1.48-1.83-1.55C532.32,175.48,532.08,176.07,532.43,176.85z",
  "M528.66,176.49c0.35,0.79,1.16,1.48,1.82,1.54c0.66,0.06,0.9-0.52,0.55-1.31c-0.35-0.79-1.17-1.48-1.82-1.54C528.56,175.11,528.32,175.7,528.66,176.49z",
  "M524.89,176.12c0.34,0.79,1.15,1.48,1.81,1.54c0.66,0.06,0.91-0.53,0.56-1.31c-0.35-0.79-1.16-1.48-1.81-1.54C524.8,174.75,524.55,175.34,524.89,176.12z",
  "M521.1,175.77c0.33,0.79,1.14,1.48,1.8,1.54c0.66,0.06,0.92-0.53,0.58-1.32c-0.34-0.79-1.14-1.47-1.8-1.54C521.03,174.4,520.77,174.98,521.1,175.77z",
  "M517.31,175.43c0.33,0.79,1.13,1.47,1.79,1.53c0.66,0.06,0.93-0.53,0.6-1.32c-0.33-0.79-1.13-1.47-1.79-1.53C517.25,174.05,516.98,174.64,517.31,175.43z",
  "M513.51,175.09c0.32,0.79,1.12,1.47,1.78,1.53c0.66,0.06,0.94-0.53,0.61-1.32c-0.33-0.79-1.12-1.47-1.78-1.53C513.46,173.72,513.19,174.3,513.51,175.09z",
  "M509.7,174.76c0.31,0.79,1.1,1.47,1.77,1.53c0.66,0.06,0.95-0.54,0.63-1.32c-0.32-0.79-1.11-1.47-1.77-1.52C509.67,173.39,509.39,173.98,509.7,174.76z",
  "M505.88,174.45c0.3,0.79,1.09,1.47,1.76,1.52c0.67,0.05,0.95-0.54,0.64-1.32c-0.31-0.79-1.1-1.47-1.76-1.52C505.86,173.07,505.57,173.66,505.88,174.45z",
  "M502.05,174.14c0.3,0.79,1.08,1.47,1.74,1.52c0.67,0.05,0.96-0.54,0.66-1.33c-0.3-0.79-1.08-1.46-1.74-1.52C502.05,172.76,501.75,173.35,502.05,174.14z",
  "M498.22,173.84c0.29,0.78,1.06,1.46,1.73,1.52c0.67,0.05,0.97-0.54,0.68-1.33c-0.29-0.79-1.07-1.46-1.73-1.51C498.23,172.46,497.93,173.05,498.22,173.84z",
  "M494.37,173.55c0.28,0.78,1.05,1.46,1.72,1.51c0.67,0.05,0.98-0.55,0.69-1.33c-0.29-0.78-1.06-1.46-1.72-1.51C494.4,172.17,494.09,172.76,494.37,173.55z",
  "M490.53,173.26c0.27,0.78,1.04,1.46,1.71,1.51c0.67,0.05,0.99-0.55,0.71-1.33c-0.28-0.78-1.04-1.46-1.71-1.51C490.57,171.89,490.25,172.48,490.53,173.26z",
  "M486.67,172.99c0.27,0.78,1.03,1.46,1.7,1.51c0.67,0.05,1-0.55,0.73-1.33c-0.27-0.78-1.03-1.46-1.7-1.5C486.73,171.61,486.4,172.21,486.67,172.99z",
  "M482.8,172.73c0.26,0.78,1.01,1.46,1.68,1.5c0.67,0.05,1.01-0.55,0.74-1.34c-0.26-0.78-1.02-1.45-1.68-1.5C482.88,171.35,482.55,171.94,482.8,172.73z",
  "M478.93,172.47c0.25,0.78,1,1.45,1.67,1.5c0.67,0.04,1.01-0.56,0.76-1.34c-0.26-0.78-1-1.45-1.67-1.5C479.03,171.09,478.68,171.69,478.93,172.47z",
  "M475.06,172.23c0.24,0.78,0.98,1.45,1.66,1.49c0.68,0.04,1.02-0.56,0.78-1.34c-0.25-0.78-0.99-1.45-1.66-1.49C475.16,170.85,474.82,171.45,475.06,172.23z",
  "M471.17,171.99c0.23,0.78,0.97,1.45,1.65,1.49c0.68,0.04,1.03-0.56,0.79-1.34c-0.24-0.78-0.98-1.45-1.65-1.49C471.3,170.61,470.94,171.21,471.17,171.99z",
  "M467.28,171.76c0.22,0.78,0.96,1.45,1.63,1.49c0.68,0.04,1.04-0.56,0.81-1.35c-0.23-0.78-0.96-1.45-1.63-1.48C467.42,170.38,467.06,170.98,467.28,171.76z",
  "M463.39,171.55c0.22,0.78,0.94,1.45,1.62,1.48c0.68,0.04,1.05-0.57,0.83-1.35c-0.22-0.78-0.95-1.44-1.62-1.48C463.54,170.16,463.17,170.77,463.39,171.55z",
  "M459.49,171.34c0.21,0.78,0.93,1.44,1.61,1.48c0.68,0.04,1.06-0.57,0.84-1.35c-0.21-0.78-0.93-1.44-1.61-1.48C459.66,169.96,459.28,170.56,459.49,171.34z",
  "M455.58,171.14c0.2,0.78,0.91,1.44,1.59,1.48c0.68,0.03,1.07-0.57,0.86-1.35c-0.21-0.78-0.92-1.44-1.59-1.47C455.77,169.76,455.38,170.36,455.58,171.14z",
  "M451.67,170.95c0.19,0.78,0.9,1.44,1.58,1.47c0.68,0.03,1.07-0.57,0.88-1.36c-0.2-0.78-0.91-1.44-1.58-1.47C451.87,169.57,451.48,170.17,451.67,170.95z",
  "M447.75,170.77c0.18,0.78,0.88,1.44,1.57,1.47c0.68,0.03,1.08-0.58,0.89-1.36c-0.19-0.78-0.89-1.44-1.57-1.47C447.97,169.39,447.57,169.99,447.75,170.77z",
  "M443.83,170.6c0.17,0.78,0.87,1.44,1.55,1.47c0.68,0.03,1.09-0.58,0.91-1.36c-0.18-0.78-0.88-1.43-1.55-1.46C444.06,169.22,443.66,169.83,443.83,170.6z",
  "M439.9,170.45c0.17,0.78,0.85,1.44,1.54,1.46c0.68,0.03,1.1-0.58,0.93-1.36c-0.17-0.78-0.86-1.43-1.54-1.46C440.15,169.06,439.74,169.67,439.9,170.45z",
  "M435.97,170.3c0.16,0.78,0.84,1.43,1.53,1.46c0.69,0.03,1.11-0.59,0.94-1.37c-0.16-0.78-0.85-1.43-1.52-1.46C436.24,168.91,435.82,169.52,435.97,170.3z",
  "M432.04,170.16c0.15,0.78,0.83,1.43,1.51,1.46c0.69,0.02,1.12-0.59,0.96-1.37c-0.15-0.78-0.83-1.43-1.51-1.45C432.32,168.77,431.89,169.38,432.04,170.16z",
  "M428.1,170.02c0.14,0.78,0.81,1.43,1.5,1.45c0.69,0.02,1.12-0.59,0.98-1.37c-0.15-0.78-0.82-1.43-1.5-1.45C428.4,168.63,427.96,169.25,428.1,170.02z",
  "M424.16,169.9c0.13,0.78,0.79,1.43,1.48,1.45c0.69,0.02,1.13-0.6,1-1.37c-0.14-0.78-0.8-1.43-1.48-1.45C424.47,168.51,424.02,169.13,424.16,169.9z",
  "M420.21,169.79c0.12,0.78,0.78,1.43,1.47,1.45c0.69,0.02,1.14-0.6,1.01-1.38c-0.13-0.78-0.79-1.42-1.47-1.44C420.54,168.4,420.09,169.02,420.21,169.79z",
  "M416.26,169.69c0.11,0.78,0.76,1.42,1.45,1.44c0.69,0.02,1.15-0.6,1.03-1.38c-0.12-0.78-0.77-1.42-1.45-1.44C416.61,168.3,416.14,168.91,416.26,169.69z",
  "M412.3,169.6c0.11,0.78,0.75,1.42,1.44,1.44c0.69,0.02,1.16-0.6,1.05-1.38c-0.11-0.78-0.75-1.42-1.44-1.44C412.67,168.21,412.2,168.82,412.3,169.6z",
  "M408.35,169.52c0.1,0.78,0.73,1.42,1.42,1.43c0.69,0.01,1.17-0.61,1.06-1.39c-0.1-0.78-0.74-1.42-1.42-1.43C408.73,168.13,408.25,168.74,408.35,169.52z",
  "M404.39,169.45c0.09,0.78,0.72,1.42,1.41,1.43c0.69,0.01,1.17-0.61,1.08-1.39c-0.09-0.78-0.72-1.42-1.41-1.43C404.79,168.05,404.3,168.67,404.39,169.45z",
  "M400.43,169.39c0.08,0.78,0.7,1.42,1.39,1.43c0.69,0.01,1.18-0.61,1.1-1.39c-0.08-0.78-0.71-1.42-1.39-1.43C400.84,167.99,400.35,168.61,400.43,169.39z",
  "M396.46,169.34c0.07,0.78,0.69,1.42,1.38,1.42c0.69,0.01,1.19-0.62,1.11-1.39c-0.08-0.78-0.69-1.41-1.38-1.42C396.89,167.94,396.39,168.56,396.46,169.34z",
  "M392.5,169.3c0.06,0.78,0.67,1.41,1.36,1.42c0.69,0.01,1.2-0.62,1.13-1.4c-0.07-0.78-0.68-1.41-1.36-1.42C392.94,167.9,392.44,168.52,392.5,169.3z",
  "M388.53,169.27c0.05,0.78,0.65,1.41,1.35,1.42c0.69,0,1.21-0.62,1.15-1.4c-0.06-0.78-0.66-1.41-1.35-1.42C388.99,167.86,388.48,168.49,388.53,169.27z",
  "M380.59,169.24c0.03,0.78,0.62,1.41,1.31,1.41c0.69,0,1.22-0.63,1.18-1.41c-0.04-0.78-0.63-1.41-1.31-1.41C381.08,167.83,380.55,168.46,380.59,169.24z",
  "M372.64,169.24c0.02,0.78,0.59,1.41,1.28,1.41c0.69,0,1.24-0.63,1.21-1.41c-0.02-0.78-0.6-1.4-1.28-1.4C373.17,167.84,372.62,168.47,372.64,169.24z",
  "M368.67,169.26c0.01,0.78,0.57,1.41,1.27,1.4c0.69,0,1.24-0.64,1.23-1.42c-0.01-0.78-0.58-1.4-1.27-1.4C369.21,167.85,368.66,168.49,368.67,169.26z",
  "M360.71,169.33c-0.01,0.78,0.54,1.4,1.23,1.4c0.69-0.01,1.26-0.64,1.26-1.42c0-0.78-0.55-1.4-1.23-1.39C361.29,167.92,360.73,168.56,360.71,169.33z",
  "M352.76,169.44c-0.03,0.78,0.51,1.4,1.2,1.39c0.69-0.01,1.27-0.65,1.3-1.43c0.02-0.78-0.52-1.4-1.2-1.39C353.37,168.03,352.79,168.66,352.76,169.44z",
  "M348.79,169.51c-0.04,0.78,0.49,1.4,1.19,1.39c0.69-0.01,1.28-0.65,1.31-1.43c0.03-0.78-0.5-1.4-1.19-1.38C349.41,168.1,348.82,168.73,348.79,169.51z",
  "M253.99,174.16c-0.24,0.78,0.11,1.38,0.79,1.32c0.68-0.05,1.42-0.73,1.66-1.52c0.24-0.78-0.12-1.38-0.79-1.32C254.98,172.7,254.24,173.38,253.99,174.16z",
  "M250.09,174.48c-0.25,0.78,0.09,1.38,0.77,1.32c0.68-0.06,1.43-0.74,1.67-1.52c0.25-0.78-0.1-1.37-0.77-1.32C251.1,173.02,250.35,173.69,250.09,174.48z",
  "M242.31,175.14c-0.27,0.78,0.06,1.38,0.74,1.32c0.68-0.06,1.44-0.74,1.7-1.53c0.26-0.78-0.07-1.37-0.74-1.32C243.34,173.67,242.58,174.35,242.31,175.14z",
  "M230.69,176.19c-0.29,0.79,0.02,1.37,0.69,1.31c0.67-0.06,1.45-0.75,1.74-1.54c0.28-0.79-0.03-1.37-0.69-1.31C231.76,174.71,230.98,175.4,230.69,176.19z",
  "M226.83,176.56c-0.3,0.79,0,1.37,0.68,1.31c0.67-0.06,1.45-0.76,1.75-1.54c0.29-0.79-0.01-1.37-0.68-1.31C227.91,175.08,227.13,175.77,226.83,176.56z",
  "M222.97,176.94c-0.31,0.79-0.01,1.37,0.66,1.31c0.67-0.07,1.46-0.76,1.76-1.55c0.3-0.79,0-1.37-0.66-1.31C224.07,175.46,223.28,176.15,222.97,176.94z",
  "M219.13,177.32c-0.31,0.79-0.02,1.37,0.65,1.31c0.67-0.07,1.46-0.76,1.77-1.55c0.31-0.79,0.02-1.37-0.65-1.3C220.23,175.84,219.44,176.53,219.13,177.32z",
  "M215.29,177.72c-0.32,0.79-0.04,1.37,0.63,1.3c0.67-0.07,1.47-0.76,1.78-1.55c0.31-0.79,0.03-1.37-0.63-1.3C216.41,176.24,215.61,176.93,215.29,177.72z",
  "M211.46,178.12c-0.33,0.79-0.05,1.37,0.61,1.3c0.67-0.07,1.47-0.77,1.79-1.56c0.32-0.79,0.05-1.37-0.61-1.3C212.59,176.64,211.79,177.33,211.46,178.12z",
  "M207.64,178.54c-0.33,0.79-0.07,1.37,0.6,1.3c0.67-0.07,1.47-0.77,1.8-1.56c0.33-0.79,0.06-1.37-0.6-1.3C208.78,177.05,207.97,177.75,207.64,178.54z",
  "M203.82,178.96c-0.34,0.79-0.08,1.37,0.58,1.3c0.66-0.07,1.48-0.77,1.81-1.56c0.34-0.79,0.08-1.37-0.58-1.3C204.98,177.47,204.17,178.17,203.82,178.96z",
  "M200.02,179.39c-0.35,0.79-0.1,1.37,0.57,1.3c0.66-0.08,1.48-0.78,1.82-1.57c0.34-0.79,0.09-1.37-0.57-1.29C201.18,177.9,200.37,178.6,200.02,179.39z",
  "M196.22,179.82c-0.36,0.79-0.11,1.37,0.55,1.29c0.66-0.08,1.48-0.78,1.83-1.57c0.35-0.79,0.1-1.37-0.55-1.29C197.4,178.33,196.58,179.03,196.22,179.82z",
  "M192.43,180.27c-0.36,0.79-0.12,1.37,0.54,1.29c0.66-0.08,1.48-0.78,1.84-1.57c0.36-0.79,0.12-1.37-0.54-1.29C193.62,178.78,192.8,179.48,192.43,180.27z",
  "M188.66,180.73c-0.37,0.79-0.14,1.37,0.52,1.29c0.66-0.08,1.49-0.79,1.85-1.58c0.36-0.79,0.13-1.37-0.52-1.29C189.85,179.23,189.03,179.93,188.66,180.73z",
  "M184.89,181.19c-0.38,0.79-0.15,1.37,0.51,1.29c0.66-0.08,1.49-0.79,1.86-1.58c0.37-0.79,0.14-1.37-0.51-1.29C186.1,179.69,185.26,180.4,184.89,181.19z",
  "M181.13,181.66c-0.38,0.79-0.16,1.37,0.49,1.29c0.65-0.08,1.49-0.79,1.87-1.58c0.38-0.79,0.16-1.37-0.49-1.29C182.35,180.16,181.51,180.87,181.13,181.66z",
  "M177.38,182.14c-0.39,0.79-0.18,1.37,0.48,1.28c0.65-0.08,1.49-0.79,1.88-1.59c0.38-0.79,0.17-1.37-0.48-1.28C178.61,180.64,177.77,181.35,177.38,182.14z",
  "M173.64,182.62c-0.4,0.79-0.19,1.37,0.46,1.28c0.65-0.08,1.49-0.8,1.88-1.59c0.39-0.79,0.18-1.37-0.46-1.28C174.88,181.12,174.04,181.83,173.64,182.62z",
  "M169.91,183.12c-0.4,0.79-0.2,1.37,0.45,1.28c0.65-0.09,1.5-0.8,1.89-1.59c0.4-0.79,0.2-1.37-0.45-1.28C171.16,181.61,170.31,182.33,169.91,183.12z",
  "M166.2,183.62c-0.41,0.79-0.21,1.37,0.43,1.28c0.65-0.09,1.5-0.8,1.9-1.6c0.4-0.79,0.21-1.37-0.43-1.28C167.45,182.11,166.6,182.83,166.2,183.62z",
  "M162.49,184.13c-0.41,0.79-0.23,1.37,0.42,1.28c0.64-0.09,1.5-0.8,1.91-1.6c0.41-0.79,0.22-1.37-0.42-1.28C163.76,182.62,162.9,183.34,162.49,184.13z",
  "M158.79,184.65c-0.42,0.79-0.24,1.37,0.4,1.28c0.64-0.09,1.5-0.81,1.92-1.6c0.41-0.79,0.23-1.36-0.41-1.27C160.07,183.14,159.21,183.85,158.79,184.65z",
  "M155.11,185.17c-0.42,0.79-0.25,1.37,0.39,1.27c0.64-0.09,1.5-0.81,1.92-1.6c0.42-0.79,0.25-1.36-0.39-1.27C156.4,183.66,155.53,184.38,155.11,185.17z",
  "M151.44,185.7c-0.43,0.8-0.26,1.36,0.38,1.27c0.64-0.09,1.5-0.81,1.93-1.61c0.43-0.79,0.26-1.36-0.38-1.27C152.73,184.19,151.87,184.91,151.44,185.7z",
  "M147.78,186.24c-0.44,0.79-0.27,1.37,0.36,1.27c0.64-0.09,1.5-0.81,1.93-1.61c0.43-0.8,0.27-1.36-0.36-1.27C149.08,184.73,148.21,185.45,147.78,186.24z",
  "M144.13,186.79c-0.44,0.8-0.29,1.36,0.35,1.27c0.63-0.09,1.5-0.82,1.94-1.61c0.44-0.8,0.28-1.36-0.35-1.27C145.44,185.27,144.57,185.99,144.13,186.79z",
  "M140.49,187.34c-0.45,0.8-0.3,1.36,0.34,1.27c0.63-0.1,1.5-0.82,1.95-1.62c0.44-0.8,0.29-1.36-0.34-1.27C141.81,185.82,140.94,186.54,140.49,187.34z",
  "M136.87,187.9c-0.45,0.8-0.31,1.36,0.32,1.27c0.63-0.1,1.5-0.82,1.95-1.62c0.45-0.8,0.3-1.36-0.32-1.27C138.19,186.38,137.32,187.1,136.87,187.9z",
  "M133.26,188.47c-0.46,0.8-0.32,1.36,0.31,1.26c0.63-0.1,1.5-0.82,1.96-1.62c0.45-0.8,0.31-1.36-0.31-1.26C134.59,186.95,133.71,187.67,133.26,188.47z",
  "M129.66,189.04c-0.46,0.8-0.33,1.36,0.3,1.26c0.63-0.1,1.5-0.83,1.96-1.62c0.46-0.8,0.32-1.36-0.3-1.26C131,187.52,130.12,188.24,129.66,189.04z",
  "M126.07,189.62c-0.47,0.8-0.34,1.36,0.28,1.26c0.62-0.1,1.5-0.83,1.97-1.63c0.46-0.8,0.34-1.36-0.28-1.26C127.42,188.1,126.54,188.82,126.07,189.62z",
  "M122.5,190.21c-0.47,0.8-0.35,1.36,0.27,1.26c0.62-0.1,1.5-0.83,1.97-1.63c0.47-0.8,0.35-1.36-0.27-1.26C123.85,188.68,122.97,189.41,122.5,190.21z",
  "M118.94,190.8c-0.48,0.8-0.36,1.36,0.26,1.26c0.62-0.1,1.5-0.83,1.97-1.63c0.47-0.8,0.36-1.36-0.26-1.26C120.3,189.27,119.42,190,118.94,190.8z",
  "M115.4,191.4c-0.48,0.8-0.37,1.36,0.25,1.26c0.62-0.1,1.5-0.84,1.98-1.63c0.48-0.8,0.37-1.36-0.25-1.26C116.76,189.87,115.88,190.6,115.4,191.4z",
  "M91,195.74c-0.51,0.8-0.43,1.36,0.17,1.24c0.6-0.11,1.49-0.85,1.99-1.65c0.5-0.8,0.43-1.36-0.17-1.24C92.4,194.21,91.5,194.95,91,195.74z",
  "M87.57,196.39c-0.51,0.8-0.44,1.35,0.16,1.24c0.59-0.11,1.49-0.85,1.99-1.65c0.51-0.8,0.44-1.35-0.16-1.24C88.98,194.85,88.08,195.59,87.57,196.39z",
  "M84.17,197.04c-0.51,0.8-0.45,1.35,0.14,1.24c0.59-0.11,1.49-0.85,1.99-1.65c0.51-0.8,0.44-1.35-0.15-1.24C85.57,195.5,84.68,196.24,84.17,197.04z",
  "M80.77,197.69c-0.51,0.8-0.45,1.35,0.13,1.24c0.59-0.11,1.48-0.85,1.99-1.65c0.51-0.8,0.45-1.35-0.14-1.24C82.18,196.15,81.29,196.89,80.77,197.69z",
  "M77.4,198.35c-0.52,0.8-0.46,1.35,0.12,1.24c0.59-0.11,1.48-0.85,1.99-1.65c0.51-0.8,0.46-1.35-0.13-1.24C78.81,196.81,77.91,197.55,77.4,198.35z",
  "M74.04,199.01c-0.52,0.8-0.47,1.35,0.11,1.24c0.58-0.11,1.48-0.85,1.99-1.65c0.52-0.8,0.47-1.35-0.12-1.24C75.45,197.47,74.56,198.21,74.04,199.01z",
  "M70.7,199.67c-0.52,0.8-0.48,1.35,0.1,1.23c0.58-0.12,1.47-0.86,1.99-1.65c0.52-0.8,0.47-1.35-0.11-1.23C72.11,198.14,71.22,198.88,70.7,199.67z",
  "M67.37,200.35c-0.52,0.8-0.48,1.35,0.09,1.23c0.58-0.12,1.47-0.86,1.99-1.65c0.52-0.8,0.48-1.35-0.1-1.23C68.79,198.81,67.89,199.55,67.37,200.35z",
  "M64.06,201.02c-0.53,0.8-0.49,1.35,0.09,1.23c0.57-0.12,1.47-0.86,1.99-1.65c0.52-0.8,0.49-1.35-0.09-1.23C65.48,199.48,64.59,200.22,64.06,201.02z",
  "M57.5,202.38c-0.53,0.8-0.5,1.34,0.07,1.23c0.57-0.12,1.46-0.86,1.99-1.66c0.53-0.8,0.5-1.35-0.07-1.23C58.92,200.84,58.03,201.59,57.5,202.38z",
  "M637.27,186.92c0.51,0.8,1.41,1.53,1.99,1.63c0.58,0.1,0.64-0.46,0.12-1.26c-0.52-0.8-1.41-1.53-1.99-1.63C636.81,185.55,636.75,186.12,637.27,186.92z",
  "M630.49,185.76c0.51,0.8,1.4,1.52,1.99,1.62c0.59,0.1,0.66-0.47,0.15-1.26c-0.51-0.8-1.4-1.52-1.99-1.62C630.05,184.4,629.98,184.97,630.49,185.76z",
  "M627.08,185.2c0.5,0.8,1.39,1.52,1.99,1.62c0.59,0.1,0.66-0.47,0.16-1.26c-0.51-0.8-1.4-1.52-1.99-1.62C626.65,183.83,626.58,184.4,627.08,185.2z",
  "M623.66,184.63c0.5,0.8,1.39,1.52,1.98,1.62c0.6,0.1,0.67-0.47,0.17-1.27c-0.5-0.8-1.39-1.52-1.98-1.62C623.24,183.27,623.16,183.84,623.66,184.63z",
  "M599.33,180.87c0.47,0.79,1.35,1.51,1.96,1.6c0.61,0.09,0.72-0.48,0.25-1.28c-0.47-0.79-1.35-1.51-1.96-1.6C598.98,179.51,598.86,180.08,599.33,180.87z",
  "M595.81,180.36c0.46,0.79,1.34,1.51,1.95,1.6c0.61,0.09,0.73-0.48,0.26-1.28c-0.47-0.79-1.34-1.51-1.95-1.6C595.46,179,595.34,179.57,595.81,180.36z",
  "M592.27,179.86c0.46,0.79,1.33,1.51,1.95,1.59c0.62,0.09,0.74-0.49,0.28-1.28c-0.46-0.79-1.33-1.51-1.95-1.59C591.93,178.49,591.81,179.06,592.27,179.86z",
  "M588.72,179.36c0.46,0.79,1.32,1.51,1.94,1.59c0.62,0.09,0.75-0.49,0.29-1.28c-0.46-0.79-1.33-1.5-1.94-1.59C588.39,178,588.26,178.57,588.72,179.36z",
  "M585.15,178.87c0.45,0.79,1.32,1.5,1.94,1.59c0.62,0.09,0.76-0.49,0.3-1.28c-0.45-0.79-1.32-1.5-1.94-1.59C584.84,177.5,584.7,178.08,585.15,178.87z",
  "M581.58,178.39c0.44,0.79,1.31,1.5,1.93,1.59c0.62,0.08,0.76-0.49,0.32-1.28c-0.45-0.79-1.31-1.5-1.93-1.58C581.28,177.02,581.14,177.59,581.58,178.39z",
  "M577.99,177.91c0.44,0.79,1.3,1.5,1.92,1.58c0.62,0.08,0.77-0.49,0.33-1.28c-0.44-0.79-1.3-1.5-1.92-1.58C577.7,176.54,577.56,177.12,577.99,177.91z",
  "M574.4,177.44c0.43,0.79,1.29,1.5,1.92,1.58c0.63,0.08,0.78-0.49,0.34-1.29c-0.44-0.79-1.3-1.5-1.92-1.58C574.12,176.08,573.96,176.65,574.4,177.44z",
  "M570.79,176.98c0.43,0.79,1.28,1.5,1.91,1.58c0.63,0.08,0.79-0.5,0.36-1.29c-0.43-0.79-1.29-1.5-1.91-1.57C570.52,175.61,570.36,176.19,570.79,176.98z",
  "M567.17,176.52c0.42,0.79,1.27,1.49,1.9,1.57c0.63,0.08,0.8-0.5,0.37-1.29c-0.43-0.79-1.28-1.49-1.9-1.57C566.91,175.16,566.75,175.73,567.17,176.52z",
  "M563.54,176.08c0.42,0.79,1.26,1.49,1.9,1.57c0.63,0.08,0.8-0.5,0.38-1.29c-0.42-0.79-1.27-1.49-1.9-1.57C563.3,174.71,563.12,175.29,563.54,176.08z",
  "M559.9,175.64c0.41,0.79,1.25,1.49,1.89,1.57c0.63,0.08,0.81-0.5,0.4-1.29c-0.41-0.79-1.26-1.49-1.89-1.56C559.67,174.27,559.49,174.85,559.9,175.64z",
  "M556.25,175.21c0.4,0.79,1.24,1.49,1.88,1.56c0.64,0.07,0.82-0.5,0.41-1.29c-0.41-0.79-1.25-1.49-1.88-1.56C556.03,173.84,555.85,174.42,556.25,175.21z",
  "M552.59,174.78c0.4,0.79,1.23,1.49,1.87,1.56c0.64,0.07,0.83-0.51,0.43-1.29c-0.4-0.79-1.24-1.48-1.87-1.56C552.38,173.42,552.19,174,552.59,174.78z",
  "M548.92,174.37c0.39,0.79,1.22,1.48,1.86,1.56c0.64,0.07,0.84-0.51,0.44-1.3c-0.39-0.79-1.23-1.48-1.86-1.55C548.72,173,548.53,173.58,548.92,174.37z",
  "M545.24,173.96c0.38,0.79,1.21,1.48,1.86,1.55c0.64,0.07,0.85-0.51,0.46-1.3c-0.39-0.79-1.22-1.48-1.85-1.55C545.06,172.59,544.85,173.17,545.24,173.96z",
  "M541.55,173.56c0.38,0.79,1.2,1.48,1.85,1.55c0.64,0.07,0.85-0.51,0.47-1.3c-0.38-0.79-1.21-1.48-1.85-1.55C541.38,172.19,541.17,172.77,541.55,173.56z",
  "M537.85,173.17c0.37,0.79,1.19,1.48,1.84,1.55c0.64,0.07,0.86-0.52,0.49-1.3c-0.37-0.79-1.2-1.48-1.84-1.54C537.69,171.8,537.48,172.38,537.85,173.17z",
  "M534.14,172.78c0.36,0.78,1.18,1.48,1.83,1.54c0.65,0.07,0.87-0.52,0.5-1.3c-0.37-0.78-1.19-1.47-1.83-1.54C534,171.42,533.77,172,534.14,172.78z",
  "M530.42,172.41c0.36,0.78,1.17,1.47,1.82,1.54c0.65,0.07,0.88-0.52,0.52-1.3c-0.36-0.78-1.17-1.47-1.82-1.54C530.3,171.04,530.06,171.62,530.42,172.41z",
  "M526.69,172.04c0.35,0.78,1.16,1.47,1.81,1.53c0.65,0.06,0.89-0.52,0.53-1.31c-0.36-0.78-1.16-1.47-1.81-1.53C526.58,170.68,526.34,171.26,526.69,172.04z",
  "M522.96,171.68c0.34,0.78,1.15,1.47,1.8,1.53c0.65,0.06,0.9-0.52,0.55-1.31c-0.35-0.78-1.15-1.47-1.8-1.53C522.86,170.32,522.61,170.9,522.96,171.68z",
  "M519.21,171.33c0.33,0.78,1.13,1.47,1.79,1.53c0.65,0.06,0.91-0.53,0.57-1.31c-0.34-0.78-1.14-1.47-1.79-1.53C519.13,169.97,518.88,170.55,519.21,171.33z",
  "M515.46,170.99c0.33,0.78,1.12,1.46,1.78,1.52c0.65,0.06,0.91-0.53,0.58-1.31c-0.33-0.78-1.13-1.46-1.78-1.52C515.39,169.62,515.13,170.21,515.46,170.99z",
  "M511.7,170.66c0.32,0.78,1.11,1.46,1.77,1.52c0.66,0.06,0.92-0.53,0.6-1.31c-0.33-0.78-1.12-1.46-1.77-1.52C511.65,169.29,511.38,169.88,511.7,170.66z",
  "M507.93,170.34c0.31,0.78,1.1,1.46,1.75,1.52c0.66,0.06,0.93-0.53,0.61-1.31c-0.32-0.78-1.1-1.46-1.75-1.51C507.89,168.97,507.62,169.55,507.93,170.34z",
  "M504.15,170.02c0.3,0.78,1.09,1.46,1.74,1.51c0.66,0.05,0.94-0.54,0.63-1.32c-0.31-0.78-1.09-1.46-1.74-1.51C504.13,168.65,503.85,169.24,504.15,170.02z",
  "M500.37,169.71c0.3,0.78,1.07,1.46,1.73,1.51c0.66,0.05,0.95-0.54,0.65-1.32c-0.3-0.78-1.08-1.45-1.73-1.51C500.36,168.34,500.07,168.93,500.37,169.71z",
  "M496.58,169.42c0.29,0.78,1.06,1.45,1.72,1.51c0.66,0.05,0.96-0.54,0.66-1.32c-0.3-0.78-1.07-1.45-1.72-1.5C496.59,168.05,496.29,168.64,496.58,169.42z",
  "M492.78,169.13c0.28,0.78,1.05,1.45,1.71,1.5c0.66,0.05,0.96-0.54,0.68-1.32c-0.29-0.78-1.05-1.45-1.71-1.5C492.8,167.76,492.5,168.35,492.78,169.13z",
  "M488.98,168.85c0.27,0.78,1.03,1.45,1.7,1.5c0.66,0.05,0.97-0.54,0.69-1.32c-0.28-0.78-1.04-1.45-1.7-1.5C489.01,167.48,488.7,168.07,488.98,168.85z",
  "M485.16,168.58c0.27,0.78,1.02,1.45,1.68,1.49c0.66,0.05,0.98-0.55,0.71-1.33c-0.27-0.78-1.03-1.45-1.68-1.49C485.22,167.21,484.9,167.8,485.16,168.58z",
  "M481.35,168.32c0.26,0.78,1.01,1.45,1.67,1.49c0.67,0.05,0.99-0.55,0.73-1.33c-0.26-0.78-1.01-1.44-1.67-1.49C481.41,166.95,481.09,167.54,481.35,168.32z",
  "M477.52,168.06c0.25,0.78,0.99,1.44,1.66,1.49c0.67,0.04,1-0.55,0.74-1.33c-0.26-0.78-1-1.44-1.66-1.48C477.6,166.69,477.27,167.29,477.52,168.06z",
  "M473.69,167.82c0.24,0.78,0.98,1.44,1.65,1.48c0.67,0.04,1.01-0.55,0.76-1.33c-0.25-0.78-0.98-1.44-1.65-1.48C473.79,166.45,473.45,167.04,473.69,167.82z",
  "M469.85,167.59c0.23,0.78,0.97,1.44,1.63,1.48c0.67,0.04,1.02-0.56,0.78-1.33c-0.24-0.78-0.97-1.44-1.63-1.48C469.97,166.21,469.62,166.81,469.85,167.59z",
  "M466.01,167.36c0.22,0.78,0.95,1.44,1.62,1.48c0.67,0.04,1.02-0.56,0.79-1.34c-0.23-0.78-0.96-1.44-1.62-1.47C466.14,165.99,465.78,166.59,466.01,167.36z",
  "M462.16,167.15c0.22,0.78,0.94,1.44,1.61,1.47c0.67,0.04,1.03-0.56,0.81-1.34c-0.22-0.78-0.94-1.43-1.61-1.47C462.31,165.77,461.94,166.37,462.16,167.15z",
  "M458.31,166.94c0.21,0.77,0.92,1.43,1.59,1.47c0.67,0.04,1.04-0.57,0.83-1.34c-0.21-0.78-0.93-1.43-1.59-1.47C458.47,165.57,458.1,166.17,458.31,166.94z",
  "M454.45,166.74c0.2,0.77,0.91,1.43,1.58,1.47c0.67,0.03,1.05-0.57,0.84-1.34c-0.21-0.78-0.91-1.43-1.58-1.46C454.63,165.37,454.25,165.97,454.45,166.74z",
  "M450.58,166.56c0.19,0.77,0.89,1.43,1.57,1.46c0.67,0.03,1.06-0.57,0.86-1.35c-0.2-0.78-0.9-1.43-1.57-1.46C450.78,165.18,450.39,165.78,450.58,166.56z",
  "M446.72,166.38c0.18,0.77,0.88,1.43,1.55,1.46c0.67,0.03,1.07-0.57,0.88-1.35c-0.19-0.77-0.88-1.43-1.55-1.46C446.92,165,446.53,165.61,446.72,166.38z",
  "M442.84,166.21c0.17,0.77,0.86,1.43,1.54,1.45c0.67,0.03,1.08-0.58,0.89-1.35c-0.18-0.77-0.87-1.42-1.54-1.45C443.07,164.84,442.67,165.44,442.84,166.21z",
  "M438.96,166.05c0.17,0.77,0.85,1.42,1.52,1.45c0.68,0.03,1.08-0.58,0.91-1.35c-0.17-0.77-0.86-1.42-1.52-1.45C439.21,164.68,438.8,165.28,438.96,166.05z",
  "M435.08,165.91c0.16,0.77,0.83,1.42,1.51,1.45c0.68,0.03,1.09-0.58,0.93-1.36c-0.16-0.77-0.84-1.42-1.51-1.44C435.34,164.53,434.92,165.13,435.08,165.91z",
  "M431.19,165.77c0.15,0.77,0.82,1.42,1.5,1.44c0.68,0.02,1.1-0.58,0.95-1.36c-0.16-0.77-0.83-1.42-1.5-1.44C431.47,164.39,431.05,164.99,431.19,165.77z",
  "M427.3,165.64c0.14,0.77,0.8,1.42,1.48,1.44c0.68,0.02,1.11-0.59,0.96-1.36c-0.15-0.77-0.81-1.42-1.48-1.44C427.6,164.26,427.16,164.87,427.3,165.64z",
  "M423.41,165.52c0.13,0.77,0.79,1.42,1.47,1.44c0.68,0.02,1.12-0.59,0.98-1.36c-0.14-0.77-0.79-1.41-1.47-1.43C423.72,164.14,423.28,164.75,423.41,165.52z",
  "M419.51,165.41c0.12,0.77,0.77,1.41,1.45,1.43c0.68,0.02,1.13-0.59,1-1.37c-0.13-0.77-0.78-1.41-1.45-1.43C419.84,164.03,419.39,164.64,419.51,165.41z",
  "M415.61,165.31c0.11,0.77,0.76,1.41,1.44,1.43c0.68,0.02,1.13-0.6,1.01-1.37c-0.12-0.77-0.76-1.41-1.44-1.43C415.95,163.93,415.5,164.54,415.61,165.31z",
  "M411.71,165.22c0.1,0.77,0.74,1.41,1.42,1.43c0.68,0.02,1.14-0.6,1.03-1.37c-0.11-0.77-0.75-1.41-1.42-1.42C412.07,163.84,411.6,164.45,411.71,165.22z",
  "M407.8,165.14c0.1,0.77,0.73,1.41,1.41,1.42c0.68,0.01,1.15-0.6,1.05-1.37c-0.1-0.77-0.73-1.41-1.41-1.42C408.18,163.76,407.71,164.37,407.8,165.14z",
  "M403.89,165.07c0.09,0.77,0.71,1.41,1.39,1.42c0.68,0.01,1.16-0.61,1.06-1.38c-0.09-0.77-0.72-1.41-1.39-1.42C404.28,163.68,403.81,164.3,403.89,165.07z",
  "M399.98,165.01c0.08,0.77,0.69,1.41,1.38,1.42c0.68,0.01,1.17-0.61,1.08-1.38c-0.08-0.77-0.7-1.4-1.38-1.41C400.39,163.62,399.9,164.24,399.98,165.01z",
  "M396.07,164.96c0.07,0.77,0.68,1.4,1.36,1.41c0.68,0.01,1.17-0.61,1.1-1.38c-0.08-0.77-0.69-1.4-1.36-1.41C396.49,163.57,396,164.19,396.07,164.96z",
  "M392.15,164.92c0.06,0.77,0.66,1.4,1.35,1.41c0.68,0.01,1.18-0.61,1.11-1.39c-0.07-0.77-0.67-1.4-1.35-1.41C392.59,163.53,392.09,164.15,392.15,164.92z",
  "M388.23,164.89c0.05,0.77,0.65,1.4,1.33,1.41c0.68,0.01,1.19-0.62,1.13-1.39c-0.06-0.77-0.65-1.4-1.33-1.4C388.69,163.5,388.18,164.12,388.23,164.89z",
  "M384.32,164.87c0.04,0.77,0.63,1.4,1.31,1.4c0.68,0,1.2-0.62,1.15-1.39c-0.05-0.77-0.64-1.4-1.31-1.4C384.79,163.48,384.27,164.1,384.32,164.87z",
  "M380.39,164.86c0.03,0.77,0.61,1.4,1.3,1.4c0.68,0,1.21-0.62,1.16-1.39c-0.04-0.77-0.62-1.4-1.3-1.4C380.88,163.46,380.36,164.09,380.39,164.86z",
  "M376.47,164.86c0.02,0.77,0.6,1.4,1.28,1.4c0.68,0,1.21-0.63,1.18-1.4c-0.03-0.77-0.61-1.39-1.28-1.39C376.98,163.46,376.45,164.09,376.47,164.86z",
  "M372.55,164.87c0.02,0.77,0.58,1.4,1.27,1.39c0.68,0,1.22-0.63,1.2-1.4c-0.02-0.77-0.59-1.39-1.27-1.39C373.07,163.47,372.53,164.1,372.55,164.87z",
  "M368.62,164.89c0.01,0.77,0.57,1.39,1.25,1.39c0.68,0,1.23-0.63,1.21-1.4c-0.01-0.77-0.57-1.39-1.25-1.39C369.16,163.49,368.62,164.12,368.62,164.89z",
  "M364.7,164.92c0,0.77,0.55,1.39,1.23,1.39c0.68-0.01,1.24-0.64,1.23-1.41c0-0.77-0.56-1.39-1.23-1.39C365.25,163.52,364.7,164.14,364.7,164.92z",
  "M360.77,164.96c-0.01,0.77,0.53,1.39,1.22,1.38c0.68-0.01,1.24-0.64,1.25-1.41c0-0.77-0.54-1.39-1.22-1.38C361.34,163.55,360.79,164.18,360.77,164.96z",
  "M356.85,165c-0.02,0.77,0.52,1.39,1.2,1.38c0.68-0.01,1.25-0.64,1.26-1.41c0.01-0.77-0.52-1.39-1.2-1.38C357.44,163.6,356.87,164.23,356.85,165z",
  "M352.92,165.06c-0.03,0.77,0.5,1.39,1.19,1.38c0.68-0.01,1.26-0.65,1.28-1.42c0.02-0.77-0.51-1.39-1.19-1.38C353.53,163.66,352.95,164.29,352.92,165.06z",
  "M349,165.13c-0.04,0.77,0.48,1.39,1.17,1.38c0.68-0.01,1.26-0.65,1.3-1.42c0.03-0.77-0.49-1.39-1.17-1.37C349.62,163.73,349.04,164.36,349,165.13z",
  "M255.37,169.75c-0.24,0.78,0.1,1.37,0.77,1.32c0.67-0.05,1.41-0.73,1.65-1.51c0.24-0.78-0.11-1.37-0.77-1.31C256.36,168.3,255.62,168.97,255.37,169.75z",
  "M243.83,170.71c-0.27,0.78,0.06,1.37,0.73,1.31c0.67-0.06,1.42-0.74,1.69-1.52c0.26-0.78-0.06-1.36-0.73-1.31C244.85,169.25,244.1,169.93,243.83,170.71z",
  "M239.99,171.05c-0.28,0.78,0.04,1.37,0.71,1.31c0.67-0.06,1.43-0.74,1.7-1.52c0.27-0.78-0.05-1.36-0.71-1.3C241.03,169.59,240.27,170.27,239.99,171.05z",
  "M236.16,171.4c-0.28,0.78,0.03,1.37,0.69,1.3c0.67-0.06,1.43-0.74,1.71-1.52c0.28-0.78-0.03-1.36-0.69-1.3C237.21,169.94,236.44,170.62,236.16,171.4z",
  "M232.33,171.76c-0.29,0.78,0.01,1.37,0.68,1.3c0.67-0.06,1.44-0.75,1.72-1.53c0.29-0.78-0.02-1.36-0.68-1.3C233.4,170.29,232.63,170.97,232.33,171.76z",
  "M228.52,172.12c-0.3,0.78,0,1.37,0.66,1.3c0.66-0.06,1.44-0.75,1.74-1.53c0.29-0.78,0-1.36-0.66-1.3C229.59,170.66,228.82,171.34,228.52,172.12z",
  "M224.7,172.5c-0.31,0.78-0.02,1.36,0.65,1.3c0.66-0.07,1.45-0.75,1.75-1.54c0.3-0.78,0.01-1.36-0.65-1.3C225.79,171.03,225.01,171.72,224.7,172.5z",
  "M220.9,172.88c-0.31,0.78-0.03,1.36,0.63,1.3c0.66-0.07,1.45-0.76,1.76-1.54c0.31-0.78,0.03-1.36-0.63-1.3C222,171.41,221.21,172.1,220.9,172.88z",
  "M217.1,173.28c-0.32,0.78-0.05,1.36,0.61,1.3c0.66-0.07,1.45-0.76,1.77-1.54c0.32-0.78,0.04-1.36-0.61-1.29C218.22,171.8,217.43,172.49,217.1,173.28z",
  "M213.31,173.68c-0.33,0.78-0.06,1.36,0.6,1.29c0.66-0.07,1.46-0.76,1.78-1.55c0.32-0.78,0.06-1.36-0.6-1.29C214.44,172.2,213.64,172.89,213.31,173.68z",
  "M205.76,174.51c-0.34,0.79-0.09,1.36,0.57,1.29c0.66-0.07,1.46-0.77,1.8-1.55c0.34-0.79,0.08-1.36-0.57-1.29C206.91,173.03,206.1,173.72,205.76,174.51z",
  "M201.99,174.93c-0.35,0.79-0.1,1.36,0.55,1.29c0.66-0.07,1.47-0.77,1.81-1.56c0.35-0.79,0.1-1.36-0.55-1.29C203.15,173.45,202.34,174.15,201.99,174.93z",
  "M198.24,175.37c-0.36,0.79-0.12,1.36,0.54,1.29c0.65-0.08,1.47-0.78,1.82-1.56c0.35-0.79,0.11-1.36-0.54-1.29C199.41,173.88,198.59,174.58,198.24,175.37z",
  "M194.49,175.81c-0.36,0.79-0.13,1.36,0.52,1.29c0.65-0.08,1.47-0.78,1.83-1.56c0.36-0.79,0.13-1.36-0.52-1.28C195.67,174.33,194.85,175.03,194.49,175.81z",
  "M190.75,176.26c-0.37,0.79-0.14,1.36,0.51,1.28c0.65-0.08,1.47-0.78,1.84-1.57c0.37-0.79,0.14-1.36-0.51-1.28C191.94,174.78,191.12,175.48,190.75,176.26z",
  "M187.02,176.72c-0.38,0.79-0.16,1.36,0.49,1.28c0.65-0.08,1.48-0.78,1.85-1.57c0.37-0.79,0.15-1.36-0.49-1.28C188.22,175.23,187.39,175.94,187.02,176.72z",
  "M183.29,177.19c-0.38,0.79-0.17,1.36,0.48,1.28c0.65-0.08,1.48-0.79,1.86-1.58c0.38-0.79,0.17-1.36-0.48-1.28C184.51,175.7,183.68,176.4,183.29,177.19z",
  "M179.58,177.67c-0.39,0.79-0.18,1.36,0.46,1.28c0.65-0.08,1.48-0.79,1.87-1.58c0.39-0.79,0.18-1.36-0.46-1.28C180.81,176.17,179.97,176.88,179.58,177.67z",
  "M175.88,178.15c-0.4,0.79-0.2,1.36,0.45,1.28c0.64-0.08,1.48-0.79,1.88-1.58c0.39-0.79,0.19-1.36-0.45-1.28C177.11,176.66,176.27,177.36,175.88,178.15z",
  "M172.18,178.64c-0.4,0.79-0.21,1.36,0.43,1.28c0.64-0.09,1.49-0.8,1.88-1.59c0.4-0.79,0.2-1.36-0.43-1.27C173.43,177.15,172.59,177.85,172.18,178.64z",
  "M168.5,179.14c-0.41,0.79-0.22,1.36,0.42,1.27c0.64-0.09,1.49-0.8,1.89-1.59c0.4-0.79,0.22-1.36-0.42-1.27C169.76,177.64,168.91,178.35,168.5,179.14z",
  "M164.83,179.65c-0.41,0.79-0.23,1.36,0.41,1.27c0.64-0.09,1.49-0.8,1.9-1.59c0.41-0.79,0.23-1.36-0.41-1.27C166.09,178.15,165.24,178.86,164.83,179.65z",
  "M161.17,180.17c-0.42,0.79-0.24,1.36,0.39,1.27c0.64-0.09,1.49-0.8,1.91-1.6c0.42-0.79,0.24-1.36-0.39-1.27C162.44,178.66,161.59,179.37,161.17,180.17z",
  "M157.51,180.69c-0.43,0.79-0.26,1.36,0.38,1.27c0.64-0.09,1.49-0.81,1.91-1.6c0.42-0.79,0.25-1.36-0.38-1.27C158.8,179.18,157.94,179.9,157.51,180.69z",
  "M153.87,181.22c-0.43,0.79-0.27,1.36,0.36,1.27c0.63-0.09,1.49-0.81,1.92-1.6c0.43-0.79,0.26-1.36-0.36-1.27C155.16,179.71,154.3,180.42,153.87,181.22z",
  "M150.24,181.75c-0.44,0.79-0.28,1.36,0.35,1.27c0.63-0.09,1.49-0.81,1.93-1.6c0.43-0.79,0.28-1.36-0.35-1.27C151.54,180.24,150.68,180.96,150.24,181.75z",
  "M146.62,182.3c-0.44,0.79-0.29,1.36,0.34,1.26c0.63-0.09,1.5-0.81,1.93-1.61c0.44-0.79,0.29-1.36-0.34-1.26C147.93,180.79,147.07,181.5,146.62,182.3z",
  "M143.02,182.85c-0.45,0.79-0.3,1.36,0.32,1.26c0.63-0.1,1.5-0.82,1.94-1.61c0.44-0.79,0.3-1.36-0.32-1.26C144.33,181.33,143.47,182.05,143.02,182.85z",
  "M139.42,183.41c-0.45,0.79-0.31,1.36,0.31,1.26c0.62-0.1,1.5-0.82,1.95-1.61c0.45-0.79,0.31-1.36-0.31-1.26C140.75,181.89,139.88,182.61,139.42,183.41z",
  "M135.84,183.97c-0.46,0.79-0.32,1.36,0.3,1.26c0.62-0.1,1.5-0.82,1.95-1.62c0.45-0.79,0.32-1.36-0.3-1.26C137.17,182.45,136.3,183.18,135.84,183.97z",
  "M132.27,184.54c-0.46,0.79-0.33,1.36,0.29,1.26c0.62-0.1,1.5-0.82,1.96-1.62c0.46-0.79,0.33-1.36-0.29-1.26C133.61,183.02,132.73,183.75,132.27,184.54z",
  "M128.71,185.12c-0.47,0.79-0.35,1.36,0.27,1.26c0.62-0.1,1.5-0.83,1.96-1.62c0.46-0.79,0.34-1.36-0.27-1.26C130.05,183.6,129.18,184.32,128.71,185.12z",
  "M125.16,185.7c-0.47,0.8-0.36,1.36,0.26,1.26c0.62-0.1,1.5-0.83,1.96-1.62c0.47-0.79,0.35-1.36-0.26-1.26C126.52,184.18,125.64,184.91,125.16,185.7z",
  "M121.63,186.29c-0.48,0.8-0.36,1.36,0.25,1.25c0.61-0.1,1.5-0.83,1.97-1.63c0.47-0.8,0.36-1.36-0.25-1.25C122.99,184.77,122.11,185.5,121.63,186.29z",
  "M118.11,186.89c-0.48,0.8-0.38,1.36,0.24,1.25c0.61-0.1,1.49-0.83,1.97-1.63c0.48-0.8,0.37-1.36-0.24-1.25C119.48,185.37,118.59,186.1,118.11,186.89z",
  "M114.61,187.49c-0.48,0.8-0.38,1.36,0.23,1.25c0.61-0.1,1.49-0.84,1.98-1.63c0.48-0.8,0.38-1.36-0.23-1.25C115.97,185.97,115.09,186.7,114.61,187.49z",
  "M111.11,188.1c-0.49,0.8-0.39,1.36,0.21,1.25c0.61-0.11,1.49-0.84,1.98-1.63c0.49-0.8,0.39-1.36-0.21-1.25C112.49,186.58,111.6,187.31,111.11,188.1z",
  "M107.63,188.72c-0.49,0.8-0.4,1.36,0.2,1.25c0.6-0.11,1.49-0.84,1.98-1.64c0.49-0.8,0.4-1.35-0.2-1.25C109.01,187.19,108.13,187.92,107.63,188.72z",
  "M104.17,189.34c-0.5,0.8-0.41,1.35,0.19,1.25c0.6-0.11,1.49-0.84,1.98-1.64c0.49-0.8,0.41-1.35-0.19-1.25C105.55,187.81,104.66,188.54,104.17,189.34z",
  "M100.72,189.97c-0.5,0.8-0.42,1.35,0.18,1.25c0.6-0.11,1.49-0.84,1.99-1.64c0.5-0.8,0.42-1.35-0.18-1.25C102.11,188.44,101.22,189.17,100.72,189.97z",
  "M97.28,190.6c-0.5,0.8-0.43,1.35,0.17,1.24c0.6-0.11,1.49-0.85,1.99-1.64c0.5-0.8,0.42-1.35-0.17-1.24C98.67,189.07,97.78,189.8,97.28,190.6z",
  "M93.86,191.24c-0.51,0.8-0.44,1.35,0.16,1.24c0.59-0.11,1.49-0.85,1.99-1.64c0.5-0.8,0.43-1.35-0.16-1.24C95.25,189.7,94.36,190.44,93.86,191.24z",
  "M90.45,191.88c-0.51,0.8-0.44,1.35,0.15,1.24c0.59-0.11,1.48-0.85,1.99-1.65c0.51-0.8,0.44-1.35-0.15-1.24C91.85,190.35,90.96,191.08,90.45,191.88z",
  "M87.06,192.53c-0.51,0.8-0.45,1.35,0.14,1.24c0.59-0.11,1.48-0.85,1.99-1.65c0.51-0.8,0.45-1.35-0.14-1.24C88.46,190.99,87.57,191.73,87.06,192.53z",
  "M83.68,193.18c-0.51,0.8-0.46,1.35,0.13,1.24c0.59-0.11,1.48-0.85,1.99-1.65c0.51-0.8,0.46-1.35-0.13-1.24C85.09,191.65,84.2,192.38,83.68,193.18z",
  "M80.32,193.84c-0.52,0.8-0.47,1.35,0.12,1.24c0.58-0.11,1.48-0.85,1.99-1.65c0.52-0.8,0.46-1.35-0.12-1.24C81.73,192.3,80.84,193.04,80.32,193.84z",
  "M76.98,194.5c-0.52,0.8-0.47,1.35,0.11,1.23c0.58-0.11,1.47-0.85,1.99-1.65c0.52-0.8,0.47-1.35-0.11-1.23C78.39,192.96,77.5,193.7,76.98,194.5z",
  "M73.65,195.17c-0.52,0.8-0.48,1.35,0.1,1.23c0.58-0.12,1.47-0.86,1.99-1.65c0.52-0.8,0.48-1.35-0.1-1.23C75.06,193.63,74.17,194.37,73.65,195.17z",
  "M70.33,195.84c-0.52,0.8-0.48,1.35,0.09,1.23c0.57-0.12,1.47-0.86,1.99-1.65c0.52-0.8,0.48-1.35-0.09-1.23C71.75,194.3,70.86,195.04,70.33,195.84z",
  "M67.04,196.52c-0.53,0.8-0.49,1.35,0.08,1.23c0.57-0.12,1.46-0.86,1.99-1.65c0.52-0.8,0.49-1.35-0.08-1.23C68.45,194.98,67.56,195.72,67.04,196.52z",
  "M63.76,197.2c-0.53,0.8-0.5,1.35,0.07,1.23c0.57-0.12,1.46-0.86,1.98-1.66c0.53-0.8,0.49-1.35-0.07-1.23C65.17,195.66,64.28,196.4,63.76,197.2z",
  "M624.23,180.7c0.5,0.79,1.39,1.52,1.98,1.62c0.59,0.1,0.66-0.47,0.15-1.26c-0.51-0.79-1.39-1.52-1.98-1.62C623.8,179.34,623.73,179.9,624.23,180.7z",
  "M620.83,180.14c0.5,0.79,1.39,1.52,1.98,1.61c0.59,0.1,0.66-0.47,0.16-1.26c-0.5-0.79-1.39-1.52-1.98-1.61C620.4,178.78,620.33,179.34,620.83,180.14z",
  "M617.42,179.58c0.5,0.79,1.38,1.52,1.98,1.61c0.59,0.1,0.67-0.47,0.17-1.26c-0.5-0.79-1.38-1.52-1.97-1.61C617,178.22,616.92,178.79,617.42,179.58z",
  "M596.67,176.39c0.47,0.79,1.34,1.5,1.95,1.59c0.61,0.09,0.71-0.48,0.24-1.27c-0.47-0.79-1.35-1.5-1.95-1.59C596.31,175.04,596.2,175.6,596.67,176.39z",
  "M593.17,175.89c0.47,0.79,1.34,1.5,1.95,1.59c0.61,0.09,0.72-0.48,0.25-1.27c-0.47-0.79-1.34-1.5-1.95-1.59C592.82,174.53,592.71,175.1,593.17,175.89z",
  "M589.66,175.38c0.46,0.79,1.33,1.5,1.94,1.59c0.61,0.09,0.73-0.48,0.27-1.27c-0.46-0.79-1.33-1.5-1.94-1.59C589.32,174.03,589.2,174.59,589.66,175.38z",
  "M586.14,174.89c0.45,0.79,1.32,1.5,1.93,1.58c0.61,0.09,0.74-0.49,0.28-1.27c-0.46-0.79-1.32-1.5-1.93-1.58C585.81,173.53,585.69,174.1,586.14,174.89z",
  "M582.61,174.4c0.45,0.79,1.31,1.5,1.93,1.58c0.61,0.08,0.74-0.49,0.29-1.28c-0.45-0.79-1.32-1.5-1.93-1.58C582.29,173.04,582.16,173.61,582.61,174.4z",
  "M579.07,173.92c0.44,0.79,1.31,1.5,1.92,1.58c0.62,0.08,0.75-0.49,0.3-1.28c-0.45-0.79-1.31-1.49-1.92-1.58C578.76,172.56,578.62,173.13,579.07,173.92z",
  "M575.51,173.45c0.44,0.79,1.3,1.49,1.92,1.57c0.62,0.08,0.76-0.49,0.32-1.28c-0.44-0.79-1.3-1.49-1.92-1.57C575.21,172.09,575.07,172.66,575.51,173.45z",
  "M571.95,172.98c0.43,0.79,1.29,1.49,1.91,1.57c0.62,0.08,0.77-0.49,0.33-1.28c-0.44-0.79-1.29-1.49-1.91-1.57C571.66,171.62,571.51,172.2,571.95,172.98z",
  "M568.37,172.52c0.43,0.79,1.28,1.49,1.9,1.57c0.62,0.08,0.78-0.49,0.34-1.28c-0.43-0.79-1.28-1.49-1.9-1.57C568.1,171.16,567.94,171.74,568.37,172.52z",
  "M564.78,172.07c0.42,0.79,1.27,1.49,1.89,1.56c0.62,0.08,0.79-0.5,0.36-1.28c-0.43-0.79-1.27-1.49-1.89-1.56C564.52,170.71,564.36,171.29,564.78,172.07z",
  "M561.19,171.63c0.42,0.78,1.26,1.48,1.89,1.56c0.63,0.08,0.79-0.5,0.37-1.28c-0.42-0.79-1.26-1.48-1.89-1.56C560.94,170.27,560.77,170.84,561.19,171.63z",
  "M557.58,171.19c0.41,0.78,1.25,1.48,1.88,1.56c0.63,0.08,0.8-0.5,0.39-1.28c-0.41-0.78-1.26-1.48-1.88-1.56C557.35,169.83,557.17,170.41,557.58,171.19z",
  "M553.97,170.76c0.4,0.78,1.24,1.48,1.87,1.55c0.63,0.07,0.81-0.5,0.4-1.29c-0.41-0.78-1.25-1.48-1.87-1.55C553.74,169.41,553.56,169.98,553.97,170.76z",
  "M550.34,170.34c0.4,0.78,1.23,1.48,1.86,1.55c0.63,0.07,0.82-0.5,0.42-1.29c-0.4-0.78-1.24-1.48-1.86-1.55C550.13,168.99,549.95,169.56,550.34,170.34z",
  "M546.71,169.93c0.39,0.78,1.22,1.48,1.85,1.55c0.63,0.07,0.83-0.51,0.43-1.29c-0.4-0.78-1.22-1.47-1.85-1.54C546.51,168.57,546.32,169.15,546.71,169.93z",
  "M543.06,169.53c0.38,0.78,1.21,1.47,1.84,1.54c0.63,0.07,0.83-0.51,0.44-1.29c-0.39-0.78-1.22-1.47-1.84-1.54C542.88,168.17,542.68,168.75,543.06,169.53z",
  "M539.41,169.13c0.38,0.78,1.2,1.47,1.84,1.54c0.64,0.07,0.84-0.51,0.46-1.29c-0.38-0.78-1.2-1.47-1.84-1.54C539.24,167.77,539.03,168.35,539.41,169.13z",
  "M535.75,168.74c0.37,0.78,1.19,1.47,1.83,1.54c0.64,0.07,0.85-0.51,0.47-1.29c-0.38-0.78-1.19-1.47-1.83-1.53C535.59,167.38,535.38,167.96,535.75,168.74z",
  "M532.08,168.36c0.36,0.78,1.18,1.47,1.82,1.53c0.64,0.07,0.86-0.51,0.49-1.29c-0.37-0.78-1.18-1.46-1.82-1.53C531.94,167,531.72,167.58,532.08,168.36z",
  "M528.4,167.99c0.36,0.78,1.17,1.46,1.81,1.53c0.64,0.06,0.87-0.52,0.5-1.3c-0.36-0.78-1.17-1.46-1.81-1.53C528.27,166.63,528.04,167.21,528.4,167.99z",
  "M524.71,167.63c0.35,0.78,1.15,1.46,1.8,1.52c0.64,0.06,0.87-0.52,0.52-1.3c-0.35-0.78-1.16-1.46-1.8-1.52C524.6,166.27,524.36,166.85,524.71,167.63z",
  "M521.02,167.27c0.34,0.78,1.14,1.46,1.79,1.52c0.64,0.06,0.88-0.52,0.54-1.3c-0.35-0.78-1.15-1.46-1.79-1.52C520.92,165.91,520.68,166.49,521.02,167.27z",
  "M517.32,166.92c0.34,0.78,1.13,1.46,1.78,1.52c0.65,0.06,0.89-0.52,0.55-1.3c-0.34-0.78-1.14-1.46-1.78-1.52C517.23,165.57,516.98,166.15,517.32,166.92z",
  "M513.6,166.59c0.33,0.78,1.12,1.46,1.77,1.51c0.65,0.06,0.9-0.53,0.57-1.3c-0.33-0.78-1.12-1.45-1.76-1.51C513.53,165.23,513.28,165.81,513.6,166.59z",
  "M509.89,166.26c0.32,0.78,1.11,1.45,1.75,1.51c0.65,0.06,0.91-0.53,0.58-1.3c-0.33-0.78-1.11-1.45-1.75-1.51C509.83,164.9,509.56,165.48,509.89,166.26z",
  "M506.16,165.94c0.31,0.78,1.09,1.45,1.74,1.51c0.65,0.06,0.92-0.53,0.6-1.31c-0.32-0.78-1.1-1.45-1.74-1.5C506.11,164.58,505.85,165.16,506.16,165.94z",
  "M502.43,165.62c0.31,0.78,1.08,1.45,1.73,1.5c0.65,0.05,0.93-0.53,0.61-1.31c-0.31-0.78-1.09-1.45-1.73-1.5C502.4,164.27,502.12,164.85,502.43,165.62z",
  "M498.69,165.32c0.3,0.77,1.07,1.45,1.72,1.5c0.65,0.05,0.93-0.53,0.63-1.31c-0.3-0.77-1.07-1.44-1.72-1.5C498.67,163.96,498.39,164.55,498.69,165.32z",
  "M494.94,165.02c0.29,0.77,1.05,1.44,1.71,1.49c0.65,0.05,0.94-0.54,0.65-1.31c-0.3-0.77-1.06-1.44-1.71-1.49C494.94,163.67,494.65,164.25,494.94,165.02z",
  "M491.18,164.74c0.28,0.77,1.04,1.44,1.7,1.49c0.65,0.05,0.95-0.54,0.66-1.31c-0.29-0.77-1.05-1.44-1.7-1.49C491.2,163.38,490.9,163.97,491.18,164.74z",
  "M487.42,164.46c0.27,0.77,1.03,1.44,1.68,1.49c0.66,0.05,0.96-0.54,0.68-1.31c-0.28-0.77-1.03-1.44-1.68-1.48C487.45,163.1,487.15,163.69,487.42,164.46z",
  "M483.66,164.19c0.27,0.77,1.01,1.44,1.67,1.48c0.66,0.05,0.97-0.54,0.7-1.32c-0.27-0.77-1.02-1.44-1.67-1.48C483.7,162.83,483.39,163.42,483.66,164.19z",
  "M479.88,163.94c0.26,0.77,1,1.44,1.66,1.48c0.66,0.04,0.98-0.55,0.71-1.32c-0.26-0.77-1.01-1.43-1.66-1.48C479.94,162.58,479.62,163.16,479.88,163.94z",
  "M476.1,163.69c0.25,0.77,0.99,1.43,1.65,1.48c0.66,0.04,0.98-0.55,0.73-1.32c-0.26-0.77-0.99-1.43-1.65-1.47C476.18,162.33,475.85,162.91,476.1,163.69z",
  "M472.32,163.45c0.24,0.77,0.97,1.43,1.63,1.47c0.66,0.04,0.99-0.55,0.74-1.32c-0.25-0.77-0.98-1.43-1.63-1.47C472.41,162.08,472.08,162.67,472.32,163.45z",
  "M468.53,163.21c0.23,0.77,0.96,1.43,1.62,1.47c0.66,0.04,1-0.55,0.76-1.32c-0.24-0.77-0.97-1.43-1.62-1.47C468.64,161.85,468.29,162.44,468.53,163.21z",
  "M464.73,162.99c0.23,0.77,0.95,1.43,1.61,1.46c0.66,0.04,1.01-0.56,0.78-1.33c-0.23-0.77-0.95-1.42-1.61-1.46C464.85,161.63,464.51,162.22,464.73,162.99z",
  "M460.93,162.78c0.22,0.77,0.93,1.42,1.59,1.46c0.66,0.04,1.02-0.56,0.79-1.33c-0.22-0.77-0.94-1.42-1.59-1.46C461.07,161.42,460.71,162.01,460.93,162.78z",
  "M457.12,162.58c0.21,0.77,0.92,1.42,1.58,1.46c0.66,0.03,1.03-0.56,0.81-1.33c-0.22-0.77-0.92-1.42-1.58-1.45C457.28,161.21,456.91,161.81,457.12,162.58z",
  "M453.31,162.38c0.2,0.77,0.9,1.42,1.57,1.45c0.66,0.03,1.04-0.56,0.83-1.33c-0.21-0.77-0.91-1.42-1.57-1.45C453.48,161.02,453.11,161.61,453.31,162.38z",
  "M449.5,162.2c0.19,0.77,0.89,1.42,1.55,1.45c0.67,0.03,1.04-0.57,0.84-1.34c-0.2-0.77-0.89-1.42-1.55-1.45C449.68,160.83,449.3,161.43,449.5,162.2z",
  "M445.68,162.02c0.18,0.77,0.87,1.42,1.54,1.45c0.67,0.03,1.05-0.57,0.86-1.34c-0.19-0.77-0.88-1.41-1.54-1.44C445.88,160.66,445.49,161.25,445.68,162.02z",
  "M441.85,161.86c0.18,0.77,0.86,1.41,1.52,1.44c0.67,0.03,1.06-0.57,0.88-1.34c-0.18-0.77-0.86-1.41-1.52-1.44C442.07,160.49,441.67,161.09,441.85,161.86z",
  "M438.02,161.7c0.17,0.77,0.84,1.41,1.51,1.44c0.67,0.03,1.07-0.57,0.9-1.34c-0.17-0.77-0.85-1.41-1.51-1.44C438.26,160.33,437.85,160.93,438.02,161.7z",
  "M434.19,161.55c0.16,0.77,0.83,1.41,1.5,1.44c0.67,0.03,1.08-0.58,0.91-1.34c-0.16-0.77-0.83-1.41-1.5-1.43C434.44,160.19,434.03,160.79,434.19,161.55z",
  "M430.35,161.42c0.15,0.77,0.81,1.41,1.48,1.43c0.67,0.02,1.08-0.58,0.93-1.35c-0.16-0.77-0.82-1.41-1.48-1.43C430.62,160.05,430.2,160.65,430.35,161.42z",
  "M426.51,161.29c0.14,0.77,0.8,1.41,1.47,1.43c0.67,0.02,1.09-0.58,0.95-1.35c-0.15-0.77-0.8-1.4-1.47-1.43C426.79,159.92,426.37,160.52,426.51,161.29z",
  "M422.66,161.17c0.13,0.77,0.78,1.41,1.45,1.43c0.67,0.02,1.1-0.59,0.96-1.35c-0.14-0.77-0.79-1.4-1.45-1.42C422.96,159.8,422.53,160.4,422.66,161.17z",
  "M418.82,161.06c0.12,0.77,0.77,1.4,1.44,1.42c0.67,0.02,1.11-0.59,0.98-1.35c-0.13-0.77-0.77-1.4-1.44-1.42C419.13,159.69,418.69,160.3,418.82,161.06z",
  "M414.97,160.96c0.11,0.77,0.75,1.4,1.42,1.42c0.67,0.02,1.12-0.59,1-1.36c-0.12-0.77-0.76-1.4-1.42-1.42C415.3,159.59,414.85,160.2,414.97,160.96z",
  "M411.11,160.88c0.11,0.77,0.74,1.4,1.41,1.41c0.67,0.02,1.13-0.59,1.01-1.36c-0.11-0.77-0.74-1.4-1.41-1.41C411.46,159.5,411.01,160.11,411.11,160.88z",
  "M407.26,160.8c0.1,0.76,0.72,1.4,1.39,1.41c0.67,0.01,1.13-0.6,1.03-1.36c-0.1-0.77-0.73-1.39-1.39-1.41C407.62,159.42,407.16,160.03,407.26,160.8z",
  "M403.4,160.73c0.09,0.76,0.7,1.4,1.38,1.41c0.67,0.01,1.14-0.6,1.05-1.37c-0.09-0.77-0.71-1.39-1.38-1.4C403.78,159.35,403.31,159.96,403.4,160.73z",
  "M399.54,160.67c0.08,0.77,0.69,1.39,1.36,1.4c0.67,0.01,1.15-0.6,1.06-1.37c-0.08-0.77-0.69-1.39-1.36-1.4C399.93,159.29,399.46,159.9,399.54,160.67z",
  "M395.67,160.62c0.07,0.77,0.67,1.39,1.35,1.4c0.67,0.01,1.16-0.61,1.08-1.37c-0.08-0.76-0.68-1.39-1.35-1.4C396.09,159.24,395.6,159.85,395.67,160.62z",
  "M391.81,160.58c0.06,0.76,0.66,1.39,1.33,1.4c0.67,0.01,1.17-0.61,1.1-1.37c-0.07-0.76-0.66-1.39-1.33-1.39C392.24,159.2,391.75,159.81,391.81,160.58z",
  "M387.94,160.55c0.05,0.76,0.64,1.39,1.31,1.39c0.67,0,1.17-0.61,1.12-1.38c-0.06-0.76-0.65-1.39-1.31-1.39C388.39,159.17,387.89,159.78,387.94,160.55z",
  "M384.07,160.53c0.04,0.76,0.62,1.39,1.3,1.39c0.67,0,1.18-0.62,1.13-1.38c-0.05-0.76-0.63-1.38-1.3-1.39C384.54,159.15,384.03,159.76,384.07,160.53z",
  "M380.2,160.52c0.03,0.76,0.61,1.39,1.28,1.39c0.67,0,1.19-0.62,1.15-1.38c-0.04-0.76-0.61-1.38-1.28-1.38C380.68,159.14,380.17,159.75,380.2,160.52z",
  "M376.33,160.52c0.02,0.76,0.59,1.39,1.27,1.39c0.67,0,1.2-0.62,1.17-1.39c-0.03-0.76-0.6-1.38-1.27-1.38C376.83,159.14,376.31,159.75,376.33,160.52z",
  "M372.46,160.53c0.02,0.76,0.58,1.38,1.25,1.38c0.68,0,1.2-0.62,1.18-1.39c-0.02-0.76-0.58-1.38-1.25-1.38C372.97,159.14,372.44,159.76,372.46,160.53z",
  "M368.58,160.55c0.01,0.76,0.56,1.38,1.23,1.38c0.68,0,1.21-0.63,1.2-1.39c-0.01-0.76-0.57-1.38-1.23-1.38C369.11,159.16,368.58,159.78,368.58,160.55z",
  "M364.71,160.58c0,0.76,0.54,1.38,1.22,1.38c0.68-0.01,1.22-0.63,1.21-1.4c0-0.76-0.55-1.38-1.22-1.37C365.26,159.19,364.71,159.81,364.71,160.58z",
  "M360.84,160.61c-0.01,0.76,0.53,1.38,1.2,1.37c0.68-0.01,1.23-0.63,1.23-1.4c0-0.76-0.53-1.38-1.2-1.37C361.4,159.23,360.85,159.85,360.84,160.61z",
  "M356.96,160.66c-0.02,0.76,0.51,1.38,1.19,1.37c0.68-0.01,1.23-0.64,1.25-1.4c0.01-0.76-0.52-1.38-1.19-1.37C357.54,159.27,356.98,159.9,356.96,160.66z",
  "M353.09,160.72c-0.03,0.76,0.49,1.38,1.17,1.37c0.68-0.01,1.24-0.64,1.26-1.4c0.02-0.76-0.5-1.37-1.17-1.36C353.68,159.33,353.12,159.96,353.09,160.72z",
  "M349.21,160.79c-0.04,0.76,0.48,1.38,1.15,1.36c0.68-0.01,1.25-0.64,1.28-1.41c0.03-0.76-0.48-1.37-1.15-1.36C349.82,159.4,349.25,160.03,349.21,160.79z",
  "M345.34,160.87c-0.05,0.76,0.46,1.38,1.14,1.36c0.68-0.01,1.26-0.65,1.3-1.41c0.04-0.76-0.47-1.37-1.14-1.36C345.97,159.48,345.39,160.1,345.34,160.87z",
  "M245.35,166.32c-0.27,0.77,0.05,1.36,0.71,1.3c0.66-0.06,1.41-0.73,1.68-1.51c0.26-0.77-0.05-1.35-0.71-1.3C246.37,164.87,245.62,165.54,245.35,166.32z",
  "M241.55,166.65c-0.28,0.78,0.03,1.36,0.69,1.3c0.66-0.06,1.42-0.74,1.69-1.51c0.27-0.77-0.04-1.35-0.69-1.3C242.59,165.2,241.83,165.88,241.55,166.65z",
  "M237.77,167c-0.29,0.78,0.02,1.36,0.68,1.3c0.66-0.06,1.42-0.74,1.7-1.51c0.28-0.78-0.02-1.35-0.68-1.29C238.81,165.55,238.05,166.22,237.77,167z",
  "M233.98,167.35c-0.29,0.78,0,1.36,0.66,1.29c0.66-0.06,1.43-0.74,1.71-1.52c0.29-0.78-0.01-1.35-0.66-1.29C235.04,165.9,234.28,166.58,233.98,167.35z",
  "M230.21,167.72c-0.3,0.78-0.01,1.36,0.65,1.29c0.66-0.06,1.43-0.75,1.72-1.52c0.29-0.78,0.01-1.35-0.65-1.29C231.28,166.26,230.51,166.94,230.21,167.72z",
  "M226.44,168.09c-0.31,0.78-0.03,1.36,0.63,1.29c0.66-0.07,1.43-0.75,1.74-1.53c0.3-0.78,0.02-1.35-0.63-1.29C227.53,166.63,226.75,167.31,226.44,168.09z",
  "M222.68,168.47c-0.32,0.78-0.04,1.36,0.61,1.29c0.66-0.07,1.44-0.75,1.75-1.53c0.31-0.78,0.03-1.35-0.61-1.29C223.78,167.01,222.99,167.69,222.68,168.47z",
  "M218.92,168.86c-0.32,0.78-0.05,1.36,0.6,1.29c0.65-0.07,1.44-0.76,1.76-1.53c0.32-0.78,0.05-1.35-0.6-1.29C220.03,167.4,219.25,168.08,218.92,168.86z",
  "M215.18,169.26c-0.33,0.78-0.07,1.36,0.58,1.29c0.65-0.07,1.45-0.76,1.77-1.54c0.32-0.78,0.06-1.35-0.58-1.28C216.3,167.79,215.51,168.48,215.18,169.26z",
  "M211.43,169.66c-0.34,0.78-0.08,1.36,0.57,1.28c0.65-0.07,1.45-0.76,1.78-1.54c0.33-0.78,0.08-1.35-0.57-1.28C212.57,168.2,211.77,168.88,211.43,169.66z",
  "M203.97,170.5c-0.35,0.78-0.11,1.36,0.54,1.28c0.65-0.07,1.46-0.77,1.8-1.55c0.35-0.78,0.11-1.35-0.54-1.28C205.13,169.03,204.33,169.72,203.97,170.5z",
  "M200.26,170.94c-0.36,0.78-0.12,1.36,0.52,1.28c0.65-0.08,1.46-0.77,1.81-1.55c0.35-0.78,0.12-1.35-0.52-1.28C201.43,169.46,200.61,170.15,200.26,170.94z",
  "M196.55,171.38c-0.37,0.78-0.14,1.36,0.51,1.28c0.65-0.08,1.46-0.77,1.82-1.56c0.36-0.78,0.13-1.35-0.51-1.28C197.73,169.9,196.91,170.59,196.55,171.38z",
  "M192.84,171.83c-0.37,0.78-0.15,1.35,0.49,1.28c0.64-0.08,1.46-0.78,1.83-1.56c0.37-0.78,0.15-1.35-0.49-1.27C194.03,170.35,193.21,171.04,192.84,171.83z",
  "M189.15,172.28c-0.38,0.78-0.16,1.36,0.48,1.28c0.64-0.08,1.47-0.78,1.84-1.56c0.37-0.78,0.16-1.35-0.48-1.27C190.35,170.8,189.53,171.5,189.15,172.28z",
  "M185.46,172.75c-0.38,0.78-0.18,1.35,0.46,1.27c0.64-0.08,1.47-0.78,1.85-1.57c0.38-0.78,0.17-1.35-0.46-1.27C186.68,171.26,185.85,171.96,185.46,172.75z",
  "M181.79,173.22c-0.39,0.78-0.19,1.36,0.45,1.27c0.64-0.08,1.47-0.79,1.86-1.57c0.39-0.78,0.19-1.35-0.45-1.27C183.01,171.73,182.18,172.44,181.79,173.22z",
  "M178.12,173.7c-0.4,0.79-0.2,1.35,0.44,1.27c0.64-0.08,1.47-0.79,1.87-1.57c0.39-0.79,0.2-1.35-0.44-1.27C179.35,172.21,178.52,172.92,178.12,173.7z",
  "M174.46,174.19c-0.4,0.79-0.22,1.35,0.42,1.27c0.64-0.09,1.48-0.79,1.88-1.58c0.4-0.79,0.21-1.35-0.42-1.27C175.7,172.7,174.87,173.4,174.46,174.19z",
  "M170.81,174.69c-0.41,0.79-0.23,1.35,0.41,1.27c0.63-0.09,1.48-0.79,1.88-1.58c0.41-0.79,0.22-1.35-0.41-1.27C172.07,173.19,171.22,173.9,170.81,174.69z",
  "M167.17,175.19c-0.42,0.79-0.24,1.35,0.39,1.27c0.63-0.09,1.48-0.8,1.89-1.58c0.41-0.79,0.23-1.35-0.39-1.27C168.44,173.7,167.59,174.4,167.17,175.19z",
  "M163.54,175.7c-0.42,0.79-0.25,1.35,0.38,1.27c0.63-0.09,1.48-0.8,1.9-1.59c0.42-0.79,0.25-1.35-0.38-1.26C164.82,174.21,163.97,174.91,163.54,175.7z",
  "M159.92,176.22c-0.43,0.79-0.26,1.35,0.37,1.26c0.63-0.09,1.48-0.8,1.91-1.59c0.42-0.79,0.26-1.35-0.37-1.26C161.2,174.72,160.35,175.43,159.92,176.22z",
  "M156.32,176.75c-0.43,0.79-0.28,1.35,0.35,1.26c0.63-0.09,1.48-0.81,1.91-1.59c0.43-0.79,0.27-1.35-0.35-1.26C157.6,175.25,156.75,175.96,156.32,176.75z",
  "M152.72,177.28c-0.44,0.79-0.29,1.35,0.34,1.26c0.63-0.09,1.49-0.81,1.92-1.6c0.43-0.79,0.28-1.35-0.34-1.26C154.01,175.78,153.15,176.49,152.72,177.28z",
  "M149.13,177.82c-0.44,0.79-0.3,1.35,0.33,1.26c0.62-0.09,1.49-0.81,1.93-1.6c0.44-0.79,0.29-1.35-0.33-1.26C150.43,176.32,149.57,177.03,149.13,177.82z",
  "M145.55,178.37c-0.45,0.79-0.31,1.35,0.31,1.26c0.62-0.1,1.49-0.81,1.93-1.6c0.44-0.79,0.3-1.35-0.31-1.26C146.86,176.86,146,177.58,145.55,178.37z",
  "M141.98,178.93c-0.45,0.79-0.32,1.35,0.3,1.26c0.62-0.1,1.49-0.82,1.94-1.61c0.45-0.79,0.32-1.35-0.3-1.26C143.31,177.42,142.44,178.14,141.98,178.93z",
  "M138.43,179.49c-0.46,0.79-0.33,1.35,0.29,1.26c0.62-0.1,1.49-0.82,1.94-1.61c0.46-0.79,0.33-1.35-0.29-1.26C139.76,177.98,138.89,178.7,138.43,179.49z",
  "M134.89,180.06c-0.46,0.79-0.34,1.35,0.28,1.25c0.62-0.1,1.49-0.82,1.95-1.61c0.46-0.79,0.34-1.35-0.28-1.25C136.22,178.54,135.35,179.27,134.89,180.06z",
  "M131.35,180.63c-0.47,0.79-0.35,1.35,0.26,1.25c0.61-0.1,1.49-0.82,1.95-1.62c0.46-0.79,0.35-1.35-0.26-1.25C132.7,179.12,131.82,179.84,131.35,180.63z",
  "M127.83,181.22c-0.47,0.79-0.36,1.35,0.25,1.25c0.61-0.1,1.49-0.83,1.96-1.62c0.47-0.79,0.36-1.35-0.25-1.25C129.18,179.7,128.31,180.42,127.83,181.22z",
  "M124.33,181.8c-0.48,0.79-0.37,1.35,0.24,1.25c0.61-0.1,1.49-0.83,1.96-1.62c0.47-0.79,0.37-1.35-0.24-1.25C125.68,180.29,124.8,181.01,124.33,181.8z",
  "M120.83,182.4c-0.48,0.79-0.38,1.35,0.23,1.25c0.61-0.1,1.49-0.83,1.97-1.62c0.48-0.79,0.38-1.35-0.23-1.25C122.19,180.88,121.31,181.61,120.83,182.4z",
  "M117.35,183c-0.49,0.79-0.39,1.35,0.22,1.25c0.61-0.1,1.49-0.83,1.97-1.63c0.48-0.79,0.39-1.35-0.22-1.25C118.71,181.48,117.83,182.21,117.35,183z",
  "M113.88,183.61c-0.49,0.79-0.4,1.35,0.2,1.25c0.6-0.11,1.49-0.84,1.97-1.63c0.49-0.79,0.39-1.35-0.21-1.25C115.25,182.09,114.37,182.81,113.88,183.61z",
  "M110.42,184.22c-0.49,0.79-0.41,1.35,0.19,1.25c0.6-0.11,1.49-0.84,1.98-1.63c0.49-0.79,0.4-1.35-0.19-1.25C111.8,182.7,110.91,183.43,110.42,184.22z",
  "M106.97,184.84c-0.5,0.79-0.42,1.35,0.18,1.24c0.6-0.11,1.48-0.84,1.98-1.63c0.49-0.79,0.41-1.35-0.18-1.24C108.36,183.32,107.47,184.05,106.97,184.84z",
  "M103.54,185.47c-0.5,0.8-0.42,1.35,0.17,1.24c0.6-0.11,1.48-0.84,1.98-1.64c0.5-0.8,0.42-1.35-0.17-1.24C104.93,183.94,104.04,184.67,103.54,185.47z",
  "M100.13,186.1c-0.5,0.8-0.43,1.35,0.16,1.24c0.59-0.11,1.48-0.84,1.98-1.64c0.5-0.79,0.43-1.35-0.16-1.24C101.52,184.57,100.63,185.3,100.13,186.1z",
  "M96.72,186.73c-0.51,0.8-0.44,1.35,0.15,1.24c0.59-0.11,1.48-0.85,1.98-1.64c0.5-0.8,0.44-1.35-0.15-1.24C98.12,185.2,97.23,185.94,96.72,186.73z",
  "M93.33,187.38c-0.51,0.8-0.45,1.35,0.14,1.24c0.59-0.11,1.48-0.85,1.99-1.64c0.51-0.8,0.44-1.35-0.14-1.24C94.73,185.85,93.84,186.58,93.33,187.38z",
  "M89.96,188.02c-0.51,0.8-0.45,1.35,0.13,1.24c0.59-0.11,1.48-0.85,1.99-1.64c0.51-0.8,0.45-1.35-0.13-1.24C91.36,186.49,90.47,187.23,89.96,188.02z",
  "M86.59,188.68c-0.52,0.8-0.46,1.35,0.12,1.24c0.58-0.11,1.47-0.85,1.99-1.65c0.51-0.8,0.46-1.35-0.12-1.24C88,187.14,87.11,187.88,86.59,188.68z",
  "M83.25,189.34c-0.52,0.8-0.47,1.35,0.11,1.23c0.58-0.11,1.47-0.85,1.99-1.65c0.52-0.8,0.46-1.35-0.11-1.23C84.66,187.8,83.77,188.54,83.25,189.34z",
  "M79.92,190c-0.52,0.8-0.47,1.35,0.1,1.23c0.58-0.11,1.47-0.85,1.99-1.65c0.52-0.8,0.47-1.35-0.11-1.23C81.33,188.46,80.44,189.2,79.92,190z",
  "M76.6,190.67c-0.52,0.8-0.48,1.35,0.1,1.23c0.58-0.12,1.47-0.86,1.99-1.65c0.52-0.8,0.48-1.35-0.1-1.23C78.01,189.13,77.12,189.87,76.6,190.67z",
  "M73.3,191.34c-0.52,0.8-0.49,1.35,0.09,1.23c0.57-0.12,1.46-0.86,1.99-1.65c0.52-0.8,0.48-1.35-0.09-1.23C74.71,189.8,73.82,190.54,73.3,191.34z",
  "M70.01,192.02c-0.53,0.8-0.49,1.35,0.08,1.23c0.57-0.12,1.46-0.86,1.98-1.65c0.53-0.8,0.49-1.35-0.08-1.23C71.43,190.48,70.54,191.22,70.01,192.02z",
  "M638.1,179.09c0.52,0.8,1.41,1.52,1.98,1.63c0.57,0.1,0.62-0.46,0.09-1.25c-0.52-0.8-1.41-1.52-1.98-1.63C637.62,177.73,637.58,178.29,638.1,179.09z",
  "M624.75,176.77c0.51,0.79,1.39,1.52,1.98,1.62c0.58,0.1,0.64-0.46,0.13-1.26c-0.51-0.79-1.4-1.52-1.98-1.61C624.3,175.42,624.24,175.98,624.75,176.77z",
  "M621.38,176.21c0.5,0.79,1.39,1.51,1.97,1.61c0.59,0.1,0.65-0.47,0.14-1.26c-0.51-0.79-1.39-1.51-1.97-1.61C620.94,174.85,620.88,175.42,621.38,176.21z",
  "M618,175.65c0.5,0.79,1.38,1.51,1.97,1.61c0.59,0.1,0.66-0.47,0.15-1.26c-0.5-0.79-1.39-1.51-1.97-1.61C617.57,174.3,617.5,174.86,618,175.65z",
  "M583.57,170.44c0.46,0.79,1.32,1.49,1.93,1.58c0.61,0.09,0.73-0.48,0.27-1.27c-0.46-0.79-1.32-1.49-1.93-1.58C583.23,169.09,583.11,169.65,583.57,170.44z",
  "M580.06,169.95c0.45,0.78,1.31,1.49,1.92,1.57c0.61,0.08,0.74-0.49,0.28-1.27c-0.45-0.79-1.31-1.49-1.92-1.57C579.74,168.6,579.61,169.17,580.06,169.95z",
  "M576.55,169.48c0.45,0.78,1.3,1.49,1.91,1.57c0.61,0.08,0.74-0.49,0.29-1.27c-0.45-0.78-1.31-1.49-1.91-1.57C576.24,168.13,576.1,168.69,576.55,169.48z",
  "M573.03,169.01c0.44,0.78,1.29,1.49,1.91,1.57c0.61,0.08,0.75-0.49,0.31-1.27c-0.45-0.78-1.3-1.48-1.91-1.57C572.72,167.66,572.59,168.23,573.03,169.01z",
  "M569.49,168.55c0.43,0.78,1.28,1.48,1.9,1.56c0.61,0.08,0.76-0.49,0.32-1.27c-0.44-0.78-1.29-1.48-1.9-1.56C569.2,167.19,569.06,167.76,569.49,168.55z",
  "M565.95,168.09c0.43,0.78,1.28,1.48,1.89,1.56c0.62,0.08,0.77-0.49,0.33-1.27c-0.43-0.78-1.28-1.48-1.89-1.56C565.67,166.74,565.52,167.31,565.95,168.09z",
  "M562.4,167.64c0.42,0.78,1.27,1.48,1.89,1.56c0.62,0.08,0.77-0.49,0.35-1.28c-0.43-0.78-1.27-1.48-1.88-1.55C562.13,166.29,561.97,166.86,562.4,167.64z",
  "M558.83,167.2c0.42,0.78,1.26,1.48,1.88,1.55c0.62,0.08,0.78-0.5,0.36-1.28c-0.42-0.78-1.26-1.47-1.88-1.55C558.58,165.85,558.42,166.42,558.83,167.2z",
  "M555.26,166.77c0.41,0.78,1.25,1.47,1.87,1.55c0.62,0.07,0.79-0.5,0.38-1.28c-0.42-0.78-1.25-1.47-1.87-1.55C555.02,165.42,554.85,165.99,555.26,166.77z",
  "M551.68,166.35c0.4,0.78,1.24,1.47,1.86,1.54c0.62,0.07,0.8-0.5,0.39-1.28c-0.41-0.78-1.24-1.47-1.86-1.54C551.45,165,551.28,165.57,551.68,166.35z",
  "M548.09,165.93c0.4,0.78,1.23,1.47,1.85,1.54c0.63,0.07,0.81-0.5,0.4-1.28c-0.4-0.78-1.23-1.47-1.85-1.54C547.88,164.58,547.7,165.15,548.09,165.93z",
  "M544.5,165.52c0.39,0.78,1.22,1.47,1.84,1.54c0.63,0.07,0.81-0.5,0.42-1.28c-0.4-0.78-1.22-1.46-1.84-1.54C544.29,164.17,544.1,164.75,544.5,165.52z",
  "M540.89,165.12c0.38,0.78,1.21,1.46,1.83,1.53c0.63,0.07,0.82-0.51,0.43-1.28c-0.39-0.78-1.21-1.46-1.83-1.53C540.7,163.77,540.5,164.35,540.89,165.12z",
  "M537.27,164.73c0.38,0.78,1.2,1.46,1.82,1.53c0.63,0.07,0.83-0.51,0.45-1.28c-0.38-0.78-1.2-1.46-1.82-1.53C537.1,163.38,536.9,163.95,537.27,164.73z",
  "M533.65,164.34c0.37,0.78,1.18,1.46,1.82,1.53c0.63,0.07,0.84-0.51,0.46-1.29c-0.38-0.78-1.19-1.46-1.81-1.52C533.49,163,533.28,163.57,533.65,164.34z",
  "M530.02,163.97c0.36,0.77,1.17,1.46,1.81,1.52c0.63,0.07,0.85-0.51,0.48-1.29c-0.37-0.78-1.18-1.45-1.8-1.52C529.87,162.62,529.65,163.19,530.02,163.97z",
  "M526.38,163.6c0.36,0.77,1.16,1.45,1.8,1.52c0.63,0.06,0.85-0.51,0.49-1.29c-0.36-0.77-1.17-1.45-1.79-1.52C526.24,162.25,526.02,162.83,526.38,163.6z",
  "M522.73,163.24c0.35,0.77,1.15,1.45,1.78,1.51c0.64,0.06,0.86-0.52,0.51-1.29c-0.36-0.77-1.15-1.45-1.78-1.51C522.61,161.89,522.38,162.47,522.73,163.24z",
  "M519.08,162.89c0.34,0.77,1.14,1.45,1.77,1.51c0.64,0.06,0.87-0.52,0.52-1.29c-0.35-0.77-1.14-1.45-1.77-1.51C518.97,161.54,518.73,162.12,519.08,162.89z",
  "M515.42,162.55c0.34,0.77,1.12,1.45,1.76,1.51c0.64,0.06,0.88-0.52,0.54-1.29c-0.34-0.77-1.13-1.44-1.76-1.5C515.32,161.2,515.08,161.77,515.42,162.55z",
  "M511.75,162.21c0.33,0.77,1.11,1.44,1.75,1.5c0.64,0.06,0.89-0.52,0.55-1.29c-0.33-0.77-1.12-1.44-1.75-1.5C511.67,160.86,511.42,161.44,511.75,162.21z",
  "M508.07,161.88c0.32,0.77,1.1,1.44,1.74,1.5c0.64,0.06,0.89-0.52,0.57-1.29c-0.33-0.77-1.11-1.44-1.74-1.5C508,160.54,507.75,161.11,508.07,161.88z",
  "M504.39,161.57c0.31,0.77,1.09,1.44,1.73,1.49c0.64,0.05,0.9-0.53,0.58-1.3c-0.32-0.77-1.09-1.44-1.73-1.49C504.33,160.22,504.07,160.8,504.39,161.57z",
  "M500.69,161.26c0.31,0.77,1.08,1.44,1.72,1.49c0.64,0.05,0.91-0.53,0.6-1.3c-0.31-0.77-1.08-1.43-1.72-1.49C500.66,159.91,500.39,160.49,500.69,161.26z",
  "M497,160.96c0.3,0.77,1.06,1.44,1.71,1.49c0.64,0.05,0.92-0.53,0.62-1.3c-0.3-0.77-1.07-1.43-1.71-1.48C496.98,159.61,496.7,160.19,497,160.96z",
  "M493.29,160.67c0.29,0.77,1.05,1.43,1.7,1.48c0.64,0.05,0.93-0.53,0.63-1.3c-0.3-0.77-1.06-1.43-1.69-1.48C493.29,159.32,493,159.9,493.29,160.67z",
  "M489.58,160.38c0.28,0.77,1.04,1.43,1.68,1.48c0.65,0.05,0.94-0.54,0.65-1.3c-0.29-0.77-1.04-1.43-1.68-1.48C489.59,159.04,489.3,159.62,489.58,160.38z",
  "M485.87,160.11c0.27,0.77,1.02,1.43,1.67,1.48c0.65,0.05,0.94-0.54,0.66-1.3c-0.28-0.77-1.03-1.43-1.67-1.47C485.89,158.76,485.59,159.34,485.87,160.11z",
  "M482.15,159.85c0.27,0.77,1.01,1.43,1.66,1.47c0.65,0.05,0.95-0.54,0.68-1.31c-0.27-0.77-1.02-1.42-1.66-1.47C482.18,158.5,481.88,159.08,482.15,159.85z",
  "M478.42,159.59c0.26,0.77,1,1.42,1.65,1.47c0.65,0.04,0.96-0.54,0.7-1.31c-0.27-0.77-1-1.42-1.64-1.46C478.47,158.24,478.16,158.82,478.42,159.59z",
  "M474.68,159.34c0.25,0.76,0.98,1.42,1.63,1.46c0.65,0.04,0.97-0.54,0.71-1.31c-0.26-0.77-0.99-1.42-1.63-1.46C474.75,157.99,474.43,158.58,474.68,159.34z",
  "M470.95,159.11c0.24,0.76,0.97,1.42,1.62,1.46c0.65,0.04,0.98-0.55,0.73-1.31c-0.25-0.76-0.97-1.42-1.62-1.46C471.03,157.76,470.7,158.34,470.95,159.11z",
  "M467.2,158.88c0.23,0.76,0.95,1.42,1.61,1.46c0.65,0.04,0.99-0.55,0.75-1.31c-0.24-0.76-0.96-1.41-1.61-1.45C467.3,157.53,466.97,158.11,467.2,158.88z",
  "M463.45,158.66c0.23,0.76,0.94,1.41,1.59,1.45c0.65,0.04,0.99-0.55,0.76-1.32c-0.23-0.76-0.95-1.41-1.59-1.45C463.57,157.31,463.23,157.89,463.45,158.66z",
  "M459.7,158.45c0.22,0.76,0.92,1.41,1.58,1.45c0.65,0.04,1-0.55,0.78-1.32c-0.22-0.76-0.93-1.41-1.58-1.45C459.83,157.1,459.48,157.68,459.7,158.45z",
  "M455.94,158.25c0.21,0.76,0.91,1.41,1.57,1.44c0.66,0.03,1.01-0.56,0.8-1.32c-0.22-0.76-0.92-1.41-1.57-1.44C456.09,156.9,455.73,157.48,455.94,158.25z",
  "M452.17,158.06c0.2,0.76,0.9,1.41,1.55,1.44c0.66,0.03,1.02-0.56,0.81-1.32c-0.21-0.76-0.9-1.41-1.55-1.44C452.34,156.7,451.97,157.29,452.17,158.06z",
  "M448.41,157.87c0.19,0.76,0.88,1.41,1.54,1.44c0.66,0.03,1.03-0.56,0.83-1.32c-0.2-0.76-0.89-1.4-1.54-1.43C448.59,156.52,448.21,157.11,448.41,157.87z",
  "M444.63,157.7c0.18,0.76,0.87,1.4,1.52,1.43c0.66,0.03,1.04-0.56,0.85-1.33c-0.19-0.76-0.87-1.4-1.52-1.43C444.83,156.35,444.45,156.94,444.63,157.7z",
  "M440.86,157.54c0.18,0.76,0.85,1.4,1.51,1.43c0.66,0.03,1.04-0.57,0.86-1.33c-0.18-0.76-0.86-1.4-1.51-1.43C441.07,156.18,440.68,156.78,440.86,157.54z",
  "M437.08,157.38c0.17,0.76,0.84,1.4,1.5,1.43c0.66,0.03,1.05-0.57,0.88-1.33c-0.17-0.76-0.84-1.4-1.49-1.42C437.3,156.03,436.91,156.62,437.08,157.38z",
  "M433.29,157.24c0.16,0.76,0.82,1.4,1.48,1.42c0.66,0.03,1.06-0.57,0.9-1.33c-0.16-0.76-0.83-1.39-1.48-1.42C433.54,155.88,433.13,156.48,433.29,157.24z",
  "M429.5,157.1c0.15,0.76,0.81,1.4,1.47,1.42c0.66,0.02,1.07-0.58,0.91-1.34c-0.16-0.76-0.81-1.39-1.47-1.42C429.76,155.75,429.35,156.34,429.5,157.1z",
  "M425.71,156.98c0.14,0.76,0.79,1.39,1.45,1.42c0.66,0.02,1.08-0.58,0.93-1.34c-0.15-0.76-0.8-1.39-1.45-1.41C425.99,155.62,425.57,156.22,425.71,156.98z",
  "M421.92,156.86c0.13,0.76,0.77,1.39,1.44,1.41c0.66,0.02,1.09-0.58,0.95-1.34c-0.14-0.76-0.78-1.39-1.44-1.41C422.21,155.5,421.79,156.1,421.92,156.86z",
  "M418.12,156.75c0.12,0.76,0.76,1.39,1.42,1.41c0.66,0.02,1.09-0.58,0.96-1.34c-0.13-0.76-0.77-1.39-1.42-1.41C418.43,155.4,418,155.99,418.12,156.75z",
  "M414.32,156.66c0.11,0.76,0.74,1.39,1.41,1.4c0.66,0.02,1.1-0.59,0.98-1.35c-0.12-0.76-0.75-1.39-1.41-1.4C414.64,155.3,414.2,155.9,414.32,156.66z",
  "M410.52,156.57c0.1,0.76,0.73,1.39,1.39,1.4c0.66,0.01,1.11-0.59,1-1.35c-0.11-0.76-0.73-1.38-1.39-1.4C410.86,155.21,410.41,155.81,410.52,156.57z",
  "M406.71,156.49c0.1,0.76,0.71,1.38,1.38,1.4c0.66,0.01,1.12-0.59,1.01-1.35c-0.1-0.76-0.72-1.38-1.38-1.39C407.07,155.13,406.61,155.73,406.71,156.49z",
  "M402.9,156.42c0.09,0.76,0.7,1.38,1.36,1.39c0.66,0.01,1.13-0.6,1.03-1.35c-0.09-0.76-0.7-1.38-1.36-1.39C403.28,155.06,402.81,155.66,402.9,156.42z",
  "M399.09,156.36c0.08,0.76,0.68,1.38,1.35,1.39c0.66,0.01,1.13-0.6,1.05-1.36c-0.09-0.76-0.69-1.38-1.34-1.39C399.48,155,399.01,155.61,399.09,156.36z",
  "M395.28,156.32c0.07,0.76,0.66,1.38,1.33,1.39c0.66,0.01,1.14-0.6,1.07-1.36c-0.08-0.76-0.67-1.38-1.33-1.38C395.69,154.95,395.21,155.56,395.28,156.32z",
  "M391.46,156.28c0.06,0.76,0.65,1.38,1.31,1.38c0.66,0.01,1.15-0.6,1.08-1.36c-0.07-0.76-0.66-1.37-1.31-1.38C391.89,154.91,391.4,155.52,391.46,156.28z",
  "M387.65,156.25c0.05,0.76,0.63,1.38,1.3,1.38c0.67,0,1.16-0.61,1.1-1.36c-0.06-0.76-0.64-1.37-1.3-1.38C388.09,154.88,387.59,155.49,387.65,156.25z",
  "M383.83,156.23c0.04,0.76,0.62,1.38,1.28,1.38c0.67,0,1.17-0.61,1.12-1.37c-0.05-0.76-0.62-1.37-1.28-1.38C384.29,154.86,383.79,155.47,383.83,156.23z",
  "M380.01,156.22c0.03,0.76,0.6,1.37,1.27,1.38c0.67,0,1.17-0.61,1.13-1.37c-0.04-0.76-0.61-1.37-1.27-1.37C380.48,154.85,379.98,155.46,380.01,156.22z",
  "M376.19,156.22c0.02,0.76,0.58,1.37,1.25,1.37c0.67,0,1.18-0.62,1.15-1.37c-0.03-0.76-0.59-1.37-1.25-1.37C376.68,154.85,376.16,155.46,376.19,156.22z",
  "M372.37,156.23c0.02,0.76,0.57,1.37,1.23,1.37c0.67,0,1.19-0.62,1.17-1.38c-0.02-0.76-0.57-1.37-1.23-1.37C372.87,154.86,372.35,155.47,372.37,156.23z",
  "M368.55,156.25c0.01,0.76,0.55,1.37,1.22,1.37c0.67,0,1.2-0.62,1.18-1.38c-0.01-0.76-0.56-1.37-1.22-1.36C369.07,154.87,368.54,155.49,368.55,156.25z",
  "M364.72,156.27c0,0.76,0.54,1.37,1.2,1.36c0.67-0.01,1.2-0.63,1.2-1.38c0-0.76-0.54-1.36-1.2-1.36C365.26,154.9,364.72,155.52,364.72,156.27z",
  "M360.9,156.31c-0.01,0.76,0.52,1.37,1.19,1.36c0.67-0.01,1.21-0.63,1.22-1.39c0-0.76-0.53-1.36-1.19-1.36C361.46,154.94,360.91,155.56,360.9,156.31z",
  "M357.08,156.36c-0.02,0.76,0.5,1.37,1.17,1.36c0.67-0.01,1.22-0.63,1.23-1.39c0.01-0.76-0.51-1.36-1.17-1.35C357.65,154.99,357.1,155.6,357.08,156.36z",
  "M345.6,156.57c-0.05,0.76,0.45,1.36,1.12,1.35c0.67-0.01,1.24-0.64,1.28-1.4c0.04-0.76-0.46-1.36-1.12-1.35C346.23,155.18,345.65,155.81,345.6,156.57z",
  "M246.87,161.95c-0.27,0.77,0.04,1.35,0.69,1.29c0.65-0.06,1.4-0.73,1.66-1.5c0.26-0.77-0.05-1.34-0.69-1.29C247.88,160.52,247.14,161.19,246.87,161.95z",
  "M243.12,162.29c-0.28,0.77,0.03,1.35,0.68,1.29c0.65-0.06,1.4-0.73,1.68-1.5c0.27-0.77-0.03-1.34-0.68-1.29C244.15,160.85,243.4,161.52,243.12,162.29z",
  "M239.38,162.63c-0.29,0.77,0.01,1.35,0.66,1.29c0.65-0.06,1.41-0.73,1.69-1.5c0.28-0.77-0.02-1.34-0.66-1.28C240.42,161.19,239.66,161.86,239.38,162.63z",
  "M235.64,162.98c-0.29,0.77,0,1.35,0.65,1.29c0.65-0.06,1.41-0.74,1.7-1.51c0.29-0.77,0-1.34-0.65-1.28C236.7,161.54,235.93,162.21,235.64,162.98z",
  "M231.91,163.34c-0.3,0.77-0.02,1.35,0.63,1.28c0.65-0.06,1.42-0.74,1.71-1.51c0.3-0.77,0.01-1.34-0.63-1.28C232.98,161.89,232.21,162.57,231.91,163.34z",
  "M228.18,163.71c-0.31,0.77-0.03,1.35,0.62,1.28c0.65-0.06,1.42-0.74,1.72-1.51c0.3-0.77,0.03-1.34-0.62-1.28C229.26,162.26,228.49,162.94,228.18,163.71z",
  "M224.46,164.09c-0.32,0.77-0.05,1.35,0.6,1.28c0.65-0.07,1.42-0.75,1.74-1.52c0.31-0.77,0.04-1.34-0.6-1.28C225.56,162.64,224.78,163.31,224.46,164.09z",
  "M220.75,164.47c-0.32,0.77-0.06,1.35,0.58,1.28c0.65-0.07,1.43-0.75,1.75-1.52s0.06-1.34-0.58-1.28C221.85,163.02,221.07,163.7,220.75,164.47z",
  "M217.04,164.87c-0.33,0.77-0.08,1.35,0.57,1.28c0.65-0.07,1.43-0.75,1.76-1.53c0.33-0.77,0.07-1.34-0.57-1.28C218.16,163.41,217.37,164.09,217.04,164.87z",
  "M213.34,165.27c-0.34,0.77-0.09,1.35,0.55,1.28c0.64-0.07,1.44-0.76,1.77-1.53c0.33-0.77,0.08-1.34-0.55-1.27C214.47,163.81,213.68,164.5,213.34,165.27z",
  "M184,168.8c-0.39,0.78-0.2,1.35,0.44,1.27c0.63-0.08,1.46-0.78,1.85-1.56c0.39-0.78,0.19-1.34-0.44-1.26C185.22,167.32,184.39,168.02,184,168.8z",
  "M180.37,169.27c-0.4,0.78-0.21,1.35,0.42,1.26c0.63-0.08,1.46-0.78,1.86-1.56c0.39-0.78,0.2-1.35-0.42-1.26C181.6,167.79,180.77,168.49,180.37,169.27z",
  "M176.74,169.76c-0.4,0.78-0.22,1.35,0.41,1.26c0.63-0.08,1.47-0.79,1.87-1.57c0.4-0.78,0.22-1.35-0.41-1.26C177.98,168.28,177.15,168.98,176.74,169.76z",
  "M173.13,170.25c-0.41,0.78-0.23,1.35,0.39,1.26c0.63-0.09,1.47-0.79,1.87-1.57c0.41-0.78,0.23-1.35-0.4-1.26C174.38,168.77,173.54,169.47,173.13,170.25z",
  "M169.52,170.75c-0.42,0.78-0.25,1.35,0.38,1.26c0.63-0.09,1.47-0.79,1.88-1.58c0.41-0.78,0.24-1.35-0.38-1.26C170.78,169.27,169.94,169.97,169.52,170.75z",
  "M165.93,171.26c-0.42,0.78-0.26,1.35,0.37,1.26c0.63-0.09,1.47-0.8,1.89-1.58c0.42-0.78,0.25-1.35-0.37-1.26C167.2,169.77,166.35,170.48,165.93,171.26z",
  "M162.34,171.78c-0.43,0.78-0.27,1.35,0.35,1.26c0.62-0.09,1.47-0.8,1.9-1.58c0.42-0.78,0.26-1.35-0.35-1.26C163.62,170.28,162.77,170.99,162.34,171.78z",
  "M158.76,172.3c-0.43,0.78-0.28,1.35,0.34,1.26c0.62-0.09,1.48-0.8,1.9-1.59c0.43-0.78,0.28-1.35-0.34-1.26C160.05,170.8,159.2,171.51,158.76,172.3z",
  "M155.19,172.83c-0.44,0.79-0.29,1.35,0.33,1.26c0.62-0.09,1.48-0.8,1.91-1.59c0.44-0.79,0.29-1.35-0.33-1.25C156.49,171.33,155.63,172.04,155.19,172.83z",
  "M151.64,173.37c-0.44,0.79-0.3,1.35,0.32,1.25c0.62-0.09,1.48-0.81,1.92-1.59c0.44-0.79,0.3-1.35-0.32-1.25C152.94,171.87,152.08,172.58,151.64,173.37z",
  "M148.09,173.91c-0.45,0.79-0.31,1.35,0.3,1.25c0.62-0.1,1.48-0.81,1.92-1.6c0.45-0.79,0.31-1.35-0.3-1.25C149.4,172.41,148.54,173.13,148.09,173.91z",
  "M144.55,174.46c-0.45,0.79-0.33,1.35,0.29,1.25c0.62-0.1,1.48-0.81,1.93-1.6c0.45-0.79,0.32-1.35-0.29-1.25C145.87,172.96,145.01,173.68,144.55,174.46z",
  "M141.02,175.02c-0.46,0.79-0.34,1.35,0.28,1.25c0.61-0.1,1.48-0.82,1.94-1.6c0.46-0.79,0.33-1.35-0.28-1.25C142.35,173.52,141.48,174.24,141.02,175.02z",
  "M137.51,175.59c-0.46,0.79-0.35,1.35,0.27,1.25c0.61-0.1,1.48-0.82,1.94-1.61c0.46-0.79,0.34-1.35-0.27-1.25C138.84,174.08,137.97,174.8,137.51,175.59z",
  "M134,176.16c-0.47,0.79-0.36,1.35,0.25,1.25c0.61-0.1,1.48-0.82,1.95-1.61c0.47-0.79,0.35-1.35-0.25-1.25C135.34,174.65,134.47,175.37,134,176.16z",
  "M130.51,176.74c-0.47,0.79-0.37,1.35,0.24,1.25c0.61-0.1,1.48-0.82,1.95-1.61c0.47-0.79,0.36-1.35-0.24-1.25C131.85,175.23,130.98,175.95,130.51,176.74z",
  "M127.02,177.33c-0.48,0.79-0.37,1.35,0.23,1.25c0.61-0.1,1.48-0.83,1.96-1.62c0.47-0.79,0.37-1.35-0.23-1.25C128.38,175.81,127.5,176.54,127.02,177.33z",
  "M123.55,177.92c-0.48,0.79-0.38,1.35,0.22,1.25c0.6-0.1,1.48-0.83,1.96-1.62c0.48-0.79,0.38-1.35-0.22-1.25C124.91,176.4,124.03,177.13,123.55,177.92z",
  "M120.09,178.52c-0.49,0.79-0.39,1.35,0.21,1.24c0.6-0.1,1.48-0.83,1.96-1.62c0.48-0.79,0.39-1.35-0.21-1.24C121.46,177,120.58,177.73,120.09,178.52z",
  "M116.64,179.12c-0.49,0.79-0.4,1.35,0.2,1.24c0.6-0.11,1.48-0.83,1.97-1.62c0.49-0.79,0.4-1.35-0.2-1.24C118.01,177.61,117.13,178.33,116.64,179.12z",
  "M113.21,179.74c-0.49,0.79-0.41,1.35,0.19,1.24c0.6-0.11,1.48-0.84,1.97-1.63c0.49-0.79,0.41-1.35-0.19-1.24C114.58,178.22,113.7,178.94,113.21,179.74z",
  "M109.78,180.35c-0.5,0.79-0.42,1.35,0.18,1.24c0.59-0.11,1.48-0.84,1.97-1.63c0.49-0.79,0.42-1.35-0.18-1.24C111.16,178.83,110.28,179.56,109.78,180.35z",
  "M106.37,180.98c-0.5,0.79-0.43,1.35,0.17,1.24c0.59-0.11,1.48-0.84,1.98-1.63c0.5-0.79,0.42-1.35-0.17-1.24C107.76,179.45,106.87,180.18,106.37,180.98z",
  "M102.97,181.61c-0.5,0.79-0.44,1.35,0.16,1.24c0.59-0.11,1.48-0.84,1.98-1.64c0.5-0.79,0.43-1.35-0.16-1.24C104.36,180.08,103.48,180.81,102.97,181.61z",
  "M99.59,182.24c-0.51,0.79-0.44,1.35,0.15,1.24c0.59-0.11,1.47-0.84,1.98-1.64c0.51-0.79,0.44-1.35-0.15-1.24C100.98,180.71,100.1,181.45,99.59,182.24z",
  "M96.22,182.88c-0.51,0.79-0.45,1.35,0.14,1.24c0.59-0.11,1.47-0.85,1.98-1.64c0.51-0.79,0.45-1.35-0.14-1.24C97.61,181.35,96.73,182.09,96.22,182.88z",
  "M92.86,183.53c-0.51,0.79-0.46,1.35,0.13,1.24c0.58-0.11,1.47-0.85,1.98-1.64c0.51-0.79,0.45-1.35-0.13-1.24C94.26,182,93.37,182.73,92.86,183.53z",
  "M89.51,184.18c-0.52,0.8-0.46,1.35,0.12,1.23c0.58-0.11,1.47-0.85,1.98-1.64c0.51-0.8,0.46-1.35-0.12-1.23C90.91,182.65,90.03,183.38,89.51,184.18z",
  "M86.18,184.84c-0.52,0.8-0.47,1.35,0.11,1.23c0.58-0.11,1.47-0.85,1.98-1.65c0.52-0.8,0.47-1.35-0.11-1.23C87.58,183.3,86.7,184.04,86.18,184.84z",
  "M82.86,185.5c-0.52,0.8-0.48,1.35,0.1,1.23c0.58-0.12,1.46-0.85,1.98-1.65c0.52-0.8,0.47-1.35-0.1-1.23C84.27,183.97,83.38,184.7,82.86,185.5z",
  "M661.13,179.46c0.54,0.8,1.42,1.53,1.98,1.64c0.56,0.11,0.57-0.45,0.03-1.25c-0.54-0.8-1.42-1.53-1.98-1.64C660.6,178.1,660.59,178.66,661.13,179.46z",
  "M657.93,178.83c0.54,0.8,1.42,1.53,1.98,1.64c0.56,0.11,0.57-0.45,0.03-1.25c-0.54-0.8-1.42-1.53-1.98-1.64C657.41,177.48,657.39,178.04,657.93,178.83z",
  "M651.48,177.6c0.53,0.8,1.42,1.53,1.98,1.64c0.56,0.11,0.58-0.45,0.05-1.25c-0.53-0.8-1.42-1.53-1.98-1.64C650.97,176.24,650.95,176.8,651.48,177.6z",
  "M644.99,176.38c0.53,0.8,1.42,1.53,1.98,1.63c0.57,0.11,0.59-0.46,0.06-1.25c-0.53-0.8-1.42-1.53-1.98-1.63C644.49,175.02,644.46,175.58,644.99,176.38z",
  "M638.44,175.18c0.52,0.79,1.41,1.52,1.98,1.62c0.57,0.1,0.61-0.46,0.08-1.25c-0.52-0.79-1.41-1.52-1.98-1.62C637.96,173.83,637.92,174.39,638.44,175.18z",
  "M635.15,174.59c0.52,0.79,1.41,1.52,1.98,1.62c0.57,0.1,0.61-0.46,0.09-1.25c-0.52-0.79-1.41-1.52-1.98-1.62C634.67,173.24,634.63,173.8,635.15,174.59z",
  "M631.85,174.01c0.52,0.79,1.4,1.52,1.98,1.62c0.57,0.1,0.62-0.46,0.1-1.25c-0.52-0.79-1.4-1.52-1.98-1.62C631.38,172.66,631.33,173.22,631.85,174.01z",
  "M628.54,173.43c0.51,0.79,1.4,1.52,1.98,1.62c0.58,0.1,0.62-0.46,0.11-1.25c-0.52-0.79-1.4-1.52-1.98-1.62C628.07,172.08,628.02,172.64,628.54,173.43z",
  "M625.21,172.86c0.51,0.79,1.4,1.51,1.97,1.61c0.58,0.1,0.63-0.46,0.12-1.26c-0.51-0.79-1.4-1.51-1.97-1.61C624.75,171.51,624.7,172.07,625.21,172.86z",
  "M621.88,172.3c0.51,0.79,1.39,1.51,1.97,1.61c0.58,0.1,0.64-0.46,0.13-1.26c-0.51-0.79-1.39-1.51-1.97-1.61C621.42,170.94,621.37,171.5,621.88,172.3z",
  "M611.8,170.64c0.5,0.79,1.38,1.51,1.96,1.6c0.59,0.09,0.66-0.47,0.16-1.26c-0.5-0.79-1.38-1.51-1.96-1.6C611.37,169.28,611.3,169.85,611.8,170.64z",
  "M605.02,169.56c0.49,0.79,1.36,1.5,1.95,1.59c0.59,0.09,0.67-0.47,0.18-1.26c-0.49-0.79-1.37-1.5-1.95-1.59C604.61,168.21,604.53,168.77,605.02,169.56z",
  "M584.45,166.49c0.46,0.78,1.32,1.49,1.92,1.57c0.6,0.09,0.71-0.48,0.25-1.26c-0.47-0.78-1.33-1.49-1.92-1.57C584.1,165.15,583.99,165.71,584.45,166.49z",
  "M580.99,166.01c0.46,0.78,1.31,1.48,1.92,1.57c0.6,0.08,0.72-0.48,0.26-1.26c-0.46-0.78-1.32-1.48-1.92-1.57C580.65,164.66,580.53,165.23,580.99,166.01z",
  "M577.51,165.53c0.45,0.78,1.31,1.48,1.91,1.57c0.6,0.08,0.73-0.48,0.27-1.26c-0.45-0.78-1.31-1.48-1.91-1.56C577.18,164.18,577.06,164.75,577.51,165.53z",
  "M574.03,165.06c0.45,0.78,1.3,1.48,1.9,1.56c0.61,0.08,0.73-0.49,0.28-1.27c-0.45-0.78-1.3-1.48-1.9-1.56C573.71,163.71,573.58,164.28,574.03,165.06z",
  "M570.54,164.59c0.44,0.78,1.29,1.48,1.9,1.56c0.61,0.08,0.74-0.49,0.3-1.27c-0.44-0.78-1.29-1.48-1.9-1.56C570.23,163.25,570.1,163.81,570.54,164.59z",
  "M567.04,164.13c0.43,0.78,1.28,1.47,1.89,1.55c0.61,0.08,0.75-0.49,0.31-1.27c-0.44-0.78-1.28-1.47-1.89-1.55C566.74,162.79,566.6,163.35,567.04,164.13z",
  "M563.53,163.68c0.43,0.78,1.27,1.47,1.88,1.55c0.61,0.08,0.76-0.49,0.32-1.27c-0.43-0.78-1.27-1.47-1.88-1.55C563.24,162.34,563.1,162.91,563.53,163.68z",
  "M560.01,163.24c0.42,0.78,1.26,1.47,1.88,1.55c0.61,0.08,0.76-0.49,0.34-1.27c-0.43-0.78-1.27-1.47-1.87-1.54C559.73,161.9,559.58,162.46,560.01,163.24z",
  "M556.48,162.8c0.42,0.78,1.25,1.47,1.87,1.54c0.61,0.08,0.77-0.49,0.35-1.27c-0.42-0.78-1.26-1.47-1.87-1.54C556.22,161.46,556.06,162.03,556.48,162.8z",
  "M552.94,162.38c0.41,0.77,1.24,1.46,1.86,1.54c0.62,0.07,0.78-0.5,0.36-1.27c-0.41-0.78-1.25-1.46-1.86-1.54C552.69,161.04,552.53,161.6,552.94,162.38z",
  "M549.4,161.96c0.4,0.77,1.23,1.46,1.85,1.53c0.62,0.07,0.79-0.5,0.38-1.27c-0.41-0.77-1.24-1.46-1.85-1.53C549.16,160.62,548.99,161.18,549.4,161.96z",
  "M545.84,161.55c0.4,0.77,1.22,1.46,1.84,1.53c0.62,0.07,0.79-0.5,0.39-1.27c-0.4-0.77-1.23-1.46-1.84-1.53C545.62,160.2,545.44,160.77,545.84,161.55z",
  "M542.28,161.14c0.39,0.77,1.21,1.46,1.83,1.53c0.62,0.07,0.8-0.5,0.41-1.27c-0.4-0.77-1.22-1.45-1.83-1.52C542.07,159.8,541.89,160.37,542.28,161.14z",
  "M538.71,160.75c0.39,0.77,1.2,1.45,1.82,1.52c0.62,0.07,0.81-0.5,0.42-1.27c-0.39-0.77-1.21-1.45-1.82-1.52C538.51,159.4,538.33,159.97,538.71,160.75z",
  "M535.13,160.36c0.38,0.77,1.19,1.45,1.81,1.52c0.62,0.07,0.82-0.5,0.43-1.28c-0.38-0.77-1.19-1.45-1.81-1.52C534.95,159.02,534.76,159.59,535.13,160.36z",
  "M531.55,159.98c0.37,0.77,1.18,1.45,1.8,1.51c0.62,0.07,0.83-0.51,0.45-1.28c-0.38-0.77-1.18-1.45-1.8-1.51C531.38,158.64,531.18,159.21,531.55,159.98z",
  "M527.96,159.6c0.36,0.77,1.17,1.45,1.79,1.51c0.63,0.06,0.83-0.51,0.46-1.28c-0.37-0.77-1.17-1.44-1.79-1.51C527.8,158.27,527.59,158.84,527.96,159.6z",
  "M524.36,159.24c0.36,0.77,1.16,1.44,1.78,1.51c0.63,0.06,0.84-0.51,0.48-1.28c-0.36-0.77-1.16-1.44-1.78-1.5C524.21,157.9,524,158.47,524.36,159.24z",
  "M520.75,158.89c0.35,0.77,1.14,1.44,1.77,1.5c0.63,0.06,0.85-0.51,0.49-1.28c-0.36-0.77-1.15-1.44-1.77-1.5C520.62,157.55,520.4,158.12,520.75,158.89z",
  "M517.13,158.54c0.34,0.77,1.13,1.44,1.76,1.5c0.63,0.06,0.86-0.51,0.51-1.28c-0.35-0.77-1.14-1.44-1.76-1.5C517.02,157.2,516.79,157.77,517.13,158.54z",
  "M513.51,158.2c0.34,0.77,1.12,1.44,1.75,1.49c0.63,0.06,0.87-0.52,0.52-1.28c-0.34-0.77-1.13-1.43-1.75-1.49C513.41,156.86,513.18,157.43,513.51,158.2z",
  "M509.89,157.87c0.33,0.77,1.11,1.43,1.74,1.49c0.63,0.06,0.87-0.52,0.54-1.28c-0.33-0.77-1.11-1.43-1.74-1.49C509.8,156.53,509.56,157.1,509.89,157.87z",
  "M506.25,157.55c0.32,0.76,1.1,1.43,1.73,1.49c0.63,0.06,0.88-0.52,0.55-1.29c-0.33-0.76-1.1-1.43-1.73-1.48C506.18,156.21,505.93,156.78,506.25,157.55z",
  "M502.61,157.23c0.31,0.76,1.08,1.43,1.72,1.48c0.63,0.05,0.89-0.52,0.57-1.29c-0.32-0.76-1.09-1.43-1.72-1.48C502.55,155.89,502.3,156.47,502.61,157.23z",
  "M498.96,156.93c0.31,0.76,1.07,1.43,1.71,1.48c0.64,0.05,0.9-0.52,0.59-1.29c-0.31-0.76-1.08-1.42-1.71-1.48C498.92,155.59,498.66,156.16,498.96,156.93z",
  "M495.31,156.63c0.3,0.76,1.06,1.42,1.69,1.47c0.64,0.05,0.91-0.53,0.6-1.29c-0.3-0.76-1.06-1.42-1.69-1.47C495.28,155.29,495.01,155.87,495.31,156.63z",
  "M491.65,156.34c0.29,0.76,1.04,1.42,1.68,1.47c0.64,0.05,0.91-0.53,0.62-1.29c-0.3-0.76-1.05-1.42-1.68-1.47C491.63,155.01,491.36,155.58,491.65,156.34z",
  "M487.98,156.07c0.28,0.76,1.03,1.42,1.67,1.47c0.64,0.05,0.92-0.53,0.63-1.29c-0.29-0.76-1.04-1.42-1.67-1.46C487.98,154.73,487.7,155.3,487.98,156.07z",
  "M484.31,155.8c0.27,0.76,1.02,1.42,1.66,1.46c0.64,0.05,0.93-0.53,0.65-1.29c-0.28-0.76-1.02-1.41-1.66-1.46C484.33,154.46,484.04,155.04,484.31,155.8z",
  "M480.63,155.53c0.27,0.76,1,1.41,1.64,1.46c0.64,0.05,0.94-0.54,0.67-1.3c-0.27-0.76-1.01-1.41-1.64-1.46C480.67,154.2,480.37,154.77,480.63,155.53z",
  "M476.95,155.28c0.26,0.76,0.99,1.41,1.63,1.45c0.64,0.04,0.95-0.54,0.68-1.3c-0.27-0.76-1-1.41-1.63-1.45C477,153.94,476.69,154.52,476.95,155.28z",
  "M473.26,155.04c0.25,0.76,0.98,1.41,1.62,1.45c0.64,0.04,0.96-0.54,0.7-1.3s-0.98-1.41-1.62-1.45C473.33,153.7,473.01,154.28,473.26,155.04z",
  "M469.57,154.8c0.24,0.76,0.96,1.41,1.61,1.45c0.64,0.04,0.96-0.54,0.71-1.3c-0.25-0.76-0.97-1.4-1.61-1.44C469.65,153.47,469.33,154.05,469.57,154.8z",
  "M465.87,154.58c0.23,0.76,0.95,1.4,1.59,1.44c0.64,0.04,0.97-0.54,0.73-1.3c-0.24-0.76-0.95-1.4-1.59-1.44C465.97,153.24,465.64,153.82,465.87,154.58z",
  "M462.17,154.36c0.23,0.76,0.93,1.4,1.58,1.44c0.65,0.04,0.98-0.55,0.75-1.3c-0.23-0.76-0.94-1.4-1.58-1.44C462.28,153.02,461.94,153.61,462.17,154.36z",
  "M458.46,154.15c0.22,0.76,0.92,1.4,1.56,1.44c0.65,0.04,0.99-0.55,0.76-1.31c-0.22-0.76-0.92-1.4-1.56-1.43C458.59,152.82,458.25,153.4,458.46,154.15z",
  "M454.75,153.96c0.21,0.76,0.9,1.4,1.55,1.43c0.65,0.03,1-0.55,0.78-1.31c-0.22-0.76-0.91-1.39-1.55-1.43C454.89,152.62,454.54,153.2,454.75,153.96z",
  "M451.04,153.77c0.2,0.75,0.89,1.39,1.54,1.43c0.65,0.03,1-0.55,0.8-1.31c-0.21-0.76-0.89-1.39-1.54-1.42C451.19,152.43,450.83,153.01,451.04,153.77z",
  "M447.31,153.59c0.19,0.75,0.87,1.39,1.52,1.42c0.65,0.03,1.01-0.56,0.81-1.31c-0.2-0.75-0.88-1.39-1.52-1.42C447.49,152.25,447.12,152.83,447.31,153.59z",
  "M443.59,153.42c0.18,0.75,0.86,1.39,1.51,1.42c0.65,0.03,1.02-0.56,0.83-1.31c-0.19-0.75-0.87-1.39-1.51-1.42C443.78,152.08,443.41,152.66,443.59,153.42z",
  "M439.86,153.26c0.18,0.75,0.84,1.39,1.49,1.42c0.65,0.03,1.03-0.56,0.85-1.32c-0.18-0.75-0.85-1.39-1.49-1.41C440.07,151.92,439.69,152.5,439.86,153.26z",
  "M436.13,153.1c0.17,0.75,0.83,1.39,1.48,1.41c0.65,0.03,1.04-0.56,0.86-1.32c-0.17-0.75-0.84-1.38-1.48-1.41C436.35,151.76,435.96,152.35,436.13,153.1z",
  "M432.4,152.96c0.16,0.75,0.81,1.38,1.47,1.41c0.65,0.02,1.05-0.57,0.88-1.32c-0.16-0.75-0.82-1.38-1.47-1.41C432.63,151.62,432.24,152.21,432.4,152.96z",
  "M428.66,152.83c0.15,0.75,0.8,1.38,1.45,1.41c0.65,0.02,1.05-0.57,0.9-1.32c-0.16-0.75-0.81-1.38-1.45-1.4C428.91,151.49,428.51,152.08,428.66,152.83z",
  "M424.91,152.7c0.14,0.75,0.78,1.38,1.44,1.4c0.65,0.02,1.06-0.57,0.91-1.33c-0.15-0.75-0.79-1.38-1.44-1.4C425.18,151.36,424.77,151.95,424.91,152.7z",
  "M421.17,152.59c0.13,0.75,0.77,1.38,1.42,1.4c0.65,0.02,1.07-0.58,0.93-1.33c-0.14-0.75-0.77-1.38-1.42-1.39C421.46,151.25,421.04,151.84,421.17,152.59z",
  "M417.42,152.48c0.12,0.75,0.75,1.38,1.41,1.39c0.65,0.02,1.08-0.58,0.95-1.33c-0.13-0.75-0.76-1.37-1.41-1.39C417.72,151.14,417.3,151.73,417.42,152.48z",
  "M413.67,152.39c0.11,0.75,0.74,1.38,1.39,1.39c0.65,0.02,1.09-0.58,0.97-1.33c-0.12-0.75-0.74-1.37-1.39-1.39C413.99,151.04,413.56,151.64,413.67,152.39z",
  "M409.92,152.3c0.11,0.75,0.72,1.37,1.38,1.39c0.65,0.01,1.09-0.58,0.98-1.33c-0.11-0.75-0.73-1.37-1.38-1.38C410.25,150.96,409.81,151.55,409.92,152.3z",
  "M406.16,152.23c0.1,0.75,0.7,1.37,1.36,1.38c0.65,0.01,1.1-0.59,1-1.34c-0.1-0.75-0.71-1.37-1.36-1.38C406.51,150.88,406.07,151.48,406.16,152.23z",
  "M402.4,152.16c0.09,0.75,0.69,1.37,1.34,1.38c0.66,0.01,1.11-0.59,1.02-1.34c-0.09-0.75-0.7-1.37-1.34-1.38C402.77,150.81,402.32,151.41,402.4,152.16z",
  "M398.64,152.1c0.08,0.75,0.67,1.37,1.33,1.38c0.66,0.01,1.12-0.59,1.03-1.34c-0.09-0.75-0.68-1.36-1.33-1.37C399.03,150.75,398.57,151.35,398.64,152.1z",
  "M394.88,152.05c0.07,0.75,0.66,1.37,1.31,1.37c0.66,0.01,1.13-0.6,1.05-1.35c-0.08-0.75-0.66-1.36-1.31-1.37C395.28,150.7,394.81,151.3,394.88,152.05z",
  "M391.12,152.02c0.06,0.75,0.64,1.36,1.3,1.37c0.66,0.01,1.13-0.6,1.07-1.35c-0.07-0.75-0.65-1.36-1.3-1.37C391.54,150.67,391.06,151.27,391.12,152.02z",
  "M387.35,151.99c0.05,0.75,0.63,1.36,1.28,1.37c0.66,0,1.14-0.6,1.08-1.35c-0.06-0.75-0.63-1.36-1.28-1.36C387.79,150.63,387.3,151.24,387.35,151.99z",
  "M383.59,151.97c0.04,0.75,0.61,1.36,1.27,1.36c0.66,0,1.15-0.6,1.1-1.35c-0.05-0.75-0.62-1.36-1.27-1.36C384.04,150.61,383.54,151.22,383.59,151.97z",
  "M379.82,151.96c0.03,0.75,0.59,1.36,1.25,1.36c0.66,0,1.16-0.61,1.12-1.36c-0.04-0.75-0.6-1.36-1.25-1.36C380.28,150.6,379.78,151.21,379.82,151.96z",
  "M376.05,151.96c0.02,0.75,0.58,1.36,1.23,1.36c0.66,0,1.16-0.61,1.13-1.36c-0.03-0.75-0.58-1.36-1.23-1.35C376.53,150.6,376.02,151.21,376.05,151.96z",
  "M372.28,151.97c0.02,0.75,0.56,1.36,1.22,1.36c0.66,0,1.17-0.61,1.15-1.36c-0.02-0.75-0.57-1.35-1.22-1.35C372.78,150.61,372.26,151.22,372.28,151.97z",
  "M368.51,151.99c0.01,0.75,0.54,1.36,1.2,1.35c0.66,0,1.18-0.62,1.17-1.37c-0.01-0.75-0.55-1.35-1.2-1.35C369.02,150.63,368.5,151.24,368.51,151.99z",
  "M364.74,152.02c0,0.75,0.53,1.35,1.19,1.35c0.66-0.01,1.19-0.62,1.18-1.37c0-0.75-0.53-1.35-1.19-1.35C365.27,150.66,364.74,151.27,364.74,152.02z",
  "M360.96,152.05c-0.01,0.75,0.51,1.35,1.17,1.35c0.66-0.01,1.2-0.62,1.2-1.37c0.01-0.75-0.52-1.35-1.17-1.34C361.51,150.69,360.97,151.3,360.96,152.05z",
  "M357.19,152.1c-0.02,0.75,0.5,1.35,1.15,1.34c0.66-0.01,1.2-0.63,1.22-1.37c0.01-0.75-0.5-1.35-1.15-1.34C357.76,150.74,357.21,151.35,357.19,152.1z",
  "M353.42,152.16c-0.03,0.75,0.48,1.35,1.14,1.34c0.66-0.01,1.21-0.63,1.23-1.38c0.02-0.75-0.49-1.35-1.14-1.34C354,150.79,353.45,151.41,353.42,152.16z",
  "M349.64,152.23c-0.04,0.75,0.46,1.35,1.12,1.34c0.66-0.01,1.22-0.63,1.25-1.38c0.03-0.75-0.47-1.35-1.12-1.33C350.24,150.86,349.68,151.48,349.64,152.23z",
  "M345.87,152.3c-0.05,0.75,0.45,1.35,1.1,1.34c0.66-0.01,1.22-0.63,1.27-1.38c0.04-0.75-0.45-1.35-1.1-1.33C346.49,150.94,345.92,151.55,345.87,152.3z",
  "M338.33,152.49c-0.07,0.75,0.41,1.35,1.07,1.33c0.66-0.02,1.24-0.64,1.3-1.39c0.06-0.75-0.42-1.34-1.07-1.33C338.98,151.11,338.39,151.74,338.33,152.49z",
  "M244.69,157.96c-0.28,0.76,0.02,1.34,0.66,1.28c0.65-0.06,1.39-0.72,1.66-1.49c0.27-0.76-0.02-1.33-0.66-1.28C245.72,156.53,244.97,157.19,244.69,157.96z",
  "M240.99,158.29c-0.29,0.76,0,1.34,0.65,1.28c0.64-0.06,1.39-0.73,1.68-1.49c0.28-0.76-0.01-1.33-0.65-1.27C242.03,156.87,241.28,157.53,240.99,158.29z",
  "M237.3,158.64c-0.29,0.76-0.01,1.34,0.63,1.28c0.64-0.06,1.4-0.73,1.69-1.49c0.29-0.76,0.01-1.33-0.63-1.27C238.35,157.21,237.59,157.88,237.3,158.64z",
  "M233.61,159c-0.3,0.77-0.03,1.34,0.62,1.27c0.64-0.06,1.4-0.73,1.7-1.5c0.3-0.76,0.02-1.33-0.62-1.27C234.67,157.56,233.91,158.23,233.61,159z",
  "M229.93,159.36c-0.31,0.77-0.04,1.34,0.6,1.27c0.64-0.06,1.41-0.74,1.71-1.5c0.3-0.77,0.03-1.33-0.6-1.27C231,157.93,230.24,158.6,229.93,159.36z",
  "M226.25,159.74c-0.32,0.77-0.05,1.34,0.59,1.27c0.64-0.07,1.41-0.74,1.72-1.51c0.31-0.77,0.05-1.33-0.59-1.27C227.34,158.3,226.57,158.97,226.25,159.74z",
  "M222.58,160.12c-0.32,0.77-0.07,1.34,0.57,1.27c0.64-0.07,1.42-0.74,1.74-1.51c0.32-0.77,0.06-1.33-0.57-1.27S222.9,159.35,222.58,160.12z",
  "M218.91,160.51c-0.33,0.77-0.08,1.34,0.56,1.27c0.64-0.07,1.42-0.75,1.75-1.52c0.33-0.77,0.08-1.33-0.56-1.27C220.03,159.06,219.24,159.74,218.91,160.51z",
  "M215.25,160.91c-0.34,0.77-0.1,1.34,0.54,1.27c0.64-0.07,1.42-0.75,1.76-1.52c0.33-0.77,0.09-1.33-0.54-1.26C216.38,159.46,215.59,160.14,215.25,160.91z",
  "M186.22,164.4c-0.39,0.78-0.2,1.34,0.42,1.26c0.63-0.08,1.45-0.78,1.84-1.55c0.39-0.77,0.2-1.34-0.42-1.26C187.43,162.93,186.61,163.62,186.22,164.4z",
  "M182.62,164.87c-0.4,0.78-0.22,1.34,0.41,1.26c0.63-0.08,1.45-0.78,1.85-1.56c0.39-0.78,0.21-1.34-0.41-1.26C183.85,163.4,183.02,164.1,182.62,164.87z",
  "M179.03,165.35c-0.41,0.78-0.23,1.34,0.4,1.26c0.62-0.08,1.46-0.78,1.86-1.56c0.4-0.78,0.22-1.34-0.4-1.25C180.27,163.88,179.44,164.58,179.03,165.35z",
  "M175.45,165.84c-0.41,0.78-0.24,1.34,0.38,1.26c0.62-0.09,1.46-0.79,1.86-1.56c0.41-0.78,0.23-1.34-0.38-1.25C176.7,164.37,175.86,165.06,175.45,165.84z",
  "M171.88,166.34c-0.42,0.78-0.25,1.34,0.37,1.25c0.62-0.09,1.46-0.79,1.87-1.57c0.41-0.78,0.25-1.34-0.37-1.25C173.13,164.86,172.3,165.56,171.88,166.34z",
  "M168.32,166.84c-0.42,0.78-0.26,1.34,0.36,1.25c0.62-0.09,1.46-0.79,1.88-1.57c0.42-0.78,0.26-1.34-0.36-1.25C169.58,165.36,168.74,166.06,168.32,166.84z",
  "M164.76,167.35c-0.43,0.78-0.28,1.34,0.34,1.25c0.62-0.09,1.46-0.79,1.89-1.57c0.42-0.78,0.27-1.34-0.34-1.25C166.03,165.87,165.19,166.57,164.76,167.35z",
  "M161.21,167.87c-0.43,0.78-0.29,1.34,0.33,1.25c0.62-0.09,1.47-0.8,1.9-1.58c0.43-0.78,0.28-1.34-0.33-1.25C162.5,166.39,161.65,167.09,161.21,167.87z",
  "M157.68,168.4c-0.44,0.78-0.3,1.34,0.32,1.25c0.62-0.09,1.47-0.8,1.9-1.58c0.44-0.78,0.29-1.34-0.32-1.25C158.97,166.91,158.12,167.62,157.68,168.4z",
  "M154.15,168.93c-0.45,0.78-0.31,1.34,0.3,1.25c0.61-0.09,1.47-0.8,1.91-1.59c0.44-0.78,0.3-1.34-0.31-1.25C155.45,167.44,154.59,168.15,154.15,168.93z",
  "M150.63,169.47c-0.45,0.78-0.32,1.34,0.29,1.25c0.61-0.09,1.47-0.81,1.92-1.59c0.45-0.78,0.32-1.34-0.29-1.25C151.94,167.98,151.08,168.69,150.63,169.47z",
  "M147.12,170.02c-0.46,0.78-0.33,1.34,0.28,1.25c0.61-0.1,1.47-0.81,1.92-1.59c0.45-0.78,0.33-1.34-0.28-1.25C148.44,168.52,147.58,169.24,147.12,170.02z",
  "M143.62,170.58c-0.46,0.78-0.34,1.34,0.27,1.25c0.61-0.1,1.47-0.81,1.93-1.6c0.46-0.78,0.34-1.34-0.27-1.25C144.95,169.08,144.08,169.79,143.62,170.58z",
  "M140.13,171.14c-0.46,0.79-0.35,1.34,0.26,1.25c0.61-0.1,1.47-0.81,1.93-1.6c0.46-0.79,0.35-1.34-0.26-1.24C141.46,169.64,140.6,170.35,140.13,171.14z",
  "M136.65,171.71c-0.47,0.79-0.36,1.34,0.24,1.25c0.6-0.1,1.47-0.82,1.94-1.6c0.47-0.79,0.36-1.34-0.25-1.24C137.99,170.2,137.12,170.92,136.65,171.71z",
  "M133.18,172.28c-0.47,0.79-0.37,1.34,0.23,1.24c0.6-0.1,1.47-0.82,1.94-1.61c0.47-0.79,0.37-1.34-0.23-1.24C134.53,170.78,133.66,171.5,133.18,172.28z",
  "M129.73,172.87c-0.48,0.79-0.38,1.34,0.22,1.24c0.6-0.1,1.47-0.82,1.95-1.61c0.48-0.79,0.38-1.34-0.22-1.24C131.08,171.36,130.2,172.08,129.73,172.87z",
  "M126.28,173.46c-0.48,0.79-0.39,1.34,0.21,1.24c0.6-0.1,1.47-0.83,1.95-1.61c0.48-0.79,0.38-1.34-0.21-1.24C127.64,171.94,126.76,172.67,126.28,173.46z",
  "M122.84,174.05c-0.49,0.79-0.4,1.35,0.2,1.24c0.6-0.1,1.47-0.83,1.96-1.62c0.48-0.79,0.39-1.34-0.2-1.24C124.2,172.54,123.33,173.26,122.84,174.05z",
  "M119.42,174.65c-0.49,0.79-0.41,1.35,0.19,1.24c0.6-0.1,1.47-0.83,1.96-1.62c0.49-0.79,0.4-1.34-0.19-1.24C120.78,173.14,119.91,173.86,119.42,174.65z",
  "M116,175.26c-0.49,0.79-0.41,1.35,0.18,1.24c0.59-0.11,1.47-0.83,1.96-1.62c0.49-0.79,0.41-1.34-0.18-1.24C117.37,173.74,116.49,174.47,116,175.26z",
  "M112.6,175.88c-0.5,0.79-0.42,1.35,0.17,1.24c0.59-0.11,1.47-0.83,1.97-1.63c0.5-0.79,0.42-1.35-0.17-1.24C113.98,174.36,113.09,175.08,112.6,175.88z",
  "M109.21,176.5c-0.5,0.79-0.43,1.35,0.16,1.24c0.59-0.11,1.47-0.84,1.97-1.63c0.5-0.79,0.43-1.35-0.16-1.24C110.59,174.98,109.71,175.7,109.21,176.5z",
  "M105.83,177.12c-0.5,0.79-0.44,1.35,0.15,1.24c0.59-0.11,1.47-0.84,1.97-1.63c0.5-0.79,0.44-1.35-0.15-1.24C107.21,175.6,106.33,176.33,105.83,177.12z",
  "M102.46,177.76c-0.51,0.79-0.45,1.35,0.14,1.24c0.58-0.11,1.47-0.84,1.98-1.63c0.51-0.79,0.44-1.35-0.14-1.24C103.85,176.23,102.96,176.96,102.46,177.76z",
  "M99.1,178.39c-0.51,0.79-0.45,1.35,0.13,1.24c0.58-0.11,1.47-0.84,1.98-1.64c0.51-0.79,0.45-1.35-0.13-1.23C100.5,176.87,99.61,177.6,99.1,178.39z",
  "M95.76,179.04c-0.51,0.79-0.46,1.35,0.12,1.23c0.58-0.11,1.47-0.85,1.98-1.64c0.51-0.79,0.46-1.35-0.12-1.23C97.16,177.51,96.27,178.24,95.76,179.04z",
  "M89.11,180.34c-0.52,0.79-0.47,1.35,0.1,1.23c0.58-0.11,1.46-0.85,1.98-1.64c0.52-0.79,0.47-1.35-0.1-1.23C90.51,178.81,89.63,179.55,89.11,180.34z",
  "M654.9,174.33c0.54,0.8,1.42,1.53,1.98,1.64c0.56,0.11,0.57-0.45,0.03-1.25c-0.54-0.8-1.42-1.53-1.97-1.64C654.38,172.97,654.36,173.53,654.9,174.33z",
  "M651.69,173.71c0.53,0.8,1.42,1.53,1.98,1.64c0.56,0.11,0.57-0.45,0.04-1.25c-0.54-0.8-1.42-1.53-1.98-1.64C651.17,172.35,651.16,172.91,651.69,173.71z",
  "M648.47,173.1c0.53,0.8,1.42,1.53,1.98,1.63c0.56,0.11,0.58-0.45,0.05-1.25c-0.53-0.8-1.42-1.53-1.98-1.63C647.96,171.74,647.94,172.3,648.47,173.1z",
  "M645.24,172.49c0.53,0.79,1.42,1.53,1.98,1.63c0.56,0.11,0.58-0.45,0.05-1.25c-0.53-0.8-1.42-1.53-1.98-1.63C644.74,171.13,644.71,171.69,645.24,172.49z",
  "M642,171.89c0.53,0.79,1.41,1.52,1.98,1.63c0.56,0.1,0.59-0.46,0.06-1.25c-0.53-0.79-1.41-1.52-1.98-1.63C641.5,170.53,641.47,171.09,642,171.89z",
  "M638.75,171.29c0.53,0.79,1.41,1.52,1.98,1.62c0.57,0.1,0.6-0.46,0.07-1.25c-0.53-0.79-1.41-1.52-1.98-1.62C638.25,169.94,638.22,170.5,638.75,171.29z",
  "M635.48,170.7c0.52,0.79,1.41,1.52,1.98,1.62c0.57,0.1,0.6-0.46,0.08-1.25c-0.52-0.79-1.41-1.52-1.97-1.62C634.99,169.34,634.96,169.9,635.48,170.7z",
  "M632.21,170.11c0.52,0.79,1.4,1.52,1.97,1.62c0.57,0.1,0.61-0.46,0.08-1.25c-0.52-0.79-1.41-1.52-1.97-1.62C631.73,168.76,631.69,169.32,632.21,170.11z",
  "M628.92,169.53c0.52,0.79,1.4,1.51,1.97,1.62c0.57,0.1,0.61-0.46,0.09-1.25c-0.52-0.79-1.4-1.51-1.97-1.61C628.45,168.18,628.4,168.74,628.92,169.53z",
  "M625.63,168.96c0.51,0.79,1.4,1.51,1.97,1.61c0.57,0.1,0.62-0.46,0.1-1.25c-0.52-0.79-1.4-1.51-1.97-1.61C625.16,167.61,625.11,168.17,625.63,168.96z",
  "M622.32,168.39c0.51,0.79,1.39,1.51,1.97,1.61c0.58,0.1,0.62-0.46,0.11-1.25c-0.51-0.79-1.39-1.51-1.97-1.61C621.86,167.04,621.81,167.6,622.32,168.39z",
  "M619,167.83c0.51,0.79,1.39,1.51,1.97,1.61c0.58,0.1,0.63-0.46,0.12-1.25c-0.51-0.79-1.39-1.51-1.96-1.6C618.55,166.48,618.49,167.04,619,167.83z",
  "M615.67,167.27c0.5,0.79,1.38,1.51,1.96,1.6c0.58,0.1,0.64-0.47,0.13-1.25c-0.51-0.79-1.39-1.51-1.96-1.6C615.23,165.93,615.17,166.49,615.67,167.27z",
  "M612.33,166.73c0.5,0.79,1.38,1.5,1.96,1.6c0.58,0.1,0.64-0.47,0.14-1.25c-0.5-0.79-1.38-1.5-1.96-1.6C611.89,165.38,611.83,165.94,612.33,166.73z",
  "M608.98,166.18c0.5,0.79,1.37,1.5,1.96,1.59c0.58,0.09,0.65-0.47,0.15-1.25c-0.5-0.79-1.37-1.5-1.95-1.59C608.55,164.84,608.49,165.4,608.98,166.18z",
  "M605.62,165.65c0.49,0.79,1.37,1.5,1.95,1.59c0.58,0.09,0.66-0.47,0.16-1.25c-0.5-0.79-1.37-1.5-1.95-1.59C605.2,164.3,605.13,164.86,605.62,165.65z",
  "M602.26,165.12c0.49,0.78,1.36,1.5,1.95,1.59c0.59,0.09,0.66-0.47,0.17-1.26c-0.49-0.79-1.36-1.49-1.95-1.59C601.84,163.77,601.77,164.33,602.26,165.12z",
  "M598.88,164.59c0.49,0.78,1.35,1.49,1.94,1.58c0.59,0.09,0.67-0.47,0.18-1.26c-0.49-0.78-1.36-1.49-1.94-1.58C598.47,163.25,598.39,163.81,598.88,164.59z",
  "M595.49,164.08c0.48,0.78,1.35,1.49,1.94,1.58c0.59,0.09,0.67-0.47,0.19-1.26c-0.48-0.78-1.35-1.49-1.94-1.58C595.09,162.73,595.01,163.3,595.49,164.08z",
  "M592.09,163.57c0.48,0.78,1.34,1.49,1.93,1.58c0.59,0.09,0.68-0.47,0.2-1.26c-0.48-0.78-1.34-1.49-1.93-1.57C591.71,162.23,591.61,162.79,592.09,163.57z",
  "M588.68,163.07c0.47,0.78,1.33,1.49,1.93,1.57c0.59,0.09,0.69-0.48,0.21-1.26c-0.47-0.78-1.34-1.48-1.93-1.57C588.31,161.72,588.21,162.29,588.68,163.07z",
  "M585.27,162.57c0.47,0.78,1.33,1.48,1.92,1.57c0.59,0.09,0.7-0.48,0.23-1.26c-0.47-0.78-1.33-1.48-1.92-1.57C584.9,161.23,584.8,161.79,585.27,162.57z",
  "M581.84,162.08c0.46,0.78,1.32,1.48,1.92,1.56c0.6,0.08,0.7-0.48,0.24-1.26c-0.47-0.78-1.32-1.48-1.91-1.56C581.49,160.74,581.38,161.3,581.84,162.08z",
  "M578.41,161.6c0.46,0.78,1.31,1.48,1.91,1.56c0.6,0.08,0.71-0.48,0.25-1.26c-0.46-0.78-1.31-1.48-1.91-1.56C578.06,160.26,577.95,160.82,578.41,161.6z",
  "M574.96,161.13c0.45,0.78,1.3,1.47,1.9,1.56c0.6,0.08,0.72-0.48,0.26-1.26c-0.46-0.78-1.31-1.47-1.9-1.55C574.63,159.79,574.51,160.35,574.96,161.13z",
  "M571.51,160.66c0.44,0.78,1.29,1.47,1.9,1.55c0.6,0.08,0.72-0.48,0.27-1.26c-0.45-0.78-1.3-1.47-1.89-1.55C571.19,159.32,571.06,159.88,571.51,160.66z",
  "M568.05,160.2c0.44,0.77,1.29,1.47,1.89,1.55c0.6,0.08,0.73-0.48,0.29-1.26c-0.44-0.78-1.29-1.47-1.89-1.55C567.74,158.86,567.61,159.42,568.05,160.2z",
  "M564.58,159.75c0.43,0.77,1.28,1.47,1.88,1.54c0.6,0.08,0.74-0.49,0.3-1.26c-0.44-0.77-1.28-1.46-1.88-1.54C564.28,158.41,564.14,158.97,564.58,159.75z",
  "M561.1,159.3c0.43,0.77,1.27,1.46,1.87,1.54c0.61,0.08,0.75-0.49,0.31-1.26c-0.43-0.77-1.27-1.46-1.87-1.54C560.81,157.96,560.67,158.53,561.1,159.3z",
  "M557.61,158.86c0.42,0.77,1.26,1.46,1.86,1.54c0.61,0.08,0.75-0.49,0.33-1.26c-0.43-0.77-1.26-1.46-1.86-1.53C557.34,157.53,557.19,158.09,557.61,158.86z",
  "M554.12,158.43c0.42,0.77,1.25,1.46,1.86,1.53c0.61,0.07,0.76-0.49,0.34-1.26c-0.42-0.77-1.25-1.46-1.86-1.53C553.86,157.1,553.7,157.66,554.12,158.43z",
  "M550.62,158.01c0.41,0.77,1.24,1.45,1.85,1.53c0.61,0.07,0.77-0.49,0.35-1.26c-0.42-0.77-1.24-1.45-1.85-1.53C550.37,156.68,550.21,157.24,550.62,158.01z",
  "M547.11,157.6c0.4,0.77,1.23,1.45,1.84,1.52c0.61,0.07,0.78-0.49,0.37-1.26c-0.41-0.77-1.23-1.45-1.84-1.52C546.87,156.26,546.7,156.83,547.11,157.6z",
  "M543.59,157.19c0.4,0.77,1.22,1.45,1.83,1.52c0.61,0.07,0.78-0.5,0.38-1.26c-0.4-0.77-1.22-1.45-1.83-1.52C543.36,155.86,543.19,156.42,543.59,157.19z",
  "M540.07,156.79c0.39,0.77,1.21,1.45,1.82,1.52c0.61,0.07,0.79-0.5,0.39-1.27c-0.4-0.77-1.21-1.44-1.82-1.51C539.85,155.46,539.67,156.02,540.07,156.79z",
  "M536.53,156.4c0.39,0.77,1.2,1.44,1.81,1.51c0.62,0.07,0.8-0.5,0.41-1.27c-0.39-0.77-1.2-1.44-1.81-1.51C536.33,155.07,536.15,155.63,536.53,156.4z",
  "M532.99,156.02c0.38,0.76,1.18,1.44,1.8,1.51c0.62,0.07,0.81-0.5,0.42-1.27c-0.38-0.77-1.19-1.44-1.8-1.5C532.8,154.69,532.62,155.25,532.99,156.02z",
  "M529.45,155.64c0.37,0.76,1.17,1.44,1.79,1.5c0.62,0.07,0.81-0.5,0.44-1.27c-0.38-0.76-1.18-1.44-1.79-1.5C529.27,154.31,529.08,154.88,529.45,155.64z",
  "M525.89,155.27c0.36,0.76,1.16,1.43,1.78,1.5c0.62,0.06,0.82-0.51,0.45-1.27c-0.37-0.76-1.17-1.43-1.78-1.5C525.73,153.94,525.53,154.51,525.89,155.27z",
  "M522.33,154.91c0.36,0.76,1.15,1.43,1.77,1.49c0.62,0.06,0.83-0.51,0.47-1.27c-0.36-0.76-1.16-1.43-1.77-1.49C522.18,153.59,521.97,154.15,522.33,154.91z",
  "M518.76,154.56c0.35,0.76,1.14,1.43,1.76,1.49c0.62,0.06,0.84-0.51,0.48-1.27c-0.36-0.76-1.14-1.43-1.76-1.49C518.63,153.24,518.41,153.8,518.76,154.56z",
  "M515.19,154.22c0.34,0.76,1.13,1.43,1.75,1.49c0.62,0.06,0.85-0.51,0.5-1.27c-0.35-0.76-1.13-1.42-1.75-1.48C515.07,152.89,514.85,153.46,515.19,154.22z",
  "M511.61,153.89c0.34,0.76,1.12,1.42,1.74,1.48c0.62,0.06,0.85-0.51,0.51-1.27c-0.34-0.76-1.12-1.42-1.74-1.48C511.5,152.56,511.27,153.13,511.61,153.89z",
  "M508.02,153.56c0.33,0.76,1.1,1.42,1.73,1.48c0.62,0.06,0.86-0.51,0.53-1.27c-0.33-0.76-1.11-1.42-1.73-1.47C507.93,152.23,507.7,152.8,508.02,153.56z",
  "M504.43,153.24c0.32,0.76,1.09,1.42,1.72,1.47c0.63,0.05,0.87-0.52,0.54-1.27c-0.33-0.76-1.09-1.42-1.72-1.47C504.35,151.92,504.11,152.49,504.43,153.24z",
  "M500.83,152.93c0.31,0.76,1.08,1.42,1.7,1.47c0.63,0.05,0.88-0.52,0.56-1.28c-0.32-0.76-1.08-1.41-1.7-1.47C500.77,151.61,500.52,152.18,500.83,152.93z",
  "M497.23,152.63c0.31,0.76,1.06,1.41,1.69,1.47c0.63,0.05,0.88-0.52,0.57-1.28c-0.31-0.76-1.07-1.41-1.69-1.46C497.18,151.31,496.92,151.88,497.23,152.63z",
  "M493.62,152.34c0.3,0.76,1.05,1.41,1.68,1.46c0.63,0.05,0.89-0.52,0.59-1.28c-0.3-0.76-1.06-1.41-1.68-1.46C493.58,151.02,493.32,151.59,493.62,152.34z",
  "M490,152.06c0.29,0.75,1.04,1.41,1.67,1.46c0.63,0.05,0.9-0.52,0.6-1.28c-0.3-0.76-1.04-1.41-1.67-1.45C489.98,150.73,489.71,151.3,490,152.06z",
  "M486.38,151.78c0.28,0.75,1.02,1.41,1.66,1.45c0.63,0.05,0.91-0.53,0.62-1.28c-0.29-0.75-1.03-1.4-1.65-1.45C486.37,150.46,486.1,151.03,486.38,151.78z",
  "M482.75,151.52c0.28,0.75,1.01,1.4,1.64,1.45c0.63,0.05,0.92-0.53,0.64-1.28c-0.28-0.75-1.02-1.4-1.64-1.45C482.76,150.19,482.48,150.76,482.75,151.52z",
  "M479.12,151.26c0.27,0.75,1,1.4,1.63,1.44c0.63,0.04,0.92-0.53,0.65-1.28c-0.27-0.75-1-1.4-1.63-1.44C479.15,149.94,478.85,150.51,479.12,151.26z",
  "M475.48,151.01c0.26,0.75,0.98,1.4,1.62,1.44c0.63,0.04,0.93-0.53,0.67-1.29c-0.27-0.75-0.99-1.39-1.62-1.44C475.52,149.69,475.22,150.26,475.48,151.01z",
  "M471.84,150.77c0.25,0.75,0.97,1.4,1.6,1.44c0.63,0.04,0.94-0.54,0.68-1.29c-0.26-0.75-0.98-1.39-1.6-1.43C471.9,149.45,471.59,150.02,471.84,150.77z",
  "M468.2,150.54c0.24,0.75,0.96,1.39,1.59,1.43c0.64,0.04,0.95-0.54,0.7-1.29c-0.25-0.75-0.96-1.39-1.59-1.43C468.27,149.22,467.95,149.79,468.2,150.54z",
  "M464.54,150.32c0.23,0.75,0.94,1.39,1.58,1.43c0.64,0.04,0.96-0.54,0.72-1.29c-0.24-0.75-0.95-1.39-1.58-1.43C464.63,148.99,464.31,149.57,464.54,150.32z",
  "M460.89,150.11c0.23,0.75,0.93,1.39,1.56,1.42c0.64,0.04,0.96-0.54,0.73-1.29c-0.23-0.75-0.93-1.38-1.56-1.42C460.99,148.78,460.66,149.36,460.89,150.11z",
  "M457.23,149.9c0.22,0.75,0.91,1.39,1.55,1.42c0.64,0.04,0.97-0.55,0.75-1.29c-0.22-0.75-0.92-1.38-1.55-1.42C457.35,148.58,457.01,149.15,457.23,149.9z",
  "M453.56,149.71c0.21,0.75,0.9,1.38,1.54,1.42c0.64,0.03,0.98-0.55,0.77-1.3c-0.22-0.75-0.9-1.38-1.54-1.41C453.7,148.38,453.35,148.96,453.56,149.71z",
  "M449.9,149.52c0.2,0.75,0.88,1.38,1.52,1.41c0.64,0.03,0.99-0.55,0.78-1.3c-0.21-0.75-0.89-1.38-1.52-1.41C450.05,148.2,449.69,148.77,449.9,149.52z",
  "M446.22,149.34c0.19,0.75,0.87,1.38,1.51,1.41c0.64,0.03,1-0.55,0.8-1.3c-0.2-0.75-0.87-1.38-1.51-1.41C446.39,148.02,446.03,148.6,446.22,149.34z",
  "M442.55,149.18c0.18,0.75,0.85,1.38,1.49,1.41c0.64,0.03,1.01-0.55,0.82-1.3c-0.19-0.75-0.86-1.37-1.49-1.4C442.73,147.85,442.36,148.43,442.55,149.18z",
  "M438.87,149.02c0.18,0.75,0.84,1.37,1.48,1.4c0.64,0.03,1.01-0.56,0.83-1.3c-0.18-0.75-0.84-1.37-1.48-1.4C439.07,147.69,438.69,148.27,438.87,149.02z",
  "M435.19,148.87c0.17,0.74,0.82,1.37,1.46,1.4c0.64,0.03,1.02-0.56,0.85-1.31c-0.17-0.75-0.83-1.37-1.46-1.39C435.4,147.54,435.02,148.12,435.19,148.87z",
  "M431.5,148.73c0.16,0.74,0.81,1.37,1.45,1.39c0.64,0.02,1.03-0.56,0.87-1.31c-0.16-0.75-0.81-1.37-1.45-1.39C431.73,147.4,431.34,147.98,431.5,148.73z",
  "M427.81,148.6c0.15,0.74,0.79,1.37,1.44,1.39c0.64,0.02,1.04-0.56,0.88-1.31c-0.16-0.74-0.8-1.36-1.43-1.39C428.06,147.27,427.66,147.85,427.81,148.6z",
  "M424.12,148.48c0.14,0.74,0.78,1.37,1.42,1.39c0.64,0.02,1.05-0.57,0.9-1.31c-0.15-0.74-0.78-1.36-1.42-1.38C424.38,147.15,423.98,147.73,424.12,148.48z",
  "M420.42,148.36c0.13,0.74,0.76,1.36,1.41,1.38c0.64,0.02,1.05-0.57,0.92-1.31c-0.14-0.74-0.77-1.36-1.4-1.38C420.7,147.03,420.29,147.62,420.42,148.36z",
  "M416.72,148.26c0.12,0.74,0.75,1.36,1.39,1.38c0.64,0.02,1.06-0.57,0.93-1.32c-0.13-0.74-0.75-1.36-1.39-1.38C417.02,146.93,416.6,147.52,416.72,148.26z",
  "M413.02,148.17c0.11,0.74,0.73,1.36,1.37,1.38c0.65,0.02,1.07-0.58,0.95-1.32c-0.12-0.74-0.74-1.36-1.37-1.37C413.34,146.84,412.91,147.42,413.02,148.17z",
  "M409.32,148.08c0.11,0.74,0.71,1.36,1.36,1.37c0.65,0.01,1.08-0.58,0.97-1.32c-0.11-0.74-0.72-1.35-1.36-1.37C409.65,146.75,409.22,147.34,409.32,148.08z",
  "M405.62,148.01c0.1,0.74,0.7,1.36,1.34,1.37c0.65,0.01,1.09-0.58,0.98-1.32c-0.1-0.74-0.7-1.35-1.34-1.37C405.96,146.67,405.52,147.26,405.62,148.01z",
  "M401.91,147.94c0.09,0.74,0.68,1.36,1.33,1.37c0.65,0.01,1.09-0.58,1-1.33c-0.09-0.74-0.69-1.35-1.33-1.36C402.27,146.61,401.82,147.2,401.91,147.94z",
  "M398.2,147.88c0.08,0.74,0.67,1.35,1.31,1.36c0.65,0.01,1.1-0.59,1.02-1.33c-0.08-0.74-0.67-1.35-1.31-1.36C398.58,146.55,398.12,147.14,398.2,147.88z",
  "M394.49,147.84c0.07,0.74,0.65,1.35,1.3,1.36c0.65,0.01,1.11-0.59,1.03-1.33c-0.08-0.74-0.66-1.35-1.3-1.36C394.88,146.5,394.42,147.09,394.49,147.84z",
  "M390.77,147.8c0.06,0.74,0.63,1.35,1.28,1.36c0.65,0.01,1.12-0.59,1.05-1.33c-0.07-0.74-0.64-1.35-1.28-1.35C391.18,146.46,390.71,147.06,390.77,147.8z",
  "M387.06,147.77c0.05,0.74,0.62,1.35,1.27,1.35c0.65,0,1.13-0.59,1.07-1.34c-0.06-0.74-0.62-1.34-1.27-1.35C387.49,146.43,387.01,147.03,387.06,147.77z",
  "M383.34,147.75c0.04,0.74,0.6,1.35,1.25,1.35c0.65,0,1.13-0.6,1.08-1.34c-0.05-0.74-0.61-1.34-1.25-1.35C383.79,146.41,383.3,147.01,383.34,147.75z",
  "M379.63,147.74c0.03,0.74,0.59,1.35,1.23,1.35c0.65,0,1.14-0.6,1.1-1.34c-0.04-0.74-0.59-1.34-1.23-1.34C380.09,146.4,379.59,147,379.63,147.74z",
  "M375.91,147.74c0.02,0.74,0.57,1.34,1.22,1.34c0.65,0,1.15-0.6,1.12-1.35c-0.03-0.74-0.58-1.34-1.22-1.34C376.38,146.4,375.88,147,375.91,147.74z",
  "M372.19,147.75c0.01,0.74,0.55,1.34,1.2,1.34c0.65,0,1.16-0.61,1.13-1.35c-0.02-0.74-0.56-1.34-1.2-1.34C372.68,146.41,372.17,147.01,372.19,147.75z",
  "M368.47,147.77c0.01,0.74,0.54,1.34,1.19,1.34c0.65,0,1.16-0.61,1.15-1.35c-0.01-0.74-0.54-1.34-1.19-1.33C368.98,146.43,368.46,147.03,368.47,147.77z",
  "M364.75,147.8c0,0.74,0.52,1.34,1.17,1.34c0.65-0.01,1.17-0.61,1.17-1.35c0-0.74-0.53-1.34-1.17-1.33C365.27,146.46,364.75,147.06,364.75,147.8z",
  "M346.14,148.08c-0.05,0.74,0.44,1.33,1.09,1.32c0.65-0.01,1.21-0.63,1.25-1.37c0.04-0.74-0.45-1.33-1.09-1.32C346.75,146.73,346.19,147.34,346.14,148.08z",
  "M342.42,148.17c-0.06,0.74,0.42,1.33,1.07,1.32c0.65-0.02,1.22-0.63,1.27-1.37c0.05-0.74-0.43-1.33-1.07-1.31C343.04,146.82,342.48,147.43,342.42,148.17z",
  "M242.61,154c-0.29,0.76,0,1.33,0.63,1.27c0.64-0.06,1.38-0.72,1.66-1.48c0.28-0.76,0-1.32-0.63-1.26C243.64,152.58,242.9,153.24,242.61,154z",
  "M228.04,155.42c-0.32,0.76-0.06,1.33,0.57,1.26c0.63-0.06,1.4-0.73,1.71-1.49c0.31-0.76,0.06-1.32-0.57-1.26C229.12,153.99,228.36,154.66,228.04,155.42z",
  "M224.41,155.8c-0.32,0.76-0.08,1.33,0.56,1.26c0.63-0.07,1.4-0.74,1.72-1.5c0.32-0.76,0.07-1.32-0.56-1.26C225.51,154.37,224.74,155.03,224.41,155.8z",
  "M220.79,156.18c-0.33,0.76-0.09,1.33,0.54,1.26c0.63-0.07,1.41-0.74,1.73-1.5c0.33-0.76,0.08-1.32-0.54-1.26C221.89,154.75,221.12,155.42,220.79,156.18z",
  "M217.17,156.58c-0.34,0.76-0.1,1.33,0.53,1.26c0.63-0.07,1.41-0.74,1.75-1.51c0.33-0.76,0.1-1.32-0.53-1.26C218.29,155.14,217.51,155.81,217.17,156.58z",
  "M206.34,157.81c-0.36,0.77-0.14,1.33,0.48,1.26c0.63-0.07,1.42-0.75,1.78-1.52c0.36-0.77,0.14-1.33-0.48-1.25C207.5,156.36,206.71,157.04,206.34,157.81z",
  "M195.58,159.12c-0.38,0.77-0.18,1.33,0.44,1.25c0.62-0.08,1.43-0.76,1.81-1.53c0.38-0.77,0.18-1.33-0.44-1.25C196.77,157.66,195.96,158.35,195.58,159.12z",
  "M192,159.57c-0.39,0.77-0.2,1.33,0.43,1.25c0.62-0.08,1.44-0.77,1.82-1.54c0.38-0.77,0.19-1.33-0.43-1.25C193.21,158.11,192.39,158.8,192,159.57z",
  "M188.44,160.03c-0.39,0.77-0.21,1.33,0.41,1.25c0.62-0.08,1.44-0.77,1.83-1.54c0.39-0.77,0.2-1.33-0.41-1.25C189.65,158.57,188.83,159.26,188.44,160.03z",
  "M184.88,160.5c-0.4,0.77-0.22,1.33,0.4,1.25c0.62-0.08,1.44-0.77,1.84-1.54c0.39-0.77,0.22-1.33-0.4-1.25C186.1,159.04,185.28,159.73,184.88,160.5z",
  "M181.32,160.97c-0.41,0.77-0.23,1.33,0.39,1.25c0.62-0.08,1.44-0.78,1.85-1.55c0.4-0.77,0.23-1.33-0.39-1.25C182.55,159.51,181.73,160.2,181.32,160.97z",
  "M177.78,161.46c-0.41,0.77-0.25,1.33,0.37,1.25c0.62-0.08,1.45-0.78,1.85-1.55c0.41-0.77,0.24-1.33-0.37-1.25C179.02,159.99,178.19,160.68,177.78,161.46z",
  "M174.24,161.95c-0.42,0.77-0.26,1.33,0.36,1.25c0.62-0.09,1.45-0.78,1.86-1.56c0.41-0.77,0.25-1.33-0.36-1.25C175.49,160.48,174.66,161.17,174.24,161.95z",
  "M170.71,162.45c-0.42,0.78-0.27,1.33,0.35,1.25c0.61-0.09,1.45-0.79,1.87-1.56c0.42-0.77,0.26-1.33-0.35-1.24C171.97,160.97,171.13,161.67,170.71,162.45z",
  "M167.18,162.95c-0.43,0.78-0.28,1.33,0.33,1.25c0.61-0.09,1.45-0.79,1.88-1.57c0.42-0.78,0.28-1.33-0.33-1.24C168.45,161.48,167.61,162.18,167.18,162.95z",
  "M163.67,163.47c-0.43,0.78-0.29,1.34,0.32,1.25c0.61-0.09,1.46-0.79,1.89-1.57c0.43-0.78,0.29-1.33-0.32-1.24C164.95,161.99,164.1,162.69,163.67,163.47z",
  "M160.16,163.99c-0.44,0.78-0.3,1.34,0.31,1.24c0.61-0.09,1.46-0.8,1.89-1.57c0.44-0.78,0.3-1.33-0.31-1.24C161.45,162.51,160.6,163.21,160.16,163.99z",
  "M156.67,164.52c-0.45,0.78-0.31,1.34,0.29,1.24c0.61-0.09,1.46-0.8,1.9-1.58c0.44-0.78,0.31-1.33-0.3-1.24C157.96,163.03,157.11,163.74,156.67,164.52z",
  "M153.18,165.05c-0.45,0.78-0.32,1.34,0.28,1.24c0.61-0.09,1.46-0.8,1.91-1.58c0.45-0.78,0.32-1.33-0.28-1.24C154.48,163.57,153.63,164.27,153.18,165.05z",
  "M149.7,165.6c-0.46,0.78-0.33,1.34,0.27,1.24c0.61-0.09,1.46-0.8,1.91-1.58c0.45-0.78,0.33-1.34-0.27-1.24C151.01,164.11,150.15,164.82,149.7,165.6z",
  "M146.22,166.15c-0.46,0.78-0.35,1.34,0.26,1.24c0.6-0.1,1.46-0.81,1.92-1.59c0.46-0.78,0.34-1.34-0.26-1.24C147.54,164.66,146.68,165.37,146.22,166.15z",
  "M142.76,166.71c-0.47,0.78-0.35,1.34,0.25,1.24c0.6-0.1,1.46-0.81,1.93-1.59c0.46-0.78,0.35-1.34-0.25-1.24C144.09,165.21,143.23,165.92,142.76,166.71z",
  "M139.31,167.27c-0.47,0.78-0.36,1.34,0.24,1.24c0.6-0.1,1.47-0.81,1.93-1.6c0.47-0.78,0.36-1.34-0.24-1.24C140.64,165.77,139.78,166.49,139.31,167.27z",
  "M135.86,167.84c-0.47,0.78-0.37,1.34,0.22,1.24c0.6-0.1,1.47-0.82,1.94-1.6c0.47-0.78,0.37-1.34-0.23-1.24C137.21,166.34,136.34,167.06,135.86,167.84z",
  "M132.43,168.42c-0.48,0.78-0.38,1.34,0.21,1.24c0.6-0.1,1.47-0.82,1.94-1.6c0.48-0.78,0.38-1.34-0.21-1.24C133.78,166.92,132.91,167.64,132.43,168.42z",
  "M129.01,169c-0.48,0.79-0.39,1.34,0.2,1.24c0.6-0.1,1.47-0.82,1.95-1.61c0.48-0.79,0.39-1.34-0.2-1.24C130.36,167.5,129.49,168.22,129.01,169z",
  "M651.87,169.82c0.54,0.8,1.42,1.53,1.97,1.64c0.55,0.11,0.57-0.45,0.03-1.25c-0.54-0.8-1.42-1.53-1.97-1.64C651.34,168.47,651.33,169.03,651.87,169.82z",
  "M648.67,169.21c0.53,0.8,1.42,1.53,1.97,1.63c0.56,0.11,0.57-0.45,0.04-1.25c-0.54-0.8-1.42-1.53-1.97-1.63C648.15,167.85,648.13,168.41,648.67,169.21z",
  "M645.46,168.6c0.53,0.79,1.42,1.52,1.97,1.63c0.56,0.11,0.58-0.45,0.04-1.25c-0.53-0.8-1.42-1.53-1.97-1.63C644.95,167.25,644.93,167.8,645.46,168.6z",
  "M642.24,168c0.53,0.79,1.41,1.52,1.97,1.63c0.56,0.1,0.58-0.45,0.05-1.25c-0.53-0.79-1.41-1.52-1.97-1.63C641.73,166.64,641.71,167.2,642.24,168z",
  "M639.01,167.4c0.53,0.79,1.41,1.52,1.97,1.62c0.56,0.1,0.59-0.46,0.06-1.25c-0.53-0.79-1.41-1.52-1.97-1.62C638.51,166.05,638.48,166.6,639.01,167.4z",
  "M635.77,166.81c0.53,0.79,1.41,1.52,1.97,1.62c0.56,0.1,0.59-0.46,0.06-1.25c-0.53-0.79-1.41-1.52-1.97-1.62C635.28,165.45,635.25,166.01,635.77,166.81z",
  "M632.52,166.22c0.52,0.79,1.41,1.52,1.97,1.62c0.57,0.1,0.6-0.46,0.07-1.25c-0.53-0.79-1.41-1.52-1.97-1.62C632.03,164.87,632,165.43,632.52,166.22z",
  "M629.26,165.64c0.52,0.79,1.4,1.51,1.97,1.61c0.57,0.1,0.6-0.46,0.08-1.25c-0.52-0.79-1.4-1.51-1.97-1.61C628.78,164.29,628.74,164.85,629.26,165.64z",
  "M625.99,165.06c0.52,0.79,1.4,1.51,1.97,1.61c0.57,0.1,0.61-0.46,0.09-1.25c-0.52-0.79-1.4-1.51-1.97-1.61C625.52,163.72,625.48,164.27,625.99,165.06z",
  "M622.71,164.5c0.51,0.79,1.4,1.51,1.97,1.61c0.57,0.1,0.61-0.46,0.1-1.25c-0.52-0.79-1.4-1.51-1.96-1.61C622.24,163.15,622.2,163.71,622.71,164.5z",
  "M619.42,163.93c0.51,0.79,1.39,1.51,1.96,1.6c0.57,0.1,0.62-0.46,0.1-1.25c-0.51-0.79-1.39-1.51-1.96-1.6C618.96,162.59,618.91,163.15,619.42,163.93z",
  "M616.13,163.38c0.51,0.79,1.39,1.5,1.96,1.6c0.57,0.1,0.62-0.46,0.11-1.25c-0.51-0.79-1.39-1.5-1.96-1.6C615.67,162.03,615.62,162.59,616.13,163.38z",
  "M612.82,162.83c0.5,0.79,1.38,1.5,1.96,1.6c0.57,0.1,0.63-0.46,0.12-1.25c-0.51-0.79-1.38-1.5-1.96-1.59C612.37,161.48,612.31,162.04,612.82,162.83z",
  "M609.5,162.28c0.5,0.78,1.38,1.5,1.95,1.59c0.58,0.09,0.64-0.47,0.13-1.25c-0.5-0.78-1.38-1.5-1.95-1.59C609.06,160.94,609,161.5,609.5,162.28z",
  "M606.17,161.75c0.5,0.78,1.37,1.49,1.95,1.59c0.58,0.09,0.64-0.47,0.14-1.25c-0.5-0.78-1.37-1.49-1.95-1.59C605.74,160.4,605.67,160.96,606.17,161.75z",
  "M602.83,161.22c0.49,0.78,1.36,1.49,1.94,1.58c0.58,0.09,0.65-0.47,0.15-1.25c-0.5-0.78-1.37-1.49-1.94-1.58C602.41,159.87,602.34,160.43,602.83,161.22z",
  "M599.49,160.69c0.49,0.78,1.36,1.49,1.94,1.58c0.58,0.09,0.65-0.47,0.16-1.25c-0.49-0.78-1.36-1.49-1.94-1.58C599.07,159.35,599,159.91,599.49,160.69z",
  "M596.13,160.17c0.49,0.78,1.35,1.49,1.94,1.58c0.58,0.09,0.66-0.47,0.17-1.25c-0.49-0.78-1.35-1.48-1.93-1.57C595.73,158.84,595.65,159.39,596.13,160.17z",
  "M592.77,159.66c0.48,0.78,1.34,1.48,1.93,1.57c0.59,0.09,0.67-0.47,0.18-1.25c-0.48-0.78-1.35-1.48-1.93-1.57C592.37,158.33,592.29,158.89,592.77,159.66z",
  "M589.4,159.16c0.48,0.78,1.34,1.48,1.92,1.57c0.59,0.09,0.67-0.47,0.19-1.25c-0.48-0.78-1.34-1.48-1.92-1.57C589.01,157.82,588.92,158.38,589.4,159.16z",
  "M586.02,158.66c0.47,0.78,1.33,1.48,1.92,1.56c0.59,0.09,0.68-0.47,0.21-1.25c-0.47-0.78-1.33-1.48-1.92-1.56C585.64,157.33,585.55,157.89,586.02,158.66z",
  "M582.63,158.17c0.47,0.78,1.32,1.47,1.91,1.56c0.59,0.08,0.69-0.48,0.22-1.25c-0.47-0.78-1.32-1.47-1.91-1.56C582.26,156.84,582.16,157.4,582.63,158.17z",
  "M579.23,157.69c0.46,0.77,1.32,1.47,1.91,1.56c0.59,0.08,0.69-0.48,0.23-1.25c-0.46-0.78-1.32-1.47-1.91-1.55C578.87,156.36,578.77,156.92,579.23,157.69z",
  "M575.83,157.22c0.46,0.77,1.31,1.47,1.9,1.55c0.59,0.08,0.7-0.48,0.24-1.25c-0.46-0.77-1.31-1.47-1.9-1.55C575.48,155.88,575.37,156.44,575.83,157.22z",
  "M572.41,156.75c0.45,0.77,1.3,1.47,1.89,1.55c0.59,0.08,0.71-0.48,0.25-1.25c-0.45-0.77-1.3-1.46-1.89-1.54C572.07,155.41,571.96,155.97,572.41,156.75z",
  "M568.99,156.29c0.44,0.77,1.29,1.46,1.89,1.54c0.6,0.08,0.71-0.48,0.26-1.25c-0.45-0.77-1.29-1.46-1.88-1.54C568.66,154.95,568.54,155.51,568.99,156.29z",
  "M565.56,155.83c0.44,0.77,1.28,1.46,1.88,1.54c0.6,0.08,0.72-0.48,0.28-1.25c-0.44-0.77-1.28-1.46-1.88-1.54C565.24,154.5,565.12,155.06,565.56,155.83z",
  "M562.12,155.39c0.43,0.77,1.27,1.46,1.87,1.53c0.6,0.08,0.73-0.48,0.29-1.25c-0.44-0.77-1.27-1.46-1.87-1.53C561.82,154.06,561.69,154.62,562.12,155.39z",
  "M558.68,154.95c0.43,0.77,1.26,1.45,1.86,1.53c0.6,0.08,0.74-0.49,0.3-1.25c-0.43-0.77-1.27-1.45-1.86-1.53C558.38,153.62,558.25,154.18,558.68,154.95z",
  "M555.22,154.51c0.42,0.77,1.25,1.45,1.85,1.53c0.6,0.08,0.74-0.49,0.32-1.25c-0.43-0.77-1.26-1.45-1.85-1.52C554.94,153.19,554.8,153.75,555.22,154.51z",
  "M551.76,154.09c0.42,0.77,1.24,1.45,1.85,1.52c0.6,0.07,0.75-0.49,0.33-1.26c-0.42-0.77-1.25-1.45-1.84-1.52C551.49,152.76,551.35,153.32,551.76,154.09z",
  "M548.3,153.67c0.41,0.76,1.23,1.44,1.84,1.52c0.6,0.07,0.76-0.49,0.34-1.26c-0.41-0.76-1.24-1.44-1.84-1.51C548.04,152.35,547.89,152.91,548.3,153.67z",
  "M544.82,153.26c0.4,0.76,1.22,1.44,1.83,1.51c0.61,0.07,0.76-0.49,0.36-1.26c-0.41-0.76-1.23-1.44-1.83-1.51C544.58,151.94,544.42,152.5,544.82,153.26z",
  "M541.34,152.86c0.4,0.76,1.21,1.44,1.82,1.51c0.61,0.07,0.77-0.49,0.37-1.26c-0.4-0.76-1.22-1.44-1.82-1.51C541.11,151.54,540.94,152.1,541.34,152.86z",
  "M537.85,152.47c0.39,0.76,1.2,1.43,1.81,1.5c0.61,0.07,0.78-0.5,0.38-1.26c-0.4-0.76-1.21-1.43-1.81-1.5C537.63,151.15,537.46,151.71,537.85,152.47z",
  "M534.36,152.08c0.38,0.76,1.19,1.43,1.8,1.5c0.61,0.07,0.79-0.5,0.4-1.26c-0.39-0.76-1.2-1.43-1.8-1.5C534.15,150.76,533.97,151.32,534.36,152.08z",
  "M530.85,151.71c0.38,0.76,1.18,1.43,1.79,1.49c0.61,0.07,0.79-0.5,0.41-1.26c-0.38-0.76-1.18-1.43-1.79-1.49C530.66,150.39,530.48,150.95,530.85,151.71z",
  "M527.34,151.34c0.37,0.76,1.17,1.43,1.78,1.49c0.61,0.06,0.8-0.5,0.42-1.26c-0.38-0.76-1.17-1.42-1.78-1.49C527.16,150.02,526.97,150.58,527.34,151.34z",
  "M523.83,150.98c0.36,0.76,1.16,1.42,1.77,1.49c0.61,0.06,0.81-0.5,0.44-1.26c-0.37-0.76-1.16-1.42-1.77-1.48C523.66,149.66,523.47,150.22,523.83,150.98z",
  "M520.31,150.62c0.36,0.76,1.15,1.42,1.76,1.48c0.61,0.06,0.82-0.5,0.45-1.26c-0.36-0.76-1.15-1.42-1.76-1.48C520.15,149.3,519.95,149.87,520.31,150.62z",
  "M516.78,150.28c0.35,0.76,1.13,1.42,1.75,1.48c0.62,0.06,0.82-0.51,0.47-1.26c-0.36-0.76-1.14-1.41-1.75-1.47C516.64,148.96,516.43,149.52,516.78,150.28z",
  "M513.25,149.94c0.34,0.75,1.12,1.41,1.74,1.47c0.62,0.06,0.83-0.51,0.48-1.26c-0.35-0.75-1.13-1.41-1.74-1.47C513.12,148.62,512.9,149.19,513.25,149.94z",
  "M509.71,149.61c0.34,0.75,1.11,1.41,1.73,1.47c0.62,0.06,0.84-0.51,0.5-1.26c-0.34-0.75-1.11-1.41-1.72-1.47C509.6,148.29,509.37,148.86,509.71,149.61z",
  "M506.16,149.29c0.33,0.75,1.1,1.41,1.71,1.46c0.62,0.06,0.85-0.51,0.51-1.26c-0.33-0.75-1.1-1.41-1.71-1.46C506.07,147.97,505.83,148.54,506.16,149.29z",
  "M502.61,148.98c0.32,0.75,1.08,1.41,1.7,1.46c0.62,0.05,0.86-0.51,0.53-1.26c-0.33-0.75-1.09-1.4-1.7-1.46C502.53,147.66,502.29,148.23,502.61,148.98z",
  "M499.06,148.67c0.31,0.75,1.07,1.4,1.69,1.46c0.62,0.05,0.86-0.51,0.54-1.27c-0.32-0.75-1.08-1.4-1.69-1.45C498.99,147.36,498.74,147.92,499.06,148.67z",
  "M495.49,148.38c0.31,0.75,1.06,1.4,1.68,1.45c0.62,0.05,0.87-0.52,0.56-1.27c-0.31-0.75-1.06-1.4-1.68-1.45C495.44,147.06,495.19,147.63,495.49,148.38z",
  "M491.93,148.09c0.3,0.75,1.04,1.4,1.67,1.45c0.62,0.05,0.88-0.52,0.57-1.27c-0.3-0.75-1.05-1.39-1.67-1.44C491.89,146.78,491.63,147.34,491.93,148.09z",
  "M488.36,147.81c0.29,0.75,1.03,1.39,1.65,1.44c0.62,0.05,0.89-0.52,0.59-1.27c-0.3-0.75-1.04-1.39-1.65-1.44C488.33,146.5,488.06,147.06,488.36,147.81z",
  "M484.78,147.54c0.28,0.75,1.02,1.39,1.64,1.44c0.62,0.05,0.9-0.52,0.61-1.27c-0.29-0.75-1.02-1.39-1.64-1.43C484.77,146.23,484.5,146.8,484.78,147.54z",
  "M481.2,147.28c0.27,0.75,1,1.39,1.63,1.43c0.62,0.05,0.9-0.52,0.62-1.27c-0.28-0.75-1.01-1.39-1.63-1.43C481.2,145.97,480.92,146.53,481.2,147.28z",
  "M477.61,147.03c0.27,0.75,0.99,1.39,1.62,1.43c0.63,0.04,0.91-0.53,0.64-1.27c-0.27-0.75-1-1.38-1.62-1.43C477.63,145.72,477.34,146.28,477.61,147.03z",
  "M474.02,146.78c0.26,0.74,0.98,1.38,1.6,1.43c0.63,0.04,0.92-0.53,0.65-1.27c-0.26-0.74-0.98-1.38-1.6-1.42C474.05,145.47,473.76,146.04,474.02,146.78z",
  "M470.42,146.55c0.25,0.74,0.96,1.38,1.59,1.42c0.63,0.04,0.93-0.53,0.67-1.27c-0.26-0.74-0.97-1.38-1.59-1.42C470.47,145.24,470.17,145.8,470.42,146.55z",
  "M466.82,146.32c0.24,0.74,0.95,1.38,1.58,1.42c0.63,0.04,0.93-0.53,0.69-1.28c-0.25-0.74-0.95-1.38-1.58-1.41C466.89,145.01,466.58,145.58,466.82,146.32z",
  "M463.22,146.1c0.23,0.74,0.93,1.38,1.56,1.41c0.63,0.04,0.94-0.54,0.7-1.28c-0.24-0.74-0.94-1.37-1.56-1.41C463.3,144.79,462.98,145.36,463.22,146.1z",
  "M459.61,145.89c0.23,0.74,0.92,1.37,1.55,1.41c0.63,0.04,0.95-0.54,0.72-1.28c-0.23-0.74-0.93-1.37-1.55-1.41C459.7,144.58,459.38,145.15,459.61,145.89z",
  "M455.99,145.69c0.22,0.74,0.9,1.37,1.53,1.41c0.63,0.03,0.96-0.54,0.73-1.28c-0.22-0.74-0.91-1.37-1.53-1.4C456.11,144.38,455.78,144.95,455.99,145.69z",
  "M452.38,145.5c0.21,0.74,0.89,1.37,1.52,1.4c0.63,0.03,0.97-0.54,0.75-1.28c-0.22-0.74-0.9-1.37-1.52-1.4C452.5,144.19,452.17,144.76,452.38,145.5z",
  "M448.76,145.32c0.2,0.74,0.88,1.37,1.51,1.4c0.63,0.03,0.97-0.54,0.77-1.28c-0.21-0.74-0.88-1.36-1.51-1.39C448.9,144.01,448.56,144.58,448.76,145.32z",
  "M445.13,145.15c0.19,0.74,0.86,1.36,1.49,1.39c0.63,0.03,0.98-0.55,0.78-1.29c-0.2-0.74-0.87-1.36-1.49-1.39C445.29,143.83,444.94,144.41,445.13,145.15z",
  "M441.51,144.98c0.18,0.74,0.85,1.36,1.48,1.39c0.63,0.03,0.99-0.55,0.8-1.29c-0.19-0.74-0.85-1.36-1.48-1.39C441.68,143.67,441.32,144.24,441.51,144.98z",
  "M437.88,144.83c0.17,0.74,0.83,1.36,1.46,1.39c0.63,0.03,1-0.55,0.82-1.29c-0.18-0.74-0.84-1.36-1.46-1.38C438.07,143.51,437.7,144.09,437.88,144.83z",
  "M434.24,144.68c0.17,0.74,0.82,1.36,1.45,1.38c0.63,0.03,1.01-0.55,0.83-1.29c-0.17-0.74-0.82-1.35-1.45-1.38C434.45,143.37,434.07,143.94,434.24,144.68z",
  "M430.6,144.54c0.16,0.74,0.8,1.36,1.43,1.38c0.63,0.02,1.01-0.56,0.85-1.29c-0.16-0.74-0.81-1.35-1.43-1.37C430.83,143.23,430.45,143.8,430.6,144.54z",
  "M426.96,144.41c0.15,0.74,0.78,1.35,1.42,1.38c0.63,0.02,1.02-0.56,0.87-1.3c-0.16-0.74-0.79-1.35-1.42-1.37C427.2,143.1,426.81,143.68,426.96,144.41z",
  "M423.32,144.29c0.14,0.74,0.77,1.35,1.4,1.37c0.64,0.02,1.03-0.56,0.88-1.3c-0.15-0.74-0.78-1.35-1.4-1.37C423.58,142.98,423.18,143.56,423.32,144.29z",
  "M419.68,144.18c0.13,0.73,0.75,1.35,1.39,1.37c0.64,0.02,1.04-0.56,0.9-1.3c-0.14-0.74-0.76-1.35-1.39-1.36C419.95,142.87,419.54,143.45,419.68,144.18z",
  "M416.03,144.08c0.12,0.74,0.74,1.35,1.37,1.36c0.64,0.02,1.05-0.57,0.92-1.3c-0.13-0.74-0.74-1.34-1.37-1.36C416.32,142.77,415.9,143.35,416.03,144.08z",
  "M412.38,143.99c0.11,0.73,0.72,1.34,1.36,1.36c0.64,0.02,1.05-0.57,0.93-1.3c-0.12-0.74-0.73-1.34-1.36-1.36C412.68,142.67,412.26,143.25,412.38,143.99z",
  "M408.72,143.91c0.11,0.73,0.71,1.34,1.34,1.36c0.64,0.01,1.06-0.57,0.95-1.31c-0.11-0.73-0.71-1.34-1.34-1.35C409.05,142.59,408.62,143.17,408.72,143.91z",
  "M405.07,143.83c0.1,0.73,0.69,1.34,1.33,1.35c0.64,0.01,1.07-0.57,0.97-1.31c-0.1-0.73-0.7-1.34-1.33-1.35C405.41,142.52,404.97,143.1,405.07,143.83z",
  "M401.41,143.77c0.09,0.73,0.67,1.34,1.31,1.35c0.64,0.01,1.08-0.58,0.98-1.31c-0.09-0.73-0.68-1.34-1.31-1.35C401.77,142.45,401.33,143.03,401.41,143.77z",
  "M397.75,143.71c0.08,0.73,0.66,1.34,1.3,1.35c0.64,0.01,1.09-0.58,1-1.31c-0.09-0.73-0.67-1.33-1.3-1.34C398.12,142.39,397.68,142.98,397.75,143.71z",
  "M394.09,143.67c0.07,0.73,0.64,1.34,1.28,1.34c0.64,0.01,1.09-0.58,1.02-1.32c-0.08-0.73-0.65-1.33-1.28-1.34C394.48,142.35,394.02,142.93,394.09,143.67z",
  "M390.43,143.63c0.06,0.73,0.63,1.33,1.27,1.34c0.64,0.01,1.1-0.59,1.04-1.32c-0.07-0.73-0.63-1.33-1.26-1.34C390.83,142.31,390.37,142.9,390.43,143.63z",
  "M386.77,143.6c0.05,0.73,0.61,1.33,1.25,1.34c0.64,0,1.11-0.59,1.05-1.32c-0.06-0.73-0.62-1.33-1.25-1.33C387.19,142.28,386.72,142.87,386.77,143.6z",
  "M383.1,143.58c0.04,0.73,0.59,1.33,1.23,1.33c0.64,0,1.12-0.59,1.07-1.32c-0.05-0.73-0.6-1.33-1.23-1.33C383.54,142.26,383.06,142.85,383.1,143.58z",
  "M379.44,143.58c0.03,0.73,0.58,1.33,1.22,1.33c0.64,0,1.13-0.59,1.09-1.33c-0.04-0.73-0.58-1.33-1.22-1.33C379.89,142.25,379.4,142.84,379.44,143.58z",
  "M375.77,143.58c0.02,0.73,0.56,1.33,1.2,1.33c0.64,0,1.13-0.6,1.1-1.33c-0.03-0.73-0.57-1.32-1.2-1.32C376.24,142.25,375.75,142.84,375.77,143.58z",
  "M342.74,144c-0.06,0.73,0.41,1.32,1.05,1.3c0.64-0.01,1.2-0.62,1.25-1.36c0.05-0.73-0.42-1.31-1.05-1.3C343.36,142.66,342.8,143.26,342.74,144z",
  "M339.07,144.09c-0.07,0.73,0.4,1.32,1.04,1.3c0.64-0.02,1.21-0.63,1.27-1.36c0.06-0.73-0.41-1.31-1.04-1.3C339.7,142.75,339.14,143.36,339.07,144.09z",
  "M273.23,147.35c-0.22,0.74,0.11,1.31,0.74,1.27c0.63-0.05,1.32-0.69,1.54-1.43c0.22-0.74-0.12-1.31-0.75-1.26C274.14,145.97,273.45,146.6,273.23,147.35z",
  "M269.59,147.62c-0.23,0.74,0.1,1.31,0.73,1.26c0.63-0.05,1.33-0.69,1.55-1.43c0.22-0.74-0.1-1.31-0.73-1.26C270.52,146.23,269.82,146.87,269.59,147.62z",
  "M229.83,151.14c-0.32,0.75-0.07,1.32,0.56,1.25c0.63-0.06,1.39-0.73,1.7-1.48c0.31-0.75,0.06-1.31-0.56-1.25C230.91,149.72,230.15,150.38,229.83,151.14z",
  "M211.94,153.08c-0.35,0.76-0.14,1.32,0.48,1.25c0.62-0.07,1.41-0.74,1.76-1.5c0.35-0.76,0.13-1.31-0.49-1.24C213.08,151.65,212.29,152.32,211.94,153.08z",
  "M208.38,153.5c-0.36,0.76-0.15,1.32,0.47,1.25c0.62-0.07,1.41-0.75,1.77-1.51c0.35-0.76,0.14-1.32-0.47-1.24C209.53,152.06,208.74,152.74,208.38,153.5z",
  "M204.82,153.92c-0.37,0.76-0.16,1.32,0.46,1.25c0.62-0.07,1.41-0.75,1.78-1.51c0.36-0.76,0.16-1.32-0.46-1.24C205.99,152.48,205.19,153.16,204.82,153.92z",
  "M201.27,154.35c-0.37,0.76-0.18,1.32,0.44,1.24c0.62-0.08,1.42-0.75,1.79-1.52c0.37-0.76,0.17-1.32-0.44-1.24C202.45,152.91,201.65,153.59,201.27,154.35z",
  "M197.73,154.79c-0.38,0.76-0.19,1.32,0.43,1.24c0.62-0.08,1.42-0.76,1.8-1.52c0.38-0.76,0.18-1.32-0.43-1.24C198.91,153.35,198.11,154.02,197.73,154.79z",
  "M194.19,155.23c-0.39,0.76-0.2,1.32,0.41,1.24c0.62-0.08,1.43-0.76,1.81-1.52c0.38-0.76,0.2-1.32-0.41-1.24C195.39,153.79,194.58,154.47,194.19,155.23z",
  "M190.66,155.69c-0.39,0.77-0.21,1.32,0.4,1.24c0.61-0.08,1.43-0.76,1.82-1.53c0.39-0.76,0.21-1.32-0.4-1.24C191.86,154.24,191.05,154.92,190.66,155.69z",
  "M187.13,156.15c-0.4,0.77-0.23,1.32,0.39,1.24c0.61-0.08,1.43-0.77,1.83-1.53c0.39-0.77,0.22-1.32-0.39-1.24C188.35,154.7,187.53,155.38,187.13,156.15z",
  "M183.61,156.62c-0.41,0.77-0.24,1.32,0.37,1.24c0.61-0.08,1.43-0.77,1.84-1.54c0.4-0.77,0.23-1.32-0.37-1.24C184.84,155.17,184.02,155.85,183.61,156.62z",
  "M180.1,157.1c-0.41,0.77-0.25,1.32,0.36,1.24c0.61-0.08,1.44-0.77,1.84-1.54c0.41-0.77,0.25-1.32-0.36-1.24C181.34,155.64,180.51,156.33,180.1,157.1z",
  "M176.6,157.58c-0.42,0.77-0.26,1.33,0.35,1.24c0.61-0.08,1.44-0.78,1.85-1.55c0.41-0.77,0.26-1.32-0.35-1.24C177.84,156.12,177.01,156.81,176.6,157.58z",
  "M173.1,158.08c-0.42,0.77-0.27,1.33,0.33,1.24c0.61-0.09,1.44-0.78,1.86-1.55c0.42-0.77,0.27-1.32-0.34-1.24C174.36,156.61,173.52,157.31,173.1,158.08z",
  "M169.61,158.58c-0.43,0.77-0.29,1.33,0.32,1.24c0.61-0.09,1.44-0.78,1.87-1.56c0.42-0.77,0.28-1.32-0.32-1.24C170.87,157.11,170.04,157.81,169.61,158.58z",
  "M166.13,159.09c-0.43,0.77-0.3,1.33,0.31,1.24c0.61-0.09,1.45-0.79,1.88-1.56c0.43-0.77,0.29-1.33-0.31-1.24C167.4,157.62,166.56,158.31,166.13,159.09z",
  "M162.65,159.6c-0.44,0.77-0.31,1.33,0.3,1.24c0.6-0.09,1.45-0.79,1.88-1.56c0.44-0.77,0.3-1.33-0.3-1.24C163.93,158.13,163.09,158.83,162.65,159.6z",
  "M159.18,160.13c-0.45,0.77-0.32,1.33,0.29,1.24c0.6-0.09,1.45-0.79,1.89-1.57c0.44-0.77,0.31-1.33-0.29-1.24C160.47,158.65,159.63,159.35,159.18,160.13z",
  "M155.72,160.66c-0.45,0.78-0.33,1.33,0.27,1.24c0.6-0.09,1.45-0.8,1.9-1.57c0.45-0.77,0.32-1.33-0.27-1.24C157.02,159.18,156.17,159.88,155.72,160.66z",
  "M152.27,161.2c-0.46,0.78-0.34,1.33,0.26,1.24c0.6-0.09,1.45-0.8,1.91-1.58c0.45-0.78,0.33-1.33-0.26-1.24C153.58,159.71,152.73,160.42,152.27,161.2z",
  "M141.96,162.85c-0.47,0.78-0.37,1.33,0.23,1.24c0.6-0.1,1.46-0.81,1.92-1.59c0.47-0.78,0.36-1.33-0.23-1.23C143.3,161.36,142.43,162.07,141.96,162.85z",
  "M138.55,163.42c-0.47,0.78-0.38,1.33,0.22,1.24c0.59-0.1,1.46-0.81,1.93-1.59c0.47-0.78,0.37-1.33-0.22-1.23C139.88,161.93,139.02,162.64,138.55,163.42z",
  "M135.14,163.99c-0.48,0.78-0.39,1.34,0.21,1.24c0.59-0.1,1.46-0.82,1.93-1.6c0.48-0.78,0.38-1.33-0.21-1.23C136.48,162.5,135.62,163.21,135.14,163.99z",
  "M642.45,164.11c0.53,0.79,1.41,1.52,1.97,1.63c0.56,0.11,0.57-0.45,0.04-1.25c-0.53-0.79-1.41-1.52-1.97-1.63C641.94,162.75,641.92,163.31,642.45,164.11z",
  "M639.24,163.51c0.53,0.79,1.41,1.52,1.97,1.62c0.56,0.1,0.58-0.46,0.05-1.25c-0.53-0.79-1.41-1.52-1.97-1.62C638.73,162.16,638.71,162.72,639.24,163.51z",
  "M636.03,162.92c0.53,0.79,1.41,1.52,1.97,1.62c0.56,0.1,0.58-0.46,0.05-1.25c-0.53-0.79-1.41-1.52-1.97-1.62C635.52,161.57,635.5,162.13,636.03,162.92z",
  "M632.8,162.33c0.53,0.79,1.41,1.51,1.97,1.62c0.56,0.1,0.59-0.46,0.06-1.25c-0.53-0.79-1.41-1.52-1.97-1.62C632.3,160.98,632.27,161.54,632.8,162.33z",
  "M629.56,161.75c0.52,0.79,1.4,1.51,1.97,1.61c0.56,0.1,0.59-0.46,0.07-1.25c-0.53-0.79-1.4-1.51-1.97-1.61C629.07,160.4,629.04,160.96,629.56,161.75z",
  "M626.32,161.18c0.52,0.79,1.4,1.51,1.96,1.61c0.56,0.1,0.6-0.46,0.07-1.25c-0.52-0.79-1.4-1.51-1.96-1.61C625.83,159.83,625.8,160.39,626.32,161.18z",
  "M623.07,160.61c0.52,0.79,1.4,1.51,1.96,1.6c0.57,0.1,0.6-0.46,0.08-1.25c-0.52-0.79-1.4-1.51-1.96-1.6C622.59,159.26,622.55,159.82,623.07,160.61z",
  "M619.8,160.04c0.51,0.79,1.39,1.5,1.96,1.6c0.57,0.1,0.61-0.46,0.09-1.25c-0.52-0.79-1.39-1.5-1.96-1.6C619.33,158.7,619.29,159.26,619.8,160.04z",
  "M616.53,159.49c0.51,0.78,1.39,1.5,1.96,1.6c0.57,0.1,0.61-0.46,0.1-1.25c-0.51-0.79-1.39-1.5-1.96-1.6C616.07,158.14,616.02,158.7,616.53,159.49z",
  "M613.25,158.94c0.51,0.78,1.38,1.5,1.95,1.59c0.57,0.1,0.62-0.46,0.11-1.25c-0.51-0.78-1.38-1.5-1.95-1.59C612.79,157.6,612.74,158.15,613.25,158.94z",
  "M609.96,158.39c0.5,0.78,1.38,1.49,1.95,1.59c0.57,0.09,0.62-0.46,0.12-1.25c-0.51-0.78-1.38-1.49-1.95-1.59C609.51,157.05,609.46,157.61,609.96,158.39z",
  "M606.67,157.86c0.5,0.78,1.37,1.49,1.95,1.58c0.57,0.09,0.63-0.47,0.13-1.25c-0.5-0.78-1.37-1.49-1.94-1.58C606.22,156.52,606.16,157.08,606.67,157.86z",
  "M603.36,157.33c0.5,0.78,1.37,1.49,1.94,1.58c0.58,0.09,0.64-0.47,0.14-1.25c-0.5-0.78-1.37-1.49-1.94-1.58C602.92,155.99,602.86,156.55,603.36,157.33z",
  "M600.05,156.8c0.49,0.78,1.36,1.48,1.94,1.58c0.58,0.09,0.64-0.47,0.15-1.25c-0.5-0.78-1.36-1.49-1.94-1.57C599.62,155.47,599.55,156.02,600.05,156.8z",
  "M596.72,156.29c0.49,0.78,1.35,1.48,1.93,1.57c0.58,0.09,0.65-0.47,0.16-1.25c-0.49-0.78-1.36-1.48-1.93-1.57C596.31,154.95,596.24,155.51,596.72,156.29z",
  "M593.39,155.77c0.48,0.78,1.35,1.48,1.93,1.57c0.58,0.09,0.65-0.47,0.17-1.25c-0.49-0.78-1.35-1.48-1.93-1.57C592.98,154.44,592.91,155,593.39,155.77z",
  "M590.06,155.27c0.48,0.78,1.34,1.48,1.92,1.56c0.58,0.09,0.66-0.47,0.18-1.25c-0.48-0.78-1.34-1.48-1.92-1.56C589.66,153.94,589.57,154.5,590.06,155.27z",
  "M586.71,154.77c0.48,0.77,1.33,1.47,1.92,1.56c0.58,0.09,0.67-0.47,0.19-1.25c-0.48-0.77-1.34-1.47-1.91-1.56C586.32,153.44,586.23,154,586.71,154.77z",
  "M583.35,154.28c0.47,0.77,1.33,1.47,1.91,1.55c0.58,0.09,0.67-0.47,0.2-1.25c-0.47-0.77-1.33-1.47-1.91-1.55C582.97,152.95,582.88,153.51,583.35,154.28z",
  "M579.99,153.8c0.47,0.77,1.32,1.47,1.9,1.55c0.59,0.08,0.68-0.47,0.21-1.25c-0.47-0.77-1.32-1.46-1.9-1.55C579.62,152.47,579.53,153.03,579.99,153.8z",
  "M576.62,153.32c0.46,0.77,1.31,1.46,1.9,1.55c0.59,0.08,0.69-0.48,0.22-1.25c-0.46-0.77-1.31-1.46-1.9-1.54C576.26,152,576.16,152.56,576.62,153.32z",
  "M573.25,152.86c0.46,0.77,1.3,1.46,1.89,1.54c0.59,0.08,0.69-0.48,0.23-1.25c-0.46-0.77-1.3-1.46-1.89-1.54C572.9,151.53,572.79,152.09,573.25,152.86z",
  "M569.86,152.39c0.45,0.77,1.29,1.46,1.88,1.54c0.59,0.08,0.7-0.48,0.24-1.25c-0.45-0.77-1.3-1.45-1.88-1.53C569.52,151.07,569.41,151.63,569.86,152.39z",
  "M566.47,151.94c0.44,0.77,1.29,1.45,1.88,1.53c0.59,0.08,0.7-0.48,0.26-1.25c-0.45-0.77-1.29-1.45-1.87-1.53C566.14,150.62,566.03,151.17,566.47,151.94z",
  "M563.07,151.49c0.44,0.76,1.28,1.45,1.87,1.53c0.59,0.08,0.71-0.48,0.27-1.25c-0.44-0.77-1.28-1.45-1.87-1.52C562.75,150.17,562.63,150.73,563.07,151.49z",
  "M559.67,151.05c0.43,0.76,1.27,1.45,1.86,1.52c0.59,0.08,0.72-0.48,0.28-1.25c-0.44-0.76-1.27-1.44-1.86-1.52C559.36,149.73,559.23,150.29,559.67,151.05z",
  "M556.25,150.62c0.43,0.76,1.26,1.44,1.85,1.52c0.59,0.08,0.72-0.48,0.29-1.25c-0.43-0.76-1.26-1.44-1.85-1.52C555.96,149.3,555.83,149.86,556.25,150.62z",
  "M552.83,150.2c0.42,0.76,1.25,1.44,1.84,1.51c0.6,0.07,0.73-0.49,0.31-1.25c-0.43-0.76-1.25-1.44-1.84-1.51C552.55,148.88,552.41,149.43,552.83,150.2z",
  "M549.41,149.78c0.42,0.76,1.24,1.44,1.83,1.51c0.6,0.07,0.74-0.49,0.32-1.25c-0.42-0.76-1.24-1.43-1.83-1.51C549.14,148.46,548.99,149.02,549.41,149.78z",
  "M545.97,149.37c0.41,0.76,1.23,1.43,1.83,1.5c0.6,0.07,0.75-0.49,0.33-1.25c-0.41-0.76-1.23-1.43-1.82-1.5C545.71,148.05,545.56,148.61,545.97,149.37z",
  "M542.54,148.97c0.4,0.76,1.22,1.43,1.82,1.5c0.6,0.07,0.75-0.49,0.34-1.25c-0.41-0.76-1.22-1.43-1.82-1.5C542.29,147.65,542.13,148.21,542.54,148.97z",
  "M539.09,148.57c0.4,0.76,1.21,1.43,1.81,1.49c0.6,0.07,0.76-0.49,0.36-1.25c-0.4-0.76-1.21-1.42-1.81-1.49C538.85,147.26,538.69,147.81,539.09,148.57z",
  "M535.64,148.18c0.39,0.75,1.2,1.42,1.8,1.49c0.6,0.07,0.77-0.49,0.37-1.25c-0.4-0.76-1.2-1.42-1.8-1.49C535.41,146.87,535.25,147.43,535.64,148.18z",
  "M532.18,147.81c0.38,0.75,1.18,1.42,1.79,1.49c0.6,0.07,0.77-0.49,0.39-1.25c-0.39-0.75-1.19-1.42-1.79-1.48C531.97,146.49,531.79,147.05,532.18,147.81z",
  "M528.72,147.43c0.38,0.75,1.17,1.42,1.78,1.48c0.6,0.06,0.78-0.5,0.4-1.25c-0.38-0.75-1.18-1.41-1.78-1.48C528.52,146.12,528.34,146.68,528.72,147.43z",
  "M525.24,147.07c0.37,0.75,1.16,1.41,1.77,1.48c0.6,0.06,0.79-0.5,0.41-1.25c-0.38-0.75-1.17-1.41-1.77-1.47C525.06,145.76,524.87,146.32,525.24,147.07z",
  "M521.77,146.72c0.36,0.75,1.15,1.41,1.76,1.47c0.6,0.06,0.8-0.5,0.43-1.25c-0.37-0.75-1.16-1.41-1.76-1.47C521.6,145.41,521.4,145.96,521.77,146.72z",
  "M518.29,146.37c0.36,0.75,1.14,1.41,1.75,1.47c0.61,0.06,0.8-0.5,0.44-1.25c-0.36-0.75-1.14-1.41-1.74-1.46C518.13,145.06,517.93,145.62,518.29,146.37z",
  "M514.8,146.03c0.35,0.75,1.13,1.4,1.73,1.46c0.61,0.06,0.81-0.5,0.46-1.25c-0.36-0.75-1.13-1.4-1.73-1.46C514.65,144.72,514.45,145.28,514.8,146.03z",
  "M511.31,145.7c0.34,0.75,1.12,1.4,1.72,1.46c0.61,0.06,0.82-0.5,0.47-1.25c-0.35-0.75-1.12-1.4-1.72-1.46C511.18,144.39,510.96,144.95,511.31,145.7z",
  "M507.81,145.37c0.34,0.75,1.1,1.4,1.71,1.45c0.61,0.06,0.83-0.51,0.49-1.25c-0.34-0.75-1.11-1.4-1.71-1.45C507.69,144.07,507.47,144.63,507.81,145.37z",
  "M504.3,145.06c0.33,0.74,1.09,1.39,1.7,1.45c0.61,0.05,0.83-0.51,0.5-1.25c-0.33-0.75-1.09-1.39-1.7-1.45C504.2,143.76,503.98,144.31,504.3,145.06z",
  "M500.8,144.75c0.32,0.74,1.08,1.39,1.69,1.45c0.61,0.05,0.84-0.51,0.52-1.25c-0.33-0.74-1.08-1.39-1.69-1.44C500.71,143.45,500.47,144.01,500.8,144.75z",
  "M497.28,144.45c0.31,0.74,1.06,1.39,1.68,1.44c0.61,0.05,0.85-0.51,0.53-1.25c-0.32-0.74-1.07-1.39-1.68-1.44C497.21,143.15,496.97,143.71,497.28,144.45z",
  "M493.76,144.16c0.31,0.74,1.05,1.39,1.66,1.44c0.61,0.05,0.86-0.51,0.55-1.25c-0.31-0.74-1.06-1.38-1.66-1.43C493.7,142.86,493.46,143.42,493.76,144.16z",
  "M490.24,143.88c0.3,0.74,1.04,1.38,1.65,1.43c0.61,0.05,0.87-0.51,0.56-1.26c-0.3-0.74-1.04-1.38-1.65-1.43C490.19,142.58,489.94,143.14,490.24,143.88z",
  "M486.71,143.61c0.29,0.74,1.02,1.38,1.64,1.43c0.61,0.05,0.87-0.52,0.58-1.26c-0.3-0.74-1.03-1.38-1.64-1.42C486.68,142.31,486.42,142.87,486.71,143.61z",
  "M483.18,143.34c0.28,0.74,1.01,1.38,1.63,1.42c0.62,0.05,0.88-0.52,0.59-1.26c-0.29-0.74-1.02-1.37-1.63-1.42C483.16,142.04,482.89,142.6,483.18,143.34z",
  "M479.64,143.09c0.27,0.74,1,1.37,1.61,1.42c0.62,0.04,0.89-0.52,0.61-1.26c-0.28-0.74-1-1.37-1.61-1.42C479.64,141.79,479.37,142.35,479.64,143.09z",
  "M476.1,142.84c0.27,0.74,0.98,1.37,1.6,1.41c0.62,0.04,0.9-0.52,0.62-1.26c-0.27-0.74-0.99-1.37-1.6-1.41C476.11,141.54,475.83,142.1,476.1,142.84z",
  "M472.55,142.6c0.26,0.74,0.97,1.37,1.59,1.41c0.62,0.04,0.9-0.52,0.64-1.26c-0.26-0.74-0.98-1.37-1.59-1.41C472.58,141.3,472.29,141.86,472.55,142.6z",
  "M469,142.37c0.25,0.74,0.96,1.37,1.57,1.41c0.62,0.04,0.91-0.53,0.66-1.26c-0.26-0.74-0.96-1.36-1.57-1.4C469.05,141.07,468.75,141.63,469,142.37z",
  "M465.45,142.15c0.24,0.73,0.94,1.36,1.56,1.4c0.62,0.04,0.92-0.53,0.67-1.26c-0.25-0.74-0.95-1.36-1.56-1.4C465.51,140.85,465.21,141.41,465.45,142.15z",
  "M461.89,141.93c0.23,0.73,0.93,1.36,1.55,1.4c0.62,0.04,0.93-0.53,0.69-1.26c-0.24-0.73-0.93-1.36-1.55-1.39C461.96,140.63,461.66,141.2,461.89,141.93z",
  "M458.33,141.73c0.23,0.73,0.91,1.36,1.53,1.39c0.62,0.04,0.94-0.53,0.7-1.27c-0.23-0.73-0.92-1.35-1.53-1.39C458.42,140.43,458.1,140.99,458.33,141.73z",
  "M454.76,141.53c0.22,0.73,0.9,1.36,1.52,1.39c0.62,0.03,0.94-0.53,0.72-1.27c-0.22-0.73-0.9-1.35-1.52-1.39C454.87,140.23,454.54,140.8,454.76,141.53z",
  "M451.19,141.34c0.21,0.73,0.88,1.35,1.51,1.39c0.62,0.03,0.95-0.54,0.74-1.27c-0.22-0.73-0.89-1.35-1.5-1.38C451.31,140.05,450.98,140.61,451.19,141.34z",
  "M447.62,141.16c0.2,0.73,0.87,1.35,1.49,1.38c0.62,0.03,0.96-0.54,0.75-1.27c-0.21-0.73-0.87-1.35-1.49-1.38C447.76,139.87,447.42,140.43,447.62,141.16z",
  "M444.04,140.99c0.19,0.73,0.85,1.35,1.48,1.38c0.62,0.03,0.97-0.54,0.77-1.27c-0.2-0.73-0.86-1.34-1.48-1.37C444.2,139.7,443.85,140.26,444.04,140.99z",
  "M440.47,140.83c0.18,0.73,0.84,1.35,1.46,1.37c0.62,0.03,0.98-0.54,0.79-1.27c-0.19-0.73-0.84-1.34-1.46-1.37C440.63,139.53,440.28,140.1,440.47,140.83z",
  "M436.88,140.68c0.17,0.73,0.82,1.34,1.45,1.37c0.62,0.03,0.98-0.55,0.8-1.28c-0.18-0.73-0.83-1.34-1.45-1.37C437.07,139.38,436.71,139.95,436.88,140.68z",
  "M433.3,140.54c0.17,0.73,0.81,1.34,1.43,1.37c0.62,0.02,0.99-0.55,0.82-1.28c-0.17-0.73-0.81-1.34-1.43-1.36C433.5,139.24,433.13,139.81,433.3,140.54z",
  "M429.71,140.4c0.16,0.73,0.79,1.34,1.42,1.36c0.63,0.02,1-0.55,0.84-1.28c-0.16-0.73-0.8-1.34-1.42-1.36C429.93,139.1,429.55,139.67,429.71,140.4z",
  "M426.12,140.27c0.15,0.73,0.78,1.34,1.4,1.36c0.63,0.02,1.01-0.55,0.85-1.28c-0.15-0.73-0.78-1.33-1.4-1.35C426.35,138.98,425.97,139.55,426.12,140.27z",
  "M422.53,140.16c0.14,0.73,0.76,1.33,1.39,1.36c0.63,0.02,1.02-0.56,0.87-1.28c-0.15-0.73-0.77-1.33-1.39-1.35C422.78,138.86,422.39,139.43,422.53,140.16z",
  "M418.93,140.05c0.13,0.73,0.75,1.33,1.37,1.35c0.63,0.02,1.02-0.56,0.89-1.28c-0.14-0.73-0.75-1.33-1.37-1.35C419.2,138.75,418.8,139.32,418.93,140.05z",
  "M415.33,139.95c0.12,0.73,0.73,1.33,1.36,1.35c0.63,0.02,1.03-0.56,0.9-1.29c-0.13-0.73-0.74-1.33-1.36-1.34C415.61,138.65,415.21,139.22,415.33,139.95z",
  "M411.73,139.86c0.11,0.73,0.71,1.33,1.34,1.34c0.63,0.02,1.04-0.56,0.92-1.29c-0.12-0.73-0.72-1.32-1.34-1.34C412.03,138.56,411.62,139.13,411.73,139.86z",
  "M408.13,139.78c0.1,0.73,0.7,1.33,1.33,1.34c0.63,0.01,1.05-0.57,0.94-1.29c-0.11-0.73-0.71-1.32-1.33-1.34C408.44,138.48,408.02,139.05,408.13,139.78z",
  "M404.52,139.71c0.1,0.73,0.68,1.33,1.31,1.34c0.63,0.01,1.05-0.57,0.95-1.29c-0.1-0.73-0.69-1.32-1.31-1.33C404.86,138.41,404.43,138.98,404.52,139.71z",
  "M400.92,139.64c0.09,0.73,0.67,1.32,1.3,1.33c0.63,0.01,1.06-0.57,0.97-1.3c-0.09-0.73-0.67-1.32-1.3-1.33C401.27,138.34,400.83,138.92,400.92,139.64z",
  "M397.31,139.59c0.08,0.72,0.65,1.32,1.28,1.33c0.63,0.01,1.07-0.57,0.99-1.3c-0.08-0.73-0.66-1.32-1.28-1.33C397.67,138.29,397.23,138.87,397.31,139.59z",
  "M393.7,139.55c0.07,0.72,0.64,1.32,1.26,1.33c0.63,0.01,1.08-0.58,1-1.3c-0.08-0.72-0.64-1.32-1.26-1.32C394.08,138.24,393.63,138.82,393.7,139.55z",
  "M390.09,139.51c0.06,0.72,0.62,1.32,1.25,1.32c0.63,0.01,1.09-0.58,1.02-1.3c-0.07-0.72-0.62-1.31-1.25-1.32C390.49,138.2,390.03,138.79,390.09,139.51z",
  "M386.48,139.48c0.05,0.72,0.6,1.32,1.23,1.32c0.63,0,1.09-0.58,1.04-1.31c-0.06-0.72-0.61-1.31-1.23-1.32C386.89,138.18,386.43,138.76,386.48,139.48z",
  "M382.86,139.47c0.04,0.72,0.59,1.32,1.22,1.32c0.63,0,1.1-0.58,1.05-1.31c-0.05-0.72-0.59-1.31-1.22-1.31C383.29,138.16,382.82,138.74,382.86,139.47z",
  "M379.25,139.46c0.03,0.72,0.57,1.31,1.2,1.32c0.63,0,1.11-0.59,1.07-1.31c-0.04-0.72-0.58-1.31-1.2-1.31C379.69,138.15,379.21,138.73,379.25,139.46z",
  "M375.63,139.46c0.02,0.72,0.55,1.31,1.18,1.31c0.63,0,1.12-0.59,1.09-1.31c-0.03-0.72-0.56-1.31-1.18-1.31C376.09,138.15,375.61,138.73,375.63,139.46z",
  "M372.01,139.47c0.01,0.72,0.54,1.31,1.17,1.31c0.63,0,1.12-0.59,1.1-1.32c-0.02-0.72-0.54-1.31-1.17-1.3C372.49,138.16,372,138.74,372.01,139.47z",
  "M368.4,139.49c0.01,0.72,0.52,1.31,1.15,1.31c0.63,0,1.13-0.6,1.12-1.32c-0.01-0.72-0.53-1.31-1.15-1.3C368.89,138.17,368.39,138.76,368.4,139.49z",
  "M364.78,139.51c0,0.72,0.51,1.31,1.14,1.3c0.63,0,1.14-0.6,1.14-1.32c0-0.72-0.51-1.3-1.14-1.3C365.29,138.2,364.78,138.79,364.78,139.51z",
  "M346.68,139.79c-0.05,0.72,0.42,1.3,1.06,1.29c0.63-0.01,1.18-0.61,1.22-1.34c0.04-0.72-0.43-1.3-1.06-1.29C347.27,138.47,346.73,139.07,346.68,139.79z",
  "M278.08,142.91c-0.21,0.73,0.12,1.3,0.75,1.25c0.63-0.04,1.3-0.68,1.51-1.41c0.21-0.73-0.13-1.29-0.75-1.25C278.97,141.55,278.29,142.18,278.08,142.91z",
  "M274.49,143.17c-0.22,0.74,0.1,1.3,0.73,1.25c0.63-0.04,1.31-0.68,1.53-1.41c0.22-0.74-0.11-1.29-0.73-1.25C275.39,141.8,274.71,142.43,274.49,143.17z",
  "M270.9,143.43c-0.23,0.74,0.09,1.3,0.71,1.25c0.63-0.05,1.31-0.68,1.54-1.42c0.22-0.74-0.1-1.29-0.71-1.25C271.82,142.06,271.13,142.69,270.9,143.43z",
  "M242.29,145.85c-0.29,0.74-0.03,1.3,0.59,1.24c0.62-0.06,1.36-0.71,1.65-1.45c0.29-0.74,0.02-1.3-0.59-1.24C243.32,144.46,242.58,145.1,242.29,145.85z",
  "M238.73,146.19c-0.3,0.74-0.04,1.3,0.58,1.24c0.62-0.06,1.36-0.71,1.66-1.46c0.3-0.74,0.04-1.3-0.58-1.24C239.78,144.79,239.03,145.44,238.73,146.19z",
  "M235.18,146.54c-0.31,0.75-0.06,1.3,0.56,1.24c0.62-0.06,1.37-0.72,1.67-1.46c0.3-0.75,0.05-1.3-0.56-1.24C236.23,145.14,235.49,145.79,235.18,146.54z",
  "M231.62,146.9c-0.32,0.75-0.07,1.3,0.55,1.24c0.62-0.06,1.37-0.72,1.68-1.47c0.31-0.75,0.07-1.3-0.55-1.24C232.7,145.49,231.94,146.15,231.62,146.9z",
  "M228.08,147.26c-0.32,0.75-0.09,1.3,0.53,1.24c0.62-0.06,1.38-0.72,1.7-1.47c0.32-0.75,0.08-1.3-0.53-1.24C229.16,145.86,228.4,146.51,228.08,147.26z",
  "M224.54,147.64c-0.33,0.75-0.1,1.31,0.52,1.24c0.62-0.07,1.38-0.73,1.71-1.48c0.33-0.75,0.1-1.3-0.52-1.24C225.63,146.23,224.87,146.89,224.54,147.64z",
  "M213.94,148.81c-0.35,0.75-0.14,1.31,0.47,1.24c0.61-0.07,1.39-0.74,1.74-1.49c0.35-0.75,0.14-1.3-0.47-1.23C215.07,147.39,214.29,148.06,213.94,148.81z",
  "M210.41,149.22c-0.36,0.75-0.16,1.31,0.46,1.24c0.61-0.07,1.4-0.74,1.75-1.49c0.36-0.75,0.15-1.3-0.46-1.23C211.56,147.8,210.77,148.46,210.41,149.22z",
  "M206.9,149.63c-0.37,0.75-0.17,1.31,0.44,1.24c0.61-0.07,1.4-0.74,1.76-1.5c0.36-0.75,0.16-1.31-0.44-1.23C208.05,148.21,207.26,148.88,206.9,149.63z",
  "M203.38,150.06c-0.37,0.76-0.18,1.31,0.43,1.24c0.61-0.07,1.41-0.75,1.78-1.5c0.37-0.76,0.18-1.31-0.43-1.23C204.55,148.63,203.76,149.3,203.38,150.06z",
  "M199.88,150.49c-0.38,0.76-0.19,1.31,0.42,1.24c0.61-0.08,1.41-0.75,1.79-1.51c0.38-0.76,0.19-1.31-0.42-1.23C201.06,149.06,200.26,149.73,199.88,150.49z",
  "M196.37,150.93c-0.39,0.76-0.21,1.31,0.4,1.24c0.61-0.08,1.41-0.76,1.8-1.51c0.38-0.76,0.2-1.31-0.4-1.23C197.57,149.5,196.76,150.17,196.37,150.93z",
  "M192.88,151.38c-0.39,0.76-0.22,1.31,0.39,1.23c0.61-0.08,1.42-0.76,1.81-1.52c0.39-0.76,0.21-1.31-0.39-1.23C194.08,149.94,193.27,150.62,192.88,151.38z",
  "M189.39,151.83c-0.4,0.76-0.23,1.31,0.38,1.23c0.61-0.08,1.42-0.76,1.82-1.52c0.4-0.76,0.23-1.31-0.38-1.23C190.6,150.39,189.79,151.07,189.39,151.83z",
  "M185.91,152.3c-0.41,0.76-0.24,1.32,0.36,1.23c0.61-0.08,1.42-0.77,1.82-1.53c0.4-0.76,0.24-1.31-0.36-1.23C187.13,150.85,186.31,151.54,185.91,152.3z",
  "M182.43,152.77c-0.41,0.76-0.26,1.32,0.35,1.23c0.61-0.08,1.43-0.77,1.83-1.53c0.41-0.76,0.25-1.31-0.35-1.23C183.66,151.32,182.84,152.01,182.43,152.77z",
  "M178.96,153.25c-0.42,0.76-0.27,1.32,0.34,1.23c0.6-0.08,1.43-0.77,1.84-1.54c0.41-0.76,0.26-1.31-0.34-1.23C180.2,151.8,179.37,152.48,178.96,153.25z",
  "M175.49,153.74c-0.42,0.77-0.28,1.32,0.32,1.23c0.6-0.09,1.43-0.77,1.85-1.54c0.42-0.76,0.27-1.31-0.33-1.23C176.74,152.28,175.92,152.97,175.49,153.74z",
  "M172.03,154.23c-0.43,0.77-0.29,1.32,0.31,1.23c0.6-0.09,1.43-0.78,1.86-1.54c0.43-0.77,0.28-1.32-0.31-1.23C173.29,152.77,172.46,153.46,172.03,154.23z",
  "M168.58,154.73c-0.43,0.77-0.3,1.32,0.3,1.23c0.6-0.09,1.44-0.78,1.87-1.55c0.43-0.77,0.3-1.32-0.3-1.23C169.85,153.27,169.02,153.96,168.58,154.73z",
  "M165.14,155.24c-0.44,0.77-0.31,1.32,0.29,1.23c0.6-0.09,1.44-0.79,1.88-1.55c0.44-0.77,0.31-1.32-0.29-1.23C166.42,153.78,165.58,154.47,165.14,155.24z",
  "M161.7,155.76c-0.45,0.77-0.32,1.32,0.28,1.23c0.6-0.09,1.44-0.79,1.88-1.56c0.44-0.77,0.32-1.32-0.28-1.23C162.99,154.29,162.14,154.99,161.7,155.76z",
  "M158.27,156.28c-0.45,0.77-0.33,1.32,0.26,1.23c0.6-0.09,1.44-0.79,1.89-1.56c0.45-0.77,0.33-1.32-0.26-1.23C159.56,154.81,158.72,155.51,158.27,156.28z",
  "M148.02,157.9c-0.47,0.77-0.36,1.33,0.23,1.23c0.59-0.1,1.45-0.8,1.91-1.58c0.46-0.77,0.36-1.32-0.23-1.23C149.34,156.42,148.49,157.12,148.02,157.9z",
  "M144.62,158.45c-0.47,0.78-0.37,1.33,0.22,1.23c0.59-0.1,1.45-0.8,1.92-1.58c0.47-0.78,0.37-1.33-0.22-1.23C145.95,156.97,145.09,157.68,144.62,158.45z",
  "M636.24,159.03c0.53,0.79,1.41,1.52,1.97,1.62c0.56,0.1,0.57-0.46,0.04-1.25c-0.53-0.79-1.41-1.52-1.97-1.62C635.73,157.68,635.71,158.24,636.24,159.03z",
  "M626.61,157.29c0.52,0.79,1.4,1.51,1.96,1.61c0.56,0.1,0.59-0.46,0.06-1.25c-0.52-0.79-1.4-1.51-1.96-1.61C626.11,155.94,626.08,156.5,626.61,157.29z",
  "M620.14,156.16c0.52,0.79,1.39,1.5,1.96,1.6c0.56,0.1,0.6-0.46,0.08-1.25c-0.52-0.79-1.39-1.5-1.96-1.6C619.66,154.82,619.62,155.37,620.14,156.16z",
  "M616.89,155.6c0.51,0.78,1.39,1.5,1.95,1.59c0.56,0.1,0.6-0.46,0.09-1.25c-0.52-0.79-1.39-1.5-1.95-1.59C616.42,154.26,616.38,154.82,616.89,155.6z",
  "M613.64,155.06c0.51,0.78,1.38,1.49,1.95,1.59c0.57,0.1,0.61-0.46,0.09-1.25c-0.51-0.78-1.39-1.5-1.95-1.59C613.17,153.72,613.13,154.27,613.64,155.06z",
  "M610.38,154.51c0.51,0.78,1.38,1.49,1.95,1.59c0.57,0.09,0.61-0.46,0.1-1.25c-0.51-0.78-1.38-1.49-1.95-1.58C609.92,153.18,609.87,153.73,610.38,154.51z",
  "M607.11,153.98c0.5,0.78,1.37,1.49,1.94,1.58c0.57,0.09,0.62-0.46,0.11-1.25c-0.51-0.78-1.38-1.49-1.94-1.58C606.66,152.64,606.61,153.2,607.11,153.98z",
  "M603.84,153.45c0.5,0.78,1.37,1.48,1.94,1.58c0.57,0.09,0.62-0.47,0.12-1.24c-0.5-0.78-1.37-1.48-1.94-1.58C603.39,152.11,603.33,152.67,603.84,153.45z",
  "M600.55,152.92c0.5,0.78,1.36,1.48,1.93,1.57c0.57,0.09,0.63-0.47,0.13-1.24c-0.5-0.78-1.37-1.48-1.93-1.57C600.11,151.59,600.06,152.15,600.55,152.92z",
  "M597.26,152.41c0.49,0.78,1.36,1.48,1.93,1.57c0.57,0.09,0.63-0.47,0.14-1.24c-0.49-0.78-1.36-1.48-1.93-1.57C596.83,151.08,596.77,151.63,597.26,152.41z",
  "M593.96,151.9c0.49,0.77,1.35,1.47,1.92,1.56c0.57,0.09,0.64-0.47,0.15-1.24c-0.49-0.78-1.35-1.47-1.92-1.56C593.54,150.57,593.47,151.12,593.96,151.9z",
  "M590.66,151.4c0.48,0.77,1.34,1.47,1.92,1.56c0.58,0.09,0.65-0.47,0.16-1.24c-0.49-0.77-1.34-1.47-1.92-1.56C590.24,150.07,590.17,150.62,590.66,151.4z",
  "M587.34,150.9c0.48,0.77,1.34,1.47,1.91,1.55c0.58,0.09,0.65-0.47,0.17-1.24c-0.48-0.77-1.34-1.47-1.91-1.55S586.86,150.13,587.34,150.9z",
  "M584.02,150.41c0.47,0.77,1.33,1.46,1.91,1.55c0.58,0.09,0.66-0.47,0.18-1.24c-0.48-0.77-1.33-1.46-1.91-1.55C583.63,149.09,583.55,149.64,584.02,150.41z",
  "M580.69,149.93c0.47,0.77,1.32,1.46,1.9,1.54c0.58,0.08,0.66-0.47,0.19-1.24c-0.47-0.77-1.32-1.46-1.9-1.54C580.31,148.6,580.22,149.16,580.69,149.93z",
  "M577.36,149.45c0.47,0.77,1.31,1.46,1.89,1.54c0.58,0.08,0.67-0.47,0.2-1.24c-0.47-0.77-1.32-1.46-1.89-1.54C576.99,148.13,576.89,148.69,577.36,149.45z",
  "M574.02,148.98c0.46,0.77,1.3,1.45,1.89,1.53c0.58,0.08,0.68-0.48,0.21-1.24c-0.46-0.77-1.31-1.45-1.89-1.53C573.65,147.66,573.56,148.22,574.02,148.98z",
  "M570.67,148.52c0.45,0.76,1.3,1.45,1.88,1.53c0.58,0.08,0.68-0.48,0.22-1.24c-0.46-0.77-1.3-1.45-1.88-1.53C570.32,147.21,570.22,147.76,570.67,148.52z",
  "M567.32,148.07c0.45,0.76,1.29,1.45,1.87,1.52c0.58,0.08,0.69-0.48,0.24-1.24c-0.45-0.76-1.29-1.44-1.87-1.52C566.97,146.75,566.87,147.31,567.32,148.07z",
  "M563.96,147.62c0.44,0.76,1.28,1.44,1.86,1.52c0.59,0.08,0.7-0.48,0.25-1.24c-0.45-0.76-1.28-1.44-1.86-1.52C563.62,146.31,563.51,146.86,563.96,147.62z",
  "M560.59,147.18c0.44,0.76,1.27,1.44,1.86,1.52c0.59,0.08,0.7-0.48,0.26-1.24c-0.44-0.76-1.27-1.44-1.86-1.51C560.27,145.87,560.15,146.42,560.59,147.18z",
  "M557.21,146.75c0.43,0.76,1.26,1.44,1.85,1.51c0.59,0.08,0.71-0.48,0.27-1.24c-0.44-0.76-1.26-1.43-1.85-1.51C556.9,145.44,556.78,145.99,557.21,146.75z",
  "M553.83,146.33c0.43,0.76,1.25,1.43,1.84,1.51c0.59,0.07,0.71-0.48,0.28-1.24c-0.43-0.76-1.26-1.43-1.84-1.5C553.53,145.02,553.41,145.57,553.83,146.33z",
  "M550.45,145.91c0.42,0.76,1.24,1.43,1.83,1.5c0.59,0.07,0.72-0.48,0.3-1.24c-0.43-0.76-1.25-1.43-1.83-1.5C550.16,144.6,550.03,145.15,550.45,145.91z",
  "M547.06,145.5c0.41,0.75,1.23,1.42,1.82,1.5c0.59,0.07,0.73-0.48,0.31-1.24c-0.42-0.76-1.23-1.42-1.82-1.49C546.78,144.19,546.64,144.74,547.06,145.5z",
  "M543.66,145.1c0.41,0.75,1.22,1.42,1.81,1.49c0.59,0.07,0.74-0.49,0.32-1.24c-0.41-0.75-1.23-1.42-1.81-1.49C543.39,143.79,543.25,144.34,543.66,145.1z",
  "M540.25,144.7c0.4,0.75,1.21,1.42,1.8,1.49c0.59,0.07,0.74-0.49,0.33-1.24c-0.41-0.75-1.21-1.42-1.8-1.48C540,143.4,539.85,143.95,540.25,144.7z",
  "M536.84,144.31c0.4,0.75,1.2,1.41,1.79,1.48c0.59,0.07,0.75-0.49,0.35-1.24c-0.4-0.75-1.2-1.41-1.79-1.48C536.6,143.01,536.45,143.56,536.84,144.31z",
  "M533.43,143.93c0.39,0.75,1.19,1.41,1.78,1.48c0.6,0.07,0.76-0.49,0.36-1.24c-0.4-0.75-1.19-1.41-1.78-1.47C533.2,142.63,533.04,143.18,533.43,143.93z",
  "M530.01,143.56c0.38,0.75,1.18,1.41,1.77,1.47c0.6,0.06,0.76-0.49,0.38-1.24c-0.39-0.75-1.18-1.41-1.77-1.47C529.79,142.26,529.62,142.81,530.01,143.56z",
  "M526.58,143.2c0.38,0.75,1.17,1.4,1.76,1.47c0.6,0.06,0.77-0.49,0.39-1.24c-0.38-0.75-1.17-1.4-1.76-1.46C526.38,141.9,526.2,142.45,526.58,143.2z",
  "M523.15,142.84c0.37,0.74,1.16,1.4,1.75,1.46c0.6,0.06,0.78-0.49,0.4-1.24c-0.38-0.75-1.16-1.4-1.75-1.46C522.96,141.54,522.78,142.1,523.15,142.84z",
  "M519.71,142.49c0.36,0.74,1.14,1.4,1.74,1.46c0.6,0.06,0.79-0.5,0.42-1.24c-0.37-0.74-1.15-1.39-1.74-1.45C519.53,141.2,519.35,141.75,519.71,142.49z",
  "M516.27,142.15c0.36,0.74,1.13,1.39,1.73,1.45c0.6,0.06,0.79-0.5,0.43-1.24c-0.36-0.74-1.14-1.39-1.73-1.45C516.11,140.86,515.91,141.41,516.27,142.15z",
  "M512.82,141.82c0.35,0.74,1.12,1.39,1.72,1.45c0.6,0.06,0.8-0.5,0.45-1.24c-0.35-0.74-1.12-1.39-1.72-1.44C512.67,140.53,512.47,141.08,512.82,141.82z",
  "M509.37,141.49c0.34,0.74,1.11,1.39,1.71,1.44c0.6,0.06,0.81-0.5,0.46-1.24c-0.35-0.74-1.11-1.38-1.71-1.44C509.23,140.2,509.02,140.75,509.37,141.49z",
  "M505.91,141.18c0.33,0.74,1.1,1.38,1.7,1.44c0.6,0.05,0.81-0.5,0.47-1.24c-0.34-0.74-1.1-1.38-1.7-1.44C505.79,139.89,505.58,140.44,505.91,141.18z",
  "M502.45,140.87c0.33,0.74,1.08,1.38,1.69,1.43c0.6,0.05,0.82-0.5,0.49-1.24c-0.33-0.74-1.09-1.38-1.69-1.43C502.34,139.58,502.12,140.13,502.45,140.87z",
  "M498.98,140.57c0.32,0.74,1.07,1.38,1.67,1.43c0.6,0.05,0.83-0.5,0.5-1.24c-0.33-0.74-1.07-1.37-1.67-1.43C498.89,139.28,498.66,139.83,498.98,140.57z",
  "M495.51,140.28c0.31,0.74,1.06,1.37,1.66,1.42c0.61,0.05,0.84-0.51,0.52-1.24c-0.32-0.74-1.06-1.37-1.66-1.42C495.43,138.99,495.2,139.54,495.51,140.28z",
  "M492.03,139.99c0.31,0.73,1.04,1.37,1.65,1.42c0.61,0.05,0.85-0.51,0.53-1.24c-0.31-0.73-1.05-1.37-1.65-1.42C491.97,138.7,491.73,139.26,492.03,139.99z",
  "M488.55,139.72c0.3,0.73,1.03,1.37,1.64,1.42c0.61,0.05,0.85-0.51,0.55-1.24c-0.3-0.73-1.04-1.37-1.64-1.41C488.5,138.43,488.26,138.98,488.55,139.72z",
  "M485.07,139.45c0.29,0.73,1.02,1.37,1.62,1.41c0.61,0.05,0.86-0.51,0.56-1.24c-0.3-0.73-1.02-1.36-1.62-1.41C485.03,138.16,484.78,138.72,485.07,139.45z",
  "M481.58,139.19c0.28,0.73,1,1.36,1.61,1.41c0.61,0.05,0.87-0.51,0.58-1.24c-0.29-0.73-1.01-1.36-1.61-1.4C481.56,137.9,481.3,138.46,481.58,139.19z",
  "M478.09,138.94c0.27,0.73,0.99,1.36,1.6,1.4c0.61,0.04,0.88-0.52,0.6-1.25c-0.28-0.73-0.99-1.36-1.6-1.4C478.08,137.65,477.81,138.21,478.09,138.94z",
  "M474.59,138.69c0.27,0.73,0.98,1.36,1.59,1.4c0.61,0.04,0.88-0.52,0.61-1.25c-0.27-0.73-0.98-1.35-1.58-1.39C474.6,137.41,474.32,137.97,474.59,138.69z",
  "M471.09,138.46c0.26,0.73,0.96,1.35,1.57,1.39c0.61,0.04,0.89-0.52,0.63-1.25c-0.26-0.73-0.97-1.35-1.57-1.39C471.11,137.18,470.83,137.73,471.09,138.46z",
  "M467.59,138.23c0.25,0.73,0.95,1.35,1.56,1.39c0.61,0.04,0.9-0.52,0.64-1.25c-0.26-0.73-0.95-1.35-1.56-1.39C467.62,136.95,467.34,137.51,467.59,138.23z",
  "M464.08,138.02c0.24,0.73,0.93,1.35,1.55,1.39c0.61,0.04,0.91-0.52,0.66-1.25c-0.25-0.73-0.94-1.34-1.54-1.38C464.13,136.73,463.84,137.29,464.08,138.02z",
  "M460.57,137.81c0.23,0.73,0.92,1.34,1.53,1.38c0.61,0.04,0.91-0.52,0.67-1.25c-0.24-0.73-0.93-1.34-1.53-1.38C460.63,136.52,460.33,137.08,460.57,137.81z",
  "M457.05,137.61c0.23,0.72,0.9,1.34,1.52,1.38c0.61,0.03,0.92-0.53,0.69-1.25c-0.23-0.72-0.91-1.34-1.52-1.37C457.13,136.32,456.82,136.88,457.05,137.61z",
  "M453.53,137.41c0.22,0.72,0.89,1.34,1.5,1.37c0.61,0.03,0.93-0.53,0.71-1.25c-0.22-0.72-0.9-1.34-1.5-1.37C453.63,136.13,453.32,136.69,453.53,137.41z",
  "M450.01,137.23c0.21,0.72,0.88,1.34,1.49,1.37c0.61,0.03,0.94-0.53,0.72-1.25c-0.22-0.72-0.88-1.33-1.49-1.36C450.13,135.95,449.8,136.51,450.01,137.23z",
  "M446.49,137.06c0.2,0.72,0.86,1.33,1.48,1.36c0.61,0.03,0.94-0.53,0.74-1.26c-0.21-0.72-0.87-1.33-1.47-1.36C446.62,135.77,446.29,136.33,446.49,137.06z",
  "M442.96,136.89c0.19,0.72,0.85,1.33,1.46,1.36c0.62,0.03,0.95-0.54,0.76-1.26c-0.2-0.72-0.85-1.33-1.46-1.36C443.1,135.61,442.77,136.17,442.96,136.89z",
  "M439.43,136.73c0.18,0.72,0.83,1.33,1.45,1.36c0.62,0.03,0.96-0.54,0.77-1.26c-0.19-0.72-0.84-1.33-1.45-1.35C439.59,135.45,439.24,136.01,439.43,136.73z",
  "M435.89,136.58c0.17,0.72,0.82,1.33,1.43,1.35c0.62,0.03,0.97-0.54,0.79-1.26c-0.18-0.72-0.82-1.32-1.43-1.35C436.07,135.3,435.72,135.86,435.89,136.58z",
  "M432.36,136.44c0.17,0.72,0.8,1.32,1.42,1.35c0.62,0.02,0.98-0.54,0.8-1.26c-0.17-0.72-0.81-1.32-1.42-1.34C432.55,135.16,432.19,135.72,432.36,136.44z",
  "M428.82,136.31c0.16,0.72,0.79,1.32,1.4,1.35c0.62,0.02,0.98-0.54,0.82-1.26c-0.16-0.72-0.79-1.32-1.4-1.34C429.03,135.03,428.66,135.59,428.82,136.31z",
  "M425.28,136.19c0.15,0.72,0.77,1.32,1.39,1.34c0.62,0.02,0.99-0.55,0.84-1.27c-0.15-0.72-0.78-1.32-1.39-1.34C425.5,134.91,425.13,135.47,425.28,136.19z",
  "M421.73,136.07c0.14,0.72,0.75,1.32,1.37,1.34c0.62,0.02,1-0.55,0.85-1.27c-0.15-0.72-0.76-1.31-1.37-1.33C421.98,134.79,421.59,135.36,421.73,136.07z",
  "M418.19,135.97c0.13,0.72,0.74,1.32,1.36,1.33c0.62,0.02,1.01-0.55,0.87-1.27c-0.14-0.72-0.74-1.31-1.36-1.33C418.45,134.69,418.06,135.25,418.19,135.97z",
  "M414.64,135.87c0.12,0.72,0.72,1.31,1.34,1.33c0.62,0.02,1.02-0.55,0.89-1.27c-0.13-0.72-0.73-1.31-1.34-1.33C414.91,134.59,414.52,135.15,414.64,135.87z",
  "M411.09,135.78c0.11,0.72,0.71,1.31,1.33,1.33c0.62,0.02,1.02-0.56,0.9-1.27c-0.12-0.72-0.71-1.31-1.33-1.32C411.38,134.5,410.97,135.07,411.09,135.78z",
  "M407.53,135.7c0.1,0.72,0.69,1.31,1.31,1.32c0.62,0.01,1.03-0.56,0.92-1.28c-0.11-0.72-0.7-1.31-1.31-1.32C407.84,134.42,407.43,134.99,407.53,135.7z",
  "M403.98,135.63c0.1,0.72,0.68,1.31,1.3,1.32c0.62,0.01,1.04-0.56,0.94-1.28c-0.1-0.72-0.68-1.3-1.29-1.32C404.31,134.35,403.88,134.92,403.98,135.63z",
  "M400.42,135.57c0.09,0.72,0.66,1.31,1.28,1.32c0.62,0.01,1.05-0.56,0.95-1.28c-0.09-0.72-0.67-1.3-1.28-1.31C400.77,134.29,400.34,134.86,400.42,135.57z",
  "M396.87,135.52c0.08,0.72,0.64,1.3,1.26,1.31c0.62,0.01,1.05-0.57,0.97-1.28c-0.08-0.72-0.65-1.3-1.26-1.31C397.22,134.23,396.79,134.8,396.87,135.52z",
  "M393.31,135.48c0.07,0.72,0.63,1.3,1.25,1.31c0.62,0.01,1.06-0.57,0.99-1.28c-0.08-0.72-0.63-1.3-1.25-1.31C393.68,134.19,393.24,134.76,393.31,135.48z",
  "M389.75,135.44c0.06,0.71,0.61,1.3,1.23,1.31c0.62,0.01,1.07-0.57,1-1.29c-0.07-0.72-0.62-1.3-1.23-1.3C390.14,134.15,389.69,134.73,389.75,135.44z",
  "M386.19,135.42c0.05,0.71,0.6,1.3,1.22,1.3c0.62,0,1.08-0.57,1.02-1.29c-0.06-0.71-0.6-1.3-1.22-1.3C386.59,134.13,386.14,134.7,386.19,135.42z",
  "M382.62,135.4c0.04,0.71,0.58,1.3,1.2,1.3c0.62,0,1.09-0.58,1.04-1.29c-0.05-0.71-0.59-1.29-1.2-1.3C383.05,134.11,382.58,134.68,382.62,135.4z",
  "M379.06,135.39c0.03,0.71,0.56,1.3,1.18,1.3c0.62,0,1.09-0.58,1.05-1.29c-0.04-0.71-0.57-1.29-1.18-1.29C379.5,134.1,379.03,134.68,379.06,135.39z",
  "M375.49,135.39c0.02,0.71,0.55,1.3,1.17,1.3c0.62,0,1.1-0.58,1.07-1.3c-0.03-0.71-0.55-1.29-1.17-1.29C375.95,134.1,375.47,134.68,375.49,135.39z",
  "M371.93,135.4c0.02,0.71,0.53,1.29,1.15,1.29c0.62,0,1.11-0.58,1.09-1.3c-0.02-0.71-0.54-1.29-1.15-1.29C372.4,134.11,371.91,134.69,371.93,135.4z",
  "M368.36,135.42c0.01,0.71,0.51,1.29,1.14,1.29c0.62,0,1.12-0.59,1.1-1.3c-0.01-0.71-0.52-1.29-1.14-1.28C368.85,134.13,368.36,134.71,368.36,135.42z",
  "M364.79,135.45c0,0.71,0.5,1.29,1.12,1.29c0.62,0,1.12-0.59,1.12-1.31c0-0.71-0.51-1.29-1.12-1.28C365.3,134.15,364.8,134.73,364.79,135.45z",
  "M361.23,135.48c-0.01,0.71,0.48,1.29,1.1,1.28c0.62-0.01,1.13-0.59,1.14-1.31c0.01-0.71-0.49-1.29-1.1-1.28C361.75,134.19,361.24,134.77,361.23,135.48z",
  "M318.41,136.62c-0.12,0.72,0.29,1.28,0.91,1.26c0.62-0.03,1.22-0.63,1.33-1.35c0.11-0.72-0.29-1.28-0.91-1.25C319.13,135.3,318.53,135.9,318.41,136.62z",
  "M314.85,136.77c-0.13,0.72,0.27,1.28,0.89,1.26c0.62-0.03,1.22-0.63,1.35-1.35c0.12-0.72-0.28-1.28-0.89-1.25C315.58,135.45,314.98,136.05,314.85,136.77z",
  "M282.83,138.54c-0.21,0.73,0.13,1.28,0.75,1.24c0.62-0.04,1.28-0.66,1.48-1.39c0.2-0.73-0.14-1.28-0.75-1.24C283.7,137.2,283.04,137.82,282.83,138.54z",
  "M279.29,138.78c-0.21,0.73,0.11,1.28,0.73,1.24c0.62-0.04,1.29-0.67,1.5-1.39c0.21-0.73-0.12-1.28-0.73-1.24C280.17,137.44,279.5,138.06,279.29,138.78z",
  "M275.74,139.03c-0.22,0.73,0.1,1.28,0.72,1.24c0.62-0.04,1.29-0.67,1.51-1.4c0.22-0.73-0.1-1.28-0.72-1.24C276.64,137.68,275.96,138.31,275.74,139.03z",
  "M272.2,139.29c-0.23,0.73,0.08,1.28,0.7,1.24c0.62-0.05,1.3-0.67,1.53-1.4c0.22-0.73-0.09-1.28-0.7-1.23C273.11,137.94,272.43,138.56,272.2,139.29z",
  "M268.66,139.56c-0.24,0.73,0.07,1.28,0.68,1.24c0.62-0.05,1.31-0.68,1.54-1.41c0.23-0.73-0.07-1.28-0.68-1.23C269.58,138.2,268.9,138.83,268.66,139.56z",
  "M265.12,139.83c-0.25,0.73,0.05,1.28,0.67,1.24c0.62-0.05,1.31-0.68,1.55-1.41c0.24-0.73-0.06-1.28-0.67-1.23C266.06,138.47,265.37,139.1,265.12,139.83z",
  "M243.95,141.66c-0.29,0.74-0.04,1.29,0.58,1.23c0.61-0.06,1.34-0.7,1.63-1.44c0.29-0.74,0.03-1.28-0.58-1.23C244.98,140.29,244.25,140.93,243.95,141.66z",
  "M240.44,142c-0.3,0.74-0.05,1.29,0.56,1.23c0.61-0.06,1.35-0.71,1.65-1.44c0.3-0.74,0.04-1.28-0.56-1.23C241.47,140.62,240.74,141.26,240.44,142z",
  "M236.92,142.34c-0.31,0.74-0.06,1.29,0.55,1.23c0.61-0.06,1.35-0.71,1.66-1.45c0.3-0.74,0.06-1.29-0.55-1.23C237.98,140.96,237.23,141.6,236.92,142.34z",
  "M233.41,142.69c-0.32,0.74-0.08,1.29,0.53,1.23c0.61-0.06,1.36-0.71,1.67-1.45c0.31-0.74,0.07-1.29-0.53-1.23C234.48,141.31,233.73,141.95,233.41,142.69z",
  "M229.91,143.05c-0.32,0.74-0.09,1.29,0.52,1.23c0.61-0.06,1.36-0.72,1.68-1.46c0.32-0.74,0.09-1.29-0.52-1.23C230.99,141.66,230.23,142.31,229.91,143.05z",
  "M226.41,143.42c-0.33,0.74-0.11,1.29,0.5,1.23c0.61-0.06,1.37-0.72,1.7-1.46c0.33-0.74,0.1-1.29-0.5-1.23C227.5,142.03,226.74,142.68,226.41,143.42z",
  "M219.42,144.18c-0.35,0.74-0.13,1.3,0.47,1.23c0.61-0.07,1.38-0.73,1.72-1.47c0.34-0.74,0.13-1.29-0.48-1.22C220.53,142.78,219.77,143.44,219.42,144.18z",
  "M215.93,144.58c-0.35,0.75-0.15,1.3,0.46,1.23c0.61-0.07,1.38-0.73,1.73-1.47c0.35-0.74,0.14-1.29-0.46-1.22C217.06,143.17,216.28,143.83,215.93,144.58z",
  "M208.97,145.39c-0.37,0.75-0.17,1.3,0.43,1.23c0.61-0.07,1.39-0.74,1.75-1.48c0.36-0.75,0.17-1.29-0.43-1.22C210.12,143.97,209.33,144.64,208.97,145.39z",
  "M205.49,145.8c-0.37,0.75-0.19,1.3,0.42,1.23c0.6-0.07,1.39-0.74,1.76-1.49c0.37-0.75,0.18-1.3-0.42-1.22C206.66,144.39,205.87,145.05,205.49,145.8z",
  "M198.56,146.66c-0.39,0.75-0.21,1.3,0.39,1.23c0.6-0.08,1.4-0.75,1.78-1.5c0.38-0.75,0.21-1.3-0.39-1.22C199.74,145.24,198.95,145.91,198.56,146.66z",
  "M195.1,147.1c-0.39,0.75-0.22,1.3,0.38,1.23c0.6-0.08,1.41-0.75,1.79-1.5c0.39-0.75,0.22-1.3-0.38-1.22C196.29,145.68,195.49,146.35,195.1,147.1z",
  "M191.65,147.55c-0.4,0.75-0.24,1.3,0.37,1.23c0.6-0.08,1.41-0.75,1.8-1.51c0.39-0.75,0.23-1.3-0.37-1.22C192.85,146.12,192.04,146.8,191.65,147.55z",
  "M188.2,148.01c-0.41,0.76-0.25,1.31,0.35,1.23c0.6-0.08,1.41-0.76,1.81-1.51c0.4-0.76,0.24-1.3-0.35-1.22C189.41,146.58,188.6,147.25,188.2,148.01z",
  "M184.75,148.47c-0.41,0.76-0.26,1.31,0.34,1.23c0.6-0.08,1.42-0.76,1.82-1.52c0.41-0.76,0.25-1.3-0.34-1.22C185.98,147.04,185.16,147.71,184.75,148.47z",
  "M177.88,149.42c-0.42,0.76-0.28,1.31,0.31,1.23c0.6-0.08,1.42-0.77,1.84-1.53c0.42-0.76,0.28-1.31-0.32-1.22C179.13,147.98,178.31,148.66,177.88,149.42z",
  "M174.46,149.91c-0.43,0.76-0.29,1.31,0.3,1.23c0.6-0.09,1.42-0.77,1.85-1.53c0.42-0.76,0.29-1.31-0.3-1.22C175.71,148.46,174.89,149.15,174.46,149.91z",
  "M167.62,150.91c-0.44,0.76-0.32,1.31,0.28,1.23c0.59-0.09,1.43-0.78,1.87-1.54c0.44-0.76,0.31-1.31-0.28-1.22C168.9,149.45,168.06,150.14,167.62,150.91z",
  "M164.22,151.42c-0.45,0.77-0.33,1.32,0.27,1.23c0.59-0.09,1.43-0.78,1.87-1.55c0.44-0.76,0.32-1.31-0.27-1.22C165.5,149.96,164.66,150.65,164.22,151.42z",
  "M154.03,152.99c-0.46,0.77-0.36,1.32,0.23,1.23c0.59-0.09,1.44-0.79,1.89-1.56c0.46-0.77,0.35-1.32-0.23-1.22C155.34,151.52,154.49,152.22,154.03,152.99z",
  "M623.65,152.84c0.52,0.79,1.4,1.5,1.96,1.6c0.56,0.1,0.58-0.46,0.06-1.25c-0.52-0.79-1.4-1.5-1.95-1.6C623.15,151.49,623.13,152.05,623.65,152.84z",
  "M620.44,152.28c0.52,0.78,1.39,1.5,1.95,1.6c0.56,0.1,0.59-0.46,0.07-1.25c-0.52-0.79-1.4-1.5-1.95-1.6C619.95,150.94,619.92,151.49,620.44,152.28z",
  "M617.22,151.73c0.52,0.78,1.39,1.5,1.95,1.59c0.56,0.1,0.59-0.46,0.07-1.25c-0.52-0.78-1.39-1.5-1.95-1.59C616.73,150.39,616.7,150.94,617.22,151.73z",
  "M613.99,151.18c0.51,0.78,1.39,1.49,1.95,1.59c0.56,0.09,0.6-0.46,0.08-1.25c-0.52-0.78-1.39-1.49-1.95-1.59C613.51,149.84,613.47,150.4,613.99,151.18z",
  "M610.75,150.64c0.51,0.78,1.38,1.49,1.94,1.58c0.56,0.09,0.6-0.46,0.09-1.24c-0.51-0.78-1.38-1.49-1.94-1.58C610.28,149.3,610.24,149.86,610.75,150.64z",
  "M607.51,150.1c0.51,0.78,1.38,1.49,1.94,1.58c0.56,0.09,0.61-0.46,0.1-1.24c-0.51-0.78-1.38-1.49-1.94-1.58C607.05,148.77,607,149.33,607.51,150.1z",
  "M604.26,149.58c0.5,0.78,1.37,1.48,1.94,1.57c0.56,0.09,0.61-0.46,0.11-1.24c-0.51-0.78-1.37-1.48-1.93-1.57C603.81,148.24,603.76,148.8,604.26,149.58z",
  "M601.01,149.06c0.5,0.77,1.36,1.48,1.93,1.57c0.57,0.09,0.62-0.47,0.12-1.24c-0.5-0.78-1.37-1.48-1.93-1.57C600.56,147.73,600.51,148.28,601.01,149.06z",
  "M597.75,148.54c0.5,0.77,1.36,1.47,1.93,1.56c0.57,0.09,0.62-0.47,0.12-1.24c-0.5-0.77-1.36-1.47-1.92-1.56C597.31,147.21,597.25,147.77,597.75,148.54z",
  "M594.48,148.03c0.49,0.77,1.35,1.47,1.92,1.56c0.57,0.09,0.63-0.47,0.13-1.24c-0.49-0.77-1.35-1.47-1.92-1.56C594.05,146.71,593.99,147.26,594.48,148.03z",
  "M591.2,147.53c0.49,0.77,1.35,1.47,1.92,1.55c0.57,0.09,0.63-0.47,0.14-1.24c-0.49-0.77-1.35-1.47-1.91-1.55C590.78,146.21,590.71,146.76,591.2,147.53z",
  "M587.92,147.04c0.48,0.77,1.34,1.46,1.91,1.55c0.57,0.09,0.64-0.47,0.15-1.24c-0.49-0.77-1.34-1.46-1.91-1.55C587.51,145.72,587.44,146.27,587.92,147.04z",
  "M584.63,146.55c0.48,0.77,1.33,1.46,1.9,1.54c0.57,0.08,0.65-0.47,0.16-1.24c-0.48-0.77-1.33-1.46-1.9-1.54C584.23,145.23,584.15,145.78,584.63,146.55z",
  "M581.34,146.07c0.47,0.77,1.32,1.45,1.9,1.54c0.57,0.08,0.65-0.47,0.17-1.24c-0.48-0.77-1.33-1.45-1.9-1.54C580.94,144.75,580.86,145.3,581.34,146.07z",
  "M578.04,145.6c0.47,0.76,1.32,1.45,1.89,1.53c0.57,0.08,0.66-0.47,0.18-1.24c-0.47-0.77-1.32-1.45-1.89-1.53C577.65,144.28,577.57,144.83,578.04,145.6z",
  "M574.73,145.13c0.46,0.76,1.31,1.45,1.88,1.53c0.57,0.08,0.66-0.47,0.19-1.24c-0.47-0.76-1.31-1.45-1.88-1.53C574.35,143.82,574.27,144.37,574.73,145.13z",
  "M571.42,144.67c0.46,0.76,1.3,1.44,1.88,1.52c0.58,0.08,0.67-0.47,0.2-1.24c-0.46-0.76-1.3-1.44-1.88-1.52C571.05,143.36,570.96,143.91,571.42,144.67z",
  "M568.1,144.22c0.45,0.76,1.29,1.44,1.87,1.52c0.58,0.08,0.67-0.47,0.22-1.23c-0.46-0.76-1.29-1.44-1.87-1.52C567.74,142.91,567.64,143.46,568.1,144.22z",
  "M564.77,143.77c0.45,0.76,1.28,1.44,1.86,1.51c0.58,0.08,0.68-0.48,0.23-1.23c-0.45-0.76-1.29-1.43-1.86-1.51C564.43,142.46,564.33,143.02,564.77,143.77z",
  "M561.44,143.33c0.44,0.76,1.27,1.43,1.85,1.51c0.58,0.08,0.69-0.48,0.24-1.23c-0.45-0.76-1.28-1.43-1.85-1.51C561.11,142.03,561,142.58,561.44,143.33z",
  "M558.11,142.9c0.44,0.75,1.26,1.43,1.85,1.5c0.58,0.08,0.69-0.48,0.25-1.23c-0.44-0.76-1.27-1.43-1.84-1.5C557.78,141.6,557.67,142.15,558.11,142.9z",
  "M554.77,142.48c0.43,0.75,1.25,1.42,1.84,1.5c0.58,0.07,0.7-0.48,0.26-1.23c-0.44-0.75-1.26-1.42-1.84-1.49C554.45,141.18,554.33,141.73,554.77,142.48z",
  "M551.42,142.06c0.43,0.75,1.25,1.42,1.83,1.49c0.58,0.07,0.71-0.48,0.27-1.23c-0.43-0.75-1.25-1.42-1.83-1.49C551.12,140.76,550.99,141.31,551.42,142.06z",
  "M548.07,141.65c0.42,0.75,1.24,1.42,1.82,1.49c0.58,0.07,0.71-0.48,0.29-1.23c-0.43-0.75-1.24-1.41-1.82-1.48C547.77,140.35,547.64,140.9,548.07,141.65z",
  "M544.71,141.25c0.41,0.75,1.23,1.41,1.81,1.48c0.58,0.07,0.72-0.48,0.3-1.23c-0.42-0.75-1.23-1.41-1.81-1.48C544.43,139.95,544.29,140.5,544.71,141.25z",
  "M541.34,140.86c0.41,0.75,1.21,1.41,1.8,1.48c0.59,0.07,0.73-0.48,0.31-1.23c-0.41-0.75-1.22-1.41-1.8-1.47C541.08,139.56,540.93,140.11,541.34,140.86z",
  "M537.97,140.47c0.4,0.74,1.2,1.41,1.79,1.47c0.59,0.07,0.73-0.48,0.33-1.23c-0.41-0.75-1.21-1.4-1.79-1.47C537.72,139.18,537.57,139.73,537.97,140.47z",
  "M534.6,140.09c0.4,0.74,1.19,1.4,1.78,1.47c0.59,0.07,0.74-0.49,0.34-1.23c-0.4-0.74-1.2-1.4-1.78-1.46C534.36,138.8,534.2,139.35,534.6,140.09z",
  "M531.22,139.72c0.39,0.74,1.18,1.4,1.77,1.46c0.59,0.07,0.75-0.49,0.35-1.23c-0.39-0.74-1.19-1.4-1.77-1.46C530.99,138.43,530.83,138.98,531.22,139.72z",
  "M527.84,139.36c0.38,0.74,1.17,1.39,1.76,1.46c0.59,0.06,0.75-0.49,0.36-1.23c-0.39-0.74-1.18-1.39-1.76-1.45C527.62,138.07,527.45,138.62,527.84,139.36z",
  "M524.45,139c0.38,0.74,1.16,1.39,1.75,1.45c0.59,0.06,0.76-0.49,0.38-1.23c-0.38-0.74-1.16-1.39-1.75-1.45C524.24,137.71,524.07,138.26,524.45,139z",
  "M521.05,138.65c0.37,0.74,1.15,1.39,1.74,1.45c0.59,0.06,0.77-0.49,0.39-1.23c-0.37-0.74-1.15-1.38-1.74-1.44C520.86,137.37,520.69,137.91,521.05,138.65z",
  "M517.66,138.31c0.36,0.74,1.14,1.38,1.73,1.44c0.59,0.06,0.77-0.49,0.41-1.23c-0.37-0.74-1.14-1.38-1.73-1.44C517.48,137.03,517.29,137.57,517.66,138.31z",
  "M514.25,137.98c0.36,0.73,1.12,1.38,1.72,1.44c0.59,0.06,0.78-0.49,0.42-1.23c-0.36-0.74-1.13-1.38-1.72-1.43C514.09,136.69,513.9,137.24,514.25,137.98z",
  "M510.85,137.65c0.35,0.73,1.11,1.38,1.71,1.43c0.59,0.06,0.79-0.49,0.43-1.23c-0.35-0.73-1.12-1.37-1.71-1.43C510.69,136.37,510.5,136.92,510.85,137.65z",
  "M507.43,137.33c0.34,0.73,1.1,1.37,1.7,1.43c0.59,0.06,0.8-0.5,0.45-1.23c-0.35-0.73-1.1-1.37-1.69-1.42C507.29,136.05,507.09,136.6,507.43,137.33z",
  "M504.02,137.02c0.33,0.73,1.09,1.37,1.68,1.42c0.6,0.05,0.8-0.5,0.46-1.23c-0.34-0.73-1.09-1.37-1.68-1.42C503.89,135.75,503.68,136.29,504.02,137.02z",
  "M500.6,136.72c0.33,0.73,1.08,1.37,1.67,1.42c0.6,0.05,0.81-0.5,0.48-1.23c-0.33-0.73-1.08-1.36-1.67-1.41C500.48,135.44,500.27,135.99,500.6,136.72z",
  "M497.17,136.43c0.32,0.73,1.06,1.36,1.66,1.41c0.6,0.05,0.82-0.5,0.49-1.23c-0.32-0.73-1.07-1.36-1.66-1.41C497.07,135.15,496.85,135.7,497.17,136.43z",
  "M493.74,136.14c0.31,0.73,1.05,1.36,1.65,1.41c0.6,0.05,0.82-0.5,0.51-1.23c-0.32-0.73-1.05-1.36-1.65-1.4C493.66,134.87,493.43,135.42,493.74,136.14z",
  "M490.31,135.86c0.3,0.73,1.04,1.36,1.63,1.4c0.6,0.05,0.83-0.5,0.52-1.23c-0.31-0.73-1.04-1.35-1.63-1.4C490.24,134.59,490,135.14,490.31,135.86z",
  "M486.87,135.6c0.3,0.72,1.02,1.35,1.62,1.4c0.6,0.05,0.84-0.51,0.54-1.23c-0.3-0.73-1.03-1.35-1.62-1.39C486.82,134.32,486.58,134.87,486.87,135.6z",
  "M483.43,135.33c0.29,0.72,1.01,1.35,1.61,1.39c0.6,0.05,0.85-0.51,0.55-1.23c-0.29-0.72-1.01-1.35-1.61-1.39C483.39,134.06,483.14,134.61,483.43,135.33z",
  "M479.99,135.08c0.28,0.72,1,1.35,1.6,1.39c0.6,0.04,0.85-0.51,0.57-1.23c-0.29-0.72-1-1.34-1.6-1.39C479.96,133.81,479.71,134.36,479.99,135.08z",
  "M476.54,134.83c0.27,0.72,0.98,1.34,1.58,1.39c0.6,0.04,0.86-0.51,0.58-1.23c-0.28-0.72-0.99-1.34-1.58-1.38C476.53,133.57,476.26,134.11,476.54,134.83z",
  "M473.09,134.6c0.27,0.72,0.97,1.34,1.57,1.38c0.6,0.04,0.87-0.51,0.6-1.23c-0.27-0.72-0.97-1.34-1.57-1.38C473.09,133.33,472.82,133.88,473.09,134.6z",
  "M469.63,134.37c0.26,0.72,0.95,1.34,1.56,1.38c0.6,0.04,0.88-0.51,0.61-1.23c-0.26-0.72-0.96-1.33-1.56-1.37C469.65,133.1,469.37,133.65,469.63,134.37z",
  "M466.17,134.15c0.25,0.72,0.94,1.33,1.54,1.37c0.6,0.04,0.88-0.51,0.63-1.23c-0.26-0.72-0.95-1.33-1.54-1.37C466.21,132.88,465.92,133.43,466.17,134.15z",
  "M462.71,133.94c0.24,0.72,0.93,1.33,1.53,1.37c0.6,0.04,0.89-0.52,0.65-1.24c-0.25-0.72-0.93-1.33-1.53-1.36C462.76,132.67,462.47,133.22,462.71,133.94z",
  "M459.24,133.73c0.23,0.72,0.91,1.33,1.52,1.36c0.6,0.04,0.9-0.52,0.66-1.24c-0.24-0.72-0.92-1.32-1.52-1.36C459.31,132.47,459.01,133.02,459.24,133.73z",
  "M455.78,133.54c0.22,0.72,0.9,1.32,1.5,1.36c0.6,0.03,0.91-0.52,0.68-1.24c-0.23-0.72-0.9-1.32-1.5-1.35C455.86,132.27,455.55,132.82,455.78,133.54z",
  "M452.3,133.35c0.22,0.71,0.88,1.32,1.49,1.35c0.61,0.03,0.92-0.52,0.69-1.24c-0.22-0.72-0.89-1.32-1.49-1.35C452.4,132.08,452.09,132.63,452.3,133.35z",
  "M448.83,133.17c0.21,0.71,0.87,1.32,1.47,1.35c0.61,0.03,0.92-0.52,0.71-1.24c-0.21-0.71-0.87-1.32-1.47-1.35C448.94,131.9,448.62,132.46,448.83,133.17z",
  "M445.35,133c0.2,0.71,0.85,1.32,1.46,1.35c0.61,0.03,0.93-0.53,0.73-1.24c-0.21-0.71-0.86-1.31-1.46-1.34C445.48,131.73,445.15,132.29,445.35,133z",
  "M441.87,132.84c0.19,0.71,0.84,1.31,1.44,1.34c0.61,0.03,0.94-0.53,0.74-1.24c-0.2-0.71-0.84-1.31-1.44-1.34C442.02,131.57,441.68,132.12,441.87,132.84z",
  "M438.39,132.68c0.18,0.71,0.82,1.31,1.43,1.34c0.61,0.03,0.95-0.53,0.76-1.24c-0.19-0.71-0.83-1.31-1.43-1.33C438.55,131.42,438.21,131.97,438.39,132.68z",
  "M434.91,132.54c0.17,0.71,0.81,1.31,1.42,1.33c0.61,0.03,0.95-0.53,0.77-1.24c-0.18-0.71-0.81-1.31-1.41-1.33C435.08,131.27,434.73,131.83,434.91,132.54z",
  "M431.42,132.4c0.17,0.71,0.79,1.31,1.4,1.33c0.61,0.02,0.96-0.54,0.79-1.25c-0.17-0.71-0.8-1.3-1.4-1.33C431.61,131.14,431.25,131.69,431.42,132.4z",
  "M427.93,132.27c0.16,0.71,0.78,1.3,1.39,1.33c0.61,0.02,0.97-0.54,0.81-1.25c-0.16-0.71-0.78-1.3-1.39-1.32C428.13,131.01,427.77,131.56,427.93,132.27z",
  "M424.44,132.15c0.15,0.71,0.76,1.3,1.37,1.32c0.61,0.02,0.98-0.54,0.82-1.25c-0.15-0.71-0.77-1.3-1.37-1.32C424.66,130.89,424.29,131.44,424.44,132.15z",
  "M420.94,132.04c0.14,0.71,0.75,1.3,1.36,1.32c0.61,0.02,0.99-0.54,0.84-1.25c-0.15-0.71-0.75-1.3-1.36-1.31C421.18,130.78,420.8,131.33,420.94,132.04z",
  "M417.44,131.94c0.13,0.71,0.73,1.3,1.34,1.32c0.61,0.02,0.99-0.54,0.86-1.25c-0.14-0.71-0.74-1.29-1.34-1.31C417.7,130.67,417.31,131.23,417.44,131.94z",
  "M413.95,131.84c0.12,0.71,0.72,1.3,1.33,1.31c0.61,0.02,1-0.55,0.87-1.25c-0.13-0.71-0.72-1.29-1.32-1.31C414.22,130.58,413.82,131.14,413.95,131.84z",
  "M410.45,131.76c0.11,0.71,0.7,1.29,1.31,1.31c0.61,0.01,1.01-0.55,0.89-1.26c-0.12-0.71-0.71-1.29-1.31-1.3C410.73,130.49,410.33,131.05,410.45,131.76z",
  "M406.94,131.68c0.1,0.71,0.68,1.29,1.29,1.31c0.61,0.01,1.02-0.55,0.91-1.26c-0.11-0.71-0.69-1.29-1.29-1.3C407.24,130.42,406.84,130.98,406.94,131.68z",
  "M403.44,131.61c0.1,0.71,0.67,1.29,1.28,1.3c0.61,0.01,1.02-0.55,0.92-1.26c-0.1-0.71-0.67-1.29-1.28-1.3C403.76,130.35,403.34,130.91,403.44,131.61z",
  "M399.93,131.55c0.09,0.71,0.65,1.29,1.26,1.3c0.61,0.01,1.03-0.56,0.94-1.26c-0.09-0.71-0.66-1.28-1.26-1.29C400.27,130.29,399.85,130.85,399.93,131.55z",
  "M396.43,131.5c0.08,0.71,0.64,1.29,1.25,1.3c0.61,0.01,1.04-0.56,0.96-1.26c-0.08-0.71-0.64-1.28-1.25-1.29C396.78,130.24,396.35,130.8,396.43,131.5z",
  "M392.92,131.46c0.07,0.71,0.62,1.29,1.23,1.29c0.61,0.01,1.05-0.56,0.97-1.27c-0.08-0.71-0.63-1.28-1.23-1.29C393.29,130.19,392.85,130.76,392.92,131.46z",
  "M389.41,131.43c0.06,0.71,0.6,1.28,1.22,1.29c0.61,0.01,1.06-0.56,0.99-1.27c-0.07-0.71-0.61-1.28-1.22-1.28C389.79,130.16,389.35,130.72,389.41,131.43z",
  "M385.9,131.4c0.05,0.7,0.59,1.28,1.2,1.29c0.61,0,1.06-0.57,1.01-1.27c-0.06-0.71-0.59-1.28-1.2-1.28C386.3,130.13,385.85,130.7,385.9,131.4z",
  "M382.38,131.39c0.04,0.7,0.57,1.28,1.18,1.28c0.61,0,1.07-0.57,1.02-1.27c-0.05-0.7-0.58-1.28-1.18-1.28C382.8,130.11,382.34,130.68,382.38,131.39z",
  "M378.87,131.38c0.03,0.7,0.56,1.28,1.17,1.28c0.61,0,1.08-0.57,1.04-1.28c-0.04-0.7-0.56-1.27-1.17-1.28C379.3,130.11,378.84,130.67,378.87,131.38z",
  "M375.36,131.38c0.02,0.7,0.54,1.28,1.15,1.28c0.61,0,1.08-0.57,1.06-1.28c-0.03-0.7-0.55-1.27-1.15-1.27C375.81,130.11,375.33,130.68,375.36,131.38z",
  "M371.84,131.39c0.01,0.7,0.52,1.28,1.14,1.27c0.61,0,1.09-0.58,1.07-1.28c-0.02-0.71-0.53-1.27-1.14-1.27C372.31,130.11,371.83,130.68,371.84,131.39z",
  "M368.33,131.41c0.01,0.7,0.51,1.28,1.12,1.27c0.61,0,1.1-0.58,1.09-1.28c-0.01-0.7-0.51-1.27-1.12-1.27C368.81,130.13,368.32,130.7,368.33,131.41z",
  "M329.63,132.19c-0.09,0.71,0.33,1.27,0.94,1.25c0.61-0.02,1.18-0.61,1.27-1.32c0.09-0.71-0.34-1.26-0.94-1.24C330.29,130.89,329.73,131.48,329.63,132.19z",
  "M326.12,132.31c-0.1,0.71,0.31,1.27,0.93,1.24c0.61-0.02,1.19-0.62,1.28-1.32c0.1-0.71-0.32-1.26-0.93-1.24C326.79,131.01,326.22,131.6,326.12,132.31z",
  "M322.6,132.44c-0.11,0.71,0.3,1.27,0.91,1.24c0.61-0.02,1.19-0.62,1.3-1.33c0.1-0.71-0.3-1.26-0.91-1.24C323.29,131.14,322.71,131.73,322.6,132.44z",
  "M319.09,132.58c-0.12,0.71,0.28,1.27,0.89,1.24c0.61-0.02,1.2-0.62,1.31-1.33c0.11-0.71-0.29-1.26-0.89-1.24C319.79,131.28,319.21,131.88,319.09,132.58z",
  "M315.57,132.73c-0.13,0.71,0.26,1.27,0.88,1.24c0.61-0.03,1.21-0.63,1.33-1.33c0.12-0.71-0.27-1.26-0.88-1.23C316.3,131.43,315.7,132.02,315.57,132.73z",
  "M291,134.02c-0.19,0.71,0.15,1.27,0.76,1.23c0.61-0.04,1.25-0.65,1.44-1.36c0.18-0.71-0.16-1.26-0.77-1.22C291.83,132.7,291.19,133.31,291,134.02z",
  "M287.5,134.24c-0.2,0.72,0.14,1.27,0.75,1.23c0.61-0.04,1.26-0.65,1.45-1.37c0.19-0.72-0.14-1.26-0.75-1.22C288.34,132.92,287.69,133.52,287.5,134.24z",
  "M283.99,134.47c-0.21,0.72,0.12,1.27,0.73,1.23c0.61-0.04,1.27-0.65,1.47-1.37c0.2-0.72-0.13-1.26-0.73-1.22C284.86,133.14,284.2,133.75,283.99,134.47z",
  "M280.49,134.7c-0.21,0.72,0.11,1.27,0.72,1.23c0.61-0.04,1.27-0.66,1.48-1.38c0.21-0.72-0.11-1.26-0.72-1.22C281.37,133.37,280.71,133.98,280.49,134.7z",
  "M277,134.95c-0.22,0.72,0.09,1.27,0.7,1.23c0.61-0.04,1.28-0.66,1.5-1.38c0.22-0.72-0.1-1.26-0.7-1.22C277.89,133.61,277.22,134.23,277,134.95z",
  "M273.5,135.2c-0.23,0.72,0.08,1.27,0.69,1.22c0.61-0.04,1.29-0.67,1.51-1.38c0.22-0.72-0.08-1.26-0.69-1.22C274.41,133.86,273.73,134.48,273.5,135.2z",
  "M270,135.46c-0.24,0.72,0.06,1.27,0.67,1.22c0.61-0.05,1.29-0.67,1.52-1.39c0.23-0.72-0.07-1.26-0.67-1.22C270.92,134.12,270.24,134.74,270,135.46z",
  "M266.51,135.73c-0.25,0.72,0.05,1.27,0.66,1.22c0.61-0.05,1.3-0.67,1.54-1.39c0.24-0.72-0.05-1.26-0.66-1.22C267.45,134.39,266.76,135.01,266.51,135.73z",
  "M242.14,137.85c-0.3,0.73-0.06,1.28,0.55,1.22c0.61-0.06,1.34-0.7,1.63-1.43c0.3-0.73,0.05-1.27-0.55-1.21C243.17,136.49,242.44,137.12,242.14,137.85z",
  "M238.67,138.19c-0.31,0.73-0.07,1.28,0.53,1.22c0.6-0.06,1.34-0.7,1.64-1.43c0.3-0.73,0.06-1.27-0.54-1.21C239.71,136.82,238.98,137.46,238.67,138.19z",
  "M235.2,138.53c-0.32,0.73-0.08,1.28,0.52,1.22c0.6-0.06,1.35-0.7,1.66-1.44c0.31-0.73,0.08-1.27-0.52-1.21C236.26,137.16,235.52,137.8,235.2,138.53z",
  "M231.74,138.89c-0.32,0.73-0.1,1.28,0.51,1.22c0.6-0.06,1.35-0.71,1.67-1.44c0.32-0.73,0.09-1.27-0.51-1.21C232.81,137.51,232.06,138.15,231.74,138.89z",
  "M228.28,139.25c-0.33,0.73-0.11,1.28,0.49,1.22c0.6-0.06,1.36-0.71,1.68-1.44c0.33-0.73,0.1-1.28-0.49-1.21C229.36,137.87,228.61,138.51,228.28,139.25z",
  "M221.37,139.99c-0.35,0.74-0.14,1.28,0.46,1.22c0.6-0.07,1.37-0.72,1.71-1.45c0.34-0.74,0.13-1.28-0.46-1.21C222.48,138.61,221.72,139.26,221.37,139.99z",
  "M217.92,140.38c-0.35,0.74-0.15,1.28,0.45,1.22c0.6-0.07,1.37-0.72,1.72-1.46c0.35-0.74,0.15-1.28-0.45-1.21C219.04,138.99,218.28,139.64,217.92,140.38z",
  "M214.48,140.77c-0.36,0.74-0.16,1.29,0.44,1.22c0.6-0.07,1.37-0.73,1.73-1.46c0.35-0.74,0.16-1.28-0.44-1.21C215.61,139.38,214.84,140.03,214.48,140.77z",
  "M207.6,141.58c-0.37,0.74-0.19,1.29,0.41,1.22c0.6-0.07,1.38-0.73,1.75-1.47c0.37-0.74,0.18-1.28-0.41-1.21C208.76,140.18,207.97,140.84,207.6,141.58z",
  "M197.32,142.86c-0.39,0.75-0.23,1.29,0.37,1.22c0.6-0.08,1.39-0.74,1.78-1.49c0.39-0.75,0.22-1.29-0.37-1.21C198.51,141.45,197.71,142.11,197.32,142.86z",
  "M190.48,143.75c-0.4,0.75-0.25,1.3,0.34,1.22c0.59-0.08,1.4-0.75,1.8-1.5c0.4-0.75,0.25-1.29-0.34-1.21C191.7,142.33,190.89,143,190.48,143.75z",
  "M176.88,145.62c-0.43,0.76-0.3,1.3,0.29,1.22c0.59-0.08,1.41-0.77,1.84-1.52c0.42-0.76,0.29-1.3-0.29-1.22C178.13,144.18,177.31,144.86,176.88,145.62z",
  "M173.49,146.11c-0.43,0.76-0.31,1.3,0.28,1.22c0.59-0.09,1.42-0.77,1.85-1.53c0.43-0.76,0.3-1.3-0.28-1.22C174.75,144.67,173.92,145.35,173.49,146.11z",
  "M170.11,146.6c-0.44,0.76-0.32,1.31,0.27,1.22c0.59-0.09,1.42-0.77,1.85-1.53c0.44-0.76,0.31-1.3-0.27-1.22C171.38,145.16,170.55,145.84,170.11,146.6z",
  "M166.73,147.1c-0.44,0.76-0.33,1.31,0.26,1.22c0.59-0.09,1.42-0.78,1.86-1.54c0.44-0.76,0.32-1.3-0.26-1.22C168.01,145.66,167.17,146.34,166.73,147.1z",
  "M604.65,145.71c0.51,0.78,1.37,1.48,1.93,1.57c0.56,0.09,0.6-0.46,0.09-1.24c-0.51-0.78-1.37-1.48-1.93-1.57C604.18,144.38,604.14,144.94,604.65,145.71z",
  "M601.42,145.19c0.5,0.77,1.37,1.47,1.93,1.56c0.56,0.09,0.61-0.47,0.1-1.24c-0.51-0.78-1.37-1.47-1.93-1.56C600.96,143.87,600.92,144.42,601.42,145.19z",
  "M598.18,144.68c0.5,0.77,1.36,1.47,1.92,1.56c0.56,0.09,0.61-0.47,0.11-1.24c-0.5-0.77-1.36-1.47-1.92-1.56C597.73,143.36,597.68,143.91,598.18,144.68z",
  "M594.94,144.18c0.5,0.77,1.35,1.47,1.92,1.55c0.56,0.09,0.62-0.47,0.12-1.24c-0.5-0.77-1.36-1.47-1.92-1.55C594.5,142.86,594.45,143.41,594.94,144.18z",
  "M591.7,143.68c0.49,0.77,1.35,1.46,1.91,1.55c0.57,0.09,0.62-0.47,0.13-1.24c-0.49-0.77-1.35-1.46-1.91-1.55C591.26,142.36,591.21,142.91,591.7,143.68z",
  "M588.45,143.19c0.49,0.77,1.34,1.46,1.91,1.54c0.57,0.09,0.63-0.47,0.14-1.24c-0.49-0.77-1.34-1.46-1.91-1.54C588.02,141.87,587.96,142.42,588.45,143.19z",
  "M585.19,142.7c0.48,0.76,1.33,1.45,1.9,1.54c0.57,0.08,0.63-0.47,0.15-1.23c-0.49-0.77-1.34-1.45-1.9-1.54C584.77,141.39,584.71,141.94,585.19,142.7z",
  "M581.93,142.23c0.48,0.76,1.33,1.45,1.89,1.53c0.57,0.08,0.64-0.47,0.16-1.23c-0.48-0.76-1.33-1.45-1.89-1.53C581.52,140.91,581.45,141.46,581.93,142.23z",
  "M578.66,141.76c0.47,0.76,1.32,1.45,1.89,1.53c0.57,0.08,0.64-0.47,0.17-1.23c-0.48-0.76-1.32-1.44-1.89-1.53C578.26,140.44,578.18,141,578.66,141.76z",
  "M575.38,141.29c0.47,0.76,1.31,1.44,1.88,1.52c0.57,0.08,0.65-0.47,0.18-1.23c-0.47-0.76-1.31-1.44-1.88-1.52C575,139.98,574.92,140.53,575.38,141.29z",
  "M572.11,140.83c0.46,0.76,1.3,1.44,1.87,1.52c0.57,0.08,0.65-0.47,0.19-1.23c-0.47-0.76-1.31-1.44-1.87-1.51S571.64,140.08,572.11,140.83z",
  "M568.82,140.39c0.46,0.76,1.29,1.43,1.87,1.51c0.57,0.08,0.66-0.47,0.2-1.23c-0.46-0.76-1.3-1.43-1.86-1.51C568.45,139.08,568.36,139.63,568.82,140.39z",
  "M565.53,139.94c0.45,0.75,1.29,1.43,1.86,1.51c0.57,0.08,0.67-0.47,0.21-1.23c-0.46-0.76-1.29-1.43-1.86-1.5C565.17,138.64,565.08,139.19,565.53,139.94z",
  "M562.24,139.51c0.45,0.75,1.28,1.42,1.85,1.5c0.57,0.08,0.67-0.47,0.22-1.23c-0.45-0.75-1.28-1.42-1.85-1.5C561.89,138.21,561.79,138.75,562.24,139.51z",
  "M558.94,139.08c0.44,0.75,1.27,1.42,1.84,1.49c0.57,0.07,0.68-0.48,0.23-1.23c-0.45-0.75-1.27-1.42-1.84-1.49C558.6,137.78,558.49,138.33,558.94,139.08z",
  "M555.63,138.66c0.44,0.75,1.26,1.42,1.83,1.49c0.58,0.07,0.68-0.48,0.24-1.23c-0.44-0.75-1.26-1.41-1.83-1.49C555.31,137.36,555.2,137.91,555.63,138.66z",
  "M552.32,138.24c0.43,0.75,1.25,1.41,1.83,1.48c0.58,0.07,0.69-0.48,0.25-1.22c-0.44-0.75-1.25-1.41-1.82-1.48C552.01,136.95,551.89,137.49,552.32,138.24z",
  "M549.01,137.83c0.43,0.74,1.24,1.41,1.82,1.48c0.58,0.07,0.7-0.48,0.27-1.22c-0.43-0.75-1.24-1.41-1.82-1.48C548.7,136.54,548.58,137.09,549.01,137.83z",
  "M545.69,137.43c0.42,0.74,1.23,1.4,1.81,1.47c0.58,0.07,0.7-0.48,0.28-1.22c-0.42-0.74-1.23-1.4-1.81-1.47C545.39,136.15,545.27,136.69,545.69,137.43z",
  "M542.36,137.04c0.41,0.74,1.22,1.4,1.8,1.47c0.58,0.07,0.71-0.48,0.29-1.22c-0.42-0.74-1.22-1.4-1.8-1.46C542.08,135.76,541.95,136.3,542.36,137.04z",
  "M539.04,136.66c0.41,0.74,1.21,1.4,1.79,1.46c0.58,0.07,0.72-0.48,0.3-1.22c-0.41-0.74-1.21-1.39-1.79-1.46C538.76,135.37,538.63,135.92,539.04,136.66z",
  "M535.7,136.28c0.4,0.74,1.2,1.39,1.78,1.46c0.58,0.07,0.72-0.48,0.32-1.22c-0.41-0.74-1.2-1.39-1.78-1.45C535.44,135,535.3,135.54,535.7,136.28z",
  "M532.36,135.91c0.39,0.74,1.19,1.39,1.77,1.45c0.58,0.06,0.73-0.48,0.33-1.22c-0.4-0.74-1.19-1.39-1.77-1.45C532.12,134.63,531.97,135.17,532.36,135.91z",
  "M529.02,135.55c0.39,0.73,1.18,1.38,1.76,1.45c0.58,0.06,0.73-0.48,0.34-1.22c-0.39-0.74-1.18-1.38-1.76-1.44C528.79,134.27,528.63,134.81,529.02,135.55z",
  "M525.67,135.19c0.38,0.73,1.16,1.38,1.75,1.44c0.58,0.06,0.74-0.48,0.36-1.22c-0.39-0.73-1.17-1.38-1.75-1.44C525.45,133.91,525.29,134.46,525.67,135.19z",
  "M522.32,134.84c0.38,0.73,1.15,1.38,1.74,1.44c0.58,0.06,0.75-0.49,0.37-1.22c-0.38-0.73-1.16-1.37-1.74-1.43C522.11,133.57,521.95,134.11,522.32,134.84z",
  "M518.97,134.5c0.37,0.73,1.14,1.37,1.73,1.43c0.58,0.06,0.76-0.49,0.38-1.22c-0.37-0.73-1.15-1.37-1.72-1.43C518.77,133.23,518.6,133.77,518.97,134.5z",
  "M515.61,134.17c0.36,0.73,1.13,1.37,1.71,1.43c0.59,0.06,0.76-0.49,0.4-1.22c-0.37-0.73-1.13-1.37-1.71-1.42C515.42,132.9,515.25,133.44,515.61,134.17z",
  "M512.24,133.84c0.35,0.73,1.12,1.36,1.7,1.42c0.59,0.06,0.77-0.49,0.41-1.22c-0.36-0.73-1.12-1.36-1.7-1.42C512.07,132.57,511.89,133.12,512.24,133.84z",
  "M508.88,133.53c0.35,0.73,1.1,1.36,1.69,1.42c0.59,0.05,0.78-0.49,0.42-1.22c-0.35-0.73-1.11-1.36-1.69-1.41C508.72,132.26,508.53,132.8,508.88,133.53z",
  "M505.5,133.22c0.34,0.72,1.09,1.36,1.68,1.41c0.59,0.05,0.78-0.49,0.44-1.22c-0.35-0.73-1.1-1.35-1.68-1.41C505.36,131.95,505.16,132.49,505.5,133.22z",
  "M502.13,132.92c0.33,0.72,1.08,1.35,1.67,1.41c0.59,0.05,0.79-0.49,0.45-1.22c-0.34-0.72-1.08-1.35-1.67-1.4C502,131.65,501.8,132.19,502.13,132.92z",
  "M498.75,132.62c0.33,0.72,1.07,1.35,1.66,1.4c0.59,0.05,0.8-0.49,0.47-1.22c-0.33-0.72-1.07-1.35-1.66-1.4C498.63,131.36,498.42,131.9,498.75,132.62z",
  "M495.37,132.33c0.32,0.72,1.05,1.35,1.64,1.4c0.59,0.05,0.8-0.5,0.48-1.22c-0.32-0.72-1.06-1.34-1.64-1.39C495.26,131.07,495.05,131.61,495.37,132.33z",
  "M491.98,132.06c0.31,0.72,1.04,1.34,1.63,1.39c0.59,0.05,0.81-0.5,0.5-1.22c-0.32-0.72-1.05-1.34-1.63-1.39C491.89,130.8,491.67,131.34,491.98,132.06z",
  "M488.59,131.79c0.3,0.72,1.03,1.34,1.62,1.39c0.59,0.05,0.82-0.5,0.51-1.22c-0.31-0.72-1.03-1.34-1.62-1.38C488.52,130.53,488.29,131.07,488.59,131.79z",
  "M485.2,131.52c0.3,0.72,1.02,1.34,1.61,1.38c0.59,0.05,0.83-0.5,0.53-1.22c-0.3-0.72-1.02-1.33-1.61-1.38C485.14,130.26,484.9,130.81,485.2,131.52z",
  "M481.8,131.27c0.29,0.71,1,1.33,1.59,1.38c0.59,0.04,0.83-0.5,0.54-1.22c-0.29-0.72-1.01-1.33-1.59-1.37C481.75,130.01,481.51,130.55,481.8,131.27z",
  "M478.4,131.02c0.28,0.71,0.99,1.33,1.58,1.37c0.59,0.04,0.84-0.5,0.56-1.22c-0.29-0.71-0.99-1.33-1.58-1.37C478.37,129.77,478.12,130.31,478.4,131.02z",
  "M474.99,130.78c0.27,0.71,0.97,1.33,1.57,1.37c0.59,0.04,0.85-0.51,0.57-1.22c-0.28-0.71-0.98-1.32-1.57-1.36C474.98,129.53,474.72,130.07,474.99,130.78z",
  "M471.59,130.55c0.26,0.71,0.96,1.32,1.55,1.36c0.59,0.04,0.86-0.51,0.59-1.22c-0.27-0.71-0.97-1.32-1.55-1.36C471.59,129.3,471.32,129.84,471.59,130.55z",
  "M468.18,130.33c0.26,0.71,0.95,1.32,1.54,1.36c0.59,0.04,0.86-0.51,0.6-1.22c-0.26-0.71-0.95-1.32-1.54-1.35C468.19,129.08,467.92,129.62,468.18,130.33z",
  "M464.76,130.11c0.25,0.71,0.93,1.32,1.53,1.35c0.59,0.04,0.87-0.51,0.62-1.22c-0.25-0.71-0.94-1.31-1.53-1.35C464.79,128.86,464.52,129.4,464.76,130.11z",
  "M461.35,129.91c0.24,0.71,0.92,1.31,1.51,1.35c0.6,0.04,0.88-0.51,0.63-1.22c-0.25-0.71-0.92-1.31-1.51-1.34C461.39,128.66,461.11,129.2,461.35,129.91z",
  "M457.93,129.71c0.23,0.71,0.9,1.31,1.5,1.34c0.6,0.03,0.89-0.51,0.65-1.22c-0.24-0.71-0.91-1.31-1.5-1.34C457.99,128.46,457.7,129,457.93,129.71z",
  "M454.51,129.52c0.22,0.71,0.89,1.31,1.49,1.34c0.6,0.03,0.89-0.52,0.66-1.22c-0.23-0.71-0.9-1.3-1.48-1.34C454.58,128.27,454.28,128.81,454.51,129.52z",
  "M451.08,129.34c0.22,0.7,0.87,1.3,1.47,1.34c0.6,0.03,0.9-0.52,0.68-1.22c-0.22-0.71-0.88-1.3-1.47-1.33C451.17,128.09,450.87,128.63,451.08,129.34z",
  "M447.65,129.16c0.21,0.7,0.86,1.3,1.46,1.33c0.6,0.03,0.91-0.52,0.7-1.22c-0.21-0.7-0.86-1.3-1.46-1.33C447.76,127.91,447.45,128.46,447.65,129.16z",
  "M444.23,129c0.2,0.7,0.85,1.3,1.44,1.33c0.6,0.03,0.92-0.52,0.71-1.22c-0.2-0.7-0.85-1.29-1.44-1.32C444.35,127.75,444.03,128.29,444.23,129z",
  "M440.79,128.84c0.19,0.7,0.83,1.3,1.43,1.32c0.6,0.03,0.92-0.52,0.73-1.23c-0.2-0.7-0.84-1.29-1.43-1.32C440.93,127.59,440.6,128.14,440.79,128.84z",
  "M437.36,128.69c0.18,0.7,0.81,1.29,1.41,1.32c0.6,0.03,0.93-0.52,0.74-1.23c-0.19-0.7-0.82-1.29-1.41-1.31C437.51,127.44,437.18,127.99,437.36,128.69z",
  "M433.92,128.55c0.17,0.7,0.8,1.29,1.4,1.32c0.6,0.02,0.94-0.53,0.76-1.23c-0.18-0.7-0.81-1.29-1.4-1.31C434.09,127.3,433.75,127.85,433.92,128.55z",
  "M430.48,128.41c0.16,0.7,0.78,1.29,1.38,1.31c0.6,0.02,0.95-0.53,0.78-1.23c-0.17-0.7-0.79-1.28-1.38-1.31C430.67,127.17,430.32,127.71,430.48,128.41z",
  "M427.04,128.29c0.16,0.7,0.77,1.29,1.37,1.31c0.6,0.02,0.95-0.53,0.79-1.23c-0.16-0.7-0.77-1.28-1.37-1.3C427.24,127.04,426.89,127.59,427.04,128.29z",
  "M423.6,128.17c0.15,0.7,0.75,1.28,1.35,1.3c0.6,0.02,0.96-0.53,0.81-1.23c-0.15-0.7-0.76-1.28-1.35-1.3C423.81,126.93,423.45,127.47,423.6,128.17z",
  "M420.15,128.07c0.14,0.7,0.74,1.28,1.34,1.3c0.6,0.02,0.97-0.54,0.82-1.23c-0.14-0.7-0.74-1.28-1.34-1.3C420.38,126.82,420.01,127.37,420.15,128.07z",
  "M416.71,127.97c0.13,0.7,0.72,1.28,1.32,1.3c0.6,0.02,0.98-0.54,0.84-1.24c-0.14-0.7-0.73-1.28-1.32-1.29C416.95,126.72,416.58,127.27,416.71,127.97z",
  "M413.26,127.87c0.12,0.7,0.71,1.28,1.31,1.29c0.6,0.02,0.99-0.54,0.86-1.24c-0.13-0.7-0.71-1.27-1.31-1.29C413.52,126.63,413.14,127.18,413.26,127.87z",
  "M402.9,127.65c0.09,0.7,0.66,1.27,1.26,1.28c0.6,0.01,1.01-0.55,0.91-1.24c-0.1-0.7-0.67-1.27-1.26-1.28C403.21,126.4,402.81,126.95,402.9,127.65z",
  "M399.44,127.59c0.09,0.7,0.64,1.27,1.25,1.28c0.6,0.01,1.02-0.55,0.92-1.24c-0.09-0.7-0.65-1.27-1.25-1.27C399.77,126.34,399.36,126.9,399.44,127.59z",
  "M395.99,127.54c0.08,0.7,0.63,1.27,1.23,1.28c0.6,0.01,1.02-0.55,0.94-1.25c-0.08-0.7-0.63-1.26-1.23-1.27C396.33,126.29,395.91,126.85,395.99,127.54z",
  "M392.53,127.5c0.07,0.7,0.61,1.27,1.22,1.27c0.6,0.01,1.03-0.55,0.96-1.25c-0.07-0.7-0.62-1.26-1.22-1.27C392.89,126.25,392.46,126.81,392.53,127.5z",
  "M389.07,127.47c0.06,0.69,0.6,1.26,1.2,1.27c0.6,0.01,1.04-0.56,0.97-1.25c-0.07-0.7-0.6-1.26-1.2-1.27C389.45,126.22,389.01,126.78,389.07,127.47z",
  "M385.61,127.45c0.05,0.69,0.58,1.26,1.18,1.27c0.6,0,1.05-0.56,0.99-1.25c-0.06-0.69-0.59-1.26-1.18-1.26C386,126.19,385.56,126.75,385.61,127.45z",
  "M382.15,127.43c0.04,0.69,0.56,1.26,1.17,1.26c0.6,0,1.05-0.56,1.01-1.26c-0.05-0.69-0.57-1.26-1.17-1.26C382.56,126.18,382.11,126.74,382.15,127.43z",
  "M378.69,127.42c0.03,0.69,0.55,1.26,1.15,1.26c0.6,0,1.06-0.56,1.02-1.26c-0.04-0.69-0.55-1.26-1.15-1.26C379.11,126.17,378.65,126.73,378.69,127.42z",
  "M375.22,127.43c0.02,0.69,0.53,1.26,1.14,1.26c0.6,0,1.07-0.57,1.04-1.26c-0.03-0.69-0.54-1.25-1.14-1.25C375.66,126.17,375.2,126.73,375.22,127.43z",
  "M305.9,129.24c-0.15,0.7,0.21,1.25,0.81,1.22c0.6-0.03,1.21-0.63,1.36-1.33c0.15-0.7-0.22-1.24-0.81-1.21C306.67,127.95,306.06,128.54,305.9,129.24z",
  "M302.44,129.42c-0.16,0.7,0.19,1.25,0.8,1.22c0.6-0.03,1.22-0.63,1.38-1.33c0.16-0.7-0.2-1.24-0.8-1.21C303.22,128.12,302.6,128.72,302.44,129.42z",
  "M298.98,129.61c-0.17,0.7,0.18,1.25,0.78,1.22c0.6-0.03,1.23-0.63,1.39-1.34c0.16-0.7-0.19-1.24-0.78-1.21C299.78,128.31,299.15,128.9,298.98,129.61z",
  "M295.52,129.8c-0.18,0.7,0.16,1.25,0.77,1.21c0.6-0.03,1.23-0.64,1.41-1.34c0.17-0.7-0.17-1.24-0.77-1.21C296.33,128.5,295.7,129.1,295.52,129.8z",
  "M292.06,130.01c-0.19,0.71,0.15,1.25,0.75,1.21c0.6-0.04,1.24-0.64,1.42-1.34c0.18-0.7-0.15-1.24-0.75-1.21C292.89,128.7,292.25,129.3,292.06,130.01z",
  "M288.61,130.22c-0.2,0.71,0.13,1.25,0.73,1.21c0.6-0.04,1.25-0.64,1.44-1.35c0.19-0.71-0.14-1.24-0.74-1.21C289.45,128.91,288.8,129.51,288.61,130.22z",
  "M285.15,130.44c-0.2,0.71,0.12,1.25,0.72,1.21c0.6-0.04,1.25-0.65,1.45-1.35c0.2-0.71-0.12-1.25-0.72-1.21C286.01,129.13,285.36,129.74,285.15,130.44z",
  "M281.7,130.67c-0.21,0.71,0.1,1.25,0.7,1.21c0.6-0.04,1.26-0.65,1.47-1.36c0.21-0.71-0.11-1.25-0.7-1.21C282.57,129.36,281.91,129.96,281.7,130.67z",
  "M278.25,130.91c-0.22,0.71,0.09,1.25,0.69,1.21c0.6-0.04,1.26-0.65,1.48-1.36c0.22-0.71-0.09-1.25-0.69-1.21C279.13,129.6,278.47,130.2,278.25,130.91z",
  "M274.8,131.16c-0.23,0.71,0.07,1.25,0.67,1.21c0.6-0.04,1.27-0.66,1.49-1.37c0.22-0.71-0.08-1.25-0.67-1.2C275.7,129.84,275.03,130.45,274.8,131.16z",
  "M271.35,131.41c-0.24,0.71,0.06,1.25,0.66,1.21c0.6-0.05,1.28-0.66,1.51-1.37c0.23-0.71-0.06-1.25-0.66-1.2C272.26,130.09,271.59,130.7,271.35,131.41z",
  "M264.46,131.95c-0.25,0.71,0.03,1.26,0.63,1.21c0.6-0.05,1.29-0.67,1.54-1.38c0.25-0.71-0.03-1.25-0.63-1.2C265.4,130.62,264.71,131.23,264.46,131.95z",
  "M226.73,135.48c-0.34,0.73-0.13,1.27,0.47,1.21c0.59-0.06,1.35-0.71,1.68-1.43c0.33-0.73,0.12-1.26-0.47-1.2C227.82,134.11,227.07,134.75,226.73,135.48z",
  "M223.32,135.85c-0.34,0.73-0.14,1.27,0.45,1.21c0.59-0.06,1.35-0.71,1.69-1.44c0.34-0.73,0.14-1.27-0.45-1.2C224.42,134.48,223.66,135.12,223.32,135.85z",
  "M219.91,136.22c-0.35,0.73-0.16,1.27,0.44,1.21c0.59-0.07,1.36-0.71,1.7-1.44c0.35-0.73,0.15-1.27-0.44-1.2C221.02,134.85,220.26,135.49,219.91,136.22z",
  "M216.51,136.61c-0.36,0.73-0.17,1.27,0.42,1.21c0.59-0.07,1.36-0.72,1.71-1.45c0.35-0.73,0.16-1.27-0.43-1.2C217.63,135.23,216.86,135.88,216.51,136.61z",
  "M213.1,137c-0.37,0.73-0.18,1.28,0.41,1.21c0.59-0.07,1.37-0.72,1.73-1.45c0.36-0.73,0.18-1.27-0.41-1.2C214.24,135.62,213.47,136.27,213.1,137z",
  "M206.31,137.81c-0.38,0.74-0.21,1.28,0.38,1.21c0.59-0.07,1.37-0.73,1.75-1.46c0.37-0.74,0.2-1.27-0.38-1.2C207.47,136.42,206.69,137.08,206.31,137.81z",
  "M199.53,138.65c-0.39,0.74-0.23,1.28,0.36,1.21c0.59-0.07,1.38-0.74,1.77-1.48c0.39-0.74,0.23-1.28-0.36-1.2C200.71,137.25,199.92,137.91,199.53,138.65z",
  "M196.15,139.09c-0.4,0.74-0.24,1.28,0.35,1.21c0.59-0.08,1.39-0.74,1.78-1.48c0.39-0.74,0.24-1.28-0.35-1.21C197.34,137.68,196.55,138.34,196.15,139.09z",
  "M182.66,140.89c-0.42,0.75-0.29,1.29,0.3,1.21c0.59-0.08,1.4-0.75,1.82-1.5c0.42-0.75,0.28-1.29-0.3-1.21C183.89,139.47,183.08,140.14,182.66,140.89z",
  "M172.59,142.32c-0.44,0.75-0.32,1.3,0.26,1.21c0.58-0.09,1.41-0.77,1.84-1.52c0.43-0.75,0.32-1.29-0.26-1.21C173.85,140.89,173.03,141.57,172.59,142.32z",
  "M169.24,142.82c-0.44,0.76-0.33,1.3,0.25,1.21c0.58-0.09,1.41-0.77,1.85-1.52c0.44-0.75,0.33-1.3-0.25-1.21C170.51,141.38,169.68,142.06,169.24,142.82z",
  "M595.36,140.33c0.5,0.77,1.35,1.46,1.91,1.55c0.56,0.09,0.61-0.47,0.1-1.24c-0.5-0.77-1.36-1.46-1.91-1.55C594.91,139.01,594.87,139.56,595.36,140.33z",
  "M588.92,139.35c0.49,0.76,1.34,1.45,1.9,1.54c0.56,0.09,0.61-0.47,0.12-1.23c-0.49-0.77-1.34-1.45-1.9-1.54C588.49,138.03,588.43,138.58,588.92,139.35z",
  "M585.7,138.87c0.49,0.76,1.34,1.45,1.9,1.53c0.56,0.08,0.62-0.47,0.13-1.23c-0.49-0.76-1.34-1.45-1.9-1.53C585.27,137.56,585.21,138.11,585.7,138.87z",
  "M582.46,138.39c0.48,0.76,1.33,1.44,1.89,1.53c0.56,0.08,0.62-0.47,0.14-1.23c-0.48-0.76-1.33-1.44-1.89-1.52C582.05,137.09,581.98,137.63,582.46,138.39z",
  "M579.23,137.93c0.48,0.76,1.32,1.44,1.88,1.52c0.56,0.08,0.63-0.47,0.15-1.23c-0.48-0.76-1.32-1.44-1.88-1.52C578.82,136.62,578.75,137.17,579.23,137.93z",
  "M575.98,137.47c0.47,0.76,1.31,1.44,1.88,1.52c0.56,0.08,0.63-0.47,0.16-1.23c-0.48-0.76-1.32-1.43-1.88-1.51C575.59,136.16,575.51,136.71,575.98,137.47z",
  "M572.74,137.01c0.47,0.75,1.3,1.43,1.87,1.51c0.56,0.08,0.64-0.47,0.17-1.23c-0.47-0.76-1.31-1.43-1.87-1.51C572.35,135.71,572.27,136.26,572.74,137.01z",
  "M569.49,136.57c0.46,0.75,1.3,1.43,1.86,1.5c0.57,0.08,0.65-0.47,0.18-1.22c-0.47-0.75-1.3-1.43-1.86-1.5C569.11,135.27,569.02,135.82,569.49,136.57z",
  "M566.23,136.13c0.46,0.75,1.29,1.42,1.85,1.5c0.57,0.08,0.65-0.47,0.19-1.22c-0.46-0.75-1.29-1.42-1.85-1.5C565.86,134.83,565.77,135.38,566.23,136.13z",
  "M562.97,135.7c0.45,0.75,1.28,1.42,1.85,1.49c0.57,0.08,0.66-0.47,0.2-1.22c-0.46-0.75-1.28-1.42-1.85-1.49C562.61,134.4,562.52,134.95,562.97,135.7z",
  "M559.71,135.27c0.45,0.75,1.27,1.41,1.84,1.49c0.57,0.07,0.66-0.47,0.21-1.22c-0.45-0.75-1.27-1.41-1.84-1.48C559.36,133.98,559.26,134.53,559.71,135.27z",
  "M556.44,134.85c0.44,0.74,1.26,1.41,1.83,1.48c0.57,0.07,0.67-0.47,0.22-1.22c-0.45-0.75-1.26-1.41-1.83-1.48C556.1,133.57,556,134.11,556.44,134.85z",
  "M553.16,134.44c0.44,0.74,1.25,1.4,1.82,1.48c0.57,0.07,0.68-0.47,0.23-1.22c-0.44-0.74-1.25-1.4-1.82-1.47C552.83,133.16,552.73,133.7,553.16,134.44z",
  "M549.89,134.04c0.43,0.74,1.24,1.4,1.81,1.47c0.57,0.07,0.68-0.48,0.25-1.22c-0.43-0.74-1.25-1.4-1.81-1.47C549.57,132.76,549.46,133.3,549.89,134.04z",
  "M546.6,133.64c0.42,0.74,1.23,1.39,1.8,1.46c0.57,0.07,0.69-0.48,0.26-1.22c-0.43-0.74-1.23-1.39-1.8-1.46C546.3,132.36,546.18,132.9,546.6,133.64z",
  "M543.32,133.25c0.42,0.74,1.22,1.39,1.79,1.46c0.57,0.07,0.69-0.48,0.27-1.21c-0.42-0.74-1.22-1.39-1.79-1.45C543.02,131.97,542.9,132.52,543.32,133.25z",
  "M540.03,132.87c0.41,0.73,1.21,1.39,1.78,1.45c0.57,0.07,0.7-0.48,0.28-1.21c-0.42-0.74-1.21-1.38-1.78-1.45C539.74,131.59,539.62,132.13,540.03,132.87z",
  "M536.73,132.49c0.41,0.73,1.2,1.38,1.77,1.45c0.57,0.07,0.71-0.48,0.29-1.21c-0.41-0.73-1.2-1.38-1.77-1.44C536.46,131.22,536.33,131.76,536.73,132.49z",
  "M533.44,132.13c0.4,0.73,1.19,1.38,1.76,1.44c0.58,0.06,0.71-0.48,0.31-1.21c-0.4-0.73-1.19-1.38-1.76-1.44C533.17,130.86,533.04,131.39,533.44,132.13z",
  "M530.13,131.77c0.39,0.73,1.18,1.37,1.75,1.44c0.58,0.06,0.72-0.48,0.32-1.21c-0.4-0.73-1.18-1.37-1.75-1.43C529.88,130.5,529.74,131.04,530.13,131.77z",
  "M526.83,131.41c0.39,0.73,1.17,1.37,1.74,1.43c0.58,0.06,0.72-0.48,0.33-1.21c-0.39-0.73-1.17-1.37-1.74-1.43C526.59,130.15,526.44,130.68,526.83,131.41z",
  "M523.52,131.07c0.38,0.73,1.16,1.36,1.73,1.43c0.58,0.06,0.73-0.48,0.35-1.21c-0.39-0.73-1.16-1.36-1.73-1.42C523.29,129.8,523.14,130.34,523.52,131.07z",
  "M520.2,130.73c0.37,0.72,1.15,1.36,1.72,1.42c0.58,0.06,0.74-0.48,0.36-1.21c-0.38-0.73-1.15-1.36-1.72-1.42C519.99,129.47,519.83,130,520.2,130.73z",
  "M516.89,130.4c0.37,0.72,1.13,1.36,1.71,1.41c0.58,0.06,0.74-0.48,0.37-1.21c-0.37-0.72-1.14-1.35-1.71-1.41C516.69,129.14,516.52,129.67,516.89,130.4z",
  "M513.57,130.07c0.36,0.72,1.12,1.35,1.7,1.41c0.58,0.06,0.75-0.49,0.39-1.21c-0.37-0.72-1.13-1.35-1.7-1.4C513.38,128.81,513.21,129.35,513.57,130.07z",
  "M510.24,129.76c0.35,0.72,1.11,1.35,1.69,1.4c0.58,0.05,0.76-0.49,0.4-1.21c-0.36-0.72-1.11-1.35-1.69-1.4C510.07,128.5,509.89,129.04,510.24,129.76z",
  "M506.91,129.45c0.35,0.72,1.1,1.34,1.68,1.4c0.58,0.05,0.76-0.49,0.41-1.21c-0.35-0.72-1.1-1.34-1.68-1.39C506.75,128.19,506.57,128.73,506.91,129.45z",
  "M503.58,129.15c0.34,0.72,1.09,1.34,1.67,1.39c0.58,0.05,0.77-0.49,0.43-1.2c-0.34-0.72-1.09-1.34-1.66-1.39C503.44,127.89,503.24,128.43,503.58,129.15z",
  "M500.25,128.85c0.33,0.71,1.07,1.34,1.65,1.39c0.58,0.05,0.78-0.49,0.44-1.2c-0.34-0.72-1.08-1.33-1.65-1.38C500.11,127.6,499.92,128.14,500.25,128.85z",
  "M496.91,128.57c0.32,0.71,1.06,1.33,1.64,1.38c0.58,0.05,0.79-0.49,0.46-1.2c-0.33-0.71-1.06-1.33-1.64-1.38C496.79,127.32,496.58,127.85,496.91,128.57z",
  "M493.57,128.29c0.32,0.71,1.05,1.33,1.63,1.38c0.58,0.05,0.79-0.49,0.47-1.2c-0.32-0.71-1.05-1.33-1.63-1.37C493.46,127.04,493.25,127.58,493.57,128.29z",
  "M490.22,128.02c0.31,0.71,1.03,1.33,1.62,1.37c0.58,0.05,0.8-0.49,0.48-1.2c-0.32-0.71-1.04-1.32-1.62-1.37C490.13,126.77,489.91,127.31,490.22,128.02z",
  "M486.88,127.76c0.3,0.71,1.02,1.32,1.6,1.37c0.58,0.05,0.81-0.49,0.5-1.2c-0.31-0.71-1.03-1.32-1.6-1.36C486.8,126.51,486.57,127.05,486.88,127.76z",
  "M483.52,127.5c0.29,0.71,1.01,1.32,1.59,1.36c0.58,0.04,0.81-0.5,0.51-1.2c-0.3-0.71-1.01-1.31-1.59-1.36C483.46,126.26,483.23,126.79,483.52,127.5z",
  "M480.17,127.25c0.29,0.71,0.99,1.31,1.58,1.36c0.58,0.04,0.82-0.5,0.53-1.2c-0.29-0.71-1-1.31-1.58-1.35C480.12,126.01,479.88,126.55,480.17,127.25z",
  "M476.81,127.01c0.28,0.7,0.98,1.31,1.57,1.35c0.59,0.04,0.83-0.5,0.54-1.2c-0.28-0.71-0.98-1.31-1.56-1.35C476.78,125.77,476.54,126.31,476.81,127.01z",
  "M473.46,126.78c0.27,0.7,0.97,1.31,1.55,1.35c0.59,0.04,0.83-0.5,0.56-1.2c-0.28-0.7-0.97-1.3-1.55-1.34C473.43,125.54,473.18,126.08,473.46,126.78z",
  "M470.09,126.56c0.26,0.7,0.95,1.3,1.54,1.34c0.59,0.04,0.84-0.5,0.57-1.2c-0.27-0.7-0.96-1.3-1.54-1.34C470.09,125.32,469.83,125.86,470.09,126.56z",
  "M466.73,126.34c0.26,0.7,0.94,1.3,1.52,1.34c0.59,0.04,0.85-0.5,0.59-1.2c-0.26-0.7-0.94-1.3-1.52-1.33C466.74,125.11,466.47,125.64,466.73,126.34z",
  "M463.36,126.13c0.25,0.7,0.92,1.3,1.51,1.33c0.59,0.04,0.86-0.5,0.6-1.2c-0.25-0.7-0.93-1.29-1.51-1.33C463.38,124.9,463.11,125.43,463.36,126.13z",
  "M459.99,125.93c0.24,0.7,0.91,1.3,1.5,1.33c0.59,0.04,0.86-0.51,0.62-1.2c-0.25-0.7-0.91-1.29-1.5-1.32C460.03,124.7,459.75,125.23,459.99,125.93z",
  "M456.62,125.74c0.23,0.7,0.9,1.29,1.48,1.33c0.59,0.03,0.87-0.51,0.64-1.21c-0.24-0.7-0.9-1.29-1.48-1.32C456.67,124.51,456.39,125.04,456.62,125.74z",
  "M453.24,125.55c0.22,0.7,0.88,1.29,1.47,1.32c0.59,0.03,0.88-0.51,0.65-1.21c-0.23-0.7-0.89-1.28-1.47-1.32C453.31,124.32,453.02,124.86,453.24,125.55z",
  "M449.86,125.38c0.21,0.7,0.87,1.29,1.46,1.32c0.59,0.03,0.89-0.51,0.67-1.21c-0.22-0.7-0.87-1.28-1.45-1.31C449.95,124.15,449.65,124.68,449.86,125.38z",
  "M446.48,125.21c0.21,0.69,0.85,1.28,1.44,1.31c0.59,0.03,0.9-0.51,0.68-1.21c-0.21-0.69-0.86-1.28-1.44-1.31C446.58,123.98,446.28,124.52,446.48,125.21z",
  "M443.1,125.05c0.2,0.69,0.84,1.28,1.43,1.31c0.59,0.03,0.9-0.51,0.7-1.21c-0.2-0.69-0.84-1.28-1.43-1.3C443.22,123.82,442.9,124.35,443.1,125.05z",
  "M439.72,124.9c0.19,0.69,0.82,1.28,1.41,1.3c0.59,0.03,0.91-0.52,0.71-1.21c-0.2-0.69-0.83-1.27-1.41-1.3C439.85,123.67,439.53,124.2,439.72,124.9z",
  "M436.33,124.75c0.18,0.69,0.81,1.27,1.4,1.3c0.59,0.03,0.92-0.52,0.73-1.21c-0.19-0.69-0.81-1.27-1.4-1.29C436.48,123.52,436.15,124.06,436.33,124.75z",
  "M432.94,124.61c0.17,0.69,0.79,1.27,1.38,1.3c0.59,0.02,0.93-0.52,0.75-1.21c-0.18-0.69-0.8-1.27-1.38-1.29C433.1,123.38,432.77,123.92,432.94,124.61z",
  "M429.55,124.48c0.16,0.69,0.78,1.27,1.37,1.29c0.59,0.02,0.93-0.52,0.76-1.21c-0.17-0.69-0.78-1.27-1.37-1.29C429.73,123.26,429.39,123.79,429.55,124.48z",
  "M426.16,124.36c0.16,0.69,0.76,1.27,1.35,1.29c0.59,0.02,0.94-0.52,0.78-1.21c-0.16-0.69-0.77-1.26-1.35-1.28C426.35,123.14,426,123.67,426.16,124.36z",
  "M422.76,124.25c0.15,0.69,0.75,1.27,1.34,1.28c0.59,0.02,0.95-0.53,0.79-1.21c-0.15-0.69-0.75-1.26-1.34-1.28C422.97,123.02,422.62,123.56,422.76,124.25z",
  "M415.97,124.05c0.13,0.69,0.71,1.26,1.31,1.28c0.59,0.02,0.96-0.53,0.83-1.22c-0.14-0.69-0.72-1.26-1.31-1.27C416.21,122.82,415.84,123.36,415.97,124.05z",
  "M412.57,123.96c0.12,0.69,0.7,1.26,1.29,1.27c0.59,0.02,0.97-0.53,0.84-1.22c-0.13-0.69-0.7-1.25-1.29-1.27C412.83,122.73,412.45,123.28,412.57,123.96z",
  "M409.17,123.88c0.11,0.69,0.68,1.26,1.28,1.27c0.59,0.01,0.98-0.53,0.86-1.22c-0.12-0.69-0.69-1.25-1.28-1.26C409.44,122.65,409.06,123.2,409.17,123.88z",
  "M405.77,123.81c0.1,0.69,0.67,1.25,1.26,1.27c0.59,0.01,0.99-0.54,0.88-1.22c-0.11-0.69-0.67-1.25-1.26-1.26C406.06,122.58,405.66,123.12,405.77,123.81z",
  "M402.36,123.75c0.09,0.69,0.65,1.25,1.25,1.26c0.59,0.01,0.99-0.54,0.89-1.22c-0.1-0.69-0.66-1.25-1.25-1.26C402.67,122.52,402.27,123.06,402.36,123.75z",
  "M398.96,123.69c0.09,0.68,0.64,1.25,1.23,1.26c0.59,0.01,1-0.54,0.91-1.23c-0.09-0.68-0.64-1.25-1.23-1.25C399.28,122.46,398.87,123.01,398.96,123.69z",
  "M395.55,123.64c0.08,0.68,0.62,1.25,1.21,1.26c0.59,0.01,1.01-0.54,0.93-1.23c-0.08-0.68-0.63-1.24-1.21-1.25C395.89,122.41,395.47,122.96,395.55,123.64z",
  "M392.14,123.6c0.07,0.68,0.6,1.25,1.2,1.25c0.59,0.01,1.02-0.55,0.94-1.23c-0.07-0.68-0.61-1.24-1.2-1.25C392.5,122.37,392.08,122.92,392.14,123.6z",
  "M388.73,123.57c0.06,0.68,0.59,1.25,1.18,1.25c0.59,0.01,1.02-0.55,0.96-1.23c-0.07-0.68-0.59-1.24-1.18-1.25C389.1,122.34,388.68,122.89,388.73,123.57z",
  "M385.32,123.55c0.05,0.68,0.57,1.24,1.17,1.25c0.59,0,1.03-0.55,0.97-1.23c-0.06-0.68-0.58-1.24-1.17-1.24C385.71,122.32,385.27,122.87,385.32,123.55z",
  "M381.91,123.54c0.04,0.68,0.56,1.24,1.15,1.24c0.6,0,1.04-0.55,0.99-1.24c-0.05-0.68-0.56-1.24-1.15-1.24C382.32,122.3,381.87,122.85,381.91,123.54z",
  "M313.6,124.97c-0.14,0.69,0.24,1.23,0.83,1.2c0.6-0.03,1.18-0.61,1.31-1.3c0.13-0.69-0.24-1.22-0.83-1.2C314.32,123.71,313.74,124.28,313.6,124.97z",
  "M310.18,125.13c-0.14,0.69,0.22,1.23,0.82,1.2c0.6-0.03,1.19-0.61,1.33-1.3c0.14-0.69-0.23-1.22-0.82-1.2C310.92,123.86,310.33,124.44,310.18,125.13z",
  "M306.77,125.3c-0.15,0.69,0.2,1.23,0.8,1.2c0.6-0.03,1.2-0.62,1.34-1.31c0.15-0.69-0.21-1.23-0.8-1.2C307.53,124.03,306.92,124.61,306.77,125.3z",
  "M303.36,125.47c-0.16,0.69,0.19,1.23,0.78,1.2c0.6-0.03,1.2-0.62,1.36-1.31c0.16-0.69-0.2-1.23-0.78-1.2C304.13,124.2,303.52,124.78,303.36,125.47z",
  "M299.95,125.66c-0.17,0.69,0.17,1.23,0.77,1.2c0.59-0.03,1.21-0.62,1.38-1.32c0.16-0.69-0.18-1.23-0.77-1.19C300.73,124.38,300.12,124.96,299.95,125.66z",
  "M296.53,125.85c-0.18,0.69,0.16,1.23,0.75,1.2c0.59-0.03,1.22-0.63,1.39-1.32c0.17-0.69-0.16-1.23-0.75-1.19C297.34,124.57,296.71,125.16,296.53,125.85z",
  "M293.12,126.05c-0.19,0.7,0.14,1.23,0.74,1.2c0.59-0.04,1.22-0.63,1.41-1.32c0.18-0.69-0.15-1.23-0.74-1.19C293.94,124.76,293.31,125.35,293.12,126.05z",
  "M289.72,126.26c-0.2,0.7,0.13,1.23,0.72,1.2c0.59-0.04,1.23-0.63,1.42-1.33c0.19-0.7-0.13-1.23-0.72-1.19C290.55,124.97,289.91,125.56,289.72,126.26z",
  "M286.31,126.47c-0.2,0.7,0.11,1.23,0.71,1.2c0.59-0.04,1.24-0.64,1.43-1.33c0.2-0.7-0.12-1.23-0.71-1.19C287.15,125.18,286.51,125.78,286.31,126.47z",
  "M282.9,126.7c-0.21,0.7,0.1,1.23,0.69,1.2c0.59-0.04,1.24-0.64,1.45-1.34c0.21-0.7-0.1-1.23-0.69-1.19C283.76,125.4,283.11,126,282.9,126.7z",
  "M279.5,126.93c-0.22,0.7,0.08,1.24,0.67,1.19c0.59-0.04,1.25-0.64,1.46-1.34c0.21-0.7-0.09-1.23-0.68-1.19C280.37,125.63,279.72,126.23,279.5,126.93z",
  "M276.09,127.17c-0.23,0.7,0.07,1.24,0.66,1.19c0.59-0.04,1.26-0.65,1.48-1.35c0.22-0.7-0.07-1.23-0.66-1.19C276.98,125.87,276.32,126.47,276.09,127.17z",
  "M272.69,127.42c-0.24,0.7,0.05,1.24,0.64,1.19c0.59-0.04,1.26-0.65,1.49-1.35c0.23-0.7-0.06-1.23-0.64-1.19C273.6,126.12,272.93,126.72,272.69,127.42z",
  "M269.29,127.68c-0.25,0.7,0.04,1.24,0.63,1.19c0.59-0.05,1.27-0.65,1.51-1.36c0.24-0.7-0.04-1.23-0.63-1.19C270.21,126.37,269.54,126.97,269.29,127.68z",
  "M265.89,127.94c-0.25,0.7,0.02,1.24,0.61,1.19c0.59-0.05,1.27-0.66,1.52-1.36c0.25-0.7-0.03-1.23-0.61-1.19C266.82,126.63,266.14,127.24,265.89,127.94z",
  "M262.49,128.21c-0.26,0.71,0.01,1.24,0.6,1.19c0.59-0.05,1.28-0.66,1.53-1.37c0.26-0.7-0.01-1.24-0.6-1.19C263.44,126.9,262.75,127.51,262.49,128.21z",
  "M221.89,132.11c-0.35,0.72-0.16,1.26,0.43,1.19c0.59-0.06,1.34-0.7,1.69-1.43c0.35-0.72,0.15-1.25-0.43-1.19C223,130.75,222.25,131.39,221.89,132.11z",
  "M208.45,133.66c-0.38,0.73-0.21,1.27,0.37,1.2c0.58-0.07,1.36-0.72,1.73-1.45c0.37-0.73,0.2-1.26-0.38-1.19C209.6,132.29,208.82,132.93,208.45,133.66z",
  "M201.74,134.49c-0.39,0.73-0.23,1.27,0.35,1.2c0.58-0.07,1.37-0.73,1.76-1.46c0.39-0.73,0.23-1.27-0.35-1.2C202.92,133.1,202.13,133.75,201.74,134.49z",
  "M195.05,135.34c-0.4,0.74-0.26,1.28,0.32,1.2c0.58-0.08,1.38-0.74,1.78-1.47c0.4-0.74,0.25-1.27-0.32-1.2C196.25,133.94,195.45,134.6,195.05,135.34z",
  "M185.04,136.67c-0.42,0.74-0.29,1.28,0.29,1.2c0.58-0.08,1.39-0.75,1.81-1.49c0.42-0.74,0.29-1.28-0.29-1.2C186.27,135.27,185.46,135.93,185.04,136.67z",
  "M566.88,132.33c0.46,0.75,1.29,1.41,1.85,1.49c0.56,0.08,0.64-0.47,0.17-1.22c-0.46-0.75-1.29-1.41-1.85-1.49C566.49,131.04,566.41,131.59,566.88,132.33z",
  "M563.65,131.9c0.46,0.74,1.28,1.41,1.84,1.48c0.56,0.07,0.64-0.47,0.18-1.22c-0.46-0.75-1.28-1.41-1.84-1.48C563.28,130.62,563.19,131.16,563.65,131.9z",
  "M544.21,129.49c0.42,0.73,1.22,1.38,1.79,1.45c0.57,0.07,0.68-0.47,0.25-1.21c-0.43-0.73-1.23-1.38-1.79-1.45C543.9,128.22,543.79,128.75,544.21,129.49z",
  "M540.96,129.11c0.42,0.73,1.21,1.38,1.78,1.44c0.57,0.07,0.68-0.47,0.26-1.21c-0.42-0.73-1.22-1.37-1.78-1.44C540.66,127.84,540.54,128.38,540.96,129.11z",
  "M537.7,128.74c0.41,0.73,1.2,1.37,1.77,1.44c0.57,0.07,0.69-0.48,0.27-1.2c-0.42-0.73-1.21-1.37-1.77-1.43C537.41,127.47,537.29,128.01,537.7,128.74z",
  "M534.44,128.37c0.4,0.73,1.19,1.37,1.76,1.43c0.57,0.06,0.7-0.48,0.29-1.2c-0.41-0.73-1.2-1.36-1.76-1.43C534.16,127.11,534.04,127.65,534.44,128.37z",
  "M527.91,127.66c0.39,0.72,1.17,1.36,1.74,1.42c0.57,0.06,0.71-0.48,0.31-1.2c-0.4-0.72-1.17-1.36-1.74-1.42C527.66,126.41,527.52,126.94,527.91,127.66z",
  "M524.64,127.32c0.39,0.72,1.16,1.35,1.73,1.41c0.57,0.06,0.71-0.48,0.32-1.2c-0.39-0.72-1.16-1.35-1.73-1.41C524.4,126.07,524.26,126.6,524.64,127.32z",
  "M521.37,126.99c0.38,0.72,1.15,1.35,1.72,1.41c0.57,0.06,0.72-0.48,0.34-1.2c-0.38-0.72-1.15-1.35-1.72-1.4C521.14,125.73,520.99,126.27,521.37,126.99z",
  "M518.09,126.66c0.37,0.72,1.14,1.34,1.71,1.4c0.57,0.06,0.73-0.48,0.35-1.2c-0.38-0.72-1.14-1.34-1.71-1.4C517.88,125.41,517.72,125.94,518.09,126.66z",
  "M514.81,126.34c0.37,0.71,1.13,1.34,1.7,1.4c0.57,0.06,0.73-0.48,0.36-1.2c-0.37-0.72-1.13-1.34-1.7-1.39C514.61,125.09,514.45,125.62,514.81,126.34z",
  "M511.53,126.02c0.36,0.71,1.11,1.34,1.69,1.39c0.57,0.06,0.74-0.48,0.38-1.19c-0.36-0.71-1.12-1.33-1.68-1.39C511.34,124.78,511.17,125.31,511.53,126.02z",
  "M508.25,125.72c0.35,0.71,1.1,1.33,1.67,1.39c0.57,0.05,0.75-0.48,0.39-1.19c-0.36-0.71-1.11-1.33-1.67-1.38C508.07,124.47,507.9,125.01,508.25,125.72z",
  "M504.96,125.42c0.34,0.71,1.09,1.33,1.66,1.38c0.57,0.05,0.75-0.48,0.4-1.19c-0.35-0.71-1.09-1.32-1.66-1.38C504.8,124.18,504.61,124.71,504.96,125.42z",
  "M501.67,125.12c0.34,0.71,1.08,1.32,1.65,1.37c0.57,0.05,0.76-0.48,0.42-1.19c-0.34-0.71-1.08-1.32-1.65-1.37C501.52,123.89,501.33,124.42,501.67,125.12z",
  "M498.37,124.84c0.33,0.71,1.06,1.32,1.64,1.37c0.57,0.05,0.77-0.48,0.43-1.19c-0.34-0.71-1.07-1.32-1.64-1.36C498.24,123.6,498.04,124.13,498.37,124.84z",
  "M495.08,124.56c0.32,0.7,1.05,1.32,1.63,1.36c0.57,0.05,0.77-0.49,0.45-1.19c-0.33-0.7-1.06-1.31-1.62-1.36C494.95,123.33,494.75,123.86,495.08,124.56z",
  "M491.78,124.29c0.32,0.7,1.04,1.31,1.61,1.36c0.57,0.05,0.78-0.49,0.46-1.19c-0.32-0.7-1.04-1.31-1.61-1.35C491.67,123.06,491.46,123.59,491.78,124.29z",
  "M488.47,124.03c0.31,0.7,1.02,1.31,1.6,1.35c0.58,0.05,0.79-0.49,0.47-1.19c-0.31-0.7-1.03-1.3-1.6-1.35C488.38,122.8,488.17,123.33,488.47,124.03z",
  "M485.17,123.78c0.3,0.7,1.01,1.3,1.59,1.35c0.58,0.04,0.79-0.49,0.49-1.19c-0.31-0.7-1.02-1.3-1.59-1.34C485.09,122.55,484.87,123.08,485.17,123.78z",
  "M481.86,123.53c0.29,0.7,1,1.3,1.58,1.34c0.58,0.04,0.8-0.49,0.5-1.19c-0.3-0.7-1-1.3-1.57-1.34C481.79,122.3,481.57,122.83,481.86,123.53z",
  "M478.55,123.29c0.29,0.7,0.99,1.3,1.56,1.34c0.58,0.04,0.81-0.49,0.52-1.19c-0.29-0.7-0.99-1.29-1.56-1.33C478.5,122.07,478.27,122.59,478.55,123.29z",
  "M475.24,123.06c0.28,0.69,0.97,1.29,1.55,1.33c0.58,0.04,0.82-0.49,0.53-1.19c-0.28-0.7-0.98-1.29-1.55-1.33C475.2,121.84,474.96,122.36,475.24,123.06z",
  "M471.92,122.83c0.27,0.69,0.96,1.29,1.54,1.33c0.58,0.04,0.82-0.49,0.55-1.19c-0.28-0.69-0.96-1.29-1.53-1.32C471.9,121.61,471.65,122.14,471.92,122.83z",
  "M468.61,122.62c0.26,0.69,0.94,1.29,1.52,1.32c0.58,0.04,0.83-0.5,0.56-1.19c-0.27-0.69-0.95-1.28-1.52-1.32C468.6,121.4,468.34,121.93,468.61,122.62z",
  "M465.29,122.41c0.25,0.69,0.93,1.28,1.51,1.32c0.58,0.04,0.84-0.5,0.58-1.19c-0.26-0.69-0.93-1.28-1.51-1.31C465.29,121.19,465.03,121.72,465.29,122.41z",
  "M461.96,122.21c0.25,0.69,0.92,1.28,1.49,1.31c0.58,0.04,0.84-0.5,0.59-1.19c-0.25-0.69-0.92-1.27-1.49-1.31C461.98,120.99,461.72,121.52,461.96,122.21z",
  "M458.64,122.01c0.24,0.69,0.9,1.28,1.48,1.31c0.58,0.03,0.85-0.5,0.61-1.19c-0.24-0.69-0.91-1.27-1.48-1.3C458.67,120.8,458.4,121.33,458.64,122.01z",
  "M438.65,121.01c0.19,0.68,0.81,1.26,1.4,1.28c0.58,0.03,0.9-0.51,0.7-1.19c-0.19-0.68-0.82-1.25-1.39-1.28C438.77,119.8,438.46,120.33,438.65,121.01z",
  "M435.31,120.87c0.18,0.68,0.8,1.26,1.38,1.28c0.58,0.02,0.9-0.51,0.72-1.19c-0.19-0.68-0.8-1.25-1.38-1.27C435.45,119.66,435.13,120.19,435.31,120.87z",
  "M391.76,119.77c0.07,0.67,0.6,1.23,1.18,1.23c0.59,0.01,1-0.54,0.93-1.21c-0.07-0.67-0.6-1.22-1.18-1.23C392.11,118.56,391.69,119.09,391.76,119.77z",
  "M321.1,120.82c-0.12,0.68,0.26,1.21,0.85,1.19c0.59-0.02,1.15-0.59,1.27-1.27c0.11-0.68-0.27-1.21-0.85-1.18C321.78,119.58,321.22,120.14,321.1,120.82z",
  "M317.73,120.96c-0.13,0.68,0.25,1.21,0.83,1.19c0.59-0.02,1.16-0.6,1.28-1.27c0.12-0.68-0.25-1.21-0.83-1.18C318.43,119.71,317.86,120.28,317.73,120.96z",
  "M314.37,121.1c-0.14,0.68,0.23,1.21,0.82,1.19c0.59-0.03,1.17-0.6,1.3-1.28c0.13-0.68-0.24-1.21-0.82-1.18C315.08,119.86,314.5,120.42,314.37,121.1z",
  "M311,121.26c-0.14,0.68,0.21,1.21,0.8,1.18c0.59-0.03,1.17-0.6,1.31-1.28c0.14-0.68-0.22-1.21-0.8-1.18C311.73,120.01,311.14,120.58,311,121.26z",
  "M307.63,121.42c-0.15,0.68,0.2,1.21,0.79,1.18c0.59-0.03,1.18-0.61,1.33-1.29c0.15-0.68-0.21-1.21-0.79-1.18C308.38,120.17,307.79,120.74,307.63,121.42z",
  "M304.27,121.59c-0.16,0.68,0.18,1.21,0.77,1.18c0.59-0.03,1.19-0.61,1.34-1.29c0.16-0.68-0.19-1.21-0.77-1.18C305.03,120.33,304.43,120.91,304.27,121.59z",
  "M300.91,121.77c-0.17,0.68,0.17,1.21,0.75,1.18c0.59-0.03,1.19-0.61,1.36-1.29c0.16-0.68-0.17-1.21-0.75-1.18C301.68,120.51,301.08,121.09,300.91,121.77z",
  "M297.54,121.95c-0.18,0.68,0.15,1.21,0.74,1.18c0.59-0.03,1.2-0.62,1.37-1.3c0.17-0.68-0.16-1.21-0.74-1.18C298.34,120.69,297.72,121.27,297.54,121.95z",
  "M294.18,122.15c-0.19,0.68,0.14,1.21,0.72,1.18c0.59-0.03,1.21-0.62,1.39-1.3c0.18-0.68-0.14-1.21-0.72-1.18C294.99,120.88,294.37,121.46,294.18,122.15z",
  "M290.82,122.35c-0.2,0.69,0.12,1.22,0.71,1.18c0.59-0.04,1.22-0.62,1.4-1.31c0.19-0.69-0.13-1.21-0.71-1.17C291.64,121.08,291.01,121.66,290.82,122.35z",
  "M287.46,122.56c-0.2,0.69,0.11,1.22,0.69,1.18c0.59-0.04,1.22-0.63,1.42-1.31c0.2-0.69-0.11-1.21-0.69-1.17C288.3,121.29,287.66,121.87,287.46,122.56z",
  "M284.1,122.78c-0.21,0.69,0.09,1.22,0.68,1.18c0.59-0.04,1.23-0.63,1.43-1.32c0.21-0.69-0.1-1.21-0.68-1.17C284.95,121.5,284.31,122.09,284.1,122.78z",
  "M280.74,123c-0.22,0.69,0.08,1.22,0.66,1.18c0.59-0.04,1.23-0.63,1.45-1.32c0.21-0.69-0.08-1.21-0.66-1.17C281.61,121.73,280.96,122.31,280.74,123z",
  "M277.38,123.24c-0.23,0.69,0.06,1.22,0.65,1.18c0.59-0.04,1.24-0.64,1.46-1.33c0.22-0.69-0.07-1.21-0.65-1.17C278.26,121.96,277.61,122.55,277.38,123.24z",
  "M274.03,123.48c-0.24,0.69,0.05,1.22,0.63,1.18c0.58-0.04,1.25-0.64,1.48-1.33c0.23-0.69-0.05-1.21-0.63-1.17C274.92,122.2,274.26,122.79,274.03,123.48z",
  "M270.67,123.73c-0.24,0.69,0.03,1.22,0.62,1.18c0.58-0.04,1.25-0.64,1.49-1.34c0.24-0.69-0.04-1.22-0.62-1.17C271.58,122.44,270.92,123.04,270.67,123.73z",
  "M267.32,123.99c-0.25,0.69,0.02,1.22,0.6,1.18c0.58-0.05,1.26-0.65,1.51-1.34c0.25-0.69-0.02-1.22-0.6-1.17C268.24,122.7,267.57,123.29,267.32,123.99z",
  "M263.96,124.25c-0.26,0.7,0,1.22,0.59,1.18c0.58-0.05,1.26-0.65,1.52-1.35c0.25-0.7-0.01-1.22-0.59-1.17C264.9,122.96,264.23,123.56,263.96,124.25z",
  "M260.61,124.52c-0.27,0.7-0.01,1.23,0.57,1.18c0.58-0.05,1.27-0.65,1.53-1.35c0.26-0.7,0.01-1.22-0.57-1.17C261.57,123.23,260.88,123.83,260.61,124.52z",
  "M257.26,124.81c-0.28,0.7-0.03,1.23,0.56,1.18c0.58-0.05,1.28-0.66,1.55-1.36c0.27-0.7,0.02-1.22-0.56-1.17C258.23,123.5,257.54,124.11,257.26,124.81z",
  "M253.92,125.09c-0.28,0.7-0.04,1.23,0.54,1.18c0.58-0.05,1.28-0.66,1.56-1.36c0.28-0.7,0.03-1.22-0.54-1.17C254.9,123.79,254.2,124.39,253.92,125.09z",
  "M250.57,125.39c-0.29,0.7-0.06,1.23,0.53,1.18c0.58-0.05,1.29-0.67,1.57-1.37c0.29-0.7,0.05-1.23-0.53-1.17C251.57,124.08,250.86,124.69,250.57,125.39z",
  "M247.23,125.7c-0.3,0.7-0.07,1.23,0.51,1.18c0.58-0.05,1.29-0.67,1.59-1.37c0.29-0.7,0.06-1.23-0.51-1.17C248.24,124.38,247.53,124.99,247.23,125.7z",
  "M243.88,126.01c-0.31,0.71-0.08,1.23,0.5,1.18c0.58-0.06,1.3-0.67,1.6-1.38c0.3-0.7,0.08-1.23-0.5-1.17C244.91,124.69,244.19,125.3,243.88,126.01z",
  "M233.87,126.99c-0.33,0.71-0.12,1.24,0.46,1.18c0.58-0.06,1.31-0.68,1.64-1.39c0.32-0.71,0.12-1.23-0.46-1.18C234.93,125.66,234.2,126.28,233.87,126.99z",
  "M230.53,127.34c-0.34,0.71-0.14,1.24,0.44,1.18c0.58-0.06,1.32-0.69,1.65-1.4c0.33-0.71,0.13-1.24-0.44-1.18C231.61,126,230.87,126.62,230.53,127.34z",
  "M227.2,127.69c-0.34,0.71-0.15,1.24,0.43,1.18c0.58-0.06,1.32-0.69,1.66-1.4c0.34-0.71,0.14-1.24-0.43-1.18C228.29,126.35,227.54,126.97,227.2,127.69z",
  "M223.87,128.05c-0.35,0.72-0.16,1.25,0.42,1.18c0.58-0.06,1.33-0.7,1.67-1.41c0.34-0.71,0.16-1.24-0.42-1.18C224.97,126.7,224.22,127.33,223.87,128.05z",
  "M220.54,128.41c-0.36,0.72-0.18,1.25,0.4,1.18c0.58-0.06,1.33-0.7,1.69-1.42c0.35-0.72,0.17-1.24-0.4-1.18C221.65,127.06,220.9,127.69,220.54,128.41z",
  "M217.22,128.79c-0.36,0.72-0.19,1.25,0.39,1.18c0.58-0.07,1.34-0.7,1.7-1.42c0.36-0.72,0.18-1.25-0.39-1.18C218.34,127.43,217.58,128.07,217.22,128.79z",
  "M213.9,129.17c-0.37,0.72-0.2,1.25,0.38,1.19c0.58-0.07,1.34-0.71,1.71-1.43c0.36-0.72,0.2-1.25-0.38-1.18C215.03,127.81,214.27,128.45,213.9,129.17z",
  "M210.58,129.56c-0.38,0.72-0.21,1.26,0.36,1.19c0.58-0.07,1.35-0.71,1.72-1.43c0.37-0.72,0.21-1.25-0.37-1.18C211.72,128.2,210.95,128.83,210.58,129.56z",
  "M207.26,129.95c-0.38,0.72-0.23,1.26,0.35,1.19c0.58-0.07,1.35-0.71,1.73-1.44c0.38-0.72,0.22-1.25-0.35-1.18C208.42,128.59,207.64,129.23,207.26,129.95z",
  "M203.94,130.36c-0.39,0.73-0.24,1.26,0.34,1.19c0.58-0.07,1.36-0.72,1.74-1.44c0.38-0.73,0.23-1.25-0.34-1.19C205.11,128.99,204.33,129.63,203.94,130.36z",
  "M200.63,130.77c-0.4,0.73-0.25,1.26,0.33,1.19c0.58-0.07,1.36-0.72,1.75-1.45c0.39-0.73,0.24-1.26-0.33-1.19C201.81,129.39,201.03,130.04,200.63,130.77z",
  "M528.93,123.94c0.4,0.72,1.17,1.35,1.74,1.41c0.56,0.06,0.69-0.47,0.29-1.19c-0.4-0.72-1.18-1.34-1.74-1.4C528.66,122.7,528.53,123.23,528.93,123.94z",
  "M525.7,123.61c0.39,0.71,1.16,1.34,1.73,1.4c0.56,0.06,0.7-0.47,0.3-1.19c-0.39-0.72-1.17-1.34-1.72-1.4C525.44,122.36,525.31,122.89,525.7,123.61z",
  "M522.46,123.27c0.38,0.71,1.15,1.34,1.72,1.4c0.56,0.06,0.7-0.48,0.32-1.19c-0.39-0.71-1.16-1.33-1.71-1.39C522.22,122.03,522.08,122.56,522.46,123.27z",
  "M519.23,122.95c0.38,0.71,1.14,1.33,1.7,1.39c0.56,0.06,0.71-0.48,0.33-1.19c-0.38-0.71-1.14-1.33-1.7-1.39C519,121.71,518.85,122.24,519.23,122.95z",
  "M515.99,122.63c0.37,0.71,1.13,1.33,1.69,1.38c0.56,0.06,0.72-0.48,0.34-1.19c-0.38-0.71-1.13-1.33-1.69-1.38C515.77,121.4,515.62,121.92,515.99,122.63z",
  "M512.75,122.32c0.36,0.71,1.12,1.32,1.68,1.38c0.56,0.05,0.72-0.48,0.35-1.18c-0.37-0.71-1.12-1.32-1.68-1.37C512.55,121.09,512.39,121.62,512.75,122.32z",
  "M509.51,122.02c0.36,0.7,1.11,1.32,1.67,1.37c0.56,0.05,0.73-0.48,0.37-1.18c-0.36-0.71-1.11-1.32-1.67-1.37C509.31,120.79,509.15,121.32,509.51,122.02z",
  "M506.26,121.72c0.35,0.7,1.09,1.31,1.66,1.37c0.57,0.05,0.74-0.48,0.38-1.18c-0.36-0.7-1.1-1.31-1.66-1.36C506.08,120.5,505.91,121.02,506.26,121.72z",
  "M503.01,121.43c0.34,0.7,1.08,1.31,1.65,1.36c0.57,0.05,0.74-0.48,0.39-1.18c-0.35-0.7-1.08-1.31-1.65-1.36C502.85,120.21,502.67,120.73,503.01,121.43z",
  "M499.76,121.15c0.34,0.7,1.07,1.31,1.63,1.36c0.57,0.05,0.75-0.48,0.41-1.18c-0.34-0.7-1.07-1.3-1.63-1.35C499.61,119.93,499.42,120.45,499.76,121.15z",
  "M496.51,120.88c0.33,0.7,1.06,1.3,1.62,1.35c0.57,0.05,0.76-0.48,0.42-1.18c-0.33-0.7-1.06-1.3-1.62-1.35C496.37,119.66,496.18,120.18,496.51,120.88z",
  "M493.25,120.61c0.32,0.69,1.04,1.3,1.61,1.34c0.57,0.05,0.76-0.48,0.43-1.18c-0.33-0.7-1.05-1.29-1.61-1.34C493.13,119.39,492.93,119.92,493.25,120.61z",
  "M489.99,120.35c0.31,0.69,1.03,1.29,1.6,1.34c0.57,0.05,0.77-0.48,0.45-1.18c-0.32-0.69-1.03-1.29-1.6-1.33C489.88,119.14,489.68,119.66,489.99,120.35z",
  "M486.73,120.1c0.31,0.69,1.02,1.29,1.58,1.33c0.57,0.04,0.78-0.48,0.46-1.18c-0.31-0.69-1.02-1.29-1.58-1.33C486.63,118.88,486.43,119.41,486.73,120.1z",
  "M483.47,119.85c0.3,0.69,1,1.29,1.57,1.33c0.57,0.04,0.78-0.48,0.48-1.17c-0.3-0.69-1.01-1.28-1.57-1.32C483.39,118.64,483.17,119.16,483.47,119.85z",
  "M480.21,119.61c0.29,0.69,0.99,1.28,1.56,1.32c0.57,0.04,0.79-0.49,0.49-1.17c-0.3-0.69-0.99-1.28-1.56-1.32C480.14,118.4,479.91,118.93,480.21,119.61z",
  "M467.13,118.73c0.26,0.68,0.94,1.27,1.51,1.3c0.57,0.04,0.82-0.49,0.55-1.17c-0.27-0.68-0.94-1.26-1.5-1.3C467.11,117.53,466.86,118.05,467.13,118.73z",
  "M463.85,118.53c0.25,0.68,0.92,1.26,1.49,1.3c0.57,0.04,0.82-0.49,0.57-1.17c-0.26-0.68-0.93-1.26-1.49-1.29C463.85,117.33,463.6,117.85,463.85,118.53z",
  "M460.57,118.34c0.24,0.68,0.91,1.26,1.48,1.29c0.57,0.03,0.83-0.49,0.58-1.17c-0.25-0.68-0.91-1.25-1.48-1.29C460.59,117.14,460.33,117.66,460.57,118.34z",
  "M437.58,117.19c0.19,0.67,0.8,1.24,1.38,1.26c0.57,0.02,0.88-0.5,0.69-1.17c-0.19-0.67-0.81-1.23-1.38-1.26C437.7,116,437.39,116.52,437.58,117.19z",
  "M434.29,117.05c0.18,0.67,0.79,1.23,1.36,1.26c0.57,0.02,0.89-0.5,0.7-1.17c-0.19-0.67-0.79-1.23-1.36-1.25C434.43,115.86,434.11,116.38,434.29,117.05z",
  "M325.08,116.89c-0.11,0.67,0.27,1.19,0.85,1.17c0.58-0.02,1.13-0.58,1.23-1.25c0.1-0.67-0.28-1.18-0.85-1.16C325.74,115.67,325.19,116.23,325.08,116.89z",
  "M318.45,117.15c-0.13,0.67,0.24,1.19,0.82,1.17c0.58-0.02,1.15-0.59,1.27-1.25c0.12-0.67-0.25-1.19-0.82-1.16C319.14,115.93,318.57,116.49,318.45,117.15z",
  "M315.13,117.3c-0.14,0.67,0.22,1.19,0.8,1.17c0.58-0.03,1.15-0.59,1.28-1.26c0.13-0.67-0.23-1.19-0.8-1.16C315.84,116.07,315.26,116.63,315.13,117.3z",
  "M311.81,117.44c-0.14,0.67,0.21,1.19,0.79,1.17c0.58-0.03,1.16-0.59,1.3-1.26c0.14-0.67-0.21-1.19-0.79-1.16C312.54,116.22,311.96,116.78,311.81,117.44z",
  "M308.5,117.6c-0.15,0.67,0.19,1.19,0.77,1.17c0.58-0.03,1.17-0.6,1.31-1.26c0.15-0.67-0.2-1.19-0.77-1.16C309.23,116.37,308.65,116.93,308.5,117.6z",
  "M305.18,117.77c-0.16,0.67,0.18,1.19,0.76,1.16c0.58-0.03,1.17-0.6,1.33-1.27c0.15-0.67-0.18-1.19-0.76-1.16C305.93,116.53,305.34,117.1,305.18,117.77z",
  "M301.86,117.94c-0.17,0.67,0.16,1.19,0.74,1.16c0.58-0.03,1.18-0.6,1.34-1.27c0.16-0.67-0.17-1.19-0.74-1.16C302.63,116.7,302.03,117.27,301.86,117.94z",
  "M298.55,118.12c-0.18,0.67,0.15,1.2,0.72,1.16c0.58-0.03,1.19-0.61,1.36-1.28c0.17-0.67-0.15-1.19-0.73-1.16C299.33,116.88,298.73,117.45,298.55,118.12z",
  "M295.23,118.31c-0.19,0.67,0.13,1.2,0.71,1.16c0.58-0.03,1.19-0.61,1.37-1.28c0.18-0.67-0.14-1.19-0.71-1.16C296.03,117.06,295.42,117.63,295.23,118.31z",
  "M291.92,118.5c-0.19,0.68,0.12,1.2,0.69,1.16c0.58-0.03,1.2-0.61,1.39-1.29c0.19-0.67-0.12-1.19-0.69-1.16C292.73,117.26,292.11,117.83,291.92,118.5z",
  "M288.6,118.71c-0.2,0.68,0.1,1.2,0.68,1.16c0.58-0.04,1.21-0.62,1.4-1.29c0.2-0.68-0.11-1.19-0.68-1.16C289.43,117.46,288.81,118.03,288.6,118.71z",
  "M285.29,118.92c-0.21,0.68,0.09,1.2,0.66,1.16c0.58-0.04,1.21-0.62,1.42-1.3c0.2-0.68-0.09-1.19-0.66-1.16C286.14,117.66,285.5,118.24,285.29,118.92z",
  "M281.98,119.14c-0.22,0.68,0.07,1.2,0.65,1.16c0.58-0.04,1.22-0.62,1.43-1.3c0.21-0.68-0.08-1.19-0.65-1.16C282.84,117.88,282.2,118.46,281.98,119.14z",
  "M278.67,119.36c-0.23,0.68,0.06,1.2,0.63,1.16c0.58-0.04,1.22-0.63,1.45-1.31c0.22-0.68-0.06-1.2-0.63-1.16C279.54,118.1,278.89,118.68,278.67,119.36z",
  "M275.36,119.6c-0.24,0.68,0.04,1.2,0.62,1.16c0.58-0.04,1.23-0.63,1.46-1.31c0.23-0.68-0.05-1.2-0.62-1.16C276.25,118.33,275.59,118.91,275.36,119.6z",
  "M272.05,119.84c-0.24,0.68,0.03,1.2,0.6,1.16c0.58-0.04,1.24-0.63,1.47-1.32c0.24-0.68-0.03-1.2-0.6-1.16C272.95,118.57,272.29,119.15,272.05,119.84z",
  "M268.74,120.09c-0.25,0.68,0.01,1.21,0.59,1.16c0.58-0.04,1.24-0.64,1.49-1.32c0.25-0.68-0.02-1.2-0.59-1.16C269.66,118.82,268.99,119.4,268.74,120.09z",
  "M265.43,120.35c-0.26,0.69,0,1.21,0.57,1.16c0.58-0.05,1.25-0.64,1.5-1.33c0.25-0.69,0-1.2-0.58-1.16C266.36,119.07,265.69,119.66,265.43,120.35z",
  "M262.12,120.61c-0.27,0.69-0.02,1.21,0.56,1.16c0.58-0.05,1.26-0.64,1.52-1.33c0.26-0.69,0.01-1.2-0.56-1.16C263.07,119.33,262.39,119.92,262.12,120.61z",
  "M258.82,120.88c-0.27,0.69-0.03,1.21,0.55,1.16c0.58-0.05,1.26-0.65,1.53-1.34c0.27-0.69,0.02-1.21-0.55-1.16C259.78,119.6,259.09,120.19,258.82,120.88z",
  "M255.52,121.16c-0.28,0.69-0.05,1.21,0.53,1.16c0.58-0.05,1.27-0.65,1.54-1.34c0.28-0.69,0.04-1.21-0.53-1.16C256.49,119.87,255.8,120.47,255.52,121.16z",
  "M252.21,121.45c-0.29,0.69-0.06,1.22,0.52,1.16c0.58-0.05,1.27-0.65,1.56-1.35c0.28-0.69,0.05-1.21-0.52-1.16C253.2,120.16,252.5,120.76,252.21,121.45z",
  "M245.61,122.05c-0.31,0.7-0.09,1.22,0.49,1.17c0.58-0.05,1.28-0.66,1.58-1.36c0.3-0.69,0.08-1.21-0.49-1.16C246.62,120.75,245.92,121.35,245.61,122.05z",
  "M242.31,122.36c-0.31,0.7-0.1,1.22,0.47,1.17c0.57-0.05,1.29-0.67,1.6-1.36c0.31-0.7,0.09-1.22-0.48-1.16C243.34,121.05,242.62,121.66,242.31,122.36z",
  "M235.72,123c-0.33,0.7-0.13,1.23,0.45,1.17c0.57-0.06,1.3-0.67,1.62-1.37c0.32-0.7,0.12-1.22-0.45-1.16C236.77,121.69,236.05,122.3,235.72,123z",
  "M232.42,123.34c-0.33,0.7-0.14,1.23,0.43,1.17c0.57-0.06,1.31-0.68,1.64-1.38c0.33-0.7,0.13-1.22-0.43-1.16C233.49,122.02,232.76,122.63,232.42,123.34z",
  "M229.13,123.68c-0.34,0.71-0.15,1.23,0.42,1.17c0.57-0.06,1.31-0.68,1.65-1.39c0.34-0.7,0.15-1.22-0.42-1.17C230.21,122.35,229.47,122.97,229.13,123.68z",
  "M225.84,124.02c-0.35,0.71-0.17,1.23,0.41,1.17c0.57-0.06,1.32-0.69,1.66-1.39c0.34-0.71,0.16-1.23-0.41-1.17C226.93,122.7,226.19,123.32,225.84,124.02z",
  "M222.55,124.38c-0.36,0.71-0.18,1.24,0.39,1.17c0.57-0.06,1.32-0.69,1.67-1.4c0.35-0.71,0.17-1.23-0.39-1.17C223.66,123.05,222.91,123.67,222.55,124.38z",
  "M219.27,124.74c-0.36,0.71-0.19,1.24,0.38,1.17c0.57-0.06,1.33-0.69,1.68-1.4c0.36-0.71,0.19-1.23-0.38-1.17C220.38,123.41,219.63,124.03,219.27,124.74z",
  "M212.7,125.49c-0.38,0.72-0.22,1.24,0.36,1.18c0.57-0.07,1.34-0.7,1.71-1.41c0.37-0.71,0.21-1.24-0.36-1.17C213.84,124.15,213.07,124.78,212.7,125.49z",
  "M513.9,118.66c0.37,0.7,1.12,1.31,1.68,1.36c0.56,0.05,0.71-0.47,0.33-1.17c-0.37-0.7-1.12-1.31-1.68-1.36C513.68,117.44,513.53,117.96,513.9,118.66z",
  "M510.7,118.36c0.36,0.7,1.11,1.31,1.67,1.36c0.56,0.05,0.71-0.47,0.35-1.17c-0.37-0.7-1.11-1.3-1.67-1.35C510.49,117.14,510.33,117.66,510.7,118.36z",
  "M507.49,118.07c0.36,0.69,1.1,1.3,1.65,1.35c0.56,0.05,0.72-0.47,0.36-1.17c-0.36-0.7-1.1-1.3-1.65-1.35C507.3,116.85,507.14,117.37,507.49,118.07z",
  "M504.28,117.78c0.35,0.69,1.08,1.3,1.64,1.35c0.56,0.05,0.72-0.47,0.37-1.17c-0.35-0.69-1.09-1.29-1.64-1.34C504.1,116.57,503.94,117.09,504.28,117.78z",
  "M501.08,117.5c0.34,0.69,1.07,1.29,1.63,1.34c0.56,0.05,0.73-0.48,0.38-1.17c-0.35-0.69-1.08-1.29-1.63-1.34C500.91,116.29,500.73,116.81,501.08,117.5z",
  "M497.86,117.23c0.33,0.69,1.06,1.29,1.62,1.33c0.56,0.05,0.74-0.48,0.4-1.17c-0.34-0.69-1.06-1.28-1.62-1.33C497.71,116.03,497.53,116.54,497.86,117.23z",
  "M491.44,116.71c0.32,0.68,1.03,1.28,1.59,1.32c0.56,0.04,0.75-0.48,0.43-1.16c-0.32-0.69-1.04-1.27-1.59-1.32C491.31,115.51,491.12,116.03,491.44,116.71z",
  "M485,116.22c0.3,0.68,1.01,1.27,1.57,1.31c0.56,0.04,0.76-0.48,0.45-1.16c-0.31-0.68-1.01-1.27-1.57-1.31C484.9,115.02,484.7,115.54,485,116.22z",
  "M481.78,115.98c0.3,0.68,0.99,1.27,1.56,1.31c0.56,0.04,0.77-0.48,0.47-1.16c-0.3-0.68-1-1.26-1.55-1.3C481.69,114.79,481.48,115.3,481.78,115.98z",
  "M478.56,115.76c0.29,0.68,0.98,1.26,1.54,1.3c0.56,0.04,0.78-0.48,0.48-1.16c-0.3-0.68-0.98-1.26-1.54-1.3C478.49,114.56,478.27,115.08,478.56,115.76z",
  "M455.96,114.35c0.23,0.67,0.88,1.24,1.45,1.27c0.56,0.03,0.82-0.49,0.58-1.15c-0.24-0.67-0.89-1.23-1.45-1.26C455.98,113.17,455.72,113.69,455.96,114.35z",
  "M452.72,114.18c0.23,0.67,0.87,1.23,1.43,1.26c0.56,0.03,0.83-0.49,0.6-1.15c-0.23-0.67-0.87-1.23-1.43-1.26C452.76,113,452.49,113.52,452.72,114.18z",
  "M446.25,113.86c0.21,0.66,0.84,1.23,1.4,1.25c0.56,0.03,0.85-0.49,0.63-1.15c-0.22-0.66-0.85-1.22-1.4-1.25C446.32,112.68,446.04,113.2,446.25,113.86z",
  "M443.01,113.71c0.2,0.66,0.83,1.22,1.39,1.25c0.56,0.03,0.85-0.49,0.64-1.15c-0.21-0.66-0.83-1.22-1.39-1.24C443.09,112.53,442.8,113.05,443.01,113.71z",
  "M439.77,113.56c0.19,0.66,0.81,1.22,1.38,1.24c0.56,0.03,0.86-0.49,0.66-1.15c-0.2-0.66-0.82-1.21-1.38-1.24C439.87,112.39,439.57,112.9,439.77,113.56z",
  "M319.16,113.41c-0.13,0.66,0.23,1.17,0.8,1.15c0.57-0.02,1.13-0.58,1.25-1.23c0.12-0.66-0.24-1.16-0.8-1.14C319.84,112.21,319.28,112.76,319.16,113.41z",
  "M315.89,113.55c-0.13,0.66,0.22,1.17,0.79,1.15c0.57-0.02,1.14-0.58,1.26-1.23c0.13-0.66-0.23-1.17-0.79-1.14C316.59,112.35,316.02,112.9,315.89,113.55z",
  "M312.62,113.7c-0.14,0.66,0.2,1.17,0.77,1.15c0.57-0.03,1.14-0.58,1.28-1.24c0.14-0.66-0.21-1.17-0.77-1.14C313.34,112.49,312.76,113.04,312.62,113.7z",
  "M309.35,113.85c-0.15,0.66,0.19,1.17,0.76,1.15c0.57-0.03,1.15-0.58,1.3-1.24c0.15-0.66-0.19-1.17-0.76-1.14C310.08,112.64,309.5,113.19,309.35,113.85z",
  "M306.08,114.01c-0.16,0.66,0.17,1.17,0.74,1.15c0.57-0.03,1.16-0.59,1.31-1.25c0.15-0.66-0.18-1.17-0.74-1.14C306.83,112.79,306.24,113.35,306.08,114.01z",
  "M302.82,114.17c-0.17,0.66,0.16,1.18,0.73,1.15c0.57-0.03,1.16-0.59,1.33-1.25c0.16-0.66-0.16-1.17-0.73-1.14C303.58,112.96,302.98,113.51,302.82,114.17z",
  "M299.55,114.35c-0.18,0.66,0.14,1.18,0.71,1.15c0.57-0.03,1.17-0.59,1.34-1.26c0.17-0.66-0.15-1.17-0.71-1.14C300.32,113.13,299.72,113.69,299.55,114.35z",
  "M296.28,114.53c-0.19,0.66,0.13,1.18,0.7,1.14c0.57-0.03,1.18-0.6,1.36-1.26c0.18-0.66-0.13-1.17-0.7-1.14C297.07,113.31,296.46,113.87,296.28,114.53z",
  "M293.01,114.72c-0.19,0.66,0.11,1.18,0.68,1.14c0.57-0.03,1.18-0.6,1.37-1.26c0.19-0.66-0.12-1.17-0.68-1.14C293.82,113.49,293.21,114.05,293.01,114.72z",
  "M289.75,114.91c-0.2,0.67,0.1,1.18,0.67,1.14c0.57-0.03,1.19-0.6,1.39-1.27c0.2-0.66-0.1-1.17-0.67-1.14C290.57,113.68,289.95,114.25,289.75,114.91z",
  "M286.48,115.12c-0.21,0.67,0.08,1.18,0.65,1.14c0.57-0.04,1.2-0.61,1.4-1.27c0.2-0.67-0.09-1.17-0.65-1.14C287.32,113.88,286.69,114.45,286.48,115.12z",
  "M283.21,115.33c-0.22,0.67,0.07,1.18,0.64,1.14c0.57-0.04,1.2-0.61,1.42-1.28c0.21-0.67-0.07-1.18-0.64-1.14C284.06,114.09,283.43,114.66,283.21,115.33z",
  "M279.95,115.55c-0.23,0.67,0.05,1.18,0.62,1.15c0.57-0.04,1.21-0.61,1.43-1.28c0.22-0.67-0.06-1.18-0.62-1.14C280.81,114.31,280.17,114.88,279.95,115.55z",
  "M276.68,115.77c-0.23,0.67,0.04,1.19,0.61,1.15c0.57-0.04,1.22-0.62,1.44-1.29c0.23-0.67-0.04-1.18-0.61-1.14C277.56,114.53,276.92,115.1,276.68,115.77z",
  "M273.42,116.01c-0.24,0.67,0.02,1.19,0.59,1.15c0.57-0.04,1.22-0.62,1.46-1.29c0.24-0.67-0.03-1.18-0.59-1.14C274.31,114.76,273.66,115.33,273.42,116.01z",
  "M270.15,116.25c-0.25,0.67,0.01,1.19,0.58,1.15c0.57-0.04,1.23-0.63,1.47-1.3c0.24-0.67-0.01-1.18-0.58-1.14C271.06,115,270.4,115.57,270.15,116.25z",
  "M266.89,116.5c-0.26,0.68-0.01,1.19,0.56,1.15c0.57-0.04,1.23-0.63,1.49-1.3c0.25-0.67,0-1.18-0.56-1.14C267.81,115.24,267.15,115.82,266.89,116.5z",
  "M263.63,116.75c-0.27,0.68-0.02,1.19,0.55,1.15c0.57-0.05,1.24-0.63,1.5-1.31c0.26-0.68,0.01-1.19-0.55-1.14C264.57,115.49,263.89,116.07,263.63,116.75z",
  "M260.37,117.01c-0.27,0.68-0.04,1.19,0.53,1.15c0.57-0.05,1.25-0.64,1.51-1.31c0.27-0.68,0.03-1.19-0.53-1.14C261.32,115.75,260.64,116.34,260.37,117.01z",
  "M257.11,117.29c-0.28,0.68-0.05,1.2,0.52,1.15c0.57-0.05,1.25-0.64,1.53-1.32c0.28-0.68,0.04-1.19-0.52-1.14C258.07,116.02,257.39,116.6,257.11,117.29z",
  "M250.59,117.85c-0.3,0.68-0.08,1.2,0.49,1.15c0.57-0.05,1.26-0.65,1.56-1.33c0.29-0.68,0.07-1.19-0.49-1.15C251.58,116.57,250.88,117.16,250.59,117.85z",
  "M247.33,118.14c-0.3,0.69-0.09,1.2,0.48,1.15c0.57-0.05,1.27-0.65,1.57-1.34c0.3-0.69,0.08-1.2-0.48-1.15C248.33,116.86,247.63,117.46,247.33,118.14z",
  "M244.07,118.44c-0.31,0.69-0.1,1.21,0.46,1.15c0.57-0.05,1.28-0.66,1.58-1.34c0.31-0.69,0.1-1.2-0.47-1.15C245.09,117.16,244.38,117.75,244.07,118.44z",
  "M240.82,118.75c-0.32,0.69-0.12,1.21,0.45,1.15c0.57-0.05,1.28-0.66,1.59-1.35c0.31-0.69,0.11-1.2-0.45-1.15C241.85,117.46,241.13,118.06,240.82,118.75z",
  "M237.56,119.06c-0.33,0.69-0.13,1.21,0.44,1.15c0.57-0.06,1.29-0.66,1.61-1.35c0.32-0.69,0.12-1.2-0.44-1.15C238.61,117.77,237.89,118.37,237.56,119.06z",
  "M234.31,119.39c-0.33,0.69-0.14,1.21,0.42,1.16c0.57-0.06,1.29-0.67,1.62-1.36c0.33-0.69,0.14-1.21-0.42-1.15C235.36,118.09,234.64,118.69,234.31,119.39z",
  "M231.06,119.72c-0.34,0.7-0.16,1.22,0.41,1.16c0.57-0.06,1.3-0.67,1.63-1.37c0.33-0.69,0.15-1.21-0.41-1.15C232.13,118.41,231.4,119.02,231.06,119.72z",
  "M502.32,113.89c0.35,0.68,1.07,1.28,1.63,1.33c0.55,0.05,0.71-0.47,0.36-1.16c-0.35-0.68-1.08-1.27-1.63-1.32C502.14,112.7,501.97,113.21,502.32,113.89z",
  "M499.15,113.63c0.34,0.68,1.06,1.27,1.61,1.32c0.55,0.05,0.72-0.47,0.38-1.15c-0.34-0.68-1.07-1.27-1.61-1.31C498.98,112.43,498.81,112.95,499.15,113.63z",
  "M495.98,113.37c0.33,0.68,1.05,1.27,1.6,1.31c0.55,0.05,0.73-0.47,0.39-1.15c-0.34-0.68-1.05-1.26-1.6-1.31C495.82,112.18,495.65,112.69,495.98,113.37z",
  "M473.74,111.74c0.28,0.66,0.96,1.24,1.51,1.27c0.55,0.04,0.77-0.48,0.49-1.14c-0.29-0.67-0.96-1.23-1.51-1.27C473.68,110.57,473.46,111.08,473.74,111.74z",
  "M384.2,108.61c0.05,0.64,0.54,1.16,1.1,1.16c0.56,0,0.97-0.51,0.91-1.15c-0.05-0.64-0.55-1.15-1.1-1.15C384.56,107.46,384.15,107.97,384.2,108.61z",
  "M377.78,108.6c0.03,0.64,0.51,1.16,1.07,1.16c0.56,0,0.98-0.52,0.95-1.15c-0.04-0.64-0.52-1.15-1.07-1.15C378.17,107.45,377.75,107.96,377.78,108.6z",
  "M374.56,108.6c0.02,0.64,0.49,1.15,1.06,1.15c0.56,0,0.99-0.52,0.96-1.16c-0.03-0.64-0.5-1.15-1.05-1.15C374.97,107.45,374.54,107.96,374.56,108.6z",
  "M326.31,109.5c-0.11,0.64,0.26,1.15,0.82,1.13c0.56-0.02,1.1-0.56,1.2-1.2c0.1-0.64-0.27-1.14-0.82-1.12C326.95,108.33,326.41,108.86,326.31,109.5z",
  "M323.09,109.62c-0.12,0.64,0.24,1.15,0.81,1.13c0.56-0.02,1.11-0.56,1.22-1.2c0.11-0.64-0.25-1.14-0.81-1.12C323.75,108.44,323.2,108.98,323.09,109.62z",
  "M319.87,109.74c-0.13,0.64,0.23,1.15,0.79,1.13c0.56-0.02,1.11-0.56,1.23-1.21c0.12-0.64-0.24-1.14-0.79-1.12C320.54,108.56,319.99,109.1,319.87,109.74z",
  "M316.65,109.87c-0.13,0.64,0.21,1.15,0.77,1.13c0.56-0.02,1.12-0.57,1.25-1.21c0.13-0.64-0.22-1.14-0.77-1.12C317.34,108.69,316.78,109.23,316.65,109.87z",
  "M313.42,110.01c-0.14,0.65,0.2,1.15,0.76,1.13c0.56-0.02,1.13-0.57,1.26-1.21c0.14-0.64-0.2-1.14-0.76-1.12C314.13,108.83,313.57,109.37,313.42,110.01z",
  "M310.2,110.16c-0.15,0.65,0.18,1.15,0.74,1.13c0.56-0.03,1.13-0.57,1.28-1.22c0.14-0.65-0.19-1.15-0.74-1.12C310.93,108.97,310.35,109.51,310.2,110.16z",
  "M306.98,110.31c-0.16,0.65,0.17,1.15,0.73,1.13c0.56-0.03,1.14-0.58,1.29-1.22c0.15-0.65-0.17-1.15-0.73-1.12C307.72,109.12,307.14,109.66,306.98,110.31z",
  "M303.76,110.47c-0.17,0.65,0.15,1.15,0.71,1.13c0.56-0.03,1.15-0.58,1.31-1.23c0.16-0.65-0.16-1.15-0.71-1.12C304.52,109.28,303.93,109.82,303.76,110.47z",
  "M300.54,110.64c-0.18,0.65,0.14,1.16,0.7,1.13c0.56-0.03,1.15-0.58,1.32-1.23c0.17-0.65-0.14-1.15-0.7-1.12C301.31,109.44,300.72,109.99,300.54,110.64z",
  "M297.32,110.81c-0.18,0.65,0.12,1.16,0.68,1.13c0.56-0.03,1.16-0.59,1.34-1.24c0.18-0.65-0.13-1.15-0.68-1.12C298.1,109.61,297.51,110.16,297.32,110.81z",
  "M294.1,111c-0.19,0.65,0.11,1.16,0.67,1.13c0.56-0.03,1.17-0.59,1.35-1.24c0.19-0.65-0.11-1.15-0.67-1.12C294.9,109.79,294.29,110.34,294.1,111z",
  "M290.88,111.18c-0.2,0.65,0.09,1.16,0.65,1.13c0.56-0.03,1.18-0.59,1.37-1.25c0.19-0.65-0.1-1.15-0.65-1.12C291.69,109.98,291.08,110.53,290.88,111.18z",
  "M287.66,111.38c-0.21,0.66,0.08,1.16,0.64,1.13c0.56-0.03,1.18-0.6,1.38-1.25c0.2-0.65-0.08-1.15-0.64-1.12C288.49,110.17,287.87,110.72,287.66,111.38z",
  "M284.44,111.58c-0.22,0.66,0.06,1.16,0.62,1.13c0.56-0.04,1.19-0.6,1.4-1.26c0.21-0.66-0.07-1.16-0.62-1.12C285.28,110.37,284.66,110.93,284.44,111.58z",
  "M274.78,112.24c-0.24,0.66,0.02,1.17,0.58,1.13c0.56-0.04,1.21-0.61,1.44-1.27c0.23-0.66-0.03-1.16-0.58-1.12C275.67,111.01,275.02,111.57,274.78,112.24z",
  "M271.56,112.47c-0.25,0.66,0,1.17,0.57,1.13c0.56-0.04,1.21-0.61,1.46-1.28c0.24-0.66-0.01-1.16-0.57-1.12C272.46,111.24,271.81,111.8,271.56,112.47z",
  "M268.34,112.71c-0.26,0.66-0.01,1.17,0.55,1.13c0.56-0.04,1.22-0.62,1.47-1.28c0.25-0.66,0-1.17-0.55-1.12C269.26,111.47,268.6,112.04,268.34,112.71z",
  "M487.84,109.09c0.32,0.66,1.01,1.24,1.56,1.28c0.55,0.04,0.73-0.47,0.41-1.13c-0.32-0.67-1.02-1.23-1.56-1.27C487.71,107.92,487.52,108.42,487.84,109.09z",
  "M472.16,108.01c0.28,0.65,0.95,1.22,1.5,1.25c0.55,0.04,0.76-0.47,0.48-1.13c-0.28-0.66-0.95-1.21-1.49-1.25C472.1,106.86,471.88,107.36,472.16,108.01z",
  "M465.88,107.63c0.26,0.65,0.92,1.21,1.47,1.24c0.55,0.03,0.77-0.47,0.5-1.12c-0.27-0.65-0.93-1.2-1.47-1.23C465.84,106.48,465.62,106.98,465.88,107.63z",
  "M450.17,106.79c0.22,0.64,0.85,1.19,1.4,1.22c0.55,0.03,0.81-0.48,0.58-1.12c-0.23-0.64-0.86-1.18-1.4-1.21C450.2,105.65,449.94,106.15,450.17,106.79z",
  "M447.02,106.64c0.22,0.64,0.84,1.18,1.38,1.21c0.55,0.03,0.81-0.48,0.59-1.12c-0.22-0.64-0.84-1.18-1.38-1.2C447.07,105.5,446.8,106,447.02,106.64z",
  "M443.87,106.5c0.21,0.64,0.82,1.18,1.37,1.21c0.55,0.03,0.82-0.48,0.61-1.12c-0.21-0.64-0.83-1.18-1.37-1.2C443.94,105.36,443.67,105.86,443.87,106.5z",
  "M440.73,106.36c0.2,0.64,0.81,1.18,1.36,1.2c0.55,0.02,0.83-0.48,0.62-1.12c-0.21-0.64-0.81-1.17-1.36-1.19C440.8,105.23,440.53,105.72,440.73,106.36z",
  "M437.58,106.23c0.19,0.64,0.79,1.17,1.34,1.2c0.55,0.02,0.83-0.48,0.64-1.12c-0.2-0.64-0.8-1.17-1.34-1.19C437.67,105.1,437.39,105.6,437.58,106.23z",
  "M431.28,105.99c0.18,0.63,0.76,1.17,1.31,1.19c0.55,0.02,0.85-0.48,0.67-1.12c-0.18-0.64-0.77-1.16-1.31-1.18C431.4,104.86,431.1,105.36,431.28,105.99z",
  "M428.13,105.88c0.17,0.63,0.75,1.16,1.3,1.18c0.55,0.02,0.85-0.48,0.68-1.12c-0.17-0.63-0.75-1.16-1.3-1.18C428.27,104.76,427.96,105.25,428.13,105.88z",
  "M424.97,105.78c0.16,0.63,0.73,1.16,1.28,1.18c0.55,0.02,0.86-0.48,0.7-1.12c-0.16-0.63-0.74-1.16-1.28-1.17C425.13,104.65,424.82,105.15,424.97,105.78z",
  "M421.82,105.68c0.15,0.63,0.72,1.16,1.27,1.18c0.55,0.02,0.87-0.48,0.71-1.12c-0.16-0.63-0.72-1.15-1.27-1.17C421.99,104.56,421.67,105.05,421.82,105.68z",
  "M399.73,105.2c0.09,0.63,0.61,1.14,1.16,1.15c0.55,0.01,0.92-0.5,0.82-1.12c-0.1-0.63-0.62-1.14-1.16-1.14C400.01,104.07,399.64,104.57,399.73,105.2z",
  "M393.41,105.12c0.07,0.62,0.58,1.14,1.13,1.15c0.55,0.01,0.93-0.5,0.85-1.12c-0.08-0.62-0.59-1.13-1.13-1.14C393.72,104,393.34,104.49,393.41,105.12z",
  "M387.09,105.06c0.06,0.62,0.55,1.14,1.1,1.14c0.55,0,0.95-0.5,0.88-1.13c-0.06-0.62-0.56-1.13-1.1-1.13C387.43,103.94,387.03,104.44,387.09,105.06z",
  "M377.6,105.04c0.03,0.62,0.5,1.13,1.05,1.13c0.55,0,0.97-0.51,0.93-1.13c-0.04-0.62-0.51-1.12-1.05-1.13C377.99,103.91,377.57,104.41,377.6,105.04z",
  "M336.43,105.61c-0.08,0.63,0.3,1.13,0.85,1.11c0.55-0.02,1.06-0.54,1.14-1.16c0.08-0.63-0.31-1.12-0.85-1.1C337.02,104.47,336.51,104.99,336.43,105.61z",
  "M333.26,105.71c-0.09,0.63,0.28,1.13,0.84,1.11c0.55-0.02,1.07-0.54,1.15-1.17c0.08-0.63-0.29-1.12-0.84-1.1C333.86,104.56,333.35,105.08,333.26,105.71z",
  "M326.91,105.91c-0.11,0.63,0.25,1.13,0.81,1.11c0.55-0.02,1.08-0.55,1.19-1.18c0.1-0.63-0.26-1.12-0.81-1.1C327.55,104.76,327.02,105.28,326.91,105.91z",
  "M314.22,106.4c-0.14,0.63,0.19,1.13,0.75,1.11c0.55-0.02,1.11-0.56,1.25-1.19c0.14-0.63-0.2-1.12-0.75-1.1C314.92,105.24,314.37,105.77,314.22,106.4z",
  "M311.05,106.54c-0.15,0.63,0.18,1.13,0.73,1.11c0.55-0.02,1.12-0.56,1.26-1.19c0.14-0.63-0.18-1.12-0.73-1.1C311.77,105.38,311.2,105.91,311.05,106.54z",
  "M307.88,106.69c-0.16,0.64,0.16,1.13,0.72,1.11c0.55-0.03,1.13-0.56,1.28-1.2c0.15-0.63-0.17-1.13-0.72-1.1C308.61,105.52,308.04,106.05,307.88,106.69z",
  "M304.7,106.84c-0.17,0.64,0.15,1.13,0.7,1.11c0.55-0.03,1.13-0.57,1.29-1.2c0.16-0.64-0.15-1.13-0.7-1.1C305.45,105.67,304.87,106.2,304.7,106.84z",
  "M301.53,107c-0.17,0.64,0.13,1.13,0.69,1.11c0.55-0.03,1.14-0.57,1.31-1.21c0.17-0.64-0.14-1.13-0.69-1.1C302.29,105.83,301.7,106.36,301.53,107z",
  "M298.36,107.17c-0.18,0.64,0.12,1.14,0.67,1.11c0.55-0.03,1.15-0.57,1.32-1.21c0.18-0.64-0.12-1.13-0.67-1.1C299.13,105.99,298.54,106.53,298.36,107.17z",
  "M292.01,107.52c-0.2,0.64,0.09,1.14,0.64,1.11c0.55-0.03,1.16-0.58,1.35-1.22c0.19-0.64-0.09-1.13-0.64-1.1C292.81,106.34,292.21,106.88,292.01,107.52z",
  "M288.83,107.71c-0.21,0.64,0.07,1.14,0.63,1.11c0.55-0.03,1.17-0.58,1.37-1.23c0.2-0.64-0.08-1.13-0.63-1.1C289.65,106.52,289.04,107.07,288.83,107.71z",
]