// material
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Helmet } from 'react-helmet-async';

// ----------------------------------------------------------------------

const metas = window.locations[window.code1].meta || [];
const titles = [
  "Description",
  "Keywords",
  "geo.region",
  "geo.placename",
  "geo.position",
  "ICBM",
]
const Page = forwardRef(({ children, title = 'test', ...other }, ref) => {
  return (
    <Box ref={ref} {...other}>
      <Helmet>
        {
          metas.map((item, i) => <meta name={titles[i]} content={item} />)
        }
        <title>{title}  </title>
      </Helmet>
      {children}
    </Box>
  )
});

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string
};

export default Page;
