import { Box, Stack, Typography } from "@mui/material"
import { motion } from "framer-motion"
import SlideUp from "../../components/animate/SlideUp"
import HeadingTitles from "./HeadingTitles"

const Services = ({ children, ...props }) => {
  return <Stack {...props} pt={10}>
    <HeadingTitles title='Services' subtitle='Our Services for clients' />
    <Box
      mt={2}
      component={motion.div}
      initial="initial"
      animate="enter"
      exit="exit"
      variants={{ exit: { transition: { staggerChildren: 0.1 } } }}
      gap={2}
      display={'grid'} gridTemplateColumns={{ xs: '1fr', sm: '1fr 1fr', md: '1fr 1fr 1fr' }} px={{ xs: 2, md: 5 }}>
      {
        window.dashboard?.services.map((service, index) => (<ServiceItem {...{ ...service, index }} key={`service-${index}`} />))
      }
    </Box>
  </Stack>
}
export default Services
const ServiceItem = ({ index, title, description }) => {
  return (
    <SlideUp>
      <Stack p={{ xs: 1, sm: 3, md: 5 }} pt={0}>
        <Typography
          sx={{
            flexGrow: 1,
            color: '#00000000',
            fontWeight: 600,
            fontSize: '3.5rem',
            WebkitTextStrokeColor: '#fff',
            WebkitTextStrokeWidth: '0.5px',
            textAlign: { xs: 'center', md: 'left' },
          }}
        >
          {`${index + 1}`.padStart(2, '0')}
        </Typography>
        <Typography
          color='primary'
          sx={{
            flexGrow: 1,
            fontWeight: 600,
            mt: 2,
            fontSize: '1.375rem',
            textAlign: { xs: 'center', md: 'left' },
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            flexGrow: 1,
            color: '#808DAD',
            mt: 1,
            fontSize: '1rem',
            textAlign: { xs: 'center', md: 'left' },
            lineHeight: '1.1em',
            height: { xs: 'auto', md: '3.3em' },
            overflow: { xs: 'auto', md: 'hidden' },
            WebkitLineClamp: { xs: 0, md: 3 },
            WebkitBoxOrient: { xs: 'none', md: 'vertical' },
            transition: 'all 0.3s ease-in-out',
            display: { xs: 'block', md: '-webkit-box' },
            '&:hover': {
              display: 'block',
              overflow: 'visible',
              height: 'auto',
              lineClamp: null,
            }
          }}
        >
          {description}
        </Typography>
      </Stack>
    </SlideUp>
  )
}