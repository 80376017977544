import { GlobalStyles } from '@mui/styled-engine'
import { Box } from "@mui/system"


const CircleLoading = ({ name = 'drop', drop = 7, color = '#1B8AF6', sx, ...props }) => {
  return <Box {...props} sx={{
    backgroundColor: 'transparent',
    position: 'relative',
    margin: "0",
    // overflow: "hidden",
    height: 100,
    width: 100,
    ...sx
  }}>
    <GlobalStyles styles={{
      [`@keyframes ${name}`]: {
        "0%": {
          width: 0,
          height: 0,
          opasity: 1,
          boxShadow: "0px 0px 1px transparent, inset 0px 0px 1px transparent"
        },
        "50%": {
          boxShadow: `20px 20px 150px ${color}80, inset 20px 20px 150px ${color}80`
        },
        "75%": {
          opasity: 0.2,
          boxShadow: `20px 20px 20px ${color}80, inset 20px 20px 20px ${color}80`
        },
        "100%": {
          width: "100%",
          height: "100%",
          opacity: 0,
          boxShadow: "0px 0px 0px transparent, inset 0px 0px 0px transparent"
        }
      }
    }} />

    <Box sx={{
      position: "absolute",
      width: "100%",
      height: "100%",
      zIndex: 10,
      backdropFilter: "blur(1px)",
      webkitBackdropFilter: "blur(1px)"
    }} />
    <Box sx={{
      position: 'relative',
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      height: "100%",
    }}>
      {
        Array(drop).fill(0).map((_, i) => (<Box key={`drop-${i}`} sx={{
          position: "absolute",
          borderRadius: "50%",
          animation: `${name} 6s ease-in-out infinite ${i * 1}s`,
        }} />))
      }
    </Box>
  </Box>
}
export default CircleLoading