import { Box, Icon, Stack, Typography } from "@mui/material"
import HeadingTitles from "./HeadingTitles"
import { motion } from "framer-motion"
import SlideUp from "../../components/animate/SlideUp"
const contactDetails = window.locations[window.code1];

const DashboardWorks = ({ works, ...props }) => {
  return <Stack {...props}>
    {!works && <HeadingTitles title='Works' subtitle='Things we have done' all='/works' />}
    <Box
      component={motion.div}
      initial="initial"
      animate="enter"
      exit="exit"
      variants={{ exit: { transition: { staggerChildren: 0.1 } } }}
      display={'grid'} gridTemplateColumns={{ xs: '1fr', md: '1fr 1fr' }}
      gap={2}
      pt={3} px={{ xs: 2, md: 5 }}>
      {
        (contactDetails.works || window.dashboard?.works).filter((_, i) => works || i < 4).map((service, index) => (<WorkItem {...{ ...service, index }} key={`work-${index}`} />))
      }
    </Box>
  </Stack>
}
export default DashboardWorks

export const WorkItem = ({ index, title, type, image, link, ...props }) => {
  return (
    <SlideUp
      variants={{
        hover: { scale: 1.1 },
        offscreen: {
          scale: 0.7,
        },
        onscreen: {
          scale: 1,
          transition: {
            type: "spring",
            bounce: 0.4,
            duration: 0.8,
          }
        }
      }}
      {...props}>
      <Stack p={{ xs: 1, sm: 3, md: 5 }} pt={0} {...(index + 1) % 2 === 0 && { ...{ mt: { xs: 0, md: 5 } } }}>
        <img loading='lazy' src={image} alt={title} />
        <Typography
          sx={{
            mt: 2,
            flexGrow: 1,
            color: '#808DAD',
            fontSize: '1rem',
          }}
        >
          {type}
        </Typography>
        <Typography
          {...link && {
            component: 'a',
            href: link,
            target: '_blank'
          }}
          color='primary.contrastText'
          sx={{
            flexGrow: 1,
            mt: 1,
            fontSize: '1.5rem',
            textTransform: 'uppercase',
          }}
        >
          {title}
          {link && <Icon sx={{ ml: 3 }}>arrow_right_alt</Icon>}
        </Typography>
        <Box></Box>
      </Stack>
    </SlideUp>
  )
}