import { CssBaseline } from '@mui/material';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import ErrorBoundary from './components/ErrorBoundary';
import Loadder from './components/Loadder';
import { ContextProvider } from './context';
import reportWebVitals from './reportWebVitals';
import Router from './routes';
ReactDOM.render(
  // <ErrorBoundary>
    <HelmetProvider>
      <BrowserRouter>
        <React.StrictMode>
          <ContextProvider>
            <CssBaseline />
            <Suspense fallback={<Loadder />}>
              <Router />
            </Suspense>
          </ContextProvider>
        </React.StrictMode>
      </BrowserRouter>
    </HelmetProvider>
  // </ErrorBoundary>
  ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
