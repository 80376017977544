import { Box } from "@mui/material";
import { motion } from "framer-motion";

const cardVariants = ({
  hover: {
    y: "-20px",
  },
  offscreen: {
    opacity: 0,
    y: "50%",
  },
  onscreen: {
    y: "0%",
    opacity: 1,
    transition: {
      type: "spring",
      bounce: 0.4,
      duration: 0.8,
    }
  }
});


const SlideUp = ({ children, variants, hover = true, sx, ...props }) => {
  return <Box
    {...{
      sx: { ...sx },
      component: motion.div,
      initial: "offscreen",
      whileInView: "onscreen",
      viewport: { once: true, amount: 0.8 },
      variants: { ...cardVariants, ...variants },
      ...hover && { whileHover: 'hover' },
      ...props
    }}
  >
    {children}
  </Box>
}
export default SlideUp