const getHeader = () => {
  return {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Origin': window.location.protocol + '//' + window.location.host,
    ...localStorage.getItem('token') && { Authorization: (localStorage.getItem('token')) },
  }
}
const fetchCall = async ({ url, method, data, headers = {} }) => {
  console.debug(url)
  const fetchResult = await fetch(url, {
    method,
    credentials: 'same-origin',
    headers: { ...getHeader(), ...headers },
    ...data ? { body: JSON.stringify(data) } : {}
  })
  return fetchResult;
}
const method = {
  GET: 'GET', POST: 'POST', PUT: 'PUT', DELETE: 'DELETE'
};

export const getPageable = async ({ url, page, size, sort, search, headers }) => {
  const op = `page=${page}&size=${size}&sort=${sort}&search=${search}`;
  const pageUrl = `${url}${url.includes('?') ? '' : '?'}${op}`;
  return await fetchCall({ url: pageUrl, method: method.GET, headers });
}
export const get = async (url, headers) => {
  return await fetchCall({ url, method: method.GET, headers });
}
export const post = async (url, data, headers) => {
  return await fetchCall({ url, method: method.POST, data, headers });
}
export const put = async (url, data, headers) => {
  return await fetchCall({ url, method: method.PUT, data, headers });
}
export const remove = async (url, headers) => {
  return await fetchCall({ url, method: method.DELETE, headers });
}