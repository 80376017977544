import { Button, Container, Stack, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { motion } from 'framer-motion';
import { Link } from "react-router-dom";
import SlideUp from "../components/animate/SlideUp";
import Page from "../components/Page";
import About from "./dashboard/About";
import Locations from "./dashboard/Locations";
import Services from "./dashboard/Services";
import DashboardWorks from "./dashboard/Works";

const imageVariants = {

  hover: { y: "-20%", },
  offscreen: {
    y: "50%", opacity: 0,
  },
  onscreen: {
    y: "0%",
    opacity: 1,
    transition: {
      type: "spring",
      bounce: 0.4,
      duration: 0.8,
    }
  }

};

export const Image = styled(motion.img)({

})
const contactDetails = window.locations[window.code1];

const Dashboard = ({ children, ...props }) => {
  return <Page title={contactDetails.name}>
    <Container maxWidth="xl">
      <Stack {...props} spacing={2} padding={1}>
        <Stack minHeight={{ xs: '0px', md: '100vh' }} justifyContent={'end'}>
          <SlideUp hover={false}>
            <Typography
              sx={{
                flexGrow: 1,
                textAlign: 'center',
                pt: 5,
                fontWeight: 700,
                fontSize: 'clamp(1rem, 8vw, 4.5rem)',
                color: '#fff',
              }}
            >
              Build. Launch. Grow.
            </Typography>
          </SlideUp>
          <SlideUp hover={false}>
            <Typography
              sx={{
                flexGrow: 1,
                textAlign: 'center',
                fontFamily: 'Inter',
                fontWeight: 400,
                fontSize: 'clamp(0.5rem, 3vw, 1.5rem)',
                color: '#fff',
              }}
            >
              Application Development. Web Development. Just Got Easier.
            </Typography>
          </SlideUp>
          <SlideUp hover={false}>
            <Typography
              sx={{
                mt: 1,
                flexGrow: 1,
                textAlign: 'center',
                fontFamily: 'Inter',
                fontWeight: 400,
                fontSize: 'clamp(0.3rem, 3vw, 1.2rem)',
                color: '#808DAD',
              }}
            >
              Let’s transform your idea in to a functioning product.
            </Typography>
          </SlideUp>

          <SlideUp component={Stack} direction={'row'} justifyContent={'center'} spacing={3} mt={2}>
            <SlideUp component={Button} size='large' variant="contained" sx={{
              boxShadow: 'none', borderRadius: 0, px: 4, py: 2,
              fontSize: 'clamp(0.3rem, 3vw, 1.2rem)', fontWeight: 400, lineHeight: 1,
            }}
              LinkComponent={Link}
              to='/about'
            >
              Learn More
            </SlideUp>
            <SlideUp component={Button} size='large' variant="outlined" sx={{
              boxShadow: 'none', borderRadius: 0, px: 4, py: 2,
              fontSize: 'clamp(0.3rem, 3vw, 1.2rem)', fontWeight: 400, lineHeight: 1,
            }}
              LinkComponent={Link}
              to='/contact-us'>
              Get Started
            </SlideUp>
          </SlideUp>
          <Stack
            component={motion.div}
            initial="offscreen"
            whileHover="hover"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.8 }}
            direction={'row'} justifyContent={'center'} my={{ xs: 5, md: 10, xl: 15 }} >
            <Image
              variants={imageVariants}
              src={'https://ik.imagekit.io/grknoi8mz/images/rocket.png'} alt='logo' sx={{ width: { xs: 150, sm: 200, md: 319 } }} />
          </Stack>
        </Stack>
        <Services />
        <DashboardWorks pt={{ xs: 9.88, md: 19 }} />
        <About pt={{ xs: 9.88, md: 19 }} />
        <Locations pt={{ xs: 9.88, md: 19 }} />
      </Stack>
    </Container>
  </Page>
}

export default Dashboard;


