import { lazy } from "react";

const AboutUs = lazy(() => import('./AboutUs'));
const Approach = lazy(() => import('./Approach'));
const Careers = lazy(() => import('./Careers'));
const ContactUs = lazy(() => import('./ContactUs'));
const Dashboard = lazy(() => import('./Dashboard'));
const DashboardLayout = lazy(() => import('./DashboardLayout'));
const Page404 = lazy(() => import('./Page404'));
const PayNow = lazy(() => import('./PayNow'));
const Pricing = lazy(() => import('./Pricing'));
const Thanks = lazy(() => import('./Thanks'));
const Works = lazy(() => import('./Works'));


export {
  AboutUs,
  Approach,
  Careers,
  ContactUs,
  Dashboard,
  DashboardLayout,
  Page404,
  PayNow,
  Pricing,
  Thanks,
  Works,
}