// material
import { CssBaseline } from '@mui/material';
import GlobalStyles from '@mui/material/GlobalStyles';
import { createTheme, StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { createContext, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { companyLogo } from '../pages/DashboardLayout';
import componentsOverride from './overrides';
import palette from './palette';
import getShadows from './shadows';
//
import shape from './shape';
import typography from './typography';

import ObjectivityBold from '../font/Objectivity-Bold.otf';
import ObjectivityRegular from '../font/Objectivity-Regular.otf';
import ObjectivityMedium from '../font/Objectivity-Medium.otf';
export const ObjectivityFont = [
  {
    fontFamily: 'Objectivity',
    fontStyle: 'normal',
    fontWeight: 400,
    src: `url(${ObjectivityRegular}) format('opentype')`
  },
  {
    fontFamily: 'Objectivity',
    fontStyle: 'medium',
    fontWeight: 600,
    src: `url(${ObjectivityMedium}) format('opentype')`
  },
  {
    fontFamily: 'Objectivity',
    fontStyle: 'bold',
    fontWeight: 700,
    src: `url(${ObjectivityBold}) format('opentype')`
  },
]
// ----------------------------------------------------------------------

ThemeConfig.propTypes = {
  children: PropTypes.node
};
export const CustomThemeContext = createContext(null);


export default function ThemeConfig({ children }) {
  const { shadows, customShadows } = getShadows(false);

  const themeOptions = useMemo(
    () => ({
      // direction: 'rtl',
      palette: {
        ...palette, ...(palette.dark),
      },
      shape,
      typography,
      shadows,
      customShadows
    }),
    [shadows, customShadows]
  );

  const theme = createTheme(themeOptions);
  theme.components = componentsOverride(theme);
  return (
    <CustomThemeContext.Provider value={{
      theme,
    }}>
      <Helmet>
        <link rel="shortcut icon" href={companyLogo} />
      </Helmet>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <GlobalStyles styles={{
            ...ObjectivityFont.map(font => ({ '@font-face': font })),
            '*': {
              margin: 0,
              padding: 0,
              boxSizing: 'border-box'
            },
            html: {
              width: '100%',
              height: '100%',
              textSizeAdjust: '100%',
              WebkitOverflowScrolling: 'touch',
              scrollbarWidth: 'thin',
              scrollbarColor: '#0E1630'
            },
            a: {
              textDecoration: 'none',
              color: theme.palette.primary.main
            },
            '::-webkit-scrollbar': {
              height: 6,
              width: 6,
            },
            '::-webkit-scrollbar-track': {
              background: '#00000000',
              width: 2,
              borderRadius: 4,
            },
            '::-webkit-scrollbar-thumb': {
              width: 2,
              background: '#676DFF50',
              borderRadius: 4
            },
            '::-webkit-scrollbar-thumb:hover': {
              background: '#676DFF',
              width: 4,
              cursor: 'pointer'
            },
            body: {
              width: '100%',
              height: '100%',
              overflowY: 'scroll',
              scrollbarWidth: 'thin',
              scrollbarColor: '#0E1630'
            },
            '#root': {
              width: '100%',
              height: '100%',
            },
            input: {
              '&[type=number]': {
                WebkitAppearance: 'textfield',
                '&::-webkit-outer-spin-button': { margin: 0, WebkitAppearance: 'none' },
                '&::-webkit-inner-spin-button': { margin: 0, WebkitAppearance: 'none' }
              },
              '&::-webkit-calendar-picker-indicator':
                { backgroundImage: `-webkit-image-set(url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTUgOWExIDEgMCAxMDAtMiAxIDEgMCAwMDAgMnpNNiAxMWExIDEgMCAxMS0yIDAgMSAxIDAgMDEyIDB6TTggOWExIDEgMCAxMDAtMiAxIDEgMCAwMDAgMnpNOSAxMWExIDEgMCAxMS0yIDAgMSAxIDAgMDEyIDB6TTExIDlhMSAxIDAgMTAwLTIgMSAxIDAgMDAwIDJ6IiBmaWxsPSIjZmZmIi8+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNSAzLjVBMi41IDIuNSAwIDAwMTIuNSAxaC05QTIuNSAyLjUgMCAwMDEgMy41djlBMi41IDIuNSAwIDAwMy41IDE1aDlhMi41IDIuNSAwIDAwMi41LTIuNXYtOXpNMiA1aDEydjcuNWMwIC44My0uNjcgMS41LTEuNSAxLjVoLTlBMS41IDEuNSAwIDAxMiAxMi41VjV6bTEuNS0zaDljLjgzIDAgMS41LjY3IDEuNSAxLjVWNEgydi0uNUMyIDIuNjcgMi42NyAyIDMuNSAyeiIgZmlsbD0iI2ZmZiIvPjwvc3ZnPg==")1x)` }
            },
            textarea: {
              "::placeholder": {
                color: theme.palette.text.disabled
              },
              '&::-webkit-input-placeholder': { color: theme.palette.text.disabled },
              '&::-moz-placeholder': { opacity: 1, color: theme.palette.text.disabled },
              '&:-ms-input-placeholder': { color: theme.palette.text.disabled },
              '&::placeholder': { color: theme.palette.text.disabled }
            },
            img: { display: 'block', maxWidth: '100%' },
            '.apexcharts-tooltip,.apexcharts-xaxistooltip': {
              border: '0 !important',
              boxShadow: `${theme.customShadows.z24} !important`,
              color: `${theme.palette.text.primary} !important`,
              borderRadius: `${theme.shape.borderRadiusSm}px !important`,
              backgroundColor: `${theme.palette.background.default} !important`
            },
            '.apexcharts-tooltip-title': {
              border: '0 !important',
              fontWeight: theme.typography.fontWeightBold,
              backgroundColor: `${theme.palette.grey[500_16]} !important`,
              color: theme.palette.text.secondary
            },
            '.apexcharts-xaxistooltip-bottom': {
              '&:before': {
                borderBottomColor: 'transparent !important'
              },
              '&:after': {
                borderBottomColor: `${theme.palette.background.paper} !important`
              }
            },

            // Legend
            '.apexcharts-legend': {
              padding: '0 !important'
            },
            '.apexcharts-legend-series': {
              alignItems: 'center',
              display: 'flex !important'
            },
            '.apexcharts-legend-marker': {
              marginTop: '-2px !important',
              marginRight: '8px !important'
            },
            '.apexcharts-legend-text': {
              lineHeight: '18px',
              textTransform: 'capitalize'
            }
          }} />
          {children}
        </ThemeProvider>
      </StyledEngineProvider>
    </CustomThemeContext.Provider>
  );
}
