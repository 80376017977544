// material
import styled from '@emotion/styled';
import { AppBar, Avatar, Box, Button, Card, CardActionArea, Container, Fab, Icon, IconButton, Link, ListItemIcon, ListItemText, Menu, MenuItem, Stack, Toolbar, Tooltip, Typography, useScrollTrigger } from '@mui/material';
import { useEffect, useState } from 'react';
import { Link as BrowserLink, Outlet, useLocation } from 'react-router-dom';
import { MotionContainer } from '../components/animate';
import ScrollTop from '../components/ScrollTop';
import icon from '../images/icon.png';
import logo from '../images/logo.svg';
import iconText from '../images/logoText.png';
import mapPin from '../images/map-pin.svg';
import { Image } from './Dashboard';
import Map from './Map';

const pages = [
  {
    icon: 'info_outline',
    page: 'About'
  },
  {
    icon: 'done_all',
    page: 'Approach'
  },
  {
    icon: 'work_outline',
    page: 'Works'
  },
  {
    icon: 'workspaces',
    page: 'Contact Us'
  },
];

export const Circle = styled(Box)(({
  background = '#676DFF',
  ...other
}) => ({
  zIndex: -10,
  position: 'absolute',
  background,
  mixBlendMode: 'difference',
  ...other
}));

export const companyLogo = window?.code1 === 1 ? icon : logo;
const contactDetails = window.locations[window.code1];
const phone = contactDetails.phone;

export default function DashboardLayout({ ...props }) {
  const { pathname } = useLocation();
  const [anchorElNav, setAnchorElNav] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };


  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const trigger = useScrollTrigger({
    target: props.window ? props.window() : undefined,
    disableHysteresis: true,
    threshold: 50,
  });

  useEffect(() => {
    const anchor = document.querySelector(
      '#back-to-top-anchor',
    );
    if (anchor) {
      anchor.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [pathname])
  const [active, setActive] = useState(0);
  const activePage = window.location.pathname.split('/')[1];
  return (
    <MotionContainer initial="initial" open >
      <Box sx={{ overflowX: 'hidden' }}>
        <AppBar sx={{ ...!trigger ? { backgroundColor: '#00000000', mt: 1, boxShadow: 'none' } : { backgroundColor: 'background.default' }, transition: 'all 700ms' }}>
          <Container maxWidth="xl" sx={{ overflow: 'hidden' }}>
            <Toolbar disableGutters>
              <Typography
                variant="h6"
                noWrap
                component={BrowserLink}
                to='/'
                sx={{ mr: 2, zIndex: 100, }}>
                <Image src={window.code1 !== 1 ? companyLogo : iconText} sx={{ height: window.code1 !== 1 ? 40 : 20 }} alt='log' />
              </Typography>

              <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, justifyContent: 'center' }}>

                <Typography
                  variant="h6"
                  noWrap
                  sx={{ mr: 2, }}
                >
                  {contactDetails?.name}
                </Typography>
              </Box>
              <Stack sx={{
                flexGrow: 1, display: { xs: 'none', md: 'flex' },
                justifyContent: 'center',
              }}
                direction={'row'}
                spacing={2}
              >
              </Stack>
              <Stack sx={{
                position: 'absolute',
                height: (theme) => theme.mixins.toolbar.minHeight,
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: { xs: 'none', md: 'flex' },
                justifyContent: 'center',
              }}
                direction={'row'}
                spacing={2}
              >
                {pages.map(({ page }) => (
                  <Typography
                    sx={{ lineHeight: (theme) => theme.mixins.toolbar.minHeight + 'px' }}
                    component={BrowserLink}
                    key={page}
                    to={`/${page.toLowerCase().replace(' ', '-')}`} color={activePage === page.toLowerCase().replace(' ', '-') ? 'primary' : 'primary.contrastText'}
                  >
                    {page}
                  </Typography>
                ))}
              </Stack>
              <Box sx={{ flexGrow: 0 }}>
                <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                  <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleOpenNavMenu}
                    color="inherit"
                  >
                    <Icon>menu</Icon>
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorElNav}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    open={Boolean(anchorElNav)}
                    onClose={handleCloseNavMenu}
                    sx={{
                      display: { xs: 'block', md: 'none' },
                    }}
                  >
                    {pages.map(({ page, icon }) => (
                      <MenuItem key={page}
                        component={BrowserLink}
                        color={activePage === page.toLowerCase().replace(' ', '-') ? 'primary' : 'primary.contrastText'}
                        to={`/${page.toLowerCase().replace(' ', '-')}`}>

                        <ListItemIcon>
                          <Icon fontSize='small'>{icon}</Icon>
                        </ListItemIcon>
                        <ListItemText>{page}</ListItemText>
                      </MenuItem>
                    ))}

                    <MenuItem
                      component={'a'}
                      href={`tel:${phone.tel}`}>
                      <ListItemIcon>
                        <Icon fontSize='16px'>phone_outline</Icon>
                      </ListItemIcon>
                      <ListItemText sx={{
                        fontWeight: 400,
                        fontSize: '1rem'
                      }}>{phone.label || phone.tel}</ListItemText>
                    </MenuItem>
                  </Menu>
                </Box>
                <Tooltip title="Call Now">
                  <Button
                    LinkComponent={'a'} href={`tel:${phone.tel}`}
                    startIcon={<Icon>phone</Icon>}
                    sx={{ mr: 2, display: { xs: 'none', md: 'flex' }, color: 'primary.contrastText', fontWeight: 400 }}
                  >
                    {phone.label || phone.tel}
                  </Button>
                </Tooltip>
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
        <Toolbar id="back-to-top-anchor" />
        <Outlet />
        <Container maxWidth="xl">
          <Box pt={{ xs: 6, md: 19 }} pb={{ xs: 6, md: 12 }}>
            <Map onClick={setActive} />
          </Box>
          <Card sx={{
            backgroundColor: '#0D1328',
            boxShadow: 'none', borderRadius: 0,
          }}>
            <Stack direction={{ xs: 'column', md: 'row' }}  >
              {
                [...window.locations]?.sort((a, b) => a.order - b.order).map(({ country, name, location, phone, flag, site }, index) => {
                  return (
                    <Card hover={false} key={country} sx={{
                      flexGrow: 1, width: '100%',
                      boxShadow: 'none', borderRadius: 0,
                      ...active === index ? { background: 'linear-gradient(180deg, #124AFF 0%, #1B8AF6 100%)' } : {
                        backgroundColor: '#0D1328',
                      }
                    }} onClick={e => setActive(index)}>
                      <CardActionArea sx={{ height: '100%' }}>
                        <Stack key={country} px={5} py={7} flexGrow={1} spacing={2}>
                          <Stack direction={'row'} spacing={3} pb={2}>
                            <Typography
                              color='primary.contrastText'
                              sx={{
                                fontWeight: 700,
                                fontSize: { xs: '18px', md: '1.46666667rem' },
                                textTransform: 'uppercase'
                              }}>
                              {country}
                            </Typography>
                            <Image src={flag} loading='lazy' alt={country} sx={{ height: 32, width: 32 }} />
                          </Stack>
                          <Typography
                            color='primary.contrastText'
                            fontWeight={600}
                            fontSize={{ xs: '16px', md: '20px' }}>
                            {name}
                          </Typography>
                          <Typography
                            color='primary.contrastText'
                            fontWeight={600}
                            fontSize={{ xs: '16px', md: '20px' }}>
                            {location}
                          </Typography>
                          <Typography
                            component={'a'}
                            href={`tel:${phone.tel}`}
                            color='primary.contrastText'
                            fontWeight={600}
                            fontSize={{ xs: '16px', md: '20px' }}>
                            {phone.label || phone.tel}
                          </Typography>
                          <Typography
                            component={'a'}
                            href={`${site}`}
                            target='_blank'
                            color='primary.contrastText'
                            fontWeight={600}
                            fontSize={{ xs: '16px', md: '20px' }}>
                            {site}
                          </Typography>
                        </Stack>
                      </CardActionArea>
                    </Card>
                  )
                })
              }
            </Stack>
          </Card>
        </Container>
        <Box sx={{ background: '#0D1328', pt: 5, mt: { xs: 9, md: 18.5 } }}>
          <Container maxWidth="xl">
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={5} pb={5}>
              <Stack flexGrow={1} width='100%'>
                <Stack direction='row' spacing={3} pb={2}>
                  <Image src={companyLogo} sx={{ height: 60 }} />
                  <Box height='100%' display='grid'>
                    <Typography
                      color='primary.contrastText'
                      sx={{
                        flexGrow: 1,
                        fontWeight: 600,
                        mt: 2,
                        fontSize: 'clamp(1rem, 3vw, 1.375rem)',
                        placeSelf: 'center'
                      }}
                    >
                      {contactDetails.name}
                    </Typography>
                  </Box>
                </Stack>
                <Typography
                  sx={{
                    color: '#808DAD',
                    flexGrow: 1,
                    mt: 2,
                    fontSize: 'clamp(.3rem, 3vw, 1rem)',
                    maxWidth: 600
                  }}
                >
                  {contactDetails.name} {window.dashboard?.description}
                </Typography>
                <Stack direction='row' spacing={3} pt={2}>
                  {
                    window.dashboard?.social.map(({ title, icon }) => (<Link href={contactDetails.social[title]} target='_blank'>
                      <Avatar src={icon} variant='square' />
                    </Link>))
                  }
                </Stack>
              </Stack>
              <Stack flexGrow={.25} sx={{ minWidth: 250 }}>
                <Typography
                  color='primary.contrastText'
                  sx={{
                    flexGrow: 1,
                    mt: 2,
                    fontSize: 'clamp(.5rem, 3vw, 1rem)',
                  }}
                >Quick Links</Typography>
                {
                  pages.map(({ page }) => <BrowserLink
                    key={page}
                    to={`/${page.toLowerCase().replace(' ', '-')}`}>
                    <Typography
                      sx={{
                        color: '#808DAD',
                        flexGrow: 1,
                        mt: 2,
                        fontSize: 'clamp(.3rem, 3vw, 1rem)',
                        placeSelf: 'center'
                      }}
                    >{page}</Typography>
                  </BrowserLink>)
                }
              </Stack>
              <Stack flexGrow={.25} sx={{ minWidth: 250 }}>
                <Typography
                  color='primary.contrastText'
                  sx={{
                    mt: 2,
                    fontSize: 'clamp(.5rem, 3vw, 1rem)',
                  }}
                >Contact Details</Typography>
                <Typography
                  sx={{
                    color: '#808DAD',
                    mt: 2,
                    fontSize: 'clamp(.3rem, 3vw, 1rem)',
                  }}
                >{contactDetails.name}</Typography>
                <Stack direction='row' spacing={1} py={1} component={'a'} target='_blank'
                  href={`https://www.google.com/maps/place/${contactDetails.location}`}>
                  <Icon>location_on</Icon>
                  <Typography
                    sx={{
                      color: '#808DAD',
                      mt: 2,
                      fontSize: 'clamp(.3rem, 3vw, 1rem)',
                      placeSelf: 'center'
                    }}
                  >{contactDetails.location}</Typography>
                </Stack>
                <Tooltip title="Call Now">
                  <Stack direction='row' spacing={1} py={1} component={'a'} href={`tel:${phone.tel}`} >
                    <Icon>phone</Icon>
                    <Typography
                      sx={{
                        color: '#808DAD',
                        mt: 2,
                        fontSize: 'clamp(.3rem, 3vw, 1rem)',
                        placeSelf: 'center'
                      }}
                    > {phone.label || phone.tel}</Typography>
                  </Stack>
                  {/* <Button
                   
                    startIcon={ }
                    sx={{ mr: 2, display: { xs: 'none', md: 'flex' }, color: 'primary.contrastText', fontWeight: 400 }}
                  >
                    {phone.label || phone.tel}
                  </Button> */}
                </Tooltip>
                <Stack direction='row' spacing={1} py={1} component={'a'} href={`tel:${phone.tel}`} >
                  <Icon>email</Icon>
                  <Typography
                    sx={{
                      color: '#808DAD',
                      mt: 2,
                      fontSize: 'clamp(.3rem, 3vw, 1rem)',
                      placeSelf: 'center'
                    }}
                  > {window.locations[window.code1].email}</Typography>
                </Stack>
                {/* <Typography
                  color='primary.contrastText'
                  sx={{
                    my: 2,
                    fontSize: 'clamp(.5rem, 3vw, 1rem)',
                  }}
                >Newsletter</Typography>
                <TextField variant='standard'
                  placeholder='Enter your email address'
                  type='email'
                  InputProps={{
                    endAdornment: <InputAdornment position="end">
                      <Icon>mail_outline</Icon>
                    </InputAdornment>
                  }}
                /> */}
              </Stack>
            </Stack>
          </Container>
        </Box>
        <ScrollTop>
          <Fab color="secondary" size="small" aria-label="scroll back to top">
            <Icon>keyboard_arrow_up</Icon>
          </Fab>
        </ScrollTop>
        <Circle sx={{
          width: 607,
          height: 607,
          left: { xs: -365, md: -195 },
          top: { xs: -290, md: -156 },
          opacity: .45,
          filter: 'blur(194px)',
        }} />
      </Box>
    </MotionContainer>
  );
}