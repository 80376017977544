export const localhost = false;
const live = process.env.NODE_ENV === 'production'
export const ORIGIN = live || !localhost ? 'https://crm.codecrewz.com.au/' : 'http://localhost/'
export const BASE_URL = `${ORIGIN}api/`;
export const AUTH_URL = BASE_URL + 'auth';
export const USER_URL = BASE_URL + 'user';
export const CHANGE_PASSWORD_URL = USER_URL + '/changepassword';
export const INVOICE_URL = BASE_URL + 'invoice';
export const INVOICE_LIST_URL = INVOICE_URL + '/list';
export const INVOICE_COUNT_URL = INVOICE_URL + '/count';
export const OWNER_URL = INVOICE_URL + '/owner/setup';
export const EMAIL_URL = INVOICE_URL + '/email/setup';
export const EMAIL_SEND_URL = INVOICE_URL + '/send';
