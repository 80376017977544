import { useRoutes } from 'react-router-dom';
import {
  AboutUs,
  Approach,
  Careers,
  ContactUs,
  Dashboard,
  DashboardLayout,
  Page404,
  PayNow,
  Pricing,
  Thanks,
  Works,
} from './pages'
// ----------------------------------------------------------------------
export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        { path: '/', element: <Dashboard /> },
        { path: '/pay', element: <PayNow /> },
        { path: '/pricing', element: <Pricing /> },
        { path: '/works', element: <Works /> },
        { path: '/about', element: <AboutUs /> },
        { path: '/approach', element: <Approach /> },
        { path: '/contact-us', element: <ContactUs /> },
        { path: '/careers', element: <Careers /> },
        { path: '/thanks', element: <Thanks /> },
        { path: '*', element: <Page404 /> }
      ]
    }
  ]);
}
